import { useCallback, useEffect } from 'react';
import type { MutableRefObject } from 'react';

export type UseOnClickOutsideProps = {
  // active is for when this hook is used for a component that does not
  // leave the tree when it's hidden. It ensures the listeners are only
  // added when needed and cleaned up otherwise.
  active?: boolean;
  ref: MutableRefObject<any>;
  // refs for any other elements that should be ignore (eg triggers for tooltips)
  ignoreRefs?: MutableRefObject<any>[];
  ignoreConditions?: (() => boolean)[];
  /**  it's a good idea to wrap the handler in`useCallback` when possible */
  handler: (e?: MouseEvent) => void;
  captureClick?: boolean;
};

export const isTargetOrChild = (target: EventTarget | HTMLElement, container: HTMLElement) =>
  container.isSameNode(target as HTMLElement) || container.contains(target as HTMLElement);

export function useOnClickOutside({
  active = true,
  ignoreRefs = [],
  ignoreConditions = [],
  ref,
  handler,
  captureClick = false,
}: UseOnClickOutsideProps) {
  const shouldHandleEvent = useCallback(
    (e: MouseEvent) => {
      if (!ref.current || !e.target) return false;

      // ignore clicks on ref or descendants of ref
      const isSame = isTargetOrChild(e.target, ref.current);
      // ignore same for any other specified refs
      const shouldIgnore =
        ignoreRefs.some(({ current }) => current && e.target && isTargetOrChild(e.target, current)) ||
        ignoreConditions.some((condition) => condition());

      if (isSame || shouldIgnore) return false;

      return true;
    },
    [ref, ignoreRefs]
  );

  const captureClickListener = useCallback(
    (e: MouseEvent) => {
      if (shouldHandleEvent(e) && captureClick) {
        handler(e);
        e.stopImmediatePropagation();
        e.stopPropagation();
        e.preventDefault();
      }
    },
    [captureClick, handler, shouldHandleEvent]
  );

  const listener = useCallback(
    (e: MouseEvent) => {
      if (shouldHandleEvent(e)) {
        handler(e);
      }
    },
    [handler, shouldHandleEvent]
  );

  useEffect(() => {
    const addHandlers = () => {
      if (captureClick) {
        document.addEventListener('click', captureClickListener, true);
        // Right clicks should be able to still pass through
        document.addEventListener('contextmenu', listener);
      } else {
        // @ts-ignore
        document.addEventListener('touchstart', listener);
        document.addEventListener('mousedown', listener);
      }
    };

    const removeHandlers = () => {
      if (captureClick) {
        document.removeEventListener('click', captureClickListener, true);
        document.removeEventListener('contextmenu', listener);
      }
      // @ts-ignore
      document.removeEventListener('touchstart', listener);
      document.removeEventListener('mousedown', listener);
    };

    if (active) {
      addHandlers();
    } else {
      removeHandlers();
    }

    return () => {
      removeHandlers();
    };
  }, [active, handler, listener, captureClickListener]);
}
