import React from 'react';
import { IndexRowItem, HelperText } from './atoms';
import { IndexRowProps, IndexRowProvider } from './provider';

type IndexRowOptionalProps = IndexRowProps & {
  helperText?: React.ReactNode;
  maxWidth?: number | string;
};

const IndexRowOptional = ({ children, helperText, maxWidth = 700, ...rest }: IndexRowOptionalProps) => {
  return (
    <IndexRowProvider {...rest}>
      <article role='group' css={{ maxWidth }}>
        <section data-index-row-parent>{children}</section>
        <HelperText helperText={helperText} />
      </article>
    </IndexRowProvider>
  );
};

const IndexRowNamespace = Object.assign(IndexRowOptional, {
  Item: IndexRowItem,
});

export { IndexRowNamespace as IndexRow };
