import { memo, useCallback, useEffect, useState } from 'react';
import { ScrollControl } from '../atoms';
import { styles } from './styles';

type Props = {
  columnsOrder?: string[];
  dataLength?: number;
  isRowComparison?: boolean;
  scrollView?: HTMLUListElement | null;
};

export const ScrollButtons = memo(({ columnsOrder = [], dataLength = 0, isRowComparison, scrollView }: Props) => {
  const [showLeftScrollButton, setShowLeftScrollButton] = useState<boolean>(false);
  const [showRightScrollButton, setShowRightScrollButton] = useState<boolean>(false);

  const handleControlledScroll = useCallback(
    (direction: 'left' | 'right' | 'reset') => {
      if (!scrollView) {
        return;
      }

      const columns = isRowComparison ? dataLength : columnsOrder.length;
      const scrollLeft = scrollView.scrollWidth / columns;

      if (direction === 'left') {
        const scrollTo = scrollView.scrollLeft - scrollLeft;
        const hasMoreToScroll = scrollTo > 0;
        scrollView.scrollTo({
          left: scrollTo,
          behavior: 'smooth',
        });
        setShowRightScrollButton(true);
        setShowLeftScrollButton(hasMoreToScroll);
      } else if (direction === 'right') {
        const scrollTo = scrollView.scrollLeft + scrollLeft;
        const hasMoreToScroll = scrollView.scrollWidth - scrollTo > scrollView.clientWidth;
        scrollView.scrollTo({
          left: scrollTo,
          behavior: 'smooth',
        });
        setShowLeftScrollButton(true);
        setShowRightScrollButton(hasMoreToScroll);
      } else {
        const hasMoreToScroll = scrollView.scrollWidth > scrollView.clientWidth;
        scrollView.scrollTo({
          left: 0,
        });
        setShowLeftScrollButton(false);
        setShowRightScrollButton(hasMoreToScroll);
      }
    },
    [dataLength, columnsOrder, scrollView?.childElementCount, isRowComparison]
  );

  useEffect(() => {
    // Debounce the scroll event to avoid performance issues
    let timeout: ReturnType<typeof setTimeout>;
    handleControlledScroll('reset');

    const handleScroll = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        if (!scrollView) {
          return;
        }

        const scrollWidth = scrollView.scrollWidth;
        const clientWidth = scrollView.clientWidth;
        const scrollLeft = scrollView.scrollLeft;

        setShowLeftScrollButton(scrollLeft > 0);
        setShowRightScrollButton(scrollLeft < scrollWidth - clientWidth);
      }, 100);
    };

    if (scrollView) {
      scrollView.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollView) {
        scrollView.removeEventListener('scroll', handleScroll);
      }
    };
  }, [scrollView?.childElementCount, isRowComparison]);

  return (
    <>
      <ScrollControl
        css={styles.scrollButton}
        direction='left'
        isVisible={showLeftScrollButton}
        onClick={() => handleControlledScroll('left')}
      />

      <ScrollControl
        css={styles.scrollButton}
        direction='right'
        isVisible={showRightScrollButton}
        onClick={() => handleControlledScroll('right')}
      />
    </>
  );
});

ScrollButtons.displayName = 'ScrollButtons';
