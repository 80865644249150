import { memo } from 'react';
import { theme } from '@frontend/theme';
import { FormatValue } from '../types';

type Props = {
  formatters?: FormatValue[];
  payload: { value: string };
  subValue?: (value: number) => number;
  x: number;
  y: number;
};

export const YAxisTick = memo(({ formatters, payload, subValue, x, y }: Props) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={0} textAnchor='end' fill={theme.font.colors.subdued} fontSize={theme.font.size.medium}>
        {formatters?.[0]?.(parseInt(payload.value)) ?? payload.value}
      </text>

      {subValue &&
        formatters?.map((formatValue, index) => {
          // Skip the first value as it is already rendered
          // Skip the value if it is 0 (no need to format the bottom most value)
          if (index === 0 || !payload.value) return null;

          const displayValue = formatValue(subValue(parseInt(payload.value)));
          return (
            <text
              dy={index * 12 + 1}
              fill={theme.font.colors.subdued}
              fontSize={theme.font.size.small}
              key={displayValue}
              textAnchor='end'
              x={0}
              y={0}
            >
              {displayValue}
            </text>
          );
        })}
    </g>
  );
});

YAxisTick.displayName = 'YAxisTick';
