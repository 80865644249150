import { useMemo } from 'react';
import { createEditor, Range, Transforms } from 'slate';
import { withHistory } from 'slate-history';
import { ReactEditor, withReact } from 'slate-react';
import { withEmojiControl } from './editor/slate-with-emoji-control';
import { withTags } from './editor/slate-with-tags';
import { serializePlainText, serializePlainTextValues } from './serialize-template';
import { TagType } from './types';
import { parseTemplate } from './parse-template';

/**
 * Used for creating the editor object used by the MessageTemplate component
 * @param tags template tags used by the MessageTemplate component
 * @returns editor object of type MessageTemplateEditor
 */
export const useCreateEditor = (tags: TagType[], disableEmoji = false, isNewTag: boolean) => {
  return useMemo(
    () => withEmojiControl(disableEmoji, withTags(tags, withReact(withHistory(createEditor())), isNewTag)),
    []
  );
};

/**
 * Editor type used by the MessageTemplate component
 */
export type MessageTemplateEditor = ReturnType<typeof useCreateEditor>;

/**
 * Used to insert text inside the editor of the MessageTemplate component
 * @param editor editor object of type MessageTemplateEditor
 * @param text text to be inserted
 * @param callback Called with the changed value of the editor
 */
export const insertText = (editor: MessageTemplateEditor, text: string, callback?: (template: string) => void) => {
  const insertOptions = editor.selection ? { at: editor.selection } : {};
  Transforms.insertText(editor, text, insertOptions);
  ReactEditor.focus(editor);
  setTimeout(() => {
    if (editor.selection) {
      Transforms.select(editor, editor.selection);
    }
    if (callback) callback(serializePlainText(editor.children));
  }, 0);
};

/**
 * Used to insert text inside the editor of the MessageTemplate component
 * @param editor editor object of type MessageTemplateEditor
 * @param text text to be inserted
 * @param editorSelection current selection of type Range
 * @param callback Called with the changed value of the editor
 */
export const insertTextAtSelection = (
  editor: MessageTemplateEditor,
  text: string,
  editorSelection: Range | null,
  callback?: (template: string) => void
) => {
  const insertOptions = editorSelection ? { at: editorSelection } : {};
  Transforms.insertText(editor, text, insertOptions);
  ReactEditor.focus(editor);
  const newSelection = editorSelection && {
    anchor: { ...editorSelection.anchor, offset: editorSelection.anchor.offset + text.length },
    focus: { ...editorSelection.focus, offset: editorSelection.focus.offset + text.length },
  };
  setTimeout(() => {
    if (newSelection) {
      Transforms.select(editor, newSelection);
    }
    if (callback) callback(serializePlainText(editor.children));
  }, 0);
  return newSelection;
};

/**
 * Used to focus the editor of the MessageTemplate component
 * @param editor editor object of type MessageTemplateEditor
 */
export const focusEditor = (editor: MessageTemplateEditor) => {
  ReactEditor.focus(editor);
};

/**
 * Used to convert the template to the text by replacing the tags
 * @param template template that has the tags
 * @param tags tags used for serializing the template
 */
export const replaceTagsInTemplate = (template: string, tags: TagType[], isNewTag = false) => {
  return serializePlainTextValues(parseTemplate(template, tags, isNewTag));
};
