export type Breakpoints = {
  xsmall: { max: number };
  small: { min: number; max: number };
  medium: { min: number; max: number };
  large: { min: number; max: number };
  xlarge: { min: number };
};

// Breakpoints
// <= 699: 1 panel max, hamburger, full screen nav overlay
// 700-849: 2 panel max, hamburger, normal nav width overlay
// 850-1239: 2 panel max, always visible nav
// 1240+: 3 panel max, always visible nav
export const breakpoints: Breakpoints = {
  xsmall: {
    max: 450,
  },
  small: {
    min: 451,
    max: 767,
  },
  medium: {
    min: 768,
    max: 1100,
  },
  large: {
    min: 1101,
    max: 1241,
  },
  xlarge: {
    min: 1242,
  },
};

export enum NavSize {
  Mini = 'mini',
  Mobile = 'mobile',
  Desktop = 'desktop',
}
