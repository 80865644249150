import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../../component-theme/theme-types';

export type TemplateCharCountThemeValues = {
  borderColor: WeaveThemeValues['Color'];
  spacing: WeaveThemeValues['Spacing'];
};

export const templateCharCountTheme: TemplateCharCountThemeValues = {
  borderColor: theme.colors.neutral10,
  spacing: theme.spacing,
};

export const templateCharCountThemeOriginal: TemplateCharCountThemeValues = {
  borderColor: themeOriginal.colors.gray[300],
  spacing: themeOriginal.spacing,
};
