import { contextFactory } from '../../../hooks';
import { type DynamicFieldAttributes } from '../molecules/dynamic-field-action';

interface EditorContext {
  readOnly?: boolean;
  markdownContent?: string;
  dynamicFields?: DynamicFieldAttributes[];
  hasDraggableBlocks?: boolean;
}

interface EditorProviderProps extends EditorContext {
  children: React.ReactNode;
}

export const [EditorContext, useEditorContext] = contextFactory<EditorContext>();

export const EditorProvider = ({
  readOnly,
  markdownContent,
  dynamicFields,
  hasDraggableBlocks,
  children,
}: EditorProviderProps) => {
  return (
    <EditorContext.Provider value={{ readOnly, markdownContent, dynamicFields, hasDraggableBlocks }}>
      {children}
    </EditorContext.Provider>
  );
};
