import { ContentPanelProps } from '../types';
import { motion } from 'framer-motion';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { ContentPanelBody } from './content-body';
import { ContentPanelBreadcrumbs } from './content-breadcrumbs';
import { ContentPanelHeader } from './content-header';
import { ContextValue, ContentPanelContext } from './content-context';
import { useBreakpoint } from '@frontend/responsiveness';
import { PANEL_MIN_WIDTH } from '../const';

const defaultVariants: ContentPanelProps['variants'] = {
  xsmall: {
    padded: {
      padding: theme.spacing(1),
    },
    reference: {
      padding: theme.spacing(4),
    },
    none: {},
  },
  small: {
    padded: {
      padding: theme.spacing(3),
    },
    reference: {
      padding: theme.spacing(4),
    },
    none: {},
  },
  medium: {
    padded: {
      padding: theme.spacing(5),
    },
    reference: {
      padding: theme.spacing(4),
    },
    none: {},
  },
  large: {
    padded: {
      padding: theme.spacing(5),
    },
    reference: {
      padding: theme.spacing(4),
    },
    none: {},
  },
  xlarge: {
    padded: {
      padding: theme.spacing(5),
    },
    reference: {
      padding: theme.spacing(4),
    },
    none: {},
  },
};

export const ContentPanel = ({
  children,
  id,
  isActive,
  isHidden,
  hasNav,
  hasBack,
  direction,
  shouldAnimate,
  onBackClick,
  variant = 'none',
  variants = defaultVariants,
  ...rest
}: ContentPanelProps) => {
  const value: ContextValue = {
    id,
    isActive,
    isHidden,
    hasNav,
    hasBack,
    onBackClick,
  };
  const breakpoint = useBreakpoint();
  const additionalStyles = variants[breakpoint][variant];

  return (
    <ContentPanelContext.Provider value={value}>
      <motion.section
        initial={shouldAnimate && { x: direction === 'left' ? '-100%' : '100%' }}
        animate={shouldAnimate && { x: '0%' }}
        exit={shouldAnimate ? { x: direction === 'left' ? '-100%' : '100%' } : undefined}
        transition={{
          duration: 0.2,
          ease: 'easeIn',
        }}
        css={[
          css`
            min-width: ${PANEL_MIN_WIDTH}px;
            display: flex;
            flex-direction: column;
            height: 100%;
            :last-of-type {
              width: 100%;
            }
            :not(:first-of-type) {
              border-left: 1px solid ${theme.colors.neutral20};
            }
          `,
          additionalStyles,
        ]}
        {...rest}
      >
        {/* Remove this once beta period is complete */}
        {children}
      </motion.section>
    </ContentPanelContext.Provider>
  );
};

ContentPanel.Header = ContentPanelHeader;
ContentPanel.Body = ContentPanelBody;
ContentPanel.Breadcrumbs = ContentPanelBreadcrumbs;
