import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Heading, SlideCarousel, Text } from '@frontend/design-system';
import { chartColors } from './constant';
import { PaymentsPieChart } from './pie-chart';
import { PaymentsChartData } from './types';

interface NarrowLayoutProps {
  chartDataList: PaymentsChartData;
  totalInvoices: number;
}

export const NarrowLayout = ({ chartDataList, totalInvoices }: NarrowLayoutProps) => {
  return (
    <SlideCarousel slides={['count', 'net']}>
      <SlideCarousel.Container>
        <SlideCarousel.Panel id='count'>
          <TotalInvoicesCount value={totalInvoices} />
        </SlideCarousel.Panel>
        <SlideCarousel.Panel id='net'>
          <div css={flexColumnStyle}>
            <PaymentsPieChart data={chartDataList.net} colors={chartColors} />
          </div>
        </SlideCarousel.Panel>
      </SlideCarousel.Container>
      <SlideCarousel.Navigation />
    </SlideCarousel>
  );
};
const flexColumnStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  minHeight: 0,
});

interface TotalInvoicesCountProps extends React.ComponentPropsWithoutRef<'div'> {
  value?: number;
}

export const TotalInvoicesCount = ({ value = 0, ...rest }: TotalInvoicesCountProps) => {
  const { t } = useTranslation('payments');

  return (
    <div
      css={[
        flexColumnStyle,
        css`
          align-items: center;
          gap: ${theme.spacing(0.5)};
          justify-content: center;
        `,
      ]}
      {...rest}
    >
      <Heading
        as='p'
        css={css`
          font-size: 136px;
          margin: 0;
          line-height: 1;
        `}
      >
        {value > 99 ? '99+' : value}
      </Heading>
      <Text
        size='medium'
        weight='regular'
        css={css`
          margin: 0;
          color: ${theme.font.colors.subdued};
        `}
      >
        {t('Total invoices collected')}
      </Text>
    </div>
  );
};
