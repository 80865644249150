import { css } from '@emotion/react';
import { FC } from 'react';
import { Text } from '../../..';
import { useThemeValues } from '../../../../hooks';
import { NakedUl } from '../../../../naked';

export type OptionGroupProps = {
  label: string;
};

export const OptionGroup: FC<React.PropsWithChildren<OptionGroupProps>> = ({ children, label, ...rest }) => {
  const { spacing } = useThemeValues();

  return (
    <li>
      <NakedUl
        css={css`
          li:not(:first-of-type) {
            // the first li child will be the label of the option group,
            // it gets its own styles
            padding: ${spacing(1, 2, 1, 4)};
          }
        `}
      >
        <Text
          as='li'
          size='large'
          weight='bold'
          className='option-group-label'
          css={css`
            margin: ${spacing(1, 0.5, 0.5, 2)};
            :first-letter {
              text-transform: capitalize;
            }
          `}
          {...rest}
        >
          {label || ''}
        </Text>
        {children}
      </NakedUl>
    </li>
  );
};
