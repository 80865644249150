import { forwardRef } from 'react';
import { useStyles } from '../../../use-styles';
import { LayoutGroup } from 'framer-motion';

export type TabBarProps = React.HTMLAttributes<HTMLDivElement> & {
  activeTab: string | undefined;
  children: Array<JSX.Element | boolean | null>;
  fullWidth?: boolean;
  isScrollable?: boolean;
  uniqueId?: string;
};

export const TabBar = forwardRef<HTMLDivElement, TabBarProps>(
  ({ activeTab, children, fullWidth, isScrollable, uniqueId, ...rest }, ref) => {
    const styles = useStyles('Tabs', 'tabBar', { fullWidth, isScrollable });

    return (
      <div ref={ref} css={styles} role='tablist' {...rest}>
        <LayoutGroup id={uniqueId}>{children}</LayoutGroup>
      </div>
    );
  }
);
