import { css } from '@emotion/react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { PopoverMenu, PopoverMenuItem, usePopoverMenu, Text, NakedButton } from '@frontend/design-system';
import { sizesMap, WidgetSizes } from '../../constants';

type WidgetSizeSelectorProps = {
  value?: WidgetSizes;
  options?: WidgetSizes[];
  onChange?: (size: WidgetSizes) => void;
  trackingId?: (size: string) => string;
};

const chevronAnimatedStyle = (isActive: boolean) => css`
  transition: transform 300ms ease-out;
  display: flex;
  flex-direction: center;
  justify-content: center;
  transform: rotate(${isActive ? 180 : 0}deg);
`;

export const WidgetSizeSelector = ({ onChange, options, value, trackingId, ...rest }: WidgetSizeSelectorProps) => {
  const { getTriggerProps, getMenuProps, getItemProps, isOpen } = usePopoverMenu<HTMLButtonElement | HTMLAnchorElement>(
    {
      interactionOptions: {
        listNavigation: {
          selectedIndex: getSelectedIndex(value, options),
        },
      },
      placement: 'bottom-end',
      middlewareOptions: {
        offset: {
          crossAxis: 0,
          mainAxis: 4,
        },
      },
    }
  );

  return (
    <>
      <NakedButton
        trackingId={trackingId?.('change-size')}
        css={[
          css`
            display: flex;
            padding: ${theme.spacing(0.5)};
            align-items: center;
            white-space: nowrap;
            gap: 4px;
            border-radius: ${theme.borderRadius.small};
            :hover {
              background-color: ${theme.colors.neutral10};
            }
          `,
          isOpen &&
            css`
              background-color: ${theme.colors.neutral10};
            `,
        ]}
        {...getTriggerProps()}
        onMouseDown={(e) => e.stopPropagation()}
        {...rest}
      >
        <Text
          css={css`
            font-size: ${theme.font.size.medium};
          `}
        >
          ({options?.length ?? ''}) Sizes
        </Text>
        <span className='chevron-arrow' css={chevronAnimatedStyle(!!isOpen)}>
          <Icon name='caret-down-tiny' />
        </span>
      </NakedButton>
      <PopoverMenu
        {...getMenuProps()}
        onMouseDown={(e) => e.stopPropagation()}
        css={css`
          width: 168px;
          max-width: 168px;
        `}
        returnFocus={false}
      >
        {options?.map((widgetSize, index) => (
          <PopoverMenuItem
            trackingId={trackingId?.(widgetSize)}
            css={css`
              height: 32px;
              padding: ${theme.spacing(1.5)};
            `}
            key={widgetSize}
            {...getItemProps({
              index,
              onClick: () => onChange?.(widgetSize),
            })}
          >
            <div
              css={css`
                display: inline-block;
                height: ${sizesMap[widgetSize].iconHeight}px;
                width: ${sizesMap[widgetSize].iconWidth}px;
                border: 2px solid ${theme.colors.primary50};
                background: ${theme.colors.primary5};
                border-radius: 2px;
              `}
            ></div>
            <p
              css={css`
                display: inline-block;
                text-align: end;
                margin-left: auto;
                font-size: ${theme.font.size.medium};
              `}
            >
              {sizesMap[widgetSize].label}
            </p>
          </PopoverMenuItem>
        ))}
      </PopoverMenu>
    </>
  );
};

const getSelectedIndex = (value?: WidgetSizes, options?: WidgetSizes[]) => options?.findIndex((size) => size === value);
