import { theme as originalTheme } from '@frontend/theme-original';
import { theme } from '@frontend/theme';
import { WeaveThemeValues } from '../../component-theme';

export type ContainerThemeValues = {
  spacing: WeaveThemeValues['Spacing'];
  timestampStyles: {
    color: WeaveThemeValues['Color'];
    errorColor: WeaveThemeValues['Color'];
    scheduledColor: WeaveThemeValues['Color'];
  };
};

export const containerTheme: ContainerThemeValues = {
  spacing: theme.spacing,
  timestampStyles: {
    color: theme.colors.neutral50,
    errorColor: theme.colors.critical50,
    scheduledColor: theme.colors.warning60,
  },
};

export const originalContainerTheme: ContainerThemeValues = {
  spacing: originalTheme.spacing,
  timestampStyles: {
    color: originalTheme.colors.gray400,
    errorColor: originalTheme.colors.error,
    scheduledColor: originalTheme.colors.warning,
  },
};

export type BubbleThemeValues = {
  spacing: WeaveThemeValues['Spacing'];
  borderRadius: WeaveThemeValues['BorderRadius'];
  inboundBackgroundColor: WeaveThemeValues['Color'];
  outboundBackgroundColor: WeaveThemeValues['Color'];
  errorBackgroundColor: WeaveThemeValues['Color'];
  focusBorderColor: WeaveThemeValues['Color'];
  interactiveStyles: {
    backgroundColor: WeaveThemeValues['Color'];
    borderColor: WeaveThemeValues['Color'];
    color: WeaveThemeValues['Color'];
    hover: {
      backgroundColor: WeaveThemeValues['Color'];
    };
    pressed: {
      backgroundColor: WeaveThemeValues['Color'];
      color: WeaveThemeValues['Color'];
    };
  };
  scheduledStyles: {
    backgroundColor: WeaveThemeValues['Color'];
    pausedBackgroundColor: WeaveThemeValues['Color'];
    borderColor: WeaveThemeValues['Color'];
    color: WeaveThemeValues['Color'];
    pausedColor: WeaveThemeValues['Color'];
  };
};

export const bubbleTheme: BubbleThemeValues = {
  spacing: theme.spacing,
  borderRadius: theme.borderRadius.medium,
  inboundBackgroundColor: theme.colors.neutral5,
  outboundBackgroundColor: theme.colors.primary5,
  errorBackgroundColor: theme.colors.critical5,
  focusBorderColor: theme.colors.primary50,
  interactiveStyles: {
    backgroundColor: theme.colors.white,
    borderColor: theme.colors.primary50,
    color: theme.colors.primary50,
    hover: {
      backgroundColor: theme.colors.primary10,
    },
    pressed: {
      backgroundColor: theme.colors.primary40,
      color: theme.colors.white,
    },
  },
  scheduledStyles: {
    backgroundColor: theme.colors.warning5,
    pausedBackgroundColor: theme.colors.white,
    borderColor: theme.colors.neutral50,
    color: theme.colors.warning80,
    pausedColor: theme.colors.neutral50,
  },
};

export const originalBubbleTheme: BubbleThemeValues = {
  spacing: originalTheme.spacing,
  borderRadius: originalTheme.borderRadius.medium,
  inboundBackgroundColor: originalTheme.colors.gray100,
  outboundBackgroundColor: originalTheme.colors.hoverBlue,
  errorBackgroundColor: originalTheme.colors.error,
  focusBorderColor: originalTheme.colors.weaveBlue,
  interactiveStyles: {
    backgroundColor: originalTheme.colors.white,
    borderColor: originalTheme.colors.weaveBlue,
    color: originalTheme.colors.weaveBlue,
    hover: {
      backgroundColor: originalTheme.colors.hoverBlue,
    },
    pressed: {
      backgroundColor: originalTheme.colors.pressedBlue,
      color: originalTheme.colors.white,
    },
  },
  scheduledStyles: {
    backgroundColor: originalTheme.colors.warning,
    pausedBackgroundColor: originalTheme.colors.white,
    borderColor: originalTheme.colors.gray500,
    color: originalTheme.colors.gray500,
    pausedColor: originalTheme.colors.gray500,
  },
};

export type ImageBubbleThemeValues = {
  spacing: WeaveThemeValues['Spacing'];
  borderRadius: WeaveThemeValues['BorderRadius'];
  backgroundColor: WeaveThemeValues['Color'];
  focusedBorderColor: WeaveThemeValues['Color'];
  scheduledStyles: {
    backgroundColor: WeaveThemeValues['Color'];
    color: WeaveThemeValues['Color'];
  };
  sendingStyles: {
    backgroundColor: WeaveThemeValues['Color'];
    borderColor: WeaveThemeValues['Color'];
  };
};

export const imageBubbleTheme: ImageBubbleThemeValues = {
  spacing: theme.spacing,
  borderRadius: theme.borderRadius.medium,
  backgroundColor: theme.colors.neutral10,
  focusedBorderColor: theme.colors.primary50,
  scheduledStyles: {
    backgroundColor: theme.colors.primary10,
    color: theme.colors.primary70,
  },
  sendingStyles: {
    backgroundColor: theme.colors.white,
    borderColor: theme.colors.primary10,
  },
};

export const originalImageBubbleTheme: ImageBubbleThemeValues = {
  spacing: originalTheme.spacing,
  borderRadius: originalTheme.borderRadius.medium,
  backgroundColor: originalTheme.colors.gray100,
  focusedBorderColor: originalTheme.colors.weaveBlue,
  scheduledStyles: {
    backgroundColor: originalTheme.colors.hoverBlue,
    color: originalTheme.colors.pressedBlue,
  },
  sendingStyles: {
    backgroundColor: originalTheme.colors.white,
    borderColor: originalTheme.colors.hoverBlue,
  },
};

export type ImagePreviewModalThemeValues = {
  borderRadius: WeaveThemeValues['BorderRadius'];
};

export const imagePreviewModalTheme: ImagePreviewModalThemeValues = {
  borderRadius: theme.borderRadius.medium,
};

export const originalImagePreviewModalTheme: ImagePreviewModalThemeValues = {
  borderRadius: originalTheme.borderRadius.medium,
};

export type ImagePreviewModalIconThemeValues = {
  spacing: WeaveThemeValues['Spacing'];
  backgroundColor: WeaveThemeValues['Color'];
};

export type DownloadIconThemeValues = {
  spacing: WeaveThemeValues['Spacing'];
  backgroundColor: WeaveThemeValues['Color'];
};

export const imagePreviewModalIconTheme: ImagePreviewModalIconThemeValues = {
  spacing: theme.spacing,
  backgroundColor: theme.colors.white,
};

export const originalImagePreviewModalIconTheme: ImagePreviewModalIconThemeValues = {
  spacing: originalTheme.spacing,
  backgroundColor: originalTheme.colors.white,
};

export const downloadIconTheme: DownloadIconThemeValues = {
  spacing: theme.spacing,
  backgroundColor: theme.colors.white,
};
