import { borderRadius } from './border-radius';
import { legacyColors } from './colors';
import { font, fontSize } from './font';
import { shadows } from './shadows';
import { spacing } from './spacing';
import { zIndex } from './z-index';

// The JS values of the theme, as opposed to CSS vars
export const weaveTheme = {
  borderRadius,
  colors: legacyColors,
  font,
  fontFamily: "'Inter', sans-serif",
  fontSize,
  name: 'original',
  heightOffset: 0,
  shadows,
  spacing,
  zIndex,
} as const;

export type WeaveTheme = typeof weaveTheme;
