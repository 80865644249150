import { css } from '@emotion/react';
import {
  SwitchChipThemeValues,
  SwitchChipGroupThemeValues,
  switchChipTheme,
  switchChipThemeOriginal,
  switchChipGroupTheme,
  switchChipGroupThemeOriginal,
} from './switch-chip-theme';

const switchChipGroupStyles = ({ spacing }: SwitchChipGroupThemeValues) => css`
  display: flex;
  align-items: center;
  gap: ${spacing(1)};
`;

type SwitchChipStylesProps = {
  selected: boolean;
  disabled?: boolean;
};

const switchChipStyles = (styles: SwitchChipThemeValues, { selected, disabled }: SwitchChipStylesProps) =>
  [
    css`
      max-width: 144px;
    `,
    selected
      ? [
          css`
            transition: background-color 0.2s ease-in-out;
            background-color: ${styles.selected.backgroundColor};
            color: ${styles.selected.color};
            border: none;
          `,
          disabled
            ? css`
                background-color: ${styles.selected.disabledBackgroundColor};
              `
            : css`
                cursor: pointer;
                :hover {
                  background-color: ${styles.selected.hoverBackgroundColor};
                }
              `,
        ]
      : [
          css`
            transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
          `,
          disabled
            ? css`
                border-color: ${styles.disabled.borderColor};
                color: ${styles.disabled.color};
              `
            : css`
                cursor: pointer;
                :hover {
                  background-color: ${styles.hover.backgroundColor};
                  border-color: ${styles.hover.backgroundColor};
                }
              `,
        ],
  ].flat();

export const SwitchChipGroupStyles = {
  SwitchChipGroup: switchChipGroupStyles(switchChipGroupTheme),
  SwitchChip: (props: SwitchChipStylesProps) => switchChipStyles(switchChipTheme, props),
};

export const SwitchChipGroupStylesOriginal = {
  SwitchChipGroup: switchChipGroupStyles(switchChipGroupThemeOriginal),
  SwitchChip: (props: SwitchChipStylesProps) => switchChipStyles(switchChipThemeOriginal, props),
};
