import { createContext, useContext } from 'react';
import { ContentPanelProps } from '../types';

export type ContextValue = Omit<ContentPanelProps, 'children'>;
export const ContentPanelContext = createContext<ContextValue>({} as ContextValue);

/**
 *
 * @returns ContextValue | undefined -
 * Any ContentPanel child type can technically be used outside a ContentPanel.
 * When they call useContentPanel in this case, they will receive undefined
 */
export const useContentPanel = (): ContextValue | undefined => useContext(ContentPanelContext);
