import { css } from '@emotion/react';
import { useThemeValues } from '../../../hooks';
import { CheckIconSmall, MinusIconSmall } from '../../../icon';
import { useStyles } from '../../../use-styles';
import type { BoolInputProps } from '../layouts';
import { BoolInput } from './bool-input.component';
import { useEffect, useRef } from 'react';

export const Checkbox = ({
  active,
  error,
  disabled,
  indeterminate = false,
  value,
  tabIndex,
  ...rest
}: BoolInputProps<'checkbox'> & { tabIndex?: number }) => {
  const styles = useStyles('Checkbox', { active, error, disabled, value });
  const theme = useThemeValues();
  const fieldRef = useRef<HTMLInputElement>(null);

  const stateIconStyles = [
    css`
      transform: scale(0);
      transform-origin: 50% 50%;
      transition: transform 200ms ease-out;
      z-index: ${theme.zIndex.low};
    `,
    value &&
      css`
        transform: scale(1);
      `,
  ];

  useEffect(() => {
    // The only way to set a checkbox to indeterminate in HTML and JavaScript is setting the prop in JavaScript
    if (fieldRef.current) {
      fieldRef.current.indeterminate = indeterminate;
    }
  }, [indeterminate]);

  return (
    <div css={styles}>
      {indeterminate ? (
        <MinusIconSmall color='white' css={stateIconStyles} />
      ) : (
        <CheckIconSmall color='white' css={stateIconStyles} />
      )}

      <BoolInput
        css={[
          css`
            cursor: pointer;
          `,
          disabled &&
            css`
              cursor: not-allowed;
            `,
        ]}
        {...rest}
        type='checkbox'
        checked={value}
        disabled={disabled}
        ref={fieldRef}
        tabIndex={tabIndex}
      />
    </div>
  );
};
