import { css } from '@emotion/react';
import { TrailingColumnThemeValues } from './list-row-trailing-column-theme';
import { TrailingColumnComponentProps } from './types';

export const trailingColumnStyles = (
  { fontSize, spacing, newTheme, hoverColor }: TrailingColumnThemeValues,
  { verticalAlign }: TrailingColumnComponentProps
) => [
  css`
    display: flex;
    grid-area: trail;
    padding-left: ${spacing(2)};
    justify-content: ${verticalAlign};
    flex-direction: column;
    align-items: flex-end;
    .trailing-column-child {
      font-size: ${verticalAlign === 'center' ? fontSize(16) : fontSize(14)};
      margin: 0;
      margin-bottom: ${spacing(0.25)};
      text-transform: capitalize;
    }
  `,
  newTheme &&
    css`
      button {
        :hover:not([disabled]) {
          background-color: ${hoverColor} !important;
        }
      }
    `,
];
