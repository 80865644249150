import { css } from '@emotion/react';
import { useThemeValues } from '../../../hooks';
import { PolymorphicComponentPropWithoutRef } from '../../../type-utils';
import { PrimaryButton, SecondaryButton } from '../../buttons';
import { ClickHandler, withClose } from '../atoms/helpers';
import { useModalContext } from '../provider';

type ComponentProps = {
  disabledPrimary?: boolean;
  onPrimaryClick?: ClickHandler;
  primaryLabel?: string;
  disabledSecondary?: boolean;
  onSecondaryClick?: ClickHandler;
  secondaryLabel?: string;
  primaryTrackingId?: string;
  secondaryTrackingId?: string;
};

type TrayActionsProps<T extends React.ElementType> = PolymorphicComponentPropWithoutRef<T, ComponentProps>;

export const TrayActions = <T extends React.ElementType = 'section'>({
  as,
  disabledPrimary = false,
  onPrimaryClick,
  primaryLabel,
  primaryTrackingId,
  disabledSecondary = false,
  onSecondaryClick,
  secondaryLabel,
  secondaryTrackingId,
  ...rest
}: TrayActionsProps<T>) => {
  const Component = as || 'section';
  const { onClose } = useModalContext();
  const theme = useThemeValues();

  return (
    <Component
      css={css`
        display: flex;
        justify-content: end;

        > button {
          width: auto;
        }
        .tray-primary-button {
          margin-left: ${theme.spacing(3)};
        }
      `}
      {...rest}
    >
      {onSecondaryClick && (
        <SecondaryButton
          className='tray-secondary-button'
          trackingId={secondaryTrackingId}
          disabled={disabledSecondary}
          onClick={withClose(onClose, onSecondaryClick)}
          size='large'
        >
          {secondaryLabel || 'Cancel'}
        </SecondaryButton>
      )}
      <PrimaryButton
        className='tray-primary-button'
        trackingId={primaryTrackingId}
        disabled={disabledPrimary}
        onClick={withClose(onClose, onPrimaryClick)}
        size='large'
      >
        {primaryLabel || 'Ok'}
      </PrimaryButton>
    </Component>
  );
};
