import { css } from '@emotion/react';
import { transparentize } from '../../../helpers';
import type { CheckboxStyleValues } from '../fields/checkbox/checkbox-theme';
import { checkboxTheme, checkboxThemeOriginal } from '../fields/checkbox/checkbox-theme';

type CheckboxStyleProps = {
  active?: boolean;
  error?: boolean;
  disabled?: boolean | undefined;
  value?: boolean;
};

const styles = (
  { borderColor, errorColor, focusColor, selectionColor }: CheckboxStyleValues,
  { value, disabled, active, error }: CheckboxStyleProps
) => [
  css`
    min-width: 20px;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    opacity: ${disabled ? 0.25 : 1};
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;

    ::before {
      width: 28px;
      height: 28px;
      content: '';
      display: block;
      background: ${transparentize(0.7, focusColor)};
      border-radius: 4px;
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      margin-top: -4px;
      margin-left: -4px;
      transform: scale(0);
      transform-origin: 50% 50%;
      transition: transform 200ms ease-out;
    }

    ::after {
      content: '';
      width: 100%;
      height: 100%;
      background: white;
      border: 1px solid ${borderColor};
      border-radius: 2px;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      transition: background 200ms ease-out, border-color 200ms ease-out;
    }

    :focus-within::before {
      transform: scale(1);
    }

    :focus-within::after {
      border-color: ${selectionColor};
    }
  `,
  !disabled &&
    active &&
    css`
      ::before {
        transform: scale(1);
      }

      ::after {
        border-color: ${selectionColor};
      }
    `,
  value &&
    css`
      ::before {
        background: ${transparentize(0.7, selectionColor)};
      }

      ::after,
      :focus-within::after {
        background: ${selectionColor};
        border-color: ${selectionColor};
      }
    `,
  !disabled &&
    error &&
    css`
      ::before {
        background: ${transparentize(0.8, errorColor)};
      }

      ::after,
      :focus-within::after {
        border-color: ${errorColor};
      }
    `,
];

export const CheckboxStyles = {
  Checkbox: (checkboxProps: CheckboxStyleProps) => styles(checkboxTheme, checkboxProps),
};

export const CheckboxStylesOriginal: typeof CheckboxStyles = {
  Checkbox: (checkboxProps: CheckboxStyleProps) => styles(checkboxThemeOriginal, checkboxProps),
};
