import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../component-theme/theme-types';

export type UploadContainerThemeValues = {
  borderRadius: WeaveThemeValues['BorderRadius'];
  borderColor: WeaveThemeValues['Color'];
  draggingBorderColor: WeaveThemeValues['Color'];
  backgroundColor: WeaveThemeValues['Color'];
  padding: typeof theme.spacing;
};

export type UploadButtonThemeValues = {
  borderColor: WeaveThemeValues['Color'];
  padding: typeof theme.spacing;
};

export type ImagePreviewContainerThemeValues = {
  padding: typeof theme.spacing;
};

export type ErrorTextThemeValues = {
  color: WeaveThemeValues['Color'];
};

export type PreviewUploadThemeValues = {
  spacing: typeof theme.spacing;
};

export const uploadContainerTheme: UploadContainerThemeValues = {
  borderRadius: theme.borderRadius.small,
  borderColor: theme.colors.neutral30,
  draggingBorderColor: theme.colors.primary50,
  backgroundColor: theme.colors.neutral5,
  padding: theme.spacing,
};

export const uploadContainerThemeOriginal: UploadContainerThemeValues = {
  borderRadius: themeOriginal.borderRadius.small,
  borderColor: themeOriginal.colors.gray400,
  draggingBorderColor: themeOriginal.colors.weaveBlue,
  backgroundColor: themeOriginal.colors.gray200,
  padding: theme.spacing,
};

export const uploadButtonTheme: UploadButtonThemeValues = {
  borderColor: theme.colors.neutral50,
  padding: theme.spacing,
};

export const uploadButtonThemeOriginal: UploadButtonThemeValues = {
  borderColor: theme.colors.neutral50,
  padding: theme.spacing,
};

export const imagePreviewContainerTheme: ImagePreviewContainerThemeValues = {
  padding: theme.spacing,
};

export const imagePreviewContainerThemeOriginal: ImagePreviewContainerThemeValues = {
  padding: theme.spacing,
};

export const errorTextTheme: ErrorTextThemeValues = {
  color: theme.colors.text.critical,
};

export const previewUploadThemeValues: PreviewUploadThemeValues = {
  spacing: theme.spacing,
};
