import { css } from '@emotion/react';
import { InvoiceModel } from '@frontend/api-invoices';
import { formatDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { CollectPaymentModal, LargeInfo } from '@frontend/payments-collection-flow';
import { useInvoiceShallowStore } from '@frontend/payments-invoice-controller';
import { theme } from '@frontend/theme';
import {
  CopyToClipboardButton,
  Heading,
  LinkIconSmall,
  ModalControlResponse,
  PrimaryButton,
} from '@frontend/design-system';
import { formatCentsToCurrency, isPaidInvoice } from '../../../utils';

type SummaryCardProps = {
  invoice: InvoiceModel;
  collectModalControl: ModalControlResponse;
};

const styles = {
  summarySectionRow: css`
    display: flex;
    margin-bottom: ${theme.spacing(3)};
    flex-wrap: wrap;
    justify-content: space-between;
    gap: ${theme.spacing(2)};
  `,
  summary: css`
    display: flex;
    flex-direction: column;
  `,
  amount: css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(2)};
    flex-wrap: wrap;
  `,
  collectPayment: css`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: ${theme.spacing(2)};
  `,
  autoWidth: css`
    width: auto;
  `,
};

export const SummaryCard = ({ invoice, collectModalControl }: SummaryCardProps) => {
  const { t } = useTranslation('payments');
  const { setSelectedInvoiceId } = useInvoiceShallowStore('setSelectedInvoiceId');
  const {
    modalProps: collectPaymentModalProps,
    triggerProps: collectPaymentModalTriggerProps,
    closeModal,
  } = collectModalControl;

  const handleCollectPaymentClick = () => {
    setSelectedInvoiceId(invoice.id);
    collectPaymentModalTriggerProps.onClick();
  };

  return (
    <section css={styles.summarySectionRow}>
      <div css={styles.summary}>
        <Heading level={3}>{invoice.person.name}</Heading>
        <div css={styles.amount}>
          <LargeInfo title={t('Amount')} info={formatCentsToCurrency(invoice.billedAmount)} />
          <LargeInfo title={t('Payment Due')} info={formatDate(invoice.billedAt, 'MMMM D, YYYY')} />
        </div>
      </div>
      {!isPaidInvoice(invoice) && (
        <>
          <div css={styles.collectPayment}>
            <PrimaryButton css={styles.autoWidth} onClick={handleCollectPaymentClick}>
              {t('Collect Payment')}
            </PrimaryButton>
            {invoice.links.payment && (
              <CopyToClipboardButton
                variant='secondary'
                textToCopy={invoice.links.payment}
                hasSuccessTooltip={true}
                tooltipText={t('Copy link')}
                tooltipSuccessText={t('Link copied!')}
                icon={LinkIconSmall}
              ></CopyToClipboardButton>
            )}
          </div>
          <CollectPaymentModal modalProps={collectPaymentModalProps} closeModal={closeModal} />
        </>
      )}
    </section>
  );
};
