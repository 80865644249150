import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { PaymentMethod, PaymentPlanFrequency, setDefaultPaymentMethod } from '@frontend/api-payment-plans';
import { PersonTypes } from '@frontend/api-person';
import { getTodaysDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { PatientDropdown } from '@frontend/patient-dropdown';
import { PaymentMethodItem, usePaymentPlanCardOnFile } from '@frontend/payments-card-on-file';
import { useMerchant, useMultiQueryUtils } from '@frontend/payments-hooks';
import { PaymentsFlowType, StripeElementsWrapper } from '@frontend/payments-stripe-wrapper';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import {
  Heading,
  MoneyField,
  Text,
  useForm,
  TextareaField,
  DatePickerField,
  DropdownField,
  PrimaryButton,
  CreditCardIcon,
  SecondaryButton,
  useModalControl,
  Modal,
  TextLink,
  TextField,
  EmailField,
  FormRow,
  NumberField,
  useAlert,
} from '@frontend/design-system';
import { useAppVariantContext } from '../../providers';
import { convertToCents, termOptions } from '../../utils';
import { CreatePlanDetailsModal, EditBillingMethodModal } from '../PaymentPlanModals';
import { CreatePlanSummary } from './plan-summary';

const styles = {
  billingMethodInfo: (personId: string | undefined) => css`
    display: flex;
    flex-flow: row;
    border: 1px solid ${!personId ? theme.colors.neutral20 : theme.colors.neutral30};
    border-radius: ${theme.borderRadius.small};
    height: 80px;
    padding: ${theme.spacing(1, 1, 1, 2)};
    align-items: center;
    gap: ${theme.spacing(2)};
  `,
  formButtonContainer: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${theme.spacing(2)};
    @media (min-width: ${breakpoints.small.min}px) {
      flex-direction: row;
    }
  `,
  formButtonRow: css`
    display: flex;
    flex-direction: row;
    @media (max-width: ${breakpoints.small.min}px) {
      justify-content: space-evenly;
      width: 100%;
    }
    @media (min-width: ${breakpoints.small.min}px) {
      gap: ${theme.spacing(2)};
    }
  `,
  formButton: css`
    align-self: start;
    width: max-content;
  `,
  paymentMethodContainer: css`
    display: flex;
    flex-flow: row;
    width: 100%;
    justify-content: space-between;
  `,
  cardBrand: css`
    display: flex;
    flex-flow: row;
    align-items: center;
    gap: ${theme.spacing(2)};
  `,
  cardInformation: css`
    display: flex;
    flex: row;
    gap: ${theme.spacing(1)};
  `,
  changeButton: css`
    margin-right: ${theme.spacing(2)};
  `,
};

export const PaymentPlansCreate = () => {
  const { t } = useTranslation('payments');
  const alert = useAlert();
  const { paymentsUrl } = useMerchant();
  const { locationId } = useMultiQueryUtils();
  const { variant } = useAppVariantContext();

  const { modalProps: detailsModalProps, triggerProps: detailsTriggerProps } = useModalControl();
  const { modalProps: editModalProps, triggerProps: editTriggerProps } = useModalControl();
  const [selectedPatient, setSelectedPatient] = useState<PersonTypes.Person | null>(null);
  const [patientPaymentMethod, setPatientPaymentMethod] = useState<PaymentMethod>();

  const { getFieldProps, reset, values, isComplete, seedValues } = useForm({
    fields: {
      planName: {
        type: 'text',
        required: true,
      },
      customerEmailId: {
        type: 'email',
        value: '',
        required: true,
      },
      frequency: {
        type: 'dropdown',
        value: PaymentPlanFrequency.Monthly,
        required: true,
      },
      paymentTerm: {
        type: 'number',
        value: '12',
        required: true,
        min: 1,
        max: 60,
      },
      planAmount: {
        type: 'money',
        min: 0.5,
        required: true,
      },
      paymentDate: {
        type: 'datePicker',
        required: true,
        value: getTodaysDate('MM/DD/YYYY'),
      },
      memo: {
        type: 'text',
      },
    },
  });

  useEffect(() => {
    if (selectedPatient?.PersonID) {
      seedValues({ customerEmailId: selectedPatient?.Email });
    }
  }, [selectedPatient]);

  const handleSetBillingMethod = async (selectedPaymentMethod: PaymentMethod) => {
    try {
      await setDefaultPaymentMethod(paymentsUrl, selectedPaymentMethod?.id, locationId);
      setPatientPaymentMethod(selectedPaymentMethod);
      editModalProps.onClose();
      alert.success(t('Payment method successfully updated.'));
    } catch (err) {
      alert.error(t('Unable to save payment method, please try again.'));
    }
  };

  const getTermPayment = () => Number(values?.planAmount) / Number(values?.paymentTerm) || 0;

  const { editBillingMethodProps, paymentMethodItemProps, disableACHPaymentMethods } = usePaymentPlanCardOnFile({
    paymentAmount: convertToCents(getTermPayment()),
    patientId: selectedPatient?.PersonID,
    setSelectedPM: setPatientPaymentMethod,
    selectDefaultPaymentMethod: true,
    paymentMethodId: patientPaymentMethod?.id,
    paymentFrequency: values.frequency as PaymentPlanFrequency,
  });

  const disableSelectedACHPaymentMethod = patientPaymentMethod?.type === 'us_bank_account' && disableACHPaymentMethods;

  const previewDisabled = !selectedPatient || !isComplete || !patientPaymentMethod || disableSelectedACHPaymentMethod;

  return (
    <>
      <div
        css={css`
          display: flex;
          flex-flow: column;
          gap: ${theme.spacing(3)};
        `}
      >
        {variant === 'portal' && <Heading level={2}>{t('New Payment Plan')}</Heading>}
        <Text>
          {t(
            'Fill out the payment plan details below. The customer will be notified when the payment plan is created.'
          )}
        </Text>
        <TextField {...getFieldProps('planName')} label={t('Payment Plan Name *')} />
        <PatientDropdown setSelectedPatient={setSelectedPatient} />
        <MoneyField {...getFieldProps('planAmount')} label={t('Total Amount *')} />
        <FormRow
          css={css`
            margin-bottom: 0;
          `}
        >
          <DropdownField label={t('Payment cadence *')} {...getFieldProps('frequency')}>
            {termOptions.map((term) => (
              <DropdownField.Option key={term.value} value={term.value}>
                {term.displayValue}
              </DropdownField.Option>
            ))}
          </DropdownField>
          <NumberField label={t('Number of payments *')} {...getFieldProps('paymentTerm')} />
        </FormRow>
        <DatePickerField {...getFieldProps('paymentDate')} label={t('Date of first payment *')} />
        {patientPaymentMethod?.id ? (
          <>
            <PaymentMethodItem
              paymentMethod={patientPaymentMethod}
              onChange={editTriggerProps.onClick}
              showError
              {...paymentMethodItemProps}
            />
          </>
        ) : (
          <div css={styles.billingMethodInfo(selectedPatient?.PersonID)}>
            <CreditCardIcon size={24} color={!selectedPatient?.PersonID ? 'disabled' : 'default'} />
            {!selectedPatient?.PersonID ? (
              <Text color='disabled'>{t('Billing Method*')}</Text>
            ) : (
              <TextLink {...editTriggerProps}>{t('Add Billing Method*')}</TextLink>
            )}
          </div>
        )}

        <TextareaField {...getFieldProps('memo')} label={t('Memo')} />
        <div>
          <Text css={{ marginBottom: theme.spacing(2) }}>Send confirmation email to</Text>
          <EmailField {...getFieldProps('customerEmailId')} label={t('Email *')} />
        </div>
        {values.planAmount && (
          <CreatePlanSummary
            amount={getTermPayment()}
            frequency={values?.frequency as PaymentPlanFrequency}
            date={values?.paymentDate}
          />
        )}
        <div css={styles.formButtonContainer}>
          <div css={styles.formButtonRow}>
            <SecondaryButton onClick={() => reset()} css={styles.formButton}>
              {t('Clear Form')}
            </SecondaryButton>
            <PrimaryButton {...detailsTriggerProps} css={styles.formButton} disabled={previewDisabled}>
              {t('Preview')}
            </PrimaryButton>
          </div>
          {previewDisabled && (
            <Text color='light' weight='light' size='small'>
              {t('Complete all required fields')}
            </Text>
          )}
        </div>
      </div>
      <Modal {...detailsModalProps} maxWidth={600}>
        <CreatePlanDetailsModal
          onClose={detailsModalProps.onClose}
          planDetails={{
            planName: values.planName,
            planAmount: convertToCents(Number(values.planAmount)),
            patient: selectedPatient
              ? {
                  id: selectedPatient?.PersonID,
                  firstName: selectedPatient?.FirstName,
                  lastName: selectedPatient?.LastName,
                }
              : undefined,
            customerEmailId: values.customerEmailId,
            frequency: values.frequency as PaymentPlanFrequency,
            paymentTerm: values.paymentTerm,
            paymentDate: values.paymentDate,
            termAmount: convertToCents(getTermPayment()),
            billingMethod: patientPaymentMethod,
            memo: values.memo,
          }}
        />
      </Modal>
      <StripeElementsWrapper type={PaymentsFlowType.AddCardOnFile}>
        <Modal {...editModalProps} maxWidth={600}>
          <EditBillingMethodModal
            handleSetBillingMethod={handleSetBillingMethod}
            onClose={editModalProps.onClose}
            {...editBillingMethodProps}
          />
        </Modal>
      </StripeElementsWrapper>
    </>
  );
};
export default PaymentPlansCreate;
export { PatientDropdown };
