import { UseCalendarProps, getDecadeRange, parseInternalDate } from './reducer/calendar-utils';
import { useCallback, useEffect, useRef, useReducer, useMemo } from 'react';
import { format, getDate, getMonth, getYear, isBefore, isSameDay, isWithinInterval, parse } from 'date-fns';
import { kebabCase } from 'lodash-es';
import {
  getCalendarData,
  createFocusedDate,
  createDateObject,
  convertToInternalFormat,
  convertToExternalFormat,
  curriedGetNextRange,
  hasPreviousView,
  hasNextView,
  isTodayMonth,
  isTodayYear,
  calendarReducer,
  CalendarReducer,
  dayDown,
  dayUp,
  dayRight,
  dayLeft,
  prevDecade,
  nextDecade,
  prevYear,
  nextYear,
  prev,
  next,
  selectDate,
  selectMonth,
  selectYear,
  reset,
  setRange,
  updateBoundaries,
  selectDateBy,
  isMonthInRange,
  isYearInRange,
  yearUp,
  yearRight,
  yearLeft,
  yearDown,
  monthLeft,
  monthRight,
  monthUp,
  monthDown,
} from './reducer';
import { KeyNames } from '../../../constants/key-names';
import type {
  CalendarDayType,
  CalendarMonthType,
  CalendarDayDataAttr,
  WrapperProps,
  NextButtonProps,
  PrevButtonProps,
  CalendarContainerProps,
  CalendarDayButtonProps,
  CalendarMonthButtonProps,
  CalendarYearButtonProps,
  SelectByType,
  SelectByBtnProps,
} from '../calendar-types';
import { useDidUpdate } from '../../../hooks';

const handleDayEvent = (e: React.KeyboardEvent) => {
  const element = e.target as HTMLButtonElement;
  e.preventDefault();
  e.stopPropagation();
  element.setAttribute('tabindex', '-1');
};

/**
 * A noop event handler that stops and arrow events from propagating
 * @param e React Synthetic Event
 */
export const killEvent = (e: React.KeyboardEvent) => {
  // if it's any of the arrow keys, prevent default
  // so we don't have weird stuff happening
  if (e.key === KeyNames.Left || e.key === KeyNames.Right || e.key === KeyNames.Up || e.key === KeyNames.Down) {
    e.preventDefault();
    e.stopPropagation();
  }
};

const killFocusOnDisabledSelect = (e: React.MouseEvent | React.TouchEvent) => {
  e.preventDefault();
};

export type UseCalendarReturn = {
  calendarContainerProps: CalendarContainerProps;
  dayNames: string[];
  focusDate: () => void;
  getDayButtonProps: (day: CalendarDayType) => CalendarDayButtonProps;
  getMonthButtonProps: (monthYear: string, monthIndex: number) => CalendarMonthButtonProps;
  getYearButtonProps: (year: string, yearIndex: number) => CalendarYearButtonProps;
  initialFocusDayRef?: React.RefObject<HTMLButtonElement>;
  months: CalendarMonthType[];
  nextBtnProps: NextButtonProps;
  prevBtnProps: PrevButtonProps;
  selectByBtnProps: SelectByBtnProps;
  reset: () => void;
  value: string | string[];
  selectDate: (day: string) => void;
  wrapperProps: WrapperProps;
  selectBy: SelectByType;
};

const DAY_NAME_FORMATS = {
  one: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
  two: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
  three: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  full: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
};

export const SELECT_BY_MAP = {
  day: 'month',
  month: 'year',
  year: 'decade',
};

export function useCalendar({
  isRange = false,
  focusedRange,
  maxDate,
  minDate,
  blackoutDates = [],
  monthsInView = 1,
  onEsc,
  onSelect,
  value, // The display string of the date, range or single
  dayNameFormat = 'three',
  dateFormat = 'mm/dd/yyyy',
  today,
}: UseCalendarProps): UseCalendarReturn {
  const assertRange = (value: any): value is string[] | undefined =>
    isRange && (Array.isArray(value) || typeof value === 'undefined');

  if (isRange && typeof value == 'string') {
    throw new Error('useCalendar: isRange is set to true, value should always be an array');
  } else if (isRange && value?.length && value.length > 2) {
    console.warn('useCalendar: value should be an array of length to, got: ' + value);
  }

  const containerRef = useRef<HTMLDivElement>(null);
  const todayObject = today ? createDateObject(today) : new Date();
  const todayMonth = format(todayObject, 'yyyy-MM');
  const todayYear = format(todayObject, 'yyyy');
  const defaultSelectBy = 'day';

  const [initFocusedDate, initialDate] = useMemo(() => {
    const todayFocusDate = () => {
      return createFocusedDate({
        year: getYear(todayObject),
        month: getMonth(todayObject),
        day: getDate(todayObject),
      });
    };

    if (!assertRange(value)) {
      const internalValue = convertToInternalFormat(value, dateFormat);

      const initialFocus = !!internalValue ? createFocusedDate(internalValue) : todayFocusDate();

      return [initialFocus, !!internalValue ? initialFocus.date : ''];
    } else {
      const internalValue = value?.map((val) => convertToInternalFormat(val, dateFormat)) ?? ['', ''];

      const index = focusedRange !== 'max' ? 0 : 1;
      const initialFocus = !!internalValue?.[index] ? createFocusedDate(internalValue[index]) : todayFocusDate();

      return [initialFocus, internalValue];
    }
  }, [value, dateFormat, focusedRange]);

  const [{ focusedDate, months, selectedDate, selectBy, ...otherState }, dispatch] = useReducer<CalendarReducer>(
    calendarReducer,
    {
      focusedDate: initFocusedDate,
      selectedDate: initialDate,
      months: getCalendarData({
        startDate: createDateObject(initFocusedDate),
        monthsInView,
        maxDate,
        minDate,
        blackoutDates,
      }),
      monthsInView,
      minDate,
      maxDate,
      blackoutDates,
      selectBy: defaultSelectBy,
    }
  );

  const isMonth = selectBy === 'month';
  const isYear = selectBy === 'year';
  const isDay = selectBy === 'day';

  useEffect(() => {
    if (
      otherState.minDate !== minDate ||
      otherState.maxDate !== maxDate ||
      otherState.blackoutDates !== blackoutDates
    ) {
      dispatch(updateBoundaries({ minDate, maxDate, blackoutDates }));
    }
  }, [minDate, maxDate, blackoutDates.toString()]);

  const focusDate = () => {
    if (focusedDate) {
      let calendarButton;
      const calendarContainer = containerRef.current;

      if (isMonth) {
        calendarButton = calendarContainer?.querySelector(`[data-month="${focusedDate.month}"]`) as HTMLButtonElement;
      }

      if (isYear) {
        calendarButton = calendarContainer?.querySelector(`[data-date="${focusedDate.year}"]`) as HTMLButtonElement;
      }

      if (isDay) {
        calendarButton = calendarContainer?.querySelector(`[data-date="${focusedDate.date}"]`) as HTMLButtonElement;
      }

      calendarButton?.setAttribute('tabindex', '0');
      calendarButton?.focus();
    }
  };

  useDidUpdate(() => {
    focusDate();
  }, [focusedDate, selectBy]);

  useEffect(() => {
    // don't call on select if select date is empty
    if (!assertRange(selectedDate) && selectedDate !== initialDate) {
      onSelect?.(convertToExternalFormat(selectedDate, dateFormat));
    } else if (assertRange(selectedDate) && !selectedDate.every((i, ind) => i === initialDate[ind])) {
      onSelect?.(convertToExternalFormat(selectedDate, dateFormat));
    }
  }, [selectedDate]);

  const resetState = useCallback(() => {
    dispatch(
      reset({
        selected: initialDate,
        focusDay: initFocusedDate.date,
      })
    );
  }, [dispatch, initialDate, initFocusedDate]);

  const getNextRange = useCallback(curriedGetNextRange({ focusedRange }), [focusedRange]);

  const currentYear = months[0].year;

  const hasNext = () => {
    const maxYear = maxDate ? getYear(new Date(maxDate)) : Infinity;
    const yearRange = getDecadeRange(currentYear);

    if (isDay) return hasNextView(months, maxDate);
    if (isMonth) return currentYear < maxYear;
    if (isYear) return maxYear > yearRange[yearRange.length - 1];

    return true;
  };

  const hasPrev = () => {
    const minYear = minDate ? getYear(new Date(minDate)) : 0;
    const yearRange = getDecadeRange(currentYear);

    if (isDay) return hasPreviousView(months, minDate);
    if (isMonth) return currentYear > minYear;
    if (isYear) return minYear < yearRange[0];

    return true;
  };

  return {
    focusDate,
    selectBy,
    value: selectedDate ? convertToExternalFormat(selectedDate, dateFormat) : isRange ? ['', ''] : '',
    selectDate: (day: string) => {
      if (assertRange(selectedDate)) {
        dispatch(setRange(getNextRange(day, selectedDate), day));
      } else {
        dispatch(selectDate(day));
      }
    },
    months,
    dayNames: DAY_NAME_FORMATS[dayNameFormat],
    // function to bring calendar back to current selected date
    // or initial start date, if no value (eg when closing a flyout)
    reset: resetState,
    wrapperProps: {
      ref: containerRef,
      tabIndex: -1,
      'aria-label': 'Calendar day picker',
      role: 'application',
      onKeyDown: (e: React.KeyboardEvent) => {
        switch (e.which || e.key) {
          case KeyNames.Escape:
            if (typeof onEsc === 'function') onEsc();
            break;
          case KeyNames.Left:
            e.preventDefault();
            dispatch(prev());
            break;
          case KeyNames.Right:
            e.preventDefault();
            dispatch(next());
            break;
          case KeyNames.PageUp:
          case KeyNames.Up:
            e.preventDefault();
            dispatch(prevYear());
            break;
          case KeyNames.PageDown:
          case KeyNames.Down:
            e.preventDefault();
            dispatch(nextYear());
            break;
          default:
            break;
        }
      },
    },
    nextBtnProps: {
      disabled: !hasNext(),
      onClick: hasNext()
        ? () => {
            if (isDay) dispatch(next());
            if (isMonth) dispatch(nextYear());
            if (isYear) dispatch(nextDecade());
          }
        : undefined,
      onKeyDown: killEvent,
      'aria-label': `Next ${SELECT_BY_MAP[selectBy]}`,
    },
    prevBtnProps: {
      disabled: !hasPrev(),
      onClick: hasPrev()
        ? () => {
            if (isDay) dispatch(prev());
            if (isMonth) dispatch(prevYear());
            if (isYear) dispatch(prevDecade());
          }
        : undefined,
      onKeyDown: killEvent,
      'aria-label': `Previous ${SELECT_BY_MAP[selectBy]}`,
    },
    selectByBtnProps: {
      onClick: () => {
        if (isDay) dispatch(selectDateBy('month'));
        if (isMonth) dispatch(selectDateBy('year'));
      },
      onKeyDown: killEvent,
      'aria-label': 'Select Date By',
    },
    calendarContainerProps: {
      onClick: (e: React.MouseEvent<HTMLElement>) => {
        const target = e.target as HTMLButtonElement;
        const buttonIsDisabled = target.getAttribute('aria-disabled') === 'true';
        const date = target.dataset;

        if (isMonth) {
          if (!date?.month || buttonIsDisabled) return;
          dispatch(selectMonth(date.month));
        }

        if (isYear) {
          if (!date?.date || buttonIsDisabled) return;
          dispatch(selectYear(date.date));
        }

        if (isDay) {
          if (!date?.date || buttonIsDisabled) return;
          // set selected state so component can calculate the correct
          // day button props
          if (assertRange(selectedDate)) {
            dispatch(setRange(getNextRange(date.date, selectedDate), date.date));
          } else {
            dispatch(selectDate(date.date));
          }
        }
      },
      onKeyDown: (e: React.KeyboardEvent) => {
        switch (e.key) {
          case KeyNames.Left:
            handleDayEvent(e);
            if (isDay) dispatch(dayLeft());
            if (isMonth) dispatch(monthLeft());
            if (isYear) dispatch(yearLeft());
            break;
          case KeyNames.Right:
            handleDayEvent(e);
            if (isDay) dispatch(dayRight());
            if (isMonth) dispatch(monthRight());
            if (isYear) dispatch(yearRight());
            break;
          case KeyNames.Up:
            handleDayEvent(e);
            if (isYear) dispatch(yearUp());
            if (isMonth) dispatch(monthUp());
            if (isDay) dispatch(dayUp());
            break;
          case KeyNames.Down:
            handleDayEvent(e);
            if (isDay) dispatch(dayDown());
            if (isMonth) dispatch(monthDown());
            if (isYear) dispatch(yearDown());
            break;
        }
      },
    },
    getDayButtonProps: (day: CalendarDayType) => {
      const dayObject = createDateObject(day);
      const isSelected = assertRange(selectedDate)
        ? Boolean(selectedDate.find((val) => isSameDay(createDateObject(val), dayObject)))
        : selectedDate === day.date;

      // convert all day data props into data- attributes
      const dataAttrs: CalendarDayDataAttr = Object.entries(day).reduce(
        (obj, [key, value]) => ({
          ...obj,
          [`data-${kebabCase(key)}`]: value,
        }),
        { ['data-date']: day.date }
      );
      // only add range props if we have a complete range (2 values)
      if (assertRange(selectedDate) && selectedDate.every((date) => !!date)) {
        const rangeStart = createDateObject(selectedDate[0]);
        const rangeEnd = createDateObject(selectedDate[1]);
        dataAttrs['in-range'] = (
          isBefore(rangeStart, rangeEnd) ? isWithinInterval(dayObject, { start: rangeStart, end: rangeEnd }) : false
        ).toString();
        dataAttrs['range-start'] = isSameDay(dayObject, rangeStart).toString();
        dataAttrs['range-end'] = isSameDay(dayObject, rangeEnd).toString();
      }

      if (isSameDay(todayObject, dayObject)) {
        dataAttrs['data-is-today'] = true;
      }

      const isFocused = isSameDay(dayObject, createDateObject(focusedDate));

      return {
        ...dataAttrs,
        'aria-disabled': day.disabled,
        'aria-selected': isSelected,
        'aria-label': `${format(dayObject, 'EEEE MMMM do yyyy')}${day.disabled ? ' (unavailable/disabled)' : ''}`,
        tabIndex: isFocused ? 0 : -1,
        ...(day.disabled
          ? {
              // prevents focus from being shifted to a disabled day button
              // (because it's not actually disabled—we want keyboard focusability for all days)
              onMouseDown: killFocusOnDisabledSelect,
              onTouchStart: killFocusOnDisabledSelect,
            }
          : {}),
      };
    },
    getMonthButtonProps: (monthYear: string, monthIndex: number) => {
      let isSelected = false;
      if (selectedDate) {
        isSelected = assertRange(selectedDate)
          ? Boolean(
              selectedDate.find((val) => {
                if (val) {
                  return format(parseInternalDate(val), 'yyyy-MM') === monthYear;
                }
                return false;
              })
            )
          : format(parseInternalDate(selectedDate), 'yyyy-MM') === monthYear;
      }
      const isThisMonth = isTodayMonth(todayMonth, monthYear);
      const isFocused = format(parseInternalDate(focusedDate.date), 'yyyy-MM') === monthYear;
      const isDisabled = isMonthInRange({ monthYear, minDate, maxDate });
      return {
        'aria-disabled': !isDisabled,
        'data-date': monthYear,
        'data-month': monthIndex,
        'data-is-today': isThisMonth,
        'aria-selected': isSelected,
        'aria-label': `${format(parse(monthYear, 'yyyy-MM', new Date()), 'MMMM yyyy')}`,
        tabIndex: isFocused ? 0 : -1,
        ...(isDisabled
          ? {
              onMouseDown: killFocusOnDisabledSelect,
              onTouchStart: killFocusOnDisabledSelect,
            }
          : {}),
      };
    },
    getYearButtonProps: (year: string, yearIndex: number) => {
      let isSelected = false;
      if (selectedDate) {
        isSelected = assertRange(selectedDate)
          ? Boolean(
              selectedDate.find((val) => {
                if (val) {
                  return format(parseInternalDate(val), 'yyyy') === year;
                }
                return false;
              })
            )
          : format(parseInternalDate(selectedDate), 'yyyy') === year;
      }
      const isThisMonth = isTodayYear(todayYear, year);

      const isFocused = format(parseInternalDate(focusedDate.date), 'yyyy') === year;
      const isDisabled = isYearInRange({ year, minDate, maxDate });
      return {
        'aria-disabled': isDisabled,
        'data-date': year,
        'data-year': Number(year),
        'data-year-index': yearIndex,
        'data-is-today': isThisMonth,
        'aria-selected': isSelected,
        'aria-label': `${year}`,
        tabIndex: isFocused ? 0 : -1,
        ...(isDisabled
          ? {
              onMouseDown: killFocusOnDisabledSelect,
              onTouchStart: killFocusOnDisabledSelect,
            }
          : {}),
      };
    },
  };
}
