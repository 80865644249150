import { createProvider } from '@frontend/store';
import { config, DashboardConfigIds } from './dashboard-config';
import { generateConfigMap, type DashboardConfigMap } from './utils';

export type DashboardStore = {
  configMap: DashboardConfigMap;
  getWidthSpace: (id: DashboardConfigIds) => number;
};

export const configMap = generateConfigMap(config);

export const { Provider: DashboardStoreProvider, useStore: useDashboard } = createProvider<DashboardStore>()(
  (_set, get) => ({
    configMap,
    getWidthSpace: (id: DashboardConfigIds) => get().configMap[id],
  })
);
