import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { PopoverDialog } from '@frontend/design-system';
import { useTableFilter } from './table-filter-provider';

const flyoutStyles = css`
  padding: ${theme.spacing(3, 0, 0)};
  border-radius: ${theme.borderRadius.small};
  width: 366px; /*specific width to fit the dates in the input field of the date range component */
  margin-top: ${theme.spacing(2)};
  display: flex;
  flex-direction: column;
`;

type TableFilterFlyoutProps = {
  children: ReactNode;
};

export const TableFilterFlyout = ({ children }: TableFilterFlyoutProps) => {
  const { popoverProps } = useTableFilter();

  return (
    <PopoverDialog {...popoverProps.getDialogProps()} css={flyoutStyles}>
      {children}
    </PopoverDialog>
  );
};
