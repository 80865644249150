import { useState, useRef, HTMLProps, MouseEvent, KeyboardEvent, Dispatch, SetStateAction } from 'react';
import {
  Placement,
  flip,
  offset,
  safePolygon,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useHover,
  useInteractions,
  useListNavigation,
  useRole,
  useTypeahead,
  UseFloatingOptions,
  Strategy,
  FloatingContext,
  UseFloatingReturn,
  UseHoverProps,
  UseClickProps,
  UseRoleProps,
  UseDismissProps,
  UseListNavigationProps,
  UseTypeaheadProps,
} from '@floating-ui/react';
import { FieldChangeEvent } from '../forms';

export type ListRefs = {
  listItemsRef: React.MutableRefObject<(HTMLElement | null)[]>;
  listContentRef: React.MutableRefObject<(string | null)[]>;
};
useHover;
export type InteractionOptions = {
  hover: UseHoverProps;
  click: UseClickProps;
  role: UseRoleProps;
  dismiss: UseDismissProps;
  listNavigation: Partial<UseListNavigationProps>;
  typeahead: Partial<UseTypeaheadProps>;
};

export type MiddlewareOptions = {
  flip: Parameters<typeof flip>[0];
  offset: Parameters<typeof offset>[0];
  shift: Parameters<typeof shift>[0];
  size: Parameters<typeof size>[0];
};

type EventHandlers<T> = Pick<
  T,
  {
    [K in keyof T]: K extends `on${string}` ? K : never;
  }[keyof T]
>;

export type OverrideEvent<T extends HTMLElement, UseDefaultEvent extends boolean = false> = Omit<
  EventHandlers<HTMLProps<T>>,
  'onChange'
> & {
  onChange?: UseDefaultEvent extends true ? HTMLProps<T>['onChange'] : (e?: FieldChangeEvent) => void;
};

const isKeyboardEvent = (e: KeyboardEvent | MouseEvent): e is KeyboardEvent => {
  return 'key' in e;
};

export const conditionallyStopPropagation = (e: KeyboardEvent | MouseEvent) => {
  if (isKeyboardEvent(e)) {
    if (e.key === 'Enter' || e.key === ' ') {
      e.stopPropagation();
    }
  } else {
    e.stopPropagation();
  }
};

export const usePopover = <TriggerElement extends HTMLElement>({
  placement = 'right-start',
  middlewareOptions = {},
  interactionOptions = {},
  omitSizeMiddleware,
  transform = true,
  whileElementsMounted,
}: {
  placement?: Placement;
  middlewareOptions?: Partial<MiddlewareOptions>;
  interactionOptions?: Partial<InteractionOptions>;
  omitSizeMiddleware?: boolean;
  transform?: boolean;
  whileElementsMounted?: UseFloatingOptions['whileElementsMounted'];
} = {}): UsePopoverResponse<TriggerElement> => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const listItemsRef = useRef<Array<HTMLElement | null>>([]);
  const listContentRef = useRef<Array<string | null>>([]);

  const nodeId = useFloatingNodeId();

  const defaultMiddlewareOptions: MiddlewareOptions = {
    flip: {},
    offset: { mainAxis: 8, alignmentAxis: 8 },
    shift: {},
    size: {
      apply({ elements, availableHeight }) {
        Object.assign(elements.floating.style, {
          maxHeight: `${availableHeight}px`,
        });
      },
      padding: 8,
    },
  };

  const { x, y, strategy, refs, update, context } = useFloating<TriggerElement>({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      // don't try to merge these two options since their types can vary wildly
      offset(middlewareOptions.offset ?? defaultMiddlewareOptions.offset),
      flip({ ...defaultMiddlewareOptions.flip, ...middlewareOptions.flip }),
      ...(!omitSizeMiddleware ? [size({ ...defaultMiddlewareOptions.size, ...middlewareOptions.size })] : []),
      shift({ ...defaultMiddlewareOptions.shift, ...middlewareOptions.shift }),
    ],
    placement,
    nodeId,
    transform,
    whileElementsMounted,
  });

  const defaultInteractionOptions: InteractionOptions = {
    hover: {
      enabled: false,
      handleClose: safePolygon(),
    },
    click: {},
    role: { role: 'menu' },
    dismiss: {},
    listNavigation: {
      activeIndex,
      focusItemOnHover: false,
      listRef: listItemsRef,
      loop: true,
      onNavigate: setActiveIndex,
      openOnArrowKeyDown: true,
    },
    typeahead: {
      activeIndex,
      listRef: listContentRef,
      onMatch: setActiveIndex,
      resetMs: 500,
    },
  };

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    useHover(context, {
      ...defaultInteractionOptions.hover,
      ...interactionOptions.hover,
    }),
    useClick(context, {
      ...defaultInteractionOptions.click,
      ...interactionOptions.click,
    }),
    useRole(context, {
      ...defaultInteractionOptions.role,
      ...interactionOptions.role,
    }),
    useDismiss(context, {
      ...defaultInteractionOptions.dismiss,
      ...interactionOptions.dismiss,
    }),
    useListNavigation(context, {
      ...defaultInteractionOptions.listNavigation,
      ...interactionOptions.listNavigation,
      listRef: listItemsRef,
      activeIndex,
    }),
    useTypeahead(context, {
      ...defaultInteractionOptions.typeahead,
      ...interactionOptions.typeahead,
      enabled: !!interactionOptions.typeahead?.enabled && isOpen,
      listRef: listContentRef,
      activeIndex,
    }),
  ]);

  return {
    x,
    y,
    strategy,
    nodeId,
    context,
    update,
    activeIndex,
    setActiveIndex,
    setIsOpen,
    isOpen,
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
    refs,
    listItemsRef,
    listContentRef,
    getReferenceProps,
    getItemProps,
    getFloatingProps,
  };
};

type UsePopoverResponse<TriggerElement extends HTMLElement> = {
  x: number;
  y: number;
  strategy: Strategy;
  nodeId: string;
  context: FloatingContext<TriggerElement>;
  update: () => void;
  activeIndex: number | null;
  setActiveIndex: Dispatch<SetStateAction<number | null>>;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  open: () => void;
  close: () => void;
  refs: UseFloatingReturn<TriggerElement>['refs'];
  listItemsRef: React.MutableRefObject<(HTMLElement | null)[]>;
  listContentRef: React.MutableRefObject<(string | null)[]>;
  getReferenceProps: (userProps?: HTMLProps<Element> | undefined) => Record<string, unknown>;
  getItemProps: (userProps?: HTMLProps<HTMLElement> | undefined) => Record<string, unknown>;
  getFloatingProps: (userProps?: HTMLProps<HTMLElement> | undefined) => Record<string, unknown>;
};
