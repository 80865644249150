import { type ReactNode, createContext, useContext, useMemo, useState } from 'react';
import type { StoredReader } from '@frontend/payments-terminal-controller';

interface TerminalPaymentsProviderProps {
  children: ReactNode;
}

type TerminalPaymentsContextState = {
  selectedReader: StoredReader | undefined;
  setSelectedReader: (reader: StoredReader | undefined) => void;
};

const TerminalPaymentsContext = createContext<TerminalPaymentsContextState | undefined>(undefined);

export const SelectedReaderProvider = ({ children }: TerminalPaymentsProviderProps) => {
  const [selectedReader, setSelectedReader] = useState<StoredReader | undefined>();
  const value = useMemo(() => ({ selectedReader, setSelectedReader }), [selectedReader]);

  return <TerminalPaymentsContext.Provider value={value}>{children}</TerminalPaymentsContext.Provider>;
};

export const useSelectedReader = () => {
  const terminalPaymentsContext = useContext(TerminalPaymentsContext);

  if (!terminalPaymentsContext) {
    throw new Error('useTerminalPaymentsContext must be used within a TerminalPaymentsProvider');
  }

  return terminalPaymentsContext;
};
