import { css } from '@emotion/react';
import { WeaveTheme } from '@frontend/theme';
import { WeaveTheme as WeaveThemeOriginal } from '@frontend/theme-original';
export type FontColorType =
  | 'primary'
  | 'default'
  | 'light'
  | 'subdued'
  | 'disabled'
  | 'white'
  | 'error'
  | 'success'
  | 'warn'
  | 'inherit';

// Maps font properties to a theme color
export const getFontColorStyle = (color: FontColorType, theme: WeaveTheme | WeaveThemeOriginal) => {
  return css`
    color: ${theme.font.colors[color] || theme.font.colors.default};
  `;
};
