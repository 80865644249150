import { theme } from '@frontend/theme';
import { css } from '@emotion/react';
import { SecondaryButton } from '../../buttons';
import { useStepperContext } from '../provider/stepper.provider';
import { useStepperCardContext } from '../provider/stepper-card.provider';
import { ReactNode } from 'react';

export type StepperPreviousButtonProps = {
  onClick?: () => void;
  trackingId?: string;
  children: ReactNode | string;
};

export const StepperPreviousButton = ({
  onClick,
  trackingId = '',
  children = 'Previous',
  ...rest
}: Partial<StepperPreviousButtonProps>) => {
  const { previousFunction } = useStepperContext();
  const { step } = useStepperCardContext();

  const previous = () => {
    onClick?.();
    previousFunction?.(step);
  };

  return (
    <>
      {step !== 1 && (
        <SecondaryButton
          className='step-button previous-step-button'
          trackingId={trackingId}
          css={css`
            margin-left: ${theme.spacing(2)};
          `}
          onClick={previous}
          {...rest}
        >
          {children}
        </SecondaryButton>
      )}
    </>
  );
};
