import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import type { TextStyleProp } from './types';
import { AlertType } from './types';
import { WeaveThemeValues } from '../../component-theme';

export type CloseButtonThemeValues = {
  backgroundColor: WeaveThemeValues['Color'];
  boxShadow: WeaveThemeValues['BoxShadow'];
};

export type AlertActionContainerThemeValues = {
  spacing: WeaveThemeValues['Spacing'];
  borderLeftColor: WeaveThemeValues['Color'];
};

export type GetAlertTextThemeValues = {
  spacing: WeaveThemeValues['Spacing'];
  borderLeftColor: WeaveThemeValues['Color'];
  borderRadius: WeaveThemeValues['BorderRadius'];
};

export type DivStyleThemeValues = {
  backgroundColor: WeaveThemeValues['Color'];
  borderRadius: WeaveThemeValues['BorderRadius'];
  boxShadow: WeaveThemeValues['BoxShadow'];
  zIndex: WeaveThemeValues['ZIndex'];
};

const colorMapThemeOriginal = {
  error: 'error',
  info: 'weaveBlue',
  success: 'success',
  warning: 'warning',
};

const getBorderLeftColor = (type: AlertType) => {
  if (type === 'error') return theme.colors.status.critical;
  if (type === 'info') return theme.colors.primary50;
  if (type === 'success') return theme.colors.status.success;
  else return theme.colors.status.warning;
};

export const closeButtonTheme: CloseButtonThemeValues = {
  backgroundColor: theme.colors.white,
  boxShadow: theme.shadows.heavy,
};

export const closeButtonThemeOriginal: CloseButtonThemeValues = {
  backgroundColor: themeOriginal.colors.white,
  boxShadow: themeOriginal.shadows.heavy,
};

export const alertActionContainerTheme: AlertActionContainerThemeValues = {
  spacing: theme.spacing,
  borderLeftColor: theme.colors.neutral50,
};

export const alertActionContainerThemeOriginal: AlertActionContainerThemeValues = {
  spacing: themeOriginal.spacing,
  borderLeftColor: themeOriginal.colors.gray400,
};

export const getAlertTextTheme = ({ type }: TextStyleProp) => ({
  spacing: theme.spacing,
  borderLeftColor: getBorderLeftColor(type),
  borderRadius: theme.borderRadius.medium,
});

export const getAlertTextThemeOriginal = ({ type }: TextStyleProp) => ({
  spacing: themeOriginal.spacing,
  borderLeftColor: themeOriginal.colors[colorMapThemeOriginal[type]],
  borderRadius: themeOriginal.borderRadius.medium,
});

export const containerTheme: DivStyleThemeValues = {
  backgroundColor: theme.colors.white,
  borderRadius: theme.borderRadius.medium,
  boxShadow: theme.shadows.heavy,
  zIndex: theme.zIndex.low,
};

export const containerThemeOriginal: DivStyleThemeValues = {
  backgroundColor: themeOriginal.colors.white,
  borderRadius: themeOriginal.borderRadius.medium,
  boxShadow: themeOriginal.shadows.heavy,
  zIndex: themeOriginal.zIndex.low,
};
