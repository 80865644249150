import React from 'react';
import { css, Global } from '@emotion/react';
import { useSlideFadeTransition, useThemeValues } from '../../../hooks';
import { config, useSpring } from 'react-spring';
import type { ModalBoxProps } from '../atoms';
import { ModalBackdrop, ModalBox } from '../atoms';
import type { ModalControlModalProps, ModalProviderProps } from '../provider';
import { ModalProvider } from '../provider';

const centeredModalClassName = 'wv-modal-container';

export type CenteredModalProps = ModalControlModalProps &
  Pick<ModalProviderProps, 'disableCloseOnEscape' | 'mountTarget' | 'prepend'> &
  ModalBoxProps & {
    disableCloseOnOverlayClick?: boolean;
  };

export const CenteredModal = ({
  children,
  disableCloseOnEscape,
  disableCloseOnOverlayClick,
  mountTarget,
  onClose,
  prepend,
  show,
  ...rest
}: CenteredModalProps) => {
  const modalTransitions = useSlideFadeTransition({
    active: show,
    direction: 'up',
    distance: '50%',
    config: { ...config.wobbly, friction: 24 },
  });
  const backdropProps = useSpring({
    opacity: show ? 1 : 0,
  });
  const { zIndex } = useThemeValues();
  return (
    <React.Fragment>
      <Global
        styles={css`
          .wv-modal-container {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            z-index: ${zIndex.modals};

            :focus {
              outline: none;
            }
          }
        `}
      />
      {modalTransitions.map(
        ({ item, key, props }) =>
          item && (
            <ModalProvider
              key={key}
              className={centeredModalClassName}
              onClose={onClose}
              timeout={500}
              disableCloseOnEscape={disableCloseOnEscape}
              mountTarget={mountTarget}
              prepend={prepend}
            >
              <ModalBackdrop
                onClick={disableCloseOnOverlayClick ? undefined : onClose}
                style={backdropProps}
                omitOffset={!!mountTarget}
              />
              <ModalBox {...rest} style={props}>
                {children}
              </ModalBox>
            </ModalProvider>
          )
      )}
    </React.Fragment>
  );
};
