import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

const styles = {
  noMargin: css`
    margin: 0;
  `,
  largeInfo: css`
    font-size: ${theme.font.size.h2};
    font-weight: ${theme.font.weight.bold};
  `,
  nonTextInfoContainer: css`
    height: calc(${theme.font.size.h2} * ${theme.font.lineHeight});
    display: flex;
    align-items: center;
  `,
};

export const LargeInfo = ({ title, info }: { title: string; info: string | undefined | ReactNode }) => {
  return (
    <div>
      <Text color='subdued' weight='semibold' size='medium' css={styles.noMargin}>
        {title}
      </Text>
      {typeof info === 'string' ? (
        <Text css={[styles.noMargin, styles.largeInfo]}>{info ?? ''}</Text>
      ) : (
        <div css={[styles.noMargin, styles.nonTextInfoContainer]}>{info}</div>
      )}
    </div>
  );
};
