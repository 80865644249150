import { forwardRef, type ComponentProps } from 'react';
import { css } from '@emotion/react';
import { useThemeValues } from '../../../../hooks';
import { styles } from '../../../../styles';
import type { TextareaProps } from '../../atoms';
import { AutoGrowTextarea, Textarea } from '../../atoms';
import type { BasicFormFieldProps, ExtraFieldProps } from '../../layouts';
import { FieldLayout } from '../../layouts';

type TextareaFieldProps = BasicFormFieldProps<'text'> &
  Pick<ExtraFieldProps, 'containerCss'> &
  TextareaProps & {
    autoGrow?: [number, number];
    height?: number;
  };

export const TextareaField = forwardRef<HTMLDivElement, TextareaFieldProps>(
  ({ autoGrow, height = 120, ...rest }, ref) => {
    const shouldAutogrow = Array.isArray(autoGrow);
    const theme = useThemeValues();
    return (
      <FieldLayout
        css={css`
          height: ${shouldAutogrow ? 'auto' : height + 'px'};
          padding: ${theme.spacing(1, 1, 1, 2)};

          textarea {
            height: 100%;
            padding-right: ${theme.spacing(1)};
            resize: none;
            ${styles.smallScrollbar(theme)}
          }
        `}
        {...rest}
        //TODO: this might be able to be improved. But this type cast just makes sure the field prop is any of the correct field types
        field={(shouldAutogrow ? AutoGrowTextarea : Textarea) as ComponentProps<typeof FieldLayout>['field']}
        fieldComponentProps={shouldAutogrow ? { minRows: autoGrow![0], maxRows: autoGrow![1] } : undefined}
        ref={ref}
      />
    );
  }
);
