/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const EmojiIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M12,22.25 C6.33908131,22.25 1.75,17.6609187 1.75,12 C1.75,6.33908131 6.33908131,1.75 12,1.75 C17.6609187,1.75 22.25,6.33908131 22.25,12 C22.25,17.6609187 17.6609187,22.25 12,22.25 Z M12,19.75 C16.2802068,19.75 19.75,16.2802068 19.75,12 C19.75,7.71979319 16.2802068,4.25 12,4.25 C7.71979319,4.25 4.25,7.71979319 4.25,12 C4.25,16.2802068 7.71979319,19.75 12,19.75 Z M9.5,10.5 C8.80964406,10.5 8.25,9.94035594 8.25,9.25 C8.25,8.55964406 8.80964406,8 9.5,8 C10.1903559,8 10.75,8.55964406 10.75,9.25 C10.75,9.94035594 10.1903559,10.5 9.5,10.5 Z M14.5,10.5 C13.8096441,10.5 13.25,9.94035594 13.25,9.25 C13.25,8.55964406 13.8096441,8 14.5,8 C15.1903559,8 15.75,8.55964406 15.75,9.25 C15.75,9.94035594 15.1903559,10.5 14.5,10.5 Z M6.94607287,14.6721143 C6.5748744,14.0900464 6.74581784,13.3172713 7.32788572,12.9460729 C7.9099536,12.5748744 8.68272866,12.7458178 9.05392713,13.3278857 C9.70319828,14.3459931 10.6349964,14.8306885 12,14.8306885 C13.3650036,14.8306885 14.2968017,14.3459931 14.9460729,13.3278857 C15.3172713,12.7458178 16.0900464,12.5748744 16.6721143,12.9460729 C17.2541822,13.3172713 17.4251256,14.0900464 17.0539271,14.6721143 C15.9339926,16.4282582 14.199124,17.3306885 12,17.3306885 C9.80087596,17.3306885 8.06600745,16.4282582 6.94607287,14.6721143 Z'
    />
  </Icon>
);
