import { css } from '@emotion/react';
import { useThemeValues } from '../../../hooks';
import { styles } from '../../../styles';

type Props = React.HtmlHTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
  className?: string;
};

export const FullscreenModalBody = ({ children, ...rest }: Props) => {
  const theme = useThemeValues();
  return (
    <div
      css={[
        css`
          flex: 1;
          overflow-y: auto;
          padding: ${theme.spacing(2)};
          text-align: left;
        `,
        styles.smallScrollbar(theme),
      ]}
      {...rest}
    >
      {children}
    </div>
  );
};
