import { ComponentPropsWithoutRef } from 'react';
import { NakedButton } from '../../../naked';
import { mergeClasses } from '../../../helpers';
import { useCalendarState } from '../provider';
import type { BaseAtomProps } from './calendar-atom.types';
import { getDecadeRange } from '../provider/reducer';

type Props = BaseAtomProps & {
  year: number;
};

type CalendarYearsProps = Props & Omit<ComponentPropsWithoutRef<'div'>, keyof Props>;

export const CalendarYears = ({ className, year: focusedYear, ...rest }: CalendarYearsProps) => {
  const { getYearButtonProps } = useCalendarState();
  return (
    <div className={mergeClasses('calendar__months__years', className)} {...rest}>
      {getDecadeRange(focusedYear).map((year, index) => (
        <NakedButton key={year} className='cal__months' type='button' {...getYearButtonProps(String(year), index)}>
          {year}
        </NakedButton>
      ))}
    </div>
  );
};
