import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../../component-theme/theme-types';
import { buttonTheme, originalButtonTheme } from '../button-theme';

export type MultiActionButtonThemeValues = {
  backgroundColor: WeaveThemeValues['Color'];
  spacing: WeaveThemeValues['Spacing'];
  hoverBackground: WeaveThemeValues['Color'];
  pressedBackground: WeaveThemeValues['Color'];
  disabledBackground: WeaveThemeValues['Color'];
  disabledBorder: WeaveThemeValues['Color'];
  heights: {
    large: 40 | 48;
    small: 40 | 32;
    tiny: 18;
  };
  radius: { large: 4 | 24; small: 4 | 20; tiny: 4 | 20 };
};

export const multiActionButtonTheme: MultiActionButtonThemeValues = {
  backgroundColor: buttonTheme.backgroundColor,
  spacing: theme.spacing,
  hoverBackground: buttonTheme.hoverColor,
  pressedBackground: buttonTheme.pressedColor,
  disabledBackground: buttonTheme.disabled.backgroundColor,
  disabledBorder: theme.colors.neutral30,
  heights: {
    large: 40,
    small: 32,
    tiny: 18,
  },
  radius: { large: 4, small: 4, tiny: 4 },
};

export const multiActionButtonThemeOriginal: MultiActionButtonThemeValues = {
  backgroundColor: originalButtonTheme.backgroundColor,
  spacing: themeOriginal.spacing,
  hoverBackground: originalButtonTheme.hoverColor,
  pressedBackground: originalButtonTheme.pressedColor,
  disabledBackground: originalButtonTheme.disabled.backgroundColor,
  disabledBorder: themeOriginal.colors.white,
  heights: {
    large: 48,
    small: 40,
    tiny: 18,
  },
  radius: { large: 24, small: 20, tiny: 20 },
};
