import { FC, PropsWithChildren } from 'react';
import { Interpolation, Theme, css } from '@emotion/react';
import { BgNoiseImg } from '@frontend/assets';
import { theme } from '@frontend/theme';
import { WeaveLogoIcon } from '@frontend/design-system';

interface GradientPageProps {
  pageStyles?: Interpolation<Theme>;
  showWeaveIconAtBottom?: boolean;
  bgColor?: string;
  gradientColor1?: string;
  gradientColor2?: string;
}
export const GradientPage: FC<PropsWithChildren<GradientPageProps>> = ({
  children,
  pageStyles,
  showWeaveIconAtBottom,
  bgColor = theme.colors.secondary.seaweed20,
  gradientColor1 = theme.colors.secondary.seaweed50,
  gradientColor2 = theme.colors.secondary.eggplant10,
}) => {
  return (
    <div css={[rootStyles, pageStyles]}>
      <div css={gradientNoiseBGStyles(bgColor)}>
        <div css={gradientShape1Styles(gradientColor1, bgColor)} />
        <div css={gradientShape2Styles(gradientColor2, bgColor)} />
      </div>
      {showWeaveIconAtBottom && <WeaveLogoIcon size={32} css={weaveIconAtBottomStyles} />}
      {children}
    </div>
  );
};

const rootStyles = css({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  width: '100%',
  height: '100%',
});

const gradientNoiseBGStyles = (bgColor: string) =>
  css({
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundImage: `url("${BgNoiseImg}")`,
    backgroundBlendMode: 'overlay',
    overflow: 'hidden',
    backgroundColor: bgColor,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    zIndex: -1,
  });

const gradientShape1Styles = (gradientColor: string, baseColor: string) =>
  css({
    position: 'absolute',
    width: '130%',
    height: '80%',
    left: 0,
    top: 0,
    background: `radial-gradient(ellipse at 50% 50%, ${gradientColor} 0%, ${baseColor}00 60%)`,
    animation: 'moveAround1 7s ease infinite',
    transformOrigin: '50% 50%',
    mixBlendMode: 'hard-light',
    ['@keyframes moveAround1']: {
      '0%': {
        transform: 'rotate(5deg) translate(0, 0)',
        scale: '1',
      },
      '50%': {
        transform: 'rotate(10deg) translate(-25%, -10%)',
        scale: '0.8',
      },
      '100%': {
        transform: 'rotate(5deg) translate(0, 0)',
        scale: '1',
      },
    },
  });

const gradientShape2Styles = (gradientColor: string, baseColor: string) =>
  css({
    position: 'absolute',
    width: '80%',
    height: '100%',
    top: '15%',
    left: '10%',
    background: `radial-gradient(ellipse at 50% 50%, ${gradientColor} 0%, ${baseColor}00 70%)`,
    mixBlendMode: 'color',
    transformOrigin: '50% 50%',
    animation: 'moveAround2 12s 3s ease infinite',
    ['@keyframes moveAround2']: {
      '0%': {
        transform: 'rotate(10deg) translate(50%, 0)',
        scale: '1',
      },
      '50%': {
        transform: 'rotate(15deg) translate(0%, 10%)',
        scale: '1.2',
      },
      '100%': {
        transform: 'rotate(10deg) translate(50%, 0)',
        scale: '1',
      },
    },
  });

const weaveIconAtBottomStyles = css({
  position: 'absolute',
  bottom: theme.spacing(2),
  left: '50%',
  transform: 'translateX(-50%)',
  color: theme.colors.neutral30,
  zIndex: -1,
});
