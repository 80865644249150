import { css } from '@emotion/react';
import { SuccessImg } from '@frontend/assets';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Heading, PrimaryButton, Text } from '@frontend/design-system';
import { formatCentsToCurrency } from '../../../utils';

type RefundSuccessProps = {
  amountInCents: number;
  cardholderName: string;
  last4?: string;
  onOkay: () => void;
};

const sidePadding = css`
  padding: ${theme.spacing(0, 3)};
`;

export const RefundSuccess = ({ cardholderName, last4, amountInCents, onOkay }: RefundSuccessProps) => {
  const { t } = useTranslation('payments');
  return (
    <>
      <img
        src={SuccessImg}
        alt={t('Success!')}
        css={css`
          margin: ${theme.spacing(3, 0)};
          align-self: center;
          width: 200px;
          height: auto;
        `}
      />
      <Heading level={2} textAlign='center' css={{ margin: 0 }}>
        {t('Refund successfully submitted!')}
      </Heading>
      <Text
        textAlign='center'
        css={[
          css`
            margin: ${theme.spacing(1, 0, 0)};
          `,
          sidePadding,
        ]}
      >
        {t('A refund of {{amount}} has been submitted for {{cardholderName}}’s card ending in ****{{last4}}.', {
          amount: formatCentsToCurrency(amountInCents),
          cardholderName,
          last4,
        })}
      </Text>
      <Text
        size='medium'
        color='light'
        textAlign='center'
        css={[
          css`
            margin: ${theme.spacing(2, 0, 0)};
          `,
          sidePadding,
        ]}
      >
        {t('It may take 5-10 days for the refund to appear on the cardholder’s statement.')}
      </Text>
      <div
        css={[
          css`
            margin: ${theme.spacing(4, 0, 0)};
          `,
          sidePadding,
        ]}
      >
        <PrimaryButton onClick={onOkay}>{t('Okay')}</PrimaryButton>
      </div>
    </>
  );
};
