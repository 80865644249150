import { Heading } from '../../../heading';
import { useVerticalSlideModalContext } from '../vertical-slide-modal';
import { IconButton } from '../../../icon-button/icon-button.component';
import { XIcon } from '../../../../icon/icons/x';
import type { IconProps } from '../../../../icon';
import { css } from '@emotion/react';
import { useThemeValues } from '../../../../hooks';

type Props = {
  children?: React.ReactNode;
  textAlign?: 'left' | 'center';
  hideCloseButton?: boolean;
  closeIconColor?: IconProps['color'];
  closeButtonStyle?: IconProps['css'];
};

type VerticalSlideModalHeaderProps = Omit<React.HTMLAttributes<HTMLElement>, keyof Props> & Props;

export const VerticalSlideModalHeader = ({
  children,
  hideCloseButton,
  textAlign = 'left',
  closeIconColor = 'default',
  closeButtonStyle,
  ...rest
}: VerticalSlideModalHeaderProps) => {
  const { labelId, close } = useVerticalSlideModalContext();
  const { spacing } = useThemeValues();

  return (
    <header
      css={css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: ${spacing(2)};
      `}
      {...rest}
    >
      {typeof children === 'string' ? (
        <Heading
          style={{
            margin: 0,
          }}
          level={2}
          textAlign={textAlign}
          id={labelId}
        >
          {children}
        </Heading>
      ) : (
        children
      )}
      {!hideCloseButton && (
        <IconButton css={closeButtonStyle} label='close' onClick={close}>
          <XIcon color={closeIconColor} />
        </IconButton>
      )}
    </header>
  );
};
