import { mergeClasses } from '../../../helpers';
import { CalendarWeek } from './calendar-week';
import type { CalendarWeekType } from '../calendar-types';
import type { BaseAtomProps } from './calendar-atom.types';

type CalendarMonthProps = BaseAtomProps & {
  weeks: CalendarWeekType[];
};

export const CalendarMonth = ({ className, weeks, ...rest }: CalendarMonthProps) => (
  <div className={mergeClasses('cal__month', className)} {...rest}>
    {weeks.map((days, i) => (
      <CalendarWeek key={`week-${i}`} days={days} />
    ))}
  </div>
);
