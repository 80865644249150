import React, { useRef } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { useSliderContext } from '../provider';

export const Track = () => {
  const { min, max, isRange, value, getPercentage, updateValue, step } = useSliderContext();
  const trackRef = useRef<HTMLDivElement>(null);

  const handleMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();
    const trackRect = trackRef.current?.getBoundingClientRect();
    if (trackRect) {
      const position = event.clientX - trackRect.left;
      const percentage = position / trackRect.width;
      const newValue = percentage * (max - min) + min;
      updateValue(Math.round(newValue / step) * step);
    }
  };

  return (
    <>
      <div css={trackStyles} ref={trackRef} onMouseDown={handleMouseDown} />
      <div
        css={rangeStyles}
        style={{
          left: isRange && Array.isArray(value) ? `${getPercentage(value[0])}%` : '0%',
          right:
            isRange && Array.isArray(value)
              ? `${100 - getPercentage(value[1])}%`
              : `${100 - getPercentage(value as number)}%`,
        }}
      />
    </>
  );
};

const trackStyles = css`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 4px;
  transform: translateY(-50%);
  border-radius: ${theme.borderRadius.small};
  background: ${theme.colors.neutral20};
  cursor: pointer;
`;

const rangeStyles = css`
  position: absolute;
  top: 50%;
  height: 4px;
  transform: translateY(-50%);
  pointer-events: none;
  border-radius: ${theme.borderRadius.medium};
  background: ${theme.colors.primary50};
`;
