import { SerializedStyles } from '@emotion/react';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { EmailIcon, Heading, PhoneIconSmall, Text } from '@frontend/design-system';
import * as styles from '../../../styles/weave-pay.styles';
import { IconTextWrapper } from './icon-text-wrapper';

interface LocationInformationProps {
  name: string;
  email: string;
  phoneNumber: string;
  style?: SerializedStyles | SerializedStyles[];
}

export const LocationInformation = ({ name, email, phoneNumber, style }: LocationInformationProps) => {
  return (
    <section css={style}>
      <Heading level={2} css={styles.noMargin}>
        {name}
      </Heading>
      {email && (
        <IconTextWrapper>
          <EmailIcon size={16} />
          <Text css={styles.noMargin}>{email}</Text>
        </IconTextWrapper>
      )}
      {phoneNumber && (
        <IconTextWrapper>
          <PhoneIconSmall />
          <Text css={styles.noMargin}>{formatPhoneNumber(phoneNumber, true, true)}</Text>
        </IconTextWrapper>
      )}
    </section>
  );
};
