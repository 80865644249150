import { PickRequired } from '@frontend/types';
import React, { createContext, useContext } from 'react';
import { mergeClasses } from '../../../helpers';
import { useCalendar } from './use-calendar';

type CalendarContextState = Pick<
  ReturnType<typeof useCalendar>,
  | 'calendarContainerProps'
  | 'nextBtnProps'
  | 'prevBtnProps'
  | 'selectByBtnProps'
  | 'months'
  | 'getDayButtonProps'
  | 'getMonthButtonProps'
  | 'getYearButtonProps'
  | 'dayNames'
  | 'selectBy'
>;

const CalendarContext = createContext<CalendarContextState | undefined>(undefined);

export type CalendarProviderProps = PickRequired<
  ReturnType<typeof useCalendar>,
  | 'wrapperProps'
  | 'dayNames'
  | 'calendarContainerProps'
  | 'months'
  | 'nextBtnProps'
  | 'getDayButtonProps'
  | 'getMonthButtonProps'
  | 'getYearButtonProps'
  | 'selectByBtnProps'
  | 'prevBtnProps'
  | 'selectBy'
> & {
  children: React.ReactNode;
  className?: string;
};

export const CalendarProvider = ({
  children,
  className,
  focusDate,
  reset,
  wrapperProps,
  ...rest
}: CalendarProviderProps) => (
  <CalendarContext.Provider value={rest}>
    <div className={mergeClasses('cal', className)} {...wrapperProps}>
      {children}
    </div>
  </CalendarContext.Provider>
);

export const useCalendarState = () => {
  const state = useContext(CalendarContext);
  if (state === undefined) {
    throw new Error('Must use useCalendarState inside of a Calendar provider');
  }
  return state;
};
