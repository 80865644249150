import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { TextLink } from '../../text-link';
import { useStepperContext } from '../provider/stepper.provider';
import { useStepperCardContext } from '../provider/stepper-card.provider';

export type StepperClearButtonProps = {
  skipTo?: number;
  trackingId?: string;
  children: string;
};

export const StepperSkipButton = ({
  skipTo,
  trackingId = '',
  children = 'Skip',
  ...rest
}: Partial<StepperClearButtonProps>) => {
  const { skipFunction, nextFunction } = useStepperContext();
  const { step } = useStepperCardContext();

  const skip = () => {
    if (skipTo) {
      skipFunction(skipTo, step);
    } else {
      nextFunction(step);
    }
  };

  return (
    <TextLink
      className='step-button skip-step'
      trackingId={trackingId}
      css={() => css`
        margin: ${theme.spacing(0, 1)};
      `}
      onClick={() => skip()}
      {...rest}
    >
      {children}
    </TextLink>
  );
};
