import { ReactNode, forwardRef, type ComponentProps } from 'react';
import { BasicFormFieldProps, ExtraFieldProps, FieldActionProps, FieldLayoutWithAction } from '../../layouts';
import { NumberInput } from '../../atoms';

type Props = BasicFormFieldProps<'number'> & ExtraFieldProps & FieldActionProps & { endAdornment?: ReactNode };

export const NumberField = forwardRef<HTMLDivElement, Props>((props, ref) => (
  <FieldLayoutWithAction
    {...props}
    //TODO: this might be able to be improved. But this type cast just makes sure the field prop is any of the correct field types
    field={NumberInput as ComponentProps<typeof FieldLayoutWithAction>['field']}
    ref={ref}
  />
));
