import { MouseEvent, forwardRef } from 'react';
import { AssociatedContactsButton as StandaloneAssociatedContactsButton } from '../associated-contacts-button';
import { useThreadHeaderContext } from '../thread-header';

type AssociatedContactsButtonProps = {
  onClick?: (newOpenState: boolean, e: MouseEvent) => void;
};

export const AssociatedContactsButton = forwardRef<HTMLButtonElement, AssociatedContactsButtonProps>(
  ({ onClick }, ref) => {
    const { associatedContacts, setAssociatedContactsDrawerIsOpen } = useThreadHeaderContext();
    const count = associatedContacts?.length ?? 0;

    if (!count) return null;

    return (
      <StandaloneAssociatedContactsButton
        count={count}
        onClick={(e) => {
          e.stopPropagation();
          setAssociatedContactsDrawerIsOpen((prev) => {
            onClick?.(!prev, e);
            return !prev;
          });
        }}
        ref={ref}
      />
    );
  }
);
AssociatedContactsButton.displayName = 'AssociatedContactsButtonAtom';
