import { Checkbox } from '../../../forms/atoms';

import { BoolFieldLayout } from '../../layouts';
import type { BasicBoolFieldProps } from '../../layouts';

type Props = BasicBoolFieldProps<'checkbox'> & {
  indeterminate?: boolean;
};

export const CheckboxField = (props: Props) => {
  return <BoolFieldLayout {...props} field={Checkbox} />;
};
