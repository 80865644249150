import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../../../component-theme/theme-types';

export type TrailingColumnThemeValues = {
  spacing: WeaveThemeValues['Spacing'];
  fontSize: WeaveThemeValues['FontSize'];
  newTheme: boolean;
  hoverColor: WeaveThemeValues['Color'] | undefined;
};

export const trailingColumnTheme: TrailingColumnThemeValues = {
  spacing: theme.spacing,
  fontSize: theme.fontSize,
  newTheme: true,
  hoverColor: theme.colors.neutral20,
};

export const trailingColumnThemeOriginal: TrailingColumnThemeValues = {
  spacing: themeOriginal.spacing,
  fontSize: themeOriginal.fontSize,
  newTheme: false,
  hoverColor: undefined,
};
