import { SerializedStyles, css } from '@emotion/react';
import { theme } from '@frontend/theme';

const DEFAULT_WIDTH = 686;
const DEFAULT_HEIGHT = 485;

const videoContainer = (width: number, height: number) => css`
  border: 1px solid ${theme.colors.neutral30};
  position: relative;
  width: ${width}px;
  height: ${height}px;
  overflow-y: hidden;
`;

const video = css`
  width: 100%;
  height: 100%;
  min-height: 485px;
  overflow-y: hidden;
`;

interface Props {
  src: string;
  title?: string;
  width?: number;
  height?: number;
  iframeStyles?: SerializedStyles;
}

export const VideoFrame = ({
  src,
  height = DEFAULT_HEIGHT,
  width = DEFAULT_WIDTH,
  title = 'Admin Controls and User Management',
  iframeStyles,
  ...rest
}: Props) => {
  return (
    <div css={videoContainer(width, height)} {...rest}>
      <iframe
        css={[video, iframeStyles]}
        src={src}
        title={title}
        data-allowtransparency='true'
        scrolling='no'
        data-allowfullscreen=''
        data-mozallowfullscreen=''
        data-webkitallowfullscreen=''
        data-oallowfullscreen=''
        data-msallowfullscreen=''
      />
    </div>
  );
};
