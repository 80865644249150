type ThemeNode = Record<string, any>;

/**
 * Takes 2 theme objects and does a deep merge. If there are any matching types
 * on any of the keys, this we will keep the original theme value. This is to
 * keep ensure all theme keys are compatible with the original theme.
 *
 * Any keys that match the same types, we take the new theme values.
 *
 * @param themeOriginal The original theme
 * @param newTheme The new theme to merge to themeOriginal
 * @returns A merged theme
 */
export function mergeThemes<T1 extends ThemeNode, T2 extends ThemeNode>(themeOriginal: T1, newTheme: T2) {
  const recursiveMerger = (theme1: ThemeNode, theme2: ThemeNode) => {
    return Object.entries(theme2 ?? {}).reduce((mergedTheme, [key2, value2]): ThemeNode => {
      // key2 is not in theme1
      if (theme1?.[key2] === undefined) {
        return {
          ...mergedTheme,
          [key2]: value2,
        };
      }
      // Need to keep the them backwards compatible with the old theme,
      // take the theme2 value here
      else if (typeof theme1[key2] === typeof value2) {
        if (typeof value2 === 'object') {
          if (Array.isArray(value2)) {
            console.warn('Got matching arrays, ignoring the theme key', key2);
            return mergedTheme;
          }
          return {
            ...mergedTheme,
            [key2]: recursiveMerger(theme1[key2], value2),
          };
        } else {
          return {
            ...mergedTheme,
            [key2]: value2,
          };
        }
      }
      // key2 is in theme1, types are different, keep theme1 value
      else {
        return mergedTheme;
      }
    }, theme1);
  };

  return recursiveMerger(themeOriginal, newTheme) as MergeThemes<T1, T2>;
}

type MatchingKeys<T1 extends ThemeNode, T2 extends ThemeNode> = keyof T1 & keyof T2;
type KeysInT1<T1 extends ThemeNode, T2 extends ThemeNode> = Exclude<keyof T1, MatchingKeys<T1, T2>>;
type KeysInT2<T1 extends ThemeNode, T2 extends ThemeNode> = Exclude<keyof T2, MatchingKeys<T1, T2>>;

type MergeThemes<T1 extends ThemeNode, T2 extends ThemeNode> = {
  [KMatch in MatchingKeys<T1, T2>]: TypeOf<T1[KMatch]> extends TypeOf<T2[KMatch]>
    ? T1[KMatch] extends ThemeNode
      ? T1[KMatch] extends Array<any> | ((...args: any[]) => any)
        ? T1[KMatch]
        : MergeThemes<T1[KMatch], T2[KMatch]>
      : T2[KMatch]
    : T1[KMatch];
} & {
  [KT1 in KeysInT1<T1, T2>]: T1[KT1];
} & {
  [KT2 in KeysInT2<T1, T2>]: T2[KT2];
};

// Need this helper to deal with the const types
type TypeOf<T> = T extends string
  ? string
  : T extends number
  ? number
  : T extends ThemeNode
  ? ThemeNode
  : T extends boolean
  ? boolean
  : T extends Array<any>
  ? Array<any>
  : T extends (...args: any[]) => any
  ? (...args: any[]) => any
  : T extends undefined
  ? undefined
  : T extends null
  ? null
  : T extends bigint
  ? bigint
  : T extends symbol
  ? symbol
  : never;
