/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const TemplateIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M17.5555556,8.30555556 C18.2459115,8.30555556 18.8055556,8.86519962 18.8055556,9.55555556 C18.8055556,10.2459115 18.2459115,10.8055556 17.5555556,10.8055556 L2,10.8055556 C1.30964406,10.8055556 0.75,10.2459115 0.75,9.55555556 C0.75,8.86519962 1.30964406,8.30555556 2,8.30555556 L17.5555556,8.30555556 Z M22,3.86111111 C22.6903559,3.86111111 23.25,4.42075517 23.25,5.11111111 C23.25,5.80146705 22.6903559,6.36111111 22,6.36111111 L2,6.36111111 C1.30964406,6.36111111 0.75,5.80146705 0.75,5.11111111 C0.75,4.42075517 1.30964406,3.86111111 2,3.86111111 L22,3.86111111 Z M22,12.75 C22.6903559,12.75 23.25,13.3096441 23.25,14 C23.25,14.6903559 22.6903559,15.25 22,15.25 L2,15.25 C1.30964406,15.25 0.75,14.6903559 0.75,14 C0.75,13.3096441 1.30964406,12.75 2,12.75 L22,12.75 Z M17.5555556,17.1944444 C18.2459115,17.1944444 18.8055556,17.7540885 18.8055556,18.4444444 C18.8055556,19.1348004 18.2459115,19.6944444 17.5555556,19.6944444 L2,19.6944444 C1.30964406,19.6944444 0.75,19.1348004 0.75,18.4444444 C0.75,17.7540885 1.30964406,17.1944444 2,17.1944444 L17.5555556,17.1944444 Z'
    />
  </Icon>
);
