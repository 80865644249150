import { css } from '@emotion/react';
import { AddressElement, useElements } from '@stripe/react-stripe-js';
import { gql } from 'graphql-request';
import { PaymentsTerminalQueries } from '@frontend/api-payments-terminal';
import { useTranslation } from '@frontend/i18n';
import { paymentsQueryKeys, useMerchant, useMultiQueryUtils } from '@frontend/payments-hooks';
import { useMutation } from '@frontend/react-query-helpers';
import { theme } from '@frontend/theme';
import { Modal, ModalProps, Text, useAlert } from '@frontend/design-system';
import { usePaymentsGraphqlClient } from '../../../../../utils';
import { useAddressForm } from './use-address-form';

export enum TerminalAddressModalMode {
  Add = 'add',
  Edit = 'edit',
}

export const TerminalAddressModal = ({
  modalProps,
  onSubmit,
  mode = TerminalAddressModalMode.Edit,
}: {
  modalProps: Omit<ModalProps, 'children'>;
  onSubmit: () => void;
  mode: TerminalAddressModalMode;
}) => {
  const { t } = useTranslation('payments');
  const elements = useElements();
  const alerts = useAlert();
  const { refetch } = useMerchant();
  const { client } = usePaymentsGraphqlClient();
  const { getMultiQueryKey, locationId } = useMultiQueryUtils();
  const { invalidateQuery } = PaymentsTerminalQueries.useInvalidateTerminalAddressQuery(locationId || '');
  const { handleAddressChange, isValid, reset, address } = useAddressForm(null);

  const { mutate: submitTerminalAddress } = useMutation({
    mutationKey: getMultiQueryKey([paymentsQueryKeys.terminalLocation]),
    mutationFn: () =>
      client.request(terminalLocation, {
        address: {
          locationId,
          addressLine1: address.line1,
          city: address.city,
          state: address.state,
          postalCode: address.postal,
        },
      }),
    onSuccess: () => {
      invalidateQuery();
      refetch();
    },
  });

  const handleTerminalAddressSubmit = async () => {
    try {
      await submitTerminalAddress();
      onSubmit();
      clearForm();
      invalidateQuery();
      alerts.success({
        message: t('Business address was successfully submitted'),
        autoDismissAfter: 2000,
      });
    } catch (err) {
      alerts.error(t('Unable to update business address.'));
    }
  };

  const clearForm = () => elements?.getElement('address')?.clear();

  const handleClose = () => {
    reset();
    clearForm();
    modalProps.onClose();
  };

  return (
    <Modal {...modalProps} minWidth={500} onClose={handleClose}>
      <Modal.Header onClose={handleClose}>
        {mode === TerminalAddressModalMode.Add ? t('Add Business Address') : t('Edit Business Address')}
      </Modal.Header>
      <Modal.Body>
        <Text
          css={css`
            margin: ${theme.spacing(1, 0, 2)};
          `}
        >
          {mode === TerminalAddressModalMode.Add
            ? t('This is the business address associated with Stripe. Please use your current business address.')
            : t('This is the business address associated with Stripe, only change this if your business has moved.')}
        </Text>
        <AddressElement
          options={{
            mode: 'billing',
            defaultValues: { address: { country: 'US' } },
            display: {
              name: 'organization',
            },
          }}
          onChange={handleAddressChange}
        />
      </Modal.Body>
      <Modal.Actions
        primaryLabel={t('Save Changes')}
        disablePrimary={!isValid}
        onPrimaryClick={handleTerminalAddressSubmit}
        secondaryLabel='Cancel'
        onSecondaryClick={handleClose}
      />
    </Modal>
  );
};

const terminalLocation = gql`
  mutation SetTerminalLocation($address: TerminalLocationInput!) {
    setTerminalLocation(input: $address) {
      id
    }
  }
`;
