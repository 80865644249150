import { Children, ReactNode } from 'react';
import { ListRowTitle } from './list-row-title';
import { ListRowTertiaryTitle } from './list-row-tertiary-title';
import { ListRowSubtitle } from './list-row-subtitle';
import { useStyles } from '../../../../use-styles';
import { ContentColumnStyleProps } from './list-row-content-column.styles';

export const ContentColumn = ({ children, ...rest }: { children: ReactNode }) => {
  const styles = useStyles('ListRow', 'ContentColumn', {
    textChildren: Children.toArray(children).length as ContentColumnStyleProps['textChildren'],
  });

  return (
    <div css={styles} className='content-column' {...rest}>
      {children}
    </div>
  );
};

ContentColumn.Title = ListRowTitle;
ContentColumn.Subtitle = ListRowSubtitle;
ContentColumn.TertiaryTitle = ListRowTertiaryTitle;
