import React, { useCallback } from 'react';
import { KeyNames } from '../../../../constants';
import type { BasicFormFieldProps } from '../../layouts';
import { useCalendar } from '../../../calendar';
import { DatePickerBaseField, DatePickerBaseFieldRef } from './date-picker-base-field.component';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useDatePickerDialog } from './use-date-picker';
import { DatePickerDialog } from './date-picker-dialog.component';
import { formatDate, getTodaysDate } from '../../../../helpers';

dayjs.extend(duration);

export type DatePickerFieldProps = BasicFormFieldProps<'datePicker'>;

export const DatePickerField = ({
  active: fieldActive,
  value: fieldValue,
  onBlur,
  onFocus,
  blackoutDates,
  maxDate,
  minDate,
  today,
  ...props
}: DatePickerFieldProps) => {
  const onToggle = useCallback((open: any) => {
    // the input will no longer be focused after opening the flyout
    // so make sure to update field's active state when clicking outside

    if (!open) {
      onBlur({
        target: { name: props.name },
      } as React.FocusEvent<HTMLInputElement>);
    }
  }, []);

  const triggerRef = React.useRef<HTMLDivElement>(null);
  const { closeDialog, openDialog, open, toggle, triggerProps, dialogProps, debouncedBlur, debouncedFocus } =
    useDatePickerDialog({
      placement: 'bottom-end',
      clickOutsideIgnoreRefs: [triggerRef],
      offsetDistance: [8, -16],
      initialFocus: -20,
      onToggle,
      onBlur,
      onFocus,
      trigger: triggerRef,
    });
  const ref = React.useRef<DatePickerBaseFieldRef>({ buttonRef: triggerProps.ref, fieldRef: triggerRef });

  const handleDateSelect = (value: string | string[]) => {
    closeDialog();
    props.onChange({ value, name: props.name });
  };

  const calendarProps = useCalendar({
    value: fieldValue,
    onSelect: handleDateSelect,
    dayNameFormat: 'one',
    maxDate,
    minDate,
    blackoutDates,
    today,
  });

  const handleKeyDown = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case KeyNames.Enter:
      case KeyNames.Space:
        e.preventDefault();
        if (!open) openDialog();
        break;
      case KeyNames.Down:
        if (!open) openDialog();

        break;
      default:
        break;
    }
  };

  return (
    <>
      <DatePickerBaseField
        fieldActive={fieldActive ?? false}
        flyoutActive={open}
        value={fieldValue}
        onBlur={debouncedBlur}
        onFocus={debouncedFocus}
        onKeyDown={handleKeyDown}
        ref={ref}
        onCalendarIconClick={toggle}
        {...props}
      />
      <DatePickerDialog {...dialogProps}>
        <DatePickerDialog.Calendar {...calendarProps} />
      </DatePickerDialog>
    </>
  );
};

const addFortyDays = dayjs().add(dayjs.duration({ days: 40 }));

DatePickerField.parameters = {
  today: getTodaysDate('YYYY-MM-DD'),
  fortyDaysFromToday: formatDate(addFortyDays, 'YYYY-MM-DD'),
};
