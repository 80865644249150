import { css, keyframes } from '@emotion/react';
import type {
  ContentLoadersThemeValue,
  ContentLoaderTextThemeValue,
  SpinnersThemeValue,
  SkeletonLoaderThemeValues,
} from './loaders-theme';
import {
  contentLoadersTheme,
  contentLoadersThemeOriginal,
  contentLoaderTextTheme,
  contentLoaderTextThemeOriginal,
  spinnersTheme,
  spinnersThemeOriginal,
  skeletonLoaderTheme,
  skeletonLoaderThemeOriginal,
} from './loaders-theme';
import { SkeletonLoaderProps } from './skeleton-loader/types';
import { ContentLoader, SpinnerColorType, SpinnerSize } from '.';

type ContentLoaderProps = Parameters<typeof ContentLoader>[0];

type ContentLoaderStyleProp = {
  opacity: ContentLoaderProps['backgroundOpacity'];
};

type SpinnerStyleProp = { size: SpinnerSize; color: SpinnerColorType };

const rotation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const sizes = {
  xl: 56,
  large: 40,
  medium: 32,
  small: 24,
  xs: 16,
};

const borders = {
  xl: 6,
  large: 6,
  medium: 4,
  small: 2,
  xs: 2,
};

const margin = 4;

const spinnerStyle = ({ colors }: SpinnersThemeValue, { size, color }: SpinnerStyleProp) => {
  const spinSize = sizes[size];
  const lineSize = borders[size];
  return css`
    width: ${spinSize + margin * 2}px;
    height: ${spinSize + margin * 2}px;
    display: inline-block;
    position: relative;

    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: ${spinSize}px;
      height: ${spinSize}px;
      margin: ${margin}px;
      border: ${lineSize}px solid;
      border-radius: 50%;
      animation: ${rotation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: ${colors[color]} transparent transparent transparent;
    }

    div:nth-of-type(1) {
      animation-delay: -0.45s;
    }

    div:nth-of-type(2) {
      animation-delay: -0.3s;
    }

    div:nth-of-type(3) {
      animation-delay: -0.15s;
    }
  `;
};

const contentLoaderStyle = ({ zIndex }: ContentLoadersThemeValue, { opacity }: ContentLoaderStyleProp) => css`
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, ${opacity});
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${zIndex};
`;

const resolveUnit = (val: SkeletonLoaderStyleProps['height'] | SkeletonLoaderStyleProps['width']) => {
  if (typeof val === 'number') {
    return `${val}px`;
  }
  return val;
};

type SkeletonLoaderStyleProps = SkeletonLoaderProps<boolean>;

const skeletonLoaderStyle = (
  { backgroundColor, borderRadius }: SkeletonLoaderThemeValues,
  { width, height, shape, distance, path, animation, duration, count, isText, isList }: SkeletonLoaderStyleProps
) => {
  const animationStyle = () => {
    const shimmer = keyframes`
    to {
      background-position-x: -20%;
    }
   `;

    const pulse = keyframes`
    from {
      opacity: 0.3;
    }
    to {
      opacity: 1;
    }
   `;

    switch (animation) {
      case 'shimmer':
        return css`
          background: linear-gradient(
              90deg,
              rgba(255, 255, 255, 0) 35%,
              rgba(255, 255, 255, 0.5) 60%,
              rgba(255, 255, 255, 0) 70%
            )
            ${backgroundColor};
          background-size: 200% 100%;
          background-position-x: 170%;
          animation: ${duration}s ${shimmer} ease-in-out infinite;
        `;
      default:
        return css`
          animation: ${duration}s ${pulse} linear alternate infinite;
        `;
    }
  };

  return [
    css`
      display: block;
      background-color: ${backgroundColor};
      width: ${width ? resolveUnit(width) : '100%'};
      height: ${isText ? '1em' : height ? resolveUnit(height) : '100%'};
      border-radius: ${shape === 'circle' ? '50%' : borderRadius};
    `,
    path &&
      css`
        clip-path: ${path};
      `,
    animationStyle(),
    count &&
      css`
        :not(:last-child) {
          margin-bottom: ${distance ? resolveUnit(distance) : '0'};
        }
      `,

    (isText || !isList) &&
      count &&
      css`
        :last-child {
          width: calc(${width ? resolveUnit(width) : '100%'} - 1.5em);
        }
      `,
    distance &&
      css`
        margin-bottom: ${distance};
      `,
  ];
};

const contentLoaderTextStyle = ({ spacing }: ContentLoaderTextThemeValue) =>
  css`
    margin-top: ${spacing(1)};
  `;

export const LoadersStyles = {
  Loaders: {
    spinnerStyle: (spinnerProp: SpinnerStyleProp) => spinnerStyle(spinnersTheme, spinnerProp),
    contentLoaderStyle: (contentProp: ContentLoaderStyleProp) => contentLoaderStyle(contentLoadersTheme, contentProp),
    skeletonLoaderStyle: (props: SkeletonLoaderStyleProps) => skeletonLoaderStyle(skeletonLoaderTheme, props),
    contentLoaderTextStyle: contentLoaderTextStyle(contentLoaderTextTheme),
  },
};

export const LoadersStylesOriginal: typeof LoadersStyles = {
  Loaders: {
    spinnerStyle: (spinnerProp: SpinnerStyleProp) => spinnerStyle(spinnersThemeOriginal, spinnerProp),
    contentLoaderStyle: (contentProp: ContentLoaderStyleProp) =>
      contentLoaderStyle(contentLoadersThemeOriginal, contentProp),
    contentLoaderTextStyle: contentLoaderTextStyle(contentLoaderTextThemeOriginal),
    skeletonLoaderStyle: (props: SkeletonLoaderStyleProps) => skeletonLoaderStyle(skeletonLoaderThemeOriginal, props),
  },
};
