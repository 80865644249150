import { NakedButton, Heading, CaretDownIconSmall } from '@frontend/design-system';
import { theme } from '@frontend/theme';
import { css } from '@emotion/react';
import { ComponentPropsWithoutRef, ReactNode, Ref, forwardRef } from 'react';

type Props = { children: ReactNode; isOpen: boolean } & ComponentPropsWithoutRef<'button'>;

export const PageTitleMenuButton = forwardRef((props: Props, ref: Ref<HTMLButtonElement>) => {
  const { children, isOpen, ...rest } = props;
  return (
    <NakedButton
      css={css`
        gap: ${theme.spacing(1)};
        display: flex;
        align-items: center;
      `}
      ref={ref}
      {...rest}
    >
      <Heading
        level={1}
        css={css`
          color: ${theme.colors.primary50};
          line-height: 1;
        `}
      >
        {children}
      </Heading>
      <CaretDownIconSmall
        css={css`
          fill: ${theme.colors.primary50};
          transform: ${isOpen ? 'rotate(180deg)' : ''};
          transition: transform 250ms;
        `}
      />
    </NakedButton>
  );
});
