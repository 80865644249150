import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { isEmail } from 'validator';
import { UpdateMerchant } from '@frontend/api-payments';
import { i18next, useTranslation } from '@frontend/i18n';
import { getMixedMerchantValues, useMerchant, useMultiQueryUtils } from '@frontend/payments-hooks';
import { theme } from '@frontend/theme';
import {
  ButtonBar,
  ContentLoader,
  EmailField,
  FormFieldActionTypes,
  FormRow,
  PhoneField,
  PrimaryButton,
  SecondaryButton,
  SpinningLoader,
  Text,
  TextField,
  ValidatorFieldState,
  ValidatorFn,
  digitsOnly,
  useForm,
} from '@frontend/design-system';
import { useCanDoAction, useFeatureFlag, usePrevious, useUpdateMerchant } from '../../../hooks';
import * as styles from '../styles';
import { ImageFile, MerchantLogo } from './merchant-logo';

const fieldNames = {
  name: 'businessName',
  email: 'email',
  phone: 'phone',
  receiptEmail: 'receiptEmail',
  statementDescriptor: 'statementDescriptor',
} as const;

const MIXED = 'Mixed';

const formStyles = {
  italic: (value: string) =>
    css`
      ${value === MIXED && 'input { font-style: italic; }'}
    `,
  buttonBar: css`
    padding: 0px;
  `,
};

const getInitialValue = <T,>(value: 'mixed' | T) => (value === 'mixed' ? MIXED : value ?? '');
const emailValidator = ({ value }: ValidatorFieldState<'email'>) =>
  value === MIXED || isEmail(value) ? '' : i18next.t('Invalid email address');
const phoneValidator = ({ value }: ValidatorFieldState<'phone'>) =>
  value === MIXED || /^\d{10,11}$/.test(digitsOnly(value)) ? '' : i18next.t('Invalid phone number');

export const PublicBusinessInformation = () => {
  const { t } = useTranslation('payments', { keyPrefix: 'settings' });
  const { enabled: isLogoEnabledLocation } = useFeatureFlag('payments_text_to_pay_old_portal');
  const { locationIds } = useMultiQueryUtils();
  const { merchantsData, isLoading } = useMerchant();
  const { isPaymentsBillingManager } = useCanDoAction();

  const merchantValues = getMixedMerchantValues(locationIds, merchantsData);

  const showLogo = locationIds.length === 1 && isLogoEnabledLocation;
  const [logo, setLogo] = useState<ImageFile | undefined>(undefined);
  const prevLogo = usePrevious(logo);

  const validateDescriptor: ValidatorFn<'text'> = ({ value: descriptor = '' }: ValidatorFieldState<'text'>) => {
    const atLeastOneLetter = /[a-zA-Z]+/.test(descriptor || '');
    const disallowedChars = /[<>"'*\\]/.test(descriptor || '');

    if (descriptor === MIXED) {
      return '';
    } else if (descriptor.length < 5 || descriptor.length > 22) {
      return t('Must be between 5-22 characters');
    } else if (!atLeastOneLetter) {
      return t('Must include at least one letter');
    } else if (disallowedChars) {
      return t(`No <, >, \\, ', ", or * characters are allowed`);
    } else {
      return '';
    }
  };

  const { handleMerchantUpdate, isUpdating } = useUpdateMerchant();

  const { formProps, getFieldProps, isComplete, seedValues, validate, changedValues } = useForm({
    computeChangedValues: true,
    fields: {
      [fieldNames.name]: { type: 'text', required: true },
      [fieldNames.email]: { type: 'text', required: true, validator: emailValidator },
      [fieldNames.receiptEmail]: { type: 'text', required: true, validator: emailValidator },
      [fieldNames.phone]: { type: 'text', required: true, validator: phoneValidator },
      [fieldNames.statementDescriptor]: {
        type: 'text',
        required: true,
        validator: validateDescriptor,
      },
    },
    onSubmit: (values) => {
      const data: UpdateMerchant = {
        ...(values[fieldNames.name] !== MIXED ? { name: values[fieldNames.name]! } : {}),
        ...(values[fieldNames.phone] !== MIXED ? { phoneNumber: values[fieldNames.phone]! } : {}),
        ...(values[fieldNames.email] !== MIXED ? { email: values[fieldNames.email]! } : {}),
        ...(values[fieldNames.receiptEmail] !== MIXED ? { receiptEmail: values[fieldNames.receiptEmail]! } : {}),
        ...(values[fieldNames.statementDescriptor] !== MIXED
          ? { statementDescriptor: values[fieldNames.statementDescriptor] }
          : {}),
      };
      handleMerchantUpdate(data);
    },
    fieldStateReducer: (state, action) => {
      if (action.type === FormFieldActionTypes.Update && action.payload.name === fieldNames.statementDescriptor) {
        return {
          ...state,
          [fieldNames.statementDescriptor]: {
            ...state[fieldNames.statementDescriptor],
            value:
              locationIds.length > 1 && action.payload.value.toLowerCase() === 'mixed'
                ? MIXED
                : action.payload.value.toUpperCase(),
          },
        };
      }
      return null;
    },
  });

  const businessNameLabelId = `${getFieldProps(fieldNames.name).id}-label`;
  const statementDescriptorLabelId = `${getFieldProps(fieldNames.statementDescriptor).id}-label`;

  const setInitialValues = () =>
    seedValues({
      [fieldNames.name]: getInitialValue(merchantValues?.name),
      [fieldNames.email]: getInitialValue(merchantValues?.email),
      [fieldNames.receiptEmail]:
        getInitialValue(merchantValues?.receiptEmail) || getInitialValue(merchantValues?.email),
      [fieldNames.phone]: getInitialValue(merchantValues?.phoneNumber),
      [fieldNames.statementDescriptor]: getInitialValue(merchantValues?.statementDescriptor),
    });

  useEffect(() => {
    setInitialValues();
  }, [JSON.stringify(merchantValues)]);

  const resetForm = () => setInitialValues();

  // validate the statement descriptor on changes and when data first loads
  // will help display errors that came from onboarding
  useEffect(() => {
    if (merchantValues?.statementDescriptor) {
      validate();
    }
  }, [merchantValues?.statementDescriptor]);

  const disableFields = isLoading || isUpdating || !isPaymentsBillingManager;

  return (
    <>
      <ContentLoader show={!merchantValues || isLoading} message={t('Loading Merchant Information')} />
      <form {...formProps} css={[styles.form, styles.formMaxWidth]}>
        <Text size='large' weight='bold' as='label' id={businessNameLabelId} css={styles.formGroupLabel}>
          {t('Business Information')}
        </Text>
        <FormRow>
          <TextField
            {...getFieldProps(fieldNames.name)}
            label={t('Business Name')}
            placeholder='e.g. Pied Piper Dental LLC'
            aria-labelledby={businessNameLabelId}
            data-trackingid='pay-portal-settings-txt-bizname'
            disabled={disableFields}
            css={formStyles.italic(getFieldProps(fieldNames.name).value)}
          />
        </FormRow>
        <FormRow css={styles.formRow}>
          <TextField
            {...getFieldProps(fieldNames.statementDescriptor)}
            label={t('Statement Descriptor')}
            placeholder='e.g. Homeland Dental'
            aria-labelledby={statementDescriptorLabelId}
            data-trackingid='pay-portal-settings-txt-statementdesc'
            disabled={disableFields}
            css={formStyles.italic(getFieldProps(fieldNames.statementDescriptor).value)}
          />
          <Text
            size='small'
            color='light'
            css={css`
              margin: ${theme.spacing(1, 0, 0, 1)};
            `}
          >
            {t(`This is the business name that will show up on your customers' bank or credit card statements.
          Choose something they will recognize to help prevent disputes. A common website URL is only
          acceptable if it provides a clear and accurate description of a transaction.`)}
          </Text>
        </FormRow>
        <Text size='large' weight='bold' css={styles.formGroupLabel}>
          {t('Notify of Payment Activity')}
        </Text>
        <FormRow css={styles.formRow}>
          <EmailField
            {...getFieldProps(fieldNames.receiptEmail)}
            label={t('Email Address')}
            data-trackingid='pay-portal-settings-notify-email'
            disabled={disableFields}
            css={formStyles.italic(getFieldProps(fieldNames.receiptEmail).value)}
          />
          <Text
            size='small'
            color='light'
            css={css`
              margin: ${theme.spacing(1, 0, 0, 1)};
            `}
          >
            {t('New transactions and other alerts will be sent here.')}
          </Text>
        </FormRow>
        <div css={styles.formRow}>
          <Text size='large' weight='bold'>
            {t('Contact Information')}
          </Text>
          <Text size='small' color='light'>
            {t('This contact information will be shown to your customers on Weave payments pages.')}
          </Text>
        </div>
        <FormRow>
          <EmailField
            {...getFieldProps(fieldNames.email)}
            label={t('Email Address')}
            data-trackingid='pay-portal-settings-txt-email'
            disabled={disableFields}
            css={formStyles.italic(getFieldProps(fieldNames.email).value)}
          />
        </FormRow>
        <FormRow>
          {getFieldProps(fieldNames.phone).value === MIXED ? (
            <TextField
              {...getFieldProps(fieldNames.phone)}
              label={t('Phone Number')}
              data-trackingid='pay-portal-settings-txt-phone'
              disabled={disableFields}
              css={formStyles.italic(getFieldProps(fieldNames.phone).value)}
            />
          ) : (
            <PhoneField
              {...getFieldProps(fieldNames.phone)}
              label={t('Phone Number')}
              data-trackingid='pay-portal-settings-txt-phone'
              disabled={disableFields}
              css={formStyles.italic(getFieldProps(fieldNames.phone).value)}
            />
          )}
        </FormRow>

        {showLogo && <MerchantLogo logo={logo} setLogo={setLogo} isUpdating={isUpdating} />}
        <ButtonBar css={formStyles.buttonBar}>
          <SecondaryButton disabled={!(changedValues || prevLogo !== logo) || disableFields} onClick={resetForm}>
            {t('Revert Changes')}
          </SecondaryButton>
          <PrimaryButton
            type='submit'
            disabled={!isComplete || !(changedValues || prevLogo !== logo) || disableFields}
            css={[styles.groupMaxWidth, styles.submitButton]}
            data-trackingid='pay-portal-settings-btn-save'
          >
            {isUpdating ? (
              <>
                {t('Saving Changes')}
                <SpinningLoader
                  size='xs'
                  css={css`
                    margin-left: ${theme.spacing(1)};
                  `}
                />
              </>
            ) : (
              t('Save Changes')
            )}
          </PrimaryButton>
        </ButtonBar>
      </form>
    </>
  );
};
