import { css } from '@emotion/react';
import { useStyles } from '../../../use-styles';
import { Text } from '../../text';

type DefaultAccordionHeaderProps = {
  title?: string;
  subtitle?: string;
};

export const DefaultAccordionHeader = ({ title, subtitle }: DefaultAccordionHeaderProps) => {
  const titleStyle = useStyles('Accordion', 'defaultAccordionHeader', {
    defaultHeader: 'title',
    hasSubtitle: !!subtitle,
  });
  const subtitleStyle = useStyles('Accordion', 'defaultAccordionHeader', {
    defaultHeader: 'subtitle',
  });

  return title || subtitle ? (
    <section
      css={css`
        display: flex;
        flex-direction: column;
        text-align: left;
      `}
    >
      {!!title && (
        <Text as='span' css={titleStyle}>
          {title}
        </Text>
      )}
      {!!subtitle && <Text css={subtitleStyle}>{subtitle}</Text>}
    </section>
  ) : null;
};
