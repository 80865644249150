import { loadStripeTerminal } from '@stripe/terminal-js';
import { PaymentsTerminalError } from '../payment-terminal-error';
import { isStripeErrorResponse } from './sdk-utils';

const stripeTerminalPromise = loadStripeTerminal();

export const getStripeTerminalSDK = async () => {
  try {
    const result = await stripeTerminalPromise;
    if (!result) {
      throw new Error();
    }
    return result;
  } catch (error) {
    if (isStripeErrorResponse(error)) {
      throw new PaymentsTerminalError('initialize', error.error);
    }
    throw new PaymentsTerminalError(
      'initialize',
      'Stripe Terminal SDK is not loaded, check that stripe domains are whitelisted'
    );
  }
};
