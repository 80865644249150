import { useMemo, useState } from 'react';

export type stepOption = { value: string; disabled?: boolean; valid?: boolean };

interface Props {
  steps: stepOption[];
}

export const useStepAccordian = ({ steps }: Props) => {
  const stepValues = steps.map((step) => step.value);
  const initialSteps = [stepValues[0]];
  const [activeSteps, setActiveSteps] = useState<string[]>(initialSteps);
  const [activeStepNumber, setActiveStepNumber] = useState<number>(1);

  const isValidStepNumber = (stepNumber: number) => stepNumber >= 1 || stepNumber <= steps.length;

  const setActiveStep = (stepNumber: number) => {
    if (!isValidStepNumber(stepNumber)) return;
    setActiveStepNumber(stepNumber);
    setActiveSteps([stepValues[stepNumber - 1] || stepValues[0]]);
  };

  const isActiveStep = (stepNumber: number) => {
    return activeStepNumber === stepNumber;
  };

  const isStepDisabled = (stepNumber: number) => {
    return !isValidStepNumber(stepNumber) || !!steps[stepNumber - 1].disabled;
  };

  const isValidStep = (stepNumber: number) => {
    return isValidStepNumber(stepNumber) && (steps[stepNumber - 1].valid ?? true);
  };

  const stepProps = useMemo(
    () => ({
      accordian: { controlledValue: activeSteps, startingValue: initialSteps },
      step: (stepNumber: number) => {
        return {
          item: { value: steps[stepNumber - 1]?.value ?? '' },
          header: {
            onClick: () => {
              if (!steps[stepNumber - 1].disabled) setActiveStep(stepNumber);
            },
            disabled: isStepDisabled(stepNumber),
          },
        };
      },
    }),
    [activeSteps, initialSteps, steps]
  );

  return { stepProps, isActiveStep, isValidStep, isStepDisabled, setActiveStep };
};
