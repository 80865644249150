import { WeaveThemeValues } from '../../component-theme/theme-types';
import { theme } from '@frontend/theme';
import { theme as originalTheme } from '@frontend/theme-original';

export type DefaultAccordionHeaderStyleValues = {
  spacing: WeaveThemeValues['Spacing'];
  fontSize: WeaveThemeValues['FontSize'];
  titleFontWeight: WeaveThemeValues['FontWeight'];
  subtitleColor: WeaveThemeValues['Color'];
};

export type AccordionHeaderStyleValues = {
  spacing: WeaveThemeValues['Spacing'];
  focusBorderColor: WeaveThemeValues['Color'];
  borderColor: WeaveThemeValues['Color'];
  filledVariantBackgroundColor: WeaveThemeValues['Color'];
  borderRadius: WeaveThemeValues['BorderRadius'];
  zIndex: WeaveThemeValues['ZIndex'];
  fontColor: WeaveThemeValues['Color'];
};

export type AccordionBodyStyleValues = {
  borderColor: WeaveThemeValues['Color'];
  borderRadius: WeaveThemeValues['BorderRadius'];
};

export type AccordionItemStyleValues = {
  spacing: WeaveThemeValues['Spacing'];
  borderColor: WeaveThemeValues['Color'];
  boxShadow: WeaveThemeValues['BoxShadow'];
  borderRadius: WeaveThemeValues['BorderRadius'];
};

export type ChevronContainerStyleValues = {
  spacing: WeaveThemeValues['Spacing'];
  disabledIconColor: WeaveThemeValues['Color'];
};

export const defaultAccordionHeaderTheme: DefaultAccordionHeaderStyleValues = {
  spacing: theme.spacing,
  fontSize: theme.fontSize,
  titleFontWeight: theme.font.weight.bold,
  subtitleColor: theme.colors.neutral40,
};

export const originalDefaultAccordionHeaderTheme: DefaultAccordionHeaderStyleValues = {
  spacing: originalTheme.spacing,
  fontSize: originalTheme.fontSize,
  titleFontWeight: originalTheme.font.weight.bold,
  subtitleColor: originalTheme.colors.gray500,
};

export const accordionHeaderTheme: AccordionHeaderStyleValues = {
  spacing: theme.spacing,
  focusBorderColor: theme.colors.primary50,
  borderColor: theme.colors.neutral20,
  filledVariantBackgroundColor: theme.colors.neutral5,
  borderRadius: theme.borderRadius.medium,
  zIndex: theme.zIndex.high,
  fontColor: theme.colors.neutral20,
};

export const originalAccordionHeaderTheme: AccordionHeaderStyleValues = {
  spacing: originalTheme.spacing,
  focusBorderColor: originalTheme.colors.weaveBlue,
  borderColor: originalTheme.colors.gray300,
  filledVariantBackgroundColor: originalTheme.colors.gray100,
  borderRadius: originalTheme.borderRadius.medium,
  zIndex: originalTheme.zIndex.high,
  fontColor: originalTheme.colors.gray200,
};

export const accordionBodyTheme: AccordionBodyStyleValues = {
  borderColor: theme.colors.neutral20,
  borderRadius: theme.borderRadius.medium,
};

export const originalAccordionBodyTheme: AccordionBodyStyleValues = {
  borderColor: originalTheme.colors.gray100,
  borderRadius: originalTheme.borderRadius.medium,
};

export const accordionItemTheme: AccordionItemStyleValues = {
  spacing: theme.spacing,
  borderColor: theme.colors.neutral20,
  boxShadow: theme.shadows.light,
  borderRadius: theme.borderRadius.medium,
};

export const originalAccordionItemTheme: AccordionItemStyleValues = {
  spacing: originalTheme.spacing,
  borderColor: originalTheme.colors.gray300,
  boxShadow: originalTheme.shadows.light,
  borderRadius: originalTheme.borderRadius.medium,
};

export const chevronContainerTheme: ChevronContainerStyleValues = {
  spacing: theme.spacing,
  disabledIconColor: theme.colors.neutral20,
};

export const originalChevronContainerTheme: ChevronContainerStyleValues = {
  spacing: originalTheme.spacing,
  disabledIconColor: originalTheme.colors.gray400,
};
