import React from 'react';
import { ActionPressedChip } from '../atoms';
import { Text } from '../../text';
import { css } from '@emotion/react';

type UnderlineActionProps = Omit<React.ComponentProps<typeof ActionPressedChip>, 'children'>;

export const UnderlineAction = ({ compact = true, showBoxShadow = true, ...rest }: UnderlineActionProps) => {
  return (
    <ActionPressedChip {...rest} compact={compact} showBoxShadow={showBoxShadow}>
      <Text
        as='span'
        css={css`
          text-decoration: underline;
        `}
      >
        U
      </Text>
    </ActionPressedChip>
  );
};
