import { ForwardedRef, useRef } from 'react';

export const useRefInterceptor = <T extends HTMLElement>(
  ref?: ForwardedRef<T>,
  callback?: (node: T | null) => void
) => {
  const localRef = useRef<T | null>(null);

  return [
    localRef,
    (node: T | null) => {
      localRef.current = node;
      if (typeof ref === 'function') {
        ref(node);
      } else if (ref) {
        ref.current = node;
      }
      callback?.(node);
    },
  ] as const;
};
