import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const modalStyles = css`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const modalHeaderStyle = css`
  display: flex;
  align-items: center;
  padding: ${theme.spacing(1, 2)};
  border-bottom: 1px solid ${theme.colors.neutral20};

  .close-btn {
    margin-left: auto;
  }
`;

export const datePickerStyle = css`
  max-width: 100%;
`;

export const formStyles = css`
  margin-top: ${theme.spacing(2)};

  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${theme.spacing(2, 2)};
`;

export const contactSearchWrapperStyle = css`
  padding: ${theme.spacing(2)};
  border-bottom: 1px solid ${theme.colors.neutral20};
`;
