import { css } from '@emotion/react';
import { mergeClasses } from '../../../helpers/merge-classes';
import { useThemeValues } from '../../../hooks/use-theme-values';
import { isFunction } from 'lodash-es';
import { ReactNode, useEffect, useRef } from 'react';
import { useModalContext } from '../provider/modal.provider';
import { TextLink } from '../../text-link/text-link.component';
import { ButtonBar } from '../../buttons/button-bar';
import { SecondaryButton } from '../../buttons/secondary/secondary-button';
import { PrimaryButton } from '../../buttons/primary/primary-button';
import { withClose } from './helpers';
import type { ClickHandler } from './helpers';
import { Text } from '../../text';
import { useStyles } from '../../../use-styles';

type Props = {
  className?: string;
  showPrimary?: boolean;
  disablePrimary?: boolean;
  disableSecondary?: boolean;
  onBackClick?: ClickHandler;
  onPrimaryClick?: 'submit' | ClickHandler;
  onSecondaryClick?: ClickHandler;
  backLabel?: ReactNode;
  primaryLabel?: string;
  secondaryLabel?: string;
  backTrackingId?: string;
  primaryTrackingId?: string;
  secondaryTrackingId?: string;
  destructive?: boolean;
  helperText?: string;
};

/**
 * Component for adding secondary + primary actions to a modal
 * @param {string} [props.className] Optional css/classname
 * @param {boolean} [props.disablePrimary] Should the primary button be disabled?
 * @param {boolean} [props.disableSecondary] Should the secondary button be disabled?
 * @param {string | function} props.onPrimaryClick Either 'submit' (for buttons controlling a form in a modal) or a click handler function.
 * @param {function} [props.onSecondaryClick] Optionals click handler for the secondary action. Defaults to the modals onClose function.
 * @param {string} [props.primaryLabel] Optional text for the primary button (default = 'Save')
 * @param {string} [props.secondaryLabel] Optional text for the secondary button (default = 'Cancel')
 */
export const ModalActions = ({
  className,
  showPrimary = true,
  disablePrimary,
  disableSecondary,
  primaryLabel = 'Save',
  secondaryLabel = 'Cancel',
  backLabel = 'Back',
  onBackClick,
  onSecondaryClick,
  onPrimaryClick,
  backTrackingId,
  primaryTrackingId,
  secondaryTrackingId,
  destructive,
  helperText,
}: Props) => {
  const isForm = !isFunction(onPrimaryClick);
  const { spacing } = useThemeValues();
  const { setActionStateChanged, onClose } = useModalContext();
  const primaryDisabled = useRef(disablePrimary);
  const secondaryDisabled = useRef(disableSecondary);
  const helperTextStyles = useStyles('Modal', 'helperTextStyles');

  useEffect(() => {
    /**
     * We trigger this side effect to allow consumers to hook into the action state
     */
    setActionStateChanged({
      primary: primaryDisabled.current !== disablePrimary,
      secondary: secondaryDisabled.current !== disableSecondary,
    });

    primaryDisabled.current = disablePrimary;
    secondaryDisabled.current = disableSecondary;
  }, [disablePrimary, disableSecondary]);

  return (
    <div
      css={css`
        display: grid;
        grid-template-areas: 'back middle main';
        grid-template-columns: auto 1fr;
        align-items: center;
        padding: ${spacing(2, 3, 0)};
      `}
    >
      {onBackClick && (
        <TextLink
          weight='bold'
          css={{ width: 'auto', gridArea: 'back' }}
          onClick={onBackClick}
          trackingId={backTrackingId}
        >
          {backLabel}
        </TextLink>
      )}
      {helperText && (
        <Text textAlign='right' css={helperTextStyles}>
          {helperText}
        </Text>
      )}
      <ButtonBar
        css={{ gridArea: 'main', alignItems: 'center' }}
        removeSpacing
        className={mergeClasses('wv-modal-actions', className)}
      >
        {isFunction(onSecondaryClick) && (
          <SecondaryButton
            css={{ width: 'auto' }}
            onClick={withClose(onClose, onSecondaryClick)}
            type='button'
            disabled={disableSecondary}
            trackingId={secondaryTrackingId}
            size='large'
          >
            {secondaryLabel}
          </SecondaryButton>
        )}
        {showPrimary && (
          <PrimaryButton
            css={{ width: 'auto' }}
            onClick={isForm ? undefined : withClose(onClose, onPrimaryClick as ClickHandler)}
            type={isForm ? 'submit' : 'button'}
            disabled={disablePrimary}
            trackingId={primaryTrackingId}
            destructive={destructive}
            size='large'
          >
            {primaryLabel}
          </PrimaryButton>
        )}
      </ButtonBar>
    </div>
  );
};
