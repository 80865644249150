import { css } from '@emotion/react';
import { XIconSmall } from '../../../icon';
import { IconButton } from '../../icon-button';
import type { FieldHandlers } from '../hooks';
import { useThemeValues } from '../../../hooks';

type Props = Omit<FieldHandlers<HTMLAnchorElement | HTMLButtonElement>, 'onChange'> & {
  onClick: () => void;
  className?: string;
};

export const ClearFieldBtn = (props: Props) => {
  const theme = useThemeValues();
  return (
    <IconButton
      size='small'
      label='Clear field'
      css={css`
        position: relative;
        left: 8px;
        min-width: 24px;
        height: 24px;
        width: 24px;
        padding: ${theme.spacing(0.5)};
      `}
      {...props}
    >
      <XIconSmall />
    </IconButton>
  );
};
