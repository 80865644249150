import { useStableValueTracker, useUid } from '../../../../hooks';
import { isEqual } from 'lodash-es';
import { useEffect, useMemo, useReducer } from 'react';
import type { FieldConfig, FieldKey, FieldValue, ResolvedFieldProps } from '../types';
import { FormFieldActionTypes } from '../types';
import { getError, initializeFieldState, normalizeChangeHandler, trimIfString, validateField } from '../utils';
import { setValue } from '../value-setters';

export const reducer = (state, action) => {
  switch (action.type) {
    case FormFieldActionTypes.Blur: {
      const nextState = {
        ...state,
        active: false,
        touched: true,
      };
      return { ...nextState, ...validateField({ ...nextState, value: action.payload }) };
    }
    case FormFieldActionTypes.Focus:
      return { ...state, active: true };
    case FormFieldActionTypes.Reset: {
      const { ...rest } = initializeFieldState({ ...state, ...action.payload });
      return rest;
    }
    case FormFieldActionTypes.Update: {
      const { value, displayValue } = action.payload;
      const shouldValidateOnChange = state.validateOnChange ? { touched: true } : {};
      const nextState = { ...state, ...shouldValidateOnChange };
      const validationState = { ...state, value: value };

      return {
        ...nextState,
        displayValue,
        ...(nextState.touched ? validateField(validationState) : { error: getError(validationState) }),
      };
    }
    default:
      return state;
  }
};

const isSameValue = (a: FieldValue, b: FieldValue) => {
  if (typeof a !== typeof b) return false;
  if (Array.isArray(a)) return isEqual(a, b);
  return a === b;
};

type ControlledFieldConfig<T extends FieldKey> = FieldConfig<T> & {
  onChange: (value: any) => void;
  value: FieldConfig<T>['value'];
};
/**
 * Hook for controlled field handling. Allows control over the value and change handler,
 * but provides all the common, field behavior. This hook exists for edge cases. Most field/form
 * handling should be done through `useForm` or `useFormField`.
 * @param props Field config object, including controlled value and onChange handler.
 */
export function useControlledField<T extends FieldKey>({
  onChange,
  ...props
}: ControlledFieldConfig<T>): ResolvedFieldProps<T> {
  const id = useUid('field');
  const stableValue = useStableValueTracker(props.value);
  // no need to track value in local reducer
  const { value, ...initialState } = useMemo(() => initializeFieldState(props as any), []);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { validateOnChange, ...rest } = state;

  // clean up bad starting values (via setValue from initialState)
  useEffect(() => {
    // array values from initializeFieldState will have a different identity
    // than the value passed in as props, so need to check for that case
    if (!isSameValue(value, props.value)) onChange(value);
  }, []);

  const handleValueChange = (payload, updateValue = false) => {
    const nextState = setValue({ ...state, value: stableValue.current }, payload.value);

    // TODO: we can remove the manual handling of specialized field values
    //       when we consolidate the field configs, value setters, etc.
    const { value, displayValue } = nextState;

    dispatch({
      type: FormFieldActionTypes.Update,
      payload: { value, displayValue },
    });
    if (!updateValue) onChange(value);
  };

  useEffect(() => {
    handleValueChange({ value: props.value }, true);
  }, [props?.value?.toString()]);

  return {
    ...rest,
    id: id.current,
    onBlur: () => {
      dispatch({ type: FormFieldActionTypes.Blur, payload: props.value });
      const trimmed = trimIfString(stableValue.current);
      if (trimmed !== stableValue.current) {
        onChange(trimmed);
      }
    },
    onChange: normalizeChangeHandler(handleValueChange),
    onFocus: () => {
      dispatch({ type: FormFieldActionTypes.Focus });
    },
    value: props.value,
  };
}
