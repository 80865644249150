import { Children, isValidElement, cloneElement, ReactElement } from 'react';
import { useStyles } from '../../../../use-styles';

type TrailingColumnProps = React.PropsWithChildren<{
  className?: string;
  verticalAlign?: 'center' | 'flex-start';
}>;

export const TrailingColumn = ({ verticalAlign = 'center', children, ...rest }: TrailingColumnProps) => {
  const styles = useStyles('ListRow', 'TrailingColumn', { verticalAlign });

  return (
    <div css={styles} className='trailing-column' {...rest}>
      {Children.map(
        children,
        (child) =>
          isValidElement(child) &&
          cloneElement(child as ReactElement<{ className: string }>, { className: 'trailing-column-child' })
      )}
    </div>
  );
};
