import { useId } from '@floating-ui/react-dom-interactions';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { useTooltip } from '../../../tooltip';
import { CheckIcon } from '../../../../icon';
import { useStyles } from '../../../../use-styles';
import { styles } from '../../../../styles';
import { Text } from '../../../text';
import { SelectContext } from './dropdown-field.context';

export interface DropdownListItemProps {
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  isSelectable?: boolean;
  /** String for searching against (if children is jsx or varies from the value) */
  searchValue?: string;
  trackingId?: string;
  displayValue?: string;
  value: string;
}

/**
 *
 * After some of the new floating-ui components have baked and garnered trust,
 * this component should be genericized and merged with
 * `libs/ui/design-system/src/components/forms/fields/time-field/dropdown-list-item.tsx`
 */
export const DropdownListItem = ({
  children,
  disabled,
  isSelectable = true,
  searchValue,
  value,
  displayValue,
  ...rest
}: DropdownListItemProps) => {
  const {
    activeIndex,
    getItemProps,
    listItemsRef,
    lookupByValue,
    name,
    onChange,
    selectedIndex,
    setFloatingElementOpen,
    setSelectedIndex,
  } = useContext(SelectContext);
  const index = lookupByValue[value]?.index;
  const id = useId();
  const selected = selectedIndex === index;
  const active = activeIndex === index;
  const dropdownOptionStyles = useStyles('DropdownField', 'dropdownOption', { active, disabled });
  const [shouldShowHoverLabel, setShouldShowHoverLabel] = useState<boolean>(false);
  const { Tooltip, tooltipProps, triggerProps } = useTooltip({
    placement: 'top',
  });
  const conditionalTriggerProps = shouldShowHoverLabel ? triggerProps : { ref: null };

  function composeRefs(node: HTMLLIElement) {
    listItemsRef.current[index] = node;
    if (conditionalTriggerProps.ref) {
      conditionalTriggerProps.ref(node);
    }
  }

  useEffect(() => {
    const listItem = listItemsRef.current?.[index];
    if (listItem) {
      const childElement = listItem.children[0] as HTMLSpanElement;
      setShouldShowHoverLabel(childElement.scrollWidth > childElement.offsetWidth);
    }
  }, [listItemsRef.current?.[index]?.children[0]?.scrollWidth]);

  const { key, ...itemProps } = getItemProps({
    ref: (node: HTMLLIElement) => {
      if (node) {
        listItemsRef.current[index] = node;
      }
    },
    // order of items will not change, so index is a reliable key
    key: index,
    onClick() {
      if (disabled) return;
      setSelectedIndex(index);
      onChange({ name, value });
      setFloatingElementOpen(false);
    },
  });

  return (
    <li
      {...conditionalTriggerProps}
      aria-disabled={disabled}
      aria-selected={active}
      id={id}
      role='option'
      data-value={value}
      {...itemProps}
      key={key}
      ref={composeRefs}
      css={dropdownOptionStyles}
      {...rest}
    >
      <Text as='span' className='select-option-text' css={styles.truncate}>
        {children ?? <i>No value</i>}
      </Text>
      {!!children && shouldShowHoverLabel && <Tooltip {...tooltipProps}>{children}</Tooltip>}
      {isSelectable && selected && <CheckIcon color='primary' />}
    </li>
  );
};
