import { useMemo } from 'react';
import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { ValidNumberStatus } from '@weave/schema-gen-ts/dist/schemas/sms/number/v1/number_service.pb';
import { SMSNumberV1 } from '@frontend/api-sms-number';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { formatPhoneNumber, formatPhoneNumberE164 } from '@frontend/phone-numbers';
import { theme } from '@frontend/theme';
import { PopoverMenu, PopoverMenuItem, Text, TextButton, usePopoverMenu } from '@frontend/design-system';

type PersonPhoneSelectorProps = {
  personPhone: string;
  onSelectPersonPhone: (personPhone: string) => void;
  color?: 'default' | 'white';
} & (
  | {
      personId?: never;
      person?: never;
      groupId?: string;
    }
  | {
      personId: string;
      person?: Person;
      groupId: string;
    }
);

/**
 * `PersonPhoneSelector` is a component that displays the currently selected phone number and a dropdown menu of a
 * person's valid phone numbers that can be selected.
 * If there is only one valid phone number, the dropdown menu will not be displayed, and the triggering button will not
 * be shown, plain text will be displayed instead.
 *
 * @param personPhone - The currently selected phone number.
 * @param onSelectPersonPhone - A function that is called when a phone number is selected.
 * @param color (optional) - The color of the text and icon button
 * @param personId - The ID of the person associated with the phone numbers.
 * @param groupId - The ID of the group to which the person belongs.
 * @param person (optional) - The person associated with the phone numbers. Providing this will skip the person lookup.
 */
export const PersonPhoneSelector = ({
  personPhone,
  onSelectPersonPhone,
  color = 'default',
  personId: providedPersonId,
  groupId,
  person,
}: PersonPhoneSelectorProps) => {
  const { t } = useTranslation('thread-header');
  const personId = providedPersonId || person?.personId || '';
  const { validPhones: personValidPhones, allPhonesWithValidity } = SMSNumberV1.Hooks.usePersonSMSNumbersValidity({
    personId,
    groupIds: groupId ? [groupId] : [],
    person,
  });

  const { data: phoneValidity } = SMSNumberV1.Queries.useGetValidityQuery({
    request: { phoneNumber: personPhone },
    options: { enabled: !!personPhone && (!personId || !groupId) },
    fallbackDataOnError: { isValid: ValidNumberStatus.VALID_NUMBER_STATUS_VALID },
  });

  const phoneWithoutPerson = useMemo(
    () => ({
      number: personPhone,
      isValid: phoneValidity?.isValid === ValidNumberStatus.VALID_NUMBER_STATUS_VALID,
    }),
    [phoneValidity?.isValid, personPhone]
  );
  const { validPhones, allPhones } = useMemo<{
    validPhones: SMSNumberV1.Types.PhoneValidity[];
    allPhones: SMSNumberV1.Types.PhoneValidity[];
  }>(
    () =>
      personId
        ? { validPhones: personValidPhones, allPhones: allPhonesWithValidity }
        : { validPhones: [phoneWithoutPerson].filter(({ isValid }) => isValid), allPhones: [phoneWithoutPerson] },
    [personId, JSON.stringify(personValidPhones), JSON.stringify(phoneWithoutPerson)]
  );
  const currentSelectedPhone: SMSNumberV1.Types.PhoneValidity =
    allPhones.find(({ number }) => formatPhoneNumberE164(number) === formatPhoneNumberE164(personPhone)) ??
    phoneWithoutPerson;
  const currentSelectedPhoneLabel = `${currentSelectedPhone.label ?? ''} ${formatPhoneNumber(
    currentSelectedPhone.number
  )}`.trim();

  // Show the button if there are other phone numbers that are valid to be selected
  const showButton = validPhones.length > 1 || (!currentSelectedPhone.isValid && !!validPhones.length);

  const { getMenuProps, getItemProps, getTriggerProps, isOpen } = usePopoverMenu({
    placement: 'bottom',
  });

  return (
    <>
      <span
        css={{
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(showButton ? 0 : 0.5),
          justifyContent: 'center',
        }}
      >
        <Text
          color={color === 'white' ? 'white' : 'light'}
          size='medium'
          weight={color === 'white' ? 'bold' : undefined}
        >
          {t('To:')}
        </Text>
        {showButton ? (
          <TextButton
            css={[
              {
                display: 'flex',
                alignItems: 'center',
                gap: theme.spacing(0.5),
                color: color === 'white' ? theme.colors.white : theme.colors.primary50,
              },
              color === 'white' && {
                ':hover, :focus': {
                  backgroundColor: theme.colors.primary60,
                  borderColor: 'transparent',
                },
              },
            ]}
            {...getTriggerProps()}
          >
            {currentSelectedPhoneLabel}
            <Icon
              name='alt-caret-down-tiny'
              color={color === 'white' ? 'white' : 'light'}
              css={[{ transition: 'rotate 200ms ease-in-out' }, isOpen && { rotate: '-180deg' }]}
            />
          </TextButton>
        ) : (
          <Text
            size='medium'
            color={color === 'white' ? 'white' : 'default'}
            css={{ lineHeight: 1, padding: theme.spacing(1, 0.5) }}
          >
            {currentSelectedPhoneLabel}
          </Text>
        )}
      </span>
      {showButton && (
        <PopoverMenu {...getMenuProps()}>
          {validPhones.map(({ number, label }, index) => {
            const active = formatPhoneNumberE164(number) === formatPhoneNumberE164(personPhone);
            return (
              <PopoverMenuItem
                key={`${label}-${number}-${index}`}
                {...getItemProps({ index, onClick: () => onSelectPersonPhone(number) })}
                active={active}
              >
                {`${label} ${formatPhoneNumber(number)}`.trim()}
              </PopoverMenuItem>
            );
          })}
        </PopoverMenu>
      )}
    </>
  );
};
