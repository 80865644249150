import { css } from '@emotion/react';
import type { FieldWrapperStyleValues } from './field-wrapper-theme';
import { FieldWrapperProps, getFieldWrapperTheme, getFieldWrapperThemeOriginal } from './field-wrapper-theme';

const styles = (
  {
    borderColor,
    borderRadius,
    color,
    contextualBorderColor,
    spacing,
    fontFamily,
    fontSize,
    disabledColor,
    placeholderColor,
  }: FieldWrapperStyleValues,
  { disabled, hasPadding }: { disabled?: boolean; hasPadding?: boolean }
) => [
  css`
    height: 40px;
    border: 1px solid ${borderColor};
    border-radius: ${borderRadius};
    color: ${color};
    display: flex;
    align-items: center;
    margin: 0;

    position: relative;
    transition: outline-color 250ms ease-out;
    outline-color: transparent;
    border-color: ${contextualBorderColor};

    @supports (selector(:has(*))) {
      &:has(:is(input, textarea):focus) {
        box-shadow: 0 0 0 1px ${contextualBorderColor};
        // Outlines only respect the border radius in Chrome right now
        /* outline: solid 1px ${contextualBorderColor}; */
      }
    }

    @supports not (selector(:has(*))) {
      /**
     * Unfortunately, Firefox does not support :has without a feature flag.
     * This means that Firefox users will see the outline around the input and any other internal elements that have outlines
    **/
      &:focus-within {
        /* outline: solid 1px ${contextualBorderColor}; */
        box-shadow: 0 0 0 1px ${contextualBorderColor};
      }
    }

    input,
    textarea {
      width: 100%;
      background: transparent;
      border: none;
      color: ${disabled ? disabledColor : color};
      display: block;
      font-family: ${fontFamily};
      font-size: ${fontSize(16)};
      line-height: 1.375;
      text-align: left;
      -webkit-appearance: none;

      :focus {
        outline: none;
      }
    }

    input::placeholder,
    textarea::placeholder {
      color: ${placeholderColor};
    }

    /* anything other than a label before an input? needs left margin */
    > *:not(label) + input {
      margin-left: ${spacing(1)};
    }
    /* if the input isn't the last child, it needs right margin */
    input:not(:last-child) {
      margin-right: ${spacing(1)};
    }
  `,
  disabled &&
    css`
      svg {
        color: ${disabledColor};
      }
    `,
  hasPadding &&
    css`
      padding: 0;
      padding: ${spacing(0, 2)};
    `,
];

export const FieldWrapperStyles = {
  FieldWrapper: (fieldWrapperProps: FieldWrapperProps) =>
    styles(getFieldWrapperTheme(fieldWrapperProps), fieldWrapperProps),
};

export const FieldWrapperStylesOriginal: typeof FieldWrapperStyles = {
  FieldWrapper: (fieldWrapperProps: FieldWrapperProps) =>
    styles(getFieldWrapperThemeOriginal(fieldWrapperProps), fieldWrapperProps),
};
