import { css } from '@emotion/react';
import { styles } from '../../styles';
import {
  errorTextTheme,
  ErrorTextThemeValues,
  imagePreviewContainerTheme,
  imagePreviewContainerThemeOriginal,
  PreviewUploadThemeValues,
  previewUploadThemeValues,
  uploadButtonTheme,
  UploadButtonThemeValues,
  uploadContainerTheme,
  uploadContainerThemeOriginal,
  UploadContainerThemeValues,
} from './file-upload-theme';

type UploadStyleProps = { isDragging: boolean };

const containerStyles = () => css`
  display: flex;
  flex-flow: column;
  width: 100%;
`;

const boxStyles = (
  { borderRadius, borderColor, draggingBorderColor, backgroundColor, padding }: UploadContainerThemeValues,
  { isDragging }: UploadStyleProps
) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: ${borderRadius};
  border: ${isDragging ? 'solid' : 'dashed'} ${!isDragging ? borderColor : draggingBorderColor} 1px;
  background-color: ${backgroundColor};
  cursor: pointer;
  padding: ${padding(2)};
  font-size: 14px;
  margin-bottom: ${padding(2)};
`;

const iconStyles = () => css`
  transform: rotate(90deg);
`;

const buttonStyles = ({ borderColor, padding }: UploadButtonThemeValues) => css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  word-wrap: none;
  width: max-content;
  padding: ${padding(1.25, 2)};
  border: 1px solid ${borderColor};
  box-sizing: border-box;
  border-radius: ${padding(0.5)};
  margin-top: ${padding(2.5)};
  cursor: pointer;
`;

const imagePreviewContainerStyles = ({ padding }) => css`
  display: flex;
  flex-flow: row wrap;
  padding-bottom: ${padding(1.5)};
`;

const imagePreviewStyles = ({ spacing }: PreviewUploadThemeValues) => css`
  display: flex;
  flex-flow: column;
  align-content: center;
  max-width: 100px;
  margin-left: ${spacing(3)};
`;

const imagePreviewTextStyles = () => css`
  height: 100%;
  ${styles.truncate}
  flex:auto;
`;

const errorTextStyles = ({ color }: ErrorTextThemeValues) => css`
  color: ${color};
`;

export const FileUploadStyles = {
  FileUpload: {
    container: containerStyles(),
    box: (props: UploadStyleProps) => boxStyles(uploadContainerTheme, props),
    iconTranslate: iconStyles(),
    button: buttonStyles(uploadButtonTheme),
    imagePreviewContainer: imagePreviewContainerStyles(imagePreviewContainerTheme),
    imagePreview: imagePreviewStyles(previewUploadThemeValues),
    imagePreviewText: imagePreviewTextStyles(),
    errorText: errorTextStyles(errorTextTheme),
  },
};

export const FileUploadStylesOriginal = {
  FileUpload: {
    container: containerStyles(),
    box: (props: UploadStyleProps) => boxStyles(uploadContainerThemeOriginal, props),
    iconTranslate: iconStyles(),
    button: buttonStyles(uploadButtonTheme),
    imagePreviewContainer: imagePreviewContainerStyles(imagePreviewContainerThemeOriginal),
    imagePreview: imagePreviewStyles(previewUploadThemeValues),
    imagePreviewText: imagePreviewTextStyles(),
    errorText: errorTextStyles(errorTextTheme),
  },
};
