import { HTMLAttributes } from 'react';
import { useStyles } from '../../use-styles';

export type SpinnerSize = 'xl' | 'large' | 'medium' | 'small' | 'xs';

export type SpinnerColorType =
  | 'primary'
  | 'default'
  | 'light'
  | 'subdued'
  | 'disabled'
  | 'white'
  | 'error'
  | 'success'
  | 'warn';

export type SpinningLoaderProps = HTMLAttributes<HTMLDivElement> & {
  size?: SpinnerSize;
  color?: SpinnerColorType;
};

export const SpinningLoader = ({ size = 'large', color = 'primary', ...rest }: SpinningLoaderProps) => {
  const spinnerStyle = useStyles('Loaders', 'spinnerStyle', { size, color });
  return (
    <div css={spinnerStyle} {...rest}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};
