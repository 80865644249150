import { formatPhoneNumberE164 } from '@frontend/phone-numbers';
import { GetValidityIO } from '../types';
import { useSMSNumberV1Queries } from './use-number-v1-queries';
import { UseSMSNumberV1QueryEndpointArgs, useSMSNumberV1Query } from './use-number-v1-query';

/**
 * A hook that returns a query for the `GetValidity` endpoint.
 * @param request The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 * @param fallbackDataOnError (optional) The fallback data to use when an error occurs. This will not be persisted to the cache.
 */
export const useGetValidityQuery = <E = unknown, D = GetValidityIO['output']>({
  request,
  ...args
}: UseSMSNumberV1QueryEndpointArgs<'GetValidity', E, D>) => {
  return useSMSNumberV1Query({
    endpointName: 'GetValidity',
    request: (...args) => {
      const initialRequest = typeof request === 'function' ? request(...args) : request;
      return {
        ...initialRequest,
        phoneNumber: formatPhoneNumberE164(initialRequest.phoneNumber),
      };
    },
    ...args,
  });
};

/**
 * A hook that returns an array of queries for the `GetValidity` endpoint.
 * It takes an array of objects with the following properties:
 * @param request The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 * @param fallbackDataOnError (optional) The fallback data to use when an error occurs. This will not be persisted to the cache.
 */
export const useGetValidityQueries = <E = unknown, D = GetValidityIO['output']>(
  argsArr: UseSMSNumberV1QueryEndpointArgs<'GetValidity', E, D>[]
) =>
  useSMSNumberV1Queries<'GetValidity', E, D>(
    argsArr.map(({ request, ...args }) => ({
      endpointName: 'GetValidity',
      request: (...args) => {
        const initialRequest = typeof request === 'function' ? request(...args) : request;
        return {
          ...initialRequest,
          phoneNumber: formatPhoneNumberE164(initialRequest.phoneNumber),
        };
      },
      ...args,
    }))
  );
