import { Fragment, useCallback } from 'react';
import { DepartmentsQueries } from '@frontend/api-departments';
import { SMSDataV3 } from '@frontend/api-sms-data';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { formatPhoneNumber, formatPhoneNumberE164 } from '@frontend/phone-numbers';
import { theme } from '@frontend/theme';
import { PopoverMenu, PopoverMenuItem, Text, TextButton, usePopoverMenu } from '@frontend/design-system';
import { OutboundNumberData } from '../types';

type DepartmentOption = {
  id: string;
  name: string;
  number: string;
};

type DepartmentSelectorProps = {
  groupId: string;
  departmentId: string;
  onSelectDepartment: ({ departmentId, locationPhone }: OutboundNumberData) => void;
  alwaysShow?: boolean;
  labelType?: 'number' | 'name' | 'both';
  personPhone?: string;
  color?: 'white' | 'default';
};

/**
 * `DepartmentSelector` is a component that displays the currently selected outbound phone number and department name,
 * as well as a dropdown menu of departments that can be selected.
 * If there is only one department, the dropdown menu will not be displayed, and the triggering button will not be
 * shown, plain text will be displayed instead.
 *
 * @param groupId - The ID of the location to which the departments belong.
 * @param departmentId - The ID of the currently selected department.
 * @param onSelectDepartment - A function that is called when a department is selected.
 * @param alwaysShow - If true, the component will always be displayed, even if there is only one department.
 * @param labelType - The type of label to display. Can be 'number', 'name', or 'both'.
 *   - `'number'`: Only the phone number will be displayed. (e.g. "(123) 456-7890")
 *   - `'name'`: Only the department name will be displayed. (e.g. "Support") If no name is available, the phone
 *   number will be displayed instead.
 *   - `'both'`: Both the department name and phone number will be displayed. (e.g. "Support (123) 456-7890")
 * @param personPhone - The phone number of the person associated with the thread. If provided, the component will
 * group departments based on whether they have active conversations with the person.
 * @param color (optional) - The color of the text and icon of the button.
 */
export const DepartmentSelector = ({
  groupId,
  departmentId,
  onSelectDepartment,
  alwaysShow,
  labelType = 'both',
  personPhone,
  color = 'default',
}: DepartmentSelectorProps) => {
  const { t } = useTranslation('thread-header');

  const {
    data: { smsNumbers: departments },
  } = DepartmentsQueries.useListDefaultSMSQuery(
    {
      locationId: groupId,
    },
    {
      enabled: !!groupId,
    }
  );
  const departmentIds =
    departments?.reduce<string[]>((acc, { id }) => {
      if (id) acc.push(id);
      return acc;
    }, []) ?? [];
  const selectedDepartment = departments?.find(({ id }) => id === departmentId);
  const getLabel = useCallback(
    ({ name, number }: { name?: string; number?: string }) => {
      switch (labelType) {
        case 'both':
          return `${name ?? ''} ${formatPhoneNumber(number) || ''}`.trim();
        case 'name':
          if (name) return name;
        // If name doesn't exist, fall back to number
        // eslint-disable-next-line no-fallthrough
        case 'number':
          return formatPhoneNumber(number) || '';
      }
    },
    [labelType]
  );

  const reduceThreadsListToDepartmentIds = useCallback((data: (typeof existingThreadsQuery)['data']) => {
    return (
      data?.pages.reduce<string[]>((acc, page) => {
        if (!!page.threads.length) acc.push(...page.threads.map(({ departmentId }) => departmentId));
        return acc;
      }, []) ?? []
    );
  }, []);

  const existingThreadsQuery = SMSDataV3.Queries.useListThreadsQuery({
    request: {
      locationId: groupId,
      groupIds: [groupId],
      threadLimit: 500,
      personPhones: personPhone ? [formatPhoneNumberE164(personPhone)] : [],
      departmentIds,
    },
    options: {
      enabled: !!personPhone && !!departments?.length,
    },
  });
  const departmentsWithActiveConversations = reduceThreadsListToDepartmentIds(existingThreadsQuery.data);
  const departmentsWithoutActiveConversations = existingThreadsQuery.data
    ? departmentIds.filter((id) => !departmentsWithActiveConversations.includes(id))
    : [];
  const archivedThreadsQuery = SMSDataV3.Queries.useListThreadsQuery({
    request: {
      locationId: groupId,
      groupIds: [groupId],
      threadLimit: 500,
      personPhones: personPhone ? [formatPhoneNumberE164(personPhone)] : [],
      departmentIds: departmentsWithoutActiveConversations,
      isArchived: true,
    },
    options: {
      enabled: !!personPhone && !!departmentsWithoutActiveConversations.length,
    },
  });
  const departmentsWithArchivedConversations = departmentsWithoutActiveConversations.length
    ? reduceThreadsListToDepartmentIds(archivedThreadsQuery.data)
    : [];
  const departmentsWithoutConversations = departmentsWithoutActiveConversations.filter(
    (id) => !departmentsWithArchivedConversations?.includes(id) && !departmentsWithActiveConversations.includes(id)
  );
  const showButton = (departments?.length ?? 0) > 1;

  const { getMenuProps, getItemProps, getTriggerProps, isOpen } = usePopoverMenu({
    placement: 'bottom',
  });

  if (!showButton && !alwaysShow) return null;

  const mapDepartmentIdToPopoverOption = (id: string): DepartmentOption => {
    const department = departments?.find(({ id: deptId }) => deptId === id);
    return {
      id,
      number: department?.smsNumber?.number ?? '',
      name: department?.name ?? '',
    };
  };

  const popoverSections: { title: string; departments: DepartmentOption[] }[] = personPhone
    ? [
        {
          title: t('Active Conversations'),
          departments: departmentsWithActiveConversations?.map(mapDepartmentIdToPopoverOption) ?? [],
        },
        {
          title: t('New Conversations'),
          departments: departmentsWithoutConversations?.map(mapDepartmentIdToPopoverOption) ?? [],
        },
        {
          title: t('Archived Conversations'),
          departments: departmentsWithArchivedConversations?.map(mapDepartmentIdToPopoverOption) ?? [],
        },
      ].filter(({ departments }) => departments.length)
    : [
        {
          title: t('Departments'),
          departments: departmentIds.map(mapDepartmentIdToPopoverOption) ?? [],
        },
      ];

  return (
    <>
      <span
        css={{
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(showButton ? 0 : 0.5),
          justifyContent: 'center',
        }}
      >
        <Text
          color={color === 'white' ? 'white' : 'light'}
          size='medium'
          weight={color === 'white' ? 'bold' : undefined}
        >
          {t('From:')}
        </Text>
        {showButton ? (
          <TextButton
            css={[
              {
                display: 'flex',
                alignItems: 'center',
                gap: theme.spacing(0.5),
                color: color === 'white' ? theme.colors.white : theme.colors.primary50,
              },
              color === 'white' && {
                ':hover, :focus': {
                  backgroundColor: theme.colors.primary60,
                  borderColor: 'transparent',
                },
              },
            ]}
            {...getTriggerProps()}
          >
            {getLabel({ name: selectedDepartment?.name, number: selectedDepartment?.smsNumber?.number })}
            <Icon
              name='alt-caret-down-tiny'
              color={color === 'white' ? 'white' : 'light'}
              css={[{ transition: 'rotate 200ms ease-in-out' }, isOpen && { rotate: '-180deg' }]}
            />
          </TextButton>
        ) : (
          <Text
            size='medium'
            color={color === 'white' ? 'white' : 'default'}
            css={{ lineHeight: 1, padding: theme.spacing(1, 0.5) }}
          >
            {getLabel({ name: selectedDepartment?.name, number: selectedDepartment?.smsNumber?.number })}
          </Text>
        )}
      </span>
      {showButton && (
        <PopoverMenu {...getMenuProps()}>
          {popoverSections.map(({ title, departments }, i) => (
            <Fragment key={`department-picker-popover-section-${title}`}>
              <Text
                css={[
                  { padding: theme.spacing(1, 2) },
                  i !== 0 && { borderTop: `solid 1px ${theme.colors.neutral20}` },
                ]}
                weight='semibold'
              >
                {title}
              </Text>
              {departments.map(({ number, name, id }, index) => {
                const active = id === departmentId;

                if (!id) return null;

                return (
                  <PopoverMenuItem
                    key={id}
                    {...getItemProps({
                      index,
                      onClick: () => {
                        onSelectDepartment({ departmentId: id, locationPhone: number });
                      },
                    })}
                    active={active}
                  >
                    {getLabel({ name, number })}
                  </PopoverMenuItem>
                );
              })}
            </Fragment>
          ))}
        </PopoverMenu>
      )}
    </>
  );
};
