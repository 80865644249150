import { SerializedStyles, css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { theme as originalTheme } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../component-theme/theme-types';
import { getFontColorStyle } from '../../helpers';
import type { FontWeight, TextSize } from '../text/text-style-helpers';
import {
  getFontFamilyStyle,
  getFontSizeStyle,
  getFontWeightStyle,
  getTextAlignStyle,
} from '../text/text-style-helpers';

export type TextLinkThemeValues = {
  fontFamily: SerializedStyles;
  fontSize: SerializedStyles;
  fontColor: SerializedStyles;
  fontWeight: SerializedStyles;
  hoverColor: WeaveThemeValues['Color'];
};

export const getTextLinkTheme = ({ size, color, disabled, weight }: TextLinkProps) => ({
  fontFamily: getFontFamilyStyle(theme),
  fontSize: getFontSizeStyle(size, theme),
  fontColor: getFontColorStyle(disabled ? 'disabled' : color, theme),
  fontWeight: getFontWeightStyle(weight, theme),
  hoverColor: theme.colors.neutral80,
});

export const getTextLinkThemeOriginal = ({ size, color, disabled, weight }: TextLinkProps) => ({
  fontFamily: getFontFamilyStyle(originalTheme),
  fontSize: getFontSizeStyle(size, originalTheme),
  fontColor: getFontColorStyle(disabled ? 'disabled' : color, originalTheme),
  fontWeight: getFontWeightStyle(weight, originalTheme),
  hoverColor: originalTheme.colors.gray[600],
});

const buttonStyles: SerializedStyles = css`
  position: relative;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  /* Remove the margin in Safari */
  margin: 0;
  border-radius: 0;
  /* Remove the padding in Firefox */
  padding: 0;
  cursor: pointer;
  user-select: none;
  appearance: none;
  /* Remove Firefox dotted outline. */
  &::-moz-focus-inner {
    border-style: none;
  }
  &:focus-visible {
    outline: none;
  }
`;

const aTagStyles: SerializedStyles = css`
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

const disabledStyles: SerializedStyles = css`
  pointer-events: none;
  cursor: default;
`;

export type TextLinkProps = {
  isButton: boolean;
  size: TextSize;
  color: 'primary' | 'error';
  textAlign: any;
  weight: FontWeight;
  disabled: boolean | undefined;
};

const styles = (
  { fontFamily, fontSize, fontColor, fontWeight }: TextLinkThemeValues,
  { isButton, size, color, textAlign, weight, disabled }: TextLinkProps
) => css`
  ${isButton ? buttonStyles : aTagStyles};
  ${fontFamily};
  ${size && fontSize};
  ${color && fontColor};
  ${textAlign && getTextAlignStyle(textAlign)};
  ${weight && fontWeight};
  ${disabled && disabledStyles};
  ${!disabled &&
  `
    :hover,
    :focus {
      outline: none;
      text-decoration: underline;
    };
  `}
`;

export const TextLinkStyles = {
  TextLink: (textLinkProps: TextLinkProps) => styles(getTextLinkTheme(textLinkProps), textLinkProps),
};

export const TextLinkStylesOriginal: typeof TextLinkStyles = {
  TextLink: (textLinkProps: TextLinkProps) => styles(getTextLinkThemeOriginal(textLinkProps), textLinkProps),
};
