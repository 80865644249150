import { css } from '@emotion/react';
import type { TooltipStyleValues } from './tooltip-theme';
import { tooltipTheme, originalTooltipTheme } from './tooltip-theme';

type TooltipThemeProps = {
  theme: 'dark' | 'light';
};

const styles = ({
  background,
  color,
  fontWeight,
  fontSize,
  maxWidth,
  shadow,
  spacing,
  borderRadius,
  zIndex,
}: TooltipStyleValues) => {
  return css`
    background: ${background};
    border-radius: ${borderRadius};
    box-shadow: ${shadow};
    font-weight: ${fontWeight};
    font-size: ${fontSize};
    color: ${color};
    max-width: ${maxWidth};
    padding: ${spacing(1)};
    z-index: ${zIndex};
    .tooltip-floating-arrow {
      fill: ${background};
    }
  `;
};

export const TooltipStyles = {
  Tooltip: {
    tooltipStyles: (props: TooltipThemeProps) => styles(tooltipTheme(props.theme)),
  },
};

export const TooltipStylesOriginal: typeof TooltipStyles = {
  Tooltip: {
    tooltipStyles: (props: TooltipThemeProps) => styles(originalTooltipTheme(props.theme)),
  },
};
