import React, { RefObject, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import { onlyText } from 'react-children-utilities';
import { Row, TableInstance } from 'react-table';
import { useThemeValues } from '../../../hooks';
import { ForwardIcon } from '../../../icon';
import { useStyles } from '../../../use-styles';
import { IconButton } from '../../icon-button';
import { AnimatedExpandable } from '../atoms';
import { TableData } from '../table-data-type';
import { CellPositionCord, TableRowActions, TableExpandableRender, RowAction, CustomRowConfig } from '../table-types';
import { isReservedCellId } from '../utils';

type TableRowProps<T extends TableData> = {
  row: Row<T>;
  rowIdx: number;
  rowCountPerPage: number;
  isFocusable?: boolean;
  tableId: string;
  currentCellPositionRef?: RefObject<CellPositionCord>;
  rowActions?: TableRowActions<T>;
  expandableRowComponent?: TableExpandableRender<T>;
  tableClientWidth: number;
  isLoading?: boolean;

  tableInstance: TableInstance<T>;
};

export const TableRow = <T extends TableData>({
  row,
  rowIdx,
  rowCountPerPage,
  isFocusable,
  tableId,
  currentCellPositionRef,
  rowActions,
  expandableRowComponent,
  tableClientWidth,
  isLoading,
  tableInstance,
}: TableRowProps<T>) => {
  const { isMobile, customRowConfig, leftShift, mobileLayoutGlobalConfig, rowExpandingConfig } = tableInstance;
  const rowCardStyles = useStyles('TableCommonStyles', 'rowCardStyles');
  const rowCardContainerStyles = useStyles('TableCommonStyles', 'rowCardContainerStyles');

  const [isOpen, setIsOpen] = useState(false);
  const customRowConditional =
    typeof customRowConfig?.condition === 'boolean'
      ? customRowConfig?.condition
      : customRowConfig?.condition?.(row.values, row.original, row);
  const handleRowClick = () => {
    if (isLoading || isMobile || !!row?.isDisabled) return;
    if (rowActions?.onRowClick) {
      rowActions.onRowClick(row.original, row);
      return;
    }
    if (!!expandableRowComponent && !isExpandableRowDisabled) {
      row.toggleRowExpanded();
    }
  };

  const isExpandableRowDisabled =
    typeof rowExpandingConfig?.isDisabled === 'boolean'
      ? rowExpandingConfig?.isDisabled
      : rowExpandingConfig?.isDisabled?.(row.original, row);

  const handleExpandRow = () => {
    if (!!expandableRowComponent) {
      row.toggleRowExpanded();
    }
  };

  return useMemo(() => {
    const { key, ...rowProps } = !isMobile ? row.getRowProps() : { key: '' };

    return (
      <>
        <div
          css={[
            css`
              display: flex;
            `,
            isMobile && rowCardContainerStyles,
          ]}
          data-row-id={row.id}
          className='table-row-group'
        >
          {isMobile && (
            <MobileRowActions
              row={row}
              rowIdx={rowIdx}
              rowCountPerPage={rowCountPerPage}
              isFocusable={isFocusable}
              tableId={tableId}
              currentCellPositionRef={currentCellPositionRef}
              onRowClick={rowActions?.onRowClick}
              onRowSelect={rowActions?.onRowSelect}
              isExpandableRowDisabled={isExpandableRowDisabled}
              isLoading={isLoading}
              tableInstance={tableInstance}
              handleExpandRow={handleExpandRow}
              tableClientWidth={tableClientWidth}
            />
          )}
          <div
            key={key}
            {...rowProps}
            onClick={handleRowClick}
            className={`table__row ${row.isDisabled || isExpandableRowDisabled ? 'disabled' : ''}`}
            role='row'
            aria-selected={row.isSelected}
            aria-expanded={row.isExpanded}
            onMouseEnter={() => {
              if (rowActions?.shouldHover && !isMobile) setIsOpen(true);
            }}
            onMouseLeave={() => {
              if (rowActions?.shouldHover && !isMobile) setIsOpen(false);
            }}
            css={[
              isMobile && rowCardStyles,
              css`
                position: relative;
              `,
              leftShift && {
                marginLeft: leftShift,
              },
              mobileLayoutGlobalConfig?.cellGap && {
                gap: mobileLayoutGlobalConfig.cellGap,
              },
              mobileLayoutGlobalConfig?.cellHorizontalGap && {
                columnGap: mobileLayoutGlobalConfig?.cellHorizontalGap,
              },
              mobileLayoutGlobalConfig?.cellVerticalGap && {
                rowGap: mobileLayoutGlobalConfig?.cellVerticalGap,
              },
            ]}
          >
            {!customRowConditional &&
              row.cells.map((cell, idx) => {
                const isReservedCell = isReservedCellId(cell.column.id);
                const cellPosition: CellPositionCord = {
                  tableId,
                  x: idx,
                  y: rowIdx % rowCountPerPage,
                };

                if (isMobile && isReservedCell) return null;
                return (
                  <React.Fragment key={`row-${row.id}-cell-${cell.column.id}`}>
                    {cell.render('Cell', {
                      cellPosition,
                      currentCellPositionRef,
                      isFocusable,
                      onRowClick: rowActions?.onRowClick,
                      onRowSelect: rowActions?.onRowSelect,
                      hasExpandableRow: !!expandableRowComponent,
                      isExpandableRowDisabled,
                      isLoading,
                      tableId,
                      handleExpandRow,
                      tableClientWidth,
                      tableInstance,
                      cellTrackingId: cell.column.cellTrackingId,
                    })}
                  </React.Fragment>
                );
              })}
            {isOpen && !isLoading && rowActions?.shouldHover && !isMobile && (
              <RowHoverActions rowActions={rowActions} row={row} />
            )}
          </div>
        </div>
        {customRowConditional && <CustomRowComponent customRowConfig={customRowConfig} row={row} />}
        {!!expandableRowComponent && (
          <AnimatedExpandable isActive={row.isExpanded}>
            {expandableRowComponent(row.original, row.values, tableInstance)}
          </AnimatedExpandable>
        )}
      </>
    );
  }, [
    row,
    rowIdx,
    rowCountPerPage,
    isFocusable,
    tableId,
    currentCellPositionRef,
    rowActions?.onRowClick,
    row.isSelected,
    row.isExpanded,
    row.isDisabled,
    tableClientWidth,
    isOpen,
    rowActions?.actions,
    tableInstance?.state?.sortBy,
    tableInstance?.visibleColumns,
    customRowConditional,
    customRowConfig,
    isExpandableRowDisabled,
  ]);
};

type MobileRowActions<T extends TableData> = {
  row: Row<T>;
  rowIdx: number;
  rowCountPerPage: number;
  isFocusable?: boolean;
  tableId: string;
  currentCellPositionRef?: RefObject<CellPositionCord>;
  onRowClick?: TableRowActions<T>['onRowClick'];
  onRowSelect?: TableRowActions<T>['onRowSelect'];
  isLoading?: boolean;
  tableClientWidth: number;
  tableInstance: TableInstance<T>;
  handleExpandRow: () => void;
  isExpandableRowDisabled: boolean | undefined;
};

const MobileRowActions = <T extends TableData>({
  row,
  rowIdx,
  rowCountPerPage,
  isFocusable,
  tableId,
  currentCellPositionRef,
  onRowClick,
  onRowSelect,
  isLoading,
  tableClientWidth,
  tableInstance,
  handleExpandRow,
  isExpandableRowDisabled,
}: MobileRowActions<T>) => {
  const { spacing } = useThemeValues();
  const mobileRowActionsStyles = useStyles('TableCommonStyles', 'mobileRowActionsStyles', {
    isExpanded: row.isExpanded,
    isSelected: row.isSelected,
  });
  const allRowActions = row.cells.filter((cell) => isReservedCellId(cell.column.id));

  if (!allRowActions.length && !onRowClick) return null;

  return (
    <div
      css={[
        mobileRowActionsStyles,
        css`
          margin-bottom: ${spacing(1)};
        `,
      ]}
    >
      {onRowClick && (
        <IconButton
          css={css`
            margin-left: ${spacing(1)};
            ${!tableInstance.isSelectable && `margin-right: auto;`}
          `}
          label='Row Click'
          onClick={() => onRowClick(row.original, row)}
        >
          <ForwardIcon color='light' />
        </IconButton>
      )}
      {allRowActions.map((cell, idx) => {
        const cellPosition: CellPositionCord = {
          tableId,
          x: idx,
          y: rowIdx % rowCountPerPage,
        };
        return (
          <React.Fragment key={`row-${row.id}-cell-${cell.column.id}`}>
            {cell.render('Cell', {
              cellPosition,
              currentCellPositionRef,
              isFocusable,
              onRowClick,
              onRowSelect,
              isLoading,
              tableId,
              isExpandableRowDisabled,
              handleExpandRow,
              tableClientWidth,
              tableInstance,
            })}
          </React.Fragment>
        );
      })}
    </div>
  );
};

type RowHoverActions<T extends TableData> = {
  rowActions?: TableRowActions<T>;
  row: Row<T>;
};

const RowHoverActions = <T extends TableData>({ rowActions, row }: RowHoverActions<T>) => {
  const hoverActionsStyles = useStyles('TableCommonStyles', 'hoverRowActionsStyles');

  const hiddenActions = () => {
    if (rowActions?.actions) {
      return rowActions?.actions?.reduce((acc, { hide }) => {
        if (typeof hide !== 'function' ? hide : hide(row?.original)) {
          return acc + 1;
        }
        return acc;
      }, 0);
    }
    return 0;
  };

  if (hiddenActions() === rowActions?.actions?.length) return null;

  return (
    <div className='table__hover__actions'>
      <div
        css={css`
          width: 100%;
        `}
      ></div>
      <motion.div
        role='dialog'
        layoutId='table__hover__actions'
        onClick={(e) => e.stopPropagation()}
        transition={{ duration: 0.2 }}
        css={hoverActionsStyles}
      >
        {rowActions?.actions?.map(({ onClick, Icon, ...rest }, id) => {
          const { hide, ...dynamicProps } = Object.entries(rest).reduce(
            (acc, [key, value]) => ({
              ...acc,
              ...(key !== 'onClick' ? { [key]: typeof value !== 'function' ? value : value(row.original) } : {}),
            }),
            {} as Omit<RowAction<T>, 'onClick' | 'Icon'>
          );

          return (
            !hide && (
              <IconButton
                key={id}
                onClick={() => {
                  onClick?.(row.original, row);
                }}
                {...dynamicProps}
                showLabelOnHover
                label={onlyText(dynamicProps.label as React.ReactNode)}
              >
                <Icon />
              </IconButton>
            )
          );
        })}
      </motion.div>
    </div>
  );
};

export const CustomRowComponent = <T extends TableData>({
  customRowConfig,
  row,
}: {
  customRowConfig?: CustomRowConfig<T>;
  row: Row<T>;
}) => {
  const { render, isSticky, wrapperStyle } = customRowConfig ?? {};
  const { spacing } = useThemeValues();

  return (
    <article className='custom__row__container' style={{ display: 'flex' }}>
      <section
        className='custom__row__sticky'
        css={[
          css`
            display: flex;
            padding: ${spacing(2)};
          `,
          !isSticky &&
            css`
              flex: 1;
            `,
          isSticky &&
            css`
              position: sticky;
              left: 0;
            `,
          wrapperStyle,
        ]}
      >
        {render?.(row['values'], row['original'], row)}
      </section>
    </article>
  );
};
