import { FC } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { IconProps, Text, SecondaryButton } from '@frontend/design-system';

interface Props {
  size?: number;
  icon?: React.ComponentType<React.PropsWithChildren<IconProps>>;
}

export const containerStyle = css`
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const IconWithText: FC<React.PropsWithChildren<Props>> = ({ children, size = 14, icon, ...rest }) => {
  const iconSize = size + Math.floor(size / 4);
  const Icon = icon;
  return (
    <div css={containerStyle} {...rest}>
      <SecondaryButton
        css={css`
          width: max-content;
          padding: ${theme.spacing(1)};
        `}
      >
        {Icon && <Icon size={iconSize} />}
        <Text
          css={css`
            margin-left: ${theme.spacing(0.5)};
            font-size: ${theme.fontSize(size)};
          `}
        >
          {children}
        </Text>
      </SecondaryButton>
    </div>
  );
};
