import { css } from '@emotion/react';
import { dotTheme, dotThemeOriginal, DotThemeValues } from './dot-theme';
import { DotProps } from './types';

type DotStylesProps = Omit<DotProps, 'innerRef'>;

const DOT_SIZE = 16;

const commonStyles = css`
  border-radius: 50%;
  content: '';
  display: block;
  height: var(--dot-size);
  left: 0px;
  position: absolute;
  top: 0px;
  width: var(--dot-size);
`;

export const styles = (theme: DotThemeValues, { color, pulsing }: DotStylesProps) => [
  css`
    border-radius: 50%;
    background: ${theme.colors[color]};
    position: absolute;
  `,
  !pulsing &&
    css`
      width: ${DOT_SIZE}px;
      height: ${DOT_SIZE}px;
      border: 3px solid ${theme.colors.white};
    `,
  pulsing &&
    css`
      --dot-size: 8px;
      width: var(--dot-size);
      height: var(--dot-size);
      ::after {
        background: ${theme.colors[color]};
        ${commonStyles}
        animation: pulse 2s ease 0s infinite;
      }
      @keyframes pulse {
        0% {
          opacity: 0.75;
          transform: scale(1);
        }
        100% {
          opacity: 0;
          transform: scale(2);
        }
      }
    `,
];

export const DotStyles = {
  Dot: (color: DotStylesProps) => styles(dotTheme, color),
};

export const DotStylesOriginal: typeof DotStyles = {
  Dot: (color: DotStylesProps) => styles(dotThemeOriginal, color),
};
