import { Icon, IconProps } from '../Icon.component';

export const DragIconSmall: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={16} {...props} viewBox='0 0 16 16'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.33333 14C6.33333 13.0795 5.58714 12.3333 4.66667 12.3333C3.74619 12.3333 3 13.0795 3 14C3 14.9205 3.74619 15.6667 4.66667 15.6667C5.58714 15.6667 6.33333 14.9205 6.33333 14ZM12.3333 14C12.3333 13.0795 11.5871 12.3333 10.6667 12.3333C9.74619 12.3333 9 13.0795 9 14C9 14.9205 9.74619 15.6667 10.6667 15.6667C11.5871 15.6667 12.3333 14.9205 12.3333 14ZM4.66667 6.33333C5.58714 6.33333 6.33333 7.07953 6.33333 8C6.33333 8.92048 5.58714 9.66667 4.66667 9.66667C3.74619 9.66667 3 8.92048 3 8C3 7.07953 3.74619 6.33333 4.66667 6.33333ZM12.3333 8C12.3333 7.07953 11.5871 6.33333 10.6667 6.33333C9.74619 6.33333 9 7.07953 9 8C9 8.92048 9.74619 9.66667 10.6667 9.66667C11.5871 9.66667 12.3333 8.92048 12.3333 8ZM4.66667 0.333334C5.58714 0.333334 6.33333 1.07953 6.33333 2C6.33333 2.92048 5.58714 3.66667 4.66667 3.66667C3.74619 3.66667 3 2.92048 3 2C3 1.07953 3.74619 0.333334 4.66667 0.333334ZM12.3333 2C12.3333 1.07953 11.5871 0.333334 10.6667 0.333334C9.74619 0.333334 9 1.07953 9 2C9 2.92048 9.74619 3.66667 10.6667 3.66667C11.5871 3.66667 12.3333 2.92048 12.3333 2Z'
      fill='#202328'
    />
  </Icon>
);
