import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../../../component-theme/theme-types';

export type RadioThemeValues = {
  selectedColor: WeaveThemeValues['Color'];
  focusColor: WeaveThemeValues['Color'];
  disabledColor: WeaveThemeValues['Color'];
  borderColor: WeaveThemeValues['Color'];
};

export const radioTheme: RadioThemeValues = {
  selectedColor: theme.colors.primary50,
  focusColor: theme.colors.primary50,
  disabledColor: theme.colors.neutral30,
  borderColor: theme.colors.neutral40,
};

export const radioThemeOriginal: RadioThemeValues = {
  selectedColor: themeOriginal.colors.weaveBlue,
  focusColor: themeOriginal.colors.weaveBlue,
  disabledColor: themeOriginal.colors.gray[300],
  borderColor: themeOriginal.colors.gray[400],
};
