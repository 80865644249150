import { css } from '@emotion/react';
import { useMerchant } from '@frontend/payments-hooks';
import { PaymentsFlowType, StripeElementsWrapper } from '@frontend/payments-stripe-wrapper';
import { breakpoints } from '@frontend/responsiveness';
import { PaymentSettingsGroup } from '../atoms';
import { TerminalSettings, TerminalAddress, TerminalSettingsAction } from '../sections';

export const TerminalSettingsGroup = () => {
  return (
    <section>
      <StripeElementsWrapper mode='setup' type={PaymentsFlowType.Terminal}>
        <PaymentSettingsGroup>
          <TerminalSettingsActions />
          <TerminalSettings />
        </PaymentSettingsGroup>
      </StripeElementsWrapper>
    </section>
  );
};

const styles = {
  actionHeader: css`
    display: flex;
    flex-direction: column;
    @media (min-width: ${breakpoints.medium.min}) {
      flex-direction: row;
      justify-content: space-between;
    }
  `,
};

const TerminalSettingsActions = () => {
  const { stripeLocationId } = useMerchant();
  if (!stripeLocationId) return;
  return (
    <div css={styles.actionHeader}>
      <TerminalAddress />
      <TerminalSettingsAction />
    </div>
  );
};
