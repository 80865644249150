import { css, SerializedStyles } from '@emotion/react';
import type { Cell, Row, TableInstance } from 'react-table';
import { useTooltip, Text, TextLink, Chip } from '../../..';
import { CellConfig, CellRenderer, CellAlignType, CellConfigProps } from '../../table-types';
import { styles } from '../../../../styles';
import { useRef, useLayoutEffect, useState } from 'react';
import { TableData } from '../../table-data-type';

const textComponents = {
  Text: Text,
  TextLink: TextLink,
};

const mixedComponents = {
  Chip: Chip,
};

const customComponents = {
  ...textComponents,
  ...mixedComponents,
};

export const DefaultCell = <T extends TableData>({
  cellRenderer,
  cellValue,
  rowData,
  rowObject,
  cellConfig,
  tableClientWidth,
  cellAlign,
  isMobile,
  tableInstance,
}: {
  cellRenderer?: CellRenderer<T>;
  cellValue: Cell<T>['value'];
  rowData: T;
  rowObject: Row<T>;
  cellConfig?: CellConfig<T>;
  tableClientWidth: number;
  cellAlign?: CellAlignType;
  isMobile?: boolean;
  tableInstance: TableInstance<T>;
}) => {
  const textCellRef = useRef<HTMLElement | null>(null);
  const [shouldShowHoverLabel, setShouldShowHoverLabel] = useState(false);

  const { element, onClick, ...rest } = cellConfig || {};

  const Component = customComponents[element as string] ?? (element as React.ElementType) ?? 'span';

  const {
    hoverLabelMessage,
    showHoverLabel,
    hoverLabelTheme,
    css: cellConfigStyle,
    dataAttributes,
    customValue,
    ...dynamicProps
  } = Object.entries(rest).reduce(
    (acc, [key, value]) => ({
      ...acc,
      ...{ [key]: typeof value !== 'function' ? value : value(cellValue, rowData) },
    }),
    {} as Omit<CellConfig<T>, 'element'> & CellConfigProps & { css?: SerializedStyles }
  );

  const { Tooltip, tooltipProps, triggerProps } = useTooltip({
    placement: 'top',
    theme: hoverLabelTheme ?? 'dark',
  });

  const conditionalTriggerProps = showHoverLabel ?? shouldShowHoverLabel ? triggerProps : {};

  const conditionalOnClickEvent = onClick
    ? {
        onClick: (e) => onClick(customValue ?? cellValue, rowData, e),
      }
    : {};

  const dataAttributesProps = dataAttributes
    ? Object.keys(dataAttributes).reduce((acc, current) => {
        acc[`data-${current}`] = dataAttributes[current];
        return acc;
      }, {})
    : {};

  useLayoutEffect(() => {
    if (textCellRef?.current) {
      if (textCellRef?.current.scrollWidth > textCellRef?.current.offsetWidth) {
        setShouldShowHoverLabel(true);
      }
    }
  }, [tableClientWidth]);

  const isNonTextComponent = !!mixedComponents[element as keyof typeof mixedComponents];

  return (
    <>
      {cellRenderer ? (
        <span css={[cellAlignmentStyle, cellAlignStyle(cellAlign)]} {...conditionalTriggerProps}>
          {cellRenderer(cellValue, rowData, rowObject, isMobile, rowObject?.isDisabled, tableInstance)}
        </span>
      ) : (
        <>
          <span css={[cellAlignmentStyle, isNonTextComponent && cellAlignStyle(cellAlign)]}>
            {isNonTextComponent ? (
              <Component css={cellConfigStyle} {...dataAttributesProps} {...dynamicProps} {...conditionalOnClickEvent}>
                {customValue ?? cellValue}
              </Component>
            ) : (
              <Component
                ref={textCellRef}
                css={[
                  styles.truncate,
                  cellConfigStyle,
                  css`
                    text-align: ${cellAlign ?? 'left'};
                  `,
                ]}
                {...conditionalTriggerProps}
                {...dataAttributesProps}
                {...dynamicProps}
                {...conditionalOnClickEvent}
              >
                {customValue ?? cellValue}
              </Component>
            )}
          </span>
        </>
      )}
      {(showHoverLabel ?? shouldShowHoverLabel) && (
        <Tooltip {...tooltipProps}>
          {hoverLabelMessage ??
            customValue ??
            (cellRenderer ? cellRenderer(cellValue, rowData, rowObject, isMobile, rowObject?.isDisabled) : cellValue)}
        </Tooltip>
      )}
    </>
  );
};

const cellAlignStyle = (position: CellAlignType | undefined) => {
  switch (position) {
    case 'right':
      return css`
        justify-content: flex-end;
      `;
    case 'center':
      return css`
        justify-content: center;
      `;
    default:
      return;
  }
};

const cellAlignmentStyle = css`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
`;
