import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Heading, Info, Text } from '@frontend/design-system';
import { formatCentsToCurrency } from '../../utils';
import { PlaceholderSkeleton } from '../PlaceholderSkeleton';

type SummaryItemProps = {
  label: string;
  amount?: number;
  infoText?: string;
  loading: boolean;
  isAmountValue?: boolean;
  newStyle?: boolean;
};

const styles = {
  label: css`
    margin: ${theme.spacing(0)};
  `,
  newLabel: css`
    font-size: ${theme.font.size.medium};
    font-style: italic;
  `,
};

export const SummaryItem = ({
  label,
  amount,
  isAmountValue = true,
  infoText,
  loading,
  newStyle = false,
  ...rest
}: SummaryItemProps) => (
  <div
    css={css`
      display: flex;
      flex-flow: column;
    `}
  >
    <Text color='light' css={[styles.label, newStyle ? styles.newLabel : null]}>
      {label}
      {infoText && (
        <Info
          css={css`
            margin-left: ${theme.spacing(1)};
            color: ${theme.colors.neutral40};
          `}
          {...rest}
        >
          {infoText}
        </Info>
      )}
    </Text>
    <PlaceholderSkeleton
      loading={loading}
      as='span'
      height='1.25rem'
      margin={theme.spacing(1, 0, 0, 0)}
      css={{ display: 'block' }}
    >
      <Heading
        level={2}
        as='span'
        css={css`
          line-height: 1.65;
        `}
      >
        {(isAmountValue ? formatCentsToCurrency(amount) : amount) ?? '-'}
      </Heading>
    </PlaceholderSkeleton>
  </div>
);
