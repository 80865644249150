/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const ConfirmBadgeIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 Z M16.4388932,7.29289322 L9.948,13.7837864 L7.70710678,11.5428932 C7.31658249,11.1523689 6.68341751,11.1523689 6.29289322,11.5428932 C5.90236893,11.9334175 5.90236893,12.5665825 6.29289322,12.9571068 L9.24089322,15.9051068 C9.63141751,16.2956311 10.2645825,16.2956311 10.6551068,15.9051068 L17.8531068,8.70710678 C18.2436311,8.31658249 18.2436311,7.68341751 17.8531068,7.29289322 C17.4625825,6.90236893 16.8294175,6.90236893 16.4388932,7.29289322 Z'
    />
  </Icon>
);
