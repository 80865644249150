import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../../component-theme/theme-types';

export type CalContainerThemeValues = {
  spacing: WeaveThemeValues['Spacing'];
  primaryColor: WeaveThemeValues['Color'];
  disabledColor: WeaveThemeValues['Color'];
  disabledBorderColor: WeaveThemeValues['Color'];
  hoverBackgroundColor: WeaveThemeValues['Color'];
  backgroundColor: WeaveThemeValues['Color'];
  boldFontWeight: WeaveThemeValues['FontWeight'];
  zIndex: WeaveThemeValues['ZIndex'];
  ringWidth: 'calc(100%)' | 'calc(100% + 12px)';
  highlightColor: WeaveThemeValues['Color'] | null;
  focusColor: WeaveThemeValues['Color'];
  focusBackgroundColor: WeaveThemeValues['Color'];
  borderRadius: WeaveThemeValues['BorderRadius'];
};

export type CalWeekHeaderThemeValues = {
  color: WeaveThemeValues['Color'];
  spacing: WeaveThemeValues['Spacing'];
  fontSize: WeaveThemeValues['FontSize'];
  fontWeight: Record<string, string | number>;
};

export const calWeekHeaderTheme: CalWeekHeaderThemeValues = {
  color: theme.colors.neutral80,
  fontSize: theme.fontSize,
  spacing: theme.spacing,
  fontWeight: theme.font.weight,
};

export const calWeekHeaderThemeOriginal: CalWeekHeaderThemeValues = {
  color: themeOriginal.colors.gray[600],
  fontSize: themeOriginal.fontSize,
  spacing: theme.spacing,
  fontWeight: themeOriginal.font.weight,
};

export const calContainerTheme: CalContainerThemeValues = {
  spacing: theme.spacing,
  primaryColor: theme.colors.primary50,
  disabledColor: theme.colors.neutral60,
  disabledBorderColor: theme.colors.neutral5,
  hoverBackgroundColor: theme.colors.neutral5,
  backgroundColor: theme.colors.white,
  boldFontWeight: theme.font.weight.bold,
  zIndex: theme.zIndex.background,
  ringWidth: 'calc(100%)',
  highlightColor: theme.colors.neutral10,
  focusColor: theme.colors.neutral90,
  focusBackgroundColor: theme.colors.neutral10,
  borderRadius: theme.borderRadius.small,
};

export const calContainerThemeOriginal: CalContainerThemeValues = {
  spacing: themeOriginal.spacing,
  primaryColor: themeOriginal.colors.weaveBlue,
  disabledColor: themeOriginal.colors.gray[400],
  disabledBorderColor: themeOriginal.colors.gray[300],
  hoverBackgroundColor: themeOriginal.colors.gray[300],
  backgroundColor: themeOriginal.colors.white,
  boldFontWeight: themeOriginal.font.weight.bold,
  zIndex: themeOriginal.zIndex.background,
  ringWidth: 'calc(100% + 12px)',
  highlightColor: null,
  focusColor: themeOriginal.colors.white,
  focusBackgroundColor: themeOriginal.colors.weaveBlue,
  borderRadius: theme.borderRadius.small,
};
