import { useSpring, animated } from 'react-spring';

type Interpolate = (args: (val: number) => string) => string;

type AnimatedCircleProps = {
  scale: {
    interpolate: Interpolate;
  };
};

interface DotProps {
  color?: string;
  cx?: number;
  cy?: number;
  size: number;
}

export const ActiveDot = ({ cx, cy, size, color }: DotProps) => {
  const dotAnimation = useSpring({
    config: { duration: 500 },
    from: { scale: 0 },
    to: { scale: 1 },
  });

  return (
    <g>
      <animated.circle
        cx={cx}
        cy={cy}
        fill={color}
        opacity={0.3}
        r={size * 2}
        transform-origin={`${cx}px ${cy}px`}
        transform={(dotAnimation as AnimatedCircleProps).scale.interpolate((val) => `scale(${val})`)}
      />
      <animated.circle cx={cx} cy={cy} fill={color} r={size} />
    </g>
  );
};
