import { theme } from '@frontend/theme';
import { theme as originalTheme } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../component-theme/theme-types';

export const defaultBuffer = 16;

export type TooltipStyleValues = {
  background: WeaveThemeValues['Color'];
  color: WeaveThemeValues['Color'];
  fontWeight: 'bold' | 'normal';
  fontSize: ReturnType<WeaveThemeValues['FontSize']>;
  maxWidth: string;
  shadow: WeaveThemeValues['BoxShadow'];
  spacing: WeaveThemeValues['Spacing'];
  borderRadius: WeaveThemeValues['BorderRadius'];
  zIndex: WeaveThemeValues['ZIndex'];
};

export type TooltipTheme = 'dark' | 'light';

export const tooltipTheme = (lightOrDarkVersion: TooltipTheme): TooltipStyleValues => {
  const darkColor = theme.colors.neutral90;

  return {
    background: lightOrDarkVersion === 'dark' ? darkColor : theme.colors.white,
    color: lightOrDarkVersion === 'dark' ? theme.colors.white : darkColor,
    fontWeight: 'bold',
    fontSize: theme.font.size.medium,
    maxWidth: '270px',
    shadow: theme.shadows.floating,
    spacing: theme.spacing,
    borderRadius: theme.borderRadius.small,
    zIndex: theme.zIndex.hints,
  };
};

export const originalTooltipTheme = (lightOrDarkVersion: TooltipTheme): TooltipStyleValues => {
  const darkColor = originalTheme.colors.gray600;

  return {
    background: lightOrDarkVersion === 'dark' ? darkColor : originalTheme.colors.white,
    color: lightOrDarkVersion === 'dark' ? originalTheme.colors.white : darkColor,
    fontWeight: 'normal',
    fontSize: theme.font.size.medium,
    maxWidth: `calc(100vw - ${defaultBuffer * 2}px)`,
    shadow: 'none',
    spacing: originalTheme.spacing,
    borderRadius: originalTheme.borderRadius.small,
    zIndex: originalTheme.zIndex.hints,
  };
};
