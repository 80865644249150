import React, { forwardRef } from 'react';
import { css } from '@emotion/react';
import { hasComplexChildren } from 'react-children-utilities';
import { PolymorphicComponentPropWithRef, PolymorphicRef } from '../../../type-utils';
import { useStyles } from '../../../use-styles';
import { Text, TextProps } from '../../text';
import { useBreadcrumbs } from '../provider';

type BreadcrumbsPathProps<C extends React.ElementType> = PolymorphicComponentPropWithRef<
  C,
  TextProps & { currentPage?: boolean }
>;

export const BreadcrumbsPath = forwardRef(
  <C extends React.ElementType = 'p'>(
    { children, as, currentPage, size = 'small', onClick, ...rest }: BreadcrumbsPathProps<C>,
    ref: PolymorphicRef<C>
  ) => {
    const isInteractive = rest?.to || rest?.href || onClick;
    const { size: globalSize } = useBreadcrumbs();
    const breadcrumbsPathStyles = useStyles('BreadcrumbPath', 'breadcrumbPathStyle', {
      isInteractive: !!isInteractive,
    });

    const defaultElement = hasComplexChildren(children) ? 'div' : 'p';

    const conditionalProps = currentPage ? { 'aria-current': true } : {};
    const asProp = as || defaultElement;

    return (
      <li css={breadcrumbsPathStyles}>
        <Text
          css={css`
            display: flex;
            align-items: center;
            color: inherit;
            text-decoration: none;
            margin: 0;
          `}
          as={!isInteractive || currentPage ? defaultElement : asProp}
          onClick={isInteractive || !currentPage ? onClick : undefined}
          size={globalSize ?? size}
          ref={ref}
          {...rest}
          {...conditionalProps}
        >
          {children}
        </Text>
      </li>
    );
  }
);

BreadcrumbsPath.displayName = 'BreadcrumbsPath';
