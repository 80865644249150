import dayjs, { type Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);

// NOTE: This is a copy of the formatDate function from the shared library (libs/services/shared/src/utils/date.ts)
// We are copying it here to avoid dependency on the shared library to reduce bundle size of DS
export const formatDate = (
  date?: string | number | Date | Dayjs,
  formattedDate?: string,
  useOnlyUTC = false
): string => {
  if (!date) return '';

  let dateObj = typeof date === 'number' ? dayjs.unix(date) : dayjs(date);
  if (isNaN(dateObj.toDate().getTime())) return '';
  // adding this check to ignore DB default date (0001-01-01T00:00:00Z)
  if (dateObj.year() < 1900) return '';

  if (useOnlyUTC) dateObj = dateObj.utc();

  return dateObj.format(formattedDate);
};

export const getTodaysDate = (formattedDate: string) => dayjs().format(formattedDate);
