import { css } from '@emotion/react';
import type { TemplateCharCountThemeValues } from './template-char-count-theme';

export const templateCharCountStyle = ({ borderColor, spacing }: TemplateCharCountThemeValues) => css`
  border-top: solid 1px ${borderColor};
  padding-top: ${spacing(1)};
  margin-top: ${spacing(1)};
  text-align: right;
  margin-bottom: ${spacing(-1)};
`;
