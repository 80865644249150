import React from 'react';
import { ActionPressedChip } from '../atoms';
import { Text } from '../../text';

type BoldActionProps = Omit<React.ComponentProps<typeof ActionPressedChip>, 'children'>;

export const BoldAction = ({ compact = true, showBoxShadow = true, ...rest }: BoldActionProps) => {
  return (
    <ActionPressedChip {...rest} compact={compact} showBoxShadow={showBoxShadow}>
      <Text as='strong' weight='bold'>
        B
      </Text>
    </ActionPressedChip>
  );
};
