import { createProvider } from '@frontend/store';

export interface WidgetLibraryConfig {
  librarySearchTerm: string;
  setLibrarySearchTerm: (librarySearchTerm: string) => void;
  libraryFilterBy: string;
  setLibraryFilterBy: (filterBy: string) => void;
}

export const { Provider: WidgetLibraryProvider, useStore: useWidgetLibrary } = createProvider<WidgetLibraryConfig>()(
  (set) => ({
    librarySearchTerm: '',
    setLibrarySearchTerm: (librarySearchTerm) => {
      set({ librarySearchTerm });
    },
    libraryFilterBy: 'all',
    setLibraryFilterBy: (filterBy) => {
      set({ libraryFilterBy: filterBy });
    },
  })
);
