import { useMemo } from 'react';
import { css } from '@emotion/react';
import { Chart } from '@frontend/charts';
import { displayAmount } from '../../../utils';

interface ChartDataInfo {
  key: string;
  label: string;
  value: number;
}

interface Props<T extends ChartDataInfo[]> {
  data: T;
  colors?: Record<T[number]['key'], string>;
}

export const PaymentsPieChart = <T extends ChartDataInfo[]>({ data, colors }: Props<T>) => {
  const { labels, groups, legendValues } = useMemo(() => {
    return data.reduce<{
      labels: Record<string, string>;
      groups: { name: string; value: number }[];
      legendValues: Record<string, string | number>;
    }>(
      (acc, item) => {
        acc.labels[item.key] = item.label;
        acc.groups.push({
          name: item.key,
          value: item.value / 100,
        });
        acc.legendValues[item.key] = displayAmount(item.value / 100);
        return acc;
      },
      {
        labels: {},
        groups: [],
        legendValues: {},
      }
    );
  }, [data]);

  return (
    <Chart colors={colors} labels={labels} css={chartStyle}>
      <Chart.PieChart
        css={css`
          min-width: 50%;
          max-width: 50%;
        `}
        commonTooltipLabel='calls'
        appearance={{
          height: '100%',
          innerRadius: '65%',
          outerRadius: '90%',
          borderRadius: 5,
          paddingAngle: 5,
          hideTooltip: true,
        }}
        data={{
          responsiveCenterLabels: {
            primaryText: {
              label: (totalValue) => displayAmount(totalValue),
            },
            secondaryText: {
              label: 'Total Processed',
            },
          },
          groups,
        }}
      />
      <Chart.Legends
        layout='vertical'
        values={legendValues}
        css={{
          button: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 0,
          },
        }}
      />
    </Chart>
  );
};

const chartStyle = css({
  marginBottom: 0,
  flex: 1,
  '> div': {
    border: 'none',
    padding: 0,
    minHeight: 0,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
});
