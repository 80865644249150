import chroma, { ColorSpaces, Color } from 'chroma-js';
import { theme } from '@frontend/theme';
import type { ShellColorTheme } from './types';
import { encodeBase64, decodeBase64 } from '@frontend/string';
import { i18next } from '@frontend/i18n';

const joinLCHWithFixedDigits = (arr: number[], fixed = 3, separator = ' '): string => {
  const formattedValues = arr.map((val, index) => {
    if (index === 0 && val >= 100) {
      return '100';
    }
    if (isNaN(val)) {
      return '0';
    }
    return val.toFixed(fixed);
  });

  return formattedValues.join(separator);
};

const solidColorToGradient = (color: Color) => {
  const colorLCH = joinLCHWithFixedDigits(color.lch());
  return `linear-gradient(90deg, lch(${colorLCH}) 0%, lch(${colorLCH}) 100%)`;
};

function getOptimalContrastColor(backgroundColor: ColorSpaces['lch']): string {
  const blackLCH = chroma(theme.font.colors.default).lch();
  const whiteLCH = chroma(theme.font.colors.white).lch();

  const contrastBlack = Math.abs(backgroundColor[0] - blackLCH[0]) + Math.abs(backgroundColor[1] - blackLCH[1]);
  const contrastWhite = Math.abs(backgroundColor[0] - whiteLCH[0]) + Math.abs(backgroundColor[1] - whiteLCH[1]);

  return contrastBlack > contrastWhite ? theme.font.colors.default : theme.font.colors.white;
}

const generateSmoothGradient = (colors: Color[], angle: number): string => {
  if (colors.length === 1) {
    return solidColorToGradient(colors[0]);
  }
  const gradientStops = colors
    .map((color, index, arr) => {
      const position = (100 / (arr.length - 1)) * index;
      return `lch(${joinLCHWithFixedDigits(color.lch())}) ${position}%`;
    })
    .join(', ');
  return `linear-gradient(${angle}deg, ${gradientStops})`;
};

function getRandomArbitrary(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

const generateColorVariants = (baseColor: Color, numColors: number): ShellColorTheme => {
  const angle = Math.floor(Math.random() * 360);
  const hueOffset = 30;
  const colorVariants: Color[] = [];

  for (let i = 0; i < numColors; i++) {
    const hue = (baseColor.lch()[2] + (i - Math.floor(numColors / 2)) * hueOffset + 360) % 360;
    colorVariants.push(chroma.lch(baseColor.lch()[0] * getRandomArbitrary(0.8, 1.1), baseColor.lch()[1], hue));
  }

  const shellColor = generateSmoothGradient(colorVariants, angle);
  const sideNavColor = generateSmoothGradient(colorVariants, angle + 90);
  const hover = chroma(baseColor).brighten(2).lch();
  const averageColor = chroma.average(colorVariants, 'lch').lch();
  const iconColor = getOptimalContrastColor(averageColor);
  const activeIconColor = getOptimalContrastColor(hover);
  const helpColor = joinLCHWithFixedDigits(chroma(baseColor).darken(1.2).lch());

  return {
    shellColor,
    sideNavColor,
    hover: `lch(${joinLCHWithFixedDigits(hover)})`,
    iconColor,
    activeIconColor,
    helpColor: `lch(${helpColor})`,
  };
};

const MIN_SATURATION = 50;
const MIN_LIGHTNESS = 25;

export const getRandomColorTheme = (customColor?: string, maxNumColors?: number): ShellColorTheme => {
  const baseHue = Math.random() * 360;
  const baseSaturation = MIN_SATURATION + Math.random() * MIN_SATURATION;
  const baseLightness = MIN_LIGHTNESS + Math.random() * 65;
  const baseColor = customColor ? chroma(customColor) : chroma.lch(baseLightness, baseSaturation, baseHue);
  const numColors = Math.floor(getRandomArbitrary(maxNumColors ?? 1, maxNumColors ?? 5)) + 1; // Generate a random number between 1 and 5
  return generateColorVariants(baseColor, numColors);
};

export function compressThemeObject(theme: ShellColorTheme): string {
  const { shellColor, sideNavColor, hover, iconColor, activeIconColor, helpColor } = theme;

  const shellColorValue = removeLinearGradient(shellColor);
  const sideNavColorValue = removeLinearGradient(sideNavColor);

  if (areColorsDifferentOnlyInDegree(shellColorValue, sideNavColorValue)) {
    const sideNavColorDeg = extractDegree(sideNavColorValue);
    return encodeBase64(`${shellColorValue}-${sideNavColorDeg}-${hover}-${iconColor}-${activeIconColor}-${helpColor}`);
  } else {
    return encodeBase64(
      `${shellColorValue}|${sideNavColorValue}|${hover}|${iconColor}|${activeIconColor}|${helpColor}`
    );
  }
}

function removeLinearGradient(color: string): string {
  return color.replace(/^linear-gradient\(|\)$/g, '');
}

function areColorsDifferentOnlyInDegree(color1: string, color2: string): boolean {
  const color1WithoutDeg = color1.replace(/\d+deg/, '');
  const color2WithoutDeg = color2.replace(/\d+deg/, '');
  return color1WithoutDeg === color2WithoutDeg;
}

function extractDegree(color: string): string {
  const match = color.match(/\d+deg/);
  return match ? match[0] : '';
}

const decodingErrors = {
  invalidInput: i18next.t('Invalid theme code', { ns: 'base' }),
  invalidColors: i18next.t('The theme contains invalid color values.', { ns: 'base' }),
};

export function decompressThemeObject(base64String: string): ShellColorTheme {
  const compressedString = decodeBase64(base64String);
  if (compressedString.includes('|')) {
    return decompressMultipleDifferences(compressedString);
  } else {
    return decompressSingleDifference(compressedString);
  }
}

function extractLchAndSimpleHexColors(text: string) {
  const regex = /lch\([^()]*\)|#[^\s]*/g;
  const matches = [];
  let match;

  while ((match = regex.exec(text)) !== null) {
    matches.push(match[0]);
  }

  return matches;
}

const validateDecodedColors = (color: string) => {
  const colors = extractLchAndSimpleHexColors(color);

  return !!colors.length;
};

function decompressMultipleDifferences(compressedString: string): ShellColorTheme {
  const compressedThemeString = compressedString.split('|');

  if (compressedThemeString.length !== 6) {
    throw new Error(decodingErrors.invalidInput);
  }

  const [shellColorValue, sideNavColorValue, hover, iconColor, activeIconColor, helpColor] = compressedThemeString;

  if (!validateDecodedColors(shellColorValue) || !validateDecodedColors(sideNavColorValue)) {
    throw new Error(decodingErrors.invalidColors);
  }

  return {
    shellColor: `linear-gradient(${shellColorValue})`,
    sideNavColor: `linear-gradient(${sideNavColorValue})`,
    hover,
    iconColor,
    activeIconColor,
    helpColor,
  };
}

function decompressSingleDifference(compressedString: string): ShellColorTheme {
  const compressedThemeString = compressedString.split('-');

  if (compressedThemeString.length !== 6) {
    throw new Error(decodingErrors.invalidInput);
  }

  const [shellColorValue, sideNavColorDeg, hover, iconColor, activeIconColor, helpColor] = compressedThemeString;

  if (!validateDecodedColors(shellColorValue)) {
    throw new Error(decodingErrors.invalidColors);
  }

  return {
    shellColor: `linear-gradient(${shellColorValue})`,
    sideNavColor: `linear-gradient(${shellColorValue.replace(/\d+deg/, sideNavColorDeg)})`,
    hover,
    iconColor,
    activeIconColor,
    helpColor,
  };
}
