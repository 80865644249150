import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { BoldAction } from '../../molecules';
import { FORMAT_TEXT_COMMAND } from 'lexical';
import { useCallback } from 'react';
import { useToolbarState } from '../../providers';

export const Bold = () => {
  const [editor] = useLexicalComposerContext();
  const { isBold } = useToolbarState(['isBold']);

  const onBoldSelect = useCallback(() => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
  }, [editor]);

  return <BoldAction onClick={onBoldSelect} isActive={isBold} aria-label='Format Bold' />;
};

Bold.displayName = 'Bold';
