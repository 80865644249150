import { css } from '@emotion/react';
import { ElementType, forwardRef } from 'react';
import { animated } from 'react-spring';
import { FlyoutMenuProps } from './types';

export type AnimatedMenuProps = Omit<FlyoutMenuProps, 'actions' | 'onClose'> & {
  as?: keyof JSX.IntrinsicElements;
};

export const AnimatedMenu = forwardRef<HTMLElement, AnimatedMenuProps>(({ children, as = 'ul', ...rest }, ref) => {
  const Component = animated[as] as ElementType;
  return (
    <Component
      css={css`
        background: white;
        list-style-type: none;
        margin: 0;
        padding: 0;
        position: absolute;
      `}
      ref={ref}
      {...rest}
    >
      {children}
    </Component>
  );
});
