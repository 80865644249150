import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { useDebouncedBlur } from '../hooks/use-debounced-blur/use-debounced-blur';
import { BasicFormFieldProps, InlineLabelPlacement } from '../layouts/types';

type BoolListContextOptionProps = Omit<BasicFormFieldProps<'checkbox'>, 'label' | 'error' | 'touched'> & {
  labelPlacement?: InlineLabelPlacement;
  labelDisabled?: boolean;
};

type BoolListContextType = (optName: string) => BoolListContextOptionProps;

const BoolListContext = createContext<BoolListContextType>(() => ({} as BoolListContextOptionProps));

type Props = Pick<BasicFormFieldProps<'checklist'>, 'disabled' | 'id' | 'onBlur' | 'onFocus' | 'onChange' | 'value'> & {
  children: React.ReactNode;
  labelPlacement?: InlineLabelPlacement;
  labelDisabled?: boolean;
  name: string;
};

export const BoolListProvider = ({
  children,
  disabled,
  id,
  labelPlacement,
  labelDisabled,
  name,
  onBlur,
  onChange,
  onFocus,
  value,
}: Props) => {
  const debounceProps = useMemo(
    () => ({
      onBlur: () => {
        const payload: unknown = { target: { name } };
        onBlur(payload as React.FocusEvent<HTMLInputElement>);
      },
      onFocus: () => {
        const payload: unknown = { target: { name } };
        onFocus(payload as React.FocusEvent<HTMLInputElement>);
      },
    }),
    [name]
  );
  const { blur, focus } = useDebouncedBlur(debounceProps);

  const getOptionProps = useCallback(
    (optName: string) => ({
      active: false,
      'aria-invalid': false,
      disabled,
      id: `${id}-opt-${optName}`,
      labelPlacement,
      labelDisabled,
      name: optName,
      onBlur: blur,
      onFocus: focus,
      onChange: () => {
        onChange({ name, value: optName });
      },
      value: value.includes(optName),
    }),
    [disabled, blur, focus, labelPlacement, labelDisabled, value]
  );
  return <BoolListContext.Provider value={getOptionProps}>{children}</BoolListContext.Provider>;
};

export function useBoolListContext() {
  const context = useContext(BoolListContext);
  if (typeof context === 'undefined') {
    throw new Error('useBoolListContext must be used inside a BoolListProvider');
  }
  return context;
}
