export const generateGradientDefs = (id: string, color: string, opacity?: number) => {
  return (
    <defs>
      <linearGradient id={id} x1='0' y1='0' x2='0' y2='1'>
        <stop offset='5%' stopColor={color} stopOpacity={opacity ?? 0.3} />
        <stop offset='95%' stopColor={color} stopOpacity={0} />
      </linearGradient>
    </defs>
  );
};
