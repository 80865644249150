import { css } from '@emotion/react';
import { useThemeValues } from '../../hooks';
import React from 'react';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
  as?: React.ElementType;
  /**
   * For when ButtonBar is embedded in an element with external padding (eg Modal)
   * Only one of these props should be passed at once. They do not stack.
   */
  removeHorizontalSpacing?: boolean;
  removeSpacing?: boolean;
  removeVerticalSpacing?: boolean;
};

export const ButtonBar = ({
  children,
  as = 'div',
  removeHorizontalSpacing,
  removeSpacing,
  removeVerticalSpacing,
  ...rest
}: Props) => {
  const Component = as;

  let spacing = [2];
  if (removeSpacing) {
    spacing = [];
  } else if (removeHorizontalSpacing) {
    spacing = [2, 0];
  } else if (removeVerticalSpacing) {
    spacing = [0, 2];
  }
  const theme = useThemeValues();
  return (
    <Component
      css={[
        css`
          width: 100%;
          background: white;
          display: flex;
          justify-content: end;
          gap: ${theme.spacing(1)};
        `,

        spacing.length > 0 &&
          css`
            padding: ${theme.spacing.apply(null, spacing)};
          `,
      ]}
      {...rest}
    >
      {children}
    </Component>
  );
};
