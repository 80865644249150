import { useCallback } from 'react';
import { useInterRouter } from './inter-router';
import { SettingsRoutes } from './settings-routes';
import { useLocation } from '@tanstack/react-location';
import { useConstructSettingsLabel } from './use-construct-settings-label';
import type { PathSegments, RouteParams } from './types';
import type { SettingsSearchParams } from './settings-search-param-types';

type BaseNavigateArgs<T extends SettingsRoutes, C extends unknown = any> = {
  to: T;
  originLabel?: string;
  search?: SettingsSearchParams<T>;
  context?: C;
  replace?: boolean;
};

export type NavigateArgs<T extends SettingsRoutes, C extends unknown = any> = PathSegments<T> extends never
  ? BaseNavigateArgs<T, C>
  : BaseNavigateArgs<T, C> & { params: RouteParams<T> };

export const useSettingsNavigate = <C extends unknown = any>() => {
  const {
    setSettingsRouterPath,
    goBackSettings,
    setSettingsRouterPathHistory,
    closeSettings,
    isSettingsOpen,
    setIsSettingsOpen,
    settingsRouterPath,
    setOriginPageLabel,
    context,
  } = useInterRouter([
    'setSettingsRouterPath',
    'goBackSettings',
    'setSettingsRouterPathHistory',
    'closeSettings',
    'isSettingsOpen',
    'setIsSettingsOpen',
    'settingsRouterPath',
    'setOriginPageLabel',
    'context',
  ]);
  const { history } = useLocation();
  const constructLabel = useConstructSettingsLabel();

  const navigate = useCallback(
    function <T extends SettingsRoutes, C extends unknown = any>({
      to,
      originLabel,
      search,
      context,
      replace,
      ...rest
    }: NavigateArgs<T, C>) {
      let newPath: string = to;

      if ('params' in rest && rest.params) {
        for (const [key, value] of Object.entries<string>(rest.params as RouteParams<T>)) {
          newPath = newPath.replace(`:${key}`, value);
        }
      }

      const params = 'params' in rest ? (rest.params as RouteParams<T>) : {};

      if (settingsRouterPath.path !== newPath) {
        setSettingsRouterPathHistory({ path: newPath, raw: to, label: constructLabel(to, params), search, replace });
        setSettingsRouterPath({ path: newPath, raw: to, search, context, replace });
      }

      if (!isSettingsOpen) {
        setIsSettingsOpen(true);
        setOriginPageLabel(originLabel);
      }
    },
    [settingsRouterPath.path, isSettingsOpen]
  );

  const goBack = useCallback(() => {
    history.back();
    const lastItem = goBackSettings();

    if (lastItem) {
      setSettingsRouterPath({ path: lastItem.path, raw: lastItem.raw, search: lastItem.search });
      history.back();
    }

    if (!lastItem) {
      closeSettings();
    }
  }, []);

  return { navigate, goBack, closeSettings, context: context as C | undefined, isOpen: isSettingsOpen };
};
