import { PropsWithChildren, ReactNode } from 'react';
import { ManualFilterSectionHeader } from './manual-filter-section-header';
import { useStyles } from '../../../use-styles';
type ManualFilterSectionProps = PropsWithChildren<{
  sectionHeaderLabel: ReactNode;
  onClearClick?: (e: React.MouseEvent<HTMLElement>) => void;
  disableClear?: boolean;
  clearLabel?: string;
}>;

export const ManualFilterSection = ({
  sectionHeaderLabel,
  onClearClick,
  disableClear = false,
  clearLabel,
  children,
}: ManualFilterSectionProps) => {
  const filterSectionStyles = useStyles('TableCommonStyles', 'tableFilterStyles', {
    type: 'filterSection',
    extra: true,
  });

  return (
    <section css={filterSectionStyles}>
      <ManualFilterSectionHeader
        label={sectionHeaderLabel}
        disabled={disableClear}
        onClick={onClearClick}
        clearLabel={clearLabel}
      />
      {children}
    </section>
  );
};
