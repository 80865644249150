import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { useTranslation } from '@frontend/i18n';

type NewBadgeProps = {
  background?: 'light' | 'dark';
  className?: string;
};

const lightBackgroundColorScheme = css`
  background-color: ${theme.colors.primary10};
  border-radius: ${theme.borderRadius.small};
  border: 1px solid ${theme.colors.primary50};
  color: ${theme.colors.primary70};
`;

const darkBackgroundColorScheme = css`
  background-color: ${theme.colors.primary70};
  border-radius: ${theme.borderRadius.small};
  border: 1px solid ${theme.colors.primary50};
  color: ${theme.colors.white};
`;

/**
 *
 * @param `background` NewBadge needs to know what background it is on so that it is legible
 * however, since the only dark background in our app now is the global nav, we can assign a default of 'light'
 */
export const NewBadge = ({ background = 'light', ...rest }: NewBadgeProps = {}) => {
  const { t } = useTranslation();
  return (
    <p
      css={[
        css`
          align-items: center;
          content: 'new';
          display: flex;
          font-size: ${theme.fontSize(8)};
          font-weight: ${theme.font.weight.semibold};
          justify-content: center;
          padding: ${theme.spacing(0.4, 0.6, 0.25)};
          text-transform: uppercase;
        `,
        background === 'dark' ? darkBackgroundColorScheme : lightBackgroundColorScheme,
      ]}
      {...rest}
    >
      {t('New')}
    </p>
  );
};
