import { PhoneTreeV2 } from './types';

export enum Instruction {
  CallGroup = 'CallGroup',
  ForwardDevice = 'ForwardDevice',
  ForwardNumber = 'ForwardNumber',
  Play = 'Play',
  VoicemailBox = 'VoicemailBox',
  VoicemailPrompt = 'VoicemailPrompt',
  Location = 'Location',
  PhoneTree = 'PhoneTree',
  CallQueue = 'CallQueue',
  Hangup = 'Hangup',
  DataEndpoint = 'DataEndpoint',
  InstructionSet = 'InstructionSet',
  IVRMenu = 'IvrMenu',
  NotConfigured = 'NotConfigured',
  DepartmentId = 'DepartmentId',
}

export type AnyInstruction =
  | NotConfiguredInstruction
  | CallGroupInstruction
  | ForwardDeviceInstruction
  | ForwardNumberInstruction
  | PlayInstruction
  | VoicemailBoxInstruction
  | VoicemailPromptInstruction
  | PhoneTreeInstruction
  | LocationInstruction
  | CallQueueInstruction
  | HangupInstruction
  | DataEndpointInstruction
  | IVRInstruction
  | InstructionSetInstruction;

export type NotConfiguredInstruction = {
  type: Instruction.NotConfigured;
};

export type CallGroupInstruction = {
  type: Instruction.CallGroup;
  callGroup: {
    callGroupId: string;
    callerName?: string;
  };
};

export type ForwardDeviceInstruction = {
  type: Instruction.ForwardDevice;
  forwardDevice: {
    deviceId: string;
    callerName?: string;
  };
};

export type ForwardNumberInstruction = {
  type: Instruction.ForwardNumber;
  forwardNumber: {
    forwardingNumberId: string;
  };
};

export type PlayInstruction = {
  type: Instruction.Play;
  play: {
    mediaFileId: string;
  };
};

export type LocationInstruction = {
  type: Instruction.Location;
  location: {
    locationId: string;
    departmentId: string;
  };
};

export type CallQueueInstruction = {
  type: Instruction.CallQueue;
  callQueue: {
    callQueueId: string;
    callerName?: string;
  };
};

export type VoicemailBoxInstruction = {
  type: Instruction.VoicemailBox;
  voicemailBox: {
    voicemailBoxId: string;
  };
};

export type PhoneTreeInstruction = {
  type: Instruction.PhoneTree;
  phoneTree: PhoneTreeV2;
};

export type AnyVoicemailPrompt = VoicemailPromptSkipGreeting | VoicemailPromptGreeting | VoicemailPromptMedia;

export type VoicemailPromptInstruction<T extends AnyVoicemailPrompt = AnyVoicemailPrompt> = {
  type: Instruction.VoicemailPrompt;
  voicemailPrompt: T;
};

export type VoicemailPromptSkipGreeting = {
  voicemailBoxId: string;
  skipGreeting: boolean;
};
export type VoicemailPromptGreeting = {
  voicemailBoxId: string;
  voicemailGreetingId: string;
};
export type VoicemailPromptMedia = {
  voicemailBoxId: string;
  systemMediaId: string;
};

export type HangupInstruction = {
  type: Instruction.Hangup;
  hangup: Record<string, never>; //proper empty object. eg. {}
};

export type DataEndpointInstruction = {
  type: Instruction.DataEndpoint;
  dataEndpoint: {
    dataEndpointId: string;
  };
};

export type InstructionSetInstruction = {
  type: Instruction.InstructionSet;
  ConditionId: string;
  InstructionSet: {
    InstructionSetId: string;
    Instructions: AnyInstruction[];
  };
};

export type IVRInstruction = {
  type: Instruction.IVRMenu;
  ivrMenu: {
    phoneTreeId: string;
  };
};

/**
 * An InstructionSet is a set of instructions that starts with a certain instruction, and then the rest
 * can be anything. It at least has to have one instruction
 */
export type InstructionSet<T extends AnyInstruction | AnyInstruction[]> = [
  ...(T extends AnyInstruction[] ? T : [T]),
  ...AnyInstruction[]
];

//type guard helpers
export function isVoicemailPromptGreeting(
  voicemailPrompt: VoicemailPromptInstruction['voicemailPrompt']
): voicemailPrompt is VoicemailPromptGreeting {
  return 'voicemailGreetingId' in voicemailPrompt;
}

export function isVoicemailPromptMedia(
  voicemailPrompt: VoicemailPromptInstruction['voicemailPrompt']
): voicemailPrompt is VoicemailPromptMedia {
  return 'systemMediaId' in voicemailPrompt;
}
