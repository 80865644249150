import { css } from '@emotion/react';
import type { WeekViewDatePickerStyleValues } from './weak-view-date-picker-theme';
import { originalWeekViewDatePickerTheme, weekViewDatePickerTheme } from './weak-view-date-picker-theme';
import { ThemeProps } from './types';

const styles = ({
  background,
  borderRadius,
  color,
  disabledTextColor,
  hoverAndFocusBackgroundColor,
  hoverAndFocusTextColor,
  selectedBackgroundColor,
  selectedTextColor,
  spacing,
  subduedColor,
}: WeekViewDatePickerStyleValues) => {
  return css`
    background: ${background};
    border-radius: ${borderRadius};
    color: ${color};
    padding: ${spacing(3)};
    width: fit-content;

    .week-view-heading {
      text-align: center;
      margin-bottom: ${spacing(2)};
    }

    .week-day-bar {
      align-items: center;
      display: flex;
      gap: ${spacing(1)};

      .week-day-list {
        display: flex;
        flex-wrap: wrap;
        gap: ${spacing(1)};
        justify-content: center;

        .week-day-item {
          align-items: center;
          border-radius: ${borderRadius};
          cursor: pointer;
          display: flex;
          flex-direction: column;
          min-width: ${spacing(6)};
          overflow: hidden;
          padding: ${spacing(1)};
          transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
          width: ${spacing(6)};

          &:focus,
          &:hover {
            background: ${hoverAndFocusBackgroundColor};
            color: ${hoverAndFocusTextColor};
            outline: none;
          }

          &.selected,
          &[aria-selected='true'] {
            background-color: ${selectedBackgroundColor};
            color: ${selectedTextColor};
          }

          &.disabled {
            color: ${disabledTextColor};
            pointer-events: none;
          }

          &.pre-selected {
            box-shadow: 0 0 0 1px ${disabledTextColor};
          }

          span {
            color: inherit;
          }
        }
      }
    }

    .time-bar {
      align-items: center;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: ${spacing(4)};

      > div {
        max-width: ${spacing(18)};
      }

      @media screen and (min-width: ${spacing(60)}) {
        flex-direction: row;
        gap: ${spacing(3)};

        > p {
          margin-top: ${spacing(1)};
        }
      }
    }

    .action-bar {
      align-items: center;
      display: flex;
      gap: ${spacing(2)};
      justify-content: flex-end;
      margin-top: ${spacing(5)};

      button {
        width: auto;
      }

      p {
        color: ${subduedColor};
        margin: 0;
      }
    }
  `;
};

export const WeekViewDatePickerStyles = {
  WeekViewDatePicker: (props: ThemeProps) => styles(weekViewDatePickerTheme(props.theme)),
};

export const WeekViewDatePickerStylesOriginal: typeof WeekViewDatePickerStyles = {
  WeekViewDatePicker: (props: ThemeProps) => styles(originalWeekViewDatePickerTheme(props.theme)),
};
