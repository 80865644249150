import { useTransition, UseTransitionResult, SpringConfig, config as rsConfig } from 'react-spring';

export type SlideTransitionResponse = UseTransitionResult<boolean, React.CSSProperties>[];

export type SlideTransitionDirection = 'down' | 'up' | 'right' | 'left';

export type SlideTransitionProps = {
  active: boolean;
  direction: SlideTransitionDirection;
  distance?: string;
  config?: SpringConfig;
};

export const slideAxis = {
  down: 'translateY',
  left: 'translateX',
  right: 'translateX',
  up: 'translateY',
};

export const axisModifier = {
  down: '-',
  left: '',
  right: '-',
  up: '',
};

export const getEnterValue = (distance: string) => `0${distance.replace(/\d/g, '')}`;

export function useSlideTransition({
  active,
  direction,
  distance = '10px',
  config = rsConfig.default,
}: SlideTransitionProps): SlideTransitionResponse {
  return useTransition(active, null, {
    from: {
      transform: `${slideAxis[direction]}(${axisModifier[direction]}${distance})`,
    },
    enter: {
      transform: `${slideAxis[direction]}(${axisModifier[direction]}${getEnterValue(distance)})`,
    },
    leave: {
      transform: `${slideAxis[direction]}(${axisModifier[direction]}${distance})`,
    },
    config: config,
  });
}
