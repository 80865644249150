import { useEffect } from 'react';
import { css } from '@emotion/react';
import { endOfDay, startOfDay } from 'date-fns';
import dayjs from 'dayjs';
import { PaymentPlanFilterType, PaymentPlanStatus } from '@frontend/api-payment-plans';
import { useArrayState } from '@frontend/array';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { DateRangeField, ModalControlModalProps, TableFilters, TextField, useFormField } from '@frontend/design-system';
import { formatDisplayDate, hasFilter } from '../../../utils';
import { StatusItem } from './status-item';

export enum RecordedValue {
  recorded = 'recorded',
  notRecorded = 'not_recorded',
}

export type PaymentPlanFilterProps = {
  modalProps: ModalControlModalProps;
  initialFilter?: PaymentPlanFilterType;
  onApply: (filter: PaymentPlanFilterType) => void;
  setShowNotification: (value: boolean) => void;
};

const checkBoxStyle = css`
  margin: ${theme.spacing(1)};
  > label {
    margin: 0; /*Because bootstrap */
    margin-left: ${theme.spacing(2)};
  }
`;

const DATA_TRACKING_PREFIX = `pay-portal-payment-plans-filter`;

const getInitialDateRange = (filter?: PaymentPlanFilterType) => {
  const initialNextPaymentAt = filter?.nextPaymentAt;
  const initialDateRange = [
    initialNextPaymentAt?.greaterThanEqual ? formatDisplayDate(initialNextPaymentAt?.greaterThanEqual) : '',
    initialNextPaymentAt?.lessThanEqual ? formatDisplayDate(initialNextPaymentAt?.lessThanEqual) : '',
  ];
  const initialDateRangeValue = initialDateRange[0] && initialDateRange[1] ? initialDateRange : [];
  return initialDateRangeValue;
};

export const PaymentPlanFilter = ({
  initialFilter,
  onApply,
  modalProps,
  setShowNotification,
}: PaymentPlanFilterProps) => {
  const { t } = useTranslation('payments');

  useEffect(() => {
    setShowNotification?.(hasFilter(initialFilter));
  }, [initialFilter, setShowNotification]);

  // PAYMENT TYPE FILTER
  const [planStatus, setPlanStatus, addPlanStatus, removePlanStatus] = useArrayState<PaymentPlanStatus>(
    initialFilter?.status ?? [],
    [initialFilter?.status]
  );
  const personFieldProps = useFormField({ type: 'text', value: initialFilter?.search?.text });

  const initialDateRangeValue = getInitialDateRange(initialFilter);
  const dateRangeProps = useFormField(
    {
      type: 'dateRange',
      value: initialDateRangeValue,
    },
    [initialFilter?.nextPaymentAt]
  );

  const handleApply = () => {
    const filter: PaymentPlanFilterType = {};

    if (personFieldProps.value) filter.search = { ...filter.search, text: personFieldProps.value };
    if (planStatus.length) filter.status = planStatus;
    if (!!dateRangeProps.value[0]) {
      filter.nextPaymentAt = {
        greaterThanEqual: startOfDay(new Date(dateRangeProps.value[0])).toISOString(),
        lessThanEqual: !!dateRangeProps.value[1]
          ? endOfDay(new Date(dateRangeProps.value[1])).toISOString()
          : endOfDay(new Date()).toISOString(),
      };
    } else if (!!dateRangeProps.value[1]) {
      const getPreviousMonthDate = dayjs(dateRangeProps.value[1]).subtract(1, 'M');
      filter.nextPaymentAt = {
        greaterThanEqual: getPreviousMonthDate.toISOString(),
        lessThanEqual: endOfDay(new Date(dateRangeProps.value[1])).toISOString(),
      };
    } else {
      if (filter.nextPaymentAt) delete filter.nextPaymentAt;
    }

    setShowNotification?.(hasFilter(filter));
    onApply(filter);
  };

  return (
    <TableFilters {...modalProps} onApplyClick={handleApply}>
      <TableFilters.Section
        sectionHeaderLabel={t('Search')}
        disableClear={!personFieldProps.value}
        onClearClick={() => personFieldProps.onChange({ name: 'search-value', value: '' })}
        data-trackingid={`${DATA_TRACKING_PREFIX}-cbx-search`}
      >
        <TextField
          name='person-filter'
          label={t('Search Person or Amount')}
          placeholder={t('e.g. Person (First or Last Name), Amount')}
          {...personFieldProps}
          data-trackingid={`${DATA_TRACKING_PREFIX}-txt-search`}
        />
      </TableFilters.Section>
      <TableFilters.Section
        sectionHeaderLabel={t('Next Payment Due')}
        disableClear={!dateRangeProps.value.filter((v) => v).length}
        onClearClick={() => dateRangeProps.onChange({ name: 'set-default', value: [] })}
        data-trackingid={`${DATA_TRACKING_PREFIX}-cbx-next-payment-at`}
      >
        <DateRangeField {...dateRangeProps} label={t('Next Payment Due')} name='next-payment-at' />
      </TableFilters.Section>
      <TableFilters.Section
        sectionHeaderLabel={t('Status')}
        disableClear={!planStatus.length}
        onClearClick={() => setPlanStatus([])}
        data-trackingid={`${DATA_TRACKING_PREFIX}-cbx-status`}
      >
        {Object.entries(PaymentPlanStatus).map(([typeKey, typeValue]) => {
          return (
            <StatusItem
              key={typeValue}
              value={typeValue}
              label={t(typeKey)}
              planStatus={planStatus}
              onAdd={addPlanStatus}
              onRemove={removePlanStatus}
              css={checkBoxStyle}
              disabled={false}
            />
          );
        })}
      </TableFilters.Section>
    </TableFilters>
  );
};
