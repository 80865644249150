import { isFunction } from 'lodash-es';
import { TextLink } from '../../text-link';
import type { FieldKey } from '../hooks';
import { FieldLayout } from './field-layout.component';
import type { FieldWithActionProps } from './types';
import { css } from '@emotion/react';
import { ForwardedRef, ReactNode, forwardRef } from 'react';

type Props = FieldWithActionProps<FieldKey> & { endAdornment?: ReactNode };
const FieldLayoutWithActionInner = (
  { actionText = 'Save', actionTrackingId, onActionClick, endAdornment, actionEnabled, ...rest }: Props,
  ref?: ForwardedRef<HTMLDivElement>
) => (
  <FieldLayout
    ref={ref}
    {...rest}
    endAdornment={
      endAdornment ? (
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          {endAdornment}
        </div>
      ) : isFunction(onActionClick) ? (
        <TextLink
          size='large'
          trackingId={actionTrackingId}
          onClick={() => {
            if (!rest.error) onActionClick?.();
          }}
          disabled={actionEnabled ? false : !rest.value || !!rest.error || rest.disabled}
        >
          {actionText}
        </TextLink>
      ) : undefined
    }
  />
);

export const FieldLayoutWithAction = forwardRef(FieldLayoutWithActionInner);
