import { memo } from 'react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { NakedButton, Text, useTooltip } from '@frontend/design-system';
import { useChartContext } from '../chart.provider';
import { styles } from './styles';
import { SortType, Sorted } from './types';

type SortClickHandler = (key: string) => void;

type ColumnHeadersProps = {
  columnsOrder: string[];
  labels?: Record<string, string>;
  onSortClick?: SortClickHandler;
  showNameColumn?: boolean;
  sorted: Sorted | null;
  trackingIdBase?: string;
};

type SortButtonProps = {
  className: string;
  label: string;
  onClick?: SortClickHandler;
  sortedBy?: SortType;
  sortId: string;
  trackingId?: string;
};

export const ColumnHeaders = memo(
  ({ columnsOrder, labels, onSortClick, showNameColumn, sorted, trackingIdBase }: ColumnHeadersProps) => {
    const { labels: contextLabels } = useChartContext();

    return (
      <li css={[styles.leaderboardRow, styles.columnsHeader]} style={{ width: '100%' }}>
        {showNameColumn && (
          <div className='sticky-content'>
            <div className='serial' />
            <SortButton
              className='name'
              label={(labels ?? contextLabels)['name']}
              onClick={onSortClick}
              sortId='name'
              sortedBy={sorted?.key === 'name' ? sorted.type : 'none'}
              trackingId={`${trackingIdBase}-sort-name`}
            />
          </div>
        )}

        <div css={styles.dataCardsWrapper}>
          {columnsOrder.map((key) => {
            return (
              <SortButton
                className='card-space'
                key={key}
                label={(labels ?? contextLabels)[key] ?? key}
                onClick={onSortClick}
                sortId={key}
                sortedBy={sorted?.key === key ? sorted.type : 'none'}
                trackingId={`${trackingIdBase}-sort-${key}`}
              />
            );
          })}
        </div>
      </li>
    );
  }
);

ColumnHeaders.displayName = 'ColumnHeaders';

const SortButton = memo(({ className, label, onClick, sortId, sortedBy, trackingId }: SortButtonProps) => {
  const { t } = useTranslation('analytics');
  const {
    Tooltip: Tooltip,
    tooltipProps,
    triggerProps,
  } = useTooltip({
    placement: 'top',
  });
  const canSort = typeof onClick === 'function';

  return (
    <>
      {canSort ? (
        <NakedButton
          {...triggerProps}
          className={`${className} sort-button`}
          color='subdued'
          onClick={() => onClick?.(sortId)}
          style={
            sortedBy === 'asc' || sortedBy === 'desc'
              ? {
                  color: theme.font.colors.default,
                }
              : {
                  color: theme.font.colors.subdued,
                }
          }
          trackingId={trackingId}
        >
          {label} <Icon name={sortedBy === 'asc' ? 'alt-caret-up-tiny' : 'alt-caret-down-tiny'} />
        </NakedButton>
      ) : (
        <Text className={`${className} sort-button`} color='subdued' size='medium'>
          {label}
        </Text>
      )}

      {canSort && (
        <Tooltip {...tooltipProps}>
          <Text size='medium' color='white'>
            {sortedBy === 'none'
              ? t('Click to sort descending')
              : sortedBy === 'desc'
              ? t('Click to sort ascending')
              : t('Click to cancel sort')}
          </Text>
        </Tooltip>
      )}
    </>
  );
});

SortButton.displayName = 'SortButton';
