import { upperFirst } from 'lodash-es';
import { UsersTypes } from '@frontend/user-helpers';

export const stringArraysMatch = (a?: string[], b?: string[]) => {
  if (!a || !b) return false;
  return JSON.stringify(a.sort()) === JSON.stringify(b.sort());
};

export const mobileAccessId = 12;

export const validateConfirmPassword = ({ value }: any, { Password }: any) => {
  if (value !== Password.value) {
    return 'Password does not match';
  }
  return '';
};

export const formatAvailableRoles = (locationRoles: UsersTypes.UserLocationRole[]): UsersTypes.RolesModel[] | [] => {
  /**
   * Transform the available roles to what the multi-select plugin accepts
   * The mobileAccessId 12 is "restrict mobile access" meaning:
   * if they have the role they won't be able to access mobile access.
   * if they don't have the role they CAN access the Weave mobile app.
   */
  if (!locationRoles.length) return [];

  return locationRoles
    .filter((value) => ![value.ID, value.Type].includes(mobileAccessId))
    .map((value) => {
      return {
        value: value?.Name ?? '',
        label: upperFirst(value.Name),
        ID: value?.ID ?? value.Type,
        Type: value.Type,
        Name: value.Name,
      };
    });
};

export const formatMultiOfficeRoles = (
  userLocationRoles: Record<string, { roles: number[] }>,
  availableRoles: UsersTypes.RolesModel[],
  childLocations: UsersTypes.UserLocationModel[]
): UsersTypes.MultiOfficeRolesModel[] => {
  const formattedRoles: UsersTypes.MultiOfficeRolesModel[] = [];
  childLocations.forEach((location) => {
    if (userLocationRoles[location.id]) {
      const roles = availableRoles.filter((role) => userLocationRoles[location.id].roles.includes(role.ID!));
      formattedRoles.push({
        locationId: location.id,
        roles: roles.map((role) => role.Name),
        originalRoles: roles.map((role) => role.Name),
        access: true,
        name: location.name,
      });
    } else {
      formattedRoles.push({
        access: false,
        roles: [],
        name: location.name,
        locationId: location.id,
        originalRoles: [],
      });
    }
  });
  return formattedRoles;
};

export const hasMobileAccess = (roles: UsersTypes.UserRole[] = []) => {
  return !roles.some((val) => val.ID === mobileAccessId);
};

export const createPasswordPolicyReqBody = (
  policies: UsersTypes.PasswordPolicyGroup[],
  values: any,
  locationId: string
): UsersTypes.PasswordPolicyReqBody => {
  const settings = policies.map((pg: UsersTypes.PasswordPolicyGroup) => {
    pg.fields.forEach((f: UsersTypes.PasswordPolicyField) => {
      if (pg.key === 'minimumLength') {
        f.value = parseInt(values.minimumLength!);
      } else {
        Object.entries(values).forEach((v) => {
          const key = v[0] as string;
          const vals = v[1] as string | string[];
          if (key === pg.key) vals.includes(f.name) ? (f.checked = true) : (f.checked = false);
        });
      }
    });
    return pg;
  });
  return { location_id: locationId, settings };
};
