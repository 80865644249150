import emojiRegex from 'emoji-regex';
import { Text as SlateText, Transforms, Range, Point } from 'slate';
import { ReactEditor } from 'slate-react';

const ZERO_WIDTH_SPACE = '\u{200B}';

const hasEmoji = (str: string) => {
  const regex = emojiRegex();
  return regex.test(str);
};

const removeEmoji = (str: string, trim = false): string => {
  const regex = emojiRegex();
  const modified = str.replace(regex, '');
  return trim ? modified.replace(/\s+/g, ' ').trim() : modified;
};

export const withEmojiControl = (disableEmoji: boolean, editor: ReactEditor) => {
  const { insertText, insertData, normalizeNode } = editor;

  // Handles pasting emojis
  editor.insertData = (data) => {
    const originalText = data.getData('text/plain');

    if (disableEmoji && hasEmoji(originalText)) {
      const modifiedData = new DataTransfer();
      const modifiedText = removeEmoji(originalText, true);
      modifiedData.setData('text/plain', modifiedText);
      insertData(modifiedData);
      return;
    }

    insertData(data);
  };

  // Handles typing/inserting emojis
  editor.insertText = (text) => {
    if (disableEmoji && hasEmoji(text)) {
      // Instead of inserting an emoji, insert a series of zero space characters. This prevents the cursor from jumping forward.
      // These zero space characters will be removed in normalizeNode.
      const substitute = ZERO_WIDTH_SPACE.repeat(text.length);
      insertText(substitute);
      return;
    }

    insertText(text);
  };

  // Cleans up after insertText, ie: removes the zero with spaces
  editor.normalizeNode = (entry) => {
    const [node, path] = entry;

    if (disableEmoji && SlateText.isText(node) && node.text.includes(ZERO_WIDTH_SPACE)) {
      const selection = editor.selection;

      // This setTimeout can possibly mess up undo after the user manually tries to insert an emoji.
      // However, it is needed to prevent the cursor from jumping forward. So... 🤷‍♂️
      setTimeout(() => {
        Transforms.removeNodes(editor, { at: path });
        const allZeroWidthSpaces = new RegExp(ZERO_WIDTH_SPACE, 'g');
        const offset = (node.text.match(allZeroWidthSpaces) || []).length;
        const text = node.text.replaceAll(ZERO_WIDTH_SPACE, '');
        Transforms.insertNodes(editor, { text }, { at: path });

        if (selection) {
          const newLocation: Point = { path: selection.focus.path, offset: selection.focus.offset - offset };
          const newSelection: Range = { anchor: newLocation, focus: newLocation };
          Transforms.select(editor, newSelection);
        }
      }, 0);
    }

    normalizeNode(entry);
  };

  return editor;
};
