import { css } from '@emotion/react';
import { FloatingFocusManager, FloatingNode, FloatingPortal } from '@floating-ui/react-dom-interactions';
import { useThemeValues } from '../../../../hooks';
import React, { forwardRef, ReactNode } from 'react';
import type { UseDataPickerDialogResponse } from './use-date-picker';
import { AnimatePresence, motion } from 'framer-motion';
import { CalendarDialogCalendar } from './calendar-dialog-calendar.component';

type Props = UseDataPickerDialogResponse['dialogProps'] & {
  children: ReactNode;
  onFocus?: (event: React.FocusEvent<HTMLDialogElement>) => void;
};

export const DatePickerDialogPre = forwardRef<HTMLDialogElement, React.ComponentPropsWithoutRef<'dialog'> & Props>(
  ({ open, nodeId, context, children, labelId, descriptionId, ...props }: Props, ref) => {
    const theme = useThemeValues();

    return (
      <FloatingNode id={nodeId}>
        <FloatingPortal>
          <AnimatePresence initial={false}>
            {open && (
              <FloatingFocusManager context={context} returnFocus={false} initialFocus={props?.initialFocus}>
                <motion.dialog
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  ref={ref}
                  open={open}
                  css={css`
                    box-shadow: ${theme.shadows.heavy};
                    border: none;
                    border-radius: ${context.placement.includes('bottom')
                      ? theme.spacing(0, 0, 1, 1)
                      : theme.spacing(1, 1, 0, 0)};
                    overflow: auto;
                    background: white;
                    padding-top: 0;
                    :focus {
                      outline: none;
                    }
                    z-index: ${theme.zIndex.popover};
                  `}
                  aria-describedby={descriptionId}
                  aria-labelledby={labelId}
                  {...props}
                >
                  {children}
                </motion.dialog>
              </FloatingFocusManager>
            )}
          </AnimatePresence>
        </FloatingPortal>
      </FloatingNode>
    );
  }
);

const DatePickerDialogNamespace = Object.assign(DatePickerDialogPre, {
  Calendar: CalendarDialogCalendar,
});

export { DatePickerDialogNamespace as DatePickerDialog };
