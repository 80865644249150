import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../../../component-theme/theme-types';
import { transparentize } from '../../../../helpers';

export type SwitchThemeValues = {
  containerBackgroundColor: WeaveThemeValues['Color'];
  disabledContainerBackgroundColor: WeaveThemeValues['Color'];
  indeterminateBackgroundColor: WeaveThemeValues['Color'];
  activeBorderColor: WeaveThemeValues['Color'];
  disabledBorderColor: WeaveThemeValues['Color'];
  disabledFalseActiveTrueBorderColor: WeaveThemeValues['Color'];
  backgroundColor: WeaveThemeValues['Color'];
  focusRing: WeaveThemeValues['Color'];
  thumbEnabledColor: WeaveThemeValues['Color'];
  thumbDisabledColor: WeaveThemeValues['Color'];
  errorColor: WeaveThemeValues['Color'];
  zIndex: WeaveThemeValues['ZIndex'];
  iconZIndex: WeaveThemeValues['ZIndex'];
};

export const switchTheme: SwitchThemeValues = {
  containerBackgroundColor: theme.colors.primary50,
  disabledContainerBackgroundColor: theme.colors.neutral40,
  indeterminateBackgroundColor: theme.colors.primary20,
  activeBorderColor: theme.colors.primary50,
  disabledBorderColor: theme.colors.neutral40,
  disabledFalseActiveTrueBorderColor: theme.colors.neutral40,
  backgroundColor: transparentize(0.7, theme.colors.primary50),
  focusRing: theme.colors.primary50,
  thumbEnabledColor: theme.colors.white,
  thumbDisabledColor: theme.colors.white,
  errorColor: theme.colors.status.critical,
  zIndex: theme.zIndex.background,
  iconZIndex: theme.zIndex.low,
};

export const switchThemeOriginal: SwitchThemeValues = {
  containerBackgroundColor: themeOriginal.colors.white,
  disabledContainerBackgroundColor: themeOriginal.colors.white,
  indeterminateBackgroundColor: themeOriginal.colors.white,
  activeBorderColor: themeOriginal.colors.weaveBlue,
  disabledBorderColor: themeOriginal.colors.gray[300],
  disabledFalseActiveTrueBorderColor: themeOriginal.colors.weaveBlue,
  backgroundColor: themeOriginal.colors.gray[300],
  focusRing: themeOriginal.colors.weaveBlue,
  thumbEnabledColor: themeOriginal.colors.weaveBlue,
  thumbDisabledColor: themeOriginal.colors.gray[300],
  errorColor: themeOriginal.colors.error,
  zIndex: themeOriginal.zIndex.background,
  iconZIndex: themeOriginal.zIndex.low,
};
