/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const MinusIconSmall: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={16} {...props} viewBox='0 0 16 16'>
    <path
      fillRule='evenodd'
      d='M2,9 L14,9 C14.5522847,9 15,8.55228475 15,8 C15,7.44771525 14.5522847,7 14,7 L2,7 C1.44771525,7 1,7.44771525 1,8 C1,8.55228475 1.44771525,9 2,9 Z'
    />
  </Icon>
);
