import { useMemo } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import appConfig from '@frontend/env';

const STRIPE_KEY = appConfig.STRIPE_EXPRESS_API_KEY;

interface UseStripePromiseOptions {
  stripeExpressKey?: string;
}

export const useStripePromise = (options?: UseStripePromiseOptions) => {
  const stripeKey = options ? options.stripeExpressKey : STRIPE_KEY;
  const stripePromise = useMemo(() => (stripeKey ? loadStripe(stripeKey!) : Promise.resolve(null)), [stripeKey]);
  return stripePromise;
};
