import { forwardRef } from 'react';
import { useStyles } from '../../use-styles';
import { Hint } from '../hint/hint.component';
import type { HintUIProps } from '../hint/hint.component';
import { ThemeProps } from './types';

type Props = HintUIProps & Partial<ThemeProps>;

export const HoverLabel = forwardRef<HTMLParagraphElement, Props>(({ theme = 'dark', ...rest }, ref) => {
  const styles = useStyles('HoverLabel', { theme });

  return <Hint css={styles} {...rest} ref={ref} />;
});
