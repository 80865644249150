type Props = {
  activeLegends: string[];
  allLegends: string[];
  legendId: string;
  onActiveLegendsChange: (legends: string[]) => void;
};

export const handleActiveLegendChange = ({ activeLegends, allLegends, legendId, onActiveLegendsChange }: Props) => {
  const activeLegendsCount = activeLegends.length;
  const totalLegendsCount = allLegends.length;

  // If all legends are already visible
  // Mark only current legend visible and hide rest of them
  if (activeLegendsCount === totalLegendsCount) {
    onActiveLegendsChange([legendId]);
    return;
  }

  // If clicked legend is selected and some other legends are also already visible
  // Hide the clicked legend and keep rest of them visible
  if (activeLegends.includes(legendId) && activeLegendsCount !== 1 && activeLegendsCount < totalLegendsCount) {
    onActiveLegendsChange(activeLegends.filter((activeLegend) => activeLegend !== legendId));
    return;
  }

  // If clicked legend is not selected but some other are already visible
  // Make the clicked legend visible along with the previously visible legends
  // And do it using allLegends array to make sure the order of legends is maintained
  if (!activeLegends.includes(legendId) && activeLegendsCount < totalLegendsCount) {
    onActiveLegendsChange(allLegends.filter((key) => activeLegends.includes(key) || key === legendId));
    return;
  }

  // If clicked legend is selected but no other legends are visible
  // Mark all the legends visible
  if (activeLegends.includes(legendId) && activeLegendsCount === 1) {
    onActiveLegendsChange(allLegends);
  }

  // The next scenario could be that no legends are visible,
  // But it won't happen as the above check will make sure to keep some legneds are always selected
};
