import { FC, useMemo } from 'react';
import { css } from '@emotion/react';
import { PaymentType } from '@frontend/api-invoices';
import { PaymentMethod, PaymentMethodStatus, PaymentPlanQueries } from '@frontend/api-payment-plans';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { getCardBrand } from '@frontend/payments-card-brand';
import { titleCase } from '@frontend/string';
import { ComponentProps } from '@frontend/types';
import { theme } from '@frontend/theme';
import { IconButton, Text } from '@frontend/design-system';
import { useCardACHSelectionContext } from '../context';
import { PaymentMethodCardLoader, PaymentMethodCardUI } from './payment-method-card-ui';

export const PaymentMethodListLoader = () => {
  return (
    <div css={containerStyles}>
      {[1, 2].map((val) => (
        <PaymentMethodCardLoader key={val} />
      ))}
    </div>
  );
};

interface PaymentMethodListProps {
  type: 'card' | 'ach';
}
export const PaymentMethodList: FC<PaymentMethodListProps> = ({ type }) => {
  const { achList, cardList, isLoading } = useCardACHSelectionContext();

  if (isLoading) {
    return <PaymentMethodListLoader />;
  }

  if ((type === 'card' && cardList?.length === 0) || (type === 'ach' && achList?.length === 0)) {
    return null;
  }

  return (
    <div css={containerStyles}>
      {type === 'card' && cardList?.map((cardData) => <CardPaymentMethod key={cardData.id} cardData={cardData} />)}
      {type === 'ach' && (
        <>
          {achList?.map((achData) => (
            <ACHPaymentMethod key={achData.id} achData={achData} />
          ))}
          <ACHNonVerifiedMessage />
        </>
      )}
    </div>
  );
};

const CardPaymentMethod: FC<{ cardData: PaymentMethod }> = ({ cardData }) => {
  const { onDeleteAction, selectedPM, setSelectedPM } = useCardACHSelectionContext();
  const { t } = useTranslation('payments');

  return (
    <PaymentMethodCardUI
      brand={getCardBrand(cardData.card?.brand)}
      title={titleCase(cardData.card?.brand)}
      last4={cardData.card?.last4}
      expMonth={cardData.card?.expMonth}
      expYear={cardData.card?.expYear}
      methodId={cardData.id}
      selected={cardData.id === selectedPM?.id}
      onClick={() => setSelectedPM(cardData)}
      disabled={cardData.status === PaymentMethodStatus.PAYMENT_METHOD_STATUS_EXPIRED}
      status={cardData.status}
      actions={[
        {
          icon: 'trash',
          label: t('Delete'),
          onClick: () => onDeleteAction(cardData),
        },
      ]}
    />
  );
};

const ACHPaymentMethod: FC<{ achData: PaymentMethod }> = ({ achData }) => {
  const { t } = useTranslation('payments');
  const {
    disableACHPaymentMethods,
    disableACHPaymentMessage,
    onDeleteAction,
    selectedPM,
    setSelectedPM,
    openResendACHVerification,
  } = useCardACHSelectionContext();

  const isVerificationPending = achData.status === PaymentMethodStatus.PAYMENT_METHOD_STATUS_VERIFICATION_PENDING;
  const title = isVerificationPending ? t('Customer Verification Pending') : titleCase(achData.bankAccount.bankName);
  const description = isVerificationPending ? t('Have customer validate through the link in their email') : undefined;
  const last4 = isVerificationPending ? '' : achData.bankAccount?.last4;

  const isDisabled =
    disableACHPaymentMethods ||
    (achData.status &&
      [
        PaymentMethodStatus.PAYMENT_METHOD_STATUS_VERIFICATION_PENDING,
        PaymentMethodStatus.PAYMENT_METHOD_STATUS_EXPIRED,
      ].includes(achData.status));

  const actions = useMemo(() => {
    const actionList: ComponentProps<typeof PaymentMethodCardUI>['actions'] = [];
    if (isVerificationPending) {
      actionList.push({
        icon: 'send-back',
        label: t('Resend Verification'),
        onClick: openResendACHVerification,
      });
    } else {
      actionList.push({
        icon: 'trash',
        label: t('Delete'),
        onClick: () => onDeleteAction(achData),
        disabled: disableACHPaymentMethods,
      });
    }

    return actionList;
  }, [isVerificationPending, disableACHPaymentMethods, onDeleteAction]);

  return (
    <PaymentMethodCardUI
      brand={PaymentType.ACH}
      title={title}
      description={description}
      last4={last4}
      methodId={achData.id}
      selected={achData.id === selectedPM?.id}
      disabled={isDisabled}
      status={achData.status}
      onClick={() => setSelectedPM(achData)}
      tooltipMessage={disableACHPaymentMethods ? disableACHPaymentMessage : ''}
      actions={actions}
    />
  );
};

const ACHNonVerifiedMessage = () => {
  const { achList, patientId } = useCardACHSelectionContext();
  const { invalidatePaymentMethodsByPerson } = PaymentPlanQueries.usePaymentPlansInvalidation();
  const { t } = useTranslation('payments');

  const hasNonVerifiedACH = useMemo(() => {
    return achList?.some((ach) => ach.status === PaymentMethodStatus.PAYMENT_METHOD_STATUS_VERIFICATION_PENDING);
  }, [achList]);

  if (!hasNonVerifiedACH) {
    return null;
  }

  return (
    <div css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1), justifyContent: 'space-between' }}>
      <Text size='medium' css={{ fontStyle: 'italic' }}>
        {t('It may take a minute for authorization to show in app. Refresh if needed')}
      </Text>
      <IconButton
        label={t('Refresh')}
        onClick={() => {
          invalidatePaymentMethodsByPerson(patientId);
        }}
      >
        <Icon name='update-small' />
      </IconButton>
    </div>
  );
};

const containerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  paddingRight: theme.spacing(2.25),
  gap: theme.spacing(2),
  flex: 1,
  flexShrink: 0,
  maxHeight: 280,
});
