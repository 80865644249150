/**
 * Replacement of polished lib
 * https://bit.cloud/styled-components/polished/color/rgb/~code
 */
import { reduceHexValue } from './reduce-hex-value';
import { numberToHex } from './number-to-hex';
import { PolishedError } from './errors';
import type { RgbColor } from './color.types';

/**
 * Returns a string value for the color. The returned result is the smallest possible hex notation.
 */
function rgb(value: RgbColor | number, green?: number, blue?: number): string {
  if (typeof value === 'number' && typeof green === 'number' && typeof blue === 'number') {
    return reduceHexValue(`#${numberToHex(value)}${numberToHex(green)}${numberToHex(blue)}`);
  } else if (typeof value === 'object' && green === undefined && blue === undefined) {
    return reduceHexValue(`#${numberToHex(value.red)}${numberToHex(value.green)}${numberToHex(value.blue)}`);
  }

  throw new PolishedError(6);
}

export { rgb };
