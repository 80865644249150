import MaskedInput, { conformToMask } from '../../../helpers/text-mask';
import type { InputProps } from './types';
import { PostalCodeLocale } from '../hooks/types';
import type { MaskConfig } from './mask-types';
import { getMaskConfigProps } from './utils';

const postalCodeMasks = {
  [PostalCodeLocale.CA]: [/[A-Z]/i, /\d/, /[A-Z]/i, ' ', /\d/, /[A-Z]/i, /\d/],
  [PostalCodeLocale.US]: [/\d/, /\d/, /\d/, /\d/, /\d/],
};

export const getPostalCodeMask = (locale: PostalCodeLocale = PostalCodeLocale.US) => postalCodeMasks[locale];

const patterns = {
  [PostalCodeLocale.CA]: '[A-Z][0-9][A-Z] [0-9][A-Z][0-9]',
  [PostalCodeLocale.US]: '[0-9]*',
};

function upperCasePipe(conformedValue: string) {
  return conformedValue.toUpperCase();
}

export const PostalCodeInput = ({
  locale = PostalCodeLocale.US,
  value = '',
  ...rest
}: InputProps<'postalCode'> & MaskConfig) => {
  const { maskConfigProps, nonConfigProps } = getMaskConfigProps(rest);

  return (
    <MaskedInput
      {...nonConfigProps}
      pattern={patterns[locale]}
      mask={getPostalCodeMask(locale)}
      type='text'
      guide={maskConfigProps?.guide}
      pipe={locale === PostalCodeLocale.US ? undefined : upperCasePipe}
      placeholderChar={maskConfigProps?.placeholderChar}
      keepCharPositions={maskConfigProps?.keepCharPositions}
      value={value.trim() ? conformToMask(value, getPostalCodeMask(locale), maskConfigProps).conformedValue : value}
    />
  );
};
