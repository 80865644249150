import React from 'react';
import { ComponentPropsWithoutRef } from 'react';

import { NakedButton } from '../../../naked';
import { useStyles } from '../../../use-styles';
import { mergeClasses } from '../../../helpers';
import type { BaseAtomProps } from './calendar-atom.types';
import { CaretDownIconSmall, CaretUpIconSmall } from '../../../icon';
import { useCalendarState } from '../provider';
import { SelectByType } from '../calendar-types';
import { getDecadeRange } from '../provider/reducer';
import { Text } from '../../';
import { formatDate } from '../../../helpers';

type Props = BaseAtomProps & {
  month: number;
  year: number;
};
type CalendarTitleProps = Props & Omit<ComponentPropsWithoutRef<'button'>, keyof Props>;

const formatTitle = (month: number, year: number, selectBy: SelectByType) => {
  switch (selectBy) {
    case 'day':
      return formatDate(new Date(year, month), 'MMMM YYYY');
    case 'month':
      return year;
    case 'year': {
      const decade = getDecadeRange(year);
      return `${decade[0]} - ${decade[decade.length - 1]}`;
    }
  }
};

export const CalendarTitle = ({ className, month, year, ...rest }: CalendarTitleProps) => {
  const { selectByBtnProps, selectBy } = useCalendarState();
  const titleHeader = useStyles('CalendarControls', 'titleHeader');
  return (
    <React.Fragment>
      {selectBy !== 'year' ? (
        <NakedButton {...selectByBtnProps} css={titleHeader} type='button' {...rest}>
          <time dateTime={`${year}-${month}`} className={mergeClasses('cal__month-name', className)}>
            {formatTitle(month, year, selectBy)}
          </time>
          <span>
            <CaretUpIconSmall color='light' />
            <CaretDownIconSmall color='light' />
          </span>
        </NakedButton>
      ) : (
        <Text as='h4' weight='bold' className={mergeClasses('cal__month-name', className)}>
          {formatTitle(month, year, selectBy)}
        </Text>
      )}
    </React.Fragment>
  );
};
