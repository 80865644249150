import { WeaveThemeValues } from '../../component-theme/theme-types';
import { theme } from '@frontend/theme';
import { theme as originalTheme } from '@frontend/theme-original';

export type TabStyleValues = {
  background: WeaveThemeValues['Color'];
  color: WeaveThemeValues['Color'];
  fontFamily: WeaveThemeValues['FontFamily'];
  fontSize: ReturnType<WeaveThemeValues['FontSize']>;
  fontWeight: WeaveThemeValues['FontWeight'];
  hoverAndFocusBackground: WeaveThemeValues['Color'];
  hoverAndFocusColor: WeaveThemeValues['Color'];
  selectedBackground?: WeaveThemeValues['Color'];
  selectedFontColor?: WeaveThemeValues['Color'];
  disabledFontColor?: WeaveThemeValues['Color'];
};

export type TabBarStyleValues = {
  slidingBorderColor: WeaveThemeValues['Color'];
  borderBottomColor: WeaveThemeValues['Color'];
  spacing: WeaveThemeValues['Spacing'];
};

export type ScrollArrowStyleValues = {
  spacing: WeaveThemeValues['Spacing'];
  disabledColor: WeaveThemeValues['Color'];
  leftShadow: WeaveThemeValues['BoxShadow'];
  rightShadow: WeaveThemeValues['BoxShadow'];
};

export const tabTheme: TabStyleValues = {
  background: theme.colors.neutral80,
  color: theme.font.colors.default,
  fontFamily: theme.font.family,
  fontSize: theme.font.size.medium,
  fontWeight: theme.font.weight.bold,
  hoverAndFocusBackground: theme.colors.neutral5,
  hoverAndFocusColor: theme.colors.neutral80,
  selectedFontColor: theme.colors.primary50,
  disabledFontColor: theme.font.colors.disabled,
};

export const originalTabTheme: TabStyleValues = {
  background: originalTheme.colors.weaveBlue,
  color: originalTheme.font.colors.light,
  fontFamily: originalTheme.font.family,
  fontSize: originalTheme.font.size.medium,
  fontWeight: originalTheme.font.weight.bold,
  hoverAndFocusBackground: originalTheme.colors.gray200,
  hoverAndFocusColor: originalTheme.colors.gray600,
  selectedFontColor: originalTheme.colors.pressedBlue,
  disabledFontColor: originalTheme.font.colors.disabled,
};

export const tabBarTheme: TabBarStyleValues = {
  slidingBorderColor: theme.colors.primary50,
  borderBottomColor: theme.colors.neutral20,
  spacing: theme.spacing,
};

export const originalTabBarTheme: TabBarStyleValues = {
  slidingBorderColor: originalTheme.colors.weaveBlue,
  borderBottomColor: originalTheme.colors.gray300,
  spacing: originalTheme.spacing,
};

export const scrollArrowTheme: ScrollArrowStyleValues = {
  spacing: theme.spacing,
  disabledColor: theme.colors.neutral20,
  leftShadow: theme.shadows.innerLeft,
  rightShadow: theme.shadows.innerRight,
};

export const originalScrollArrowTheme: ScrollArrowStyleValues = {
  spacing: originalTheme.spacing,
  disabledColor: originalTheme.colors.gray300,
  leftShadow: originalTheme.shadows.innerLeft,
  rightShadow: originalTheme.shadows.innerRight,
};
