import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../component-theme/theme-types';

export type BannerNotificationThemeValues = {
  colors: {
    warn: WeaveThemeValues['Color'];
    success: WeaveThemeValues['Color'];
    error: WeaveThemeValues['Color'];
    info: WeaveThemeValues['Color'];
    textColor: WeaveThemeValues['Color'];
  };
  borderColor: {
    warn: WeaveThemeValues['Color'];
    success: WeaveThemeValues['Color'];
    error: WeaveThemeValues['Color'];
    info: WeaveThemeValues['Color'];
  };
  fontSize: WeaveThemeValues['FontSize'];
};

export const bannerNotificationTheme: BannerNotificationThemeValues = {
  colors: {
    warn: theme.colors.warning5,
    success: theme.colors.success5,
    error: theme.colors.critical5,
    info: theme.colors.primary5,
    textColor: theme.colors.neutral90,
  },
  borderColor: {
    warn: theme.colors.warning50,
    success: theme.colors.success50,
    error: theme.colors.critical50,
    info: theme.colors.primary50,
  },
  fontSize: theme.fontSize,
};

export const bannerNotificationThemeOriginal: BannerNotificationThemeValues = {
  colors: {
    warn: themeOriginal.colors.warning,
    success: themeOriginal.colors.success,
    error: themeOriginal.colors.error,
    info: themeOriginal.colors.weaveBlue,
    textColor: themeOriginal.colors.gray[600],
  },
  borderColor: {
    warn: themeOriginal.colors.warning,
    success: themeOriginal.colors.success,
    error: themeOriginal.colors.error,
    info: themeOriginal.colors.weaveBlue,
  },
  fontSize: themeOriginal.fontSize,
};
