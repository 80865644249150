import { ElementType } from 'react';
import { theme } from '@frontend/theme';
import { PolymorphicComponentPropWithoutRef } from '@frontend/design-system';

type ThreadSubHeaderProps<E extends ElementType = 'div'> = PolymorphicComponentPropWithoutRef<E>;

/**
 * `ThreadSubHeader` wraps the secondary content of the header. It mainly controls the layout of that secondary section of
 * the header.
 * It is intended to be used for the thread phone number selectors and other secondary actions.
 */
export const ThreadSubHeader = <E extends ElementType = 'div'>({ as, children, ...rest }: ThreadSubHeaderProps<E>) => {
  const Component = as || 'div';

  return (
    <Component
      css={{
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1, 2),
        position: 'relative',
        width: '100%',
        gap: theme.spacing(1),
        borderTop: `1px solid ${theme.colors.neutral20}`,
        flexWrap: 'wrap',
      }}
      {...rest}
    >
      {children}
    </Component>
  );
};
