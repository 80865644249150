import { SerializedStyles, css } from '@emotion/react';
import { capitalize } from 'lodash-es';
import { CreditCardBrand, PaymentType } from '@frontend/api-invoices';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { ACHIcon, AffirmIcon, AmexIcon, DiscoverIcon, LinkIcon, MastercardIcon, VisaIcon } from './assets';

type Props = {
  brand?: CreditCardBrand | PaymentType;
  className?: string;
  last4?: string;
  style?: SerializedStyles | SerializedStyles[];
};

type BrandInfo = { label: string; icon?: string };

export const cardBrandMap: Record<string, BrandInfo> = {
  VISA: { label: 'Visa', icon: VisaIcon },
  DISCOVER: { label: 'Discover', icon: DiscoverIcon },
  AMEX: { label: 'American Express', icon: AmexIcon },
  MASTERCARD: { label: 'Mastercard', icon: MastercardIcon },
  DINERS: { label: 'Diners Club' },
  JCB: { label: 'JCB' },
  UNIONPAY: { label: 'UnionPay' },
  CARD_BRAND_UNKNOWN: { label: '' },
  AFFIRM: { label: 'Affirm', icon: AffirmIcon },
  LINK: { label: 'Link', icon: LinkIcon },
  ACH: { label: 'ACH', icon: ACHIcon },
};

const defaultStyles = css`
  width: ${theme.spacing(5)};
`;

const containerStyle = css`
  max-width: 100%;
  display: flex;
  align-items: center;
  white-space: pre;
  flex-wrap: wrap;
`;

const textStyle = css`
  margin: 0;
`;

export const CardBrand = ({ brand, className, last4, style }: Props) => {
  const upperCaseBrandName = brand?.toUpperCase();
  const value = upperCaseBrandName ? cardBrandMap[upperCaseBrandName] : undefined;
  last4 = last4?.trim();
  return (
    <div css={[containerStyle, style]}>
      {value?.icon ? (
        <img src={value.icon} alt={value.label} css={defaultStyles} className={className} />
      ) : (
        <Text size='inherit' className={className} css={textStyle}>
          {value?.label ?? capitalize(brand)}
        </Text>
      )}
      {last4 && ` *${last4}`}
    </div>
  );
};
