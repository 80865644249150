import { css } from '@emotion/react';
import { mergeClasses } from '../../../helpers';
import { useCalendarState } from '../provider';
import type { BaseAtomProps } from './calendar-atom.types';

export const CalendarWeekHeader = ({ className }: BaseAtomProps) => {
  const { dayNames } = useCalendarState();
  return (
    <div
      className={mergeClasses('cal__week-header', className)}
      css={css`
        display: flex;
        justify-content: space-between;
      `}
    >
      {dayNames.map((name, indx) => (
        <span
          key={indx + name}
          className='cal__week-dayname'
          aria-hidden='true'
          css={css`
            min-width: calc(100% / 7);
            flex: 1;
            text-align: center;
          `}
        >
          {name}
        </span>
      ))}
    </div>
  );
};
