import { css } from '@emotion/react';
import { IndexRowItemStyleValues, indexRowItemThemeOriginal, indexRowItemTheme } from './common-theme';

type IndexRowItemStyleProps = {
  disabled?: boolean;
};

const indexRowItemStyle = (
  {
    spacing,
    backgroundColor,
    borderColor,
    borderRadius,
    disabledColor,
    hoverColor,
    caretColor,
  }: IndexRowItemStyleValues,
  { disabled }: IndexRowItemStyleProps
) => [
  css`
    padding: ${spacing(1)};
    display: flex;
    min-height: 40px;
    width: 100%;
    background: ${backgroundColor};
    align-items: center;
    border: 1px solid ${borderColor};
    transition: background 0.2s ease;
    text-decoration: none;
    color: ${caretColor};
    &:not(:first-child) {
      border-top: none;
    }

    &:first-child {
      border-radius: ${borderRadius} ${borderRadius} 0 0;
    }

    &:last-child {
      border-radius: 0 0 ${borderRadius} ${borderRadius};
    }

    &:only-child {
      border-radius: ${borderRadius};
    }
  `,
  disabled &&
    css`
      background: ${disabledColor};
      cursor: default;
    `,
  !disabled &&
    css`
      :hover {
        background-color: ${hoverColor};
      }
    `,
];

export const IndexRowStyles = {
  IndexRow: {
    indexRowItemStyle: (props: IndexRowItemStyleProps) => indexRowItemStyle(indexRowItemTheme, props),
  },
};

export const IndexRowStylesOriginal: typeof IndexRowStyles = {
  IndexRow: {
    indexRowItemStyle: (props: IndexRowItemStyleProps) => indexRowItemStyle(indexRowItemThemeOriginal, props),
  },
};
