import { PageParamsInStore } from '@frontend/api-payment-plans';
import { createShallowStore, createStoreWithSubscribe } from '@frontend/store';
import { PayoutModel } from '../types';
import { PayoutListFilter } from '../utils';

type PayoutsState = {
  selectedPayout: PayoutModel | null;
  filter: PayoutListFilter;
  numRows: number;
  pageParams: PageParamsInStore;
};

type PayoutActions = {
  setFilter: (filter: PayoutsState['filter']) => void;
  setNumRows: (numRows: PayoutsState['numRows']) => void;
  setSelectedPayout: (invoice: NonNullable<PayoutsState['selectedPayout']>) => void;
  setPageParams: (pageParams: PayoutsState['pageParams']) => void;
  resetPageParams: () => void;
};

type PayoutStore = PayoutsState & PayoutActions;

const pageParams = { first: 10, currentPage: 1 };

export const usePayoutStore = createStoreWithSubscribe<PayoutStore>(
  (set) => {
    return {
      selectedPayout: null,
      filter: {},
      numRows: 10,
      pageParams,

      setFilter: (filter) => set((prevState) => ({ ...prevState, filter, pageParams })),
      setSelectedPayout: (payout) => set((prevState) => ({ ...prevState, selectedPayout: payout })),
      setNumRows: (numRows) =>
        set((prevState) => ({ ...prevState, numRows, pageParams: { ...pageParams, first: numRows } })),
      setPageParams: (pageParams) => set((prevState) => ({ ...prevState, pageParams })),
      resetPageParams: () => set((prevState) => ({ ...prevState, pageParams })),
    };
  },
  { name: 'PayoutStore' }
);

export const usePayoutShallowStore = createShallowStore(usePayoutStore);
