import { createContext, useContext } from 'react';
import type { UseListboxProps, UseListboxResponse } from './use-listbox';
import { useListbox } from './use-listbox';
import type { ReactNode } from 'react';

const ListboxContext = createContext<UseListboxResponse>({} as UseListboxResponse);

export type ListboxChildren = ReactNode;

export type ListboxProviderProps<T extends HTMLElement = HTMLUListElement> = UseListboxProps<T> & {
  children?: ListboxChildren;
};

export const ListboxProvider = ({ children, ...rest }: ListboxProviderProps) => {
  const contextValue = useListbox(rest);
  return <ListboxContext.Provider value={contextValue}>{children}</ListboxContext.Provider>;
};

export function useListboxContext(): UseListboxResponse {
  const context = useContext(ListboxContext);
  if (typeof context === 'undefined') {
    throw new Error('useListboxContext must be used inside a ListboxProvider');
  }
  return context;
}
