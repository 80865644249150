import { css } from '@emotion/react';
import {
  ChipTheme,
  chipTheme,
  chipThemeOriginal,
  DropdownFilterChipTheme,
  dropdownFilterTheme,
  dropdownFilterThemeOriginal,
  StatusSwitcherChipTheme,
  statusSwitcherChipTheme,
  StatusSwitcherVariants,
  statusSwitcherVariantsTheme,
  TagColor,
  tagVariantsTheme,
  tagVariantsThemeOriginal,
  Variants,
  variantsTheme,
  variantsThemeOriginal,
} from './chip.theme';

export type ChipStylesProps = {
  variant: Variants;
  size: 'default' | 'large';
  isResponsive?: boolean;
  maxWidth: string;
};

const style = (
  { variant, size, isResponsive, maxWidth = '120px' }: ChipStylesProps,
  variants: typeof variantsThemeOriginal,
  theme: ChipTheme
) => [
  css`
    align-items: center;
    border-radius: ${theme.borderRadius};
    display: inline-flex;
    justify-content: center;
    gap: ${theme.spacing(0.75)};
    height: 24px;
    padding: ${theme.spacing(0.25, 1)};
    white-space: nowrap;
    max-width: ${isResponsive ? `min(100%, ${maxWidth})` : maxWidth};
    font-size: ${theme.fontSize};
    .chip-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 0;
    }
    :focus {
      outline: 2px solid ${theme.outlineColor};
      outline-offset: 1px;
    }
    @supports (selector(:has(*))) {
      :focus-within:has(input) {
        outline: 2px solid ${theme.outlineColor};
        outline-offset: 1px;
      }
    }
    @supports not (selector(:has(*))) {
      :focus-within {
        outline: 2px solid ${theme.outlineColor};
        outline-offset: 1px;
      }
    }
    button {
      :focus {
        outline: 2px solid ${theme.outlineColor};
        border-radius: ${theme.borderRadius};
      }
    }
    svg:first-of-type {
      flex-shrink: 0;
    }
  `,
  variants[variant],
  size === 'large' &&
    css`
      height: 40px;
    `,
];

export type TagChipStylesProps = {
  color: TagColor;
  hasOnClick: boolean;
};

const tagStyle = ({ color, hasOnClick }: TagChipStylesProps, variants: typeof tagVariantsThemeOriginal) => [
  css`
    cursor: ${hasOnClick ? 'pointer' : 'default'};
  `,
  variants[color].styles,
  hasOnClick && variants[color].hoverStyles,
];

export type DropdownFilterChipStylesProps = {
  filterIsActive: boolean;
  isOpen: boolean;
};

const dropdownFilterStyle = (
  { filterIsActive, isOpen }: DropdownFilterChipStylesProps,
  theme: DropdownFilterChipTheme
) => [
  css`
    cursor: pointer;
    & .location-caret {
      transition: transform 250ms ease-in-out;
    }
  `,
  filterIsActive
    ? css`
        background-color: ${theme.active.backgroundColor};
        border: none;
        color: ${theme.active.color};
        fill: ${theme.active.color};
      `
    : css`
        background-color: transparent;
        border: 1px solid ${theme.inactive.borderColor};
        color: ${theme.inactive.color};
        fill: ${theme.inactive.color};
      `,
  isOpen &&
    css`
      & .location-caret {
        transform: rotate(180deg);
      }
    `,
];

export type StatusSwitcherStyleProps = {
  variant: StatusSwitcherVariants;
};

const statusSwitcherStyle = (
  { variant }: StatusSwitcherStyleProps,
  variants: typeof statusSwitcherVariantsTheme,
  theme: StatusSwitcherChipTheme
) => [
  css`
    cursor: pointer;
    max-width: 114px;
    font-size: ${theme.fontSize};
    gap: ${theme.spacing(0.5)};
  `,
  variants[variant],
];

export const ChipStyles = {
  Chip: (props: ChipStylesProps) => style(props, variantsTheme, chipTheme),
  TagChip: (props: TagChipStylesProps) => tagStyle(props, tagVariantsTheme),
  DropdownFilterChip: (props: DropdownFilterChipStylesProps) => dropdownFilterStyle(props, dropdownFilterTheme),
  StatusSwitcherChip: (props: StatusSwitcherStyleProps) =>
    statusSwitcherStyle(props, statusSwitcherVariantsTheme, statusSwitcherChipTheme),
};
export const ChipStylesOriginal: typeof ChipStyles = {
  Chip: (props: ChipStylesProps) => style(props, variantsThemeOriginal, chipThemeOriginal),
  TagChip: (props: TagChipStylesProps) => tagStyle(props, tagVariantsThemeOriginal),
  DropdownFilterChip: (props: DropdownFilterChipStylesProps) => dropdownFilterStyle(props, dropdownFilterThemeOriginal),
  StatusSwitcherChip: (props: StatusSwitcherStyleProps) =>
    statusSwitcherStyle(props, statusSwitcherVariantsTheme, statusSwitcherChipTheme),
};
