import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../component-theme/theme-types';

export type TooltipStylesTheme = {
  spacing: WeaveThemeValues['Spacing'];
  copiedColor: WeaveThemeValues['Color'];
  notCopiedColor: WeaveThemeValues['Color'];
};

export type TextStylesTheme = {
  color: WeaveThemeValues['Color'];
  highlightColor: WeaveThemeValues['Color'];
};

export const tooltipStyles = {
  spacing: theme.spacing,
  copiedColor: theme.colors.success[50],
  notCopiedColor: theme.colors.white,
};

export const tooltipStylesOriginal = {
  spacing: themeOriginal.spacing,
  copiedColor: themeOriginal.colors.success,
  notCopiedColor: themeOriginal.colors.white,
};

export const textStyles = {
  color: theme.colors.white,
  highlightColor: theme.colors.text.highlight,
};

export const textStylesOriginal = {
  color: themeOriginal.colors.white,
  highlightColor: themeOriginal.colors.highlight,
};
