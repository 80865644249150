import { forwardRef, type ComponentProps } from 'react';
import type { BasicFormFieldProps, ExtraFieldProps, FieldActionProps } from '../../layouts';
import { FieldLayoutWithAction } from '../../layouts';
import { PostalCodeInput } from '../../atoms';

type Props = BasicFormFieldProps<'postalCode'> & ExtraFieldProps & FieldActionProps;

export const PostalCodeField = forwardRef<HTMLDivElement, Props>((props, ref) => (
  <FieldLayoutWithAction
    {...props}
    //TODO: this might be able to be improved. But this type cast just makes sure the field prop is any of the correct field types
    field={PostalCodeInput as ComponentProps<typeof FieldLayoutWithAction>['field']}
    ref={ref}
  />
));
