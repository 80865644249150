import { ReactNode, createContext, useContext, useRef } from 'react';

import { createStore, useStore as useStoreZustand } from 'zustand';
import { StateCreator, StoreMutatorIdentifier } from 'zustand/vanilla';
import { shallow } from 'zustand/shallow';
import { pick } from './utils/pick';

export const createProvider =
  <T,>() =>
  <Mos extends [StoreMutatorIdentifier, unknown][] = []>(initializer: StateCreator<T, [], Mos>) => {
    type TInitialState = Partial<T>;
    type TInitState = (prevStore: ReturnType<typeof initializer>) => TInitialState;
    const initializeStore = (initialState: TInitState | TInitialState) =>
      createStore<T>()((...args) => {
        const initStore = initializer(...args);
        const initState: TInitialState = typeof initialState === 'function' ? initialState(initStore) : initialState;
        return {
          ...initStore,
          ...initState,
        };
      });

    type StoreType = ReturnType<typeof initializeStore>;

    const ZustandContext = createContext<StoreType | null>(null);

    const StoreProvider = ({
      children,
      initialState,
    }: {
      children: ReactNode;
      initialState?: TInitState | TInitialState;
    }) => {
      const storeRef = useRef<StoreType>();
      if (!storeRef.current) {
        storeRef.current = initializeStore(initialState ?? {});
      }
      return <ZustandContext.Provider value={storeRef.current}>{children}</ZustandContext.Provider>;
    };

    function useStore<SelectedData extends (keyof T)[]>(selector: SelectedData): Pick<T, SelectedData[number]>;

    function useStore<SelectedData>(
      selector: (state: T) => SelectedData,
      compareFunction?: (a: SelectedData, b: SelectedData) => boolean
    ) {
      const store = useContext(ZustandContext);

      if (!store) {
        throw new Error('useStore must be used within a StoreProvider');
      }

      if (Array.isArray(selector)) {
        const stateKeys = selector;
        const selectFromState = (state: T) => pick(state, ...stateKeys) as unknown as SelectedData;
        return useStoreZustand(store, selectFromState, shallow);
      }

      return useStoreZustand(store, selector, compareFunction);
    }

    return { Provider: StoreProvider, useStore };
  };
