import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { FontPickerAction } from '../../molecules';
import { $isRangeSelection, $getSelection } from 'lexical';
import { useCallback } from 'react';
import { $patchStyleText } from '@lexical/selection';
import { useToolbarState } from '../../providers';
import { css } from '@emotion/react';
import { $patchDynamicFieldLabelStyle } from '../../utils/node-helpers';

export const FontPicker = () => {
  const [editor] = useLexicalComposerContext();
  const { fontFamily } = useToolbarState(['fontFamily']);

  const onFontFamilySelect = useCallback(
    (option: string) => {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $patchStyleText(selection, {
            'font-family': option,
          });
        }
        $patchDynamicFieldLabelStyle(selection, { fontFamily: option });
      });
    },
    [editor]
  );

  return (
    <FontPickerAction
      css={css`
        width: 140px;
        min-width: 140px;
        max-width: 260px;
        flex: 1;
      `}
      value={fontFamily}
      onChange={onFontFamilySelect}
    />
  );
};

FontPicker.displayName = 'FontPicker';
