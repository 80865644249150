import {
  defaultMissingModuleFallback,
  defaultModuleFetchErrorFallback,
  getRoutesFromPaths,
} from '@frontend/file-based-routing';
import { AuthenticatedRoute } from '../../components/authenticated-route';
import { ErrorComponent } from '../../components/error.component';

export function getWeavePayRoutes() {
  const paths = import.meta.glob<boolean, string, { [key: string]: any }>('/src/pages/**/[a-z[]*.tsx');
  const routes = getRoutesFromPaths(paths, {
    AccessControl: AuthenticatedRoute,
    missingModuleFallback: defaultMissingModuleFallback,
    moduleFetchErrorFallback: defaultModuleFetchErrorFallback,
  }).concat([
    { path: '/', meta: { type: 'public' }, element: <ErrorComponent /> },
    { path: '*', meta: { type: 'public' }, element: <ErrorComponent /> },
  ]);
  return routes;
}
