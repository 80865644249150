import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { InvoiceModel } from '@frontend/api-invoices';
import { formatDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { useMultiQueryUtils } from '@frontend/payments-hooks';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { Chip, Heading, Text } from '@frontend/design-system';
import { formatCentsToCurrency } from '../../../utils';
import { InvoiceStatusChip } from '../../Invoices';

interface DetailsProps {
  invoice: InvoiceModel;
}

const styles = {
  detailsSection: css`
    margin-bottom: ${theme.spacing(3)};
  `,
  detailsTitle: css`
    margin-bottom: ${theme.spacing(2)};
  `,
  detailsContainer: css`
    display: grid;
    overflow-y: auto;
    grid-template-columns: repeat(1, max-content);
    grid-gap: ${theme.spacing(1, 3)};

    @media (min-width: ${breakpoints.small.min}px) {
      grid-template-columns: repeat(2, max-content);
    }
  `,
  detailsInfo: css`
    margin-bottom: ${theme.spacing(1)};

    @media (min-width: ${breakpoints.small.min}px) {
      margin-bottom: ${theme.spacing(0)};
    }
  `,
  noMargin: css`
    margin: 0;
  `,
  locationChip: css`
    max-width: none;
  `,
};

export const Details = ({ invoice }: DetailsProps) => {
  const { t } = useTranslation('payments');
  const { getLocationName } = useMultiQueryUtils();

  return (
    <section css={styles.detailsSection}>
      <Heading level={3} css={styles.detailsTitle}>
        {t('Details')}
      </Heading>
      <div css={styles.detailsContainer}>
        <DetailRow title={t('Provider')} info={invoice.providerName} />
        <DetailRow
          title={t('Location')}
          info={<Chip.SingleChip css={styles.locationChip}>{getLocationName(invoice.locationId)}</Chip.SingleChip>}
        />
        <DetailRow title={t('Status')} info={<InvoiceStatusChip invoice={invoice} />} />
        <DetailRow title={t('Date Requested')} info={formatDate(invoice.billedAt, 'MMMM D, YYYY')} />
        <DetailRow title={t('Request amount')} info={formatCentsToCurrency(invoice.billedAmount)} />
        <DetailRow title={t('Memo')} info={invoice.memo} />
      </div>
    </section>
  );
};

const DetailRow = ({ title, info }: { title: string; info: string | undefined | ReactNode }) => {
  return (
    <>
      <Text color='subdued' size='medium' css={styles.noMargin}>
        {title}
      </Text>
      {typeof info === 'string' ? (
        <Text size='medium' css={[styles.noMargin, styles.detailsInfo]}>
          {info || '-'}
        </Text>
      ) : (
        <div css={[styles.noMargin, styles.detailsInfo]}>{info || '-'}</div>
      )}
    </>
  );
};
