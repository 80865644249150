import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../../../component-theme/theme-types';

export type OptionSwitchOptionThemeValues = {
  borderColor: WeaveThemeValues['Color'];
  borderRadius: WeaveThemeValues['BorderRadius'];
  fontSize: WeaveThemeValues['FontSize'];
  fontWeight: WeaveThemeValues['FontWeight'];
  focusColor: WeaveThemeValues['Color'];
  textColor: WeaveThemeValues['Color'];
  zIndex: WeaveThemeValues['ZIndex'];
};

export const optionSwitchOptionTheme: OptionSwitchOptionThemeValues = {
  borderColor: theme.colors.neutral40,
  borderRadius: theme.borderRadius.small,
  fontSize: theme.fontSize,
  fontWeight: theme.font.weight.bold,
  focusColor: theme.colors.primary50,
  textColor: theme.colors.neutral50,
  zIndex: theme.zIndex.low,
};

export const optionSwitchOptionThemeOriginal: OptionSwitchOptionThemeValues = {
  borderColor: themeOriginal.colors.gray[400],
  borderRadius: themeOriginal.borderRadius.small,
  fontSize: themeOriginal.fontSize,
  fontWeight: themeOriginal.font.weight.bold,
  focusColor: themeOriginal.colors.weaveBlue,
  textColor: themeOriginal.colors.gray[500],
  zIndex: themeOriginal.zIndex.low,
};
