import { css } from '@emotion/react';
import type { MultiActionButtonThemeValues } from './multi-action-button-theme';
import { multiActionButtonTheme, multiActionButtonThemeOriginal } from './multi-action-button-theme';

const secondaryBtnWidths = {
  large: 64,
  small: 56,
};

type IconButtonStyleProps = {
  active: boolean;
  size: 'large' | 'small';
};

const iconButtonStyle = (
  {
    backgroundColor,
    spacing,
    hoverBackground,
    pressedBackground,
    disabledBackground,
    disabledBorder,
    heights,
    radius,
  }: MultiActionButtonThemeValues,
  { active, size }: IconButtonStyleProps
) => css`
  width: ${secondaryBtnWidths[size]}px;
  height: ${heights[size]}px;
  background: ${backgroundColor};
  border-left: 2px solid ${pressedBackground};
  border-top-right-radius: ${radius[size]}px;
  border-bottom-right-radius: ${radius[size]}px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${spacing(size === 'large' ? 2 : 1)};
  transition: background 250ms ease-out;

  :hover,
  :focus {
    background: ${hoverBackground};
    outline: none;
    border-top-right-radius: ${radius[size]}px;
    border-bottom-right-radius: ${radius[size]}px;
  }

  :active {
    background: ${pressedBackground};
  }

  :disabled {
    background: ${disabledBackground};
    border-color: ${disabledBorder};
    cursor: default;
  }

  > svg {
    color: white;
  }

  ${active && `background: ${hoverBackground};`}
`;

export const MultiActionButtonStyles = {
  MultiActionButton: (props: IconButtonStyleProps) => iconButtonStyle(multiActionButtonTheme, props),
};
export const MultiActionButtonStylesOriginal: typeof MultiActionButtonStyles = {
  MultiActionButton: (props: IconButtonStyleProps) => iconButtonStyle(multiActionButtonThemeOriginal, props),
};
