import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../component-theme/theme-types';

export type ListboxLiThemeValues = {
  color: WeaveThemeValues['Color'];
  disabledColor: WeaveThemeValues['Color'];
  focusedBackground: WeaveThemeValues['Color'];
  fontSize: WeaveThemeValues['FontSize'];
  spacing: WeaveThemeValues['Spacing'];
};

export type ListboxLiIconThemeValues = {
  spacing: ListboxLiThemeValues['spacing'];
};

export const listboxLiTheme: ListboxLiThemeValues = {
  color: theme.colors.neutral90,
  disabledColor: theme.colors.neutral20,
  focusedBackground: theme.colors.neutral5,
  fontSize: theme.fontSize,
  spacing: theme.spacing,
};

export const listboxLiThemeOriginal: ListboxLiThemeValues = {
  color: themeOriginal.colors.gray[600],
  disabledColor: themeOriginal.colors.gray[400],
  focusedBackground: themeOriginal.colors.gray[200],
  fontSize: themeOriginal.fontSize,
  spacing: themeOriginal.spacing,
};

export const listboxLiIconTheme: ListboxLiIconThemeValues = {
  spacing: theme.spacing,
};

export const listboxLiIconThemeOriginal: ListboxLiIconThemeValues = {
  spacing: themeOriginal.spacing,
};
