import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../../component-theme/theme-types';

export type TemplatePreviewThemeValues = {
  backgroundColor: WeaveThemeValues['Color'];
  borderRadius: WeaveThemeValues['BorderRadius'];
  spacing: WeaveThemeValues['Spacing'];
};

export const templatePreviewTheme: TemplatePreviewThemeValues = {
  backgroundColor: theme.colors.neutral10,
  borderRadius: theme.borderRadius.small,
  spacing: theme.spacing,
};

export const templatePreviewThemeOriginal: TemplatePreviewThemeValues = {
  backgroundColor: themeOriginal.colors.gray[200],
  borderRadius: themeOriginal.borderRadius.small,
  spacing: themeOriginal.spacing,
};
