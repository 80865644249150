import React, { forwardRef } from 'react';
import { AccordionProviderProps, AccordionProvider } from './provider/accordion.provider';
import { AccordionItem } from './atoms/accordion-item';
import { AccordionHeader } from './atoms/accordion-header';
import { AccordionBody } from './atoms/accordion-body';
import { CaretDownIcon } from '../../icon';
import { PolymorphicComponentPropWithRef, PolymorphicRef } from '../../type-utils';

export type AccordionProps<
  C extends React.ElementType,
  Multiple extends boolean = false
> = PolymorphicComponentPropWithRef<C, AccordionProviderProps<Multiple>>;

export const AccordionOptional = forwardRef(
  <C extends React.ElementType = 'section', Multiple extends boolean = false>(
    {
      shouldLoop,
      children,
      isMulti,
      controlledValue,
      startingValue,
      onChange,
      onAnimationStart,
      onAnimationEnd,
      onAnimationComplete,
      chevronPosition = 'right',
      isInteractive = false,
      headingLevel,
      shouldRenderBody,
      chevronIcon = CaretDownIcon,
      chevronSize,
      variant = 'default',
      padding,
      distance,
      hasMultiLocations,
      size = 'small',
      showBoxShadow,
      showBorderBottomAlways,
      ...others
    }: AccordionProps<C, Multiple>,
    ref: PolymorphicRef<C>
  ) => {
    return (
      <AccordionProvider
        isMulti={isMulti}
        controlledValue={controlledValue}
        startingValue={startingValue}
        onChange={onChange}
        shouldLoop={shouldLoop}
        onAnimationStart={onAnimationStart}
        onAnimationEnd={onAnimationEnd}
        onAnimationComplete={onAnimationComplete}
        chevronPosition={chevronPosition}
        isInteractive={isInteractive}
        headingLevel={headingLevel}
        chevronIcon={chevronIcon}
        variant={variant}
        shouldRenderBody={shouldRenderBody}
        padding={padding}
        chevronSize={chevronSize}
        distance={distance}
        size={size}
        hasMultiLocations={hasMultiLocations}
        showBoxShadow={showBoxShadow}
        showBorderBottomAlways={showBorderBottomAlways}
      >
        <div ref={ref} {...others} data-accordion>
          {children}
        </div>
      </AccordionProvider>
    );
  }
);

const AccordionNamespace = Object.assign(AccordionOptional, {
  Item: AccordionItem,
  Header: AccordionHeader,
  Body: AccordionBody,
});

export { AccordionNamespace as Accordion };
