import React, { forwardRef, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useStyles } from '../../use-styles';
import { FontColorType } from '../../helpers';
import { ConfirmBadgeIcon, ErrorBadgeIcon, InfoBadgeIcon, WarningBadgeIcon, XIconSmall } from '../../icon';
import { Text } from '../text';
import { AlertType, AlertAction } from './types';
import { TextButton } from '../text-button';
import { MotionProps, PanInfo, motion, useAnimation } from 'framer-motion';
import { useThemeValues } from '../../hooks';
import { useMediaMatches } from '@frontend/responsiveness';

type AlertProps = Omit<React.HTMLAttributes<HTMLParagraphElement>, 'onClick'> & {
  children: React.ReactNode;
  id?: string;
  onClick?: (id: string) => void;
  type: AlertType;
  action?: AlertAction;
  index?: number;
} & Omit<MotionProps, 'onClick'>;

const iconMap = {
  error: ErrorBadgeIcon,
  info: InfoBadgeIcon,
  success: ConfirmBadgeIcon,
  warning: WarningBadgeIcon,
};

const iconColorMap: { [key: string]: FontColorType } = {
  error: 'error',
  info: 'primary',
  success: 'success',
  warning: 'warn',
};

const toastVariants = {
  hidden: { opacity: 0, y: 50, scale: 0.9 },
  show: { opacity: 1, y: 0, scale: 1, transition: { duration: 0.4, ease: 'easeOut', staggerChildren: 0.4 } },
  exit: { opacity: 0, y: -50, scale: 0.9, zIndex: -1, transition: { duration: 0.3, ease: 'easeIn' } },
  slideOut: { x: 400, opacity: 0, transition: { duration: 0.1 } },
};

export const Alert = forwardRef<HTMLParagraphElement, AlertProps>(
  ({ children, id, onClick, type, action, index, ...rest }: AlertProps, ref) => {
    const controls = useAnimation();
    const { matches } = useMediaMatches();
    const isMobileScreen = matches.xsmallMax();
    const { spacing, borderRadius } = useThemeValues();
    const [slideOut, setSlideOut] = useState(false);

    const Icon = iconMap[type];

    let handleClick;
    if (typeof onClick === 'function') {
      handleClick = () => onClick(id ?? '');
    }

    useEffect(() => {
      if (handleClick) {
        controls.start('show');
      }
    }, []);

    const handleDragEnd = (_, { offset }: PanInfo) => {
      if (offset.x > 200) {
        setSlideOut(true);
        controls.start('slideOut').then(handleClick);
      } else {
        controls.start({ x: 0, transition: { duration: 0.2 } }); // Snap back if not far enough
      }
    };

    const handleDrag = (_, { offset }: PanInfo) => {
      if (offset.x < 0) {
        controls.start({ x: 0 });
      }
    };

    const actionContainerStyle = useStyles('Alert', 'alertActionContainerStyle');
    const containerStyle = useStyles('Alert', 'containerStyle');
    const textStyle = useStyles('Alert', 'textStyle', { type });
    return (
      <motion.div
        css={[
          css`
            position: relative;
            margin-bottom: ${spacing(1)};
            display: flex;
            gap: ${spacing(0.5)};
            width: 100%;
            border-radius: ${borderRadius.medium};
          `,
          index &&
            css`
              z-index: ${index};
            `,
        ]}
        {...rest}
        drag={isMobileScreen && !!handleClick ? 'x' : undefined}
        dragConstraints={{ left: 0, right: 0 }}
        dragElastic={0.5}
        onDrag={handleDrag}
        onDragEnd={handleDragEnd}
        animate={!!handleClick ? controls : undefined}
        initial={!!handleClick ? 'hidden' : undefined}
        exit={!!handleClick ? (slideOut ? {} : 'exit') : undefined}
        layout={!!handleClick ? 'position' : false}
        variants={!!handleClick ? toastVariants : undefined}
        ref={ref}
      >
        {!isMobileScreen && !!handleClick && <XButton onClose={handleClick} />}
        <motion.div css={containerStyle} aria-live={type === 'error' || type === 'warning' ? 'assertive' : 'polite'}>
          <motion.div
            css={css`
              padding: ${spacing(2, 2, 2, 2.5)};
              display: flex;
              width: 100%;
              align-items: center;
              gap: ${spacing(2)};
            `}
          >
            <Icon
              css={
                action &&
                css`
                  margin: ${spacing(1, 0)};
                `
              }
              color={iconColorMap[type]}
            />
            <Text size='medium' css={textStyle}>
              {children}
            </Text>
          </motion.div>
          {action && (
            <motion.div css={actionContainerStyle}>
              <TextButton
                onClick={(e) => {
                  action.onClick(e);
                  handleClick();
                }}
                css={css`
                  padding: ${spacing(1)};
                `}
              >
                {action.label}
              </TextButton>
            </motion.div>
          )}
        </motion.div>
      </motion.div>
    );
  }
);

const buttonAnimation = {
  hidden: { x: 50, transition: { duration: 0.4 } },
  show: { x: 0, transition: { delay: 0.1 } },
  exit: { x: 50, scale: 0, opacity: 0 },
};

type XButtonProps = {
  onClose: () => void;
};

export const XButton = ({ onClose }: XButtonProps) => {
  const closeButtonStyle = useStyles('Alert', 'closeButtonStyle');

  return (
    <motion.button
      initial='hidden'
      animate='show'
      exit='exit'
      variants={buttonAnimation}
      onClick={(e) => {
        e.stopPropagation();
        onClose();
      }}
      css={closeButtonStyle}
    >
      <XIconSmall />
    </motion.button>
  );
};
