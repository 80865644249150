import React from 'react';
import { PopoverDialog, usePopoverDialog } from '../../popover/dialog';
import { ColorPicker } from '../../color-picker/color-picker';
import { ActionDropdownChip } from '../atoms';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

type ColorPickerActionProps = Pick<
  React.ComponentProps<typeof ColorPicker>,
  'color' | 'onChange' | 'palette' | 'imageSrc' | 'shouldApply'
> &
  Pick<React.ComponentProps<typeof ActionDropdownChip>, 'showBoxShadow' | 'compact' | 'css'>;

export const ColorPickerAction = ({
  color = '#FFF',
  showBoxShadow = true,
  compact = true,
  css: actionStyle,
  ...rest
}: ColorPickerActionProps) => {
  const { getTriggerProps, getDialogProps, isOpen, close } = usePopoverDialog<HTMLButtonElement>({
    placement: 'bottom-start',
  });

  return (
    <>
      <ActionDropdownChip
        compact={compact}
        {...getTriggerProps()}
        showBoxShadow={showBoxShadow}
        isActive={isOpen}
        isOpen={isOpen}
        css={actionStyle}
      >
        <div
          className='color-action-box'
          css={css`
            width: 16px;
            height: 16px;
            border-radius: ${theme.borderRadius.small};
            background: ${color};
            border: 1px solid ${theme.colors.neutral30};
            margin-right: ${theme.spacing(0.5)};
            transition: 0.2s ease;
          `}
        ></div>
      </ActionDropdownChip>
      <PopoverDialog
        css={css`
          border-radius: ${theme.borderRadius.medium};
        `}
        {...getDialogProps()}
        returnFocus={false}
      >
        <ColorPicker syncColor={color} onClick={close} {...rest} />
      </PopoverDialog>
    </>
  );
};
