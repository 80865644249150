import type { FC } from 'react';
import { useStyles } from '../../../../use-styles';
import { FieldDescriptor, FieldDescriptorProps } from '../../atoms/field-descriptor.component';
import { CommonFieldAtomProps } from '../../atoms/types';

type CommonProps = Pick<CommonFieldAtomProps, 'disabled' | 'hasError'> & FieldDescriptorProps;

interface HelperTextProps extends CommonProps {
  disabled?: boolean;
  hasError?: boolean;
}

export const HelperText: FC<React.PropsWithChildren<HelperTextProps>> = ({ children, disabled, hasError, ...rest }) => {
  const styles = useStyles('HelperText', { disabled, hasError });
  return (
    <FieldDescriptor css={styles} {...rest}>
      {children}
    </FieldDescriptor>
  );
};
