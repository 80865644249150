import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ColorPickerAction } from '../../molecules';
import { $isRangeSelection, $getSelection } from 'lexical';
import { useCallback } from 'react';
import { $patchStyleText } from '@lexical/selection';
import { useToolbarState } from '../../providers';
import { $patchDynamicFieldLabelStyle } from '../../utils/node-helpers';

export const FontColor = ({ palette, shouldApply }: { palette?: string[]; shouldApply?: boolean }) => {
  const [editor] = useLexicalComposerContext();
  const { fontColor } = useToolbarState(['fontColor']);

  const applyStyleText = useCallback(
    (styles: Record<string, string>) => {
      editor.update(() => {
        const selection = $getSelection();
        if (selection && $isRangeSelection(selection)) {
          $patchStyleText(selection, styles);
        }
        $patchDynamicFieldLabelStyle(selection, styles);
      });
    },
    [editor]
  );

  const onFontColorSelect = useCallback(
    (value: string) => {
      applyStyleText({ color: value });
    },
    [applyStyleText]
  );

  return (
    <ColorPickerAction color={fontColor} onChange={onFontColorSelect} shouldApply={shouldApply} palette={palette} />
  );
};

FontColor.displayName = 'FontColor';
