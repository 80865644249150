import type { CalendarDayType, SelectByType } from '../../calendar-types';

export enum Actions {
  dayUp = 'DAY_UP',
  dayDown = 'DAY_DOWN',
  dayLeft = 'DAY_LEFT',
  dayRight = 'DAY_RIGHT',
  monthUp = 'MONTH_UP',
  monthDown = 'MONTH_DOWN',
  monthLeft = 'MONTH_LEFT',
  monthRight = 'MONTH_RIGHT',
  yearUp = 'YEAR_UP',
  yearDown = 'YEAR_DOWN',
  yearLeft = 'YEAR_LEFT',
  yearRight = 'YEAR_RIGHT',
  prev = 'PREVIOUS',
  next = 'NEXT',
  prevYear = 'PREV_YEAR',
  nextYear = 'NEXT_YEAR',
  prevDecade = 'PREV_DECADE',
  nextDecade = 'NEXT_DECADE',
  selectDate = 'SELECT_DATE',
  selectMonth = 'SELECT_MONTH',
  selectYear = 'SELECT_YEAR',
  setRange = 'SET_RANGE',
  reset = 'RESET',
  updateBoundaries = 'UPDATE_RANGE',
  selectBy = 'SELECT_BY',
}

type DayUpAction = {
  type: Actions.dayUp;
  payload?: CalendarDayType;
};

type DayDownAction = {
  type: Actions.dayDown;
  payload?: CalendarDayType;
};

type DayLeftAction = {
  type: Actions.dayLeft;
  payload?: CalendarDayType;
};

type DayRightAction = {
  type: Actions.dayRight;
  payload?: CalendarDayType;
};

type MonthUpAction = {
  type: Actions.monthUp;
  payload?: CalendarDayType;
};

type MonthDownAction = {
  type: Actions.monthDown;
  payload?: CalendarDayType;
};

type MonthLeftAction = {
  type: Actions.monthLeft;
  payload?: CalendarDayType;
};

type MonthRightAction = {
  type: Actions.monthRight;
  payload?: CalendarDayType;
};

type YearUpAction = {
  type: Actions.yearUp;
  payload?: CalendarDayType;
};

type YearDownAction = {
  type: Actions.yearDown;
  payload?: CalendarDayType;
};

type YearLeftAction = {
  type: Actions.yearLeft;
  payload?: CalendarDayType;
};

type YearRightAction = {
  type: Actions.yearRight;
  payload?: CalendarDayType;
};

type PreviousAction = {
  type: Actions.prev;
};

type NextAction = {
  type: Actions.next;
};

type NextYearAction = {
  type: Actions.nextYear;
};

type PrevYearAction = {
  type: Actions.prevYear;
};

type PrevDecadeAction = {
  type: Actions.prevDecade;
};

type NextDecadeAction = {
  type: Actions.nextDecade;
};

type SelectDateAction = {
  type: Actions.selectDate;
  payload: string;
};

type selectMonthAction = {
  type: Actions.selectMonth;
  payload: string;
};

type selectYearAction = {
  type: Actions.selectYear;
  payload: string;
};

type SetRangeAction = {
  type: Actions.setRange;
  payload: {
    focusDay: string;
    range: string[];
  };
};

type ResetActionPayload = {
  focusDay: string;
  selected: string | string[];
};

type ResetAction = {
  type: Actions.reset;
  payload: ResetActionPayload;
};

export type CalendarBoundaries = {
  blackoutDates?: string[];
  maxDate?: string;
  minDate?: string;
};

type UpdateBoundariesAction = {
  type: Actions.updateBoundaries;
  payload: CalendarBoundaries;
};

type SelectByAction = {
  type: Actions.selectBy;
  payload: SelectByType;
};

export type ActionType =
  | DayUpAction
  | DayDownAction
  | DayLeftAction
  | DayRightAction
  | MonthUpAction
  | MonthDownAction
  | MonthLeftAction
  | MonthRightAction
  | YearUpAction
  | YearDownAction
  | YearLeftAction
  | YearRightAction
  | PreviousAction
  | NextAction
  | NextYearAction
  | PrevYearAction
  | PrevDecadeAction
  | NextDecadeAction
  | SelectDateAction
  | selectMonthAction
  | selectYearAction
  | SetRangeAction
  | ResetAction
  | UpdateBoundariesAction
  | SelectByAction;

export const dayUp = (day?: CalendarDayType): ActionType => ({
  type: Actions.dayUp,
  payload: day,
});

export const dayDown = (day?: CalendarDayType): ActionType => ({
  type: Actions.dayDown,
  payload: day,
});

export const dayLeft = (day?: CalendarDayType): ActionType => ({
  type: Actions.dayLeft,
  payload: day,
});

export const dayRight = (day?: CalendarDayType): ActionType => ({
  type: Actions.dayRight,
  payload: day,
});

export const monthUp = (month?: CalendarDayType): ActionType => ({
  type: Actions.monthUp,
  payload: month,
});

export const monthDown = (month?: CalendarDayType): ActionType => ({
  type: Actions.monthDown,
  payload: month,
});

export const monthLeft = (month?: CalendarDayType): ActionType => ({
  type: Actions.monthLeft,
  payload: month,
});

export const monthRight = (month?: CalendarDayType): ActionType => ({
  type: Actions.monthRight,
  payload: month,
});

export const yearUp = (year?: CalendarDayType): ActionType => ({
  type: Actions.yearUp,
  payload: year,
});

export const yearDown = (year?: CalendarDayType): ActionType => ({
  type: Actions.yearDown,
  payload: year,
});

export const yearLeft = (year?: CalendarDayType): ActionType => ({
  type: Actions.yearLeft,
  payload: year,
});

export const yearRight = (year?: CalendarDayType): ActionType => ({
  type: Actions.yearRight,
  payload: year,
});

export const prev = (): ActionType => ({
  type: Actions.prev,
});

export const next = (): ActionType => ({
  type: Actions.next,
});

export const prevYear = (): ActionType => ({
  type: Actions.prevYear,
});

export const nextYear = (): ActionType => ({
  type: Actions.nextYear,
});

export const prevDecade = (): ActionType => ({
  type: Actions.prevDecade,
});

export const nextDecade = (): ActionType => ({
  type: Actions.nextDecade,
});

export const selectDate = (date: string): ActionType => ({
  type: Actions.selectDate,
  payload: date,
});

export const selectMonth = (month: string): ActionType => ({
  type: Actions.selectMonth,
  payload: month,
});

export const selectYear = (year: string): ActionType => ({
  type: Actions.selectYear,
  payload: year,
});

export const setRange = (range: string[], focusDay: string): ActionType => ({
  type: Actions.setRange,
  payload: {
    focusDay,
    range,
  },
});

export const reset = (payload: ResetActionPayload): ActionType => ({
  type: Actions.reset,
  payload,
});

export const updateBoundaries = (payload: CalendarBoundaries): ActionType => ({
  type: Actions.updateBoundaries,
  payload,
});

export const selectDateBy = (payload: SelectByType): ActionType => ({
  type: Actions.selectBy,
  payload,
});
