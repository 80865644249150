import { useState } from 'react';
import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import dayjs from 'dayjs';
import { motion, AnimatePresence } from 'framer-motion';
import { useQueryClient, useQuery, useMutation, UseMutationResult } from 'react-query';
import { markInvoiceRecorded, getInvoiceHistory, InvoiceStatus, type InvoiceModel } from '@frontend/api-invoices';
import { Dashboard } from '@frontend/dashboard';
import { useTranslation } from '@frontend/i18n';
import { useMerchant, useMultiQueryUtils } from '@frontend/payments-hooks';
import { breakpoints, useMatchMedia } from '@frontend/responsiveness';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  Radio,
  Chip,
  NakedUl,
  SkeletonLoader,
  Text,
  emptyStateGraphics,
  styles,
  useControlledField,
  useTooltip,
  useAlert,
} from '@frontend/design-system';
import { formatCentsToCurrency } from '../../utils';
import { FallingMoneyAnimation } from './atoms';
import { updateInvoiceHistory } from './helpers';

const EmptyPlaceholder = emptyStateGraphics.payments;
const queryKeyLabel = 'payments-dashboard-module';

const staticInvoiceHistoryParams = {
  order: '-paidAt' as const,
  limit: 100,
  isRecorded: false,
  skip: 0,
  status: [InvoiceStatus.Paid, InvoiceStatus.PartiallyPaid],
};

export const PaymentsDashboardModuleContent = ({ isSmall }: { isSmall?: boolean }) => {
  const alerts = useAlert();
  const queryClient = useQueryClient();
  const { paymentsUrl } = useMerchant();
  const { t } = useTranslation('payments');
  const { getMultiQueryKey } = useMultiQueryUtils();
  const { selectedLocationIds } = useAppScopeStore();
  const [startAnimation, setStartAnimation] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<InvoiceModel | null>(null);
  const currentTime = dayjs();

  const { data, status, isLoading } = useQuery({
    queryKey: getMultiQueryKey(queryKeyLabel),
    queryFn: () =>
      getInvoiceHistory(`${paymentsUrl}/v1/search/invoices`, {
        ...staticInvoiceHistoryParams,
        start: currentTime.subtract(24, 'hour').toISOString(),
        end: currentTime.toISOString(),
        locationIds: selectedLocationIds,
      }),
    refetchOnWindowFocus: 'always',
    staleTime: 0,
    cacheTime: 0,
    enabled: !!paymentsUrl,
  });

  const {
    mutate: toggleRecorded,
  }: UseMutationResult<unknown, unknown, { paymentId?: string; resetField: () => void }, unknown> = useMutation(
    async ({ paymentId }) => {
      if (!paymentsUrl || !paymentId) return;
      return markInvoiceRecorded(paymentsUrl, paymentId);
    },
    {
      onSuccess: () => {
        setStartAnimation(true);
        alerts.success(t('Successfully marked as recorded'));
      },
      onError: (_, variables) => {
        variables.resetField();
        alerts.error(t('Failed to mark as recorded'));
      },
    }
  );

  const hasRecordedInvoices = !!data?.data?.invoices?.length;

  return (
    <>
      <Text size='medium' weight='bold' color='subdued'>
        {t('Total: {{total}}', { total: formatCentsToCurrency(data?.data?.summary?.gross) })}
      </Text>

      <NakedUl
        css={css`
          display: flex;
          flex-direction: column;
          gap: ${theme.spacing(2)};
          margin-top: ${theme.spacing(1)};
          height: 300px;
          overflow: auto;
        `}
      >
        {isLoading && (
          <SkeletonLoader
            animation='shimmer'
            isList
            height={52}
            count={5}
            css={css`
              min-height: 52px;
            `}
          />
        )}
        {!isLoading && !hasRecordedInvoices && <PaymentsEmptyState />}
        {status !== 'loading' && hasRecordedInvoices && (
          <AnimatePresence>
            {data?.data?.invoices?.map((invoice) => (
              <PaymentsDashboardCard
                isSmall={isSmall}
                key={invoice.id}
                invoice={invoice}
                onClick={(resetField) => {
                  toggleRecorded({
                    paymentId: invoice.payment?.paymentId,
                    resetField,
                  });
                  setSelectedInvoice(invoice);
                }}
              />
            ))}
          </AnimatePresence>
        )}
      </NakedUl>
      <FallingMoneyAnimation
        onAnimationComplete={() => {
          setStartAnimation(false);
          queryClient.setQueryData<typeof data>(getMultiQueryKey(queryKeyLabel), (oldData) =>
            updateInvoiceHistory({ oldData, recordedInvoiceId: selectedInvoice })
          );

          setSelectedInvoice(null);
        }}
        isAnimationPlaying={startAnimation}
      />
    </>
  );
};

type PaymentsDashboardCardProps = {
  invoice: InvoiceModel;
  onClick: (resetField: () => void) => void;
  isSmall?: boolean;
};

const PaymentsDashboardCard = ({ invoice, onClick, isSmall }: PaymentsDashboardCardProps) => {
  const { getLocationName } = useAppScopeStore();
  const isMobile = isSmall ?? useMatchMedia({ maxWidth: breakpoints.small.max });

  return (
    <motion.li
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      layout='position'
      css={css`
        padding: ${theme.spacing(2)};
        display: flex;
        align-items: center;
        border: 1px solid ${theme.colors.neutral20};
        border-radius: ${theme.borderRadius.small};
      `}
    >
      {isMobile && <RecordedAction onClick={onClick} />}
      <div
        css={[
          css`
            display: flex;
            align-items: center;
            width: 100%;
            min-width: 0;
          `,
          isMobile &&
            css`
              flex-wrap: wrap;
              gap: ${theme.spacing(1)};
              margin-left: ${theme.spacing(2)};
            `,
        ]}
      >
        <div
          css={[
            css`
              display: flex;
              align-items: center;
              flex-basis: 35%;
              min-width: 200px;
              justify-content: space-between;
            `,
            isMobile &&
              css`
                flex-basis: 100%;
                gap: 0;
              `,
          ]}
        >
          <Text css={styles.truncate}>{invoice.person.name}</Text>
          <Chip.SingleChip
            css={css`
              flex: 0.5;
              margin-left: ${theme.spacing(2)};
            `}
          >
            {getLocationName(invoice.locationId)}
          </Chip.SingleChip>
        </div>

        <div
          css={[
            css`
              display: flex;
              align-items: center;
              flex: 2;
              justify-content: space-between;
              min-width: 0;
              margin-left: ${theme.spacing(4)};
            `,
            isMobile &&
              css`
                flex-basis: 100%;
                margin: 0;
              `,
          ]}
        >
          <Text
            css={[
              styles.truncate,
              css`
                margin-right: ${theme.spacing(1)};
                min-width: 0;
              `,
            ]}
            size='medium'
            color='subdued'
          >
            Payment made at {dayjs(invoice.payment?.paidAt as string).format('h:mm A')}
          </Text>
          <Text
            css={css`
              margin-left: auto;
              text-align: right;
            `}
          >
            {formatCentsToCurrency(invoice.payment?.paidAmount)}
          </Text>
          {!isMobile && <RecordedAction onClick={onClick} />}
        </div>
      </div>
    </motion.li>
  );
};

type RecordedActionProps = {
  onClick: (resetField: () => void) => void;
};

const RecordedAction = ({ onClick }: RecordedActionProps) => {
  const [selected, setSelected] = useState(false);
  const { Tooltip, triggerProps, tooltipProps } = useTooltip({ placement: 'top' });

  const resetField = () => {
    setSelected(false);
  };

  const radioProps = useControlledField({
    type: 'radio',
    value: '',
    onChange: () => {
      setSelected(!selected);
      onClick(resetField);
    },
  });

  return (
    <div
      css={css`
        flex: 0.3;
        display: flex;
        justify-content: flex-end;
      `}
    >
      <span {...triggerProps}>
        <Radio
          tabIndex={0}
          {...radioProps}
          css={css`
            cursor: pointer;
          `}
          checked={selected}
          name='mark-recorded-radio-button'
        />
      </span>

      <Tooltip {...tooltipProps}>Mark Recorded</Tooltip>
    </div>
  );
};

const PaymentsEmptyState = () => {
  const { t } = useTranslation('payments');

  return (
    <div
      css={css`
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      `}
    >
      <EmptyPlaceholder
        css={css`
          max-height: 200px;
          margin-bottom: ${theme.spacing(3)};
        `}
      />
      <Text size='large' textAlign='center' weight='regular' color='subdued'>
        {t('You’ve recorded all your paid invoices. Cha-ching!')}
      </Text>
    </div>
  );
};

export const PaymentsDashboardModule = () => {
  const { t } = useTranslation('payments');
  const isMobile = useMatchMedia({ maxWidth: breakpoints.small.max });
  const navigate = useNavigate();

  return (
    <>
      <Dashboard.Module.Header
        title={!isMobile ? t('Payments Made in the Last 24 Hours') : t('Payments / 24 Hours')}
        action={{ label: t('View All Invoices'), onClick: () => navigate({ to: '/payments/invoices' }) }}
      />

      <Dashboard.Module.Content
        css={css`
          margin-top: ${theme.spacing(2)};
        `}
      >
        <PaymentsDashboardModuleContent />
      </Dashboard.Module.Content>
    </>
  );
};
