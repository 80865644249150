import { useStyles } from '../../../use-styles';
import { Label } from '../atoms/label.component';
import { NonFloatingLabelProps } from './types';

export const NonFloatingLabel = ({ children, disabled, hasError, ...rest }: NonFloatingLabelProps) => {
  const styles = useStyles('NonFloatingLabel', { disabled, hasError });
  return (
    <Label css={styles} {...rest}>
      {children}
    </Label>
  );
};
