import { Children, forwardRef, ReactNode } from 'react';
import { FloatingFocusManager, FloatingNode, FloatingPortal, useFloating } from '@floating-ui/react';
import { Heading } from '../../';
import { useStyles } from '../../../use-styles';
import { UsePopoverMenuResponse } from './use-popover-menu';
import { AnimatePresence, motion } from 'framer-motion';

export type PopoverMenuProps<T extends HTMLElement = HTMLMenuElement> = ReturnType<
  UsePopoverMenuResponse<T>['getMenuProps']
> & {
  title?: string;
  initialFocus?: Parameters<typeof FloatingFocusManager>[0]['initialFocus'];
  children: ReactNode;
  returnFocus?: boolean;
  alwaysRender?: boolean;
};

export const PopoverMenu = forwardRef<HTMLMenuElement, PopoverMenuProps>(
  ({ children, context, isOpen, title, initialFocus, returnFocus, alwaysRender, ...popoverProps }, ref) => {
    const styles = useStyles('PopoverMenu', 'menu');
    useFloating({ nodeId: popoverProps.nodeId });
    const { onAnimationStart, onDragStart, onDragEnd, onDrag, nodeId, ...motionCompatibleProps } = popoverProps;
    const count = Children.count(children);

    const shouldOpen = isOpen && count > 0;
    return (
      <FloatingNode id={nodeId}>
        <FloatingPortal>
          <ConditionalRender condition={shouldOpen || !!alwaysRender}>
            <FloatingFocusManager context={context} initialFocus={initialFocus ?? 0} returnFocus={returnFocus}>
              <motion.menu
                css={styles}
                initial={alwaysRender ? { visibility: 'hidden' } : { opacity: 0, y: 8 }}
                animate={
                  alwaysRender
                    ? {
                        opacity: shouldOpen ? 1 : 0,
                        transform: shouldOpen ? 'translateY(0)' : 'translateY(-8px)',
                        transitionEnd: { visibility: shouldOpen ? 'visible' : 'hidden' },
                      }
                    : { opacity: 1, y: 0 }
                }
                exit={{ opacity: 0, y: 8 }}
                transition={{ duration: 0.2, ease: 'easeInOut' }}
                ref={ref}
                {...motionCompatibleProps}
              >
                {!!title && <Heading level={2}>{title}</Heading>}
                {children}
              </motion.menu>
            </FloatingFocusManager>
          </ConditionalRender>
        </FloatingPortal>
      </FloatingNode>
    );
  }
);

const ConditionalRender = ({ children, condition }: { children: ReactNode; condition: boolean }) => (
  <AnimatePresence>{condition ? <>{children}</> : null}</AnimatePresence>
);
