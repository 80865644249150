import { css } from '@emotion/react';
import type {
  MultiselectSelectionButtonValues,
  MultiselectSelectionRemainderThemeValues,
  MultiselectSelectionTextThemeValues,
} from './multiselect-selection-theme';
import {
  multiselectSelectionButtonTheme,
  multiselectSelectionButtonThemeOriginal,
  multiselectSelectionRemainderTheme,
  multiselectSelectionRemainderThemeOriginal,
  multiselectSelectionTextTheme,
  multiselectSelectionTextThemeOriginal,
} from './multiselect-selection-theme';

type MultiselectProps = {
  selectionCount: number;
};

const textStyles = (
  { borderColor, spacing, zIndex }: MultiselectSelectionTextThemeValues,
  { selectionCount = 0 }: MultiselectProps
) => css`
  max-width: ${selectionCount > 1 ? 70 : 100}%;
  height: 26px;
  background: white;
  border: 1px solid ${borderColor};
  border-radius: 15px;
  display: flex;
  align-items: center;
  margin: 0;
  padding: ${spacing(0.5, 0.75, 0.5, 1)};
  z-index: ${zIndex};
`;

const buttonStyles = ({ color, spacing, focusColor, blueColor }: MultiselectSelectionButtonValues) => css`
  color: ${color};
  line-height: 0;
  margin-left: ${spacing(1)};
  position: relative;
  transition: color 250ms ease-out;

  ::before {
    content: '';
    width: 24px;
    height: 24px;
    background: transparent;
    border: 2px solid transparent;
    border-radius: 50%;
    display: block;
    transition: border-color 250ms ease-out;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  :focus {
    color: ${focusColor};
    outline: none;

    ::before {
      border-color: ${blueColor};
    }
  }
`;

const remainderStyles = ({ spacing }: MultiselectSelectionRemainderThemeValues) => css`
  margin: 0;
  padding-right: ${spacing(3)};
`;

export const MultiselectSelectionStyles = {
  MultiselectSelection: {
    textStyles: (multiselectProps: MultiselectProps) => textStyles(multiselectSelectionTextTheme, multiselectProps),
    buttonStyles: buttonStyles(multiselectSelectionButtonTheme),
    remainderStyles: remainderStyles(multiselectSelectionRemainderTheme),
  },
};

export const MultiselectSelectionStylesOriginal: typeof MultiselectSelectionStyles = {
  MultiselectSelection: {
    textStyles: (multiselectProps: MultiselectProps) =>
      textStyles(multiselectSelectionTextThemeOriginal, multiselectProps),
    buttonStyles: buttonStyles(multiselectSelectionButtonThemeOriginal),
    remainderStyles: remainderStyles(multiselectSelectionRemainderThemeOriginal),
  },
};
