import { forwardRef, useMemo } from 'react';
import { Icon } from '@frontend/icons';
import { SpinningLoader } from '../loader';
import { ButtonProps } from './button.type';
import { getIconColor, getLoaderColor, getVariantStyles } from './styles';

const eventsToDisable = ['onClick', 'onMouseDown', 'onMouseUp', 'onKeyDown', 'onKeyUp'];

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      iconName,
      disabled = false,
      size = 'small',
      trackingId,
      type = 'button',
      loading = false,
      variant = 'primary',
      destructive = false,
      ...rest
    },
    ref
  ) => {
    const iconOnly = !children && !!iconName;

    const buttonStyles = useMemo(() => {
      // getVariantStyles() has a few method calls. Better to memoize if props aren't changing.
      return getVariantStyles({ variant, size, loading, iconOnly, destructive });
    }, [variant, size, loading, iconOnly, destructive]);

    const effectivelyDisabled = disabled || loading;

    const eventHandlers = eventsToDisable.reduce((handlers, eventName) => {
      handlers[eventName] = (event) => {
        if (effectivelyDisabled) {
          event.preventDefault();
          return;
        }
        rest[eventName]?.(event);
      };
      return handlers;
    }, {});

    return (
      <button
        css={buttonStyles}
        type={type}
        ref={ref}
        data-trackingid={trackingId}
        aria-disabled={effectivelyDisabled}
        tabIndex={effectivelyDisabled ? -1 : 0}
        {...rest}
        {...eventHandlers}
      >
        {loading ? (
          <SpinningLoader color={getLoaderColor(variant, destructive)} css={{ margin: 0 }} size='xs' />
        ) : (
          <span css={{ display: 'flex', alignItems: 'center', gap: 4 }}>
            {iconName && (
              <Icon
                name={iconName}
                color={getIconColor(variant, disabled, destructive)}
                size={size === 'large' ? 24 : 16}
              />
            )}
            {children}
          </span>
        )}
      </button>
    );
  }
);

Button.displayName = 'Button';
