import { Trans, useTranslation } from '@frontend/i18n';
import { Heading, Text } from '@frontend/design-system';
import { successIcon, resultMessage, successIconImg } from '../../../styles/weave-pay.styles';
import { Payment } from '../../../types/payment';
import { displayAmount } from '../../../utils/currency';
import successGraphic from '../assets/success_graphic.png';

export const TTPSuccessComponent = ({ payment }: { payment: Payment }) => {
  const { t } = useTranslation('weave-pay');
  const { amount, patientName, receiptEmail } = payment;
  const fullName = [patientName?.firstName, patientName?.lastName].join(' ').trim();

  return (
    <div css={resultMessage}>
      <figure>
        <img css={[successIcon, successIconImg]} src={successGraphic} alt='success graphic' />
      </figure>
      <Heading textAlign='center' level={2}>
        {t('Payment successfully submitted!')}
      </Heading>
      {!!amount && (
        <Text textAlign='center'>
          <Trans t={t}>
            A payment of {displayAmount(amount)} has been submitted
            {fullName && ` for ${fullName}'s card.`}
          </Trans>
        </Text>
      )}
      {receiptEmail && <Text textAlign='center'>{t(`An email receipt has been sent to ${receiptEmail}.`)}</Text>}
    </div>
  );
};
