export interface User {
  email: string;
  userId: string;
  roles?: number[];
  userName?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  mobileNumber?: string;
  type?: 'weave' | 'internal' | 'practice';
}
export interface PortalUser {
  userID: string;
  username: string;
  firstName?: string;
  lastName?: string;
  mobileNumber?: string;
  type: 'weave' | 'internal' | 'practice';
}

export enum AuthStorage {
  user = 'user',
  weave_token = 'token',
  decoded_weave = 'decoded_weave',
  forward_url = 'forward_url',
  sign_in_fingerprint = 'sign_in_fingerprint',
  okta_id_token = 'okta_id_token',
  okta_session_exp = 'okta_session_exp',
  sign_in_method = 'sign_in_method',
  original_uri = 'original_uri',
  oidc_token_storage = 'oidc-token-storage',
  weave_session_token = 'weave_session_token',
}

export type SignInMethod = 'okta' | 'legacy' | 'oidc';

export type AuthConfig = {
  oktaIssuer?: string;
  oktaClientID?: string;
  oktaRedirectUri?: string;
  oidcIssuer?: string;
  oidcClientID?: string;
  oidcRedirectUri?: string;
  backendAPI?: string;
  appSource?: string;
};
