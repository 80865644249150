import { SchemaIO } from '@frontend/schema';
import {
  INFINITE_QUERY_ENDPOINT_NAMES,
  MUTATION_ENDPOINT_NAMES,
  QUERY_ENDPOINT_NAMES,
  STANDARD_QUERY_ENDPOINT_NAMES,
} from './constants';
import { SchemaSMSDataV3Service } from './service';

export type StandardQueryEndpointName = (typeof STANDARD_QUERY_ENDPOINT_NAMES)[number];
export type InfiniteQueryEndpointName = (typeof INFINITE_QUERY_ENDPOINT_NAMES)[number];
export type QueryEndpointName = (typeof QUERY_ENDPOINT_NAMES)[number];
export type ServiceStandardQueries = Pick<typeof SchemaSMSDataV3Service, StandardQueryEndpointName>;
export type ServiceInfiniteQueries = Pick<typeof SchemaSMSDataV3Service, InfiniteQueryEndpointName>;
export type ServiceQueries = Pick<typeof SchemaSMSDataV3Service, QueryEndpointName>;

export type MutationEndpointName = (typeof MUTATION_ENDPOINT_NAMES)[number];
export type ServiceMutations = Pick<typeof SchemaSMSDataV3Service, MutationEndpointName>;

export type ListThreadsIO = SchemaIO<ServiceQueries['ListThreads']>;
export type LookupThreadIdIO = SchemaIO<ServiceQueries['LookupThreadId']>;

/**
 * KnownThreadStatus is a FE-only enum. The BE just uses a string, but we want to keep status consistent.
 */
export enum KnownThreadStatus {
  NEW = 'new',
  READ = 'read',
  ERROR = 'error',
}

/**
 * OptimisticUpdateStatusDetails is a FE-only enum used to track the status of an optimistic update.
 */
export enum OptimisticUpdateStatusDetails {
  DELETING = 'DELETING',
  SENDING = 'SENDING',
}

/**
 * KnownProgramSlugIds is a FE-only enum. The BE just uses a string, but we want to keep slugIds consistent
 */
export enum KnownProgramSlugIds {
  MANUAL_MESSAGES = 'manual-messages',
  PAYMENT_REMINDERS = 'payment-reminders',
  APPOINTMENTS_REMINDERS = 'appointments-reminders',
  REVIEW_REQUESTS = 'review-requests',
  PRODUCT_READY = 'product-ready',
  BIRTHDAY = 'birthday',
  MESSAGING_PREFERENCES = 'messaging-preferences',
  MISSED_CALL = 'missed-call',
  MARKETING_MESSAGES = 'marketing-messages',
  RECALL = 'recall',
  SAVE_THE_DATE = 'save-the-date',
}
