import { css } from '@emotion/react';
import type { ButtonStyleValues } from '../button-theme';
import { buttonTheme, originalButtonTheme } from '../button-theme';
import { baseButtonStyle } from '../common.styles';
import { PrimaryButtonProps } from './types';

export const primaryHoverStyle = (
  styleValues: Pick<ButtonStyleValues, 'hoverColor' | 'destructive' | 'boxShadow'>,
  destructive?: boolean
) => css`
  background: ${destructive ? styleValues.destructive.hoverColor : styleValues.hoverColor};
  border-color: ${destructive ? styleValues.destructive.hoverColor : styleValues.hoverColor};
  box-shadow: ${styleValues.boxShadow};
`;

export const primaryPressedStyle = (
  styleValues: Pick<ButtonStyleValues, 'pressedColor' | 'destructive'>,
  destructive?: boolean
) => css`
  :active {
    background: ${destructive ? styleValues.destructive.pressedColor : styleValues.pressedColor};
    border-color: ${destructive ? styleValues.destructive.pressedColor : styleValues.pressedColor};
    box-shadow: none;
  }
`;

type StyleProps = Pick<PrimaryButtonProps, 'destructive' | 'size'>;

const styles =
  ({
    backgroundColor,
    borderRadius,
    boxShadow,
    color,
    destructive: destructiveStyle,
    disabled,
    focusRingColor,
    fontFamily,
    fontWeight,
    hoverColor,
    pressedColor,
    spacing,
    ...sizes
  }: ButtonStyleValues) =>
  ({ size, destructive }: StyleProps) => {
    const bgColor = destructive ? destructiveStyle.backgroundColor : backgroundColor;

    return [
      baseButtonStyle({ borderRadius, focusRingColor, fontFamily, ...sizes }, size),
      css`
        background: ${bgColor};
        border: 1px solid ${bgColor};
        border-radius: ${borderRadius};
        color: ${color};
        font-weight: ${fontWeight};
        transition: background 150ms ease-out, color 150ms ease-out, border-color 150ms ease-out;

        :hover {
          ${primaryHoverStyle({ hoverColor, destructive: destructiveStyle, boxShadow }, destructive)};
        }

        ${primaryPressedStyle({ pressedColor, destructive: destructiveStyle }, destructive)};

        :disabled {
          background: ${disabled.backgroundColor};
          border-color: ${disabled.backgroundColor};
          box-shadow: none;
          color: ${disabled.color};
        }

        svg {
          margin-left: ${spacing(1)};
        }
      `,
    ];
  };

export const PrimaryButtonStyles = {
  PrimaryButton: styles(buttonTheme),
};

export const PrimaryButtonStylesOriginal: typeof PrimaryButtonStyles = {
  PrimaryButton: styles(originalButtonTheme),
};
