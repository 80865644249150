/**
 * Replacement of polished lib
 * https://bit.cloud/styled-components/polished/
 */

// @flow

export function guard(lowerBoundary: number, upperBoundary: number, value: number): number {
  return Math.max(lowerBoundary, Math.min(upperBoundary, value));
}
