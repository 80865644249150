import { SerializedStyles } from '@emotion/react';
import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../../../component-theme/theme-types';
import { styles } from '../../../../styles';

export type DropdownMenuThemeProps = {
  backgroundColor: WeaveThemeValues['Color'];
  boxShadow: WeaveThemeValues['BoxShadow'];
  smallScrollbar: SerializedStyles;
};

export type DropdownOptionThemeProps = {
  activeBackground: WeaveThemeValues['Color'];
  color: WeaveThemeValues['Color'];
  disabledColor: WeaveThemeValues['Color'];
  spacing: WeaveThemeValues['Spacing'];
};

export type DropdownInputThemeProps = {
  disabledColor: WeaveThemeValues['Color'];
  fontFamily: WeaveThemeValues['FontFamily'];
  fontSize: WeaveThemeValues['FontSize'];
  placeholderColor: WeaveThemeValues['Color'];
  spacing: WeaveThemeValues['Spacing'];
};

export const dropdownMenuTheme = {
  backgroundColor: theme.colors.white,
  boxShadow: theme.shadows.floating,
  smallScrollbar: styles.smallScrollbar(theme),
};

export const dropdownMenuThemeOriginal = {
  backgroundColor: themeOriginal.colors.white,
  boxShadow: themeOriginal.shadows.light,
  smallScrollbar: styles.smallScrollbar(themeOriginal),
};

export const dropdownOptionTheme = {
  activeBackground: theme.colors.neutral5,
  color: theme.colors.neutral90,
  disabledColor: theme.colors.status.disabled,
  spacing: theme.spacing,
};

export const dropdownOptionThemeOriginal = {
  activeBackground: themeOriginal.colors.gray200,
  color: themeOriginal.colors.gray600,
  disabledColor: themeOriginal.colors.disabled,
  spacing: themeOriginal.spacing,
};

export const dropdownInputTheme = {
  disabledColor: theme.colors.status.disabled,
  fontFamily: theme.font.family,
  fontSize: theme.fontSize,
  placeholderColor: theme.colors.black,
  spacing: theme.spacing,
};

export const dropdownInputThemeOriginal = {
  disabledColor: themeOriginal.colors.disabled,
  fontFamily: themeOriginal.font.family,
  fontSize: themeOriginal.fontSize,
  placeholderColor: themeOriginal.colors.error,
  spacing: themeOriginal.spacing,
};
