import { css } from '@emotion/react';
import { CaretDownIconSmall, IconProps } from '../../../../icon';
import { DropdownFieldProps } from './types';

type Props = Pick<DropdownFieldProps, 'active' | 'icon'> & { color?: IconProps['color'] };

export const DropdownIcon = ({ active, icon, color = 'default' }: Props) => {
  const Icon = icon ?? CaretDownIconSmall;
  return (
    <Icon
      color={color}
      css={[
        css`
          cursor: default;
          pointer-events: none;
          position: absolute;
          top: 50%;
          right: 16px;
          transform: translateY(-50%);
          transition: transform 250ms ease-out;
        `,
        !icon &&
          active &&
          css`
            transform: translateY(-50%) rotate(180deg);
          `,
      ]}
    />
  );
};
