/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const UpdateIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M8.89897921,6.04558484 L10.1848116,6.48833244 C10.837556,6.71309035 11.1845075,7.42444649 10.9597496,8.07719085 C10.7349917,8.72993521 10.0236356,9.07688678 9.37089121,8.85212888 L5.11605762,7.38707218 C4.46331326,7.16231427 4.11636169,6.45095813 4.3411196,5.79821377 L5.80617629,1.54338018 C6.0309342,0.890635815 6.74229034,0.543684243 7.3950347,0.768442152 C8.04777907,0.99320006 8.39473064,1.7045562 8.16997273,2.35730056 L7.64729271,3.87527356 C7.66392951,3.86618829 7.68084992,3.85743348 7.69804978,3.8490212 C9.08722062,3.16959199 10.6175552,2.81148196 12.1963559,2.81148196 C17.8572746,2.81148196 22.4463559,7.40056327 22.4463559,13.061482 C22.4463559,18.7224006 17.8572746,23.311482 12.1963559,23.311482 C6.53543722,23.311482 1.94635591,18.7224006 1.94635591,13.061482 C1.94635591,12.371126 2.50599997,11.811482 3.19635591,11.811482 C3.88671185,11.811482 4.44635591,12.371126 4.44635591,13.061482 C4.44635591,17.3416888 7.9161491,20.811482 12.1963559,20.811482 C16.4765627,20.811482 19.9463559,17.3416888 19.9463559,13.061482 C19.9463559,8.78127515 16.4765627,5.31148196 12.1963559,5.31148196 C11.0392078,5.31148196 9.9205192,5.5644695 8.89897921,6.04558484 Z'
    />
  </Icon>
);
