import { differenceInYears } from 'date-fns';
import * as PersonTypes from './types';
import { isPhoneNumber, sanitizePhoneNumber } from '@frontend/phone-numbers';

export const getNumberLabel = (number: string, person?: PersonTypes.Person) => {
  if (!person) {
    return '';
  }

  const sanitizedNumber = sanitizePhoneNumber(number.replace('+1', ''));

  if (sanitizedNumber === sanitizePhoneNumber(person.HomePhone)) {
    return 'Home';
  } else if (sanitizedNumber === sanitizePhoneNumber(person.MobilePhone)) {
    return 'Mobile';
  } else if (sanitizedNumber === sanitizePhoneNumber(person.WorkPhone)) {
    return 'Work';
  } else if (sanitizedNumber === sanitizePhoneNumber(person.OtherContactInfo)) {
    return 'Other';
  }

  return '';
};

export const getMobileNumber = (person?: PersonTypes.Person): string => {
  return person?.MobilePhone ?? '';
};

export const getAge = (data?: Partial<PersonTypes.Person>) => {
  if (!data?.Birthdate) {
    return null;
  }

  return differenceInYears(new Date(), new Date(data?.Birthdate));
};

export const getFullName = (person?: Partial<PersonTypes.Person> | null) => {
  const firstName = person?.FirstName ?? '';
  const lastName = person?.LastName ?? '';
  let preferredName = person?.PreferredName ?? '';

  if (preferredName?.includes(firstName) && preferredName?.includes(lastName)) {
    return preferredName;
  }
  if (preferredName.includes(lastName)) {
    preferredName = preferredName.replace(lastName, '');
  }
  if (Number(preferredName)) {
    preferredName = '';
  }

  return `${preferredName.trim() || firstName.trim()} ${lastName}`.trim();
};

export const getAddress = (person?: PersonTypes.Person): string[] => {
  return person?.Address && !isEmptyAddress(person?.Address)
    ? [
        person.Address.Street,
        `${person.Address.City !== '' && `${person.Address.City}, `}${person.Address.State} ${person.Address.Zip}`,
      ]
    : [];
};

const isEmptyAddress = (address: PersonTypes.Person['Address']): boolean => {
  if (!address) {
    return true;
  }

  const fields = Object.keys(address);
  for (let i = 0; i < fields.length; i++) {
    if (address[fields[i] as keyof PersonTypes.Person['Address']] !== '') {
      return false;
    }
  }

  return true;
};

export const getAllPhoneNumbers = (person?: PersonTypes.Person) => {
  const otherContactInfoIsPhoneNumber = isPhoneNumber(person?.OtherContactInfo);
  const phoneNumbers = [
    {
      label: 'Mobile',
      number: person?.MobilePhone ?? '',
    },
    {
      label: 'Home',
      number: person?.HomePhone ?? '',
    },
    {
      label: 'Work',
      number: person?.WorkPhone ?? '',
    },
    {
      label: 'Other',
      number: otherContactInfoIsPhoneNumber ? person?.OtherContactInfo ?? '' : '',
    },
  ].filter((phoneData) => !!phoneData.number);
  return phoneNumbers;
};
