import { ComponentPropsWithoutRef, useMemo } from 'react';
import { useStyles } from '../../../use-styles';
import { useSkeletonLoader } from './skeleton.provider';
import { generateSizeVariability } from './utils';
import { SkeletonLoaderProps } from './types';

type Props<T extends boolean = false> = SkeletonLoaderProps<T> &
  Omit<ComponentPropsWithoutRef<T extends true ? 'span' : 'div'>, keyof SkeletonLoaderProps>;

export const SkeletonLoader = <T extends boolean = false>({
  width = '100%',
  height = '100%',
  shape = 'rectangle',
  count,
  distance,
  path,
  animation = 'pulse',
  duration = 1,
  children,
  isText,
  widthVariability,
  heightVariability,
  isLoading,
  isList,
  ...rest
}: React.PropsWithChildren<Props<T>>) => {
  const context = useSkeletonLoader();

  const randomWidth = useMemo(() => generateSizeVariability(width, widthVariability), []);
  const randomHeight = useMemo(() => generateSizeVariability(height, heightVariability), []);

  const skeletonLoaderStyle = useStyles('Loaders', 'skeletonLoaderStyle', {
    width: widthVariability ? randomWidth : width,
    height: heightVariability ? randomHeight : height,
    shape: context?.shape ?? shape,
    distance,
    path,
    animation: context?.animation ?? animation,
    duration: context?.duration ?? duration,
    isText: isText,
    count,
    isList,
  });

  const Component = isText ? 'span' : 'div';

  const skeletonLoader = useMemo(
    () => (
      <>
        {!count ? (
          <Component css={skeletonLoaderStyle} {...rest} />
        ) : (
          Array.from({ length: count }).map((_, index) => <Component key={index} css={skeletonLoaderStyle} {...rest} />)
        )}
      </>
    ),
    [count, skeletonLoaderStyle, rest]
  );

  if (!children) {
    return skeletonLoader;
  }

  return context?.isLoading ?? isLoading ? skeletonLoader : <>{children}</>;
};
