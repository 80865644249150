import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import {
  CreditCardBrand,
  CreditCardBrandKeys,
  FilterPaymentType,
  FilterPaymentTypeKeys,
  PaymentOrigin,
} from '@frontend/api-invoices';
import { RefundCreditCardBrand, RefundFilterType, RefundType } from '@frontend/api-refunds';
import { useArrayState } from '@frontend/array';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { ModalControlModalProps, MoneyField, TableFilters, TextField, useFormField } from '@frontend/design-system';
import { usePaymentTypeMap } from '../../../hooks';
import {
  filterCardTypeMap,
  filterPaymentMethodMap,
  formatCentsToCurrency,
  hasFilter,
  useCurrencyValue,
} from '../../../utils';
import { StatusItem as FilterCheckboxItem, PaymentTypeItem } from '../../FilterCheckbox';
import { CardTypeFilterTitle } from '../../Shared';
import { StatusItem } from './status-item';

export interface RefundFilterProps {
  initialFilter?: RefundFilterType;
  onApply: (filter: RefundFilterType) => void;
  modalProps: ModalControlModalProps;
  setShowNotification: (showNotification: boolean) => void;
}

const styles = {
  checkBoxStyle: css`
    margin: ${theme.spacing(1, 3)};
    > label {
      margin: 0; /*Because bootstrap */
      margin-left: ${theme.spacing(2)};
    }
  `,
  searchFieldStyle: css`
    margin-bottom: ${theme.spacing(2)};
  `,
};

const DATA_TRACKING_PREFIX = `pay-portal-refunds-filter`;

export const RefundFilter = ({ initialFilter, onApply, modalProps, setShowNotification }: RefundFilterProps) => {
  const { t } = useTranslation('payments');
  const { paymentTypeMap } = usePaymentTypeMap();

  useEffect(() => {
    setShowNotification?.(hasFilter(initialFilter, ['dateRefunded']));
  }, [initialFilter, setShowNotification]);

  const handleOnApply = (filter: RefundFilterType) => {
    setShowNotification?.(hasFilter(filter, ['dateRefunded']));
    onApply(filter);
  };
  // PERSON
  const {
    paymentId: initialPaymentId,
    refundAmount: initialRefundAmount,
    personName: initialLastName,
    origin: initialPaymentType,
  } = initialFilter || {};

  const personFieldProps = useFormField({ type: 'text', value: initialLastName });

  const paymentIdFieldProps = useFormField({
    type: 'text',
    value: initialPaymentId,
  });

  const amountFieldProps = useFormField({
    type: 'money',
    value: initialRefundAmount ? formatCentsToCurrency(initialRefundAmount) : '',
  });

  const { value: amountValue, string: amountString } = useCurrencyValue(amountFieldProps.value);
  // STATUS OPTIONS

  const [cardType, setCardType, addCardType, removeCardType] = useArrayState<RefundCreditCardBrand>(
    initialFilter?.cardType ?? []
  );
  const [refundType, setRefundType] = useState<RefundType | null>(initialFilter?.refundType ?? null);

  // PAYMENT TYPE FILTER
  const [paymentType, setPaymentType, addPaymentType, removePaymentType] = useArrayState<PaymentOrigin>(
    initialPaymentType ?? []
  );

  const [paymentMethod, setPaymentMethod, addPaymentMethod, removePaymentMethod] = useArrayState<FilterPaymentType>(
    initialFilter?.paymentType ?? []
  );

  // Build filter

  const handleApply = () => {
    const filter: RefundFilterType = { dateRefunded: initialFilter?.dateRefunded };

    if (refundType) {
      filter.refundType = refundType;
    }

    if (personFieldProps.value) {
      filter.personName = personFieldProps.value;
    }

    if (paymentIdFieldProps.value) {
      filter.paymentId = paymentIdFieldProps.value;
    }

    if (amountValue) {
      filter.refundAmount = amountValue;
    }

    if (paymentType.length > 0) {
      filter.origin = paymentType;
    }

    if (paymentMethod.length > 0) {
      filter.paymentType = paymentMethod;
    }

    if (cardType.length > 0) {
      if (!filter.paymentType?.includes(FilterPaymentType.Card))
        filter.paymentType = [...(filter.paymentType ?? []), FilterPaymentType.Card];
      filter.cardType = cardType;
    }

    handleOnApply(filter);
  };

  return (
    <TableFilters {...modalProps} onApplyClick={handleApply}>
      <TableFilters.Section
        sectionHeaderLabel='Search'
        disableClear={!personFieldProps.value && !+amountFieldProps.value && !paymentIdFieldProps.value}
        onClearClick={() => {
          personFieldProps.onChange({ value: '', name: '' });
          amountFieldProps.onChange({ value: '', name: '' });
          paymentIdFieldProps.onChange({ value: '', name: '' });
        }}
        data-trackingid={`${DATA_TRACKING_PREFIX}-cbx-search`}
      >
        <TextField
          name='person-filter'
          label={t('Search Person')}
          placeholder={t('e.g. Person')}
          {...personFieldProps}
          css={styles.searchFieldStyle}
          data-trackingid={`${DATA_TRACKING_PREFIX}-txt-search`}
        />
        <MoneyField
          name='amount-filter'
          label={t('Search Amount')}
          placeholder={t('e.g. 10')}
          {...amountFieldProps}
          value={amountString}
          css={styles.searchFieldStyle}
          data-trackingid={`${DATA_TRACKING_PREFIX}-amount-search`}
        />
        <TextField
          name='payment-id-filter'
          label={t('Search Payment Id')}
          placeholder={t('Payment Id')}
          {...paymentIdFieldProps}
          css={styles.searchFieldStyle}
          data-trackingid={`${DATA_TRACKING_PREFIX}-payment-id-search`}
        />
      </TableFilters.Section>
      <TableFilters.Section
        sectionHeaderLabel={t('Refund Type')}
        disableClear={!refundType}
        onClearClick={() => setRefundType(null)}
        data-trackingid={`${DATA_TRACKING_PREFIX}-cbx-type`}
      >
        <StatusItem
          status={refundType}
          onAdd={setRefundType}
          disabled={false}
          css={styles.checkBoxStyle}
          data-trackingid={`${DATA_TRACKING_PREFIX}-cbx-refund-type`}
        />
      </TableFilters.Section>
      <TableFilters.Section
        sectionHeaderLabel={t('Payment Type')}
        disableClear={!paymentType.length}
        onClearClick={() => setPaymentType([])}
        data-trackingid={`${DATA_TRACKING_PREFIX}-cbx-payment-type`}
      >
        {Object.entries(paymentTypeMap).map(([typeKey, typeValue]) => {
          return (
            <PaymentTypeItem
              key={typeKey}
              value={typeKey as PaymentOrigin}
              label={typeValue || ''}
              paymentType={paymentType}
              onAdd={addPaymentType}
              onRemove={removePaymentType}
              css={styles.checkBoxStyle}
              disabled={false}
            />
          );
        })}
      </TableFilters.Section>
      <TableFilters.Section
        sectionHeaderLabel={t('Payment Method')}
        disableClear={!paymentMethod.length}
        onClearClick={() => setPaymentMethod([])}
        data-trackingid={`${DATA_TRACKING_PREFIX}-cbx-payment-method`}
      >
        {Object.entries(FilterPaymentType).map(([paymentMethodKey, paymentMethodValue]) => (
          <FilterCheckboxItem<FilterPaymentType>
            key={paymentMethodValue}
            value={paymentMethodValue}
            label={filterPaymentMethodMap(paymentMethodKey as FilterPaymentTypeKeys)}
            status={paymentMethod}
            onAdd={addPaymentMethod}
            onRemove={removePaymentMethod}
            css={styles.checkBoxStyle}
            disabled={false}
            data-trackingid={`${DATA_TRACKING_PREFIX}-cbx-${paymentMethodKey.toLowerCase()}`}
          />
        ))}
      </TableFilters.Section>
      <TableFilters.Section
        sectionHeaderLabel={<CardTypeFilterTitle />}
        disableClear={!cardType.length}
        onClearClick={() => setCardType([])}
        data-trackingid={`${DATA_TRACKING_PREFIX}-cbx-card-type`}
      >
        {Object.entries(CreditCardBrand).map(([cardTypeKey, cardTypeValue]) => (
          <FilterCheckboxItem<RefundCreditCardBrand>
            key={cardTypeValue}
            value={
              cardTypeValue === CreditCardBrand.CardBrandUnknown
                ? ''
                : (cardTypeValue.toLowerCase() as RefundCreditCardBrand)
            } //refunds uses empty string for unknown brands
            label={filterCardTypeMap(cardTypeKey as CreditCardBrandKeys)}
            status={cardType}
            onAdd={addCardType}
            onRemove={removeCardType}
            css={styles.checkBoxStyle}
            disabled={false}
            data-trackingid={`${DATA_TRACKING_PREFIX}-cbx-${cardTypeKey.toLowerCase()}`}
          />
        ))}
      </TableFilters.Section>
    </TableFilters>
  );
};
