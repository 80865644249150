import React, { useRef, ElementRef } from 'react';
import useResizeObserver from '@react-hook/resize-observer';

interface UseContainerQueryProps {
  minWidth?: number;
  maxWidth?: number;
}

export function useContainerQuery<T extends React.ElementType = 'div'>({
  minWidth = 1,
  maxWidth,
}: Readonly<UseContainerQueryProps>) {
  const [isMatch, setIsMatch] = React.useState(false);
  const containerRef = useRef<ElementRef<T>>(null);

  useResizeObserver(containerRef as unknown as React.RefObject<HTMLDivElement>, (entry) => {
    const elementWidth = (entry.borderBoxSize as unknown as ResizeObserverSize)?.inlineSize ?? entry.contentRect.width;
    if (elementWidth <= 0) return;

    const value =
      maxWidth === undefined ? elementWidth >= minWidth : elementWidth >= minWidth && elementWidth <= maxWidth;

    setIsMatch(value);
  });

  return [isMatch, containerRef] as const;
}
