import { Paths, RoutesGlob } from './route-types';

export function getPaths(routes: RoutesGlob) {
  const paths: Paths = {};
  const sortedPaths = Object.keys(routes)
    .map((path) => ({
      original: path,
      parsed: path
        .replace(/\/src\/pages|index|\.tsx$/g, '')
        .replace(/\[\.{3}.+\]/, '*') // [...catchAll].tsx => '*'
        .replaceAll('[', ':')
        .replaceAll(']', ''),
    }))
    .sort((a, b) => alphanumericSort(a.parsed, b.parsed, 'desc'));

  sortedPaths.forEach(({ original, parsed }) => {
    //TODO: this is useful, but more testing is needed before pushing this
    // if (parsed === "/") {
    //   paths["/"] = {
    //     module: routes[original],
    //     originalPath: original,
    //     fullSegmentPath: original,
    //     path: parsed,
    //     isParent: false,
    //     children: {},
    //   };
    // }
    parsed.split('.parent').forEach((segment, i, arr) => {
      if (segment === '/') {
        return;
      }

      // since it's split by parent folders, we assume everything prior to the last, is a parent
      const isParent = i !== arr.length - 1;
      const { currPaths, isPrefix } = findPath(paths, arr, segment);

      // console.log({ isPrefix, isParent, segment, original })
      if (!isPrefix) {
        const parentIndex = getPosition(original, '.parent', i + 1);
        const parentPath = original.slice(0, parentIndex);
        const currSegmentRoutePath = isParent ? parentPath + '.parent/index.tsx' : original;
        currPaths[segment] = {
          module: routes[currSegmentRoutePath],
          originalPath: original,
          fullSegmentPath: currSegmentRoutePath,
          //react router expects all paths to end in a slash
          path: !isParent && !segment.endsWith('/') ? segment + '/' : segment,
          isParent,
          children: {},
        };
      }
    });
  });

  return paths;
}

const regAlpha = /[^a-zA-Z]/g;
const regNum = /[^0-9]/g;
/**
 * alphanumeric sort. Supposed to be faster than localeCompare, and deal with symbols better
 * https://webedit.com/0GZ6h7paIPSfwN6kvMqxv85p9XX2/alphanum.timkay.com/index.js?run=edit
 * Read comment on this answer: https://stackoverflow.com/a/44197285/1679202
 */
const alphanumericSort = (a: string, b: string, direction: 'asc' | 'desc') => {
  const aA = a.replace(regAlpha, '');
  const bA = b.replace(regAlpha, '');
  if (aA === bA) {
    const aN = parseInt(a.replace(regNum, ''), 10);
    const bN = parseInt(b.replace(regNum, ''), 10);
    return aN === bN ? 0 : direction === 'asc' ? (aN > bN ? 1 : -1) : aN > bN ? -1 : 1;
  } else {
    return direction === 'asc' ? (aA > bA ? 1 : -1) : aA > bA ? -1 : 1;
  }
};

/**
 * Function that returns the start index of the nth instance of a substring in a string.
 *
 * @param string The string to check for substrings
 * @param subString The substring to check if exists in string param
 * @param nthInstance The nth instance of the substring to get the position index for. For
 * example, if the string is "hi-hi-hi" and you are wanting to get the position for
 * substring "hi" for the 2nd instance of it, the function would return 3 since that is
 * the position of the start index for the 2nd instance of the substring "hi".
 */
export function getPosition(string: string, subString: string, nthInstance: number) {
  const index = string.split(subString, nthInstance).join(subString).length;
  if (index === string.length) return -1;
  return index;
}

export function findPath(paths: Paths, segments: string[], currentSegment: string) {
  let currPaths = paths;
  let isPrefix = false;
  segments.forEach((segment) => {
    if (currPaths[segment]) {
      currPaths = currPaths[segment]['children']!;
      if (segment === currentSegment) {
        isPrefix = true;
      }
    }
  });
  return { currPaths, isPrefix };
}
