import { css } from '@emotion/react';
import { getIconTheme, getOriginalIconTheme, IconProps, IconThemeValues } from './Icon-theme';

type IconStyleProps = IconProps & { noFill?: boolean };

const styles = (
  { iconSize, fontColorStyle }: IconThemeValues,
  { minWidth, noFill }: { minWidth: number; noFill?: boolean }
) => [
  css`
    /* Keeps size consistent inside flex parents */
    ${fontColorStyle ?? ''};
    min-width: ${minWidth}px;
    transition: fill 200ms ease-out;
    user-select: none;
    line-height: 1;
    font-size: ${iconSize};
  `,
  noFill &&
    css`
      fill: none;
    `,
  !noFill &&
    css`
      fill: currentColor;
    `,
];

export const IconStyles = {
  Icon: (IconProps: IconStyleProps) =>
    styles(getIconTheme(IconProps), { minWidth: IconProps.size, noFill: IconProps.noFill }),
};

export const IconStylesOriginal: typeof IconStyles = {
  Icon: (originalIconProps: IconStyleProps) =>
    styles(getOriginalIconTheme(originalIconProps), {
      minWidth: originalIconProps.size,
      noFill: originalIconProps.noFill,
    }),
};
