import { css } from '@emotion/react';
import { useThemeValues } from '../../../hooks';
import { animated } from '../../animated';
import { ContentLoader } from '../../loader';
import { useModalContext } from '../provider';

type Props = {
  children: React.ReactNode;
  style: { [key: string]: any };
};

export const FullScreenModalContainer = ({ children, style, ...rest }: Props) => {
  const { containerProps, loadingState } = useModalContext();
  const { zIndex } = useThemeValues();
  return (
    <animated.section
      {...containerProps}
      {...rest}
      css={css`
        width: 100%;
        height: 100%;
        background: white;
        display: flex;
        flex-direction: column;
        overflow: auto;
        position: absolute;
        top: 0;
        left: 0;
        z-index: ${zIndex.modals};

        :focus {
          outline: none;
        }
      `}
      style={style}
    >
      {children}
      <ContentLoader {...loadingState} />
    </animated.section>
  );
};
