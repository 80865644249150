import { useEffect, useState, useRef } from 'react';
import { O } from 'ts-toolbelt';
import { toPixelValue } from './to-pixel-value';

type MediaRange = O.AtLeast<
  {
    minWidth?: number;
    maxWidth?: number;
  },
  'maxWidth' | 'minWidth'
>;

export function getMediaQuery({ minWidth, maxWidth }: MediaRange): string {
  if (maxWidth !== undefined && minWidth !== undefined) {
    return `(min-width: ${toPixelValue(minWidth)}) and (max-width: ${toPixelValue(maxWidth)})`;
  } else if (maxWidth !== undefined) {
    return `(max-width: ${toPixelValue(maxWidth)})`;
  } else if (minWidth !== undefined) {
    return `(min-width: ${toPixelValue(minWidth)})`;
  } else {
    throw new Error('minWidth or maxWidth must be provided');
  }
}

export function useMediaQuery(query: string) {
  const [hasMatch, setHasMatch] = useState(() => window.matchMedia(query).matches);
  useEffect(() => {
    const mediaQueryList = window.matchMedia(query);
    const listener = (event: MediaQueryListEvent) => {
      if (!event.matches) {
        return;
      }
      setHasMatch(event.matches);
    };
    if (mediaQueryList.addEventListener) {
      mediaQueryList.addEventListener('change', listener);
    } else {
      mediaQueryList.addListener(listener);
    }
    return () => {
      if (mediaQueryList.removeEventListener) {
        mediaQueryList.removeEventListener('change', listener);
      } else {
        mediaQueryList.removeListener(listener);
      }
    };
  }, []);
  return hasMatch;
}

export const useMatchMedia = (mediaRange: MediaRange) => {
  const matchMediaQuery = useRef(window.matchMedia(getMediaQuery(mediaRange)));
  const [isMediaMatch, setIsMediaMatch] = useState(matchMediaQuery.current.matches);

  useEffect(() => {
    matchMediaQuery.current.onchange = function isMobileOnChange(event) {
      setIsMediaMatch(event.matches);
    };

    return function isMobileCleanup() {
      matchMediaQuery.current.onchange = null;
    };
  }, []);

  return isMediaMatch;
};
