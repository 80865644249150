import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { ContentLoader, Text } from '@frontend/design-system';
import { usePaymentPlanInfo } from '../../hooks';
import { LocationInformation } from '../PaymentPortal';
import { Authorization } from './authorization';
import { PlanDetails } from './plan-details';

const styles = {
  page: css`
    margin: 0 auto;
    width: 100%;
    max-width: ${theme.spacing(104)};
    & > section {
      padding: ${theme.spacing(3, 5)};
    }
    & > section:not(:last-of-type) {
      border-bottom: 1px solid ${theme.colors.neutral20};
    }
  `,
  locationInfo: css`
    padding: ${theme.spacing(5)};
  `,
  noMargin: css`
    margin: 0px;
  `,
};

export const PaymentPlansConfirm = () => {
  const { t } = useTranslation('payments');
  const { location, paymentPlanLoading } = usePaymentPlanInfo();

  return (
    <div css={styles.page}>
      {paymentPlanLoading ? (
        <ContentLoader show={true} />
      ) : (
        <>
          {location && <LocationInformation {...location} style={styles.locationInfo} />}
          <section>
            {' '}
            <Text css={styles.noMargin}>
              {t(
                'You’ve signed up for a payment plan! The details of the plan can be found below. If you have any questions please contact us. We just need you to confirm below to get your payment plan started.'
              )}
            </Text>
          </section>
          <PlanDetails />
          <Authorization />
        </>
      )}
    </div>
  );
};
