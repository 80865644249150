import { css } from '@emotion/react';
import { TextButtonStyleValues, textButtonTheme, textButtonThemeOriginal } from './text-button-theme';

type TextButtonProps = {
  hasIcon: boolean;
  variant?: 'rounded' | 'rounded-small';
};

const styles = (
  {
    activeBackgroundColor,
    disabledTextColor,
    focusBackgroundColor,
    fontFamily,
    fontSize,
    primaryColor,
    spacing,
    textColor,
    roundedBorderRadius,
    roundedHoverColor,
  }: TextButtonStyleValues,
  { hasIcon, variant }: TextButtonProps
) => {
  return [
    css`
      ${hasIcon
        ? `
            align-items: center;
            display: flex;
    
            svg {
              margin-right: ${spacing(1)};
            }
          `
        : ''}

      background: transparent;
      border: 2px solid transparent;
      border-radius: 2px;
      color: ${textColor};
      cursor: pointer;
      font-family: ${fontFamily};
      font-size: ${fontSize(14)};
      padding: ${spacing(0.5, 1)};
      transition: background border-radius 250ms ease-out, border-color 250ms ease-out;

      :hover,
      :focus {
        background: ${!variant ? focusBackgroundColor : roundedHoverColor};
        border-color: ${!variant ? focusBackgroundColor : roundedHoverColor};
      }

      :focus {
        outline-color: ${primaryColor};
        outline-offset: 5px;
        outline-width: 2px;
      }

      :active {
        background: ${activeBackgroundColor};
        border-color: ${activeBackgroundColor};
        outline: none;
      }

      :disabled {
        background: transparent;
        border-color: transparent;
        color: ${disabledTextColor};
        cursor: default;
      }
    `,
    variant === 'rounded' &&
      css`
        border-radius: ${roundedBorderRadius};
        padding: ${spacing(1)};
      `,
    variant === 'rounded-small' &&
      css`
        font-size: ${fontSize(12)};
        border-radius: ${roundedBorderRadius};
      `,
  ];
};

export const TextButtonStyles = {
  TextButton: (textButtonProps: TextButtonProps) => styles(textButtonTheme, textButtonProps),
};

export const TextButtonStylesOriginal = {
  TextButton: (textButtonProps: TextButtonProps) => styles(textButtonThemeOriginal, textButtonProps),
};
