import { ExposedError, Terminal } from '@stripe/terminal-js';
import { createShallowStore, createStoreWithSubscribe } from '@frontend/store';

export type ReaderConnectionStatusType = 'connecting' | 'connected' | 'not_connected';
export type ReaderPaymentStatusType = 'not_ready' | 'ready' | 'waiting_for_input' | 'processing';

type TerminalState = {
  connectionError?: ExposedError | null;
  stripeTerminal?: Terminal;
  terminalLocationId?: string;
  creatingNewTerminal: boolean;
};

type TerminalActions = {
  setConnectionError: (connectionError: TerminalState['connectionError']) => void;
  setStripeTerminal: (stripeTerminal: TerminalState['stripeTerminal']) => void;
  setTerminalLocationId: (terminalLocationId: TerminalState['terminalLocationId']) => void;
  setCreatingNewTerminal: (creatingNewTerminal: TerminalState['creatingNewTerminal']) => void;
};

type TerminalStore = TerminalState & TerminalActions;

export const useTerminalStore = createStoreWithSubscribe<TerminalStore>(
  (set) => {
    return {
      creatingNewTerminal: false,
      setConnectionError: (connectionError: ExposedError | undefined | null) =>
        set((prevState) => ({ ...prevState, connectionError })),
      setStripeTerminal: (stripeTerminal: Terminal | undefined) =>
        set((prevState) => ({ ...prevState, stripeTerminal })),
      setTerminalLocationId: (terminalLocationId: string | undefined) =>
        set((prevState) => ({ ...prevState, terminalLocationId })),
      setCreatingNewTerminal: (creatingNewTerminal: boolean) =>
        set((prevState) => ({ ...prevState, creatingNewTerminal })),
    };
  },
  { name: 'TerminalStore' }
);

export const useTerminalShallowStore = createShallowStore(useTerminalStore);
