import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../component-theme/theme-types';

export type PaginationThemeValues = {
  background: WeaveThemeValues['Color'];
  borderRadiusMedium: WeaveThemeValues['BorderRadius'];
  borderRadiusSmall: WeaveThemeValues['BorderRadius'];
  primary: WeaveThemeValues['Color'];
  selectedFontWeight: WeaveThemeValues['FontWeight'];
  spacing: WeaveThemeValues['Spacing'];
};

export const paginationButtonTheme: PaginationThemeValues = {
  background: theme.colors.neutral10,
  borderRadiusMedium: theme.borderRadius.medium,
  borderRadiusSmall: theme.borderRadius.small,
  primary: theme.colors.primary50,
  selectedFontWeight: theme.font.weight.semibold,
  spacing: theme.spacing,
};

export const paginationButtonThemeOriginal: typeof paginationButtonTheme = {
  background: themeOriginal.colors.gray200,
  borderRadiusMedium: themeOriginal.borderRadius.medium,
  borderRadiusSmall: themeOriginal.borderRadius.small,
  primary: themeOriginal.colors.weaveBlue,
  selectedFontWeight: themeOriginal.font.weight.semibold,
  spacing: themeOriginal.spacing,
};
