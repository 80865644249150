import { ReactNode, createContext, useContext, useMemo } from 'react';
import { UsePopoverDialogResponse, usePopoverDialog } from '@frontend/design-system';

type TableFilterContextState = {
  showNotification: boolean;
  popoverProps: UsePopoverDialogResponse;
};

const TableFilterContext = createContext<TableFilterContextState | undefined>(undefined);

type TableFilterProviderProps = {
  showNotification?: boolean;
  children: ReactNode;
};

export const TableFilterProvider = ({ showNotification = false, children }: TableFilterProviderProps) => {
  const popoverProps = usePopoverDialog({ placement: 'bottom-start' });

  const providerValue = useMemo(
    () => ({
      showNotification,
      popoverProps,
    }),
    [showNotification, popoverProps]
  );

  return <TableFilterContext.Provider value={providerValue}>{children}</TableFilterContext.Provider>;
};

export const useTableFilter = () => {
  const context = useContext(TableFilterContext);

  if (context === undefined) {
    throw new Error('Must use useTableFilter in a TableFilterProvider');
  }

  return context;
};
