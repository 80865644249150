import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import { ReactNode, forwardRef } from 'react';
import { theme } from '@frontend/theme';
import { WIDTH_DIMS } from '@frontend/design-system';

interface Props {
  children: ReactNode;
  className?: string;
  width?: keyof typeof WIDTH_DIMS;
}

export const AsidePanel = forwardRef<HTMLDivElement, Props>(({ width = 'small', children, ...rest }, ref) => {
  return (
    <motion.aside
      ref={ref}
      layout
      initial={{ width: 0, opacity: 0 }}
      animate={{ width: WIDTH_DIMS[width], opacity: 1 }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      exit={{ width: 0, opacity: 0 }}
      css={css`
        height: 100%;
        display: flex;
        width: ${WIDTH_DIMS[width]}px;
        border-left: 1px solid ${theme.colors.neutral20};
        border-top: 1px solid ${theme.colors.neutral20};
        position: relative;
      `}
      {...rest}
    >
      <motion.section
        css={css`
          min-width: ${WIDTH_DIMS[width]}px;
          overflow: hidden auto;
        `}
      >
        {children}
      </motion.section>
    </motion.aside>
  );
});
