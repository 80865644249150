import { ButtonHTMLAttributes, ReactNode, forwardRef } from 'react';
import { NakedButton } from '../../../naked';
import { useStyles } from '../../../use-styles';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  children: ReactNode;
  id: string;
  trackingId?: string;
  isActive: boolean;
};

export const Tab = forwardRef<HTMLButtonElement, Props>(({ children, isActive, ...rest }: Props, ref) => {
  const styles = useStyles('Tabs', 'tabItem');

  return (
    <NakedButton ref={ref} css={styles} {...rest}>
      {children}
    </NakedButton>
  );
});
