import { css } from '@emotion/react';
import ThinkLoaderGray from '../assets/thinking-loader/thinking-gray.svg?react';
import ThinkLoaderWhite from '../assets/thinking-loader/thinking-white.svg?react';

const styles = {
  thinkingLoader: css`
    @keyframes thinking-loader {
      0% {
        transform: rotate(0deg);
      }
      16.666% {
        transform: rotate(0deg);
      }
      16.667% {
        transform: rotate(60deg);
      }
      33.333% {
        transform: rotate(60deg);
      }
      33.334% {
        transform: rotate(120deg);
      }
      49.999% {
        transform: rotate(120deg);
      }
      50% {
        transform: rotate(180deg);
      }
      66.666% {
        transform: rotate(180deg);
      }
      66.667% {
        transform: rotate(240deg);
      }
      83.333% {
        transform: rotate(240deg);
      }
      83.334% {
        transform: rotate(300deg);
      }
      99.999% {
        transform: rotate(300deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    animation: thinking-loader 1s forwards infinite;
  `,
};

export const ThinkingLoader = ({ variant = 'white' }: { variant: 'white' | 'gray' }) => {
  return <div css={styles.thinkingLoader}>{variant === 'white' ? <ThinkLoaderWhite /> : <ThinkLoaderGray />}</div>;
};
