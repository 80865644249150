import { theme as themeOriginal } from '@frontend/theme-original';
import { theme } from '@frontend/theme';
import { WeaveThemeValues } from '../../component-theme/theme-types';

export type SpinnersThemeValue = {
  colors: WeaveThemeValues['FontColors'];
};

export type ContentLoadersThemeValue = {
  zIndex: WeaveThemeValues['ZIndex'];
};

export type ContentLoaderTextThemeValue = {
  spacing: WeaveThemeValues['Spacing'];
};

export type SkeletonLoaderThemeValues = {
  borderRadius: WeaveThemeValues['BorderRadius'];
  backgroundColor: WeaveThemeValues['Color'];
};

export const spinnersTheme: SpinnersThemeValue = {
  colors: theme.font.colors,
};

export const spinnersThemeOriginal: SpinnersThemeValue = {
  colors: themeOriginal.font.colors,
};

export const contentLoadersTheme: ContentLoadersThemeValue = {
  zIndex: theme.zIndex.high,
};

export const contentLoadersThemeOriginal: ContentLoadersThemeValue = {
  zIndex: themeOriginal.zIndex.high,
};

export const contentLoaderTextTheme: ContentLoaderTextThemeValue = {
  spacing: theme.spacing,
};

export const contentLoaderTextThemeOriginal: ContentLoaderTextThemeValue = {
  spacing: themeOriginal.spacing,
};

export const skeletonLoaderTheme: SkeletonLoaderThemeValues = {
  borderRadius: theme.borderRadius.small,
  backgroundColor: theme.colors.neutral10,
};

export const skeletonLoaderThemeOriginal: SkeletonLoaderThemeValues = {
  borderRadius: themeOriginal.borderRadius.small,
  backgroundColor: themeOriginal.colors.gray300,
};
