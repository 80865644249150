import { css } from '@emotion/react';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { RegisterTerminalModalSteps } from './hooks';
import { RegisterTerminalModalStep } from './register-terminal-modal-step';

const styles = {
  list: css`
    list-style-type: number;
    padding-left: ${theme.spacing(2)};
    margin-top: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(1)};
    & > li:not(:first-of-type) {
      margin-top: ${theme.spacing(2)};
    }
    & > li:not(:last-of-type) {
      margin-bottom: ${theme.spacing(2)};
    }
  `,
  italicText: css`
    color: ${theme.font.colors.light};
  `,
};

export const OpenWifiSettings = () => {
  const { t } = useTranslation('payments');

  return (
    <RegisterTerminalModalStep title={t('Open WiFi Settings')} nextStep={RegisterTerminalModalSteps.ConnectToNetwork}>
      <ol css={styles.list}>
        <li>{t('Ensure that your terminal is powered on and complete any available software updates')}</li>
        <li>{t('Swipe from the left side of the screen to the right side to open the menu')}</li>
        <li>{t('Tap Settings')}</li>
        <li>{t('Enter the Admin PIN 07139')}</li>
        <li>{t('Tap Wi-Fi Settings')}</li>
      </ol>
    </RegisterTerminalModalStep>
  );
};

export const ConnectToNetwork = () => {
  const { t } = useTranslation('payments');

  return (
    <RegisterTerminalModalStep
      title={t('Connect to network')}
      nextStep={RegisterTerminalModalSteps.GeneratePairingCode}
      hasContinue
    >
      <ol css={styles.list} start={6}>
        <li>
          <Trans t={t}>
            Tap the name of your secure or private WiFi network
            <br />
            <i css={styles.italicText}>
              Ensure the network you connect your terminal to is the same network your desktop application is connected
              to
            </i>
          </Trans>
        </li>
        <li>{t('Enter your WiFi password using the digital keyboard at the bottom of the display screen')}</li>
        <li>{t('Tap Connect')}</li>
        <li>
          {t('Once connected to WiFi, tap the back arrow at the bottom of the screen to go back to your Admin options')}
        </li>
      </ol>
    </RegisterTerminalModalStep>
  );
};
