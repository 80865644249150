import { createContext, useContext } from 'react';

export const contextFactory = <T>(error?: string, initialState?: T) => {
  const Context = createContext<T | undefined>(initialState);

  const useContextFactory = (): T => {
    const context = useContext(Context);

    if (typeof context === 'undefined') {
      throw Error(error ?? 'Hook from contextFactory must be used in an associated provider');
    }

    return context;
  };

  return [Context, useContextFactory] as const;
};
