import {
  Appointment,
  ContactInfo,
  ListAppointmentsRequest,
  ListContactInfosRequest,
  ListOrdersRequest,
  ListPersonsRequest,
  ListRecallsRequest,
  ListRecordHistoriesRequest,
  Order,
  Person,
  Recall,
} from '@weave/schema-gen-ts/dist/schemas/messaging/etl/history/v1/service.pb';
import { ListRecipientsRequest_AppointmentQualifier } from '@weave/schema-gen-ts/dist/schemas/messaging/etl/bulk/v1/service.pb';
import { SchemaSMSSharedModels } from './schema-types';
import { Merge } from 'ts-toolbelt/out/Object/Merge';
import { PersonTypes } from '@frontend/api-person';
import { SMSTag, Thread } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/models.pb';
import { SchemaIO, SchemaSMSNumberService } from '@frontend/schema';
import { ContactType_Enum } from '@weave/schema-gen-ts/dist/shared/persons/v3/enums.pb';

export interface Filters {
  primaryInsurance?: string[];
  appointmentDate?: [string, string];
  appointmentTypes?: string[];
  appointmentStatuses?: string[];
  minDaysSinceLastBulkMessage?: MinDaysOptions;
  minAge?: string;
  maxAge?: string;
  sourceTenantIds?: string[];
  recipientStatuses?: string[];
  appointmentPractitioners?: string[];
  recallTypes?: string[];
  recallDate?: [string, string];
  searchToken?: string;
  appointmentQualifier?: AppointmentQualifierOptions;
}

export type AppointmentQualifierOptions = 'Last Seen' | 'Next Appointment' | 'No Appointment';

export const AppointmentQualifierMap = {
  'Last Seen': ListRecipientsRequest_AppointmentQualifier.LAST_SEEN,
  'Next Appointment': ListRecipientsRequest_AppointmentQualifier.NEXT_APPOINTMENT,
  'No Appointment': ListRecipientsRequest_AppointmentQualifier.NO_APPOINTMENT,
};

export type MinDaysOptions = '30+ days' | '60+ days' | '90+ days' | '120+ days' | '180+ days';

export enum MinDaysSinceLastBulkMessageMap {
  '30+ days' = 30,
  '60+ days' = 60,
  '90+ days' = 90,
  '120+ days' = 120,
  '180+ days' = 180,
}

export type DateList = string | SchemaSMSSharedModels.SMS;

export type InboxListItem = {
  locationId: string;
  autogeneratedBy?: string;
  body: string;
  createdAt: string;
  departmentId?: string;
  direction?: string;
  id: string;
  person?: SchemaSMSSharedModels.Person;
  isBlocked?: boolean;
  personId: string;
  personPhone: string;
  smsId: string;
  status?: string;
  threadId: string;
  numMedia: number;
  isSearchResult: boolean;
  uniqueTags?: SMSTag[];
};

export enum RecordType {
  APPOINTMENT = 'appointment',
  ORDER = 'order',
  RECALL = 'recall',
  CONTACTINFO = 'contactinfo',
  PERSON = 'person',
}

export type ListRequest =
  | ListRecallsRequest
  | ListOrdersRequest
  | ListPersonsRequest
  | ListAppointmentsRequest
  | ListContactInfosRequest
  | ListRecordHistoriesRequest;

export type SmsRecord = Recall | Order | Appointment | ContactInfo | Person;

export type ListViews = 'inbox' | 'blocked' | 'archived' | 'scheduled';

export type SMSNumber = {
  number: string;
  label: string;
};

export type SMSNumberValidity = SMSNumber & {
  isValid: boolean;
};

export type TransformedValidity = SchemaIO<(typeof SchemaSMSNumberService)['GetValidity']>['output'] & {
  number: string;
  contactType: ContactType_Enum;
};

/**
 * KnownProgramSlugIds is a FE-only enum. The BE just uses a string, but we want to keep slugIds consistent
 */
export enum KnownProgramSlugIds {
  MANUAL_MESSAGES = 'manual-messages',
  PAYMENT_REMINDERS = 'payment-reminders',
  APPOINTMENTS_REMINDERS = 'appointments-reminders',
  REVIEW_REQUESTS = 'review-requests',
  PRODUCT_READY = 'product-ready',
  BIRTHDAY = 'birthday',
  MESSAGING_PREFERENCES = 'messaging-preferences',
  MISSED_CALL = 'missed-call',
  MARKETING_MESSAGES = 'marketing-messages',
  RECALL = 'recall',
  SAVE_THE_DATE = 'save-the-date',
}

/**
 * KnownThreadStatuses is a FE-only enum. The BE just uses a string, but we want to keep statuses consistent
 */
export enum KnownThreadStatuses {
  NEW = 'new',
  READ = 'read',
  ERROR = 'error',
}

/**
 * A wrapper for the standard Person type with only the properties available in a thread Person required.
 */
export type InboxPerson = Merge<
  Partial<PersonTypes.Person>,
  {
    PersonID: PersonTypes.Person['PersonID'];
    FirstName: PersonTypes.Person['FirstName'];
    LastName: PersonTypes.Person['LastName'];
    PreferredName: PersonTypes.Person['PreferredName'];
  }
>;

export type ThreadMetaData = Omit<SchemaSMSSharedModels.Thread, 'messages'>;

export type MessageChatMeta = ThreadMetaData & {
  personPhone: string;
  targetSmsId?: string;
  targetSmsCreatedAt?: string;
  targetSmsClickCount?: number;
  isNew?: boolean;
};

export enum ThreadDataStatus {
  NEW = 'new',
  ACTIVE = 'active',
  ARCHIVED = 'archived',
}
export type ThreadData = {
  departmentId?: string;
  threadId: string;
  status: ThreadDataStatus;
};

export type ThreadsByPhone = Record<string, Thread[]>;

export type ThreadFocusedSmsData = {
  id: string;
  createdAt: string;
};

export enum EmailType {
  EMAIL = 'email',
  WEAVE_EMAIL = 'weave-email',
  WEAVE_EMAIL_UPDATE = 'weave-email-update',
}
