import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../component-theme';

export type SwitchChipGroupThemeValues = {
  spacing: WeaveThemeValues['Spacing'];
};

export const switchChipGroupTheme: SwitchChipGroupThemeValues = {
  spacing: theme.spacing,
};

export const switchChipGroupThemeOriginal: SwitchChipGroupThemeValues = {
  spacing: themeOriginal.spacing,
};

export type SwitchChipThemeValues = {
  selected: {
    backgroundColor: WeaveThemeValues['Color'];
    disabledBackgroundColor: WeaveThemeValues['Color'];
    hoverBackgroundColor: WeaveThemeValues['Color'];
    color: WeaveThemeValues['Color'];
  };
  focused: {
    borderColor: WeaveThemeValues['Color'];
  };
  disabled: {
    borderColor: WeaveThemeValues['Color'];
    color: WeaveThemeValues['Color'];
  };
  hover: {
    backgroundColor: WeaveThemeValues['Color'];
  };
};

export const switchChipTheme: SwitchChipThemeValues = {
  selected: {
    backgroundColor: theme.colors.primary50,
    disabledBackgroundColor: theme.colors.primary20,
    hoverBackgroundColor: theme.colors.primary60,
    color: theme.colors.white,
  },
  focused: {
    borderColor: theme.colors.primary50,
  },
  disabled: {
    borderColor: theme.colors.neutral20,
    color: theme.colors.neutral20,
  },
  hover: {
    backgroundColor: theme.colors.neutral10,
  },
};

export const switchChipThemeOriginal: SwitchChipThemeValues = {
  selected: {
    backgroundColor: themeOriginal.colors.weaveBlue,
    disabledBackgroundColor: themeOriginal.colors.disabled,
    hoverBackgroundColor: themeOriginal.colors.pressedBlue,
    color: themeOriginal.colors.white,
  },
  focused: {
    borderColor: themeOriginal.colors.weaveBlue,
  },
  disabled: {
    borderColor: themeOriginal.colors.gray100,
    color: themeOriginal.colors.gray100,
  },
  hover: {
    backgroundColor: themeOriginal.colors.gray100,
  },
};
