import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../component-theme/theme-types';

export type StoryHeaderProps = {
  backgroundColor: WeaveThemeValues['Color'];
  borderRadius: WeaveThemeValues['BorderRadius'];
  spacing: WeaveThemeValues['Spacing'];
};

export type WarningTypeProps = {
  type: 'warn' | 'error' | 'notify';
};

export const getStoryHeaderTheme = ({ type }: WarningTypeProps) => ({
  backgroundColor: type === 'warn' ? theme.colors.status.warning : theme.colors.primary50,
  borderRadius: theme.borderRadius.medium,
  spacing: theme.spacing,
});

export const getStoryHeaderThemeOriginal = ({ type }: WarningTypeProps) => ({
  backgroundColor: themeOriginal.colors[type === 'warn' ? 'error' : 'weaveBlue'],
  borderRadius: themeOriginal.borderRadius.medium,
  spacing: themeOriginal.spacing,
});
