import { HTMLAttributes, ElementType, ReactNode, FC } from 'react';
import { useActiveTab } from './tabs.provider';

type Props = HTMLAttributes<HTMLElement> & {
  as?: ElementType | FC<React.PropsWithChildren<unknown>>;
  children: ReactNode;
  controller: string;
  id: string;
};

export const TabPanel = ({ as = 'section', controller, id, ...rest }: Props) => {
  const { activeTab } = useActiveTab();
  const hidden = activeTab !== controller;
  const Component = as;
  return <Component {...rest} role='tabpanel' hidden={hidden} aria-labelledby={controller} />;
};
