import { css } from '@emotion/react';
import { transparentize } from '../../../../helpers';
import type { RadioThemeValues } from './radio-theme';
import { radioTheme, radioThemeOriginal } from './radio-theme';
import type { RadioOptionProps } from './types';

type RadioStyleProps = Pick<RadioOptionProps, 'active' | 'checked' | 'disabled'>;

const styles = (
  { selectedColor, focusColor, disabledColor, borderColor }: RadioThemeValues,
  { active, checked, disabled }: RadioStyleProps
) => [
  css`
    min-width: 20px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    ::before {
      width: 28px;
      height: 28px;
      content: '';
      display: block;
      background: ${disabledColor};
      border-radius: 50%;
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      margin-top: -4px;
      margin-left: -4px;
      transform: scale(0);
      transform-origin: 50% 50%;
      transition: transform 200ms ease-out;
    }

    ::after {
      content: '';
      width: 100%;
      height: 100%;
      background: white;
      border: 1px solid ${disabled ? disabledColor : borderColor};
      border-radius: 50%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      transition: background 200ms ease-out, border-color 200ms ease-out;
    }
  `,
  !disabled &&
    active &&
    css`
      ::before {
        transform: scale(1);
      }

      ::after {
        border-color: ${borderColor};
      }
    `,
  checked &&
    css`
      ::before {
        background: ${transparentize(0.7, focusColor as string)};
      }

      ::after {
        background: ${selectedColor};
        border-color: ${selectedColor};
      }
    `,
  checked &&
    disabled &&
    css`
      opacity: 0.4;
    `,
];

export const RadioStyles = {
  Radio: (radioStyleProps: RadioStyleProps) => styles(radioTheme, radioStyleProps),
};

export const RadioStylesOriginal = {
  Radio: (radioStyleProps: RadioStyleProps) => styles(radioThemeOriginal, radioStyleProps),
};
