import {
  CreditCardBrand,
  CreditCardBrandKeys,
  FilterPaymentType,
  FilterPaymentTypeKeys,
  PaymentType,
  StatusKeys,
} from '@frontend/api-invoices';
import { PaymentPlanFrequency } from '@frontend/api-payment-plans';
import { RefundFilterType } from '@frontend/api-refunds';
import { i18next } from '@frontend/i18n';

export type GetLocationName = (locationId: string) => string | null | undefined;

export const filterStatusMap = (key: StatusKeys) => {
  const statusMap = {
    paid: i18next.t('Paid', { ns: 'payments' }),
    partiallyPaid: i18next.t('Partially Paid', { ns: 'payments' }),
    unpaid: i18next.t('Unpaid', { ns: 'payments' }),
  };
  return statusMap[key];
};

export const filterCardTypeMap = (key: CreditCardBrandKeys) => {
  const cardTypeMap = {
    Amex: i18next.t('American Express', { ns: 'payments' }),
    Diners: i18next.t('Diners Club', { ns: 'payments' }),
    Discover: i18next.t('Discover', { ns: 'payments' }),
    JCB: i18next.t('JCB', { ns: 'payments' }),
    MasterCard: i18next.t('Mastercard', { ns: 'payments' }),
    UnionPay: i18next.t('UnionPay', { ns: 'payments' }),
    Visa: i18next.t('VISA', { ns: 'payments' }),
    CardBrandUnknown: i18next.t('Others', { ns: 'payments' }),
  };
  return cardTypeMap[key];
};

export const filterPaymentMethodMap = (key: FilterPaymentTypeKeys) => {
  const paymentMethodMap = {
    Card: i18next.t('Card', { ns: 'payments' }),
    Affirm: i18next.t('Affirm', { ns: 'payments' }),
    Link: i18next.t('Link', { ns: 'payments' }),
    ACH: i18next.t('ACH', { ns: 'payments' }),
  };
  return paymentMethodMap[key];
};

export const planStatusMap = {
  Active: i18next.t('Active', { ns: 'payments' }),
  Paused: i18next.t('Paused', { ns: 'payments' }),
  Failed: i18next.t('Failed', { ns: 'payments' }),
  Completed: i18next.t('Completed', { ns: 'payments' }),
  Canceled: i18next.t('Canceled', { ns: 'payments' }),
};

export const frequencyDisplayMap = {
  [PaymentPlanFrequency.Monthly]: 'every month',
  [PaymentPlanFrequency.BiWeekly]: 'every other week',
  [PaymentPlanFrequency.Weekly]: 'every week',
};

export const getPaymentMethod = (paymentType: PaymentType | undefined, cardBrand: CreditCardBrand | undefined) => {
  const isCardType = paymentType && [PaymentType.Card, PaymentType.CardPresent].includes(paymentType);
  return isCardType ? cardBrand : paymentType;
};

export const kebabCase = (str: string) =>
  str
    .replace(/([a-z])([A-Z])/g, '$1-$2') // get all lowercase letters that are near to uppercase ones
    .replace(/[\s_]+/g, '-') // replace all spaces and low dash
    .toLowerCase();

export const setAndNotUnknown = (input: string | undefined, numDigits?: number) => {
  const testWord = 'UNKNOWN';

  return input !== '' && input !== testWord.substr(0, numDigits);
};

export const parseName = (input: string) => {
  const fullName = input || '';
  const result = {
    firstName: '',
    lastName: '',
  };

  const splitName = fullName.split(' ');
  result.firstName = splitName[0];
  result.lastName = splitName.slice(1, splitName.length).join(' ');

  return result;
};

export const hasFilter = (filter: Record<string, unknown> | undefined, ignoreFields: string[] = []) =>
  !!filter &&
  !!Object.entries(filter).filter(
    ([key, value]) => !ignoreFields.includes(key) && (Array.isArray(value) ? !!value.length : !!value)
  ).length;

export const getRefundsQueryFilter = (filter: RefundFilterType) => {
  const { origin, paymentType, ...rest } = filter;
  return {
    ...rest,
    ...(origin?.length ? { origin: origin?.join(',') } : {}),
    ...(paymentType?.length
      ? {
          paymentType: [
            ...paymentType,
            ...(paymentType.includes(FilterPaymentType.Card) ? [PaymentType.CardPresent] : []),
          ],
        }
      : {}),
  };
};

export const pluralize = (count: number) => (count === 1 ? '' : 's');

export enum PaymentsTableInstances {
  Invoices = 'payments-invoices',
  Refunds = 'payments-refunds',
  Payouts = 'payments-payouts',
  PaymentPlans = 'payments-paymentPlans',
  PaymentRequests = 'payments-payment-requests',
}

export enum PaymentsNavAlerts {
  InvalidInvoice = 'Invalid Invoice',
  InvalidPayout = 'Invalid Payout',
  CreatePaymentRequest = 'Create Payment Request',
}

export const ordinalize = (i: number) => {
  const j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) return i + 'st';
  if (j === 2 && k !== 12) return i + 'nd';
  if (j === 3 && k !== 13) return i + 'rd';
  return i + 'th';
};
