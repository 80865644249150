import { useTranslation } from '@frontend/i18n';
import { Heading, SelectAllIcon, Text } from '@frontend/design-system';
import * as styles from '../../../styles/weave-pay.styles';
import { Payment } from '../../../types/payment';
import { displayAmount } from '../../../utils/currency';

export const SuccessComponent = ({ payment }: { payment: Payment }) => {
  const { t } = useTranslation('weave-pay');

  return (
    <div css={styles.successContainer}>
      <SelectAllIcon color='success' css={styles.successIcon} />
      <div css={styles.successText}>
        <Heading level={2} css={styles.successHeader}>
          {t('Payment received!')}
        </Heading>
        <Text css={styles.successBody}>
          {t(
            `${displayAmount(payment?.amount || 0)} payment for ${payment.patientName?.firstName} ${
              payment.patientName?.lastName
            }`
          )}
        </Text>
        {payment.receiptEmail && (
          <Text css={styles.successBody}>{t(`An email confirmation was sent to ${payment.receiptEmail}`)}</Text>
        )}
      </div>
    </div>
  );
};
