import { css } from '@emotion/react';
import { useMerchant } from '@frontend/payments-hooks';
import { theme } from '@frontend/theme';

const onlineBillPayBtnStyle = css`
  background-color: ${theme.colors.primary50};
  color: ${theme.colors.white};
  font-family: ${theme.font.family};
  font-size: ${theme.fontSize(16)};
  font-weight: 500;
  padding: ${theme.spacing(1, 1.5)};
  border: none;
  border-radius: ${theme.borderRadius.small};
  cursor: pointer;
`;
const onlineBillPayBtnStyleString = onlineBillPayBtnStyle.styles;
const onlineBillPayBtnText = 'Pay Your Bill';

export const useOnlineBillPayAssets = () => {
  const { merchant, paymentsUrl } = useMerchant();

  const { locationPortalSlug = '', onlineBillPayLink: onlineBillPayUrl = '' } = merchant ?? {};
  const onlineBillPayQRImg = locationPortalSlug && `${paymentsUrl}/v1/download/qr?data=${locationPortalSlug}`;

  const onlineBillPayBtnHtml = `<a href="${onlineBillPayUrl}" target="_blank"><button style="${onlineBillPayBtnStyleString}">${onlineBillPayBtnText}</button></a>`;
  const onlineBillPayQRHtml =
    onlineBillPayQRImg && `<a href="${onlineBillPayUrl}" target="_blank"><img src="${onlineBillPayQRImg}"/></a>`;

  return { onlineBillPayUrl, onlineBillPayBtnText, onlineBillPayBtnHtml, onlineBillPayQRHtml, onlineBillPayQRImg };
};
