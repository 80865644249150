import { HTMLAttributes, ReactNode, useLayoutEffect, useRef } from 'react';
import { useActiveTab, useTabSetter } from './tabs.provider';
import { Tab } from './atoms';
import { createKeydownHandler, scrollIntoView } from '../../helpers';
import { motion } from 'framer-motion';
import { onlyText } from 'react-children-utilities';

type Props = HTMLAttributes<HTMLButtonElement> & {
  children: ReactNode;
  controls: string;
  disabled?: boolean;
  id: string;
  trackingId?: string;
};

export const TabListItem = ({ children, controls, id, onKeyDown, ...rest }: Props) => {
  const tabRef = useRef<HTMLButtonElement | null>(null);
  const handleTabChange = useTabSetter();
  const { activeTab } = useActiveTab();
  const isActive = activeTab === id;
  const handleClick = () => {
    handleTabChange(id);
  };

  useLayoutEffect(() => {
    if (isActive && tabRef?.current?.disabled) {
      handleTabChange('');
    }
  }, [isActive]);

  return (
    <Tab
      {...rest}
      data-text={onlyText(children as React.ReactNode)}
      onKeyDown={createKeydownHandler({
        shouldLoop: true,
        shouldScrollIntoView: true,
        siblingSelector: '[role="tab"]',
        parentSelector: '[role="tablist"]',
        orientation: 'horizontal',
        onKeyDown,
      })}
      id={id}
      aria-controls={controls}
      aria-selected={isActive}
      role='tab'
      tabIndex={isActive ? 0 : -1}
      onClick={() => {
        handleClick();
        scrollIntoView(tabRef.current, true);
      }}
      isActive={isActive}
      ref={tabRef}
    >
      {children}
      {isActive && (
        <motion.span
          className='sliding__border'
          layoutId='sliding__border'
          initial={false}
          transition={{ ease: 'easeInOut', duration: 0.3 }}
        />
      )}
    </Tab>
  );
};
