import { useQueryClient } from 'react-query';
import { useSettingsPathLabelMap } from './use-settings-path-label-map';
import { extractAndFormatPathName } from '@frontend/string';
import { constructRoutePath } from './utils';
constructRoutePath;

export const useConstructSettingsLabel = () => {
  const queryClient = useQueryClient();
  const pathLabelMap = useSettingsPathLabelMap();

  const constructSettingsLabel = (crumb: string, params: any) => {
    const mappedLabel = pathLabelMap[crumb as keyof typeof pathLabelMap]?.label;

    const isLabelFunction = typeof mappedLabel === 'function';

    if (isLabelFunction) {
      const cachedLabel = queryClient.getQueryData(['auto-settings-label', constructRoutePath(crumb, params)]);
      return cachedLabel ?? '';
    }

    if (mappedLabel) return mappedLabel;

    const label = extractAndFormatPathName(crumb);
    return label.includes(':') ? params[label.slice(1)] : label;
  };

  return constructSettingsLabel;
};
