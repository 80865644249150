import { css } from '@emotion/react';
import { Text } from '../../';
import { styles } from '../../../styles';
import { useStyles } from '../../../use-styles';
import { Heading } from '../../heading';
import { useModalContext } from '../provider';

type Props = {
  title?: string;
  subtitle?: string;
  className?: string;
};

export const FullscreenModalHeader = ({ title, subtitle, ...rest }: Props) => {
  const { titleId } = useModalContext();
  const fullscreenModalHeaderStyle = useStyles('Modal', 'fullscreenModalHeader');
  return (
    <header css={fullscreenModalHeaderStyle} {...rest}>
      {!!title && (
        <Heading
          title={title}
          id={titleId}
          css={[
            css`
              margin: 0;
            `,
            styles.truncate,
          ]}
          level={2}
          textAlign='center'
        >
          {title}
          {!!subtitle && (
            <Text
              title={subtitle}
              css={[
                css`
                  display: block;
                `,
                styles.truncate,
              ]}
              as='span'
              size='medium'
              textAlign='center'
              weight='regular'
            >
              {subtitle}
            </Text>
          )}
        </Heading>
      )}
    </header>
  );
};
