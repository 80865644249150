import { ReactNode, useEffect } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { CheckboxField, useFormField } from '@frontend/design-system';
import { kebabCase } from '../../utils';
import { FilterGroup } from './filter-group';

const checkBoxStyle = css`
  margin: ${theme.spacing(1, 3)};
  > label {
    margin: 0; /*Because bootstrap */
    margin-left: ${theme.spacing(2)};
  }
`;

type TableFilterOptionProps = {
  label: string;
  initialState?: boolean;
  animationHeight: number;
  children: ReactNode;
  onToggle?: (state: boolean) => void;
  onShow?: () => void;
  onHide?: () => void;
};

export const TableFilterOption = ({
  initialState = false,
  animationHeight,
  label,
  children,
  onToggle,
  onShow,
  onHide,
  ...rest
}: TableFilterOptionProps) => {
  const showOption = useFormField({
    type: 'checkbox',
    value: initialState,
  });

  useEffect(() => {
    onToggle?.(showOption.value);
    if (showOption.value) {
      onShow?.();
    } else {
      onHide?.();
    }
  }, [showOption.value]);

  return (
    <>
      <CheckboxField
        name={`${kebabCase(label)}-${showOption.id}`}
        label={label}
        {...showOption}
        css={checkBoxStyle}
        {...rest}
      />
      <FilterGroup
        active={showOption.value}
        remHeight={animationHeight}
        css={css`
          > div:not(:last-child) {
            margin-bottom: ${theme.spacing(3)};
          }
        `}
      >
        {children}
      </FilterGroup>
    </>
  );
};
