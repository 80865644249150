import { forwardRef, type ComponentProps } from 'react';
import { css } from '@emotion/react';
import { CalendarIcon } from '../../../../icon';
import { NakedButton } from '../../../../naked';
import { DateInput } from '../../atoms';
import type { BasicFormFieldProps, ExtraFieldProps } from '../../layouts';
import { FieldLayout } from '../../layouts';
import { useStyles } from '../../../../use-styles';
import { ReferenceType } from '@floating-ui/react-dom-interactions';

export type DatePickerBaseFieldRef = {
  buttonRef: ((node: ReferenceType | null) => void) | React.RefObject<HTMLButtonElement>;
  fieldRef: React.RefObject<HTMLDivElement>;
};

export type DatePickerBaseFieldProps = Omit<BasicFormFieldProps<'date'>, 'active'> &
  Pick<ExtraFieldProps, 'containerCss'> & {
    children?: React.ReactNode;
    fieldActive: boolean;
    flyoutActive: boolean;
    onCalendarIconClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onKeyDown: (e: React.KeyboardEvent) => void;
  };

export const DatePickerBaseField = forwardRef<DatePickerBaseFieldRef, DatePickerBaseFieldProps>(
  ({ children, onCalendarIconClick, flyoutActive, fieldActive, onKeyDown, disabled, ...props }, ref) => {
    const styles = useStyles('DatePickerBaseFieldIconButtonStyle');

    if (ref === null || typeof ref === 'function' || ref.current === null) return null;

    const { buttonRef, fieldRef } = ref.current;

    return (
      <FieldLayout
        ref={fieldRef}
        fieldComponentProps={{ ref: fieldRef, onKeyDown }}
        //TODO: this might be able to be improved. But this type cast just makes sure the field prop is any of the correct field types
        field={DateInput as ComponentProps<typeof FieldLayout>['field']}
        active={fieldActive || flyoutActive}
        endAdornment={
          <NakedButton
            aria-label='activate calendar flyout'
            ref={buttonRef}
            onClick={onCalendarIconClick}
            css={styles}
            onBlur={() => props.onBlur()}
            onFocus={() => props.onFocus()}
            tabIndex={-1}
            disabled={disabled}
            type='button'
          >
            <CalendarIcon size={16} color='light' />
          </NakedButton>
        }
        css={[
          css`
            max-width: 318px;
          `,
          flyoutActive &&
            css`
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
            `,
        ]}
        disabled={disabled}
        {...props}
      />
    );
  }
);
