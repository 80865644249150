/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const CalendarIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M20,9.5 L20,6.34146341 C20,5.94745539 19.6642136,5.62804878 19.25,5.62804878 L17.25,5.62804878 L17.25,6.75 C17.25,7.44035594 16.6903559,8 16,8 C15.3096441,8 14.75,7.44035594 14.75,6.75 L14.75,5.62804878 L9.75,5.62804878 L9.75,6.75 C9.75,7.44035594 9.19035594,8 8.5,8 C7.80964406,8 7.25,7.44035594 7.25,6.75 L7.25,5.62804878 L5.25,5.62804878 C4.83578644,5.62804878 4.5,5.94745539 4.5,6.34146341 L4.5,9.5 L20,9.5 Z M20,12 L4.5,12 L4.5,19.6585366 C4.5,20.0525446 4.83578644,20.3719512 5.25,20.3719512 L19.25,20.3719512 C19.6642136,20.3719512 20,20.0525446 20,19.6585366 L20,12 Z M17.25,3.25 L19.25,3.25 C21.0449254,3.25 22.5,4.63409532 22.5,6.34146341 L22.5,19.6585366 C22.5,21.3659047 21.0449254,22.75 19.25,22.75 L5.25,22.75 C3.45507456,22.75 2,21.3659047 2,19.6585366 L2,6.34146341 C2,4.63409532 3.45507456,3.25 5.25,3.25 L7.25,3.25 L7.25,2.25 C7.25,1.55964406 7.80964406,1 8.5,1 C9.19035594,1 9.75,1.55964406 9.75,2.25 L9.75,3.25 L14.75,3.25 L14.75,2.25 C14.75,1.55964406 15.3096441,1 16,1 C16.6903559,1 17.25,1.55964406 17.25,2.25 L17.25,3.25 Z'
    />
  </Icon>
);
