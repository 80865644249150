import React from 'react';

export type ClickHandler = (
  event?: React.PointerEvent | React.FormEvent
) => void | Promise<void> | boolean | Promise<boolean>;

/**
 * Handles closing a modal when the user's action function returns true
 * @param onClose The modal's onClose function which should be triggered if the user's handler function returns true
 * @param fn The user's handler function
 * @returns A new handler function
 */
export const withClose = (onClose: () => void, fn?: ClickHandler) => (e?: React.PointerEvent | React.FormEvent) => {
  Promise.resolve(fn?.(e)).then((res) => {
    if (res === true) onClose();
  });
};
