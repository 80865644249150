import { MouseEventHandler } from 'react';
import { PersonsV3 } from '@frontend/api-person';
import { CustomContactFormContext, useCustomContactFormSlidePanel } from '@frontend/create-contact-panel';
import { formatPhoneNumber, formatPhoneNumberE164 } from '@frontend/phone-numbers';
import { useThreadHeaderContext } from '../thread-header';
import { ThreadTitle, ThreadTitleProps } from '../thread-title';

type TitleProps = Omit<ThreadTitleProps, 'text' | 'personId' | 'groupId' | 'variant'> & {
  onCreateContact?: CustomContactFormContext['onSave'];
  onCreateContactError?: CustomContactFormContext['onError'];
};

export const Title = ({
  onClick,
  openContactPanel = true,
  onCreateContact,
  onCreateContactError,
  ...props
}: TitleProps) => {
  const { personPhone, groupId, variant, primaryContact, primaryContactId } = useThreadHeaderContext();
  const text = primaryContact ? PersonsV3.PersonHelpers.getFullName(primaryContact) : formatPhoneNumber(personPhone);
  const { setShow } = useCustomContactFormSlidePanel();

  const openCreateContact = () => {
    setShow({
      show: true,
      context: {
        mode: 'create',
        person: {
          MobilePhone: formatPhoneNumberE164(personPhone).replace('+1', ''),
        },
        locationId: groupId,
        onSave: onCreateContact,
        onError: onCreateContactError,
      },
    });
  };

  const handleClick: MouseEventHandler<HTMLDivElement> = (e) => {
    if (openContactPanel && !primaryContact) {
      openCreateContact();
    }
    onClick?.(e);
  };

  return (
    <ThreadTitle
      css={[openContactPanel && !primaryContact && { cursor: 'pointer' }]}
      {...props}
      text={text}
      personId={primaryContact?.personId || primaryContactId}
      groupId={groupId}
      variant={variant}
      onClick={handleClick}
      openContactPanel={openContactPanel}
    />
  );
};
