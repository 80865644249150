import { css } from '@emotion/react';
import { containerTheme, containerThemeOriginal, getAlertTextTheme, getAlertTextThemeOriginal } from './alert-theme';
import type { GetAlertTextThemeValues, DivStyleThemeValues, TextStyleProp } from './types';

const textStyle = ({ spacing, borderLeftColor, borderRadius }: GetAlertTextThemeValues) => css`
  display: flex;
  align-items: center;
  margin: 0;
  padding: ${spacing(2, 2, 2, 1.5)};

  ::before {
    content: '';
    width: 4px;
    height: 100%;

    background: ${borderLeftColor};
    border-top-left-radius: ${borderRadius};
    border-bottom-left-radius: ${borderRadius};
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }

  svg {
    min-width: 24px;
    margin-right: ${spacing(1)};
  }
`;

const containerStyle = ({ backgroundColor, borderRadius, boxShadow, zIndex }: DivStyleThemeValues) => css`
  width: 100%;
  background: ${backgroundColor};
  border-radius: ${borderRadius};
  box-shadow: ${boxShadow};
  cursor: pointer;
  overflow: hidden;
  position: relative;
  pointer-events: all;
  z-index: ${zIndex};
`;

export const deprecated_AlertStyles = {
  deprecated_Alert: {
    textStyle: (textStyleProps: TextStyleProp) => textStyle(getAlertTextTheme(textStyleProps)),
    containerStyle: containerStyle(containerTheme),
  },
};

export const deprecated_AlertStylesOriginal: typeof deprecated_AlertStyles = {
  deprecated_Alert: {
    textStyle: (textStyleProps: TextStyleProp) => textStyle(getAlertTextThemeOriginal(textStyleProps)),
    containerStyle: containerStyle(containerThemeOriginal),
  },
};
