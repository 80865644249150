import { css, SerializedStyles } from '@emotion/react';
import { getBaseFontSize } from '@frontend/theme-original';
import { styles } from '../../../../styles';
import { DirectionProps } from '../../hooks/types';
import type { DropdownMenuStylesType, InputStylesProps } from './old-theme';
import {
  dropdownMenuTheme,
  dropdownMenuThemeOriginal,
  inputTheme,
  inputThemeOriginal,
  placeholderTheme,
  placeholderThemeOriginal,
} from './old-theme';

type DropdownProps = { active?: boolean } & DirectionProps;

type PlaceholderStyleProps = { color: string };

type InputStyleProps = { isPlaceholder: boolean; disabled: boolean };

const BASE = getBaseFontSize();

export const activeDropdownStyle: (placement: string) => SerializedStyles = (placement = 'bottom') =>
  placement === 'bottom'
    ? css`
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      `
    : css`
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      `;

export const placeholderStyle = ({ color }: PlaceholderStyleProps) => css`
  color: ${color};
`;

export const inputStyle = (
  { fontFamily, fontSize, spacing, placeholderColor, disabledColor }: InputStylesProps,
  { isPlaceholder, disabled }: InputStyleProps
) => [
  css`
    font-family: ${fontFamily};
    font-size: ${fontSize(16)};
    line-height: 1.375;
    padding-right: ${spacing(4)};
    text-align: left;
    ${isPlaceholder && `color: ${placeholderColor}`}
    ${styles.truncate}
  > input[type='text'] {
      margin-right: 0;
      padding-right: ${spacing(3)};
      ::placeholder {
        color: ${placeholderColor};
      }
    }
  `,
  disabled &&
    css`
      color: ${disabledColor};
    `,
];
const upperBoxShadow = '1px -5px 14px 2px rgb(49 53 61 / 24%)';

export const dropdownMenuStyle = (
  { boxShadow, borderRadius, zIndex }: DropdownMenuStylesType,
  { direction, active }: DropdownProps
) => css`
  width: 100%;
  max-height: ${208 / BASE}rem;
  background: white;
  box-shadow: ${direction === 'up' ? upperBoxShadow : boxShadow};
  border-radius: ${direction === 'up' ? `${borderRadius} 0` : `0 ${borderRadius}`};
  bottom: -1px;
  left: 0;
  overflow: auto;
  transform: ${direction === 'up' ? `translateY(-52px)` : `translateY(100%)`};
  z-index: ${active ? zIndex.high : zIndex.base};
  :focus {
    outline: none;
  }
  ${!active && `pointer-events: none;`}
`;

export const DropdownInputStyles = {
  DropdownInput: {
    inputStyle: (displayValue) => inputStyle(inputTheme, displayValue),
    dropdownMenuStyle: (dropdownProps: DropdownProps) => dropdownMenuStyle(dropdownMenuTheme, dropdownProps),
    placeholderStyle: placeholderStyle(placeholderTheme),
  },
};

export const DropdownInputStylesOriginal: typeof DropdownInputStyles = {
  DropdownInput: {
    inputStyle: (displayValue) => inputStyle(inputThemeOriginal, displayValue),
    dropdownMenuStyle: (dropdownProps: DropdownProps) => dropdownMenuStyle(dropdownMenuThemeOriginal, dropdownProps),
    placeholderStyle: placeholderStyle(placeholderThemeOriginal),
  },
};
