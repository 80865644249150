import { ContextlessQueryObserverOptions, useSchemaInfiniteQuery } from '@frontend/react-query-helpers';
import { SchemaIO } from '@frontend/schema';
import { SchemaSMSDataV3Service, serviceName } from '../service';
import { InfiniteQueryEndpointName, ServiceInfiniteQueries } from '../types';

const DEFAULT_OPTIONS: ContextlessQueryObserverOptions = {
  refetchOnWindowFocus: false,
};

export type UseSMSDataV3InfiniteQueryArgs<
  EndpointName extends InfiniteQueryEndpointName,
  E = unknown,
  D = SchemaIO<ServiceInfiniteQueries[EndpointName]>['output'],
  PageParam = any
> = Omit<
  Parameters<typeof useSchemaInfiniteQuery<ServiceInfiniteQueries, EndpointName, E, D, PageParam>>[0],
  'service' | 'serviceName'
>;
/**
 * A wrapper around useSchemaInfiniteQuery that uses the SMSDataV3 service and serviceName.
 */
export const useSMSDataV3InfiniteQuery = <
  EndpointName extends InfiniteQueryEndpointName,
  E = unknown,
  D = SchemaIO<ServiceInfiniteQueries[EndpointName]>['output'],
  PageParam = any
>({
  options,
  ...args
}: UseSMSDataV3InfiniteQueryArgs<EndpointName, E, D, PageParam>) =>
  useSchemaInfiniteQuery<ServiceInfiniteQueries, EndpointName, E, D, PageParam>({
    service: SchemaSMSDataV3Service,
    serviceName,
    ...args,
    options: {
      ...DEFAULT_OPTIONS,
      ...options,
    },
  });

export type UseSMSDataV3InfiniteQueryEndpointArgs<
  EndpointName extends InfiniteQueryEndpointName,
  E = unknown,
  D = SchemaIO<ServiceInfiniteQueries[EndpointName]>['output'],
  PageParam = any,
  Request = SchemaIO<ServiceInfiniteQueries[EndpointName]>['input']
> = Omit<UseSMSDataV3InfiniteQueryArgs<EndpointName, E, D, PageParam>, 'endpointName' | 'request'> & {
  request: Request;
};
