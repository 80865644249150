import { css } from '@emotion/react';
import { useThemeValues } from '../../../hooks';
import React from 'react';
import { Text } from '../../text';
import { useModalContext } from '../provider';

export const ConfirmationText: React.FC<React.PropsWithChildren<{ children: React.ReactNode }>> = ({ children }) => {
  const { descriptionId } = useModalContext();
  const { spacing } = useThemeValues();
  return (
    <Text
      css={css`
        margin: ${spacing(2, 0, 5)};
      `}
      size='medium'
      id={descriptionId}
      textAlign='left'
    >
      {children}
    </Text>
  );
};
