import { createStoreWithSubscribe, createShallowStore } from '@frontend/store';
import {
  CustomizationFlag,
  CustomizationFlagsIdMap,
  CustomizationFlagKeys,
  CustomizationFlagsMap,
  CustomizationFlagState,
} from './types';

export interface CustomizationFlagStore {
  setFlags: (flags: CustomizationFlag[]) => void;
  hasActiveFlag: (name: CustomizationFlagKeys) => boolean;
  hasPromoteFlag: (name: CustomizationFlagKeys) => boolean;
  getFlag: (name: CustomizationFlagKeys) => CustomizationFlag | undefined;
  flags: CustomizationFlagsMap;
  flagsIdMap: CustomizationFlagsIdMap;
}

export const useCustomizationFlagStore = createStoreWithSubscribe<CustomizationFlagStore>(
  (set, get) => ({
    flags: {},
    flagsIdMap: {},
    setFlags: (flags) =>
      set((state: any) => {
        state.flags = flags.reduce<CustomizationFlagsMap>((acc: any, val: any) => {
          const key = val.name.toLowerCase().replace(/\s+/g, '') as CustomizationFlagKeys;
          acc[key] = val;
          return acc;
        }, {});

        state.flagsIdMap = flags.reduce<CustomizationFlagsIdMap>((acc: any, val: any) => {
          const key = val.id;
          acc[key] = val?.state === CustomizationFlagState.ACTIVE;
          return acc;
        }, {});
      }),
    getFlag: (name) => get().flags[name],
    hasActiveFlag: (name) => Boolean(get().flags[name]?.state === CustomizationFlagState.ACTIVE),
    hasPromoteFlag: (name) => Boolean(get().flags[name]?.state === CustomizationFlagState.PROMOTE),
  }),
  { name: 'CustomizationFlagStore' }
);

export const useCustomizationFlagShallowStore = createShallowStore(useCustomizationFlagStore);
