import { useMemo } from 'react';

export const formatCentsToCurrency = (amountInCents?: number, addFractionDigits = true) => {
  if (amountInCents === undefined) {
    return;
  }
  const amountInDollars = amountInCents / 100;
  const RemainingCents = amountInCents % 100;
  const shouldHaveDecimals = addFractionDigits || RemainingCents > 0;
  return amountInDollars.toLocaleString(undefined, {
    maximumFractionDigits: shouldHaveDecimals ? 2 : 0,
    minimumFractionDigits: shouldHaveDecimals ? 2 : 0,
    style: 'currency',
    currency: 'USD',
  });
};

/**
 * Takes cents as a number and returns a decimal represented string as the dollar amount
 * EX: 21 => "21.00"
 * @param amount Number
 */
export const centsToStringDollars = (amount?: number) => ((amount ?? 0) / 100).toFixed(2);

/**
 * Takes dollars as a string and returns cents as a number
 * @param amount String
 */
export const stringDollarsToCents = (amount?: string) => Math.round(+(amount ?? '0') * 100);

export const removeDecimals = (value?: string | number) => {
  const numericVal = Number(value);
  return !isNaN(numericVal) ? Math.round(numericVal * 100) : 0;
};

export const formatCurrencyValue = (value: number) => {
  return !isNaN(+value) ? `${formatCentsToCurrency(value, false)}` : ``;
};

export const useCurrencyValue = (inputVal?: string) =>
  useMemo(() => {
    let value = 0,
      string = '';
    if (inputVal) {
      inputVal = inputVal.replace(/\.0{2,}$/, '.0');
      const hasDotAtTheEnd = inputVal.match(/\.$/);
      const hasOneZeroAfterDecimal = inputVal.match(/\.0$/);
      const isEmptyDecimal = inputVal.match(/^\.0+/);
      const hasDecimalPoint = inputVal.match(/\./);
      value = removeDecimals(inputVal);
      if (!isEmptyDecimal)
        string = !hasDotAtTheEnd && hasDecimalPoint && !hasOneZeroAfterDecimal ? formatCurrencyValue(value) : inputVal;
    }
    return { value, string };
  }, [inputVal]);
