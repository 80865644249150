/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const AlignCenterIconSmall: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={16} {...props} viewBox='0 0 16 16'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.76501 0.991211C2.21273 0.991211 1.76501 1.43893 1.76501 1.99121C1.76501 2.5435 2.21273 2.99121 2.76501 2.99121H14.7794C15.3317 2.99121 15.7794 2.5435 15.7794 1.99121C15.7794 1.43893 15.3317 0.991211 14.7794 0.991211H2.76501ZM4.48132 8.96191C3.92904 8.96191 3.48132 9.40963 3.48132 9.96191C3.48132 10.5142 3.92904 10.9619 4.48132 10.9619H13.063C13.6153 10.9619 14.063 10.5142 14.063 9.96191C14.063 9.40963 13.6153 8.96191 13.063 8.96191H4.48132ZM4.76868 5.97656C4.76868 5.42428 5.21639 4.97656 5.76868 4.97656H11.7759C12.3282 4.97656 12.7759 5.42428 12.7759 5.97656C12.7759 6.52885 12.3282 6.97656 11.7759 6.97656H5.76868C5.21639 6.97656 4.76868 6.52885 4.76868 5.97656ZM11.4355 15.0086C11.9878 15.0087 12.4333 14.561 12.4307 14.0087C12.428 13.4564 11.9782 13.0086 11.4259 13.0085L6.10924 13.0078C5.55699 13.0077 5.11145 13.4553 5.11411 14.0077C5.11676 14.56 5.56661 15.0078 6.11886 15.0078L11.4355 15.0086Z'
    />
  </Icon>
);
