import { weaveTheme } from './weaveTheme';
import { css, Global } from '@emotion/react';
import DMSans from './font-assets/DMSans-Regular.ttf';
import DMSansItalic from './font-assets/DMSans-Italic.ttf';
import DMSansMedium from './font-assets/DMSans-Medium.ttf';
import DMSansBold from './font-assets/DMSans-Bold.ttf';

const styles = css`
  @font-face {
    font-family: 'DM Sans';
    src: url(${DMSans}) format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'DM Sans';
    src: url(${DMSansItalic}) format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: 'DM Sans';
    src: url(${DMSansMedium}) format('truetype');
    font-weight: 500;
  }

  @font-face {
    font-family: 'DM Sans';
    src: url(${DMSansBold}) format('truetype');
    font-weight: 700;
  }

  html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
  }

  html,
  body {
    margin: 0;
    padding: 0;
  }

  * {
    box-sizing: inherit;
    font-family: ${weaveTheme.font.family};
  }

  *::before,
  *::after {
    box-sizing: inherit;
  }

  *::selection {
    background: ${weaveTheme.colors.text.highlight};
    color: ${weaveTheme.colors.text.white};
  }
`;

export const UniversalBaseStyle = () => <Global styles={styles} />;
