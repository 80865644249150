import { isEmpty } from 'lodash-es';
import { Cell, Column, ColumnGroup, ColumnInstance, IdType, Row } from 'react-table';
import { reservedCells } from '../atoms/cells';
import { TableCell } from '../atoms/cells/table-cell.component';
import { fieldComponentsMap } from '../column-filters/atoms/filter-fields.components';
import { ColumnFilterTypes, TableStyleConfig, EmotionStyle } from '../table-types';
import { TableData } from '../table-data-type';

export const defaultAutoResetOptions = {
  autoResetResize: false,
  autoResetExpanded: false,
  autoResetGroupBy: false,
  autoResetSelectedRows: false,
  autoResetSortBy: false,
  autoResetFilters: false,
  autoResetRowState: false,
  autoResetGlobalFilter: false,
  autoResetHiddenColumns: false,
};

export const getFilterColumnsId = (tableInstanceId?: string) =>
  tableInstanceId ? `weave-table-${tableInstanceId}` : undefined;
export const getOrderColumnsId = (tableInstanceId?: string) =>
  tableInstanceId ? `order-weave-table-${tableInstanceId}` : undefined;
export const getResizeColumnsId = (tableInstanceId?: string) =>
  tableInstanceId ? `resize-weave-table-${tableInstanceId}` : undefined;

export const findMaxLeftShift = <T extends TableData>(colConfig: Column<T>[]): number | undefined => {
  const leftShiftValues = colConfig?.reduce<number[]>((acc, obj) => {
    const leftShift = obj?.mobileLayoutConfig?.leftShift;
    if (leftShift !== undefined) {
      acc.push(leftShift);
    }
    return acc;
  }, []);

  return leftShiftValues?.length > 0 ? Math.max(...leftShiftValues) : undefined;
};

const removeSpacesAndLowercase = (input: string | undefined): string => {
  if (!input) {
    return '';
  }

  return input.replace(/\s/g, '').toLowerCase();
};

const trackingIds = {
  globalSearch: 'txt-tableglobalsearch',
  tableFilters: 'icn-tablefilters',
  rowActionMenu: 'icn-tablerowactionmenu',
  tableActionMenu: 'icn-tableactionmenu',
  selectAll: 'chb-tableselectall',
  filterColumns: 'icn-tablefiltercolumns',
  columnResize: 'ctm-tablecolumnresize',
  dynamicRowActionItem: (label: string) => `btn-tablerowaction-${label}`,
  dynamicTableActionItem: (label: string) => `btn-tableactionitem-${label}`,
  clientFilterActions: (label: string) => `btn-tableclientfilters-${label}`,
} as const;

type FunctionType = (arg: string) => string;
type TrackingIds = typeof trackingIds;
type TableTrackingIds = { -readonly [K in keyof TrackingIds]: TrackingIds[K] | undefined };
export type TableTrackingId = Readonly<TableTrackingIds>;

export const tableTrackingIdGenerator = (globalId?: string) => {
  const delimiter = globalId?.endsWith('-') ? '' : '-';

  const trackIds = Object.keys(trackingIds).reduce((acc, key) => {
    const trackingKey = key;
    if (typeof trackingIds[trackingKey] === 'function') {
      acc[trackingKey] = (arg: string) =>
        globalId
          ? `${globalId}${delimiter}${(trackingIds[trackingKey] as FunctionType)(removeSpacesAndLowercase(arg))}`
          : undefined;
    } else {
      acc[trackingKey] = globalId ? `${globalId}${delimiter}${trackingIds[trackingKey]}` : undefined;
    }
    return acc;
  }, {} as TableTrackingIds);

  return trackIds as TableTrackingId;
};

export const getTotalColumnCount = (columns) => {
  return columns.reduce((acc, column) => {
    if (column.columns) return acc + getTotalColumnCount(column.columns);
    return acc + 1;
  }, 0);
};

export const persistUserPreference = <T extends TableData>(
  id?: string,
  values?: IdType<T>[] | Record<string, IdType<T>>
) => {
  if (!id) return;

  try {
    window.localStorage.setItem(id, JSON.stringify(values));
  } catch (e) {
    console.error(`Saving this preference was unsuccessful due to an error `, e);
  }
};

export const isReservedCellId = (id: string) => {
  return Object.values(reservedCells).some((reservedCell) => reservedCell === id);
};

const defaultFilterType: Record<keyof typeof fieldComponentsMap, ColumnFilterTypes> = {
  checkbox: 'exact',
  multiselect: 'multiSelect',
  selectlist: 'multiSelect',
  optionswitch: 'text',
  dropdown: 'multiSelect',
  switch: 'exact',
  text: 'text',
  radio: 'text',
  datePicker: 'date',
  dateRange: 'dateRange',
  date: 'date',
  email: 'text',
  money: 'text',
  phone: 'text',
  postalCode: 'text',
  time: 'time',
  timeRange: 'timeRange',
  checklist: 'multiSelect',
  number: 'equals',
};

const getTheRightFilter = (filterConfig) => {
  if (!filterConfig) return;

  if (filterConfig.filter) return filterConfig.filter;
  return defaultFilterType[filterConfig.type];
};

export const formatColConfig = <T extends TableData, C>(colConfig: Column<T>[], styleConfig?: TableStyleConfig<T, C>) =>
  colConfig
    .map((column) => {
      const { columns } = column as ColumnGroup<T>;

      if (columns) {
        return {
          ...column,
          columns: formatColConfig<T, C>(columns, styleConfig),
          styleConfig,
          isSuperheader: true,
        };
      }

      return {
        ...column,
        Cell: TableCell,
        styleConfig,
        sortDescFirst: true,
        disableFilters: true,
        filter: getTheRightFilter(column?.filterConfig) || undefined,
      };
    })
    .filter((column) => !column.omit);

export const getCellStyles = <T extends TableData>({
  cellValue,
  column,
  columnId = '',
  columnStyles,
  isHeaderStyle = false,
  rowObject,
  isMobile,
}: {
  cellValue?: Cell<T>['value'];
  column: ColumnInstance<T>;
  columnId: Column['id'];
  columnStyles: TableStyleConfig<T, string>['columns'];
  isHeaderStyle?: boolean;
  rowObject?: Row<T>;
  isMobile?: boolean;
}) => {
  if (columnStyles && !isEmpty(columnStyles)) {
    const stylerKey = isHeaderStyle ? 'headerStyler' : 'cellStyler';

    const colStyleConfig = columnStyles.find(({ id }) => id === columnId);
    const allStyleConfig = columnStyles.find(({ id }) => id === '*');

    if (!colStyleConfig?.[stylerKey] && !allStyleConfig) return undefined;

    let allColStyles: EmotionStyle;
    let colStyles: EmotionStyle;

    if (isHeaderStyle) {
      const headerStyler = colStyleConfig?.headerStyler;
      const allHeaderStyler = allStyleConfig?.headerStyler;

      colStyles = typeof headerStyler === 'function' ? headerStyler(cellValue, column) : headerStyler;
      allColStyles = typeof allHeaderStyler === 'function' ? allHeaderStyler(cellValue, column) : allHeaderStyler;
    }

    if (!isHeaderStyle && rowObject) {
      const cellStyler = colStyleConfig?.cellStyler;
      const allCellStyler = allStyleConfig?.cellStyler;

      colStyles = typeof cellStyler === 'function' ? cellStyler(cellValue, column, rowObject, isMobile) : cellStyler;
      allColStyles =
        typeof allCellStyler === 'function' ? allCellStyler(cellValue, column, rowObject, isMobile) : allCellStyler;
    }

    return [allColStyles, colStyles];
  }

  return undefined;
};

export const resetToFirstPage = ({ gotoPage }) => {
  gotoPage(0);
};

export const resetFilters = (tableInstance) => {
  const { setGlobalFilter, setAllFilters } = tableInstance;

  setGlobalFilter('');
  setAllFilters([]);

  resetToFirstPage(tableInstance);
};

export const getGlobalFilterFn = (colConfig) => (rows, _, globalFilterValue) => {
  if (isEmpty(globalFilterValue)) return rows;

  const searchTerm = globalFilterValue.trim().toLowerCase();

  const filteredRows = rows.filter((row) => {
    const { values: colToCellValueMap } = row;

    const doesRowHaveSearchTerm = Boolean(
      colConfig.find((col) => {
        const cellValue = colToCellValueMap[col.id];
        const cellText = cellValue;

        const lowerCaseCellText = cellText?.toString().toLowerCase();
        return lowerCaseCellText?.includes(searchTerm);
      })
    );

    return doesRowHaveSearchTerm;
  });

  return filteredRows;
};
