import { css } from '@emotion/react';
import { AccordionVariant } from './accordion.types';
import {
  defaultAccordionHeaderTheme,
  originalDefaultAccordionHeaderTheme,
  DefaultAccordionHeaderStyleValues,
  accordionItemTheme,
  originalAccordionItemTheme,
  AccordionItemStyleValues,
  accordionHeaderTheme,
  originalAccordionHeaderTheme,
  AccordionHeaderStyleValues,
  ChevronContainerStyleValues,
  chevronContainerTheme,
  originalChevronContainerTheme,
  AccordionBodyStyleValues,
  accordionBodyTheme,
  originalAccordionBodyTheme,
} from './common-theme';
import { theme } from '@frontend/theme';

type DefaultAccordionHeaderStyleProps = {
  defaultHeader?: 'title' | 'subtitle';
  hasSubtitle?: boolean;
};

const defaultAccordionHeaderStyle = (
  { spacing, fontSize, titleFontWeight, subtitleColor }: DefaultAccordionHeaderStyleValues,
  { defaultHeader, hasSubtitle }: DefaultAccordionHeaderStyleProps
) => {
  if (defaultHeader === 'title') {
    return css`
      font-size: ${fontSize(18)};
      font-weight: ${titleFontWeight};
      margin-bottom: ${hasSubtitle ? spacing(0.5) : 0};
    `;
  }

  if (defaultHeader === 'subtitle') {
    return css`
      margin: 0;
      max-width: 500px;
      font-size: ${fontSize(14)};
      color: ${subtitleColor};
    `;
  }
  return;
};

const getLocationBackgroundColor = (hasMultiLocations?: boolean) =>
  hasMultiLocations ? theme.colors.warning5 : theme.colors.primary20;
const getLocationFontColor = (hasMultiLocations?: boolean) =>
  hasMultiLocations ? theme.colors.warning80 : theme.colors.primary70;

type AccordionHeaderStyleProps = {
  chevronPosition?: 'left' | 'right';
  isActive?: boolean;
  variant?: AccordionVariant;
  isInteractive?: boolean;
  isDisabled?: boolean;
  hasMultiLocations?: boolean;
  showBorderBottomAlways?: boolean;
};

const accordionHeaderStyle = (
  {
    focusBorderColor,
    borderColor,
    fontColor,
    filledVariantBackgroundColor,
    borderRadius,
    zIndex,
  }: AccordionHeaderStyleValues,
  {
    chevronPosition,
    isActive,
    variant,
    isInteractive,
    isDisabled,
    hasMultiLocations,
    showBorderBottomAlways,
  }: AccordionHeaderStyleProps
) => {
  const radius = variant === 'hybrid' ? `${borderRadius} ${borderRadius} 0 0` : borderRadius;
  let backgroundColor;
  let borderBottom = `1px solid ${isActive || showBorderBottomAlways ? borderColor : 'transparent'}`;
  let transition = 'border 0.3s ease';
  let border = 'none';

  switch (variant) {
    case 'hybrid':
      backgroundColor = isActive ? 'white' : filledVariantBackgroundColor;
      transition = 'background 0.3s ease';
      border = isActive ? `1px solid ${borderColor}` : `1px solid ${filledVariantBackgroundColor}`;
      borderBottom = 'none';
      break;
    case 'filled':
      backgroundColor = filledVariantBackgroundColor;
      break;
    case 'location':
      backgroundColor = isActive ? getLocationBackgroundColor(hasMultiLocations) : 'white';
      transition = 'background 0.3s ease';
      borderBottom = 'none';
      break;
    default:
      backgroundColor = 'white';
  }

  return [
    css`
      display: flex;
      position: relative;
      flex-direction: ${chevronPosition === 'right' ? 'row-reverse' : 'row'};
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: space-between;
      border: ${border};
      border-bottom: ${borderBottom};
      background-color: ${backgroundColor};
      box-sizing: border-box;
      transition: ${transition};
    `,
    variant === 'hybrid' &&
      css`
        border-radius: ${radius};
      `,
    variant === 'location' &&
      isActive &&
      css`
        color: ${getLocationFontColor(hasMultiLocations)};
        svg {
          color: ${getLocationFontColor(hasMultiLocations)};
        }
      `,
    isDisabled &&
      css`
        cursor: unset;
        span {
          color: ${fontColor};
        }
      `,
    !isInteractive &&
      css`
        ::before {
          content: '';
          position: absolute;
          pointer-events: none;
          top: 0px;
          left: 0px;
          border: 2px solid ${focusBorderColor};
          border-radius: ${borderRadius};
          width: 100%;
          height: 100%;
          z-index: ${zIndex};
          opacity: 0;
          transition: 0.2s ease;
        }
        :focus-visible {
          outline: none;
          ::before {
            opacity: 1;
          }
        }
      `,
  ];
};

type AccordionItemStyleProps = { variant?: AccordionVariant; distance?: number; showBoxShadow?: boolean };

const accordionItemStyle = (
  { spacing, boxShadow, borderColor, borderRadius }: AccordionItemStyleValues,
  { variant, distance, showBoxShadow }: AccordionItemStyleProps
) => {
  const defaultStyles = css`
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  `;

  switch (variant) {
    case 'location':
    case 'hybrid': {
      return [
        defaultStyles,
        css`
          margin-bottom: ${spacing(distance ?? 2)};
          border-radius: ${borderRadius};
        `,
        showBoxShadow &&
          css`
            box-shadow: ${boxShadow};
          `,
      ];
    }

    case 'bordered': {
      return [
        defaultStyles,
        css`
          border: 1px solid ${borderColor};
          :first-child {
            border-radius: ${spacing(1, 1, 0, 0)};
            border-bottom: none;
          }
          :last-child {
            border-radius: ${spacing(0, 0, 1, 1)};
            border-top: none;
          }
        `,
      ];
    }

    case 'blank':
    case 'filled': {
      return css`
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        border-bottom: 1px solid ${borderColor};

        :last-child {
          border-bottom: none;
        }
      `;
    }

    default: {
      return [
        defaultStyles,
        showBoxShadow === false
          ? null
          : css`
              box-shadow: ${boxShadow};
            `,
        css`
          margin-bottom: ${spacing(distance ?? 1.5)};
          border-radius: ${spacing(1)};
        `,
      ];
    }
  }
};

type AccordionBodyStyleProps = Pick<AccordionItemStyleProps, 'variant'>;

const accordionBodyStyle = (
  { borderColor, borderRadius }: AccordionBodyStyleValues,
  { variant }: AccordionBodyStyleProps
) => {
  switch (variant) {
    case 'hybrid':
      return css`
        border-radius: 0 0 ${borderRadius} ${borderRadius};
        border: 1px solid ${borderColor};
        border-top: none;
      `;
    case 'location': {
      return css`
        background: white;
      `;
    }
    default:
      return;
  }
};

type ChevronContainerStyleProps = { disabled?: boolean };

export const chevronContainerStyle = (
  { spacing, disabledIconColor }: ChevronContainerStyleValues,
  { disabled }: ChevronContainerStyleProps
) => css`
  height: ${spacing(5)};
  padding: ${spacing(1)};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  ${!!disabled &&
  `svg {
    color: ${disabledIconColor};
  }`}
`;

export const AccordionStyles = {
  Accordion: {
    defaultAccordionHeader: (defaultAccordionHeaderProps: DefaultAccordionHeaderStyleProps) =>
      defaultAccordionHeaderStyle(defaultAccordionHeaderTheme, defaultAccordionHeaderProps),
    accordionHeader: (accordionHeaderProps: AccordionHeaderStyleProps) =>
      accordionHeaderStyle(accordionHeaderTheme, accordionHeaderProps),
    accordionBody: (accordionBodyProps: AccordionBodyStyleProps) =>
      accordionBodyStyle(accordionBodyTheme, accordionBodyProps),
    accordionItem: (accordionItemProps: AccordionItemStyleProps) =>
      accordionItemStyle(accordionItemTheme, accordionItemProps),
    chevronContainer: (chevronContainerProps: ChevronContainerStyleProps) =>
      chevronContainerStyle(chevronContainerTheme, chevronContainerProps),
  },
};

export const AccordionStylesOriginal: typeof AccordionStyles = {
  Accordion: {
    defaultAccordionHeader: (defaultAccordionHeaderProps: DefaultAccordionHeaderStyleProps) =>
      defaultAccordionHeaderStyle(originalDefaultAccordionHeaderTheme, defaultAccordionHeaderProps),
    accordionHeader: (accordionHeaderProps: AccordionHeaderStyleProps) =>
      accordionHeaderStyle(originalAccordionHeaderTheme, accordionHeaderProps),
    accordionBody: (accordionBodyProps: AccordionBodyStyleProps) =>
      accordionBodyStyle(originalAccordionBodyTheme, accordionBodyProps),
    accordionItem: (accordionItemProps: AccordionItemStyleProps) =>
      accordionItemStyle(originalAccordionItemTheme, accordionItemProps),
    chevronContainer: (chevronContainerProps: ChevronContainerStyleProps) =>
      chevronContainerStyle(originalChevronContainerTheme, chevronContainerProps),
  },
};
