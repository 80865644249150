import { PropsWithChildren } from 'react';
import { css } from '@emotion/react';
import { AnimatePresence, motion } from 'framer-motion';

interface Props {
  isActive: boolean;
}

export const AnimatedExpandable = ({ children, isActive }: PropsWithChildren<Props>) => {
  return (
    <AnimatePresence initial={false}>
      {isActive && (
        <motion.article
          className='expandable__row__container'
          style={{ display: 'flex' }}
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: 'auto', opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          transition={{ duration: 0.3, ease: 'easeInOut' }}
        >
          <motion.section
            className='expandable__row__sticky'
            css={css`
              display: flex;
              position: sticky;
              left: 0;
            `}
          >
            {children}
          </motion.section>
        </motion.article>
      )}
    </AnimatePresence>
  );
};
