import { SlidePanelStore, useSlidePanelShallowStore } from '@frontend/slide-panel';
import { CustomContactFormContext } from '../components/custom-contact-form/types';

type SetShowArgs =
  | {
      show: true;
      context: CustomContactFormContext;
    }
  | {
      show: false;
      context?: CustomContactFormContext;
    };

const defaultKeys: (keyof SlidePanelStore)[] = ['context', 'show', 'setShow', 'panelType'];

export const useCustomContactFormSlidePanel = () => {
  const store = useSlidePanelShallowStore<CustomContactFormContext>(...defaultKeys);

  const setShow = ({ show, context }: SetShowArgs) => {
    store.setShow?.(show, 'customContactForm', context);
  };

  if (store.panelType !== 'customContactForm') {
    return {
      setShow,
      show: false,
      context: undefined,
    };
  }
  return {
    setShow,
    show: store.show,
    context: store.context,
  };
};
