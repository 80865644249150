import { FontColorType } from '../../../../helpers';
import { Text } from '../../..';
import { useThemeValues } from '../../../../hooks';
import { css } from '@emotion/react';

type Props = {
  color?: FontColorType;
  TrailingIcon?: React.ReactElement;
  children: React.ReactNode;
  isBold?: boolean;
};

export const ListRowTitle = ({ color = 'default', TrailingIcon, children, isBold = true, ...rest }: Props) => {
  const { spacing } = useThemeValues();

  return (
    <div
      css={css`
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline;
        white-space: nowrap;
        /* color for ellipsis should match color of text */
        color: ${color};
      `}
      className='content-column-title-container'
    >
      <Text
        size='large'
        weight={isBold ? 'bold' : 'inherit'}
        color={color}
        className='content-column-title'
        css={css`
          display: inline;
          line-height: 1;
          padding-right: ${spacing(1)};
        `}
        {...rest}
      >
        {children}
      </Text>
      {!!TrailingIcon && TrailingIcon}
    </div>
  );
};
