import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { ErrorBadgeIcon, InfoBadgeIcon } from '@frontend/design-system';

type AlertBannerVariants = 'error' | 'info';
type IconColors = 'error' | 'primary';

interface AlertBannerProps {
  children: ReactNode;
  variant?: AlertBannerVariants;
  icon?: AlertBannerVariants;
}

const colors = {
  border: { error: theme.colors.critical50, info: theme.colors.primary50 },
  background: { error: theme.colors.critical5, info: theme.colors.primary5 },
  icon: { error: ErrorBadgeIcon, info: InfoBadgeIcon },
  iconColor: { error: 'error', info: 'primary' } as Record<AlertBannerVariants, IconColors>,
};

export const AlertBanner = ({ children, variant = 'error', icon }: AlertBannerProps) => {
  icon = icon ?? variant;
  const Icon = colors.icon[icon];
  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: ${theme.spacing(1)};
        min-height: ${theme.spacing(5)};
        border: 1px solid ${colors.border[variant]};
        border-radius: ${theme.borderRadius.small};
        background: ${colors.background[variant]};
        width: max-content;
        max-width: ${theme.spacing(65)};
        padding: ${theme.spacing(1)};
        margin-bottom: ${theme.spacing(2)};
      `}
    >
      <Icon color={colors.iconColor[variant]} size={16} />
      {children}
    </div>
  );
};
