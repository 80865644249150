/**
 * This file uses the .stories. convention in the file name so that it will get ignored
 * during build. It actually only contains utilities used in the preview.js file for
 * the storybook config.
 */

import React, { ReactNode, useCallback, useContext, useLayoutEffect, useState } from 'react';
import { OptionSwitchField, useControlledField } from '../../components';
import { OriginalThemeProvider, ThemeProvider } from '../../theme-providers';

enum ThemeType {
  original = 'original',
  webApp = 'webApp',
}

type ThemeManagerSettings = {
  defaultTheme?: ThemeType;
  themesAvailable?: ThemeType[];
};

const ThemeManagerContext = React.createContext<((options: ThemeManagerSettings) => void) | undefined>(undefined);

export const useThemeManager = (options?: 'webAppOnly') => {
  const defaultTheme = options === 'webAppOnly' && ThemeType.webApp;
  const themesAvailable = options === 'webAppOnly' ? [ThemeType.webApp] : [ThemeType.original, ThemeType.webApp];
  const setter = useContext(ThemeManagerContext);

  if (!setter) {
    throw new Error('Cannot use the useThemeManager hook outside of a ThemeManager');
  }

  useLayoutEffect(() => {
    setter?.({
      themesAvailable,
      ...(defaultTheme ? { defaultTheme } : {}),
    });
  }, []); // need default theme?
};

const ThemeSwitcher = ({ onChange, value }: any) => {
  const formProps = useControlledField({
    type: 'optionswitch',
    onChange,
    value,
  });

  return (
    <OptionSwitchField name='Theme Switcher' {...formProps}>
      <OptionSwitchField.Option value={ThemeType.original}>Original Theme</OptionSwitchField.Option>
      <OptionSwitchField.Option value={ThemeType.webApp}>Web App Theme</OptionSwitchField.Option>
    </OptionSwitchField>
  );
};

export const ThemeManager = ({ children }: { children: ReactNode }) => {
  const [currentTheme, setCurrentTheme] = useState(ThemeType.original);

  const [settings, setSettings] = useState<Required<ThemeManagerSettings>>({
    defaultTheme: ThemeType.original,
    themesAvailable: [ThemeType.original],
  });

  const renderThemeSwitcher = () => (
    <>
      {settings.themesAvailable?.length > 1 && (
        <ThemeSwitcher
          value={currentTheme}
          onChange={(value: ThemeType.original | ThemeType.webApp) => setCurrentTheme(value)}
        />
      )}
    </>
  );

  const mergeSettings = useCallback(
    (options: ThemeManagerSettings = {}) => {
      if (options.defaultTheme) {
        setCurrentTheme(options.defaultTheme);
      }
      setSettings((curr) => {
        return {
          ...curr,
          ...options,
        };
      });
    },
    [setCurrentTheme]
  );

  return (
    <ThemeManagerContext.Provider value={mergeSettings}>
      {settings.themesAvailable?.includes(ThemeType.original) && currentTheme === ThemeType.original && (
        <OriginalThemeProvider includeEmotionTheme>
          {renderThemeSwitcher()}
          {children}
        </OriginalThemeProvider>
      )}
      {settings.themesAvailable?.includes(ThemeType.webApp) && currentTheme === ThemeType.webApp && (
        <ThemeProvider includeEmotionTheme>
          {renderThemeSwitcher()}
          {children}
        </ThemeProvider>
      )}
    </ThemeManagerContext.Provider>
  );
};

export default {};
