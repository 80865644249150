/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const InfoBadgeIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 Z M12,9.75 C11.3096441,9.75 10.75,10.3096441 10.75,11 L10.75,17.5 C10.75,18.1903559 11.3096441,18.75 12,18.75 C12.6903559,18.75 13.25,18.1903559 13.25,17.5 L13.25,11 C13.25,10.3096441 12.6903559,9.75 12,9.75 Z M12,5.25 C11.3096441,5.25 10.75,5.80964406 10.75,6.5 C10.75,7.19035594 11.3096441,7.75 12,7.75 C12.6903559,7.75 13.25,7.19035594 13.25,6.5 C13.25,5.80964406 12.6903559,5.25 12,5.25 Z'
    />
  </Icon>
);
