import { forwardRef } from 'react';
import type { HTMLAttributes, AnchorHTMLAttributes, ElementType, ReactNode } from 'react';
import { useStyles } from '../../use-styles';
import type { FontWeight, TextAlignment, TextSize } from '../text';

type TextLinkProps = HTMLAttributes<HTMLElement> &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'type'> & {
    children: ReactNode;
    className?: string;
    color?: 'primary' | 'error';
    disabled?: boolean;
    size?: TextSize;
    textAlign?: TextAlignment;
    to?: string;
    weight?: FontWeight;
    trackingId?: string;
    as?: ElementType;
  };

export const TextLink = forwardRef<HTMLButtonElement | HTMLAnchorElement, TextLinkProps>(
  (
    {
      className,
      color = 'primary',
      disabled,
      onClick,
      size = 'inherit',
      textAlign,
      weight = 'inherit',
      trackingId,
      as,
      ...props
    },
    ref
  ) => {
    const isButton = !props.to && !!onClick;
    const Component: ElementType = as ?? (isButton ? 'button' : 'a');
    const styles = useStyles('TextLink', {
      isButton,
      size,
      color,
      textAlign,
      weight,
      disabled,
    });
    return (
      <>
        <Component
          type={isButton ? 'button' : undefined}
          className={className}
          css={styles}
          href={props.to}
          onClick={onClick}
          disabled={disabled && isButton ? true : false}
          ref={ref as any}
          data-trackingid={trackingId}
          {...props}
        />
      </>
    );
  }
);
