import { WeaveThemeValues } from '../../component-theme/theme-types';
import { theme } from '@frontend/theme';
import { theme as originalTheme } from '@frontend/theme-original';

export type IndexRowItemStyleValues = {
  spacing: WeaveThemeValues['Spacing'];
  borderColor: WeaveThemeValues['Color'];
  backgroundColor: WeaveThemeValues['Color'];
  hoverColor: WeaveThemeValues['Color'];
  disabledColor: WeaveThemeValues['Color'];
  borderRadius: WeaveThemeValues['BorderRadius'];
  caretColor: WeaveThemeValues['Color'];
};

export const indexRowItemTheme: IndexRowItemStyleValues = {
  spacing: theme.spacing,
  borderColor: theme.colors.neutral20,
  backgroundColor: theme.colors.white,
  hoverColor: theme.colors.neutral5,
  disabledColor: theme.colors.neutral10,
  borderRadius: theme.borderRadius.small,
  caretColor: theme.colors.neutral70,
};

export const indexRowItemThemeOriginal: IndexRowItemStyleValues = {
  spacing: theme.spacing,
  borderColor: originalTheme.colors.gray200,
  backgroundColor: originalTheme.colors.white,
  hoverColor: originalTheme.colors.gray100,
  disabledColor: originalTheme.colors.gray200,
  borderRadius: originalTheme.borderRadius.small,
  caretColor: originalTheme.colors.gray500,
};
