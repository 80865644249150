import { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import type { Reader } from '@stripe/terminal-js';

interface TerminalPaymentsProviderProps {
  children: ReactNode;
}

type TerminalPaymentsContextState = {
  selectedReader: Reader | undefined;
  setSelectedReader: (Reader: Reader | undefined) => void;
};

const TerminalPaymentsContext = createContext<TerminalPaymentsContextState | undefined>(undefined);

/**
 * @deprecated This should not be in the API module, will be replaced by SelectedReaderProvider in new flow
 */
export const SelectedReaderProvider = ({ children }: TerminalPaymentsProviderProps) => {
  const [selectedReader, setSelectedReader] = useState<Reader | undefined>();
  const value = useMemo(() => ({ selectedReader, setSelectedReader }), [selectedReader]);

  return <TerminalPaymentsContext.Provider value={value}>{children}</TerminalPaymentsContext.Provider>;
};

/**
 * @deprecated This should not be in the API module, will be replaced by SelectedReaderProvider in new flow
 */
export const useTerminalPaymentsContext = () => {
  const terminalPaymentsContext = useContext(TerminalPaymentsContext);

  if (!terminalPaymentsContext) {
    throw new Error('useTerminalPaymentsContext must be used within a TerminalPaymentsProvider');
  }

  return terminalPaymentsContext;
};
