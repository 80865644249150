import {
  autoUpdate,
  flip,
  offset as offsetFunction,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  Placement,
  useInteractions,
  useRole,
  safePolygon,
  arrow,
  limitShift,
  useFloatingNodeId,
  useClick,
  UseFloatingReturn,
  FloatingContext,
  ReferenceType,
} from '@floating-ui/react';
import React, { useRef, useState } from 'react';

import { Tooltip } from './tooltip.component';
import { TooltipTheme } from './tooltip-theme';

export type UseTooltipProps = {
  buffer?: number;
  hoverDelay?: number;
  offset?: number;
  placement?: Placement;
  trigger?: 'hover' | 'click';
  initialOpen?: boolean;
  theme?: TooltipTheme;
};

export type UseTooltipResponse = {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  triggerProps: {
    ref: UseFloatingReturn['refs']['setReference'];
  };
  tooltipProps: {
    theme: TooltipTheme;
    arrowRef: React.RefObject<SVGSVGElement>;
    isOpen: boolean;
    ref: UseFloatingReturn['refs']['setFloating'];
    context: FloatingContext<ReferenceType>;
    nodeId: string;
    style: UseFloatingReturn['floatingStyles'];
    floatingRef: UseFloatingReturn['refs']['floating'];
  };
  Tooltip: typeof Tooltip;
  refs: UseFloatingReturn['refs'];
};

export function useTooltip({
  initialOpen = false,
  placement = 'top',
  trigger = 'hover',
  buffer = 1,
  offset = 8,
  hoverDelay = 300,
  theme = 'dark',
}: UseTooltipProps = {}): UseTooltipResponse {
  const [open, setOpen] = useState(initialOpen);
  const arrowRef = useRef<SVGSVGElement>(null);
  const nodeId = useFloatingNodeId();
  const isTrigger = trigger === 'click';

  const { refs, context, floatingStyles } = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offsetFunction(offset),
      flip({
        crossAxis: placement.includes('-'),
        fallbackAxisSideDirection: 'start',
        padding: 5,
      }),
      shift({
        padding: 4,
        limiter: limitShift({
          crossAxis: isTrigger,
          mainAxis: isTrigger,
          offset: 20,
        }),
      }),
      arrow({
        element: arrowRef,
        padding: 8,
      }),
    ],
  });

  const hover = useHover(context, {
    move: false,
    enabled: !isTrigger,
    handleClose: safePolygon({
      buffer: buffer,
    }),
    delay: {
      open: hoverDelay,
      close: 0,
    },
  });
  const focus = useFocus(context, {
    enabled: !isTrigger,
  });

  const click = useClick(context, {
    enabled: isTrigger,
  });

  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });

  const { getFloatingProps, getReferenceProps } = useInteractions([hover, focus, dismiss, role, click]);
  return React.useMemo(
    () => ({
      isOpen: open,
      setOpen,
      triggerProps: {
        ...(getReferenceProps({ ref: refs.setReference }) as { ref: UseFloatingReturn['refs']['setReference'] }),
      },
      tooltipProps: {
        isOpen: open,
        context,
        nodeId,
        ...(getFloatingProps({
          ref: refs.setFloating,
          style: floatingStyles,
        }) as {
          ref: UseFloatingReturn['refs']['setFloating'];
          style: UseFloatingReturn['floatingStyles'];
        }),
        theme,
        floatingRef: refs.floating,
        arrowRef,
      },
      Tooltip,
      refs,
    }),
    [open, setOpen, refs, context, floatingStyles, getFloatingProps, getReferenceProps, arrowRef]
  );
}
