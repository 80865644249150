import { HTMLAttributes, KeyboardEvent } from 'react';
import { useStyles } from '../../use-styles';
import { Text } from '../text';
import { FlyoutMenuAction } from './types';

type Props = FlyoutMenuAction & HTMLAttributes<HTMLLIElement>;

export const FlyoutMenuItem = ({ Icon, onClick, onKeyDown, label, trackingId, disabled = false }: Props) => {
  const liStyle = useStyles('FlyoutMenuItem', 'liStyle', { disabled });
  const iconStyle = useStyles('FlyoutMenuItem', 'iconStyle');

  return (
    <Text
      as='li'
      size='large'
      color='default'
      css={liStyle}
      onClick={onClick}
      onKeyDown={(event: KeyboardEvent) => onKeyDown!(event, disabled)}
      role='menuitem'
      tabIndex={-1}
      data-trackingid={trackingId}
    >
      {!!Icon && <Icon css={iconStyle} />}
      {label}
    </Text>
  );
};
