import { useMemo } from 'react';
import dayjs from 'dayjs';
import { http } from '@frontend/fetch';
import { useQuery } from '@frontend/react-query-helpers';
import { AppointmentTypesTypes } from '@frontend/api-appointment-types';
import { ScheduleRequestSource } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import { overrideFields, customFields } from './defaults';
import * as Types from './types';
import { DaysStatusList, explodeAvailableOpenings, formatOpenings, getStatusList } from './utils';
import isoWeek from 'dayjs/plugin/isoWeek';
import { scheduleBookingQueryKeys } from './query-keys';
import { CACHE_AND_STALE_TIME_FOR_APPOINTMENT_OPENINGS } from './defaults';
dayjs.extend(isoWeek);

class URLS {
  static base = 'schedule/api';
  static locations = {
    v2: `${this.base}/v2/locations`,
  };
  static customFields = {
    v2: `${this.base}/v2/customfields`,
  };
  static appointmentTypes = {
    v1: `${this.base}/v1/appointment-types`,
    v2: `${this.base}/v2/appointment-types`,
  };
  static providers = {
    v2: `${this.base}/v2/providers`,
  };
  static providerHours = {
    v2: `${this.base}/v2/providerhours`,
  };
  static officeHours = {
    v2: `${this.base}/v2/officehours`,
  };
  static requests = {
    v2: `${this.base}/v2/requests`,
  };
}

export const getLocation = async (locationId: string): Promise<Types.Location> => {
  return await http.publicGet<Types.Location>(
    URLS.locations.v2,
    locationId ? { headers: { 'Location-Id': locationId } } : undefined
  );
};

export const getApptTypes = async (locationId: string): Promise<Types.ApptType[]> => {
  const appointmentTypes = await http.publicGetData<Types.ApptType[]>(
    URLS.appointmentTypes.v2,
    locationId ? { headers: { 'Location-Id': locationId } } : undefined
  );
  return appointmentTypes?.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)) || [];
};

export const getProviders = async (locationId: string) => {
  const url = URLS.providers.v2 + `?locationId=${locationId}`;
  const { providers } = await http.publicGet<Types.ProviderResponse>(
    url,
    locationId ? { headers: { 'Location-Id': locationId } } : undefined
  );

  return (
    providers?.sort((a, b) => (a.publicDisplayName?.toLowerCase() < b.publicDisplayName?.toLowerCase() ? -1 : 1)) ||
    providers
  );
};

export const getSchedule = async (providerId: string[] | string, locationId?: string) => {
  const isAnyProvider = typeof providerId !== 'string';
  if (isAnyProvider) {
    return await http.publicGet(`${URLS.officeHours.v2}/${locationId}`);
  }
  return await http.publicGet(`${URLS.providerHours.v2}/${providerId}`);
};

export const getOfficeHours = async (locationId: string) => {
  return await http.publicGet<Types.OfficeHoursWeekSchedule>(`${URLS.officeHours.v2}/${locationId}`);
};

export const getApptOpenings = async (apptTypeId?: string, start?: string, locationId?: string) => {
  if (!apptTypeId || !start) {
    return [];
  }
  const startDate = dayjs(start || undefined).startOf('month');
  const endDate = startDate.endOf('month');
  const { openings = [] } = await http.publicGet<{ openings: AppointmentTypesTypes.Opening[] }>(
    `${URLS.appointmentTypes.v2}/${apptTypeId}/openings`,
    {
      params: {
        start: startDate.toISOString(),
        end: endDate.toISOString(),
        appointmentID: apptTypeId,
      },
      ...(locationId && { headers: { 'Location-Id': locationId } }),
    }
  );
  return openings;
};
export const getNewApptOpenings = async (
  apptTypeId?: string,
  start?: string,
  locationId?: string,
  providerId?: string,
  dateRange?: 'day' | 'isoWeek' | 'week' | 'month'
) => {
  if (!apptTypeId || !start) {
    return [];
  }
  const startDate = dayjs(start);
  const endDate = dayjs(start).endOf(dateRange || 'month');

  const { data = [] } = await http.publicGet<{ data: Types.OpeningBlock[] }>(
    `${URLS.appointmentTypes.v2}/${apptTypeId}/opening-times`,
    {
      params: {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        appointmentTypeId: apptTypeId,
        providerId: providerId === 'any' ? '' : providerId,
      },
      ...(locationId && { headers: { 'Location-Id': locationId } }),
    }
  );
  return data;
};

export const validateDetails = (patientInfo?: Types.PatientInfo): string[] => {
  const requiredFields: (keyof Types.PatientInfo)[] = ['firstName', 'lastName', 'phoneNumber', 'email'];
  if (!patientInfo) {
    return requiredFields;
  }

  const missingFields = requiredFields.reduce((fields: string[], key: keyof Types.PatientInfo) => {
    if (!patientInfo[key]) {
      return [...fields, key];
    }
    return fields;
  }, []);

  return missingFields;
};

const getPublicApiAuthHeader = ({ token = '' }) => ({
  headers: { Authorization: token ?? '' },
});
export const requestAppointment = async (
  locationId: string,
  request: Types.FormDetails,
  paymentTransactionId?: string,
  bookingSource?: ScheduleRequestSource
): Promise<{ status?: 'success' | 'error' | 'sent'; message?: string; e?: any }> => {
  const { patientInfo, apptType, provider, dateTimeRequest, sent } = request;
  if (!!sent) {
    return Promise.resolve({
      status: 'sent',
    });
  }
  if (!patientInfo || !apptType || !provider || !dateTimeRequest) {
    return Promise.resolve({
      status: 'error',
      message: 'Incomplete',
    });
  }
  const missingFields = validateDetails(patientInfo);
  if (missingFields.length) {
    return Promise.resolve({
      status: 'error',
      message: `Missing the following fields: ${missingFields.join(', ')}`,
    });
  }
  try {
    const {
      note = '',
      custom,
      birthDate,
      insurance,
      isNewUser,
      address,
      gender,
      phoneNumber,
      phone,
      ...scheduler
    } = patientInfo;
    await http.publicPost<any, Types.Request>(
      `${URLS.requests.v2}`,
      {
        locationId,
        dateTime: dayjs().toISOString(),
        duration: String(apptType?.durationMinutes),
        scheduler: {
          ...scheduler,
          phoneNumber: phone || phoneNumber,
          birthDate:
            birthDate || custom?.birthDate ? dayjs(String(birthDate || custom?.birthDate)).format('YYYY-MM-DD') : '',
          existingUser: !isNewUser,
          insuranceInfo: JSON.stringify({ ...custom, isNewUser }),
          gender: String(gender || custom?.gender || 'other'),
          address,
        },
        schedulee: null,
        appointmentType: String(apptType?.name),
        note,
        requestedOpenings: dateTimeRequest?.map((dateTime) => ({
          dateTime,
          assets: {
            providers: [provider.publicDisplayName || provider.resourceName || ''],
          },
        })),
        appointmentTypeId: apptType?.id ?? '',
        practitionerId: provider?.id ?? '',
        paymentTransactionId,
        scheduleRequestSource: bookingSource,
      },
      getPublicApiAuthHeader({ token: '' })
    );
    return {
      status: 'success',
    };
  } catch (e) {
    return Promise.resolve({
      status: 'error',
      message: 'incomplete',
      error: e,
    });
  }
};

function getCalendarSchedule(openings: Types.OpeningBlock[], viewDate?: string) {
  const stampedOpeningBlocks = {} as Types.StampedOpeningBlocks;
  openings.forEach((openingBlock) => {
    const date = dayjs(openingBlock.startTime).format('YYYY-MM-DD');
    // stampedOpeningBlocks[date] = [...stampedOpeningBlocks[date], openingBlock];
    stampedOpeningBlocks[date] = stampedOpeningBlocks[date] || [];
    stampedOpeningBlocks[date].push(openingBlock);
  });
  const start = dayjs(viewDate || undefined).startOf('month');
  const end = dayjs(viewDate || undefined).endOf('month');
  const closed: string[] = [];
  const opened: string[] = [];
  for (let day = 0; day <= end.diff(start, 'd'); day++) {
    const date = dayjs(start).add(day, 'd').format('YYYY-MM-DD');
    if (stampedOpeningBlocks[date]?.length) {
      opened.push(date);
    } else {
      closed.push(date);
    }
  }
  return {
    openings: stampedOpeningBlocks,
    opened,
    closed,
  };
}

export const useGetNewOpenings = (
  locationId?: string,
  viewDate?: string,
  provider?: Partial<Types.Provider>,
  appointmentTypeId?: string
): { openings: Types.StampedOpeningBlocks; closed: string[]; opened: string[] } => {
  const { data: openings = [] } = useQuery({
    queryKey: scheduleBookingQueryKeys.getAppointmentOpeningsKeys({
      providerId: provider?.id,
      apptTypeId: appointmentTypeId ?? '',
      weekInView: viewDate ?? '',
      locationId: locationId ?? '',
    }),
    queryFn: () => getNewApptOpenings(appointmentTypeId, viewDate, locationId, provider?.id),
    enabled: !!appointmentTypeId && !!viewDate && !!locationId,
    cacheTime: CACHE_AND_STALE_TIME_FOR_APPOINTMENT_OPENINGS, // 3 mins
    staleTime: CACHE_AND_STALE_TIME_FOR_APPOINTMENT_OPENINGS, // 3 mins
    retry: 1,
  });

  const toReturn = useMemo(() => {
    return getCalendarSchedule(openings, viewDate);
  }, [openings, viewDate]);

  return toReturn;
};
export const useGetOpenings = (
  locationId?: string,
  viewDate?: string,
  provider?: Partial<Types.Provider>,
  appointmentTypeId?: string,
  durationMinutes?: number,
  cadence?: number
): DaysStatusList & { openings: Types.StampedOpenings } => {
  const { data: openings } = useQuery({
    queryKey: ['appointmentTypes', locationId, appointmentTypeId, provider?.id, viewDate],
    queryFn: () => getApptOpenings(appointmentTypeId, viewDate, locationId),
    retry: 1,
  });
  const providerOpenings = useMemo(() => {
    const filtered =
      openings?.filter((o: AppointmentTypesTypes.Opening) => {
        return (
          provider?.id === 'any' ||
          !!o.assetMap?.providers?.find(({ id, name }) => provider?.id === id || name === provider?.publicDisplayName)
        );
      }) || [];

    return filtered;
  }, [openings]);

  const explodedOpenings = useMemo(() => {
    const exOpenings = explodeAvailableOpenings({
      openings: providerOpenings,
      durationMinutes: durationMinutes || 40,
      cadence: cadence || 60,
    });
    return formatOpenings(exOpenings);
  }, [providerOpenings]);

  const { start, end } = useMemo(() => {
    const start = dayjs(viewDate || undefined)
      .startOf('month')
      .format('MM/DD/YYYY');
    const end = dayjs(viewDate || undefined)
      .endOf('month')
      .format('MM/DD/YYYY');
    return {
      start,
      end,
    };
  }, [viewDate]);

  const statusList = useMemo(() => {
    return getStatusList({ openings: formatOpenings(providerOpenings), start, end });
  }, [start, end, providerOpenings]);

  return { ...statusList, openings: explodedOpenings };
};

export const getCustomFields = async (locationId: string): Promise<Types.CustomFieldsResponse> => {
  const response = await http.publicGet<Types.CustomFieldsResponseRaw>(`${URLS.customFields.v2}/${locationId}`);
  return {
    ...response,
    overrideFields: response.overrideFields ? JSON.parse(response.overrideFields) : overrideFields,
    customFields: response.customFields ? JSON.parse(response.customFields) : customFields,
  };
};

export const upsertCustomFields = (
  locationId: string,
  data: Types.CustomFieldsResponse
): Promise<Types.CustomFieldsResponseRaw> => {
  const payload: Types.CustomFieldsResponseRaw = {
    locationId,
    overrideFields: JSON.stringify(data.overrideFields),
    customFields: JSON.stringify(data.customFields),
  };

  if (data.locationId === locationId) {
    const response = http.put<Types.CustomFieldsResponseRaw, Types.CustomFieldsResponseRaw>(
      `${URLS.customFields.v2}/${locationId}`,
      payload
    );
    return response;
  }
  const response = http.post<Types.CustomFieldsResponseRaw, Types.CustomFieldsResponseRaw>(
    `${URLS.customFields.v2}`,
    payload
  );
  return response;
};

export const emailPost = async (data: Types.EmailRequestData) => {
  return await http.publicPost('/schedule/api/v2/booking/email-request', data);
};
