import { createStoreWithSubscribe, createShallowStore } from '@frontend/store';

export type NavigationAlertType = 'success' | 'error' | 'info' | 'warning';

export interface PaymentsStore {
  navigationAlertKey: string | null;
  alertType: NavigationAlertType | null;
  setNavigationAlertKey: (key: string | null, alertType?: NavigationAlertType) => void;
}

export const usePaymentsStore = createStoreWithSubscribe<PaymentsStore>(
  (set) => ({
    navigationAlertKey: null,
    alertType: null,
    setNavigationAlertKey: (key: string | null, alertType?: NavigationAlertType) =>
      set((prevState) => ({ ...prevState, navigationAlertKey: key, alertType: alertType ?? 'info' })),
  }),
  {
    name: 'PaymentsStore',
    trace: true,
  }
);

export const usePaymentsShallowStore = createShallowStore(usePaymentsStore);
