import { createContext, useContext, useEffect, useState } from 'react';
import { ErrorBoundary } from '@sentry/react';
import { ErrorComponent } from '../components/error.component';
import { getWeavePayRoutes } from '../utils/routes/routes';

type ContextValue = {
  routes: ReturnType<typeof getWeavePayRoutes>;
  refresh: () => void;
};

const RoutesContext = createContext<ContextValue>({
  routes: [],
  refresh: () => {},
});

type Props = {
  children: React.ReactNode;
};

export const RoutesProvider = ({ children }: Props) => {
  const [isReady, setIsReady] = useState(false);
  const [routes, setRoutes] = useState<ReturnType<typeof getWeavePayRoutes>>(() => getWeavePayRoutes());

  useEffect(() => {
    setRoutes(getWeavePayRoutes());
  }, []);

  useEffect(() => {
    if (!location.pathname) {
      setIsReady(true);
      return;
    }

    if (!routes) {
      return;
    }

    setIsReady(true);
  }, [location.pathname, routes]);

  const value: ContextValue = {
    routes,
    refresh: () => setRoutes(getWeavePayRoutes()),
  };
  return (
    <RoutesContext.Provider value={value}>
      <ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag('topic', 'routeError');
          scope.setLevel('fatal');
        }}
        fallback={() => <RouteError />}
      >
        {isReady && children}
      </ErrorBoundary>
    </RoutesContext.Provider>
  );
};

export const useRoutes = () => useContext(RoutesContext);

const RouteError = () => {
  return <ErrorComponent />;
};
