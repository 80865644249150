import { css } from '@emotion/react';
import { Divider } from '@frontend/divider';
import { useTranslation } from '@frontend/i18n';
import { useInvoicePerson } from '@frontend/payments-collection-flow';
import { useSelectedInvoice } from '@frontend/payments-invoice-controller';
import { theme } from '@frontend/theme';
import { Heading, Text, TextLink, WarningIndicatorSmall, useTooltip } from '@frontend/design-system';
import { useViewAttachment } from '../../../hooks';

const styles = {
  attachmentSection: css`
    margin-bottom: ${theme.spacing(3)};
  `,
  attachmentTitleRow: css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};
    margin-bottom: ${theme.spacing(2)};
  `,
  warningIndicator: css`
    position: relative;
  `,
  attachmentNameRow: css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: ${theme.spacing(2)};
  `,
  uploadAttachmentLink: css`
    margin-bottom: ${theme.spacing(2, 0)};
    cursor: pointer;
  `,
  divider: css`
    border-color: ${theme.colors.neutral20};
  `,
};

export const AttachmentSection = () => {
  const { t } = useTranslation('payments');
  const { invoice } = useSelectedInvoice();
  const person = useInvoicePerson(invoice);
  const { tooltipProps, triggerProps, Tooltip } = useTooltip({ placement: 'top', trigger: 'click' });
  const { viewAttachment } = useViewAttachment();

  const attachmentName = invoice?.attachments?.[0].filename ?? '';
  const attachmentLink = invoice?.links?.attachment;

  if (!invoice?.hasAttachment) return null;
  return (
    <section css={styles.attachmentSection}>
      <div css={styles.attachmentTitleRow}>
        <Heading level={3}>{t('Attachments')}</Heading>
        {!person?.Birthdate && (
          <div css={styles.warningIndicator} {...triggerProps}>
            <WarningIndicatorSmall />
            <Tooltip {...tooltipProps} color='white'>
              <Text size='medium' color='white'>
                {t(
                  'Attachment will be hidden from customer. Customer birthday is missing which is required for authentication.'
                )}
              </Text>
            </Tooltip>
          </div>
        )}
      </div>
      {invoice?.hasAttachment && attachmentLink && (
        <div css={styles.attachmentNameRow}>
          <Text>{attachmentName}</Text>
          <TextLink css={styles.uploadAttachmentLink} onClick={() => viewAttachment(attachmentLink)}>
            {t('View attachment')}
          </TextLink>
        </div>
      )}
      <Divider css={styles.divider} />
    </section>
  );
};
