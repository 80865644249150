import { RefObject, useCallback } from 'react';
import React from 'react';
import { type Column, type Row, type TableInstance } from 'react-table';
import { EmptyStates } from '../atoms';
import { TableData } from '../table-data-type';
import {
  CellPositionCord,
  TableRowActions,
  TableExpandableRender,
  EmptyStateConfig,
  CollapsedTableConfig,
} from '../table-types';
import { TableRow } from './row.component';

type TableBodyProps<T extends TableData> = {
  tableInstance: TableInstance<T>;
  isPaginated?: boolean;
  columns?: Column<T>[];
  isFocusable?: boolean;
  currentCellPositionRef: RefObject<CellPositionCord>;
  tableId: string;
  rowActions?: TableRowActions<T>;
  expandableRowComponent?: TableExpandableRender<T>;
  emptyStateConfig?: EmptyStateConfig<T>;
  tableClientWidth: number;
  collapsedTableConfig?: CollapsedTableConfig<T>;
  isTableCollapsed?: boolean;
  isColumnResizing: boolean;
};

const TableBodyInner = <T extends TableData>({
  tableInstance,
  isPaginated,
  isFocusable,
  currentCellPositionRef,
  tableId,
  rowActions,
  expandableRowComponent,
  emptyStateConfig,
  tableClientWidth,
  collapsedTableConfig,
  isTableCollapsed,
}: TableBodyProps<T>) => {
  const { getTableBodyProps, rows, prepareRow, page, isLoading, isSelectable, rowSelectionConfig, isRowDisabled } =
    tableInstance;
  const rowsToDisplay = !isTableCollapsed && isPaginated ? page : rows;
  const rowsMap = !isTableCollapsed ? rowsToDisplay : rowsToDisplay.slice(0, collapsedTableConfig?.rowsToShow ?? 3);

  const determineRowDisabled = useCallback(
    (row: Row<T>) => {
      if (isRowDisabled) {
        return typeof isRowDisabled === 'boolean' ? isRowDisabled : isRowDisabled(row.original, row);
      }
      if (isSelectable && rowSelectionConfig?.isRowDisabled) {
        return typeof rowSelectionConfig.isRowDisabled === 'boolean'
          ? rowSelectionConfig.isRowDisabled
          : rowSelectionConfig.isRowDisabled(row.original, row);
      }
      return false;
    },
    [isRowDisabled, isSelectable, rowSelectionConfig]
  );

  return (
    <>
      {rowsMap.length ? (
        <div {...getTableBodyProps()} className='table__body' role='rowgroup'>
          {rowsMap.map((row, idx) => {
            const rowWithAddedProps =
              isSelectable || isRowDisabled
                ? Object.assign(row, {
                    isDisabled: determineRowDisabled(row),
                  })
                : row;

            prepareRow(rowWithAddedProps);
            return (
              <TableRow
                key={`row-${row.id}`}
                row={row}
                rowIdx={idx}
                rowActions={rowActions}
                rowCountPerPage={rowsMap.length}
                isFocusable={isFocusable}
                tableId={tableId}
                currentCellPositionRef={currentCellPositionRef}
                isLoading={isLoading}
                expandableRowComponent={expandableRowComponent}
                tableClientWidth={tableClientWidth}
                tableInstance={tableInstance}
              />
            );
          })}
        </div>
      ) : (
        <EmptyStates tableInstance={tableInstance} emptyStateConfig={emptyStateConfig} />
      )}
    </>
  );
};

export const TableBody = React.memo(
  TableBodyInner,
  (_, nextProps) => nextProps.isColumnResizing
) as typeof TableBodyInner;
