/**
 * Replacement of polished lib
 * Taken from https://bit.cloud/styled-components/polished/color/rgba/~code
 */
import { parseToRgb } from './parse-to-rgb';
import { rgb } from './rgb';
import { PolishedError } from './errors';
import type { RgbaColor } from './color.types';

/**
 * Returns a string value for the color. The returned result is the smallest possible rgba or hex notation.
 *
 * Can also be used to fade a color by passing a hex value or named CSS color along with an alpha value.
 */
function rgba(
  firstValue: RgbaColor | number | string,
  secondValue?: number,
  thirdValue?: number,
  fourthValue?: number
): string {
  if (typeof firstValue === 'string' && typeof secondValue === 'number') {
    const rgbValue = parseToRgb(firstValue);
    return `rgba(${rgbValue.red},${rgbValue.green},${rgbValue.blue},${secondValue})`;
  } else if (
    typeof firstValue === 'number' &&
    typeof secondValue === 'number' &&
    typeof thirdValue === 'number' &&
    typeof fourthValue === 'number'
  ) {
    return fourthValue >= 1
      ? rgb(firstValue, secondValue, thirdValue)
      : `rgba(${firstValue},${secondValue},${thirdValue},${fourthValue})`;
  } else if (
    typeof firstValue === 'object' &&
    secondValue === undefined &&
    thirdValue === undefined &&
    fourthValue === undefined
  ) {
    return firstValue.alpha >= 1
      ? rgb(firstValue.red, firstValue.green, firstValue.blue)
      : `rgba(${firstValue.red},${firstValue.green},${firstValue.blue},${firstValue.alpha})`;
  }

  throw new PolishedError(7);
}

export { rgba };
