import { useEffect, useRef } from 'react';
import { initThirdPartyScriptReady } from './app-lifecycle-subjects';

export const useThirdPartyScripts = () => {
  const doWhenDocumentReady = (action: () => void) => {
    if (document.readyState === 'complete') {
      action();
    } else {
      window.addEventListener('load', () => {
        action();
      });
    }
  };

  useDidMount(() => {
    doWhenDocumentReady(initThirdPartyScriptReady.notify);
  });
};

function useDidMount(fn: () => void) {
  const once = useRef(true);

  useEffect(() => {
    if (once.current) {
      fn();
      once.current = false;
    }
  }, []);
}
