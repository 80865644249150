import { theme } from '@frontend/theme';
import { WeaveThemeValues } from '../../../component-theme';
import { theme as themeOriginal } from '@frontend/theme-original';

export type HelperTextModalActionsTheme = {
  color: WeaveThemeValues['Color'];
  spacing: WeaveThemeValues['Spacing'];
};

export const helperTextModalActionsTheme: HelperTextModalActionsTheme = {
  color: theme.colors.neutral50,
  spacing: theme.spacing,
};

export const helperTextModalActionThemesOriginal: HelperTextModalActionsTheme = {
  color: themeOriginal.colors.gray[300],
  spacing: themeOriginal.spacing,
};
