import { ReactNode, forwardRef } from 'react';
import { useStyles } from '../../../use-styles';
import { CommonFieldAtomProps } from '../atoms/types';

// TODO: add color theming prop so it can be used on non-white bgs
// need input from design on what those options and styles should be
interface FieldWrapperProps extends Omit<CommonFieldAtomProps, 'id'> {
  className?: string;
  mode?: 'light' | 'dark';
  children: ReactNode;
  hasPadding?: boolean;
}

export const FieldWrapper = forwardRef<HTMLDivElement, FieldWrapperProps>(
  ({ active, children, disabled, hasError, hasPadding, ...props }, ref) => {
    const styles = useStyles('FieldWrapper', { disabled, hasError, active, hasPadding });

    return (
      <div css={styles} {...props} ref={ref}>
        {children}
      </div>
    );
  }
);
