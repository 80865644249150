import type { FC } from 'react';
import { useStyles } from '../../../use-styles';
import { Label } from '../atoms/label.component';
import { FloatingLabelProps } from './types';

export const FloatingLabel: FC<React.PropsWithChildren<FloatingLabelProps>> = ({
  active,
  children,
  disabled,
  hasError,
  leftOffset = 0,
  ...rest
}) => {
  const styles = useStyles('FloatingLabel', { disabled, hasError, leftOffset, active });
  return (
    <Label css={styles} {...rest}>
      {children}
    </Label>
  );
};
