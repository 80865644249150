import { last, capitalize } from 'lodash-es';

export function extractAndFormatPathName(path: string): string {
  const lastPart = last(path.split('/').filter(Boolean)) || '';

  if (lastPart.includes(':')) return lastPart;

  if (!lastPart.includes('-')) return capitalize(lastPart);

  return lastPart.split('-').map(capitalize).join(' ');
}
