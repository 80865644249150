import { ElementType, MouseEventHandler, forwardRef } from 'react';
import { Chip, TagChipProps } from '../../chip';
import { useChatItemContext } from '../chat-item';
import { PolymorphicComponentPropWithoutRef, PolymorphicRef } from '../../../type-utils';

export const chatItemTagDisplayName = 'ChatItemTag' as const;

export const ChatItemTag = forwardRef(
  <
    C extends MouseEventHandler<HTMLButtonElement> | undefined,
    E extends ElementType = C extends undefined ? 'div' : 'button'
  >(
    props: PolymorphicComponentPropWithoutRef<E, TagChipProps<C>>,
    ref?: PolymorphicRef<E>
  ) => {
    const { hoverProps } = useChatItemContext();

    return <Chip.Tag {...props} {...hoverProps} ref={ref} />;
  }
);

ChatItemTag.displayName = chatItemTagDisplayName;
