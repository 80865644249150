/**
 *
 * @param name string
 * A function that accepts a name and returns initials
 */
export const getInitials = (name: string) => {
  const parts = name
    .trim()
    .replace(/[^a-z0-9 ]/gi, '')
    .split(' ');
  if (parts.length > 1) {
    return (parts[0].charAt(0) + parts[parts.length - 1].charAt(0)).toLocaleUpperCase();
  } else if (parts.length === 1) {
    return parts[0].charAt(0).toLocaleUpperCase();
  }
  return '';
};

export const getFirstLastInitials = (firstName: string, lastName: string) => {
  return `${firstName.charAt(0)}${lastName.charAt(0)}`.trim();
};
