import React from 'react';
import { Text, TextProps } from '../../text';
import { useIndexRowItemContext } from './index-row-item';
import { css } from '@emotion/react';

type IndexRowTitleProps = {
  children: React.ReactNode;
} & TextProps;

export const IndexRowTitle = ({ children, ...rest }: IndexRowTitleProps) => {
  const { disabled } = useIndexRowItemContext();

  return (
    <>
      {typeof children === 'string' ? (
        <Text
          size='large'
          css={[
            css`
              margin: 0;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            `,
            disabled &&
              css`
                opacity: 0.5;
              `,
          ]}
          {...rest}
        >
          {children}
        </Text>
      ) : (
        children
      )}
    </>
  );
};

IndexRowTitle.displayName = 'IndexRowTitle';
