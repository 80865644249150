import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../../component-theme/theme-types';

export type TemplateEditorThemeValues = {
  borderColor: WeaveThemeValues['Color'];
  borderRadius: WeaveThemeValues['BorderRadius'];
  spacing: WeaveThemeValues['Spacing'];
  fontSize: WeaveThemeValues['FontSize'];
  fontColor: WeaveThemeValues['Color'];
  focusColor: WeaveThemeValues['Color'];
  errorColor: WeaveThemeValues['Color'];
};

export const templateEditorTheme: TemplateEditorThemeValues = {
  borderColor: theme.colors.neutral30,
  borderRadius: theme.borderRadius.small,
  spacing: theme.spacing,
  fontSize: theme.fontSize,
  fontColor: theme.colors.neutral60,
  focusColor: theme.colors.primary50,
  errorColor: theme.colors.status.critical,
};

export const templateEditorThemeOriginal: TemplateEditorThemeValues = {
  borderColor: themeOriginal.colors.gray[300],
  borderRadius: themeOriginal.borderRadius.small,
  spacing: themeOriginal.spacing,
  fontSize: themeOriginal.fontSize,
  fontColor: themeOriginal.colors.gray500,
  focusColor: themeOriginal.colors.weaveBlue,
  errorColor: themeOriginal.colors.error,
};
