import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { capitalize } from 'lodash-es';
import { PaymentPlanConfirmationStatus } from '@frontend/api-payment-plans';
import { formatDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { CardBrand } from '@frontend/payments-card-brand';
import { getPaymentMethodNameParts } from '@frontend/payments-card-on-file';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { Chip, ChipVariants, Text } from '@frontend/design-system';
import { usePaymentPlanInfo } from '../../hooks';
import { formatCentsToCurrency, termOptions } from '../../utils';

const styles = {
  planInfoSection: css`
    & > div {
      margin-bottom: ${theme.spacing(3)};
    }
  `,
  noMargin: css`
    margin: 0;
  `,
  largeInfo: css`
    font-size: ${theme.font.size.h2};
    font-weight: ${theme.font.weight.bold};
  `,
  confirmationStatusContainer: css`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: ${theme.spacing(2, 2)};
  `,
  amountInfo: css`
    display: flex;
    gap: ${theme.spacing(4, 4)};
    flex-wrap: wrap;
  `,
  planDetailsTitle: css`
    font-size: ${theme.font.size.h3};
    font-weight: ${theme.font.weight.bold};
    margin-bottom: ${theme.spacing(2)};
  `,
  planDetails: css`
    display: grid;
    overflow-y: auto;
    grid-template-columns: repeat(1, max-content);
    grid-gap: ${theme.spacing(1, 3)};

    @media (min-width: ${breakpoints.small.min}px) {
      grid-template-columns: repeat(2, max-content);
    }
  `,
  planDetailsInfo: css`
    margin-bottom: ${theme.spacing(1)};

    @media (min-width: ${breakpoints.small.min}px) {
      margin-bottom: ${theme.spacing(0)};
    }
  `,
  cardBrand: css`
    font-size: ${theme.font.size.medium};
  `,
};

export const PlanDetails = () => {
  const { t } = useTranslation('payments');
  const { schedule, paymentSummary, paymentMethod, paymentPlan } = usePaymentPlanInfo();
  const planFrequency = schedule?.frequency
    ? termOptions.find((option) => schedule.frequency === option.value)?.displayValue
    : '';
  const remainingAmount = formatCentsToCurrency(paymentSummary?.remainingAmount);
  const originalBalance = formatCentsToCurrency(schedule?.amount);
  const personName = `${paymentPlan?.person.firstName} ${paymentPlan?.person.lastName}`;
  const { paymentMethodBrand, last4 } = getPaymentMethodNameParts(paymentMethod);

  return (
    <section css={styles.planInfoSection}>
      <LargeInfo
        title={t('Plan Holder')}
        info={
          <div css={styles.confirmationStatusContainer}>
            {personName}
            <ConfirmationStatusChip status={paymentPlan?.confirmationDetails.confirmationStatus} />
          </div>
        }
      />
      <div css={styles.amountInfo}>
        <LargeInfo title={t('Starting Balance')} info={remainingAmount} />
        <LargeInfo title={t('Payment Amount')} info={formatCentsToCurrency(schedule?.paymentAmount)} />
        {schedule?.nextPaymentAt && (
          <LargeInfo title={t('Payment Due')} info={formatDate(schedule?.nextPaymentAt ?? '', 'MMMM D, YYYY')} />
        )}
      </div>
      <div>
        <Text css={styles.planDetailsTitle}>{t('Payment Plan Details')}</Text>
        <div css={styles.planDetails}>
          <DetailRow title={t('Date of first payment')} info={formatDate(schedule?.startAt ?? '', 'MMMM D, YYYY')} />
          <DetailRow
            title={t('Date of final payment')}
            info={formatDate(schedule?.finalPaymentAt ?? '', 'MMMM D, YYYY')}
          />
          <DetailRow title={t('Original balance')} info={originalBalance} />
          <DetailRow title={t('Total of payments')} info={originalBalance} />
          <DetailRow title={t('Interest rate')} info='0% APR' />
          <DetailRow title={t('Payment schedule')} info={planFrequency} />
          <DetailRow title={t('Payment term')} info={(schedule?.paymentTerm ?? '').toString()} />
          <DetailRow
            title={t('Billing method')}
            info={<CardBrand brand={paymentMethodBrand} last4={last4} style={styles.cardBrand} />}
          />
        </div>
      </div>
    </section>
  );
};

const LargeInfo = ({ title, info }: { title: string; info: string | undefined | ReactNode }) => {
  return (
    <div>
      <Text color='subdued' size='medium' css={styles.noMargin}>
        {title}
      </Text>
      {typeof info === 'string' ? (
        <Text css={[styles.noMargin, styles.largeInfo]}>{info ?? ''}</Text>
      ) : (
        <div css={[styles.noMargin, styles.largeInfo]}>{info}</div>
      )}
    </div>
  );
};

const DetailRow = ({ title, info }: { title: string; info: string | undefined | ReactNode }) => {
  return (
    <>
      <Text color='subdued' size='medium' css={styles.noMargin}>
        {title}
      </Text>
      {typeof info === 'string' ? (
        <Text size='medium' css={[styles.noMargin, styles.planDetailsInfo]}>
          {info ?? ''}
        </Text>
      ) : (
        <div css={[styles.noMargin, styles.planDetailsInfo]}>{info}</div>
      )}
    </>
  );
};

const statusChipVariantMap: Partial<Record<PaymentPlanConfirmationStatus, ChipVariants>> = {
  [PaymentPlanConfirmationStatus.Pending]: 'disabled',
  [PaymentPlanConfirmationStatus.Accept]: 'success',
  [PaymentPlanConfirmationStatus.Deny]: 'critical',
};

const statusChipTextMap: Partial<Record<PaymentPlanConfirmationStatus, string>> = {
  [PaymentPlanConfirmationStatus.Pending]: 'Pending',
  [PaymentPlanConfirmationStatus.Accept]: 'Accepted',
  [PaymentPlanConfirmationStatus.Deny]: 'Denied',
};

const ConfirmationStatusChip = ({
  status = PaymentPlanConfirmationStatus.Pending,
}: {
  status: PaymentPlanConfirmationStatus | undefined;
}) => {
  return <>{status && <Chip variant={statusChipVariantMap[status]}>{capitalize(statusChipTextMap[status])}</Chip>}</>;
};
