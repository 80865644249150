/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const EmailIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M4.12846955,6.25 L10.4745745,10.4807366 C11.3982978,11.0965522 12.6017022,11.0965522 13.5254255,10.4807366 L19.8715305,6.25 L4.12846955,6.25 Z M20.75,8.6689797 L14.912176,12.5608623 C13.1487041,13.7365103 10.8512959,13.7365103 9.08782397,12.5608623 L3.25,8.6689797 L3.25,17 C3.25,17.4142136 3.58578644,17.75 4,17.75 L20,17.75 C20.4142136,17.75 20.75,17.4142136 20.75,17 L20.75,8.6689797 Z M20,20.25 L4,20.25 C2.20507456,20.25 0.75,18.7949254 0.75,17 L0.75,7 C0.75,5.20507456 2.20507456,3.75 4,3.75 L20,3.75 C21.7949254,3.75 23.25,5.20507456 23.25,7 L23.25,17 C23.25,18.7949254 21.7949254,20.25 20,20.25 Z'
    />
  </Icon>
);
