import { PropsWithChildren } from 'react';
import { css } from '@emotion/react';
import { AnimatePresence, motion, DragHandlers } from 'framer-motion';
import { useSlideCarousel } from '../provider';

type SlidePanelProps = {
  id: string;
};

export const SlidePanel = ({ id, children }: PropsWithChildren<SlidePanelProps>) => {
  const { slideIds, activeId, setActiveId, canDrag } = useSlideCarousel();
  const index = slideIds.indexOf(id);
  const activeIndex = slideIds.indexOf(activeId);
  const offset = index - activeIndex;

  const handleDragEnd: DragHandlers['onDragEnd'] = (_event, info) => {
    if (info.offset.x < -50 && activeIndex < slideIds.length - 1) {
      setActiveId(slideIds[activeIndex + 1]);
    } else if (info.offset.x > 50 && activeIndex > 0) {
      setActiveId(slideIds[activeIndex - 1]);
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        id={id}
        css={slideStyle}
        initial={{ x: `${offset * 100}%` }}
        animate={{ x: `${offset * 100}%` }}
        drag={canDrag ? 'x' : false}
        dragConstraints={{ left: 0, right: 0 }}
        onDragEnd={handleDragEnd}
        transition={{
          type: 'spring',
          stiffness: 200,
          damping: 20,
          mass: 0.75,
          bounce: 0.25,
          ease: [0.43, 0.13, 0.23, 0.96],
        }}
        style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          minWidth: '100%',
          left: 0,
          top: 0,
        }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

const slideStyle = css({});
