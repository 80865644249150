import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { invalidateDiscoverReaderQuery } from '@frontend/payments-collection-flow';
import { useMerchant } from '@frontend/payments-hooks';
import { RegisterTerminals } from '@frontend/payments-register-terminal';
import { theme } from '@frontend/theme';
import { PlusIcon, SecondaryButton, useModalControl } from '@frontend/design-system';
import { useCanDoAction } from '../../../../hooks';
import { TerminalAddressModal, TerminalAddressModalMode } from './terminal-address';

const styles = {
  plusIcon: css`
    margin-right: ${theme.spacing(1)};
    margin-left: ${theme.spacing(-1)};
  `,
};

export const TerminalSettingsAction = () => {
  const { t } = useTranslation('payments');
  const { isPaymentsBillingManager } = useCanDoAction();
  const { merchant, locationId } = useMerchant();
  const { modalProps, openModal, closeModal } = useModalControl();
  const {
    modalProps: addressModalProps,
    openModal: openAddressModal,
    closeModal: closeAddressModal,
  } = useModalControl();

  const { invalidateQuery } = invalidateDiscoverReaderQuery(locationId || '');

  const onRegisterTerminalClick = () => {
    if (merchant?.hasTerminalLocation) {
      openModal();
    } else {
      openAddressModal();
    }
  };

  const handleAddressModalClose = () => {
    closeAddressModal();
    openModal();
  };

  return (
    <>
      <SecondaryButton
        disabled={!isPaymentsBillingManager}
        onClick={onRegisterTerminalClick}
        size={'small'}
        css={{ width: 'max-content' }}
      >
        <PlusIcon css={styles.plusIcon} />
        {t('Register Terminal')}
      </SecondaryButton>
      <RegisterTerminals modalProps={modalProps} closeModal={closeModal} onTerminalRegistered={invalidateQuery} />
      <TerminalAddressModal
        modalProps={addressModalProps}
        onSubmit={handleAddressModalClose}
        mode={TerminalAddressModalMode.Add}
      />
    </>
  );
};
