const gray = {
  100: '#f9fbfe',
  200: '#f3f7fc',
  300: '#e2e7f1',
  400: '#c3c8d2',
  500: '#8d919b',
  600: '#31353d',
};

const palette = {
  weaveBlue: '#28caf4',
  hoverBlue: '#4adaff',
  pressedBlue: '#25BBE2',
  pizazz: '#FF9000',
  studio: '#864ba0',
  strawberry: '#ff31a2',
  gray,
  success: '#2bd53c',
  warning: '#ffb92e',
  error: '#ff6b59',
  errorPressed: '#FA1F05',
  errorHovered: '#FF4F39',
  disabled: gray[300],
  white: '#ffffff',
  highlight: '#6E9CD4',
} as const;

export const legacyColors = {
  ...palette,
  gray100: palette.gray[100],
  gray200: palette.gray[200],
  gray300: palette.gray[300],
  gray400: palette.gray[400],
  gray500: palette.gray[500],
  gray600: palette.gray[600],
} as const;
