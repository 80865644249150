import { Link } from '@tanstack/react-location';
import { useTranslation } from '@frontend/i18n';
import { Heading, TextLink } from '@frontend/design-system';
import { theme } from '@frontend/theme';

export function NotFoundPage() {
  const { t } = useTranslation();

  return (
    <main
      css={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }}
    >
      <Heading css={{ 'margin-bottom': theme.spacing(1) }}>{t('404 - Page Not Found')}</Heading>
      <TextLink as={Link} to={'/'}>
        {t('Go home')}
      </TextLink>
    </main>
  );
}
