import { css } from '@emotion/react';
import { BreadcrumbPathStyleValues, breadcrumbPathTheme, breadcrumbPathThemeOriginal } from './common-theme';

type BreadcrumbPathStyle = {
  isInteractive: boolean;
};

const breadcrumbPathStyle = (
  { clickableColor, currentPageColor }: BreadcrumbPathStyleValues,
  { isInteractive }: BreadcrumbPathStyle
) =>
  css`
    &:not(:last-of-type) {
      color: ${isInteractive ? clickableColor : currentPageColor};
    }

    &:last-of-type {
      color: ${currentPageColor};
    }
  `;

export const BreadcrumbPathStyles = {
  BreadcrumbPath: {
    breadcrumbPathStyle: (props: BreadcrumbPathStyle) => breadcrumbPathStyle(breadcrumbPathTheme, props),
  },
};

export const BreadcrumbPathStylesOriginal: typeof BreadcrumbPathStyles = {
  BreadcrumbPath: {
    breadcrumbPathStyle: (props: BreadcrumbPathStyle) => breadcrumbPathStyle(breadcrumbPathThemeOriginal, props),
  },
};
