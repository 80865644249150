import { forwardRef } from 'react';
import { createAutoCorrectedDatePipe } from 'text-mask-addons';
import { getMaskConfigProps } from './utils';
import type { InputProps } from './types';
import type { MaskConfig } from './mask-types';
import { conformToMask } from '../../../helpers/text-mask/conformToMask';
import MaskedInput from '../../../helpers/text-mask';

export const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy');
export const dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

export const DateInput = forwardRef<HTMLInputElement, InputProps<'date'> & MaskConfig>(
  ({ blackoutDates, maxDate, minDate, value = '', ...rest }, ref) => {
    const { maskConfigProps, nonConfigProps } = getMaskConfigProps(rest);

    return (
      <MaskedInput
        {...nonConfigProps}
        mask={dateMask}
        pipe={autoCorrectedDatePipe}
        value={
          !!value.trim() || value === ''
            ? conformToMask(value, dateMask, {
                ...maskConfigProps,
                pipe: autoCorrectedDatePipe,
              }).conformedValue
            : value
        }
        render={(textMaskRef, props) => (
          <input
            {...props}
            type='text'
            ref={(node) => {
              textMaskRef(node); // Keep this so the component can still function
              if (typeof ref === 'function') {
                ref(node);
              } else if (ref) {
                // @ts-ignore
                ref.current = node;
              }
            }}
          />
        )}
      />
    );
  }
);
