import { css, SerializedStyles } from '@emotion/react';
import { theme as themeOriginal } from '@frontend/theme-original';
import { styles } from '../../styles';
import {
  sortingCaretTheme,
  sortingCaretThemeOriginal,
  SortingCaretThemeValues,
  tableCellTheme,
  tableCellThemeOriginal,
  TableCellThemeValues,
  tableContainerTheme,
  tableContainerThemeOriginal,
  TableContainerThemeValues,
  tableHeaderTheme,
  tableHeaderThemeOriginal,
  TableHeaderThemeValues,
  tableFilterTheme,
  tableFilterThemeOriginal,
  TableFiltersThemeValues,
  FilterNotificationBadgeThemeValues,
  filterNotificationBadgeTheme,
  filterNotificationBadgeThemeOriginal,
  FilterColumnsThemeValues,
  filterColumnsTheme,
  filterColumnsThemeOriginal,
  TableMobileRowActionsThemeValues,
  mobileRowActionsTheme,
  mobileRowActionsThemeOriginal,
  TableRowCardThemeValues,
  tableRowCardTheme,
  tableRowCardThemeOriginal,
  HoverRowActionsThemeValues,
  hoverRowActionsTheme,
  hoverRowActionsThemeOriginal,
  ColumnResizerThemeValues,
  columnResizerTheme,
  columnResizerThemeOriginal,
  ReorderColumnWhileTapThemeValues,
  reorderColumnWhileTapTheme,
  reorderColumnWhileTapThemeOriginal,
  TableRowCardContainerThemeValues,
  tableRowCardContainerTheme,
  tableRowCardContainerThemeOriginal,
} from './common-theme';
import { FullHeightConfig } from './table-types';

type TableContainerStyleProps = {
  isHoverable: boolean;
  isFocusable: boolean;
  tableId: string;
  leftShadow: boolean;
  rightShadow: boolean;
  topShadow: boolean;
  tableStyle?: SerializedStyles;
  wrapperStyle?: SerializedStyles;
  tableScrollWidth: number;
  tableClientWidth: number;
  tableYPositioning: number;
  fullHeight?: boolean;
  fullHeightConfig?: FullHeightConfig;
  isMobile?: boolean;
  hasHoverableActions?: boolean;
  hasStickyCustomRow?: boolean;
};

export const tableContainerStyle = (
  {
    selectedRowBackgroundColor,
    hoverBackgroundColor,
    cellBorderColor,
    focusColor,
    borderRadius,
    tableBorderRadius,
    stickyBoxShadow,
    spacing,
    popoverZIndex,
    leftShadow: innerLeft,
    rightShadow: innerRight,
  }: TableContainerThemeValues,
  {
    isHoverable,
    isFocusable,
    tableStyle,
    tableId,
    wrapperStyle,
    leftShadow,
    rightShadow,
    topShadow,
    tableClientWidth,
    tableScrollWidth,
    tableYPositioning,
    fullHeight,
    fullHeightConfig,
    isMobile,
    hasHoverableActions,
    hasStickyCustomRow,
  }: TableContainerStyleProps
) => css`
  width: 100%;
  /* height: 100%; */
  display: flex;
  isolation: isolate;
  flex-direction: column;
  ${wrapperStyle};
  @media print {
    height: auto;
  }

  #${tableId}.table {
    border-spacing: 0;
    flex: 1;
    ${fullHeight &&
    !isMobile &&
    `max-height: ${
      fullHeightConfig?.maxHeight
        ? `${fullHeightConfig.maxHeight}px`
        : `calc(100vh - ${tableYPositioning}px - ${spacing(5)})`
    };
      min-height: ${
        fullHeightConfig?.minHeight
          ? `${fullHeightConfig.minHeight}px`
          : `max(calc(100vh - ${tableYPositioning}px - ${spacing(5)}),400px)`
      };
      `}

    @media print {
      max-height: fit-content;
      min-height: auto;
    }

    ${styles.smallScrollbar(themeOriginal)};
    ${tableStyle};
    ${isMobile && 'max-height: unset;'}
    .table__header.resizing {
      background: ${hoverBackgroundColor};
    }

    .table__header:last-of-type .table-header-resizer {
      display: none;
    }

    ${isFocusable === true &&
    `.table__header::after,.table__cell::after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      border: 2px solid ${focusColor};
      border-radius: inset ${borderRadius};
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: opacity 100ms ease-out;
    }`};

    .table__header,
    .table__cell {
      ${!isMobile &&
      css`
        border-bottom: 1px solid ${cellBorderColor};
      `};
    }

    ${!isMobile &&
    css`
      border: 1px solid ${cellBorderColor};
      border-radius: ${tableBorderRadius};
      .table-row-group:last-of-type .table__row .table__cell {
        border-bottom: 1px solid transparent;
      }
    `}

    ${isHoverable === true &&
    !isMobile &&
    `.table__row:hover:not(.disabled) {
      cursor:pointer;
      .cell__${tableId}.table__cell {
        background: ${hoverBackgroundColor};
      }

      & svg {
        visibility: visible;
      }
    }`};
    .table__row {
      .cell__${tableId}.table__cell.selected {
        background-color: ${selectedRowBackgroundColor};
      }

      &:hover,
      .cell__${tableId}.table__cell.expanded {
        ${!isMobile &&
        css`
          background: ${hoverBackgroundColor};
        `};
      }

      .cell__${tableId}.table__header.selected {
        background-color: ${selectedRowBackgroundColor};
      }

      &:hover,
      .cell__${tableId}.table__header.expanded {
        ${!isMobile &&
        css`
          background: ${hoverBackgroundColor};
        `};
      }
    }

    .custom__row__container {
      border-bottom: 1px solid ${cellBorderColor};
    }

    ${hasStickyCustomRow &&
    `.custom__row__sticky {
      width: ${tableClientWidth}px;
      @media print {
        width: 100%;
      }
    }`}

    .expandable__row__container,
    .empty__states__wrapper {
      width: ${tableScrollWidth}px;
    }

    ${!tableScrollWidth &&
    !tableClientWidth &&
    `.empty__states__wrapper{
      display: none;
      animation: intro 0.3s ease;
    }

    `}

    .table__hover__actions {
      display: flex;
      position: absolute;
      left: 0;
      bottom: ${spacing(1)};
      width: ${tableScrollWidth}px;
      z-index: ${popoverZIndex};
      pointer-events: none;
    }

    ${tableScrollWidth &&
    tableClientWidth &&
    `.empty__states__wrapper{
        animation: intro 0.3s ease;
      }`}

    @keyframes intro {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    .expandable__row__sticky {
      width: ${tableClientWidth}px;
      border-bottom: 1px solid ${cellBorderColor};
    }

    .empty__state__container {
      width: ${tableClientWidth}px;
    }

    ${isFocusable === true &&
    `.table__header:focus-visible,.table__cell:focus-visible {
      outline: none;
      ::after {
        opacity: 1;
      }
    }`};

    &.sticky {
      overflow: auto;
      @media print {
        overflow: visible;
      }
      .header,
      .footer {
        position: sticky;
        ${!hasHoverableActions && `z-index: 1;`}
      }

      .header {
        top: 0;
        display: flex;
      }

      .table__body {
        position: relative;
        z-index: 0;
        display: grid;
      }

      .table__header {
        position: sticky !important;
      }

      .thead {
        transition: 0.3s ease;
      }

      ${topShadow &&
      `& .thead.headers__${tableId} {
        box-shadow: ${themeOriginal.shadows.header};
      }`}

      [data-sticky-id] {
        position: sticky;
        box-shadow: ${stickyBoxShadow};
      }

      .cell__${tableId}[data-sticky-last-left-td]::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: -1px;
        width: ${spacing(4)};
        transform: translate(100%);
        transition: opacity 0.3s;
        content: '';
        pointer-events: none;
        box-shadow: ${innerLeft};
        opacity: 0;
      }

      ${leftShadow &&
      `.cell__${tableId}[data-sticky-last-left-td]::before {
        opacity: 1;
      }`}

      .cell__${tableId}[data-sticky-first-right-td]::before {
        position: absolute;
        top: 0;
        left: -${spacing(8)};
        bottom: -1px;
        width: ${spacing(4)};
        transform: translate(100%);
        transition: opacity 0.3s;
        content: '';
        pointer-events: none;
        box-shadow: ${innerRight};
        opacity: 0;
      }

      ${rightShadow &&
      `.cell__${tableId}[data-sticky-first-right-td]::before {
        opacity: 1;
      }`}
    }
  }
`;

type TableCellStylesProps = {
  isMobile?: boolean;
  isDisabled?: boolean;
};

const tableCellStyles = (
  { cellFontColor, disabledCellFontColor, fontSize, fontWeight, spacing, backgroundColor }: TableCellThemeValues,
  { isMobile, isDisabled }: TableCellStylesProps
) => {
  return [
    css`
      position: relative;
      display: flex;
      align-items: center;
      color: ${isDisabled ? disabledCellFontColor : cellFontColor};
      font-size: ${fontSize(14)};
      font-weight: ${fontWeight};
      background-color: ${backgroundColor};
      outline: none;
    `,
    !isMobile &&
      css`
        padding: ${spacing(1, 2)};
        min-height: 52px;
      `,
  ];
};

type TableHeaderStylesProps = {
  canSort: boolean;
  isMobile: boolean;
};

const tableHeaderStyles = (
  {
    fontSize,
    fontWeight,
    spacing,
    backgroundColor,
    headerFontColor,
    hoverBackgroundColor,
    borderColor,
  }: TableHeaderThemeValues,
  { canSort, isMobile }: TableHeaderStylesProps
) => {
  return css`
    position: relative;
    color: ${headerFontColor};
    font-size: ${fontSize(14)};
    font-weight: ${fontWeight};
    padding: ${spacing(0.5, 2)};
    min-height: 52px;
    user-select: none;
    background-color: ${backgroundColor};
    outline: none;
    :not(:last-child) {
      border-right: 1px solid ${borderColor};
    }

    :last-child {
      border-right: 1px solid transparent;
    }

    cursor: default;
    ${canSort &&
    !isMobile &&
    `
    &:hover{
      background-color: ${hoverBackgroundColor};
    }
    `}
  `;
};

type TableMobileRowActionsStylesProps = {
  isExpanded: boolean;
  isSelected: boolean;
};

const mobileRowActionsStyles = (
  {
    backgroundColor,
    selectedRowBackgroundColor,
    expandedRowBackgroundColor,
    spacing,
  }: TableMobileRowActionsThemeValues,
  { isExpanded, isSelected }: TableMobileRowActionsStylesProps
) => css`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  min-height: ${spacing(5)};
  align-items: center;
  padding: ${spacing(1)};
  border-radius: ${spacing(0.5)};
  background-color: ${isSelected
    ? selectedRowBackgroundColor
    : isExpanded
    ? expandedRowBackgroundColor
    : backgroundColor};
`;

const rowCardContainerStyles = ({ spacing, borderBottomColor }: TableRowCardContainerThemeValues) => css`
  flex-direction: column;
  border-bottom: 1px solid ${borderBottomColor};
  padding: ${spacing(1, 0)};
`;

const rowCardStyles = ({ spacing }: TableRowCardThemeValues) => css`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: ${spacing(0.5)};
  grid-auto-flow: dense;
`;

type TableFilterStylesProps =
  | {
      type: 'filterSidebarHeader' | 'filterSectionHeader' | 'filterSidebarButtons';
    }
  | {
      type: 'filterSection';
      extra?: boolean;
    };

const tableFilterStyles = (
  { borderColor, zIndex, spacing }: TableFiltersThemeValues,
  props: TableFilterStylesProps
) => {
  const { type } = props;

  switch (type) {
    case 'filterSection':
      return css`
        padding: ${spacing(2, 4, 2, 4)};
        &:not(:first-of-type) {
          border-top: 1px solid ${borderColor};
        }
        ${props.extra === true &&
        `
        margin-bottom: ${spacing(2)};
        `}
      `;
    case 'filterSidebarHeader':
      return css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: ${spacing(3, 4)};
        position: sticky;
        background: white;
        z-index: ${zIndex};
        border-bottom: 1px solid ${borderColor};
        top: 0;
      `;
    case 'filterSectionHeader':
      return css`
        display: flex;
        justify-content: space-between;
        margin: ${spacing(2, 0)};
      `;
    case 'filterSidebarButtons':
      return css`
        border-top: 1px solid ${borderColor};
        flex: 0 1;
      `;
    default:
      return;
  }
};

const filterNotificationBadgeStyles = ({
  backgroundColor,
  spacing,
  defaultBorderColor,
  focusBorderColor,
  zIndex,
}: FilterNotificationBadgeThemeValues) => {
  return css`
    ::after {
      position: absolute;
      background: ${backgroundColor};
      border-radius: 16px;
      border: 1.5px solid ${defaultBorderColor};
      height: ${spacing(1.5)};
      width: ${spacing(1.5)};
      top: 6px;
      left: ${spacing(3)};
      z-index: ${zIndex};
      content: '';
      transition: border-color, opacity ease-in-out 250ms;
    }
    :hover::after,
    :focus::after {
      border-color: ${focusBorderColor};
    }
  `;
};

const filterColumnStyles = ({ borderColor, spacing }: FilterColumnsThemeValues) => css`
  border-bottom: 1px solid ${borderColor};
  padding: ${spacing(1, 2)};
  min-height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type SortingCaretTypes = { isSorted: boolean; isSortedDesc?: boolean };

const sortingCaretStyles = (
  { activeCaretColor, inactiveCaretColor, spacing }: SortingCaretThemeValues,
  { isSorted, isSortedDesc }: SortingCaretTypes
) => {
  return [
    css`
      margin-left: ${spacing(1)};
      color: ${inactiveCaretColor};

      svg {
        transition: color 0.3s ease;
      }
    `,
    isSorted &&
      (!isSortedDesc
        ? css`
            svg:first-of-type {
              color: ${activeCaretColor};
            }
          `
        : css`
            svg:nth-of-type(2) {
              color: ${activeCaretColor};
            }
          `),
  ];
};

const hoverRowActionsStyles = ({
  spacing,
  floatingShadow,
  borderRadius,
  backgroundColor,
}: HoverRowActionsThemeValues) => {
  return css`
    display: flex;
    position: sticky;
    right: ${spacing(3)};
    cursor: default;
    align-items: flex-start;
    pointer-events: auto;
    background: ${backgroundColor};
    padding: ${spacing(1)};
    gap: ${spacing(1)};
    border-radius: ${borderRadius};
    box-shadow: ${floatingShadow};
  `;
};

const columnResizerStyles = ({ hoverColor }: ColumnResizerThemeValues) => {
  return css`
    width: 9px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%) translateZ(1px);
    touch-action: none;
    transition: background 0.2s ease;

    &::after {
      content: '';
      position: absolute;
      left: 3px;
      top: 0;
      width: 2px;
      height: 100%;
      opacity: 0;
      background: ${hoverColor};
      transition: opacity 0.2s ease;
    }

    &:hover::after {
      opacity: 1;
    }

    &:active::after {
      opacity: 1;
    }
  `;
};

const reorderColumnWhileTapStyles = ({ boxShadow, backgroundColor }: ReorderColumnWhileTapThemeValues) => {
  return {
    cursor: 'grabbing',
    boxShadow,
    background: backgroundColor,
  };
};

export const TableCommonStyles = {
  TableCommonStyles: {
    tableContainerStyles: (props: TableContainerStyleProps) => tableContainerStyle(tableContainerTheme, props),
    tableCellStyles: (props: TableCellStylesProps) => tableCellStyles(tableCellTheme, props),
    tableHeaderStyles: (props: TableHeaderStylesProps) => tableHeaderStyles(tableHeaderTheme, props),
    mobileRowActionsStyles: (props: TableMobileRowActionsStylesProps) =>
      mobileRowActionsStyles(mobileRowActionsTheme, props),
    rowCardStyles: rowCardStyles(tableRowCardTheme),
    rowCardContainerStyles: rowCardContainerStyles(tableRowCardContainerTheme),
    hoverRowActionsStyles: hoverRowActionsStyles(hoverRowActionsTheme),
    columnResizerStyles: columnResizerStyles(columnResizerTheme),
    sortingCaretStyles: (props: SortingCaretTypes) => sortingCaretStyles(sortingCaretTheme, props),
    tableFilterStyles: (props: TableFilterStylesProps) => tableFilterStyles(tableFilterTheme, props),
    filterNotificationBadgeStyles: filterNotificationBadgeStyles(filterNotificationBadgeTheme),
    filterColumnStyles: filterColumnStyles(filterColumnsTheme),
    reorderColumnWhileTapStyles: reorderColumnWhileTapStyles(reorderColumnWhileTapTheme),
  },
};

export const TableCommonStylesOriginal: typeof TableCommonStyles = {
  TableCommonStyles: {
    tableContainerStyles: (props: TableContainerStyleProps) => tableContainerStyle(tableContainerThemeOriginal, props),
    tableCellStyles: (props: TableCellStylesProps) => tableCellStyles(tableCellThemeOriginal, props),
    tableHeaderStyles: (props: TableHeaderStylesProps) => tableHeaderStyles(tableHeaderThemeOriginal, props),
    mobileRowActionsStyles: (props: TableMobileRowActionsStylesProps) =>
      mobileRowActionsStyles(mobileRowActionsThemeOriginal, props),
    rowCardStyles: rowCardStyles(tableRowCardThemeOriginal),
    rowCardContainerStyles: rowCardContainerStyles(tableRowCardContainerThemeOriginal),
    hoverRowActionsStyles: hoverRowActionsStyles(hoverRowActionsThemeOriginal),
    columnResizerStyles: columnResizerStyles(columnResizerThemeOriginal),
    sortingCaretStyles: (props: SortingCaretTypes) => sortingCaretStyles(sortingCaretThemeOriginal, props),
    tableFilterStyles: (props: TableFilterStylesProps) => tableFilterStyles(tableFilterThemeOriginal, props),
    filterNotificationBadgeStyles: filterNotificationBadgeStyles(filterNotificationBadgeThemeOriginal),
    filterColumnStyles: filterColumnStyles(filterColumnsThemeOriginal),
    reorderColumnWhileTapStyles: reorderColumnWhileTapStyles(reorderColumnWhileTapThemeOriginal),
  },
};
