import { CriticalDot, PrimaryDot, SuccessDot, WarningDot } from '../../dots';
import { DotThemeValues } from '../../dots/dot-theme';

enum HoverTexts {
  Critical = 'Message failed to send',
  Warning = 'Message has not been responded to',
  Primary = 'Unread',
}

export const AvatarDot = ({ variant, hoverText }: { variant: keyof DotThemeValues['colors']; hoverText?: string }) => {
  const Dots = {
    critical: <CriticalDot hoverText={hoverText ?? HoverTexts.Critical} />,
    primary: <PrimaryDot hoverText={hoverText ?? HoverTexts.Primary} />,
    success: <SuccessDot />,
    warning: <WarningDot hoverText={hoverText ?? HoverTexts.Warning} />,
  };

  return <span id='avatar-dot'>{Dots[variant]}</span>;
};
