import { useTransition, config as rsConfig } from 'react-spring';
import { getEnterValue, slideAxis, axisModifier } from './use-slide-transition';
import type { SlideTransitionProps, SlideTransitionResponse } from './use-slide-transition';

export function useSlideFadeTransition({
  active,
  direction,
  distance = '10px',
  config = rsConfig.default,
}: SlideTransitionProps): SlideTransitionResponse {
  return useTransition(active, null, {
    from: {
      opacity: 0,
      transform: `${slideAxis[direction]}(${axisModifier[direction]}${distance})`,
    },
    enter: {
      opacity: 1,
      transform: `${slideAxis[direction]}(${axisModifier[direction]}${getEnterValue(distance)})`,
    },
    leave: {
      opacity: 0,
      transform: `${slideAxis[direction]}(${axisModifier[direction]}${distance})`,
    },
    config: config,
  });
}
