import { Text } from '../../';
import { TextLink } from '../../';
import { useStyles } from '../../../use-styles';

type ManualSidebarSectionHeader = {
  label: React.ReactNode;
  clearLabel?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
};

export const ManualFilterSectionHeader = ({
  label,
  onClick,
  clearLabel,
  disabled = false,
}: ManualSidebarSectionHeader) => {
  const filterSectionHeaderStyles = useStyles('TableCommonStyles', 'tableFilterStyles', {
    type: 'filterSectionHeader',
  });

  return (
    <section css={filterSectionHeaderStyles}>
      {typeof label === 'string' ? <Text weight='semibold'>{label}</Text> : label}
      {!!onClick && (
        <TextLink disabled={disabled} weight='semibold' onClick={onClick}>
          {clearLabel ?? 'Clear'}
        </TextLink>
      )}
    </section>
  );
};
