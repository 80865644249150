import { ColorPickerProvider } from './provider';
import { ColorPickerMain } from './color-picker-main';

export const ColorPicker = (props: Omit<ColorPickerProvider, 'children'>) => {
  return (
    <ColorPickerProvider {...props}>
      <ColorPickerMain />
    </ColorPickerProvider>
  );
};
