import React from 'react';
import { Text, TextProps } from '../../text';
import { css } from '@emotion/react';
import { useIndexRowItemContext } from './index-row-item';

type IndexRowTailProps = {
  children: React.ReactNode;
  isInteractive?: boolean;
} & TextProps;

export const IndexRowTail = ({ children, isInteractive = true, ...rest }: IndexRowTailProps) => {
  const { disabled } = useIndexRowItemContext();

  return (
    <div
      onClick={typeof children === 'string' || !isInteractive ? undefined : (e) => e.stopPropagation()}
      css={css`
        margin-left: auto;
      `}
    >
      {typeof children === 'string' ? (
        <Text
          color='light'
          css={[
            css`
              margin: 0;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            `,
            disabled &&
              css`
                opacity: 0.5;
              `,
          ]}
          {...rest}
          size='medium'
        >
          {children}
        </Text>
      ) : (
        children
      )}
    </div>
  );
};

IndexRowTail.displayName = 'IndexRowTail';
