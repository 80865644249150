import { css } from '@emotion/react';
import type { PopoverMenuItemThemeValues } from './popover-menu-theme';
import {
  popoverMenuItemTheme,
  popoverMenuItemThemeOriginal,
  popoverMenuTheme,
  popoverMenuThemeOriginal,
  PopoverMenuThemeValues,
} from './popover-menu-theme';

type LiStyleProps = { disabled?: boolean; active?: boolean; destructive?: boolean };

const menuItemStyle = (
  { backgroundColor, disabledColor, focusedBorderColor, spacing }: PopoverMenuItemThemeValues,
  props?: LiStyleProps
) => [
  css`
    align-items: center;
    background: none;
    border: none;
    cursor: default;
    display: flex;
    height: 40px;
    justify-content: flex-start;
    margin: 0;
    outline: none;
    padding: ${spacing(0, 2)};
    gap: ${spacing(1)};
    position: relative;
    text-decoration: none;
    transition: background-color 250ms ease-out;

    > :first-letter {
      text-transform: uppercase;
    }

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 1;
      display: inline;
      text-align: start;
    }

    :hover {
      background-color: ${backgroundColor};
    }
    :focus {
      background-color: ${backgroundColor};
      outline: none;
    }
  `,

  !props?.destructive &&
    css`
      svg {
        /* fill needs to be here in case the svg is inside a :visited <a> tag */
        fill: inherit;
      }
    `,
  props?.disabled &&
    css`
      background-color: ${disabledColor};
      color: black;
      cursor: default;
      opacity: 0.5;
      pointer-events: none;
      :hover,
      :focus {
        background-color: ${disabledColor};
        outline: none;
      }
    `,
  props?.active &&
    css`
      background: ${backgroundColor};
      outline: none;
      ::before {
        content: '';
        background: ${focusedBorderColor};
        height: 100%;
        width: 3px;
        left: 0;
        position: absolute;
      }
    `,
];

const menuStyle = ({ spacing, borderRadius, shadows, fontSize, zIndex }: PopoverMenuThemeValues) => css`
  background: white;
  border-radius: ${borderRadius};
  box-shadow: ${shadows};
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  min-width: 100px;
  overflow: auto;
  padding: ${spacing(1, 0)};
  z-index: ${zIndex};

  :focus {
    outline: none;
  }

  h2 {
    border-left: 2px solid transparent;
    font-size: ${fontSize(18)};
    margin: 0;
    padding: ${spacing(1, 2)};
    text-transform: capitalize;
  }
`;

export const PopoverMenuStyles = {
  PopoverMenu: {
    menuItem: (props: LiStyleProps) => menuItemStyle(popoverMenuItemTheme, props),
    menu: () => menuStyle(popoverMenuTheme),
  },
};

export const PopoverMenuStylesOriginal: typeof PopoverMenuStyles = {
  PopoverMenu: {
    menuItem: (props: LiStyleProps) => menuItemStyle(popoverMenuItemThemeOriginal, props),
    menu: () => menuStyle(popoverMenuThemeOriginal),
  },
};
