/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const EyedropIconSmall: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={16} {...props} viewBox='0 0 16 16' noFill>
    <path
      d='M7.77359 5.88257L3.23701 10.4191C2.97556 10.6806 2.79171 11.0094 2.70587 11.369L2.38611 12.7087C2.37717 12.7462 2.36481 12.7827 2.34919 12.8179L2.3363 12.847C2.1035 13.3717 2.64927 13.9028 3.16747 13.6558V13.6558C3.21066 13.6352 3.2561 13.6197 3.30287 13.6096L4.63915 13.3208C5.01476 13.2396 5.35903 13.0519 5.63077 12.7801L10.151 8.25994'
      stroke='currentColor'
      strokeWidth='2'
    />
    <path
      stroke='currentColor'
      d='M7.26522 6.15868L9.84132 8.73478C10.4271 9.32057 11.3769 9.32056 11.9626 8.73478L12.108 8.58944C12.6063 8.09108 12.6807 7.32929 12.3311 6.7523L13.6505 5.43293C14.4979 4.58552 14.4979 3.21161 13.6505 2.3642C12.8031 1.5168 11.4292 1.5168 10.5818 2.3642L9.26589 3.68008C8.6868 3.31776 7.91418 3.38841 7.41056 3.89202L7.26522 4.03736C6.67943 4.62315 6.67943 5.5729 7.26522 6.15868Z'
      strokeWidth='2'
    />
  </Icon>
);
