import { css } from '@emotion/react';
import type { TemplateEditorThemeValues } from './template-editor-theme';

export type TemplateEditorStyleProps = { readOnly: boolean; error: boolean; hasSubtext: boolean };

export const templateEditorStyle = (
  { borderColor, borderRadius, spacing, fontSize, fontColor, focusColor, errorColor }: TemplateEditorThemeValues,
  { readOnly, error, hasSubtext }: TemplateEditorStyleProps
) => css`
  display: flex;
  flex-direction: column;
  border: 1px solid ${error ? errorColor : borderColor};
  border-radius: ${borderRadius};
  padding: ${spacing(2)};
  font-size: ${fontSize(16)};

  p[data-slate-node='element'] span[data-slate-leaf] span[contenteditable='false'] {
    /* Style the placeholder text */
    color: ${error ? errorColor : fontColor};
    opacity: 1 !important; /* Override slate */
  }

  span[data-slate-void='true'] span[data-slate-spacer] {
    /* Needed to fix the cursor after a tag, so it doesn't look like a superscript */
    position: relative !important;
  }

  .message-template-slate-editor {
    flex: 1;
  }

  ${hasSubtext &&
  `
    /* Spacing for the error message */
    margin-bottom: ${spacing(1)};
  `}

  ${!readOnly &&
  `
    :focus-within {
      box-shadow: 0 0 0 1px ${error ? errorColor : focusColor};
      border-color: ${error ? errorColor : focusColor};
    }
  `}
`;
