import { css } from '@emotion/react';
import { Placement } from '@floating-ui/react-dom-interactions';
import { styles } from '../../../../styles';
import {
  dropdownInputTheme,
  dropdownInputThemeOriginal,
  DropdownInputThemeProps,
  dropdownMenuTheme,
  dropdownMenuThemeOriginal,
  DropdownMenuThemeProps,
  dropdownOptionTheme,
  dropdownOptionThemeOriginal,
  DropdownOptionThemeProps,
} from './theme';

const upperBoxShadow = '1px -5px 14px 2px rgb(49 53 61 / 24%)';

type DropdownMenuProps = { placement?: Placement | ''; maxHeight: number | null };

type DropdownOptionProps = { active: boolean; disabled?: boolean };

type InputProps = { disabled?: boolean };

const dropdownMenuStyle = (
  { backgroundColor, smallScrollbar, boxShadow }: DropdownMenuThemeProps,
  { placement, maxHeight }: DropdownMenuProps
) => [
  css`
    background: ${backgroundColor};
    /* theme shadows blur the field label if the floating element renders above the trigger 
    /* this custom upperBoxShadow makes sure the label is visible */
    box-shadow: ${placement === 'top' ? upperBoxShadow : boxShadow};
    margin: 0;
    outline: none;
    padding: 0;
    max-height: 250px;
    ${smallScrollbar}
  `,
  maxHeight &&
    css`
      max-height: ${maxHeight}px;
    `,
];

const dropdownOptionStyle = (
  { color, spacing, activeBackground, disabledColor }: DropdownOptionThemeProps,
  { active, disabled }: DropdownOptionProps
) => [
  css`
    align-items: center;
    color: ${color};
    cursor: default;
    display: flex;
    justify-content: space-between;
    min-height: 40px;
    outline: 0;
    padding: ${spacing(1, 2)};
    width: 100%;
    .select-option-text {
      /* adding the break-all property because some values in our apps are very long and have no spaces */
      /* break-all prevents those values from causing overflow problems */
      word-break: break-all;
    }
  `,
  active &&
    css`
      background: ${activeBackground};
    `,
  disabled &&
    css`
      .select-option-text {
        color: ${disabledColor};
      }
      :hover {
        background: none;
        cursor: not-allowed;
      }
    `,
];

export const inputStyle = (
  { fontFamily, fontSize, spacing, placeholderColor, disabledColor }: DropdownInputThemeProps,
  { disabled }: InputProps
) => [
  css`
    font-family: ${fontFamily};
    font-size: ${fontSize(16)};
    line-height: 1.375;
    padding-right: ${spacing(4)};
    text-align: left;
    ${styles.truncate}
    > input[type='text'] {
      margin-right: 0;
      padding-right: ${spacing(3)};
      ::placeholder {
        color: ${placeholderColor};
      }
    }
  `,
  disabled &&
    css`
      color: ${disabledColor};
    `,
];

export const DropdownFieldStyles = {
  DropdownField: {
    dropdownMenu: (dropdownMenuProps: DropdownMenuProps) => dropdownMenuStyle(dropdownMenuTheme, dropdownMenuProps),
    dropdownOption: (dropdownOptionProps: DropdownOptionProps) =>
      dropdownOptionStyle(dropdownOptionTheme, dropdownOptionProps),
    dropdownInput: (dropdownInputProps: InputProps) => inputStyle(dropdownInputTheme, dropdownInputProps),
  },
};

export const DropdownFieldStylesOriginal: typeof DropdownFieldStyles = {
  DropdownField: {
    dropdownMenu: (dropdownMenuProps: DropdownMenuProps) =>
      dropdownMenuStyle(dropdownMenuThemeOriginal, dropdownMenuProps),
    dropdownOption: (dropdownOptionProps: DropdownOptionProps) =>
      dropdownOptionStyle(dropdownOptionThemeOriginal, dropdownOptionProps),
    dropdownInput: (dropdownInputProps: InputProps) => inputStyle(dropdownInputThemeOriginal, dropdownInputProps),
  },
};
