import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../../../component-theme/theme-types';

export type CalendarControlThemeValues = {
  spacing: WeaveThemeValues['Spacing'];
  svgColor: WeaveThemeValues['Color'];
};

export type ControlsHeaderThemeValues = {
  borderColor: WeaveThemeValues['Color'];
  spacing: WeaveThemeValues['Spacing'];
};

export type MonthNameThemeValues = {
  color: WeaveThemeValues['Color'];
  fontSize: WeaveThemeValues['FontSize'];
  fontWeight: WeaveThemeValues['FontWeight'];
};

export type TitleHeaderThemeValues = {
  backgroundColor: WeaveThemeValues['Color'];
  svgColor: WeaveThemeValues['Color'];
  focusRingColor: WeaveThemeValues['Color'];
  borderRadius: WeaveThemeValues['BorderRadius'];
  spacing: WeaveThemeValues['Spacing'];
};

export const calendarControlTheme: CalendarControlThemeValues = {
  spacing: theme.spacing,
  svgColor: theme.colors.neutral80,
};

export const calendarControlThemeOriginal: CalendarControlThemeValues = {
  spacing: themeOriginal.spacing,
  svgColor: themeOriginal.colors.gray[600],
};

export const controlsHeaderTheme: ControlsHeaderThemeValues = {
  borderColor: theme.colors.neutral20,
  spacing: theme.spacing,
};

export const controlsHeaderThemeOriginal: ControlsHeaderThemeValues = {
  borderColor: themeOriginal.colors.gray[300],
  spacing: themeOriginal.spacing,
};

export const monthNameTheme: MonthNameThemeValues = {
  color: theme.colors.neutral90,
  fontSize: theme.fontSize,
  fontWeight: theme.font.weight.bold,
};

export const monthNameThemeOriginal: MonthNameThemeValues = {
  color: themeOriginal.colors.gray[600],
  fontSize: themeOriginal.fontSize,
  fontWeight: themeOriginal.font.weight.bold,
};

export const titleHeaderTheme: TitleHeaderThemeValues = {
  backgroundColor: theme.colors.neutral5,
  svgColor: theme.colors.neutral80,
  focusRingColor: theme.colors.primary50,
  borderRadius: theme.borderRadius.small,
  spacing: theme.spacing,
};

export const titleHeaderThemeOriginal: TitleHeaderThemeValues = {
  backgroundColor: themeOriginal.colors.gray[300],
  svgColor: themeOriginal.colors.gray[600],
  focusRingColor: themeOriginal.colors.weaveBlue,
  borderRadius: themeOriginal.borderRadius.small,
  spacing: themeOriginal.spacing,
};
