import { theme } from '@frontend/theme';
import type { WeaveTheme as WeaveThemeOriginal } from '@frontend/theme-original';
import { theme as originalTheme } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../component-theme/theme-types';

export type IconButtonStyleValues = {
  backgroundColor: WeaveThemeValues['Color'];
  borderRadius: WeaveThemeValues['BorderRadius'];
  disabledSvgFill: WeaveThemeValues['Color'];
  destructiveSvgFill: WeaveThemeValues['Color'];
  disabledTextColor?: WeaveThemeValues['Color'];
  focusRingColor: WeaveThemeValues['Color'];
  fontSize: WeaveThemeValues['FontSize'];
  spacing: WeaveThemeValues['Spacing'];
  svgFill: WeaveThemeValues['Color'] | WeaveThemeOriginal['colors']['gray'];
};

export const iconButtonTheme: IconButtonStyleValues = {
  backgroundColor: theme.colors.neutral5,
  borderRadius: theme.borderRadius.small,
  disabledSvgFill: theme.colors.neutral20,
  destructiveSvgFill: theme.colors.critical50,
  disabledTextColor: theme.colors.neutral20,
  focusRingColor: theme.colors.primary50,
  fontSize: theme.fontSize,
  spacing: theme.spacing,
  svgFill: theme.colors.neutral80,
};

export const originalIconButtonTheme: IconButtonStyleValues = {
  backgroundColor: originalTheme.colors.gray[300],
  borderRadius: '999px',
  disabledSvgFill: originalTheme.colors.gray[400],
  destructiveSvgFill: originalTheme.colors.error,
  focusRingColor: originalTheme.colors.weaveBlue,
  fontSize: originalTheme.fontSize,
  spacing: originalTheme.spacing,
  svgFill: originalTheme.colors.gray,
};
