/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const CaretLeftIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M9.66776695,11.981728 L16.9156115,19.2295725 C17.4037668,19.7177279 17.4037668,20.5091841 16.9156115,20.9973394 C16.4274561,21.4854948 15.6359999,21.4854948 15.1478445,20.9973394 L7.01611652,12.8656115 C6.52796116,12.3774561 6.52796116,11.5859999 7.01611652,11.0978445 L15.1478445,2.96611652 C15.6359999,2.47796116 16.4274561,2.47796116 16.9156115,2.96611652 C17.4037668,3.45427189 17.4037668,4.24572811 16.9156115,4.73388348 L9.66776695,11.981728 Z'
    />
  </Icon>
);
