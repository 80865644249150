import React from 'react';
import { ActionPressedChip } from '../atoms';
import { UpdateIcon } from '../../../icon';

type UndoActionProps = Omit<React.ComponentProps<typeof ActionPressedChip>, 'children'>;

export const UndoAction = ({ compact = true, showBoxShadow = true, ...rest }: UndoActionProps) => {
  return (
    <ActionPressedChip {...rest} compact={compact} showBoxShadow={showBoxShadow}>
      <UpdateIcon size={16} />
    </ActionPressedChip>
  );
};
