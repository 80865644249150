import { useRefInterceptor } from '../../../hooks';
import { forwardRef, useLayoutEffect, useRef, useState } from 'react';
import type { InputProps, TextareaProps } from './types';

export type AutoGrowTextareaProps = TextareaProps &
  InputProps<'text'> & {
    maxRows?: number;
    minRows?: number;
  };

export type TextareaSelection = {
  selectionStart: number;
  selectionEnd: number;
};
export type TextareaRef = HTMLTextAreaElement & {
  getSelection: () => TextareaSelection;
  setSelection: (position: number) => void;
};

const getLineHeight = (el: HTMLTextAreaElement) => {
  const styles = window.getComputedStyle(el);
  const lineHeight = styles?.['line-height'];
  if (!lineHeight) return 16;
  return +lineHeight.replace(/\D/g, '');
};

export const AutoGrowTextarea = forwardRef<HTMLTextAreaElement, AutoGrowTextareaProps>(
  ({ maxRows = 10, minRows = 2, spellCheck = true, ...rest }, ref) => {
    const [rows, setRows] = useState(minRows || 1);
    const [localRef, elementRef] = useRefInterceptor(ref);
    const baseScrollHeight = useRef<number>(0);
    const rowHeight = useRef<number>(16);

    const updateRows = () => {
      const element = localRef.current;
      if (element) {
        element.rows = minRows;
        // bitwise shift (~~) is faster Math.floor
        const nextRows = ~~((element.scrollHeight - baseScrollHeight.current) / rowHeight.current) + minRows;
        element.rows = rows;
        if (nextRows !== rows) {
          setRows(nextRows > maxRows ? maxRows : nextRows);
        }
      }
    };

    useLayoutEffect(() => {
      const element = localRef.current;
      // get height of box's minRows for diffing scrollHeights
      if (element) {
        element.rows = minRows;
        element.value = '';
        baseScrollHeight.current = element.scrollHeight;
        rowHeight.current = getLineHeight(element);
        element.value = rest.value;
        element.rows = rows;
      }
    }, [minRows]);

    useLayoutEffect(() => {
      updateRows();
    }, [rest.value]);

    return (
      //@ts-ignore -> we are using HTMLInput props and not HTMLTextArea, need to create
      // a new entry in FieldConfig for type = "textarea"
      <textarea {...rest} rows={rows} ref={elementRef} spellCheck={spellCheck} />
    );
  }
);
