import React, { ChangeEvent, forwardRef, useImperativeHandle, useRef } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import TextareaAutosize from 'react-textarea-autosize';

export type AutoGrowTextareaProps = Omit<React.HTMLProps<HTMLTextAreaElement>, 'onChange'> & {
  maxRows?: number;
  minRows?: number;
  name: string;
  onChange: (value: string) => void;
  value: string;
};

export type TextareaSelection = { selectionStart: number; selectionEnd: number };
export type TextareaRef = HTMLTextAreaElement & {
  getSelection: () => TextareaSelection;
  setSelection: (position: number) => void;
};

export const textAreaStyle = css`
  font-family: ${theme.font.family};
  font-size: ${theme.fontSize(16)};
  text-align: left;
  line-height: 1.375;
  color: ${theme.colors.neutral70};
  width: 100%;
  resize: none;
  outline: none;
  border: none;
  &::placeholder {
    color: ${theme.colors.neutral30};
  }
`;

export const AutoGrowTextarea = forwardRef(
  ({ maxRows = 10, minRows = 2, onChange, spellCheck = true, ...rest }: AutoGrowTextareaProps, ref) => {
    const elementRef = useRef<HTMLTextAreaElement>(null);

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (elementRef.current) {
          elementRef.current.focus();
        }
      },
      select: () => {
        if (elementRef.current) {
          elementRef.current.select();
        }
      },
      getSelection: () => {
        if (elementRef.current) {
          const { selectionStart, selectionEnd } = elementRef.current;
          return { selectionStart, selectionEnd };
        }
        return {};
      },
      setSelection: (position: number) => {
        if (elementRef.current) {
          elementRef.current.setSelectionRange(position, position);
        }
      },
    }));

    const { minHeight, maxHeight, height, ...restStyle } = rest.style || {};

    return (
      <TextareaAutosize
        css={[
          textAreaStyle,
          {
            minHeight,
            maxHeight,
            height,
          },
        ]}
        {...rest}
        minRows={minRows}
        maxRows={maxRows}
        // If height is not provided, a default value is added here, otherwise it will be added in the css prop
        style={{ height: height ? undefined : 26, ...restStyle }}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
          onChange(e.target.value);
        }}
        ref={elementRef}
        spellCheck={spellCheck}
      />
    );
  }
);
