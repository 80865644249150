import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { useGetPrimaryContactLegacyQuery, useListPreferredPersonsByPhoneQuery } from './queries';

type QueryType = 'associatedContacts' | 'primaryContact' | 'both';

type UseAssociatedContactsArgs = {
  phoneNumber: string;
  locationId: string;
  enabled?: boolean;
};

type UseAssociatedContactsResults = {
  isLoading: boolean;
  associatedContacts: Person[];
  primaryContactId: string;
  isDefaultPrimaryContact?: boolean;
  refetch: (queryType?: QueryType) => Promise<{
    associatedContacts?: Person[];
    primaryContactId?: string;
  }>;
};

/**
 * A hook to retrieve the primary contact id and associated contacts (all contacts who share the given phone number)
 * for a give phone number in a specified location.
 * @param phoneNumber - The phone number for which to retrieve the primary contact and associated contacts.
 * @param locationId - The location from which the primary contact and associated contacts should be fetched.
 * @param enabled (optional) - When `false`, will disable all queries used by this hook. Defaults to `true`. Useful for
 *  disabling unnecessary queries when conditionally showing a specific person instead of the primary contact.
 */
export const useAssociatedContacts = ({
  phoneNumber,
  locationId,
  enabled = true,
}: UseAssociatedContactsArgs): UseAssociatedContactsResults => {
  const {
    data: associatedContactsResponse = { persons: [] },
    isLoading: associatedContactsIsLoading,
    refetch: refetchAssociatedContacts,
  } = useListPreferredPersonsByPhoneQuery(
    {
      phoneNumber,
      locationId,
    },
    {
      enabled: enabled && !!locationId && !!phoneNumber,
    }
  );

  const {
    data: primaryContact,
    isLoading: primaryContactIsLoading,
    refetch: refetchPrimaryContact,
  } = useGetPrimaryContactLegacyQuery(
    {
      phoneNumber,
      locationId,
    },
    {
      enabled: enabled && !!locationId && !!phoneNumber,
    }
  );

  const refetch: UseAssociatedContactsResults['refetch'] = async (queryType = 'both') => {
    switch (queryType) {
      case 'associatedContacts':
        return {
          associatedContacts: (await refetchAssociatedContacts()).data?.persons,
        };
      case 'primaryContact':
        return {
          primaryContactId: (await refetchPrimaryContact()).data?.personId,
        };
      default:
        return {
          associatedContacts: (await refetchAssociatedContacts()).data?.persons,
          primaryContactId: (await refetchPrimaryContact()).data?.personId,
        };
    }
  };

  // Default to oldest associated contact (response is already sorted by the BE query)
  const defaultPrimaryContactPersonId = associatedContactsResponse.persons[0]?.personId;
  const primaryContactId = primaryContact?.personId || defaultPrimaryContactPersonId;

  return enabled
    ? {
        isLoading: associatedContactsIsLoading || primaryContactIsLoading,
        associatedContacts: associatedContactsResponse.persons,
        primaryContactId,
        isDefaultPrimaryContact: !primaryContact?.personId,
        refetch,
      }
    : {
        isLoading: false,
        associatedContacts: [],
        primaryContactId: '',
        isDefaultPrimaryContact: undefined,
        refetch: (_) => Promise.resolve({}),
      };
};
