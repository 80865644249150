export const kebabCase = (str: string) => str.toLowerCase().split(' ').join('-');

export const sentenceCase = (str: string) => {
  const sentence = str.toLowerCase().split(' ');
  return sentence
    .map((word, index) => {
      if (index > 0) {
        const previousWord = sentence.at(index);
        if (previousWord?.includes('.'))
          return word
            .split('')
            .map((letter, index) => (index === 0 ? letter.toUpperCase() : letter))
            .join('');
        return word;
      } else {
        return word
          .split('')
          .map((letter, index) => (index === 0 ? letter.toUpperCase() : letter))
          .join('');
      }
    })
    .join(' ');
};

export const titleCase = (str: string) => {
  const sentence = str.toLowerCase().split(' ');
  for (let i = 0; i < sentence.length; i++) {
    if (!sentence[i] || sentence[i].length === 0) sentence[i] = '';
    else sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
  }
  return sentence.join(' ');
};
