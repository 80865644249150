import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { useMultiStepModal } from '@frontend/payments-multistep-modal';
import { theme } from '@frontend/theme';
import { Heading, Modal, PrimaryButton, SecondaryButton, TextButton } from '@frontend/design-system';
import { RegisterTerminalModalSteps, useRegisterTerminalModal } from './hooks';

interface RegisterTerminalModalStepProps {
  title: string;
  children: ReactNode;
  nextStep?: RegisterTerminalModalSteps;
  hasContinue?: boolean;
  hideBackButton?: boolean;
  nextSectionStep?: RegisterTerminalModalSteps;
  onRegister?: () => void;
  isRegisterButtonDisabled?: boolean;
}

const styles = {
  list: css`
    list-style-type: number;
  `,
  modalActions: css`
    display: flex;
    justify-content: space-between;
    padding: ${theme.spacing(3, 3, 0)};
    gap: ${theme.spacing(2)};
    flex-wrap: wrap;
    align-items: center;
  `,
  actionsLeft: css``,
  actionsRight: css`
    display: flex;
    gap: ${theme.spacing(2)};
    flex-wrap: wrap;
  `,
  actionsButton: css`
    width: auto;
  `,
  skipButton: css`
    color: ${theme.colors.primary50};
    font-weight: ${theme.font.weight.bold};
  `,
};

export const RegisterTerminalModalStep = ({
  title,
  children,
  nextStep,
  hasContinue = false,
  hideBackButton = false,
  nextSectionStep,
  onRegister,
  isRegisterButtonDisabled = false,
}: RegisterTerminalModalStepProps) => {
  const { t } = useTranslation('payments');
  const { goToStep } = useRegisterTerminalModal();
  const { goBack } = useMultiStepModal();
  return (
    <>
      <Modal.Body>
        <Heading level={3}>{title}</Heading>
        {children}
      </Modal.Body>
      <div css={styles.modalActions}>
        <div css={styles.actionsLeft}>
          {nextSectionStep && (
            <TextButton onClick={() => goToStep(nextSectionStep)} css={styles.skipButton}>
              {t('Skip this step')}
            </TextButton>
          )}
        </div>
        <div css={styles.actionsRight}>
          {!hideBackButton && (
            <SecondaryButton onClick={() => goBack()} css={styles.actionsButton} size='large'>
              {t('Back')}
            </SecondaryButton>
          )}
          {onRegister ? (
            <PrimaryButton
              onClick={() => onRegister()}
              css={styles.actionsButton}
              size='large'
              disabled={isRegisterButtonDisabled}
            >
              {t('Register')}
            </PrimaryButton>
          ) : (
            nextStep &&
            (hasContinue ? (
              <PrimaryButton onClick={() => goToStep(nextStep)} css={styles.actionsButton} size='large'>
                {t('Continue')}
              </PrimaryButton>
            ) : (
              <SecondaryButton onClick={() => goToStep(nextStep)} css={styles.actionsButton} size='large'>
                {t('Next')}
              </SecondaryButton>
            ))
          )}
        </div>
      </div>
    </>
  );
};
