import { ElementType } from 'react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { Photos } from '@frontend/photos';
import { useContactPanelShallowStore } from '@frontend/shared';
import { theme } from '@frontend/theme';
import {
  Avatar,
  Heading,
  HeadingProps,
  PolymorphicComponentPropWithoutRef,
  TextProps,
  Text,
  AvatarProps,
  IconButton,
} from '@frontend/design-system';

type CustomTextProps<E extends 'heading' | 'text'> = {
  type: E;
} & (E extends 'text' ? TextProps : HeadingProps);

export type ThreadTitleProps<
  E extends ElementType = 'div',
  TextType extends 'heading' | 'text' = 'heading'
> = PolymorphicComponentPropWithoutRef<
  E,
  {
    text: string;
    personId?: string;
    groupId?: string;
    variant?: 'horizontal' | 'vertical';
    textProps?: CustomTextProps<TextType>;
    avatarProps?: AvatarProps;
    openContactPanel?: boolean;
    onBackClick?: () => void;
  }
>;

/**
 * `ThreadTitle` is a component that displays the title of the thread. This includes the `Avatar`, and title text (usually
 * the person name or phone number)
 * Its children will be rendered inside the title, after the avatar and title text. This is a useful place to put the
 * `PersonSecondaryInfo` and `AssociatedContactsButton` components.
 *
 * If the `variant` is 'vertical', its children will be displayed below the title text.
 *
 * @param text - The title text to display.
 * @param personId (optional) - The ID of the person associated with the thread. If provided, the title text will be clickable and
 * will open the contact panel for the person.
 * @param groupId (optional) - The ID of the group to which the person belongs.
 * @param variant (optional) - The layout of the title. Can be 'horizontal' or 'vertical'. Defaults to 'horizontal'.
 * @param textProps (optional) - The props to pass to the title text component. If the `type` is 'text', a `Text` component will be
 * used. If the `type` is 'heading', a `Heading` component will be used. Defaults to a `Heading` component.
 * @param avatarProps (optional) - The props to pass to the `Avatar` component.
 * @param openContactPanel (optional) - Whether the contact panel should be opened when the title text is clicked. Defaults to `true`.
 * @param onBackClick (optional) - A callback function that will be called when the back button is clicked. If provided, a back button
 * will be displayed before the avatar.
 */
export const ThreadTitle = <E extends ElementType = 'div', TextType extends 'heading' | 'text' = 'heading'>({
  as,
  variant = 'horizontal',
  text,
  textProps,
  avatarProps,
  personId,
  groupId,
  openContactPanel = true,
  onBackClick,
  children,
  ...rest
}: ThreadTitleProps<E, TextType>) => {
  const Component = as || 'div';
  const { t } = useTranslation('thread-header');

  const { setPersonId } = useContactPanelShallowStore('setPersonId');

  return (
    <Component
      css={[
        {
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(1),
          overflowX: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
        variant === 'vertical' && {
          flexDirection: 'column',
          flexGrow: 1,
        },
      ]}
      {...rest}
    >
      {onBackClick && (
        <IconButton label={t('Back')} onClick={onBackClick}>
          <Icon name='back' />
        </IconButton>
      )}
      {variant === 'horizontal' &&
        (personId ? (
          <Photos.ContactProfilePhoto
            personId={personId}
            name={text}
            disableClick={!openContactPanel}
            {...avatarProps}
          />
        ) : (
          <Avatar name={personId ? text : undefined} {...avatarProps} />
        ))}
      {textProps?.type === 'text' ? (
        <Text
          css={{ cursor: 'pointer', textOverflow: 'ellipsis', overflowX: 'hidden' }}
          {...(textProps as TextProps)}
          onClick={(e) => {
            if (personId && openContactPanel) {
              setPersonId(personId, true, groupId);
            }
            textProps?.onClick?.(e);
          }}
        >
          {text}
        </Text>
      ) : (
        <Heading
          css={{ cursor: openContactPanel ? 'pointer' : 'default', textOverflow: 'ellipsis', overflowX: 'hidden' }}
          level={2}
          {...(textProps as HeadingProps)}
          onClick={(e) => {
            if (personId && openContactPanel) {
              setPersonId(personId, true, groupId);
            }
            textProps?.onClick?.(e);
          }}
        >
          {text}
        </Heading>
      )}
      {children}
    </Component>
  );
};
