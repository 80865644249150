import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { HyperlinkAction } from '../../molecules';
import { $isRangeSelection, $getSelection, SELECTION_CHANGE_COMMAND } from 'lexical';
import { useCallback, useEffect } from 'react';
import { useToolbarState } from '../../providers';
import { mergeRegister } from '@lexical/utils';
import { $isAtNodeEnd } from '@lexical/selection';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';

export const Link = () => {
  const [editor] = useLexicalComposerContext();
  const { isLink, linkUrl, setLinkUrl } = useToolbarState(['isLink', 'linkUrl', 'setLinkUrl']);

  const LowPriority = 1;

  function getSelectedNode(selection) {
    const anchor = selection.anchor;
    const focus = selection.focus;
    const anchorNode = selection.anchor.getNode();
    const focusNode = selection.focus.getNode();
    if (anchorNode === focusNode) {
      return anchorNode;
    }
    const isBackward = selection.isBackward();
    if (isBackward) {
      return $isAtNodeEnd(focus) ? anchorNode : focusNode;
    } else {
      return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
    }
  }

  const insertLink = useCallback(
    (val: any) => {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, val === '' ? null : val);
    },
    [editor]
  );

  const updateLinkEditor = () => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL());
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
      } else {
        setLinkUrl('');
      }
    }
  };

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateLinkEditor();
        });
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor();
          return true;
        },
        LowPriority
      )
    );
  }, [editor, updateLinkEditor]);

  return <HyperlinkAction isActive={isLink} onChange={insertLink} value={linkUrl} />;
};

Link.displayName = 'Link';
