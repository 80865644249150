import { createContext } from 'react';
import type { ReactNode } from 'react';
import { RootComponentStyles } from '../component-theme';

export const StylesContext = createContext<RootComponentStyles | undefined>(undefined);

type StylesProviderProps = {
  styles: RootComponentStyles;
  children: ReactNode;
};
export const StylesProvider = ({ styles, children }: StylesProviderProps) => (
  <StylesContext.Provider value={styles}>{children}</StylesContext.Provider>
);
