import MaskedInput, { conformToMask } from '../../../helpers/text-mask';
import { digitsOnly } from '../../../helpers';
import type { ChangeEvent } from 'react';
import type { InputProps } from './types';
import type { MaskConfig } from './mask-types';
import { getMaskConfigProps } from './utils';

const phoneMasks = {
  hyphenated: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  standard: ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
};

export const PhoneInput = ({
  format = 'standard',
  onChange,
  value = '',
  ...rest
}: InputProps<'phone'> & MaskConfig) => {
  // always keep the value digits-only, keeping formatting only in the ui
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange({ name: rest.name, value: digitsOnly(event.target.value) });
  };

  const { maskConfigProps, nonConfigProps } = getMaskConfigProps(rest);

  return (
    <MaskedInput
      {...nonConfigProps}
      onChange={handleChange}
      type='tel'
      pattern='[0-9]*'
      mask={phoneMasks[format]}
      value={value.trim() ? conformToMask(value, phoneMasks[format], maskConfigProps).conformedValue : value}
    />
  );
};
