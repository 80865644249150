import { forwardRef } from 'react';
import { useStyles } from '../../../use-styles';
import { ButtonRef } from '../button-type';
import { PrimaryButtonProps } from './types';

/** @deprecated Instead, please use `<Button type='primary'>` from `@frontend/design-system` */
export const PrimaryButton = forwardRef<ButtonRef, PrimaryButtonProps>(
  ({ children, destructive, size = 'small', trackingId, type = 'button', ...rest }, ref) => {
    const styles = useStyles('PrimaryButton', { destructive, size });

    return (
      <button css={styles} type={type} {...rest} ref={ref} data-trackingid={trackingId}>
        {children}
      </button>
    );
  }
);
