/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const IdIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M4,3.25 L20,3.25 C21.7949254,3.25 23.25,4.70507456 23.25,6.5 L23.25,17.5 C23.25,19.2949254 21.7949254,20.75 20,20.75 L4,20.75 C2.20507456,20.75 0.75,19.2949254 0.75,17.5 L0.75,6.5 C0.75,4.70507456 2.20507456,3.25 4,3.25 Z M4,5.75 C3.58578644,5.75 3.25,6.08578644 3.25,6.5 L3.25,17.5 C3.25,17.9142136 3.58578644,18.25 4,18.25 L20,18.25 C20.4142136,18.25 20.75,17.9142136 20.75,17.5 L20.75,6.5 C20.75,6.08578644 20.4142136,5.75 20,5.75 L4,5.75 Z M6.5,8.5 L17.5,8.5 C18.1903559,8.5 18.75,9.05964406 18.75,9.75 C18.75,10.4403559 18.1903559,11 17.5,11 L6.5,11 C5.80964406,11 5.25,10.4403559 5.25,9.75 C5.25,9.05964406 5.80964406,8.5 6.5,8.5 Z M6.5,13 L11.5,13 C12.1903559,13 12.75,13.5596441 12.75,14.25 C12.75,14.9403559 12.1903559,15.5 11.5,15.5 L6.5,15.5 C5.80964406,15.5 5.25,14.9403559 5.25,14.25 C5.25,13.5596441 5.80964406,13 6.5,13 Z'
    />
  </Icon>
);
