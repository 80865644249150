import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { ListRow, TextLink } from '@frontend/design-system';
import { LocationCountChipForToggle } from '../../Shared';
import * as settingStyles from '../styles';

interface PaymentMethodRowProps {
  info?: string;
  title: string;
  subtitle?: ReactNode;
  switchField: ReactNode;
  learnMoreLink: string;
  enabledLocations: string[];
}

const styles = {
  rowContainer: css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1, 3)};
    flex-wrap: wrap;
  `,
  row: css`
    border-bottom: none;
    padding: 0;
    position: static;
    ${settingStyles.formMaxWidth}
  `,
  title: css`
    font-weight: ${theme.font.weight.regular};
  `,
  subtitle: css`
    display: flex;
    font-size: ${theme.font.size.small};
    color: ${theme.colors.neutral[50]};
  `,
  learnMoreLink: css`
    margin-left: ${theme.spacing(1)};
  `,
};

export const PaymentMethodRow = ({
  title,
  subtitle,
  switchField,
  learnMoreLink,
  enabledLocations,
}: PaymentMethodRowProps) => {
  const { t } = useTranslation('payments');

  return (
    <div css={styles.rowContainer}>
      <ListRow css={styles.row}>
        <ListRow.Content>
          <ListRow.Content.Title css={styles.title}>
            {title}
            <TextLink href={learnMoreLink} target='_blank' css={styles.learnMoreLink}>
              {t('Learn More')}
            </TextLink>
          </ListRow.Content.Title>
          {subtitle && <ListRow.Content.Subtitle css={styles.subtitle}>{subtitle}</ListRow.Content.Subtitle>}
        </ListRow.Content>
        <ListRow.Trail>{switchField}</ListRow.Trail>
      </ListRow>
      <LocationCountChipForToggle enabledLocationIds={enabledLocations} />
    </div>
  );
};
