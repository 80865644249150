import { css } from '@emotion/react';
import { ColorThemeValues, datePickerTheme, datePickerThemeOriginal } from './date-picker-base-field-theme';

const styles = ({ focusColor }: ColorThemeValues) => css`
  outline: none;
  :focus {
    color: ${focusColor};
  }
`;

export const DatePickerBaseFieldStyles = {
  DatePickerBaseFieldIconButtonStyle: styles(datePickerTheme),
};

export const DatePickerBaseFieldStylesOriginal: typeof DatePickerBaseFieldStyles = {
  DatePickerBaseFieldIconButtonStyle: styles(datePickerThemeOriginal),
};
