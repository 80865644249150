import { Route } from '@tanstack/react-location';
import { getPaths } from './get-paths';
import type { Paths, RouteOptions, RoutesGlob } from './route-types';
import { NotFoundPage } from '@frontend/components';

export function getRoutesFromPaths(fileList: RoutesGlob, options: RouteOptions): Route[] {
  const paths = getPaths(fileList);
  return extractRoutesFromPaths(paths, options).sort((a, b) => sortRoutes(a.path ?? '', b.path ?? ''));
}

function extractRoutesFromPaths(paths: Paths, options: RouteOptions) {
  const { AccessControl: GlobalAccessControl, modifyProps } = options;
  return Object.values(paths)
    .sort((a, b) => sortRoutes(a.path, b.path)) // sorting to ensure dynamic paths come after static
    .map((path) => {
      const route: Route = {
        path: path.path,
        loader: (...args) =>
          path.module().then((mod) => (modifyProps ? mod?.loader?.(modifyProps(...args)) : mod?.loader?.(...args))),
        element:
          typeof path.module === 'function'
            ? (props: any) =>
                path
                  .module()
                  .then((mod) => {
                    if (!mod || !mod.default) {
                      return options.missingModuleFallback(path);
                    }

                    if (GlobalAccessControl && !path.originalPath.startsWith('/src/pages/public')) {
                      if (mod.useHasAccess) {
                        const RouteAccessor = ({ children }: { children: React.ReactNode }) => {
                          const { hasAccess, noAccessMessage } = mod.useHasAccess(props);
                          if (!hasAccess && noAccessMessage) {
                            return noAccessMessage;
                          } else if (!hasAccess) {
                            return <NotFoundPage />;
                          } else {
                            return children;
                          }
                        };
                        return (
                          <GlobalAccessControl>
                            <RouteAccessor>
                              <mod.default {...props} />
                            </RouteAccessor>
                          </GlobalAccessControl>
                        );
                      }
                      return (
                        <GlobalAccessControl>
                          <mod.default {...props} />
                        </GlobalAccessControl>
                      );
                    }

                    return <mod.default {...props} />;
                  })
                  .catch((err) => {
                    return options.moduleFetchErrorFallback(err, path);
                  })
            : (() => {
                return (
                  <>
                    {path.isParent
                      ? 'A parent folder requires an index file with a default export. '
                      : 'Module Not Found. Did you forget to default export?'}
                  </>
                );
              })(),
      };

      if (Object.keys(path.children ?? {}).length) {
        route.children = [
          ...extractRoutesFromPaths(path.children, options).sort((a, b) => sortRoutes(a.path ?? '', b.path ?? '')),
        ];
      }

      return route;
    });
}
/**
 * This is important because the ordering of the routes determines what route gets matched.
 *
 * Sort order:
 *  Path with the most segments (slashes separate segments) comes first because it's the most specific
 *    If equal number of segments in the paths:
 *      static files first
 *      dynamic files last
 */
export function sortRoutes(a: string, b: string): number {
  if (a === '/') {
    return -1;
  } else if (b === '/') {
    return 1;
  }

  const segmentsA = getPathSegments(a);
  const segmentsB = getPathSegments(b);

  const trimmedA = a.replace(/^\/+/, '');
  const trimmedB = b.replace(/^\/+/, '');

  if (segmentsA.length !== segmentsB.length) {
    return segmentsB.length - segmentsA.length;
  }

  // Because of the check above, we know at this point that both a and b have the same
  // number of path segments (could be just one segment or multiple segments such as
  // a=="seg1/:segment2/seg3" b=="segment1/segment2/seg3", both contain 3 segments).
  if (segmentsA.length > 1) {
    // If the strings to compare both contain multiple (but the same amount of) segments,
    // then we need to recursively call routeSorter on all of those segments at the same
    // index to get the overall sort value so that the routes are sorted at the segment
    // level (e.g "/seg1/:dynamic" should come after "/seg1/not-dynamic" since static
    // segments should always come after dynamic segments).
    return segmentsA.reduce((sortBalance, segment, index) => sortBalance + sortRoutes(segment, segmentsB[index]), 0);
  }

  if (trimmedA.startsWith('*')) {
    return 1;
  } else if (trimmedB.startsWith('*')) {
    return -1;
  } else if (trimmedA.startsWith(':') && !trimmedB.startsWith(':')) {
    return 1;
  } else if (trimmedB.startsWith(':') && !trimmedA.startsWith(':')) {
    return -1;
  }

  return a.localeCompare(b, undefined, { numeric: true });
}

function getPathSegments(path: string): string[] {
  return path.split('/').filter((segment) => segment.length);
}

export const defaultMissingModuleFallback: RouteOptions['missingModuleFallback'] = () => {
  if (import.meta.env.MODE === 'development') {
    return (
      <>
        Did you forget to do a default export on this page? If not, then try refreshing. A new version of the app might
        be available.
      </>
    );
  }
  console.warn('New version available! Reloading...');
  window.location.reload();
  return <>A page refresh is required to receive the latest update</>;
};

export const defaultModuleFetchErrorFallback: RouteOptions['moduleFetchErrorFallback'] = (err, _path) => {
  console.error(err);
  // This error happens when we delete the current set of assets (because of a new release) while the user is on a page, and then tries to load another page.
  // The better solution would be to reload these routes when a new release has been made, but we don't have a websocket event for that yet.

  // An alternative would be to not delete the old assets, and let the user opt into the new release either through a refresh, or a button
  if (err.toString().includes('Failed to fetch dynamically imported module')) {
    console.warn('New version available! Reloading...');
    window.location.reload();
    return <>A page refresh is required to receive the latest update</>;
  }
  return;
};
