import { LexicalNode } from 'lexical';
import { $createBeautifulMentionNode, $createZeroWidthNode } from 'lexical-beautiful-mentions';
import { TRANSFORMERS, $convertFromMarkdownString, type TextMatchTransformer } from '@lexical/markdown';
import { DynamicFieldAttributes } from '../molecules/dynamic-field-action';
import { $isDynamicFieldNode, DynamicFieldNode } from '../nodes/dynamic-field-node';
import { DYNAMIC_FIELD_TRIGGER } from '../constants';

export const dynamicFieldTransform = (fields: DynamicFieldAttributes[]): TextMatchTransformer => ({
  dependencies: [DynamicFieldNode],
  export: (node: LexicalNode) => {
    if (!$isDynamicFieldNode(node)) {
      return null;
    }
    const textContent = node.getValue();
    return `${textContent}`;
  },
  importRegExp: /{{(.*?)}}/,
  regExp: /{{(.*?)}}/,
  replace: (textNode, match) => {
    const content = match[1];
    const zeroWidthNode = $createZeroWidthNode();

    const value = `{{${content}}}`;
    const dynamicField = fields.find((field) => field.symbol === value);
    const variableNode = $createBeautifulMentionNode(DYNAMIC_FIELD_TRIGGER, value, {
      label: dynamicField?.label ?? '',
      val: dynamicField?.val ?? '',
    });
    textNode.replace(variableNode).insertBefore(zeroWidthNode);
  },
  trigger: DYNAMIC_FIELD_TRIGGER,
  type: 'text-match',
});

/**
 * Note: This can be used only inside the context of the RichTextEditor
 */
export const $convertFromMarkdownStringWithDF = (content: string, dynamicFields: DynamicFieldAttributes[]) => {
  $convertFromMarkdownString(content, [...TRANSFORMERS, dynamicFieldTransform(dynamicFields)]);
  return null;
};
