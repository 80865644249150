export const sanitizePhoneNumber = (txt: string) => txt.replace(/\D/g, '');

export const isPhoneNumber = (txt = '') => {
  return /^[0-9]{10,11}$/.test(sanitizePhoneNumber(txt));
};

export const isPartialPhoneNumber = (txt: string) => {
  const sanitizedNumber = sanitizePhoneNumber(txt);
  return sanitizedNumber.length < 10 && sanitizedNumber.length > 0;
};
