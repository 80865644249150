import { spacing } from './spacing';
import { fontSize } from './font';

const colors = {
  white: 'var(--colors-white)',
  black: 'var(--colors-black)',
  neutral90: 'var(--colors-neutral-90)',
  neutral80: 'var(--colors-neutral-80)',
  neutral70: 'var(--colors-neutral-70)',
  neutral60: 'var(--colors-neutral-60)',
  neutral50: 'var(--colors-neutral-50)',
  neutral40: 'var(--colors-neutral-40)',
  neutral30: 'var(--colors-neutral-30)',
  neutral20: 'var(--colors-neutral-20)',
  neutral10: 'var(--colors-neutral-10)',
  neutral5: 'var(--colors-neutral-5)',
  primary80: 'var(--colors-primary-80)',
  primary70: 'var(--colors-primary-70)',
  primary50: 'var(--colors-primary-50)',
  primary10: 'var(--colors-primary-10)',
  primary5: 'var(--colors-primary-5)',
  seaweed90: 'var(--colors-seaweed-90)',
  seaweed80: 'var(--colors-seaweed-80)',
  seaweed70: 'var(--colors-seaweed-70)',
  seaweed60: 'var(--colors-seaweed-60)',
  seaweed50: 'var(--colors-seaweed-50)',
  seaweed40: 'var(--colors-seaweed-40)',
  seaweed30: 'var(--colors-seaweed-30)',
  seaweed20: 'var(--colors-seaweed-20)',
  seaweed10: 'var(--colors-seaweed-10)',
  seaweed5: 'var(--colors-seaweed-5)',
  eggplant90: 'var(--colors-eggplant-90)',
  eggplant80: 'var(--colors-eggplant-80)',
  eggplant70: 'var(--colors-eggplant-70)',
  eggplant60: 'var(--colors-eggplant-60)',
  eggplant50: 'var(--colors-eggplant-50)',
  eggplant40: 'var(--colors-eggplant-40)',
  eggplant30: 'var(--colors-eggplant-30)',
  eggplant20: 'var(--colors-eggplant-20)',
  eggplant10: 'var(--colors-eggplant-10)',
  eggplant5: 'var(--colors-eggplant-5)',
  criticalPressed: 'var(--colors-status-criticalPressed)',
  criticalHover: 'var(--colors-status-criticalHover)',
  critical: 'var(--colors-status-critical)',
  disabled: 'var(--colors-status-disabled)',
  warning: 'var(--colors-status-warning)',
  successPressed: 'var(--colors-status-successPressed)',
  successHover: 'var(--colors-status-successHover)',
  success: 'var(--colors-status-success)',
} as const;

export const weaveTheme = {
  colors,
  spacing,
  shadows: {
    none: 'none',
    light: 'var(--shadow-light)',
    heavy: 'var(--shadow-heavy)',
    footer: 'var(--shadow-footer)',
    header: 'var(--shadow-header)',
    hover: 'var(--shadow-hover)',
  },
  borderRadius: {
    none: 'none',
    small: 'var(--border-radius-small)',
    medium: 'var(--border-radius-medium)',
  },
  fontSize,
  font: {
    family: 'var(--font-family)',
    colors: {
      primary: colors.primary50,
      default: colors.neutral90,
      light: colors.neutral60,
      disabled: colors.neutral40,
      white: colors.white,
      error: colors.critical,
      success: colors.success,
      warn: colors.warning,
    },
    lineHeight: 'var(--font-line-height)',
    size: {
      large: fontSize(16),
      medium: fontSize(14),
      small: fontSize(12),
      h1: fontSize(24),
      h2: fontSize(20),
    },
    weight: {
      regular: 'var(--font-weight-regular)',
      semibold: 'var(--font-weight-semibold)',
      bold: 'var(--font-weight-bold)',
    },
  },
  zIndex: {
    background: -1,
    low: 1,
    middle: 5,
    high: 10,
    higher: 100,
    highest: 1000,
    // RESERVED: not for normal usage
    modals: 10000,
    hints: 11000,
    alerts: 100000,
  },
} as const;

export type WeaveTheme = typeof weaveTheme;
export type WeaveThemeColors = keyof typeof weaveTheme.colors;
export type WeaveThemeShadows = keyof typeof weaveTheme.shadows;
export type WeaveThemeBorderRadius = keyof typeof weaveTheme.borderRadius;
