import { css } from '@emotion/react';
import type { HeadingStylesProps, HeadingThemeValues } from './heading-theme';
import { getHeadingTheme, getHeadingThemeOriginal } from './heading-theme';

const style = ({ fontColorStyle, fontFamily, fontSize, fontWeight, lineHeight, alignment }: HeadingThemeValues) =>
  css`
    ${fontColorStyle}
    ${fontFamily}
    ${alignment}
    font-size: ${fontSize};
    font-weight: ${fontWeight};
    line-height: ${lineHeight};
  `;

export const HeadingStyles = {
  Heading: (props: HeadingStylesProps) => style(getHeadingTheme(props)),
};
export const HeadingStylesOriginal = {
  Heading: (props: HeadingStylesProps) => style(getHeadingThemeOriginal(props)),
};
