import { css } from '@emotion/react';
import { transparentize } from '../../../../helpers';
import type { SwitchThemeValues } from './switch-theme';
import { switchTheme, switchThemeOriginal } from './switch-theme';
import { BoolInputProps } from '../../layouts/types';

type SwitchProps = BoolInputProps<'switch'>;

type SwitchStyleProps = Pick<SwitchProps, 'disabled' | 'active' | 'error' | 'value' | 'indeterminate'>;

const style = (
  {
    containerBackgroundColor,
    disabledContainerBackgroundColor,
    indeterminateBackgroundColor,
    activeBorderColor,
    disabledBorderColor,
    disabledFalseActiveTrueBorderColor,
    focusRing,
    thumbEnabledColor,
    thumbDisabledColor,
    errorColor,
    iconZIndex,
  }: SwitchThemeValues,
  { disabled, active, error, value, indeterminate }: SwitchStyleProps
) => [
  css`
    min-width: 40px;
    width: 40px;
    height: 24px;
    background: ${indeterminate
      ? indeterminateBackgroundColor
      : value
      ? containerBackgroundColor
      : disabledContainerBackgroundColor};
    border: 1px solid ${disabledBorderColor};
    border-radius: 12px;
    position: relative;
    outline: transparent 2px solid;
    outline-offset: 2px;
    transition: outline 200ms ease-out;

    .icon {
      transition: opacity 200ms ease-in-out;
      opacity: ${indeterminate ? 1 : 0};
      top: 10%;
      left: calc(50% - 9px);
      position: absolute;
      z-index: ${iconZIndex};
      cursor: pointer;
      height: 18px;
      width: 18px;
    }

    :focus-within {
      outline-color: ${focusRing};
    }

    ::after {
      content: '';
      width: 18px;
      height: 18px;
      background: ${thumbDisabledColor};
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 3px;
      transform: translateY(-50%);
      transition: background 200ms ease-out, left 200ms ease-in-out;
    }
  `,
  !disabled &&
    active &&
    css`
      border-color: ${disabledFalseActiveTrueBorderColor};

      ::before {
        transform: scale(1);
      }
    `,
  value &&
    css`
      border-color: ${activeBorderColor};

      ::before {
        background: ${transparentize(0.7, focusRing)};
      }

      ::after {
        background: ${thumbEnabledColor};
        left: calc(100% - 21px);
      }
    `,
  indeterminate &&
    css`
      ::after {
        left: calc(50% - 9px);
      }
    `,
  disabled &&
    css`
      opacity: 0.4;
    `,
  !disabled &&
    error &&
    css`
      border-color: ${errorColor};

      ::before {
        background: ${transparentize(0.8, errorColor)};
        border-color: ${errorColor};
      }

      ::after {
        background: ${transparentize(0.6, errorColor)};
      }
    `,
];

export const SwitchStyles = {
  Switch: (switchProps: SwitchStyleProps) => style(switchTheme, switchProps),
};

export const SwitchStylesOriginal: typeof SwitchStyles = {
  Switch: (switchProps: SwitchStyleProps) => style(switchThemeOriginal, switchProps),
};
