import { contextFactory } from '../../hooks';

export type SwitchChipGroupContext = {
  value: string[];
  toggleValue: (value: string) => void;
  disabled?: boolean;
};

export const [SwitchChipGroupContext, useSwitchChipGroupContext] = contextFactory<SwitchChipGroupContext>(
  'useSwitchChipGroupContext must be used within a SwitchChipGroupProvider'
);
