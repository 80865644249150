import { theme } from '@frontend/theme';
import { theme as originalTheme } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../../component-theme/theme-types';

export type TabStyleValues = {
  background: WeaveThemeValues['Color'];
  color: WeaveThemeValues['Color'];
  fontFamily: WeaveThemeValues['FontFamily'];
  fontSize: ReturnType<WeaveThemeValues['FontSize']>;
  fontWeight: WeaveThemeValues['FontWeight'];
  hoverAndFocusBackground: WeaveThemeValues['Color'];
  hoverAndFocusColor: WeaveThemeValues['Color'];
  selectedBackground?: WeaveThemeValues['Color'];
  selectedFontColor?: WeaveThemeValues['Color'];
};

export type TabBarStyleValues = {
  afterBackground: WeaveThemeValues['Color'];
};

export const tabTheme: TabStyleValues = {
  background: theme.colors.neutral80,
  color: theme.font.colors.light,
  fontFamily: theme.font.family,
  fontSize: theme.font.size.medium,
  fontWeight: theme.font.weight.bold,
  hoverAndFocusBackground: theme.colors.neutral5,
  hoverAndFocusColor: theme.colors.neutral80,
  selectedFontColor: theme.colors.primary50,
};

export const originalTabTheme: TabStyleValues = {
  background: originalTheme.colors.weaveBlue,
  color: originalTheme.font.colors.light,
  fontFamily: originalTheme.font.family,
  fontSize: originalTheme.font.size.medium,
  fontWeight: originalTheme.font.weight.bold,
  hoverAndFocusBackground: originalTheme.colors.gray200,
  hoverAndFocusColor: originalTheme.colors.gray600,
  selectedFontColor: originalTheme.colors.pressedBlue,
};

export const tabBarTheme: TabBarStyleValues = {
  afterBackground: theme.colors.primary50,
};

export const originalTabBarTheme: TabBarStyleValues = {
  afterBackground: originalTheme.colors.weaveBlue,
};
