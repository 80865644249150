import { userColors, contactColors } from './constants';

/**
 * For reference: This is using the Fowler–Noll–Vo hash algorithm
 */
const hashCode = (str: string): number => {
  // That's the FNV offset basis
  let hash = 2166136261;
  for (let i = 0; i < str.length; i++) {
    hash ^= str.charCodeAt(i);
    hash += (hash << 1) + (hash << 4) + (hash << 7) + (hash << 8) + (hash << 24);
  }
  return hash >>> 0;
};

export const getColor = (identifier: string, isUser: boolean) => {
  const colors = isUser ? userColors : contactColors;
  const hash = hashCode(identifier);
  return colors[hash % colors.length];
};
