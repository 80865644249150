/**
 * Replacement of polished lib
 * https://bit.cloud/styled-components/polished/
 */

type Fn = (...args: any[]) => any;

function curried(f: Fn, length: number, acc: Array<any>): Fn {
  return function fn(this: any, ...args: any[]) {
    const combined = acc.concat(Array.prototype.slice.call(args));
    return combined.length >= length ? f.apply(this, combined) : curried(f, length, combined);
  };
}

export function curry(f: Fn): Fn {
  return curried(f, f.length, []);
}
