import { useEffect, useRef } from 'react';
import { TextareaRef, TextareaSelection } from './auto-grow-textarea';
import { genUID } from '@frontend/design-system';
import { F } from 'ts-toolbelt';

type onChangeHandler = (value: string) => void;

interface UseHandlersWithoutTagsParams {
  value: string;
  id?: string;
  setShowEmojiPicker: (show: boolean) => void;
  onChange: onChangeHandler;
}

const DEFAULT_SELECTION: TextareaSelection = {
  selectionStart: 0,
  selectionEnd: 0,
};

export const useHandlerWithoutTags = ({ value, id }: UseHandlersWithoutTagsParams) => {
  const idRef = useRef<string>(id || `${genUID()}`);
  const inputRef = useRef<TextareaRef>(null);
  const selectionRef = useRef<TextareaSelection>(DEFAULT_SELECTION);

  // listen for global selection changes scoped to textarea.
  // tracks cursor without adding multiple events to textarea
  const handlerRef = useRef<F.Function>(() => {
    const activeElement = window.document.activeElement;
    if (inputRef.current && activeElement && activeElement.id === idRef.current) {
      selectionRef.current = inputRef.current.getSelection();
    }
  });

  useEffect(() => {
    const listener = (e: any) => handlerRef.current!(e);
    window.document.addEventListener('selectionchange', listener);
    return () => {
      window.document.removeEventListener('selectionchange', listener);
    };
  }, []);

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const insertText = (text: string, onChange?: onChangeHandler) => {
    const { selectionStart, selectionEnd } = selectionRef.current;
    const chars = value.split('');
    const prefix = selectionStart > 0 && /\S/.test(chars[selectionStart - 1]) ? ' ' : '';

    // remove a trailing space if present (emojis seem to always have extra space on the right)
    if (/\s/.test(chars[selectionEnd])) {
      chars.splice(selectionEnd, 1);
    }
    // inject the emoji in place instead of always at the end of the value
    if (selectionStart === selectionEnd) {
      chars.splice(selectionStart, 0, `${prefix}${text}`);
    } else {
      chars.splice(selectionStart, selectionEnd - selectionStart, `${prefix}${text}`);
    }

    onChange?.(chars.join(''));

    // update the cursor to the position right after the injected emoji
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.setSelection(selectionStart + text.length + prefix.length);
        focusInput();
      }
    }, 0);
  };

  return { focusInput, insertText, inputId: idRef.current, inputRef };
};
