import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { animated, useTransition } from 'react-spring';
import { theme } from '@frontend/theme';

// note: don't add padding or margin here, it will
// mess with the animation.
const animatedContainer = css`
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.white};
  overflow: hidden;
`;

const spacingContainer = css`
  padding: ${theme.spacing(2, 3)};
`;

const defaultConfig = { mass: 1, tension: 200, friction: 26 };

type FilterGroupProps = {
  active: boolean;
  remHeight: number;
  children: ReactNode;
};

export const FilterGroup = ({ active, children, remHeight, ...rest }: FilterGroupProps) => {
  const transitions = useTransition(active, null, {
    from: { maxHeight: '0rem', overflow: 'hidden', opacity: 0.5 },
    enter: { maxHeight: remHeight + 'rem', opacity: 1, overflow: 'visible' },
    leave: { maxHeight: '0rem', overflow: 'hidden', opacity: 0.5 },
    config: defaultConfig,
  });

  return (
    <>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div key={key} style={props} css={animatedContainer}>
              <div css={spacingContainer} {...rest}>
                {children}
              </div>
            </animated.div>
          )
      )}
    </>
  );
};
