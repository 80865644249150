import { createContext, useContext, useEffect, useMemo, useState, ReactNode, useRef } from 'react';
import { KeyNames } from '../../../constants';
import { usePortal, UsePortalProps, useTabbable, useUid } from '../../../hooks';
import { PortalDivAttributes } from '../../../hooks';
import type { ModalControlModalProps } from './use-modal-control';

type AriaModalValue = 'true' | 'false';

export type ModalProviderContextType = Pick<ModalControlModalProps, 'onClose'> & {
  containerProps: {
    role: string;
    'aria-labelledby': string;
    'aria-describedby': string;
    'aria-modal': AriaModalValue;
  };
  descriptionId: string;
  loadingState: LoadingState;
  setLoading: (loading: boolean, message?: string) => void;
  titleId: string;
  uid: string;
  actionStateChanged: { primary: boolean; secondary: boolean };
  setActionStateChanged: ({ primary, secondary }: { primary: boolean; secondary: boolean }) => void;
};

export const ModalProviderContext = createContext<ModalProviderContextType>({} as ModalProviderContextType);

export type ModalProviderProps = Pick<ModalControlModalProps, 'onClose'> &
  Partial<Pick<UsePortalProps, 'prepend' | 'mountTarget'>> & {
    children: ReactNode;
    className?: string;
    containerAttributes?: PortalDivAttributes;
    disableCloseOnEscape?: boolean;
    timeout?: number;
  };

type LoadingState = {
  show: boolean;
  message?: string;
};

// Global config for modal settings
// Allows making global changes to where/how modals mount
type GlobalModalConfig = {
  mountTarget?: string;
  prepend?: boolean;
};

let GLOBAL_MODAL_CONFIG: GlobalModalConfig = {};

export function configureModalSettings(settings: GlobalModalConfig) {
  GLOBAL_MODAL_CONFIG = settings;
}

const getMountTarget = (selector?: string): HTMLElement => {
  const target = selector || GLOBAL_MODAL_CONFIG.mountTarget;
  if (target) {
    return document.querySelector(target) ?? document.body;
  }
  return document.body;
};

export const ModalProvider = ({
  children,
  className,
  containerAttributes,
  disableCloseOnEscape,
  onClose,
  mountTarget,
  prepend,
  timeout = 0,
}: ModalProviderProps) => {
  const uid = useUid();
  const [loadingState, setLoadingState] = useState<LoadingState>({
    show: false,
    message: '',
  });
  const [actionStateChanged, setActionStateChanged] = useState({ primary: false, secondary: false });
  const { Portal, portalRef } = usePortal({
    attributes: {
      ...containerAttributes,
      id: `modal=${uid.current}`,
      tabIndex: -1,
    },
    className,
    mountTarget: mountTarget || GLOBAL_MODAL_CONFIG.mountTarget,
    prepend: prepend ?? GLOBAL_MODAL_CONFIG.prepend,
  });

  useTabbable({ ref: portalRef, timeout, reset: actionStateChanged });

  const contextValue = useMemo(() => {
    const descriptionId = `modal=${uid.current}-desc`;
    const titleId = `modal=${uid.current}-title`;

    return {
      containerProps: {
        role: 'dialog',
        'aria-labelledby': titleId,
        'aria-describedby': descriptionId,
        'aria-modal': 'true' as AriaModalValue,
      },
      descriptionId,
      loadingState,
      onClose,
      setLoading: (loading: boolean, message?: string) => {
        setLoadingState({ show: loading, message });
      },
      titleId,
      uid: uid.current,
      actionStateChanged,
      setActionStateChanged,
    };
  }, [onClose, loadingState, setLoadingState]);

  const overflowValue = useRef<string>();

  // setup and cleanup
  useEffect(() => {
    const container = getMountTarget(mountTarget);
    // disable container scrolling to avoid funkity funk
    overflowValue.current = container.style.overflow;
    container.style.overflow = 'hidden';
    // focus first on the modal (reads title and description to screen readers)
    // useTabbable will trap subsequent tabbing to within the modal,
    // but the modal itself should only be focused on mount
    setTimeout(() => {
      portalRef.current?.focus();
    }, timeout);

    return () => {
      // when two modals are open, we don't want to re-enable scrolling until the all modals are closed
      if (!container.querySelectorAll(':scope > [id^="modal="]').length) {
        // re-enable container scrolling
        container.style.overflow = overflowValue.current === 'hidden' ? '' : overflowValue.current ?? '';
      }
    };
  }, []);

  useEffect(() => {
    const closeOnEsc = (event) => {
      if (event.key === KeyNames.Escape) {
        event.stopPropagation();
        onClose();
      }
    };
    // prevent esc to close manually, or while in a loading state
    if (!disableCloseOnEscape && !loadingState.show) {
      portalRef.current?.addEventListener('keydown', closeOnEsc);
    }
    return () => {
      portalRef.current?.removeEventListener('keydown', closeOnEsc);
    };
  }, [disableCloseOnEscape, loadingState.show]);

  return (
    <ModalProviderContext.Provider value={contextValue}>
      <Portal>{children}</Portal>
    </ModalProviderContext.Provider>
  );
};

export function useModalContext() {
  const context = useContext(ModalProviderContext);
  if (context === undefined) {
    throw new Error('useModalContext must be used inside a ModalProvider.');
  }
  return context;
}

export function useModalContextControl() {
  const context = useContext(ModalProviderContext);
  if (context === undefined) {
    throw new Error('useModalContext must be used inside a ModalProvider.');
  }
  return {
    closeModal: context.onClose,
    setLoading: context.setLoading,
    loading: context.loadingState.show,
    message: context.loadingState.message,
  };
}

export function useModalLoadingState() {
  const context = useContext(ModalProviderContext);
  if (context === undefined) {
    throw new Error('useModalLoadingState must be used inside a ModalProvider.');
  }
  const { loadingState, setLoading } = context;
  return {
    loading: loadingState.show,
    message: loadingState.message,
    setLoading,
  };
}
