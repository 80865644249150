import { css } from '@emotion/react';
import type { TabBarStyleValues, TabStyleValues } from './common-theme';
import {
  originalTabBarTheme,
  originalTabTheme,
  tabBarTheme,
  tabTheme,
  scrollArrowTheme,
  originalScrollArrowTheme,
  ScrollArrowStyleValues,
} from './common-theme';

type TabBarStyleProps = { fullWidth?: boolean; isScrollable?: boolean };

export const tabBarStyle = (
  { slidingBorderColor, borderBottomColor, spacing }: TabBarStyleValues,
  { fullWidth, isScrollable }: TabBarStyleProps
) => css`
  width: 100%;
  min-height: 40px;
  height: 40px;
  max-width: 100%;
  display: flex;
  overflow: auto hidden;
  position: relative;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
  :focus {
    outline: none;
  }

  ${!isScrollable &&
  `::after {
    content: '';
    position: absolute;
    bottom: 0px;
    border-bottom: 1px solid ${borderBottomColor};
    pointer-events: none;
    left: 0;
    width: 100%;
  }`}

  ::-webkit-scrollbar {
    display: none;
  }

  .sliding__border {
    border-bottom: 2px solid ${slidingBorderColor};
    position: absolute;
    bottom: ${isScrollable ? '-1' : '0'}px;
    z-index: 1;
    width: 100%;
  }

  a,
  button {
    ${fullWidth && `flex: 1 0 auto;`}
    padding: 0 ${spacing(2)};
    flex-shrink: 0;
    ${isScrollable && `border-bottom: 1px solid ${borderBottomColor};`}
  }
`;

export const tabItemStyle = ({
  fontFamily,
  fontSize,
  color,
  hoverAndFocusBackground,
  fontWeight,
  background,
  selectedBackground,
  hoverAndFocusColor,
  disabledFontColor,
}: TabStyleValues) =>
  css`
    font-family: ${fontFamily};
    font-size: ${fontSize};
    color: ${color};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: center;
    position: relative;
    text-decoration: none;
    transition: background 0.25s ease-out;

    :before {
      content: attr(data-text);
      content: attr(data-text) / '';
      height: 0;
      visibility: hidden;
      overflow: hidden;
      user-select: none;
      pointer-events: none;
      font-family: ${fontFamily};
      font-size: ${fontSize};
      font-weight: ${fontWeight};
      @media speech {
        display: none;
      }
    }

    :hover:not(:disabled),
    :focus {
      background: ${hoverAndFocusBackground};
      color: ${hoverAndFocusColor};
      outline: none;
    }

    &.selected,
    &[aria-selected='true'] {
      font-weight: ${fontWeight};
      background: ${selectedBackground};
      :focus::after {
        background: ${background};
      }
    }
    :disabled {
      color: ${disabledFontColor};
    }
  `;

type ScrollArrowStyleProps = { disabled: boolean; direction: 'left' | 'right' };

export const scrollArrowStyle = (
  { spacing, disabledColor, leftShadow, rightShadow }: ScrollArrowStyleValues,
  { disabled, direction }: ScrollArrowStyleProps
) => {
  return css`
    position: relative;
    ::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: -1px;
      width: 32px;
      transition: opacity 0.3s;
      pointer-events: none;
      opacity: 0;
      ${direction === 'left'
        ? `
        right: 0;
        transform: translateX(100%);
        box-shadow: ${leftShadow};`
        : `
        left: 0;
        transform: translateX(-100%);
        box-shadow: ${rightShadow};
        `}
    }

    ${!disabled &&
    `::after {
        opacity: 1;
    }`}

    button {
      ${scrollingArrowsDefaultStyle}
      padding: 0 ${spacing(0.5)};
    }

    svg {
      ${disabled && `color: ${disabledColor};`}
      transition: 0.2s ease;
    }
  `;
};

const scrollingArrowsDefaultStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const TabsStyles = {
  Tabs: {
    tabItem: tabItemStyle(tabTheme),
    tabBar: (tabBarProps: TabBarStyleProps) => tabBarStyle(tabBarTheme, tabBarProps),
    scrollArrowStyle: (scrollArrowProps: ScrollArrowStyleProps) => scrollArrowStyle(scrollArrowTheme, scrollArrowProps),
  },
};

export const TabsStylesOriginal: typeof TabsStyles = {
  Tabs: {
    tabItem: tabItemStyle(originalTabTheme),
    tabBar: (tabBarProps: TabBarStyleProps) => tabBarStyle(originalTabBarTheme, tabBarProps),
    scrollArrowStyle: (scrollArrowProps: ScrollArrowStyleProps) =>
      scrollArrowStyle(originalScrollArrowTheme, scrollArrowProps),
  },
};
