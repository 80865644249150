import { type SVGProps } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import type { IconName } from './icon-constant';

export type IconColorType =
  | 'primary'
  | 'default'
  | 'light'
  | 'subdued'
  | 'disabled'
  | 'white'
  | 'error'
  | 'success'
  | 'warn';

const defaultIconSizes = {
  tiny: 10,
  small: 16,
  large: 24,
} as const;

type IconSize = keyof typeof defaultIconSizes;

function determineIconSize(name: IconName, size?: number): number {
  const sizeSuffix = name.match(/-(tiny|small)$/)?.[1] as IconSize | undefined;
  const defaultSize: IconSize = sizeSuffix ? sizeSuffix : 'large';
  return size ?? defaultIconSizes[defaultSize];
}

const idPrefix = 'weaveSpriteIcon-';

export function Icon({
  name,
  size,
  color,
  ...props
}: Omit<SVGProps<SVGSVGElement>, 'color'> & {
  name: IconName;
  size?: number;
  color?: IconColorType;
}) {
  const actualSize = determineIconSize(name, size);
  const iconColor = color ? theme.font.colors[color] : undefined;

  return (
    <svg
      width={actualSize}
      height={actualSize}
      css={[
        css`
          color: currentColor;
        `,
        iconColor &&
          css`
            color: ${iconColor};
          `,
      ]}
      {...props}
    >
      <use href={`#${idPrefix}${name}`} />
    </svg>
  );
}
