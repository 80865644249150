import { css } from '@emotion/react';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';

const cardWidthBase = 98;

export const styles = {
  ul: css`
    overflow: auto;
    transition: max-height 0.3s ease;
    width: 100%;

    &.rows-comparison-ul {
      display: flex;

      @media screen and (min-width: ${breakpoints.medium.min}px) {
        display: block;
      }

      .data-cards-wrapper {
        flex-direction: column;

        @media screen and (min-width: ${breakpoints.medium.min}px) {
          flex-direction: row;
        }
      }
    }
  `,

  leaderboardRow: css`
    display: flex;
    position: relative;

    &.rows-comparison-li:nth-of-type(even) {
      .card-space,
      .sticky-content {
        background-color: ${theme.colors.neutral5};
      }
    }

    &.columns-comparison-li {
      .data-cards-wrapper {
        @media screen and (max-width: ${breakpoints.small.max}px) {
          .card-space:nth-of-type(even) {
            background-color: ${theme.colors.neutral5};
          }
        }

        @media screen and (min-width: ${breakpoints.medium.min}px) {
          .card-space:nth-of-type(odd) {
            background-color: ${theme.colors.neutral5};
          }
        }
      }
    }

    .sticky-content {
      align-items: center;
      background-color: ${theme.colors.white};
      display: flex;
      left: 0;
      padding: ${theme.spacing(1.5)};
      position: sticky;
      z-index: 1;

      .serial {
        min-width: ${theme.spacing(3)};
        width: ${theme.spacing(3)};
      }
    }

    .name {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      > p {
        flex: 0;
        min-height: ${theme.spacing(2.5)};
      }

      @media screen and (min-width: ${breakpoints.medium.min}px) {
        width: 162px;
      }
    }
  `,

  dataCardsWrapper: css`
    display: flex;
    flex: 1;

    .card-space {
      flex: 1;
      min-width: 180px;
      padding: ${theme.spacing(1, 1.5)};
      width: calc(100vw - ${cardWidthBase}px);

      @media screen and (min-width: ${breakpoints.small.min}px) {
        width: calc(50vw - ${cardWidthBase / 2}px);
      }

      @media screen and (min-width: ${breakpoints.medium.min}px) {
        padding: ${theme.spacing(1.5)};
        width: auto;
      }
    }
  `,

  mainWrapper: css`
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    position: relative;

    .toggle-more {
      align-items: center;
      display: flex;
      gap: ${theme.spacing(0.5)};
      margin-top: ${theme.spacing(1)};
    }
  `,

  columnsHeader: css`
    .sort-button {
      align-items: center;
      display: flex;
      flex-direction: row;
      font-size: ${theme.font.size.medium};
      gap: ${theme.spacing(0.5)};
      justify-content: center;
    }
  `,

  scrollButton: css`
    bottom: 0;
    margin: auto;
    position: absolute;
    top: 0;

    &.left-scroll {
      left: 0;
    }

    &.right-scroll {
      right: 0;
    }
  `,
};
