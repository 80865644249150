import { useState } from 'react';
import { css } from '@emotion/react';
import { PaymentPlanConfirmationStatus, PaymentPlanDetailsResponse } from '@frontend/api-payment-plans';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Modal, Text, TextButton, useModalControl } from '@frontend/design-system';
import { useActivatePlan } from '../../../hooks';
import { SendConfirmationEmailModal } from '../../PaymentPlanModals';
import { AlertBanner } from '../atoms';

interface ActivatePlanAlertProps {
  planDetails: PaymentPlanDetailsResponse | undefined;
}

const styles = {
  alertMessageContainer: css`
    display: flex;
    flex-direction: column;
    align-items: start;
  `,
  alertActionButtonBar: css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(2, 2)};
  `,
  alertActionBtn: css`
    color: ${theme.font.colors.primary};
    padding: 0;
  `,
  modalTitle: css`
    margin-bottom: ${theme.spacing(3)};
  `,
  modalBody: css`
    padding: ${theme.spacing(1, 3)};
  `,
  modalText: css`
    margin: ${theme.spacing(2, 0)};
    &:first-of-type {
      margin-top: 0;
    }
  `,
  modalTextBtn: css`
    font-size: ${theme.font.size.large};
  `,
};

export const ActivatePlanAlert = ({ planDetails }: ActivatePlanAlertProps) => {
  const { t } = useTranslation('payments');
  const {
    modalProps: activateModalProps,
    triggerProps: activateModalTriggerProps,
    closeModal: closeActivateModal,
  } = useModalControl();
  const [showSendConfirmationModal, setShowSendConfirmationModal] = useState(false);
  const { activatePlan, activatingPlan } = useActivatePlan({ planDetails });

  const { confirmationDetails } = planDetails ?? {};
  const { confirmationStatus = PaymentPlanConfirmationStatus.Pending, isConfirmationLinkExpired } =
    confirmationDetails ?? {};
  const showBanner =
    confirmationStatus === PaymentPlanConfirmationStatus.Pending ||
    confirmationStatus === PaymentPlanConfirmationStatus.Deny;

  if (!showBanner) return null;
  return (
    <section>
      {confirmationStatus === PaymentPlanConfirmationStatus.Pending ? (
        <AlertBanner variant={isConfirmationLinkExpired ? 'error' : 'info'} icon='info'>
          <div css={styles.alertMessageContainer}>
            {isConfirmationLinkExpired
              ? t('Link expired while waiting for customer authorization to activate plan.')
              : t('Waiting for customer authorization to activate plan.')}
            <div css={styles.alertActionButtonBar}>
              <TextButton css={styles.alertActionBtn} {...activateModalTriggerProps}>
                {t('Activate Now')}
              </TextButton>
              {isConfirmationLinkExpired && (
                <TextButton css={styles.alertActionBtn} onClick={() => setShowSendConfirmationModal(true)}>
                  {t('Resend confirmation email')}
                </TextButton>
              )}
            </div>
          </div>
        </AlertBanner>
      ) : (
        confirmationStatus === PaymentPlanConfirmationStatus.Deny && (
          <AlertBanner icon='info'>
            <div css={styles.alertMessageContainer}>
              {t('Customer has denied authorization')}
              <TextButton css={styles.alertActionBtn} onClick={() => setShowSendConfirmationModal(true)}>
                {t('Resend confirmation email')}
              </TextButton>
            </div>
          </AlertBanner>
        )
      )}
      <Modal {...activateModalProps} maxWidth={608}>
        <Modal.Header onClose={closeActivateModal} css={styles.modalTitle}>
          {t('Activate Payment Plan')}
        </Modal.Header>
        <Modal.Body css={styles.modalBody}>
          <Text size='large' weight='bold' css={styles.modalText}>
            {t('You are required to receive confirmation from the customer before activating a payment plan')}
          </Text>
          <Trans t={t}>
            <Text css={styles.modalText}>
              By manually activating this payment plan you are acknowledging that you have received authorization from
              the customer and are keeping your own record of this authorization.
            </Text>
            <Text css={styles.modalText}>
              If you have not received authorization from the customer click “Send confirmation link” below. The
              customer will receive an email and the payment plan will be activated automatically after receiving
              customer authorization.
            </Text>
          </Trans>
          <TextButton
            css={[styles.alertActionBtn, styles.modalTextBtn]}
            onClick={() => setShowSendConfirmationModal(true)}
          >
            {t('Send confirmation link')}
          </TextButton>
        </Modal.Body>
        <Modal.Actions
          onSecondaryClick={() => {
            return true;
          }}
          onPrimaryClick={async () => {
            if (await activatePlan()) return true;
            else return false;
          }}
          disablePrimary={activatingPlan}
          primaryLabel={t('Activate Payment Plan')}
          secondaryLabel={t('Cancel')}
        />
      </Modal>
      <SendConfirmationEmailModal
        planDetails={planDetails}
        show={showSendConfirmationModal}
        onClose={() => setShowSendConfirmationModal(false)}
      />
    </section>
  );
};
