import { css } from '@emotion/react';
import type { FlyoutMenuItemThemeValues, IconStyleThemeValues } from './flyout-menu-item-theme';
import {
  flyoutMenuItemTheme,
  flyoutMenuItemThemeOriginal,
  iconStyleTheme,
  iconStyleThemeOriginal,
} from './flyout-menu-item-theme';

type LiStyleProps = { disabled?: boolean };

const liStyle = (
  { spacing, backgroundColor, borderColor, disabledColor }: FlyoutMenuItemThemeValues,
  props?: LiStyleProps
) => [
  css`
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: ${spacing(2)};
    text-transform: capitalize;
    transition: background-color 250ms ease-out;

    :hover,
    :focus {
      background-color: ${backgroundColor};
      outline: none;
    }

    :not(:last-of-type) {
      border-bottom: 1px solid ${borderColor};
    }
  `,
  props?.disabled &&
    css`
      background-color: ${disabledColor};
      cursor: default;
      pointer-events: none;
      opacity: 0.5;
      :hover,
      :focus {
        background-color: ${disabledColor};
        outline: none;
      }
    `,
];

const iconStyle = ({ spacing }: IconStyleThemeValues) => css`
  line-height: 1;
  margin-right: ${spacing(1)};
`;

export const FlyoutMenuItemStyles = {
  FlyoutMenuItem: {
    liStyle: (props: LiStyleProps) => liStyle(flyoutMenuItemTheme, props),
    iconStyle: iconStyle(iconStyleTheme),
  },
};
export const FlyoutMenuItemStylesOriginal: typeof FlyoutMenuItemStyles = {
  FlyoutMenuItem: {
    liStyle: (props: LiStyleProps) => liStyle(flyoutMenuItemThemeOriginal, props),
    iconStyle: iconStyle(iconStyleThemeOriginal),
  },
};
