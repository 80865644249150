import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../../../component-theme/theme-types';

export type InputStylesProps = {
  fontFamily: WeaveThemeValues['FontFamily'];
  fontSize: WeaveThemeValues['FontSize'];
  spacing: WeaveThemeValues['Spacing'];
  placeholderColor: WeaveThemeValues['Color'];
  disabledColor: WeaveThemeValues['Color'];
};

type PlaceholderStylesType = {
  color: WeaveThemeValues['Color'];
};

export type DropdownMenuStylesType = {
  boxShadow: WeaveThemeValues['BoxShadow'];
  borderRadius: WeaveThemeValues['BorderRadius'];
  zIndex: Record<string, number | string>;
};

export const inputTheme: InputStylesProps = {
  fontFamily: theme.font.family,
  fontSize: theme.fontSize,
  spacing: theme.spacing,
  placeholderColor: theme.colors.neutral50,
  disabledColor: theme.colors.status.disabled,
};

export const inputThemeOriginal: InputStylesProps = {
  fontFamily: themeOriginal.font.family,
  fontSize: themeOriginal.fontSize,
  spacing: themeOriginal.spacing,
  placeholderColor: themeOriginal.colors.gray[500],
  disabledColor: themeOriginal.colors.disabled,
};

export const placeholderTheme: PlaceholderStylesType = {
  color: theme.colors.neutral50,
};
export const placeholderThemeOriginal: PlaceholderStylesType = {
  color: themeOriginal.colors.gray[500],
};

export const dropdownMenuTheme: DropdownMenuStylesType = {
  boxShadow: theme.shadows.heavy,
  borderRadius: theme.borderRadius.small,
  zIndex: theme.zIndex,
};

export const dropdownMenuThemeOriginal: DropdownMenuStylesType = {
  boxShadow: themeOriginal.shadows.heavy,
  borderRadius: themeOriginal.borderRadius.small,
  zIndex: themeOriginal.zIndex,
};
