import { css } from '@emotion/react';
import { isFunction } from 'lodash-es';
import { ButtonBar, PrimaryButton, SecondaryButton } from '../../buttons';
import { withClose } from '../atoms/helpers';
import type { ClickHandler } from '../atoms/helpers';
import { useModalContext } from '../provider';

type Props = {
  actionName?: string;
  cancelName?: string;
  disableAction?: boolean;
  onAction: ClickHandler;
  onCancel?: ClickHandler;
  actionTrackingId?: string;
  cancelTrackingId?: string;
  className?: string;
  maxWidth?: number;
};

export const FullscreenModalActions = ({
  onAction,
  onCancel,
  actionName = 'Save',
  cancelName = 'Cancel',
  disableAction,
  cancelTrackingId,
  actionTrackingId,
  maxWidth,
}: Props) => {
  const { onClose } = useModalContext();

  return (
    <ButtonBar
      css={css`
        position: relative;
        margin: auto;
        max-width: ${maxWidth}px;
      `}
    >
      {isFunction(onCancel) && (
        <SecondaryButton onClick={withClose(onClose, onCancel)} trackingId={cancelTrackingId}>
          {cancelName}
        </SecondaryButton>
      )}
      <PrimaryButton onClick={withClose(onClose, onAction)} disabled={disableAction} trackingId={actionTrackingId}>
        {actionName}
      </PrimaryButton>
    </ButtonBar>
  );
};
