import { Dispatch, ElementType, ReactNode, SetStateAction, useEffect } from 'react';
import { createGetSpecificChildren } from '../../helpers';
import { SwitchChip, SwitchChipProps } from './switch-chip';
import { PolymorphicComponentPropWithoutRef } from '../../type-utils';
import { SwitchChipGroupContext } from './switch-chip-group-context';
import { useStyles } from '../../use-styles';

export type SwitchChipGroupOptional = {
  children: ReactNode;
  value: string[];
  setValue: Dispatch<SetStateAction<string[]>>;
  defaultValue?: string;
  disabled?: boolean;
  singleSelect?: boolean;
};

type PolymorphicProps<T extends ElementType> = PolymorphicComponentPropWithoutRef<T, SwitchChipGroupOptional>;

const SwitchChipGroupOptional = <T extends ElementType = 'div'>({
  as,
  children,
  value,
  setValue,
  defaultValue,
  disabled,
  singleSelect,
  ...rest
}: PolymorphicProps<T>) => {
  const Component = as || 'div';
  const styles = useStyles('SwitchChipGroup');
  const getChildren = createGetSpecificChildren<typeof SwitchChip.displayName, SwitchChipProps>(children, true);
  const chipsChildren = getChildren(SwitchChip.displayName);
  const childrenValues = chipsChildren.map(({ props }) => props.value);

  const toggleValue = (valueToToggle: string) => {
    if (value.length === 1 && value.includes(valueToToggle)) return;

    if (singleSelect) {
      setValue([valueToToggle]);
      return;
    }

    if (value.includes(valueToToggle)) {
      setValue((prev) => prev.filter((val) => val !== valueToToggle));
    } else {
      setValue((prev) => [...prev, valueToToggle]);
    }
  };

  useEffect(() => {
    if (!!value.length) return;

    if (defaultValue && childrenValues.includes(defaultValue)) {
      setValue([defaultValue]);
      return;
    }

    const firstEnabledChip = chipsChildren.filter(({ props }) => !props.disabled)[0];
    if (firstEnabledChip) {
      setValue([firstEnabledChip.props.value]);
    }
  }, [value.length]);

  useEffect(() => {
    if (value.length > 1 && singleSelect) {
      setValue((prev) => prev.slice(0, 1));
    }
  }, [singleSelect]);

  return (
    <SwitchChipGroupContext.Provider
      value={{
        value,
        toggleValue,
        disabled,
      }}
    >
      <Component css={styles} {...rest} role='group'>
        {chipsChildren}
      </Component>
    </SwitchChipGroupContext.Provider>
  );
};

export const SwitchChipGroup = Object.assign(SwitchChipGroupOptional, {
  Option: SwitchChip,
});
