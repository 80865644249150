import { css } from '@emotion/react';
import { transparentize } from '../../../../helpers';
import type { OptionSwitchOptionThemeValues } from './option-switch-option-theme';
import { optionSwitchOptionTheme, optionSwitchOptionThemeOriginal } from './option-switch-option-theme';
const style = ({
  borderColor,
  borderRadius,
  fontSize,
  fontWeight,
  focusColor,
  textColor,
  zIndex,
}: OptionSwitchOptionThemeValues) => css`
  width: 100%;
  height: 100%;
  background: white;
  border: 1px solid ${borderColor};
  border-radius: 0;
  color: ${textColor};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${fontSize(14)};
  transition: color 250ms ease-out, border-color 250ms ease-out, background 200ms ease-out;

  :focus {
    box-shadow: 0 0 0 4px ${transparentize(0.75, focusColor)};
    font-weight: ${fontWeight};
    outline: none;
    z-index: ${zIndex};
  }

  :not(:last-of-type) {
    border-right: 0;
  }

  :first-of-type {
    border-radius: ${borderRadius} 0 0 ${borderRadius};
  }

  :last-of-type {
    border-radius: 0 ${borderRadius} ${borderRadius} 0;
  }

  &[aria-checked='true'] {
    border: 2px solid ${focusColor};
    color: ${focusColor};
    font-weight: ${fontWeight};
  }

  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const OptionSwitchOptionStyles = {
  OptionSwitchOption: style(optionSwitchOptionTheme),
};

export const OptionSwitchOptionStylesOriginal: typeof OptionSwitchOptionStyles = {
  OptionSwitchOption: style(optionSwitchOptionThemeOriginal),
};
