import { css } from '@emotion/react';
import type { TabBarStyleValues, TabStyleValues } from './tab-theme';
import { originalTabBarTheme, originalTabTheme, tabBarTheme, tabTheme } from './tab-theme';

type TabBarStyleProps = { activeIndex: number; tabWidth: number };

export const tabBarStyle = ({ afterBackground }: TabBarStyleValues, { activeIndex, tabWidth }: TabBarStyleProps) => css`
  width: 100%;

  /* Prevents inadvertent collapsing inside a flex parent */
  min-height: 40px;
  height: 40px;
  display: flex;
  position: relative;

  :focus {
    outline: none;
  }

  ::after {
    content: '';
    display: block;
    height: 2px;
    background: ${afterBackground};
    position: absolute;
    bottom: -1px;
    left: 0;
    transition: transform 0.3s ease-out;
    width: ${tabWidth}%;
    opacity: ${activeIndex >= 0 ? 1 : 0};
    transform: translateX(${100 * activeIndex}%);
  }

  a,
  button {
    min-width: ${tabWidth}%;
    flex-basis: ${tabWidth}%;
  }
`;

export const tabItemStyle = ({
  fontFamily,
  fontSize,
  color,
  hoverAndFocusBackground,
  selectedFontColor,
  fontWeight,
  background,
  selectedBackground,
  hoverAndFocusColor,
}: TabStyleValues) =>
  css`
    font-family: ${fontFamily};
    font-size: ${fontSize};
    color: ${color};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-decoration: none;
    transition: background 0.25s ease-out;

    :hover,
    :focus {
      background: ${hoverAndFocusBackground};
      color: ${hoverAndFocusColor};
      outline: none;
    }

    &.selected,
    &[aria-selected='true'] {
      color: ${selectedFontColor};
      font-weight: ${fontWeight};
      background: ${selectedBackground};

      :focus::after {
        background: ${background};
      }
    }
  `;

export const TabFilterStyles = {
  TabFilter: {
    tabItem: tabItemStyle(tabTheme),
    tabBar: (tabBarProps: TabBarStyleProps) => tabBarStyle(tabBarTheme, tabBarProps),
  },
};

export const TabFilterStylesOriginal: typeof TabFilterStyles = {
  TabFilter: {
    tabItem: tabItemStyle(originalTabTheme),
    tabBar: (tabBarProps: TabBarStyleProps) => tabBarStyle(originalTabBarTheme, tabBarProps),
  },
};
