import { helperTextModalActionThemesOriginal, helperTextModalActionsTheme } from './atoms/modal-actions-theme';
import { modalHelperTextStyles } from './atoms/modal-actions.styles';
import { modalBackdropTheme, modalBackdropThemeOriginal } from './atoms/modal-backdrop-theme';
import { modalBackdropStyles } from './atoms/modal-backdrop.styles';
import {
  fullscreenModalHeaderTheme,
  fullscreenModalHeaderThemeOriginal,
} from './fullscreen-modal/fullscreen-modal-header-theme';
import { fullscreenModalHeaderStyle } from './fullscreen-modal/fullscreen-modal-header.styles';

export const ModalStyles = {
  Modal: {
    fullscreenModalHeader: fullscreenModalHeaderStyle(fullscreenModalHeaderTheme),
    modalBackdropStyles: modalBackdropStyles(modalBackdropTheme),
    helperTextStyles: modalHelperTextStyles(helperTextModalActionsTheme),
  },
};

export const ModalStylesOriginal: typeof ModalStyles = {
  Modal: {
    fullscreenModalHeader: fullscreenModalHeaderStyle(fullscreenModalHeaderThemeOriginal),
    modalBackdropStyles: modalBackdropStyles(modalBackdropThemeOriginal),
    helperTextStyles: modalHelperTextStyles(helperTextModalActionThemesOriginal),
  },
};
