import { useState } from 'react';
import { css } from '@emotion/react';
import { PaymentPlanConfirmationStatus } from '@frontend/api-payment-plans';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  ButtonBar,
  CheckIcon,
  CheckboxField,
  PrimaryButton,
  SecondaryButton,
  Text,
  TextButton,
  TextLink,
  XIcon,
  useFormField,
} from '@frontend/design-system';
import { useConfirmPlan, usePaymentPlanInfo } from '../../hooks';
import { PlanTermsOfService } from './plan-terms-of-service';

const styles = {
  section: css`
    display: flex;
    flex-direction: column;
    align-items: start;
  `,
  margin: css`
    margin-left: ${theme.spacing(3.5)};
    margin-top: ${theme.spacing(2)};
    padding: 0px;
  `,
  buttonBar: css`
    justify-content: start;
    flex-wrap: wrap;
    & > button {
      width: auto;
    }
  `,
  confirmMessageBox: css`
    display: flex;
    gap: ${theme.spacing(2, 2)};
  `,
  confirmIcon: css`
    margin-top: ${theme.spacing(0.5)};
  `,
  confirmMessage: css`
    margin: 0;
    font-size: ${theme.font.size.medium};
  `,
  acceptedMessageBox: css`
    color: ${theme.font.colors.success};
  `,
  acceptedMessage: css`
    color: inherit;
  `,
  deniedMessageBox: css`
    color: ${theme.font.colors.error};
  `,
  deniedActionBar: css`
    & > button {
      color: ${theme.font.colors.primary};
    }
  `,
  onlineBillPayLink: css`
    font-size: ${theme.font.size.medium};
    padding: ${theme.spacing(0.5, 1)};
  `,
  termsBtn: css`
    width: auto;
    color: ${theme.font.colors.primary};
  `,
};

export const Authorization = () => {
  const { t } = useTranslation('payments');
  const { paymentPlan, location } = usePaymentPlanInfo();
  const { agreePlan, denyPlan } = useConfirmPlan();
  const [showTermsModal, setShowTermsModal] = useState(false);

  const approveFieldProps = useFormField({
    type: 'checkbox',
    value: false,
  });

  return (
    <section css={styles.section}>
      {paymentPlan?.confirmationDetails.confirmationStatus == PaymentPlanConfirmationStatus.Accept ? (
        <div css={[styles.confirmMessageBox, styles.acceptedMessageBox]}>
          <CheckIcon size={20} />
          <Text css={styles.confirmMessage}>
            {t('You’ve successfully authorized your payment plan. Payments will start on the date indicated above.')}
          </Text>
        </div>
      ) : paymentPlan?.confirmationDetails.confirmationStatus === PaymentPlanConfirmationStatus.Deny ? (
        <>
          <div css={[styles.confirmMessageBox, styles.deniedMessageBox]}>
            <XIcon size={20} css={styles.confirmIcon} />
            <Text css={styles.confirmMessage}>
              {t(
                'The office has been notified that you have declined the payment plan. By declining the payment plan, you will be responsible for payment in full or you can contact the office to make a different agreement.'
              )}
            </Text>
          </div>
          <ButtonBar css={[styles.margin, styles.buttonBar, styles.deniedActionBar]}>
            <TextLink href={location?.onlineBillPayLink} target='_blank' css={styles.onlineBillPayLink}>
              {t('Make Full Payment')}
            </TextLink>
          </ButtonBar>
        </>
      ) : (
        <>
          <CheckboxField
            {...approveFieldProps}
            name='approve-plan'
            label={t(
              'I authorize the {{name}}(hereinafter “Business” or “us”) to charge the Billing method provided above, to the Payment Authorization, Electronic Consent Agreement and terms outlined above and arising from the services/products provided by Business.  I certify that I am an authorized user of this card/account and that I agree to the payment terms as provided. You can contact “Business” to cancel at any time.',
              { name: location?.name ?? '' }
            )}
          />
          <TextButton css={[styles.margin, styles.termsBtn]} onClick={() => setShowTermsModal(true)}>
            {t('Read the terms of service')}
          </TextButton>
          <ButtonBar css={[styles.margin, styles.buttonBar]}>
            <PrimaryButton onClick={agreePlan} disabled={!approveFieldProps.value}>
              {t('Confirm and Start Payment Plan')}
            </PrimaryButton>
            <SecondaryButton onClick={denyPlan} destructive>
              {t('Deny Authorization')}
            </SecondaryButton>
          </ButtonBar>
          <PlanTermsOfService show={showTermsModal} onClose={() => setShowTermsModal(false)} location={location} />
        </>
      )}
    </section>
  );
};
