import { CustomizationFlagState } from './types';

export const FLAG_ACTIVE_VALID_STATES = [
  CustomizationFlagState.ACTIVE,
  CustomizationFlagState.TRIAL,
  CustomizationFlagState.PROMOTE,
];

export const isCustomizationFlagActive = ({
  state,
  activeStates = FLAG_ACTIVE_VALID_STATES,
}: {
  state?: CustomizationFlagState;
  activeStates?: CustomizationFlagState[];
}) => {
  return !!state && activeStates.includes(state);
};
