import { css } from '@emotion/react';
import { PolymorphicComponentPropWithoutRef, PolymorphicRef } from '@frontend/design-system';
import { ElementType, forwardRef } from 'react';

type NavLinkComponentProps<C extends ElementType> = PolymorphicComponentPropWithoutRef<C>;

const plainButtonStyles = css`
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
`;

const plainLinkStyles = css`
  text-decoration: none;
`;

export const NavLink = forwardRef(
  <C extends ElementType = 'a'>(
    { as, children, trackingId, ...rest }: NavLinkComponentProps<C>,
    ref: PolymorphicRef<C>
  ) => {
    const Component = as || 'a';

    // TODO: Add preloading of the links
    return (
      <Component
        css={[Component === 'button' ? plainButtonStyles : plainLinkStyles]}
        data-trackingid={trackingId}
        {...rest}
        ref={ref}
      >
        {children}
      </Component>
    );
  }
);
