import { ElementType, useState, Dispatch, SetStateAction } from 'react';
import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { Compulsory } from 'ts-toolbelt/out/Object/Compulsory';
import { PersonsV3 } from '@frontend/api-person';
import { breakpoints, useContainerQuery } from '@frontend/responsiveness';
import { PickRequired } from '@frontend/types';
import { theme } from '@frontend/theme';
import { PolymorphicComponentPropWithoutRef, contextFactory } from '@frontend/design-system';
import { HEADER_SHADOW } from '../styles';
import { OutboundNumberData } from '../types';
import * as Atoms from './atoms';
import { ThreadDrawer } from './thread-drawer';
import { ThreadSubHeader } from './thread-sub-header';

type ThreadHeaderContext = {
  personPhone: string;
  outboundPhone?: OutboundNumberData;
  onPersonPhoneChange?: (personPhone: string) => void;
  onOutboundPhoneChange?: (outboundPhone: OutboundNumberData) => void;
  groupId: string;
  variant?: 'horizontal' | 'vertical';
  associatedContacts?: Person[];
  primaryContactId?: string;
  primaryContact?: Person;
  contextPersonId?: string;
  associatedContactsDrawerIsOpen: boolean;
  setAssociatedContactsDrawerIsOpen: Dispatch<SetStateAction<boolean>>;
};
export const [ThreadHeaderContext, useThreadHeaderContext] = contextFactory<Compulsory<ThreadHeaderContext, 'variant'>>(
  'useThreadHeaderContext must be used within ThreadHeaderProvider (or ThreadHeader component)'
);

type ThreadHeaderProps<E extends ElementType> = PolymorphicComponentPropWithoutRef<
  E,
  Omit<PickRequired<ThreadHeaderContext, 'groupId' | 'personPhone'>, 'variant'> & {
    variant?: ThreadHeaderContext['variant'] | 'responsive';
    hideAssociatedContacts?: boolean;
  }
>;

const ThreadHeaderComponent = <E extends ElementType = 'section'>({
  as,
  children,
  personPhone,
  outboundPhone,
  onPersonPhoneChange,
  onOutboundPhoneChange,
  groupId,
  variant = 'responsive',
  associatedContacts: providedAssociatedContacts,
  primaryContact: providedPrimaryContact,
  primaryContactId: providedPrimaryContactId = providedPrimaryContact?.personId,
  contextPersonId,
  associatedContactsDrawerIsOpen,
  setAssociatedContactsDrawerIsOpen,
  onBackClick,
  hideAssociatedContacts,
  ...rest
}: ThreadHeaderProps<E>) => {
  const Component = as || 'section';
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const resolvedDrawerIsOpen = associatedContactsDrawerIsOpen ?? drawerIsOpen;
  const resolvedSetDrawerIsOpen = setAssociatedContactsDrawerIsOpen ?? setDrawerIsOpen;
  const [isWide, ref] = useContainerQuery({ minWidth: breakpoints.small.max });
  const calculatedVariant = variant === 'responsive' ? (isWide ? 'horizontal' : 'vertical') : variant;

  const { associatedContacts: fetchedAssociatedContacts, primaryContactId } =
    PersonsV3.PersonHooks.useAssociatedContacts({
      phoneNumber: personPhone,
      locationId: groupId,
      enabled: !providedAssociatedContacts?.length,
    });
  const resolvedPrimaryContactId = providedPrimaryContactId || primaryContactId;
  const associatedContacts = providedAssociatedContacts?.length
    ? providedAssociatedContacts
    : fetchedAssociatedContacts;
  const primaryContact =
    providedPrimaryContact ||
    associatedContacts.find((contact) => contact.personId === (providedPrimaryContactId || primaryContactId));

  const filteredAssociatedContacts = hideAssociatedContacts
    ? []
    : associatedContacts.filter(({ personId: id }) => id !== primaryContactId) ?? [];

  return (
    <ThreadHeaderContext.Provider
      value={{
        personPhone,
        outboundPhone,
        onPersonPhoneChange,
        onOutboundPhoneChange,
        groupId,
        variant: calculatedVariant,
        associatedContacts: filteredAssociatedContacts,
        primaryContactId: resolvedPrimaryContactId,
        primaryContact,
        contextPersonId,
        associatedContactsDrawerIsOpen: resolvedDrawerIsOpen,
        setAssociatedContactsDrawerIsOpen: resolvedSetDrawerIsOpen,
      }}
    >
      <Component
        ref={ref}
        css={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          position: 'relative',
          zIndex: theme.zIndex.middle,
          boxShadow: HEADER_SHADOW,
        }}
        {...rest}
      >
        {children}
      </Component>
    </ThreadHeaderContext.Provider>
  );
};

/**
 * `ThreadHeader` is a re-usable, composable component that provides a consistent header layout and context for the
 * headers of sms threads.
 * It is designed to be used with its various atoms and actions to minimize the amount of props that need to be passed
 * down to each component.
 * @param personPhone - The phone number of the person associated with the thread.
 * @param outboundPhone (optional) - The phone number that messages will be sent from. Must be provided if the location
 * has multiple outbound numbers.
 * @param onPersonPhoneChange (optional) - A callback function that will be called when the person phone number
 * selection changes. If not provided, the `PersonPhoneSelector` will not do anything.
 * @param onOutboundPhoneChange (optional) - A callback function that will be called when the outbound phone number
 * selection changes. If not provided, the `DepartmentSelector` will not do anything.
 * @param groupId - The location id (business group id) of the thread.
 * @param variant (optional) - The layout variant of the header. Defaults to 'responsive', which will switch between
 * 'horizontal' and 'vertical' based on the screen width. Can also be set to 'horizontal' or 'vertical' to force a specific layout.
 * @param associatedContacts (optional) - An array of associated contacts to display in the associated contacts drawer.
 * If not provided, the associated contacts will be fetched based on the `personPhone` and `groupId`. This is useful for restricting the associated contacts list to a subset of contacts.
 * @param person (optional) - The person object associated with the thread. If not provided, the person will be fetched
 * based on the `personId` (if provided) or the primary contact associated with the provided `personPhone`.
 * @param personId (optional) - The person id of the person associated with the thread. If not provided, the primary
 * contact associated with the provided `personPhone` will be used.
 * @param associatedContactsDrawerIsOpen (optional) - The open state of the associated contacts drawer. If not
 * provided, the drawer will be closed by default.
 * @param setAssociatedContactsDrawerIsOpen (optional) - A callback function that will be called when the associated
 * contacts drawer open state changes. If not provided, the drawer will not be able to be opened or closed.
 */
export const ThreadHeader = Object.assign(ThreadHeaderComponent, {
  /**
   * A hook to access the context provided by the `ThreadHeader` component.
   */
  useContext: useThreadHeaderContext,
  /**
   * `Header` wraps the main content of the header. It mainly controls the layout of that main section of the header.
   * It is heavily affected by the `variant` context from the `ThreadHeader` component.
   *
   * Variant info:
   * - 'horizontal': The header will be displayed in a horizontal layout, with the main content centered vertically,
   * and children spaced evenly.
   * - 'vertical': The header will be displayed in a vertical layout, with the main content aligned to the top, and
   * children spaced evenly.
   */
  Header: Atoms.Header,
  /**
   * `SubHeader` wraps the secondary content of the header. It mainly controls the layout of that secondary section of
   * the header.
   * It is intended to be used for the thread phone number selectors and other secondary actions.
   */
  SubHeader: ThreadSubHeader,
  /**
   * `Drawer` is a drawer that can be used to display additional content related to the thread.
   * It has smooth animations and can be opened and closed by setting the `open` prop to `true` or `false`.
   * This is best used as a child of the `Header` component.
   *
   * @param open - Whether the drawer is open or not.
   * @param onClose - A callback function that will be called when the drawer is closed.
   * @param children - The content to display inside the drawer.
   * @param keepMounted (optional) - Whether the drawer should remain mounted when closed. Defaults to `false`.
   * @param closeLabel (optional) - The label to display on the close button. Defaults to 'Close'.
   * @param hideCloseButton (optional) - Whether the close button in the drawer should be hidden. Defaults to `false`.
   */
  Drawer: ThreadDrawer,
  /**
   * `AssociatedContactsDrawer` is a drawer that displays a list of associated contacts for the thread.
   * It wraps the `ThreadHeader.Drawer` component and is controlled by the `associatedContactsDrawerIsOpen` and
   * `setAssociatedContactsDrawerIsOpen` context from `ThreadHeader`.
   * This is best used as a child of the `Header` component.
   *
   * @param onSelect (optional) - A callback function that will be called when an associated contact is selected. If
   * not provided, the associated contact will not be selectable.
   * @param onClose (optional) - A callback function that will be called when the drawer is closed. If not provided,
   * the drawer will not be able to be closed.
   */
  AssociatedContactsDrawer: Atoms.AssociatedContactsDrawer,
  /**
   * `AssociatedContactsButton` is a button that opens the associated contacts drawer when clicked.
   * It is best used as a child of the `Title` component.
   * If there are no associated contacts, the button will not be displayed.
   *
   * @param onClick (optional) - A callback function that will be called when the button is clicked. If not provided,
   * the button will not do anything.
   */
  AssociatedContactsButton: Atoms.AssociatedContactsButton,
  /**
   * `Title` is a component that displays the title of the thread. This includes the `Avatar`, and title text (usually
   * the person name or phone number)
   * Its children will be rendered inside the title, after the avatar and title text. This is a useful place to put the
   * `PersonSecondaryInfo` and `AssociatedContactsButton` components.
   *
   * If the `variant` from the context is 'vertical', its children will be displayed below the title text.
   */
  Title: Atoms.Title,
  /**
   * `Info` is a component that displays additional information about the person, such as sex, age, and status.
   * If there is no `person` in the context, this component will not be rendered.
   */
  Info: Atoms.Info,
  /**
   * `PersonPhoneSelector` is a component that displays the current phone number selection and allows the user to
   * select a different phone number for the thread.
   * It will automatically show all valid phone numbers associated with the person as options.
   */
  PersonPhoneSelector: Atoms.PersonPhoneSelector,
  /**
   * `DepartmentSelector` is a component that displays the current outbound phone number selection and allows the user
   * to select a different outbound phone number for the thread.
   * It will automatically show all valid outbound phone numbers associated with the location as options.
   * If the location has only one outbound phone number, this component will not be rendered unless the `alwaysShow`
   * prop is set to `true`.
   * If no `onOutboundPhoneChange` callback is provided from the context, this component will not be rendered
   */
  DepartmentSelector: Atoms.DepartmentSelector,
});
