/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const AlignRightIconSmall: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={16} {...props} viewBox='0 0 16 16'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.76489 0.991211C2.21261 0.991211 1.76489 1.43893 1.76489 1.99121C1.76489 2.5435 2.21261 2.99121 2.76489 2.99121H14.7793C15.3316 2.99121 15.7793 2.5435 15.7793 1.99121C15.7793 1.43893 15.3316 0.991211 14.7793 0.991211H2.76489ZM6.19751 8.96191C5.64523 8.96191 5.19751 9.40963 5.19751 9.96191C5.19751 10.5142 5.64523 10.9619 6.19751 10.9619H14.7792C15.3315 10.9619 15.7792 10.5142 15.7792 9.96191C15.7792 9.40963 15.3315 8.96191 14.7792 8.96191H6.19751ZM7.77197 5.97656C7.77197 5.42428 8.21969 4.97656 8.77197 4.97656H14.7792C15.3315 4.97656 15.7792 5.42428 15.7792 5.97656C15.7792 6.52885 15.3315 6.97656 14.7792 6.97656H8.77197C8.21969 6.97656 7.77197 6.52885 7.77197 5.97656ZM14.7843 15.0086C15.3365 15.0087 15.7821 14.561 15.7794 14.0087C15.7768 13.4564 15.3269 13.0086 14.7747 13.0085L9.458 13.0078C8.90575 13.0077 8.46021 13.4553 8.46286 14.0077C8.46552 14.56 8.91536 15.0078 9.46761 15.0078L14.7843 15.0086Z'
    />
  </Icon>
);
