import { CSSProperties, ReactNode, memo } from 'react';
import { css } from '@emotion/react';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { FontWeight, Text, TextSize } from '@frontend/design-system';

type commonProps = {
  className?: string;
  size?: TextSize;
  style?: CSSProperties;
  weight?: FontWeight;
};

type SneakPeakBarProps = commonProps & {
  data: {
    key: string;
    name: string | ReactNode;
    omitSubtext?: boolean;
    subtext?: string;
    value: number | string;
  }[];
};

export const SneakPeakBar = memo(
  ({ className = '', data, size = 'medium', style = {}, weight = 'regular' }: SneakPeakBarProps) => {
    return (
      <div className={className} css={styles.wrapper} style={style}>
        {data.map(({ key, name, omitSubtext, subtext, value }) => (
          <div className='sneak-peak-item' css={styles.sneakPeakItem} key={key}>
            {typeof name === 'string' ? (
              <Text color='light' size={size} weight={weight}>
                {name}
              </Text>
            ) : (
              name
            )}
            <Text className='value' size={size} weight={weight}>
              {value}
            </Text>
            {subtext && !omitSubtext && (
              <Text color='light' size='small'>
                {subtext}
              </Text>
            )}
          </div>
        ))}
      </div>
    );
  }
);

SneakPeakBar.displayName = 'SneakPeakBar';

const styles = {
  wrapper: css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
    gap: ${theme.spacing(4)};

    @media screen and (min-width: ${breakpoints.medium.min}px) {
      flex-direction: row;
      gap: ${theme.spacing(2)};
    }
  `,

  sneakPeakItem: css`
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex: 1;
    justify-content: center;

    @media screen and (min-width: ${breakpoints.medium.min}px) {
      &:first-of-type {
        align-items: flex-start;
      }

      &:last-of-type {
        align-items: flex-end;
      }
    }

    .value {
      font-size: ${theme.fontSize(36)};
    }

    span {
      white-space: nowrap;
    }
  `,
};
