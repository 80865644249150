import { QueryFunction, QueryKey, useQuery as useRQQuery, UseQueryOptions, UseQueryResult } from 'react-query';

export function useQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>): UseQueryResult<TData, TError>;

export function useQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey'>
): UseQueryResult<TData, TError>;

export function useQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>
): UseQueryResult<TData, TError>;

export function useQuery<TQueryFnData, TError, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
  arg1: TQueryKey | UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  arg2?: QueryFunction<TQueryFnData, TQueryKey> | UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  arg3?: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>
): UseQueryResult<TData, TError> {
  if (!arg2 && !arg3) {
    return useRQQuery<TQueryFnData, TError, TData, TQueryKey>(
      arg1 as UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>
    );
  }

  if (!arg3) {
    return useRQQuery<TQueryFnData, TError, TData, TQueryKey>(
      arg1 as TQueryKey,
      arg2 as UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>
    );
  }

  return useRQQuery<TQueryFnData, TError, TData, TQueryKey>(
    arg1 as TQueryKey,
    arg2 as QueryFunction<TQueryFnData, TQueryKey>,
    arg3
  );
}
