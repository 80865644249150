import { BaseSelection } from 'lexical';
import { $isDynamicFieldNode } from '../nodes';

export function $patchDynamicFieldLabelStyle(selection: BaseSelection | null, styles: React.CSSProperties) {
  if (selection) {
    const nodes = selection.getNodes();
    nodes.forEach((node) => {
      if ($isDynamicFieldNode(node)) {
        const dynamicNode = node;
        dynamicNode.$patchDynamicLabelStyle(styles);
      }
    });
  }
}
