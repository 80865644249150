import { css } from '@emotion/react';
import { UlProps } from '../../naked';
import { NakedUl } from '../../naked';
import { forwardRef } from 'react';
import { useThemeValues } from '../../hooks';
import { styles } from '../../styles';

export const StyledListboxList = forwardRef<HTMLUListElement, UlProps>(({ children, ...rest }, ref) => {
  const theme = useThemeValues();
  return (
    <NakedUl
      css={css`
        flex: 1;
        overflow-y: auto;
        margin: 0;
        :focus {
          outline: none;
        }
        ${styles.smallScrollbar(theme)};
      `}
      ref={ref}
      {...rest}
    >
      {children}
    </NakedUl>
  );
});
