import { ReactNode, ReactElement } from 'react';

export type StepperSuccessProps = {
  children: ReactNode | ReactNode[] | ReactElement;
};

export const StepperSuccess = ({ children }: StepperSuccessProps) => {
  return <>{children}</>;
};

// we need this to differentiate it when we filter thru the children.
// Check out the stepper.component file to see where we filter by displayName
// the reason we can't filter thru Component Name like 'StepperSuccess' is because it gets minified in a prod build
StepperSuccess.displayName = 'StepperSuccess';
