import { css } from '@emotion/react';
import { useThemeValues } from '../../../../hooks';

export const LeadingColumn = ({ children, ...rest }: { children: React.ReactNode }) => {
  const { spacing } = useThemeValues();

  return (
    <div
      css={css`
        display: flex;
        grid-area: lead;
        padding-right: ${spacing(2)};
        align-items: center;
      `}
      {...rest}
    >
      {children}
    </div>
  );
};
