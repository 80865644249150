import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { AlignmentAction } from '../../molecules';
import { FORMAT_ELEMENT_COMMAND } from 'lexical';
import { useCallback } from 'react';
import { useToolbarState } from '../../providers';

export const ElementFormat = () => {
  const [editor] = useLexicalComposerContext();
  const { elementFormat } = useToolbarState(['elementFormat']);

  const onAlignmentSelect = useCallback(
    (value: typeof elementFormat) => {
      editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, value);
    },
    [editor]
  );

  return <AlignmentAction onChange={onAlignmentSelect} value={elementFormat || 'left'} />;
};

ElementFormat.displayName = 'ElementFormat';
