// Write documentation for this file

/**
 *
 * @param size
 * @param percentage
 * @description
 * This function is used to generate a random size based on the percentage of the original size.
 * @returns number | string
 *
 */

export const generateSizeVariability = (size: number | string, percentage?: number): string | number => {
  if (!percentage) return size;

  if (size === 'auto') return size;

  if (typeof size === 'number') {
    const min = size * (percentage / 100);
    const max = size;

    return Math.round(Math.random() * (max - min) + min);
  }

  const sizeNumber = Number(size.replace(/[^0-9.]/g, ''));
  const sizeUnit = size.replace(/[0-9.]/g, '');

  const min = sizeNumber * (percentage / 100);
  const max = sizeNumber;
  const randomValue = Math.random() * (max - min) + min;

  return `${randomValue.toFixed(2)}${sizeUnit}`;
};
