/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const DynamicFieldIconSmall: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={16} {...props} viewBox='0 0 16 16' noFill>
    <path
      d='M14.2277 10.0362C14.2277 10.7009 14.2277 11.3751 14.2277 11.9734C14.2277 13.078 13.3323 13.9734 12.2277 13.9734H4.281C3.17644 13.9734 2.28101 13.0779 2.28101 11.9734V4.02661C2.28101 2.92204 3.17644 2.02661 4.28101 2.02661H6.26769'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
    />
    <path d='M8.01196 5.24927H14.012' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
    <path d='M11.012 2.24927L11.012 8.24927' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
  </Icon>
);
