import { Interpolation, Theme, css } from '@emotion/react';
import { isFunction } from 'lodash-es';
import { Modal } from '../modal';
import type { ModalControlModalProps } from '../provider';
import { ModalProviderProps, useModalContext } from '../provider';
import { ConfirmationText } from './confirmation-text';
import { useThemeValues } from '../../../hooks';

export type ConfirmationModalProps = ModalControlModalProps &
  Pick<ModalProviderProps, 'mountTarget' | 'prepend'> & {
    cancelLabel?: string;
    confirmLabel?: string;
    cancelTrackingId?: string;
    confirmTrackingId?: string;
    modalCss?: Interpolation<Theme>;
    message?: React.ReactNode;
    onCancel?: () => void;
    onConfirm: () => void;
    title: string;
    children?: React.ReactNode;
    destructive?: boolean;
    maxWidth?: number;
  };

type ContentProps = Omit<ConfirmationModalProps, 'mountTarget' | 'prepend' | 'show'>;

const ConfirmationModalContent = ({
  cancelLabel = 'Cancel',
  confirmLabel = 'Confirm',
  cancelTrackingId,
  confirmTrackingId,
  children,
  message,
  onCancel,
  onConfirm,
  onClose,
  title,
  destructive = false,
}: ContentProps) => {
  const { setLoading } = useModalContext();
  const { spacing } = useThemeValues();

  const maybeAsyncHandler = (handler: () => void) => async () => {
    try {
      const result: any = handler();
      if (result instanceof Promise) {
        setLoading(true);
        await result;
        setLoading(false);
      }
      onClose();
    } catch {
      setLoading(false);
      return;
    }
  };

  return (
    <>
      <Modal.Header onClose={onClose}>{title}</Modal.Header>
      <Modal.Body>
        {message && <ConfirmationText>{message}</ConfirmationText>}
        {children}
      </Modal.Body>
      <Modal.Actions
        css={css`
          padding-top: 0;
          gap: ${spacing(2)};
        `}
        destructive={destructive}
        primaryLabel={confirmLabel}
        primaryTrackingId={confirmTrackingId}
        secondaryLabel={cancelLabel}
        secondaryTrackingId={cancelTrackingId}
        onPrimaryClick={maybeAsyncHandler(onConfirm)}
        onSecondaryClick={isFunction(onCancel) ? maybeAsyncHandler(onCancel!) : onClose}
      />
    </>
  );
};

export const ConfirmationModal = ({
  mountTarget,
  prepend,
  show,
  maxWidth = 400,
  modalCss,
  ...rest
}: ConfirmationModalProps) => (
  <Modal
    show={show}
    onClose={rest.onClose}
    maxWidth={maxWidth}
    mountTarget={mountTarget}
    prepend={prepend}
    css={modalCss}
  >
    <ConfirmationModalContent {...rest} />
  </Modal>
);
