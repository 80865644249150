import { css } from '@emotion/react';
import { PaymentPlanDetailsResponse, PlanDetailsType } from '@frontend/api-payment-plans';
import { useTranslation } from '@frontend/i18n';
import { mapPaymentPlanPaymentMethodToPaymentMethod } from '@frontend/payments-card-on-file';
import { theme } from '@frontend/theme';
import { EmailField, Modal, Text, useFormField } from '@frontend/design-system';
import { useSendConfirmationEmail } from '../../hooks';
import { PaymentPlanDetailsModalBody } from './payment-plan-details-modal-body';

interface SendConfirmationEmailModalProps {
  planDetails: PaymentPlanDetailsResponse | undefined;
  show: boolean;
  onClose: () => void;
}

const styles = {
  modalTitle: css`
    margin-bottom: ${theme.spacing(3)};
  `,
};

export const SendConfirmationEmailModal = ({ planDetails, show, onClose }: SendConfirmationEmailModalProps) => {
  const { t } = useTranslation('payments');
  const emailFieldProps = useFormField({ type: 'email', required: true });
  const { sendConfirmationEmail, sendingConfirmationEmail } = useSendConfirmationEmail({ planDetails });

  const planDetailsForModal: PlanDetailsType = {
    planName: planDetails?.name,
    planAmount: planDetails?.schedule.amount,
    patient: planDetails?.person,
    termAmount: planDetails?.schedule.paymentAmount,
    paymentTerm: (planDetails?.schedule.paymentTerm ?? 0).toString(),
    paymentDate: planDetails?.schedule.nextPaymentAt ?? undefined,
    billingMethod: planDetails?.paymentMethod && mapPaymentPlanPaymentMethodToPaymentMethod(planDetails.paymentMethod),
    memo: planDetails?.notes,
  };

  return (
    <Modal show={show} onClose={onClose} maxWidth={608}>
      <Modal.Header onClose={onClose} textAlign='left' css={styles.modalTitle}>
        {t('Resend Confirmation')}
      </Modal.Header>
      <PaymentPlanDetailsModalBody planDetails={planDetailsForModal}>
        <div>
          <Text css={{ margin: theme.spacing(3, 0, 1) }} size='medium'>
            {t('Send confirmation email to')}
          </Text>
          <EmailField
            {...emailFieldProps}
            name='customer-email-id'
            placeholder={planDetails?.person?.email}
            label={t('Email *')}
            css={{ width: theme.spacing(40) }}
          />
        </div>
      </PaymentPlanDetailsModalBody>
      <Modal.Actions
        onSecondaryClick={() => {
          return true;
        }}
        onPrimaryClick={async () => {
          if (await sendConfirmationEmail(emailFieldProps.value)) return true;
          else {
            emailFieldProps.onBlur();
            return false;
          }
        }}
        disablePrimary={sendingConfirmationEmail}
        primaryLabel={t('Send Confirmation')}
        secondaryLabel={t('Cancel')}
      />
    </Modal>
  );
};
