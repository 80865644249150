import { createShallowStore, createStoreWithSubscribe } from '@frontend/store';

interface QuoteSidePanelStore {
  isEditingShipping: boolean;
  isShippingPanelOpen: boolean;
  isPaymentPanelOpen: boolean;
  isSignaturePanelOpen: boolean;
  isSoftwareOnly: boolean;

  setIsEditingShipping: (value: boolean) => void;
  setIsShippingPanelOpen: (value: boolean) => void;
  setIsPaymentPanelOpen: (value: boolean) => void;
  setIsSignaturePanelOpen: (value: boolean) => void;

  initializeState: (data: { isSoftwareOnly: boolean; isQuotePresigned: boolean }) => void;
}

export const useQuoteSidePanelStore = createStoreWithSubscribe<QuoteSidePanelStore>(
  (set) => ({
    isEditingShipping: false,
    isShippingPanelOpen: false,
    isPaymentPanelOpen: false,
    isSignaturePanelOpen: false,
    isSoftwareOnly: false,

    setIsEditingShipping: (value) => set({ isEditingShipping: value }),
    setIsShippingPanelOpen: (value) => set({ isShippingPanelOpen: value }),
    setIsPaymentPanelOpen: (value) => set({ isPaymentPanelOpen: value }),
    setIsSignaturePanelOpen: (value) => set({ isSignaturePanelOpen: value }),

    initializeState: ({ isSoftwareOnly, isQuotePresigned }) => {
      set({
        isSoftwareOnly,
        isShippingPanelOpen: true,
        isEditingShipping: !isQuotePresigned,
        isPaymentPanelOpen: isSoftwareOnly || isQuotePresigned,
        isSignaturePanelOpen: isSoftwareOnly || isQuotePresigned,
      });
    },
  }),
  {
    name: 'QuoteSidePanelStore',
    trace: true,
  }
);

export const useQuoteSidePanelShallowStore = createShallowStore<QuoteSidePanelStore>(useQuoteSidePanelStore);
