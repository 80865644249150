import { theme } from '@frontend/theme';
import { HTMLAttributes, forwardRef } from 'react';
import { format } from 'date-fns';

type TimeDividerRowProps = HTMLAttributes<HTMLTimeElement> & {
  timestamp: Date;
};

export const TimeDividerRow = forwardRef<HTMLTimeElement, TimeDividerRowProps>(({ timestamp, ...rest }, ref) => {
  return (
    <time
      css={{
        width: '100%',
        display: 'block',
        textAlign: 'center',
        color: theme.colors.neutral50,
        transition: `margin-bottom 300ms ease-in-out`,
        padding: theme.spacing(1.5, 0, 1),
      }}
      {...rest}
      ref={ref}
    >
      {format(timestamp, 'PP')}
    </time>
  );
});
