import { theme } from '@frontend/theme';
import { theme as originalTheme } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../../../component-theme/theme-types';

export type CheckboxStyleValues = {
  borderColor: WeaveThemeValues['Color'];
  errorColor: WeaveThemeValues['Color'];
  focusColor: WeaveThemeValues['Color'];
  selectionColor: WeaveThemeValues['Color'];
};

export const checkboxTheme: CheckboxStyleValues = {
  borderColor: theme.colors.neutral40,
  errorColor: theme.colors.status.critical,
  focusColor: theme.colors.primary50,
  selectionColor: theme.colors.primary50,
};

export const checkboxThemeOriginal: CheckboxStyleValues = {
  borderColor: originalTheme.colors.gray500,
  errorColor: originalTheme.colors.error,
  focusColor: originalTheme.colors.weaveBlue,
  selectionColor: originalTheme.colors.weaveBlue,
};
