import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { theme as originalTheme } from '@frontend/theme-original';
import type { ButtonStyleValues } from '../button-theme';
import { buttonTheme, originalButtonTheme } from '../button-theme';
import { baseButtonStyle } from '../common.styles';
import { SecondaryButtonProps } from './types';

type StyleProps = Pick<SecondaryButtonProps, 'destructive' | 'size'>;

const styles =
  ({
    backgroundColor,
    borderRadius,
    color,
    destructive: destructiveStyle,
    disabled,
    focusRingColor,
    hoverColor,
    pressedColor,
    pressedBorderColor,
    fontFamily,
    ...variants
  }: ButtonStyleValues & { pressedBorderColor: string }) =>
  ({ size, destructive }: StyleProps) => {
    const fontColor = destructive ? destructiveStyle.fontColor : color;
    return [
      baseButtonStyle({ fontFamily, focusRingColor, borderRadius, ...variants }, size),
      css`
        background: transparent;
        border: 1px solid ${backgroundColor};
        border-radius: ${borderRadius};
        color: ${fontColor};
        transition: background 250ms ease-out, border-color 250ms ease-out;

        :hover,
        :focus {
          background: ${hoverColor};
          border-color: ${hoverColor};
        }

        :active {
          background: ${pressedColor};
          border-color: ${pressedBorderColor};
        }

        :disabled {
          background: white;
          border-color: ${disabled.borderColor};
          color: ${disabled.secondaryColor};
        }
      `,
    ];
  };

export const SecondaryButtonStylesOriginal = {
  SecondaryButton: styles({
    ...originalButtonTheme,
    backgroundColor: originalTheme.colors.gray400,
    color: originalTheme.colors.gray500,
    hoverColor: originalTheme.colors.gray200,
    pressedColor: 'transparent',
    pressedBorderColor: originalTheme.colors.weaveBlue,
  }),
};

export const SecondaryButtonStyles: typeof SecondaryButtonStylesOriginal = {
  SecondaryButton: styles({
    ...buttonTheme,
    backgroundColor: theme.colors.neutral30,
    color: theme.font.colors.default,
    hoverColor: theme.colors.neutral5,
    pressedColor: 'transparent',
    pressedBorderColor: theme.colors.neutral90,
  }),
};
