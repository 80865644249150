import { colors } from '../colors';
import { fontSize } from './font-size';

export const font = {
  family: "'DM Sans', sans-serif",
  colors: {
    primary: colors.primary50,
    default: colors.neutral90,
    light: colors.neutral60, // deprecate it?
    subdued: colors.neutral50,
    disabled: colors.neutral20,
    white: colors.white,
    error: colors.status.critical, // change key to critical?
    success: colors.status.success, //deprecate it?
    warn: colors.status.warning,
  },
  lineHeight: 1.5,
  size: {
    large: fontSize(16),
    medium: fontSize(14),
    small: fontSize(12),
    xs: fontSize(10),
    h1: fontSize(36),
    h2: fontSize(24),
    h3: fontSize(20),
    inherit: 'inherit',
  },
  weight: {
    regular: 'normal',
    semibold: 500,
    bold: 700,
    inherit: 'inherit',
  },
} as const;
