import { css } from '@emotion/react';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';

// Grayscale colors are ordered from Black to White
export const gray4 = '#666666';
export const gray7 = '#777777'; // 50% black
export const gray11 = '#D4DBDF';

export const noMargin = css`
  margin: 0;
`;

export const fullWidth = css`
  width: 100%;
`;

export const minWidth = css`
  width: min-content;
`;

export const maxWidth = css`
  width: max-content;
`;

export const inputField = css`
  margin-top: ${theme.spacing(3)};
`;

export const buttonRow = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: ${theme.spacing(3)};
  @media (min-width: ${breakpoints.small.min}px) {
    justify-content: space-between;
    flex-direction: row;
    .nextButton {
      width: min-content;
      margin-left: auto;
    }
  }
`;

export const submit = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${theme.spacing(2)};
  @media (min-width: ${breakpoints.small.min}px) {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    .submitButton {
      width: max-content;
      align-self: flex-start;
    }
    .submitButton:only-child {
      margin: 0 0 0 auto;
    }
  }
`;

export const pageContainer = css`
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

export const portalContainer = css`
  display: flex;
  flex-flow: column;
  padding: ${theme.spacing(2)};
  margin: 0 auto;
  width: 100%;
  @media (min-width: ${breakpoints.small.min}px) {
    width: 544px;
    position: relative;
    height: max-content;
    top: ${theme.spacing(4.5)};
  }
`;

export const portalHeader = css`
  display: flex;
  flex-flow: column;
  margin-bottom: ${theme.spacing(2)};
`;

export const iconTextWrapper = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};
`;

export const input = css`
  margin: 8px auto;
  border-radius: 5px;
  border: 1px solid ${theme.colors.neutral30};
  height: 40px;
  font-size: 16px;
  padding: 10px;
  width: 100%;

  &:focus {
    outline: none;
    border-color: ${theme.colors.primary50};
  }
`;

export const container = css`
  width: 350px;
  padding: 16px;
  margin: auto;
  margin-top: 0px;

  input {
    ${input}
  }

  button {
    display: block;
  }

  &,
  * {
    box-sizing: border-box;
  }
`;

export const accordionBase = css`
  > div {
    margin-bottom: ${theme.spacing(2)};
    border-bottom: 1px solid ${theme.colors.neutral10} !important;
    border-top: 1px solid ${theme.colors.neutral10} !important;
    border-radius: ${theme.borderRadius.medium} !important;
  }
`;

export const accordionHeader = css`
  margin: 0;
  font-size: ${theme.spacing(2.5)};
`;

export const paymentDetailsHeader = css`
  display: flex;
  flex-flow: row;
  gap: ${theme.spacing(1)};
`;

export const patientNameRow = css`
  display: flex;
  flex-flow: row;
  gap: ${theme.spacing(2)};
  margin-top: ${theme.spacing(2)};
  width: 100%;
  > div {
    flex-grow: 1;
  }
  @media (max-width: ${breakpoints.small.min}px) {
    flex-flow: column;
    & .first-name label {
      font-size: ${theme.fontSize(13)};
    }
  }
`;

export const attachmentContainer = css`
  @media (max-width: ${breakpoints.small.min}px) {
    margin-bottom: ${theme.spacing(2)};
  }
`;

export const paymentAmountRow = css`
  display: flex;
  flex-flow: column;
  width: 100%;
  align-items: center;
  margin-bottom: ${theme.spacing(3)};
  gap: ${theme.spacing(2)};
  & > div {
    width: 100%;
  }

  @media (min-width: ${breakpoints.small.min}px) {
    flex-flow: row;
    & > div {
      width: auto;
    }
    & > button {
      margin-top: ${theme.spacing(2)};
    }
  }
`;

export const checkboxInput = css`
  margin-top: ${theme.spacing(3)};
`;

export const addressElementContainer = css`
  overflow: scroll;
  min-height: 380px;
  height: fit-content;
`;

export const errorIcon = css`
  margin: 20px auto;
`;

export const stripeIcon = css`
  height: 20px;
  display: inline-block;
  margin-left: ${theme.spacing(3)};
  vertical-align: middle;
`;

export const smallText = css`
  font-size: 12px;
  color: ${gray7};
`;

export const buttonContainer = css`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: ${theme.spacing(2)};
  .legalItems {
    display: flex;
    align-content: center;
    margin-top: ${theme.spacing(3)};
  }
  .submitButton {
    width: 100%;
  }
  @media (min-width: ${breakpoints.small.min}px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    .legalItems {
      flex-direction: row;
      margin-top: ${theme.spacing(5)};
    }
  }
`;

export const resultMessage = css`
  text-align: center;
  color: ${gray7};
  max-width: 400px;
  margin: 0 auto;
`;

export const successContainer = css`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: ${theme.spacing(2)};
  gap: ${theme.spacing(1)};
  background: ${theme.colors.success5};
  margin-bottom: ${theme.spacing(2)};
`;

export const successIcon = css`
  margin-top: ${theme.spacing(0.5)};
`;

export const successIconImg = css`
  width: 100%;
`;

export const successText = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: none;
  color: ${theme.colors.success60};
`;

export const successHeader = css`
  color: ${theme.colors.success60};
  margin: 0;
`;

export const successBody = css`
  color: ${theme.colors.success50};
  margin: 0;
`;

export const footer = css`
  padding-top: ${theme.spacing(2)};
  padding-bottom: ${theme.spacing(2)};
  text-align: center;
  margin-top: ${theme.spacing(2)};
  a {
    text-decoration: none;
    color: ${theme.font.colors.subdued};
  }
  a:hover {
    color: ${theme.font.colors.default};
  }
`;

export const errorBody = css`
  line-height: 21px;
`;

export const modal = css`
  text-align: center;
  min-height: 300px;

  & p {
    color: ${gray7};
  }
`;

export const confirmContainer = css`
  display: flex;
  flex-flow: column;
  font-weight: ${theme.font.weight.semibold};
  margin: ${theme.spacing(2, 0, 3)};
  gap: ${theme.spacing(2)};

  @media (min-width: ${breakpoints.xsmall.max}px) {
    display: grid;
    grid-template-columns: repeat(2, minmax(auto, 1fr));
  }
`;

export const confirmKeys = css`
  margin: 0px;
  color: ${theme.colors.neutral60};
`;
export const confirmData = css`
  margin: 0px;
`;

export const birthdate = css`
  margin-top: 25px;

  & input {
    width: 65px;
    margin: 10px;
    text-align: center;

    &::placeholder {
      color: ${theme.colors.neutral30};
    }

    &:focus {
      border-color: ${theme.colors.primary50};
    }
  }

  & > div > span {
    display: inline-block;
    width: 65px;
    text-align: center;
    margin: 0 10px 10px;
  }
`;

export const infoText = css`
  font-size: 15px;
`;

export const securityBadges = css`
  margin-top: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const spinnerBody = css`
  margin-top: 42px;
`;

export const invoiceCard = css`
  display: flex;
  flex-flow: row;
  align-content: center;
  justify-content: space-between;
  border: 1px solid ${theme.colors.primary50};
  border-radius: ${theme.borderRadius.small};
  padding: ${theme.spacing(2)};
  margin-top: ${theme.spacing(2)};
`;

export const requestedAmount = css`
  display: flex;
  flex-flow: column;
`;

export const amountText = css`
  font-size: ${theme.fontSize(16)};
`;
