import { PhoneOverride } from '@weave/schema-gen-ts/dist/schemas/phone/override/v1/override.pb';
import { StateCreator } from 'zustand';
import { createStoreWithSubscribe, createShallowStore } from '@frontend/store';

export type PhoneOverrideStore = {
  overrides: PhoneOverride[];
  setOverrides: (overrides: PhoneOverride[]) => void;
};

const storeDefinition: StateCreator<
  PhoneOverrideStore,
  [['zustand/subscribeWithSelector', never], ['zimmer', never], ['zustand/devtools', never]]
> = (set) => ({
  overrides: [],
  setOverrides: (overrides: PhoneOverride[]) => {
    set({ overrides });
  },
});

export const usePhoneOverrideStore = createStoreWithSubscribe<PhoneOverrideStore>(storeDefinition, {
  name: 'PhoneOverrideStore',
  trace: true,
});

export const usePhoneOverrideShallowStore = createShallowStore(usePhoneOverrideStore);
