import { ReactNode } from 'react';
import { useStepperContext } from '../provider/stepper.provider';
import { useStepperCardContext } from '../provider/stepper-card.provider';

export type StepperContentProps = {
  children: ReactNode[] | ReactNode;
};

export const StepperContent = ({ children, ...rest }: Partial<StepperContentProps>) => {
  const { stepStatus } = useStepperContext();
  const { step } = useStepperCardContext();

  return (
    <>
      {(stepStatus[step] === 'currActive' || stepStatus[step] === 'active' || stepStatus[step] === 'errorActive') && (
        <div className='step-content' {...rest}>
          {children}
        </div>
      )}
    </>
  );
};
