/**
 * Converts an object's values to be a css var following the object key.
 * For example:
 * ```
 * theme.colors.status.success="green" => theme.colors.status.success="var(--colors-status-success)"
 * ```
 *
 * @param themeNode A theme or part of a theme
 * @param previousPath the any previous path to append to the css var
 * @returns
 */
export const convertToCssVars = <T extends Record<string, any>>(themeNode: T, previousPath = '') => {
  return Object.entries(themeNode).reduce((acc, [key, value]): Record<string, any> => {
    const property = (previousPath || '--') + key;
    switch (typeof value) {
      case 'string':
      case 'number': {
        return {
          ...acc,
          [key]: `var(${property})`,
        };
      }
      case 'object': {
        return {
          ...acc,
          [key]: convertToCssVars(value, property + '-'),
        };
      }
      default:
        return { ...acc, [key]: value };
    }
    // return the same type given to keep the dev
    // experience of using the theme the same
  }, {} as Record<string, any>) as T;
};
