import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { UnderlineAction } from '../../molecules';
import { FORMAT_TEXT_COMMAND } from 'lexical';
import { useCallback } from 'react';
import { useToolbarState } from '../../providers';

export const Underline = () => {
  const [editor] = useLexicalComposerContext();
  const { isUnderline } = useToolbarState(['isUnderline']);

  const onUnderlineSelect = useCallback(() => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
  }, [editor]);

  return <UnderlineAction onClick={onUnderlineSelect} isActive={isUnderline} aria-label='Format Underline' />;
};

Underline.displayName = 'Underline';
