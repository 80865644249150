import React, { ReactNode, useCallback, useMemo } from 'react';
import type { IconName } from '@frontend/icons';
import { contextFactory } from '@frontend/design-system';
import { type WidgetSizes } from '../../constants';
import { WidgetizedDashboardTrackingIds } from '../../tracking-ids';

interface DashboardWidgetContext {
  id: string;
  title: string;
  icon: IconName;
  sizes: WidgetSizes[];
  currentSize: WidgetSizes;
  isEditMode?: boolean;
  currentBreakpoint: 'large' | 'medium' | 'small' | 'extraSmall';
  onWidgetRemove?: (id: string) => void;
  onSizeChange?: (currentSize: WidgetSizes) => void;
  widgetTrackingId: (label: string) => string;
}

export const [DashboardWidgetContext, useDashboardWidget] = contextFactory<DashboardWidgetContext>();

interface DashboardWidgetProviderProps {
  id: string;
  title: string;
  icon: IconName;
  sizes?: WidgetSizes[];
  currentSize: WidgetSizes;
  isEditMode?: boolean;
  currentBreakpoint: 'large' | 'medium' | 'small' | 'extraSmall';
  children: ReactNode;
  onWidgetRemove?: (id: string) => void;
  onSizeChange?: (currentSize: WidgetSizes) => void;
}

export const DashboardWidgetProvider: React.FC<DashboardWidgetProviderProps> = ({
  id,
  title,
  icon,
  sizes = [],
  currentSize,
  isEditMode,
  currentBreakpoint,
  children,
  onWidgetRemove,
  onSizeChange,
}) => {
  const memoizedSizes = useMemo(() => sizes, [sizes]);
  const widgetTrackingId = useCallback(
    (label: string) => WidgetizedDashboardTrackingIds.widgetSpecificTrackingId(id)(label),
    [id]
  );

  const contextValue = useMemo(
    () => ({
      id,
      title,
      icon,
      sizes: memoizedSizes,
      currentSize,
      isEditMode,
      currentBreakpoint,
      onWidgetRemove,
      onSizeChange,
      widgetTrackingId,
    }),
    [
      id,
      title,
      icon,
      memoizedSizes,
      currentSize,
      isEditMode,
      currentBreakpoint,
      onWidgetRemove,
      onSizeChange,
      widgetTrackingId,
    ]
  );

  return <DashboardWidgetContext.Provider value={contextValue}>{children}</DashboardWidgetContext.Provider>;
};
