/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const ForwardIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M8.08363094,4.36611652 C8.57178631,3.87796116 9.36324253,3.87796116 9.8513979,4.36611652 C10.3395533,4.85427189 10.3395533,5.64572811 9.8513979,6.13388348 L9.8513979,6.13388348 L5.252,10.732 L21.782233,10.732233 C22.472589,10.732233 23.032233,11.2918771 23.032233,11.982233 C23.032233,12.672589 22.472589,13.232233 21.782233,13.232233 L5.282,13.232 L9.8513979,17.8011454 C10.3070096,18.256757 10.3373837,18.9765775 9.94252023,19.467417 L9.8513979,19.5689123 C9.36324253,20.0570677 8.57178631,20.0570677 8.08363094,19.5689123 L8.08363094,19.5689123 L1.36611652,12.8513979 L1.28030796,12.7564626 C0.879868016,12.2654953 0.908470869,11.5412766 1.36611652,11.0836309 L1.36611652,11.0836309 Z'
      transform='translate(12.016117, 11.967514) scale(-1, 1) translate(-12.016117, -11.967514) '
    />
  </Icon>
);
