import { FC } from 'react';
import { Interpolation, Theme, css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Heading, SecondaryButton } from '@frontend/design-system';
import { useCardACHSelectionContext } from '../context';
import { PaymentMethodList } from './payment-method-list';

interface CardACHSelectionContentProps {
  contentStyles?: Interpolation<Theme>;
  achDisabledMessage?: string;
}

export const CardACHSelectionContent: FC<CardACHSelectionContentProps> = ({ contentStyles }) => {
  const { t } = useTranslation('payments');
  const { isLoading, achList, achOnFileEnabled, addACHEnabled, openAddACH, openAddCard } = useCardACHSelectionContext();

  const showACHList = achOnFileEnabled && (isLoading || !!achList.length || addACHEnabled);

  return (
    <section css={[containerStyles, contentStyles]}>
      <Heading level={2}>{t('Card on File')}</Heading>
      <PaymentMethodList type='card' />
      <SecondaryButton css={addBtnStyles} disabled={isLoading} onClick={openAddCard}>
        <Icon name='plus-small' />
        {t('Add New Card')}
      </SecondaryButton>
      {showACHList && (
        <>
          <Heading level={2}>{t('ACH on File')}</Heading>
          <PaymentMethodList type='ach' />
          {addACHEnabled && (
            <SecondaryButton css={addBtnStyles} disabled={isLoading} onClick={openAddACH}>
              <Icon name='plus-small' />
              {t('Add New ACH')}
            </SecondaryButton>
          )}
        </>
      )}
    </section>
  );
};

const containerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(0, 3),
  overflowY: 'auto',
});

const addBtnStyles = css({
  width: 'max-content',
  gap: theme.spacing(0.5),
  display: 'flex',
  alignItems: 'center',
});
