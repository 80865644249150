import { css } from '@emotion/react';
import type { ListboxLiIconThemeValues, ListboxLiThemeValues } from './listbox-li-theme';
import {
  listboxLiIconTheme,
  listboxLiIconThemeOriginal,
  listboxLiTheme,
  listboxLiThemeOriginal,
} from './listbox-li-theme';
import { ListboxOptionGetterProps } from './use-listbox';

const liStyles = (
  { color, disabledColor, focusedBackground, fontSize, spacing }: ListboxLiThemeValues,
  liStylesProps: ListboxOptionGetterProps
) => css`
  color: ${color};
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${fontSize(16)};
  line-height: 1.375;
  padding: ${spacing(1, 2)};
  ${liStylesProps['aria-disabled']
    ? `color: ${disabledColor};
       cursor: not-allowed;`
    : `:hover,
      &[data-focused='true'] {
      background: ${focusedBackground};
      }`}
`;

const iconStyles = ({ spacing }: ListboxLiIconThemeValues, iconStylesProps: ListboxOptionGetterProps) => css`
  flex-shrink: 0;
  margin-left: ${spacing(2)};
  visibility: ${iconStylesProps['aria-selected'] ? 'visible' : 'hidden'};
`;

export const ListboxLiStyles = {
  ListboxLi: {
    liStyles: (props) => liStyles(listboxLiTheme, props),
    iconStyles: (props) => iconStyles(listboxLiIconTheme, props),
  },
};
export const ListboxLiStylesOriginal: typeof ListboxLiStyles = {
  ListboxLi: {
    liStyles: (liStylesProps: ListboxOptionGetterProps) => liStyles(listboxLiThemeOriginal, liStylesProps),
    iconStyles: (iconStylesProps: ListboxOptionGetterProps) => iconStyles(listboxLiIconThemeOriginal, iconStylesProps),
  },
};
