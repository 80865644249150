import { PaymentMethod_PaymentMethodStatus as PaymentMethodStatus } from '@weave/schema-gen-ts/dist/schemas/payments/messages/views/views.pb';
import { CreditCardBrand } from '@frontend/api-invoices';
import { SchemaIO, SchemaPaymentsCommandsService } from '@frontend/schema';

export interface PaymentPlanSummary {
  totalPlans: number;
  expectedPayments: number;
  outstandingBalance: number;
  needActionPlansCount: number;
  missedPayments: number;
}

export interface PaymentPlanPerson {
  email?: string;
  firstName: string;
  homeNumber?: string;
  id: string;
  lastName: string;
  mobileNumber?: string;
  pmId?: string;
}

export enum PaymentPlanConfirmationStatus {
  Pending = 'PENDING',
  Accept = 'ACCEPT',
  Deny = 'DENY',
  PausedByOffice = 'PAUSED_BY_OFFICE',
}

export enum PaymentPlanConfirmationFrom {
  Customer = 'CUSTOMER',
  Office = 'OFFICE',
}

export enum PaymentPlanStatus {
  Active = 'PLAN_IN_PROGRESS',
  Paused = 'PLAN_PAUSED',
  Completed = 'PLAN_COMPLETED',
  Failed = 'PLAN_FAILED',
  Canceled = 'PLAN_CANCELED',
}

export enum PaymentPlanInvoiceStatus {
  Paid = 'PAID',
  Partial = 'PARTIALLY_PAID',
  Unpaid = 'UNPAID',
  Scheduled = 'SCHEDULED',
  Refunded = 'REFUNDED',
  Paused = 'PAUSED',
  Canceled = 'CANCELED',
}

export enum PaymentPlanFrequency {
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  BiWeekly = 'BI_WEEKLY',
}

export enum PaymentPlanPaymentType {
  Scheduled = 'SCHEDULED_PAYMENT',
  Balance = 'BALANCE_PAYMENT',
  Next = 'NEXT_PAYMENT',
}

export type PaymentPlanStatusKeys = keyof typeof PaymentPlanStatus;

export type PaymentPlanInvoiceStatusKeys = keyof typeof PaymentPlanInvoiceStatus;

export type PaymentPlanFrequencyKeys = keyof typeof PaymentPlanFrequency;

export interface CreatePlanResponse {
  workflowId: string;
}

export interface PaymentPlanModel {
  createdAt: string;
  externalInvoiceId: string;
  id: string;
  locationId: string;
  name: string;
  paymentSummary: {
    remainingAmount: number;
    paidAmount: number;
  };
  person: PaymentPlanPerson;
  schedule: PaymentPlanSchedule;
  status: PaymentPlanStatus;
  statusReason?: string;
  updatedAt: string;
  userId: string;
}

export interface PaymentPlanLocationInfo {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
  onlineBillPayLink: string;
}

export interface PaymentPlanSchedule {
  amount: number;
  finalPaymentAt: string;
  frequency: PaymentPlanFrequency;
  lastPaymentAmount?: number;
  nextPaymentAt?: string | null;
  paymentAmount: number;
  paymentTerm: number;
  startAt: string;
}
export interface PaymentPlanEdge {
  node: PaymentPlanModel;
  cursor: string;
}

export interface PaymentPlanUpdateFields {
  amount?: number;
  frequency?: PaymentPlanFrequency;
  paymentMethodId?: string;
  paymentTerm?: number;
  startAt?: string;
  notes?: string;
  type?: string;
  customerEmailId?: string;
  status?: PaymentPlanStatus;
}
export interface PageInfo {
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  startCursor: string;
  endCursor: string;
}

export interface PageParams {
  after?: string;
  before?: string;
  first?: number;
  last?: number;
}

export type PageParamsInStore = PageParams & { currentPage: number };

export interface PaymentPlanListResponse {
  summary: PaymentPlanSummary;
  pageInfo?: PageInfo;
  paymentPlan?: {
    edges?: PaymentPlanEdge[];
  };
}

export type PaymentPlanPaymentMethod = {
  cardInfo: PlanDetailsCardInfo;
  paymentMethodId: string;
  bankAccountInfo: PaymentMethodBankAccountInfo | null;
};

export interface PaymentPlanDetailsResponse {
  createdAt: string;
  id: string;
  locationId: string;
  name: string;
  notes?: string;
  confirmationDetails: {
    confirmationStatus: PaymentPlanConfirmationStatus;
    isConfirmationLinkExpired: boolean;
  };
  paymentMethod: PaymentPlanPaymentMethod;
  paymentSummary: {
    remainingAmount: number;
    remainingTerms: number;
    paidAmount: number;
    paidTerms: number;
  };
  person: PaymentPlanPerson;
  schedule: PaymentPlanSchedule;
  status: PaymentPlanStatus;
  statusReason: string;
  updatedAt: string;
  userId: string;
  location: PaymentPlanLocationInfo;
}

export interface PlanDetailsCardInfo {
  brand: CreditCardBrand;
  country: string;
  expMonth: number;
  expYear: number;
  fingerprint: string;
  funding: string;
  lastFour: string;
}

export interface PaymentMethodCardType {
  brand: string;
  country: string;
  expMonth: number;
  expYear: number;
  fingerprint: string;
  funding: string;
  last4: string;
}

export interface PaymentMethodBankAccount {
  last4: string;
  bankName: string;
  accountType: string;
}

export interface PaymentMethodBankAccountInfo {
  lastFour: string;
  bankName: string;
  type: string;
}

export interface PaymentPlanUpdateReviewResponse {
  newPlan: PaymentPlanDetailsResponse;
  previousPlan: PaymentPlanDetailsResponse;
}

export interface PaymentPlanDetailsHistoryResponse {
  balancePaid: number;
  planHistory: PaymentPlanHistoryItem[];
}

export interface PaymentPlanHistoryItem {
  type: string;
  occurredAt: string;
  plan?: PaymentPlanHistoryItemData;
  payment?: PaymentPlanHistoryInvoiceData;
}

export interface PaymentPlanHistoryItemData {
  from?: PaymentPlanDetailsResponse;
  to?: PaymentPlanDetailsResponse;
}

export interface PaymentPlanHistoryInvoiceData {
  amount: number;
  balanceRemaining: number;
  createdAt: string;
  finalPaymentAt: string;
  hasRefund: boolean;
  id: string;
  name: string;
  paidAmount: number;
  paidAt?: string;
  paymentAmount: number;
  paymentId: string;
  paymentMethod: PaymentPlanPaymentMethod;
  remainingTerm: number;
  recordedAt?: string;
  scheduledFor: string;
  status: PaymentPlanInvoiceStatus;
}

export type PaymentPlanDateObject = {
  greaterThan?: string;
  greaterThanEqual?: string;
  lessThan?: string;
  lessThanEqual?: string;
  equal?: string;
};

export type PaymentPlanFilterType = {
  search?: { text?: string };
  nextPaymentAt?: PaymentPlanDateObject;
  startedAt?: PaymentPlanDateObject;
  endedAt?: PaymentPlanDateObject;
  status?: PaymentPlanStatus[];
};

export interface PaymentPlanSearchParams extends PaymentPlanFilterType {
  limit?: number;
  skip?: number;
  order?:
    | 'startedAt'
    | '-startedAt'
    | 'nextPaymentAt'
    | '-nextPaymentAt'
    | 'person'
    | '-person'
    | 'balanceRemaining'
    | '-balanceRemaining'
    | 'paymentAmount'
    | '-paymentAmount'
    | 'name'
    | '-name';
}

export interface PersonPaymentMethodsResponse {
  links: {
    self: string;
    setups: string;
  };
  personPaymentMethods: Record<string, string[]>;
}

export interface PaymentMethodResponse {
  id: string;
  links?: {
    payments: string;
    self: string;
  };
  locationId: string;
  personId: string;
  paymentMethod?: PaymentMethod;
}

export type PaymentMethodType = 'card' | 'us_bank_account';

export interface PaymentMethod {
  id: string;
  is_default: boolean;
  type: PaymentMethodType;
  card: PaymentMethodCardType;
  bankAccount: PaymentMethodBankAccount;
  status?: PaymentMethodStatus;
}
export interface CreatePlanData {
  name?: string;
  externalInvoiceId?: string;
  amount?: number;
  frequency?: PaymentPlanFrequency;
  personId?: string;
  customerEmailId?: string;
  paymentMethodId?: string;
  paymentTerm?: number;
  startAt?: string;
  notes?: string;
}

export interface ClientSecretLinkResponse {
  headers: Headers;
}

export interface PlanConfirmationRequest {
  id: string;
  confirmationStatus?: PaymentPlanConfirmationStatus;
  confirmedAt: string;
  confirmationFrom: PaymentPlanConfirmationFrom;
  expireLink: boolean;
  status?: PaymentPlanStatus;
}

export interface PlanConfirmationResponse {
  data: {
    workflowId: string;
    links: {
      self: string;
    };
  };
}

export type PlanDetailsType = {
  planName?: string;
  planAmount?: number;
  invoiceId?: string;
  patient?: PaymentPlanPerson;
  customerEmailId?: string;
  termAmount?: number;
  frequency?: PaymentPlanFrequency;
  paymentTerm?: string;
  paymentDate?: string;
  billingMethod?: PaymentMethod;
  memo?: string;
};

export type ConfirmPlanData = Pick<PlanConfirmationRequest, 'expireLink' | 'confirmationStatus' | 'status'>;

export type SendConfirmationEmailRequest = { email: string; planId: string };

export interface ClientSecretResponse {
  clientSecret: string;
}

export interface GetLocationPaymentMethodsRequest {
  paymentsUrl: string | null;
  locationId: string;
}

export interface GetPersonPaymentMethodsRequest extends GetLocationPaymentMethodsRequest {
  patientId: string;
}

export type CreatePMRegistrationLinkRequest = SchemaIO<
  (typeof SchemaPaymentsCommandsService)['CreatePaymentMethodRegistrationLink']
>['input'] & {
  locationId: string;
};

export type CreatePMRegistrationLinkResponse = SchemaIO<
  (typeof SchemaPaymentsCommandsService)['CreatePaymentMethodRegistrationLink']
>['output'];

export { PaymentMethod_PaymentMethodStatus as PaymentMethodStatus } from '@weave/schema-gen-ts/dist/schemas/payments/messages/views/views.pb';
