import { memo } from 'react';
import { css } from '@emotion/react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { useChartContext } from '../chart.provider';

type Props = {
  metric: string;
  rank: 'highest' | 'lowest';
};

export const Rank = memo(({ metric, rank }: Props) => {
  const { labels } = useChartContext();

  return (
    <div css={styles} style={{ color: rank === 'highest' ? theme.colors.success40 : theme.colors.critical40 }}>
      <Icon name='star-small' />
      <Text color={rank === 'highest' ? 'success' : 'error'} size='small'>
        {(rank === 'highest' ? labels['highestMetric'] : labels['lowestMetric']) || labels[metric] || metric}
      </Text>
    </div>
  );
});

Rank.displayName = 'Rank';

const styles = css`
  align-items: center;
  display: flex;
  gap: ${theme.spacing(0.5)};
`;
