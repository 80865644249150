import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { validateBirthday } from '@frontend/api-weave-pay';
import { http } from '@frontend/fetch';
import { Trans, useTranslation } from '@frontend/i18n';
import {
  Text,
  useFormField,
  FormRow,
  PrimaryButton,
  DateField,
  Modal,
  useModalControl,
  SpinningLoader,
  useAlert,
} from '@frontend/design-system';
import * as styles from '../../../styles/weave-pay.styles';
import EncryptIcon from '../assets/encrypt.png';
import HippaIcon from '../assets/hippa.png';

interface Props {
  link: string;
  closeModal: () => void;
  personName?: string;
}

export const Auth = ({ link, closeModal, personName }: Props) => {
  const { t } = useTranslation('weave-pay');
  const alerts = useAlert();
  const [submitReady, setSubmitReady] = useState(false);
  const {
    modalProps: authenticatingModalProps,
    openModal: openAuthenticatingModal,
    closeModal: closeAuthenticatingModal,
  } = useModalControl();

  const dateFieldProps = useFormField({
    required: true,
    minDate: '12/31/1899',
    maxDate: new Date().toLocaleDateString(),
    type: 'date',
  });

  const checkBirthdayValidity = async () => {
    openAuthenticatingModal();
    try {
      await validateBirthday(link, dateFieldProps.value);
      setSubmitReady(true);
    } catch (err) {
      if (http.isHttpError(err) && err.status === 404) {
        alerts.error({
          autoDismissAfter: 12000,
          message: t('Birthday did not match our records.'),
        });
        setSubmitReady(false);
      } else {
        setSubmitReady(true);
      }
    } finally {
      closeAuthenticatingModal();
    }
  };

  useEffect(() => {
    if (!dateFieldProps.error) {
      checkBirthdayValidity();
    } else {
      setSubmitReady(false);
    }
  }, [dateFieldProps.value]);

  const submitBirthdate = async () => {
    closeModal();
  };

  return (
    <>
      <form css={styles.resultMessage} method='post' action={link} onSubmit={submitBirthdate} target='_blank'>
        <Text css={styles.infoText}>
          <Trans t={t}>
            This payment request includes a secure billing attachment. To view the attachment, please authenticate by
            filling in the information below.
          </Trans>
        </Text>

        <div css={styles.birthdate}>
          <Text color='light' style={{ lineHeight: '0px' }}>
            {t('Birthday of')}
          </Text>
          <Text weight='bold'>{personName || t('Yourself')}</Text>
          <FormRow>
            <DateField {...dateFieldProps} name='birthday' label={t('Birthday')} />
          </FormRow>
        </div>

        <PrimaryButton type='submit' disabled={!submitReady}>
          {t('Open Invoice')}
        </PrimaryButton>

        <div css={styles.securityBadges}>
          <img
            src={EncryptIcon}
            css={css`
              height: 50px;
              margin-right: 24px;
              margin-top: 8px;
            `}
            alt='Secure'
          />
          <img
            src={HippaIcon}
            css={css`
              height: 40px;
            `}
            alt='HIPPA Compliant'
          />
        </div>
      </form>
      <Modal {...authenticatingModalProps} maxWidth={300} css={styles.modal}>
        <Modal.Header>{t('Authenticating Birthday')}</Modal.Header>
        <Modal.Body css={styles.spinnerBody}>
          <SpinningLoader size='xl' />
        </Modal.Body>
      </Modal>
    </>
  );
};
