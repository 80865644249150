import { useEffect, useMemo, useReducer, Reducer } from 'react';
import { useUid, useExecutionCounter } from '../../../../hooks';
import type {
  FieldConfig,
  FieldConfigUnion,
  FieldKey,
  FormFieldActions,
  FormFieldState,
  ResolvedFieldProps,
} from '../types';
import { FormFieldActionTypes } from '../types';
import { initializeFieldState, normalizeChangeHandler, validateField, getError, trimIfString } from '../utils';
import { setValue } from '../value-setters';

export const reducer = (state, action) => {
  switch (action.type) {
    case FormFieldActionTypes.Blur: {
      const nextState = {
        ...state,
        active: false,
        touched: true,
        value: trimIfString(state.value),
      };
      return { ...nextState, ...validateField(nextState) };
    }
    case FormFieldActionTypes.Focus:
      return { ...state, active: true };
    case FormFieldActionTypes.Reset:
      return initializeFieldState({ ...state, ...action.payload });
    case FormFieldActionTypes.Update: {
      const { value } = action.payload;
      const shouldValidateOnChange = state.validateOnChange ? { touched: true } : {};
      const nextState = setValue({ ...state, ...shouldValidateOnChange }, value);
      return {
        ...nextState,
        ...(nextState.touched ? validateField(nextState) : { error: getError(nextState) }),
      };
    }
    default:
      return state;
  }
};

type StateReducer<T extends FieldKey> = Reducer<FormFieldState<T>, FormFieldActions>;

/**
 * Hook for easy, one-off field handling. This should be used for fields that aren't part of a larger form.
 * @param props Field seed values and other config (eg validation and limits.)
 * @param changeDeps Dependencies that should update seed values. For example, if you are loading a value from an async source, or have validation properties that might update dynamically (eg max, min on a money field). Ideally these should be primitive values.
 */
export function useFormField<T extends FieldKey>(props: FieldConfig<T>, changeDeps: any[] = []): ResolvedFieldProps<T> {
  const id = useUid('field');
  const runCount = useExecutionCounter();
  const [state, dispatch] = useReducer<StateReducer<T>, FieldConfig<T>>(reducer, props, initializeFieldState);

  // The onBlur, onFocus and onChange handlers from useForm and useFormField
  // are stable values and can be left out of effects dependency arrays.
  // They will trigger effects as new values because they are returned via spread
  // to mix in with other frequently changing field + form values.
  const handlers = useMemo(
    () => ({
      onBlur: () => {
        dispatch({ type: FormFieldActionTypes.Blur });
      },
      onChange: normalizeChangeHandler((payload) => {
        dispatch({ type: FormFieldActionTypes.Update, payload });
      }),
      onFocus: () => {
        dispatch({ type: FormFieldActionTypes.Focus });
      },
    }),
    [dispatch]
  );

  useEffect(() => {
    if (runCount.current > 0) {
      dispatch({ type: FormFieldActionTypes.Reset, payload: props as FieldConfigUnion });
    }
  }, changeDeps);

  const { validateOnChange, ...rest } = state;
  const field: unknown = useMemo(() => ({ ...rest, ...handlers, id: id.current }), [state]);
  return field as ResolvedFieldProps<T>;
}
