import { css } from '@emotion/react';
import { mergeClasses } from '../../../helpers';
import { NakedButton } from '../../../naked';
import type { CalendarWeekType } from '../calendar-types';
import { useCalendarState } from '../provider';
import type { BaseAtomProps } from './calendar-atom.types';

const dayClass = css`
  text-align: center;
`;

type CalendarWeekProps = BaseAtomProps & {
  days: CalendarWeekType;
};

export const CalendarWeek = ({ className, days, ...rest }: CalendarWeekProps) => {
  const { getDayButtonProps } = useCalendarState();
  return (
    <div
      className={mergeClasses('cal__week', className)}
      css={css`
        display: flex;
        justify-content: space-between;
      `}
      {...rest}
    >
      {days.map((day, i) =>
        !day ? (
          <div className='cal__day cal__day--empty' key={i} css={dayClass} />
        ) : (
          <NakedButton key={i} className='cal__day' css={dayClass} type='button' {...getDayButtonProps(day)}>
            {day.day}
          </NakedButton>
        )
      )}
    </div>
  );
};
