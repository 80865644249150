import { mergeClasses } from '../../../helpers';
import { Heading } from '../../heading';
import { useModalContext } from '../provider';
import { IconButton } from '../../icon-button/icon-button.component';
import { XIcon } from '../../../icon/icons/x';

type Props = Omit<React.HTMLAttributes<HTMLHeadingElement>, 'color'> & {
  onClose?: () => void;
  closeBtnTrackingId?: string;
  children?: React.ReactNode;
  textAlign?: 'left' | 'center';
};

export const ModalHeader = ({
  children,
  className,
  textAlign = 'left',
  closeBtnTrackingId = 'modal-close-icon-button',
  onClose,
  ...rest
}: Props) => {
  const { titleId } = useModalContext();

  return (
    <header
      css={{ display: 'grid', gridTemplateColumns: '1fr auto', alignItems: 'center' }}
      className={mergeClasses('wv-modal-header', className)}
    >
      <Heading
        css={{
          margin: 0,
        }}
        level={2}
        textAlign={textAlign}
        {...rest}
        id={titleId}
      >
        {children}
      </Heading>
      {onClose && (
        <IconButton trackingId={closeBtnTrackingId} label='close' onClick={onClose} css={{ alignSelf: 'baseline' }}>
          <XIcon />
        </IconButton>
      )}
    </header>
  );
};
