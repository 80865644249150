import { themeValues } from './theme';
import { css, Global } from '@emotion/react';
import Inter from './font-assets/Inter-Regular.ttf';
import InterSemiBold from './font-assets/Inter-SemiBold.ttf';
import InterBold from './font-assets/Inter-Bold.ttf';

const styles = css`
  @font-face {
    font-family: 'Inter';
    src: url(${Inter}) format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Inter';
    src: url(${InterSemiBold}) format('truetype');
    font-weight: 600;
  }

  @font-face {
    font-family: 'Inter';
    src: url(${InterBold}) format('truetype');
    font-weight: 700;
  }

  html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
  }

  html,
  body {
    margin: 0;
    padding: 0;
  }

  * {
    box-sizing: inherit;
    /* 
      Adding an important tag to force this use in 
      the desktop client to override the DM Sans that is 
      used in the DS components
    */
    font-family: ${themeValues.font.family}!important;
  }

  *::before,
  *::after {
    box-sizing: inherit;
  }

  *::selection {
    background: ${themeValues.colors.text.highlight};
    color: ${themeValues.colors.text.white};
  }
`;

export const UniversalBaseStyle = () => <Global styles={styles} />;
