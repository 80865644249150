import { PersonsOrder } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import {
  PersonsResponse,
  SearchPersonsLegacyRequest,
} from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons_service.pb';
import { PersonOrderBy_Enum } from '@weave/schema-gen-ts/dist/shared/persons/v3/enums.pb';
import { UseQueryOptions } from 'react-query';
import { arrayBufferToBase64 } from '@frontend/array';
import { Options, http } from '@frontend/fetch';
import { useQuery, ContextlessQueryObserverOptions } from '@frontend/react-query-helpers';
import type {
  Person,
  HouseholdMember,
  AgingData,
  Note,
  NewNote,
  HouseholdTasks,
  UploadPersonImageResponse,
  DeletePersonImageResponse,
} from './types';
import { PersonTypes } from '.';

type GetPersonImage = (personId: string) => Promise<string>;

const baseQueryKey = ['person'];
export const queryKeys = {
  personExtended: (personId: string, groupId?: string) => [...baseQueryKey, 'personExtended', personId, groupId],
};

export const deletePersonImage = (personId: string) => {
  return http.delete<DeletePersonImageResponse>(`/photo/v1/person/${personId}`);
};

export const uploadPersonImage = async (personId: string, image: File | Blob) => {
  const formData = new FormData();
  formData.append('image', image);
  formData.append('source', 'weave');
  return await http.post<UploadPersonImageResponse>(`/photo/v1/person/${personId}`, formData);
};

export const getPersonImage: GetPersonImage = async (personId) => {
  const res = await http
    .get<ArrayBuffer>(`photo/v1/person/${personId}`, { responseType: 'arrayBuffer' })
    .then((res) => {
      if (!res || !res.byteLength) {
        throw new Error('Person Not Found');
      }
      return res;
    })
    .then(arrayBufferToBase64)
    .then((res) => {
      return `data:image/png;base64,${res}`;
    });

  return res;
};

export const getHouseholdTasks = (householdId: string, locationId?: string) =>
  http.get<HouseholdTasks>(
    `task/${householdId}/set`,
    !!locationId ? { headers: { 'Location-Id': locationId } } : undefined
  );

export const putHouseholdTask = ({
  taskId,
  locationId,
  payload,
}: {
  taskId: string;
  locationId: string;
  payload: { status: 'completed' | 'uncompleted' };
}) => http.put(`task/${taskId}`, payload, !!locationId ? { headers: { 'Location-Id': locationId } } : undefined);

export const getHouseholdNotes = (householdId: string) => http.get<Note[]>(`notes/?filter=household_id~${householdId}`);

export const addNote = ({ note }: { note: NewNote }) => http.post<Note>(`notes`, note);

export const putHouseholdNote = ({ noteId, note }: { noteId: Note['id']; note: Note }) =>
  http.put<Note>(`notes/${noteId}`, note);

export const deleteHouseholdNote = ({ noteId }: { noteId: Note['id'] }) => {
  return http.delete(`notes/${noteId}`);
};

export const getPersonAging = (householdId: string) => http.get<AgingData>(`household/${householdId}/aging`);

export const getPersonHousehold = (householdId: string, options?: Options) =>
  http.get<HouseholdMember[]>(`household/${householdId}/contact`, options);

export const getPersonByPhone = (number: string) => http.getData<Person>(`portal/v1/person/${number}`);

export const updatePersonPreferences = (personId: string, preferences: Record<string, any>, locationId?: string) =>
  http.post(`desktop/v1/persons/${personId}/preferences`, preferences, {
    headers: locationId ? { 'Location-Id': locationId } : undefined,
  });

export const getPersonsByPhone = (name: string) =>
  getPersonsV2({ query: name, status: 'Active', order: ['last_name', 'first_name'] });

export const getPersonsByName = (name: string) =>
  getPersonsV2({ query: name, status: 'Active', order: ['last_name', 'first_name'] });

const LOCATION_ID_HEADER = 'Location-Id';

type PersonsParams = {
  limit: number;
  order: string;
  skip: number;
  query: string;
  status: Person['Status'] | '';
  ds: string[];
};

export const getPersons = async (
  limit: number,
  skip = 0,
  query = '',
  status: Person['Status'] | '',
  locationId = '',
  dataSourceIds: string[] = []
) => ({
  rows: await http.getData<Person[]>(`desktop/v1/persons`, {
    params: http
      .paramBuilder<PersonsParams>()
      .addParam('limit', limit)
      .addParam('order', 'last_name')
      .addParam('skip', skip)
      .addParam('query', query)
      .addParam('status', status, !!status)
      .addParam('ds', dataSourceIds)
      .build(),
    headers: locationId ? { [LOCATION_ID_HEADER]: locationId } : undefined,
  }),
  nextOffset: skip + 1,
});

type PersonV3Params = {
  'page.size': number;
  'page.token'?: string;
  order: string;
  responseLimit: number;
  search?: string;
  statusFilter?: string;
  locationIds?: string;
  sourceIds?: string[];
};

export const getPersonsV3 = async ({
  pageToken,
  responseLimit,
  search,
  statusFilter,
  locationId,
  sourceIds = [],
}: {
  pageToken?: string;
  responseLimit: number;
  search: string;
  statusFilter: Person['Status'] | '';
  locationId: string;
  sourceIds?: string[];
}) => {
  const { persons, nextPageToken } = await http.get<PersonsResponse>(`persons/v3/persons/search`, {
    params: http
      .paramBuilder<PersonV3Params>()
      .addParam('locationIds', locationId)
      .addParam('page.size', responseLimit)
      .addParam('page.token', pageToken, !!pageToken)
      .addParam('search', search || '', !!search)
      .addParam('statusFilter', statusFilter, !!statusFilter)
      .addParam('sourceIds', sourceIds)
      .build(),
  });

  return {
    rows: persons,
    nextOffset: nextPageToken,
  };
};

export const getMultiPersonsV3 = async ({
  pageToken,
  responseLimit,
  statusFilter,
  locationIds,
  orderBy,
  search,
  sourceIds = [],
}: {
  pageToken?: string;
  responseLimit: number;
  statusFilter: SearchPersonsLegacyRequest['statusFilter'] | '';
  locationIds: SearchPersonsLegacyRequest['locationIds'];
  orderBy: PersonsOrder['orderBy'];
  search: SearchPersonsLegacyRequest['search'];
  sourceIds?: string[];
}) => {
  const postBody: SearchPersonsLegacyRequest = {
    locationIds,
    ...(statusFilter && { statusFilter }),
    search,
    page: {
      size: responseLimit,
      token: pageToken,
      order:
        orderBy === PersonOrderBy_Enum.LAST_NAME
          ? [
              { orderBy: PersonOrderBy_Enum.LAST_NAME, descending: false },
              { orderBy: PersonOrderBy_Enum.FIRST_NAME, descending: false },
            ]
          : [
              { orderBy: PersonOrderBy_Enum.FIRST_NAME, descending: false },
              { orderBy: PersonOrderBy_Enum.LAST_NAME, descending: false },
            ],
    },
    sourceIds,
  };
  const { persons, nextPageToken } = await http.post<PersonsResponse>(`persons/v3/persons/search`, postBody);

  return {
    rows: persons,
    nextOffset: nextPageToken,
  };
};

export const getPersonsV2 = async ({
  order,
  skip = 0,
  limit = 25,
  query,
  status,
  locationId,
  dataSourceIds,
}: Partial<{
  order: string[];
  skip: number;
  limit: number;
  query: string;
  status: string;
  locationId: string;
  dataSourceIds?: string[];
}>) => ({
  rows: await http.getData<Person[]>(`desktop/v1/persons`, {
    params: { order, skip, limit, query, status, ds: dataSourceIds },
    headers: locationId ? { 'Location-Id': locationId } : undefined,
  }),
  nextOffset: skip + 1,
});

export const getPersonExtended = async (personId: string, locationId?: string) =>
  await http.getData<Person>(
    `desktop/v1/person_extended/${personId}`,
    locationId ? { headers: { 'Location-Id': locationId } } : undefined
  );

const defaultOptions: ContextlessQueryObserverOptions = {
  refetchOnMount: true,
  refetchOnWindowFocus: false,
};

type UsePersonExtendedRequest = {
  personId: string;
  locationId?: string;
};

export const usePersonExtended = (
  { personId, locationId }: UsePersonExtendedRequest,
  options?: UseQueryOptions<PersonTypes.Person>
) => {
  const query = useQuery({
    queryKey: queryKeys.personExtended(personId, locationId),
    queryFn: () => getPersonExtended(personId, locationId),
    ...defaultOptions,
    enabled: !!personId,
    ...options,
  });
  return query;
};
