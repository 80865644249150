import { ActionButtonStyles } from '../components/action-button/action-button.styles';
import { AlertStyles } from '../components/alerts/alert.styles';
import { AudioScrubberStyles } from '../components/audio-scrubber/audio-scrubber.styles';
import { AvatarGroupStyles } from '../components/avatar-group/avatar-group.styles';
import { AvatarStyles } from '../components/avatar/avatar.styles';
import { BannerNotificationStyles } from '../components/banner-notification/banner-notification.styles';
import { BreadcrumbPathStyles } from '../components/breadcrumbs/common-styles';
import { MultiActionButtonStyles } from '../components/buttons/multiaction/multi-action-button.styles';
import { PrimaryButtonStyles } from '../components/buttons/primary/primary-button.styles';
import { SecondaryButtonStyles } from '../components/buttons/secondary/secondary-button.styles';
import { CalendarCommonStyles } from '../components/calendar/layouts/common-styles';
import { CalendarControlsStyles } from '../components/calendar/layouts/dialog/styles';
import { SingleMonthCalendarControlStyles } from '../components/calendar/layouts/single-month/styles';
import { ChatItemStyles } from '../components/chat-item/chat-item.styles';
import { ChipStyles } from '../components/chip/chip.styles';
import { CopyToClipboardStyles } from '../components/copy-to-clipboard/copyable-text.styles';
import { deprecated_AlertStyles } from '../components/deprecated_alerts/alert.styles';
import { DotStyles } from '../components/dots/dot.styles';
import { FileUploadStyles } from '../components/file-upload/file-upload.styles';
import { FlyoutMenuItemStyles } from '../components/flyouts/flyout-menu-item.styles';
import { CheckboxStyles } from '../components/forms/atoms/checkbox.styles';
import { DatePickerBaseFieldStyles } from '../components/forms/fields/date-picker/date-picker-base-field.styles';
import { DropdownInputStyles } from '../components/forms/fields/dropdown/input-styles';
import { DropdownFieldStyles } from '../components/forms/fields/dropdown/styles';
import { MultiselectSelectionStyles } from '../components/forms/fields/multiselect/multiselect-selection.styles';
import { OptionSwitchOptionStyles } from '../components/forms/fields/option-switch/option-switch-option.styles';
import { RadioCardStyles } from '../components/forms/fields/radio-card/radio-card.styles';
import { RadioStyles } from '../components/forms/fields/radio/radio.styles';
import { SwitchStyles } from '../components/forms/fields/switch/switch.styles';
import { FieldWrapperStyles } from '../components/forms/layouts/field-wrapper.styles';
import { FloatingLabelStyles } from '../components/forms/layouts/floating-label.styles';
import { HelperTextThemeStyles } from '../components/forms/layouts/helper-text/helper-text.styles';
import { NonFloatingLabelStyles } from '../components/forms/layouts/non-floating-label.styles';
import { ListFieldItemStyles } from '../components/forms/list-fields/list-field-item.styles';
import { HeadingStyles } from '../components/heading/heading.styles';
import { HoverLabelStyles } from '../components/hover-label/hover-label.styles';
import { IconButtonStyles } from '../components/icon-button/icon-button.styles';
import { IndexRowStyles } from '../components/index-row/common-styles';
import { InfoStyles } from '../components/info/info.styles';
import { ListRowStyles } from '../components/list-row/list-row.styles';
import { ListboxLiStyles } from '../components/listbox/listbox-li.styles';
import { LoadersStyles } from '../components/loader/loaders.styles';
import { MessageTemplateStyles } from '../components/message-template/message-template.styles';
import { ModalStyles } from '../components/modal/modal.styles';
import { PaginationStyles } from '../components/pagination/pagination.styles';
import { PopoverMenuStyles } from '../components/popover/menu/popover-menu.styles';
import { StoryHeaderStyles } from '../components/story-components/story-header.styles';
import { SwitchChipGroupStyles } from '../components/switch-chip/switch-chip.styles';
import { TableCommonStyles } from '../components/table/common-styles';
import { TabFilterStyles } from '../components/tabs-filter/common/styles';
import { TabsStyles } from '../components/tabs/common-styles';
import { TextButtonStyles } from '../components/text-button/text-button.styles';
import { TextLinkStyles } from '../components/text-link/text-link.styles';
import { TextStyles } from '../components/text/text.styles';
import { TooltipStyles } from '../components/tooltip/tooltip.styles';
import { WeekViewDatePickerStyles } from '../components/week-view-date-picker/week-view-date-picker.styles';
import { IconStyles } from '../icon/Icon.styles';
import { AccordionStyles } from './../components/accordion/common-styles';

export const rootStyles = {
  ...AccordionStyles,
  ...ActionButtonStyles,
  ...AlertStyles,
  ...AudioScrubberStyles,
  ...AvatarGroupStyles,
  ...AvatarStyles,
  ...BannerNotificationStyles,
  ...BreadcrumbPathStyles,
  ...CalendarCommonStyles,
  ...CalendarControlsStyles,
  ...ChatItemStyles,
  ...CheckboxStyles,
  ...ChipStyles,
  ...CopyToClipboardStyles,
  ...DatePickerBaseFieldStyles,
  ...DotStyles,
  ...DropdownFieldStyles,
  ...DropdownInputStyles,
  ...FieldWrapperStyles,
  ...FileUploadStyles,
  ...FloatingLabelStyles,
  ...FlyoutMenuItemStyles,
  ...HeadingStyles,
  ...HelperTextThemeStyles,
  ...HoverLabelStyles,
  ...IconButtonStyles,
  ...IconStyles,
  ...IndexRowStyles,
  ...InfoStyles,
  ...ListFieldItemStyles,
  ...ListRowStyles,
  ...ListboxLiStyles,
  ...LoadersStyles,
  ...MessageTemplateStyles,
  ...ModalStyles,
  ...MultiActionButtonStyles,
  ...MultiselectSelectionStyles,
  ...NonFloatingLabelStyles,
  ...OptionSwitchOptionStyles,
  ...PaginationStyles,
  ...PopoverMenuStyles,
  ...PrimaryButtonStyles,
  ...RadioStyles,
  ...RadioCardStyles,
  ...SecondaryButtonStyles,
  ...SingleMonthCalendarControlStyles,
  ...StoryHeaderStyles,
  ...SwitchChipGroupStyles,
  ...SwitchStyles,
  ...TabFilterStyles,
  ...TableCommonStyles,
  ...TabsStyles,
  ...TextButtonStyles,
  ...TextLinkStyles,
  ...TextStyles,
  ...TooltipStyles,
  ...WeekViewDatePickerStyles,
  ...deprecated_AlertStyles,
};

export type RootComponentStyles = typeof rootStyles;
