import { ReactNode, ComponentType } from 'react';
import { Heading } from '../../heading';
import { theme } from '@frontend/theme';

type TrayHeaderProps = {
  children?: ReactNode;
  Buttons?: ReactNode;
  className?: string;
  Button?: ComponentType<React.PropsWithChildren<unknown>>;
};

const Title = (props) => <Heading level={2} css={{ margin: 0 }} {...props} />;

export const TrayHeader = ({ Buttons, children, Button, ...rest }: TrayHeaderProps) => {
  return (
    <section
      className='tray-header'
      css={{
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        alignItems: 'center',
        position: 'relative',
      }}
      {...rest}
    >
      {typeof children === 'string' ? <Title>{children}</Title> : children}
      <div css={{ display: 'flex', gap: theme.spacing(1) }}>{Buttons}</div>
    </section>
  );
};
