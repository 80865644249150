import { css } from '@emotion/react';
import { TextLink } from '../../text-link';

export type StepperClearButtonProps = {
  clearStep: () => void;
  trackingId?: string;
  children: string;
};

export const StepperClearButton = ({
  clearStep,
  trackingId = '',
  children = 'Clear',
  ...rest
}: Partial<StepperClearButtonProps>) => {
  return (
    <TextLink
      className='step-button clear-step'
      trackingId={trackingId}
      css={css`
        position: absolute;
        left: 0;
      `}
      onClick={() => clearStep?.()}
      {...rest}
    >
      {children}
    </TextLink>
  );
};
