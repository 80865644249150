import { createContext, FC, useContext, useEffect } from 'react';
import { IHTTP, HTTP } from './http';

const Context = createContext<IHTTP>({});

export const FetchProvider: FC<React.PropsWithChildren<IHTTP>> = ({ baseUrl = '', headers, children }) => {
  return <Context.Provider value={{ baseUrl, headers }}>{children}</Context.Provider>;
};

export const http = new HTTP();

const resolveParams = ({ context, props }: { context?: IHTTP; props?: IHTTP }) => {
  return {
    ...context,
    ...props,
  };
};

export const useFetch = (props?: IHTTP): HTTP => {
  const context = useContext(Context);
  useEffect(() => {
    const { baseUrl, headers } = resolveParams({ context, props });
    if (headers) {
      http.setHeaders(headers);
    }

    if (baseUrl) {
      http.setBaseUrl(baseUrl);
    }
  }, [props, context]);

  return http;
};
