/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const ErrorBadgeIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 Z M7.70710678,6.29289322 C7.31658249,5.90236893 6.68341751,5.90236893 6.29289322,6.29289322 C5.90236893,6.68341751 5.90236893,7.31658249 6.29289322,7.70710678 L6.29289322,7.70710678 L10.585,12 L6.29289322,16.2928932 C5.90236893,16.6834175 5.90236893,17.3165825 6.29289322,17.7071068 C6.68341751,18.0976311 7.31658249,18.0976311 7.70710678,17.7071068 L7.70710678,17.7071068 L12,13.415 L16.2928932,17.7071068 C16.6834175,18.0976311 17.3165825,18.0976311 17.7071068,17.7071068 C18.0976311,17.3165825 18.0976311,16.6834175 17.7071068,16.2928932 L17.7071068,16.2928932 L13.415,12 L17.7071068,7.70710678 C18.0976311,7.31658249 18.0976311,6.68341751 17.7071068,6.29289322 C17.3165825,5.90236893 16.6834175,5.90236893 16.2928932,6.29289322 L16.2928932,6.29289322 L12,10.585 Z'
    />
  </Icon>
);
