import { useStyles } from '../../use-styles';
import { DotProps } from './types';

export type ColoredDotProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'color'> & {
  hoverText?: string;
};

export const Dot: React.FC<React.PropsWithChildren<DotProps>> = ({ color, innerRef, pulsing = false, ...rest }) => {
  const dotStyle = useStyles('Dot', { color, pulsing });

  return <div css={dotStyle} ref={innerRef} {...rest} />;
};
