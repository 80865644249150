import { RefObject, useEffect, useState } from 'react';

export const useIsVisible = (ref: RefObject<Element>): boolean => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));
    ref.current && observer.observe(ref.current);
  }, [ref.current]);

  return isIntersecting;
};
