import { useEffect, useRef } from 'react';

/**
 * A hook for tracking and easily accessing a value that needs to be stable
 * across executions, eg in aggressively memoized functions returned from other hooks.
 * @param {any} value Value to track
 * @returns React ref
 */
export function useStableValueTracker<T = unknown>(value: T): React.MutableRefObject<T> {
  const stableValue = useRef<T>(value);

  useEffect(() => {
    stableValue.current = value;
  }, [value]);

  return stableValue;
}
