import { DefaultCell } from './default-cell.component';
import { ActionsCell } from './actions-cell.component';
import { SelectRowCell } from './select-row-cell.component';
import { RowExpandCell } from './row-expand.component';

const DEFAULT = 'default';
const ACTIONS = '__actions';
const SELECT_ROW = '__selector';
const ROW_EXPAND = '__expandable';

export const reservedCells = {
  ACTIONS,
  SELECT_ROW,
  ROW_EXPAND,
} as const;

export const displayCellType = {
  [DEFAULT]: DefaultCell,
  [ACTIONS]: ActionsCell,
  [SELECT_ROW]: SelectRowCell,
  [ROW_EXPAND]: RowExpandCell,
} as const;
