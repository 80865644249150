import { css } from '@emotion/react';
import type { FloatingLabelStyleValues } from './floating-label-theme';
import { getFloatingLabelTheme, getFloatingLabelThemeOriginal } from './floating-label-theme';
import { FloatingLabelProps } from './types';

type FloatingLabelStyleProps = Pick<FloatingLabelProps, 'active' | 'disabled' | 'hasError' | 'leftOffset'>;

const styles = (
  { fontColor, fontSize, spacing }: FloatingLabelStyleValues,
  { leftOffset, active }: FloatingLabelStyleProps
) => [
  css`
    color: ${fontColor};
    font-size: ${fontSize(16)};
    line-height: 1;
    padding: ${spacing(0, 0.5)};
    pointer-events: none;
    position: absolute;
    top: 0;
    left: ${spacing(1.5)};
    transform-origin: left top;
    transition: color 200ms ease-out, transform 150ms ease-out;
  `,
  active
    ? css`
        transform: scale(0.75) translateY(-65%);

        ::before {
          content: '';
          width: 100%;
          height: calc(100% + 4px);
          background: white;
          display: block;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          z-index: -1;
        }
      `
    : css`
        transform: scale(1) ${leftOffset ? `translate(${leftOffset + 8}px, 11px)` : 'translateY(11px)'};
      `,
];

export const FloatingLabelStyles = {
  FloatingLabel: (floatingLabelProps: FloatingLabelStyleProps) =>
    styles(
      getFloatingLabelTheme({
        disabled: floatingLabelProps.disabled,
        hasError: floatingLabelProps.hasError,
      }),
      floatingLabelProps
    ),
};

export const FloatingLabelStylesOriginal: typeof FloatingLabelStyles = {
  FloatingLabel: (floatingLabelProps: FloatingLabelStyleProps) =>
    styles(
      getFloatingLabelThemeOriginal({
        disabled: floatingLabelProps.disabled,
        hasError: floatingLabelProps.hasError,
      }),
      floatingLabelProps
    ),
};
