import { useTranslation } from '@frontend/i18n';
import { weavePayStyles } from '@frontend/payments';
import { ErrorBadgeIcon } from '@frontend/design-system';

const { container, resultMessage, errorIcon, errorBody } = weavePayStyles;

export const ErrorComponent = () => {
  const { t } = useTranslation('weave-pay');
  return (
    <div css={container}>
      <div css={resultMessage}>
        <h1>{t('Whoops!')}</h1>
        <p css={errorBody}>
          {t('An error occurred. Please try again. If you continue to get this message, please request a new link.')}
        </p>
        <ErrorBadgeIcon css={errorIcon} color='error' size={150} />
      </div>
    </div>
  );
};
