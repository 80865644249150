import { ComponentType, KeyboardEvent, useMemo, useRef, useState } from 'react';
import { BaseFieldProps, TextField } from '../forms';
import { PopoverMenuItem, usePopoverMenu } from '../popover';
import { ComboboxBase } from './base';
import { debounce } from 'lodash-es';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { theme } from '@frontend/theme';
import { css } from '@emotion/react';

const MENU_ITEM_HEIGHT = 40;
const MENU_PADDING = 16;
const MAX_MENU_HEIGHT = 400;
const MENU_ITEM_WIDTH = 400;

const isPrintableCharacter = (str: string) => {
  return str.length === 1 && str.match(/\S| /);
};

const getPrintableValue = (item, accessor) => {
  if (typeof accessor === 'string') {
    return item[accessor];
  } else if (typeof accessor === 'function') {
    return accessor(item);
  } else {
    return item;
  }
};

const getFilteredOptions = <ItemType,>(options: ItemType[], input: string, accessor) => {
  return options.filter((item) => {
    return getPrintableValue(item, accessor).toLowerCase().startsWith(input.toLowerCase());
  });
};

const virtuosoNavigationCallback = ({
  event,
  activeIndex,
  maxIndex,
  setActiveIndex,
  virtuosoNode,
}: {
  event: KeyboardEvent;
  activeIndex: number | null;
  maxIndex: number;
  setActiveIndex: (index: number | null) => void;
  virtuosoNode: VirtuosoHandle;
}) => {
  let nextIndex: number | null = null;

  if (event.code === 'ArrowUp') {
    nextIndex = Math.max(0, activeIndex !== null ? activeIndex - 1 : maxIndex);
  } else if (event.code === 'ArrowDown') {
    nextIndex = Math.min(maxIndex, activeIndex !== null ? activeIndex + 1 : 0);
  }

  if (nextIndex !== null) {
    setActiveIndex(nextIndex);
    virtuosoNode.scrollIntoView({
      index: nextIndex,
      behavior: 'auto',
    });
    event.preventDefault();
  }
};

export const Combobox = <ItemType,>({
  options,
  label,
  name,
  autocomplete = false,
  accessor,
  Item,
  itemHeight = MENU_ITEM_HEIGHT,
  itemWidth = MENU_ITEM_WIDTH,
  clearable,
  onOptionSelect,
  onNewOption,
  fieldProps,
}: {
  value?: string;
  options: ItemType[];
  label: string;
  name: string;
  autocomplete?: boolean;
  accessor?: string | ((item: ItemType) => string);
  Item?: ComponentType<React.PropsWithChildren<{ item: ItemType }>>;
  itemHeight?: number;
  itemWidth?: number;
  clearable?: boolean;
  onOptionSelect?: (tag: ItemType) => void;
  onNewOption?: (value: string) => void;
  fieldProps: BaseFieldProps<string, '', HTMLInputElement>;
}) => {
  const field = fieldProps;
  const [input, setInput] = useState('');

  const { refs, close, open, isOpen, activeIndex, setActiveIndex, getTriggerProps, getMenuProps, getItemProps } =
    usePopoverMenu<HTMLInputElement>({
      placement: 'bottom',
      interactionOptions: {
        listNavigation: { virtual: true, loop: false },
        typeahead: { enabled: false },
        click: { keyboardHandlers: false },
      },
      middlewareOptions: { offset: 20 },
    });

  const virtuosoRef = useRef<VirtuosoHandle>(null);

  const filteredOptions = useMemo(() => getFilteredOptions(options, input, accessor), [options, input]);

  const { ref, ...triggerProps } = getTriggerProps({
    onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => {
      switch (event.key) {
        case 'Backspace':
          if (event.metaKey || event.ctrlKey) {
            setInput('');
          }

          if (!isOpen) {
            open();
          }

          if (activeIndex !== null) {
            setActiveIndex(null);
          }

          /**
           * When the user presses backspace, we stop suggesting options until they start typing again.
           */

          break;
        case 'ArrowDown':
        case 'ArrowUp':
          if (virtuosoRef.current) {
            virtuosoNavigationCallback({
              event,
              activeIndex,
              maxIndex: filteredOptions.length - 1,
              setActiveIndex,
              virtuosoNode: virtuosoRef.current,
            });
          }
          break;
        case 'ArrowLeft':
        case 'ArrowRight':
          break;
      }
    },
    onKeyUp: (event: KeyboardEvent<HTMLInputElement>) => {
      switch (event.key) {
        case 'Backspace':
          setInput(event.currentTarget.value);

          break;
        case 'Enter':
          if (activeIndex != null && filteredOptions[activeIndex]) {
            const item = filteredOptions[activeIndex];
            const value = getPrintableValue(item, accessor);
            field.onChange({ name, value });
            onOptionSelect?.(item);
            setActiveIndex(null);
            if (isOpen) {
              close();
            }

            if (event.currentTarget instanceof HTMLInputElement) {
              /**
               * Set the cursor to the end of the input value
               */
              event.currentTarget.setSelectionRange(value.length, value.length);
            }
          } else {
            const value = event.currentTarget.value;
            field.onChange({ name, value });
            onNewOption?.(value);
          }
          break;

        case 'ArrowDown':
        case 'ArrowUp':
        case 'ArrowLeft':
        case 'ArrowRight':
          break;
        default: {
          if (isPrintableCharacter(event.key)) {
            const inputValue = event.currentTarget.value;
            setInput(inputValue);

            if (event.currentTarget === document.activeElement && !isOpen) {
              open();
            }

            if (inputValue !== '') {
              const currentFilteredOptions = getFilteredOptions(options, inputValue, accessor);

              if (autocomplete) {
                debouncedTriggerSuggestion(
                  currentFilteredOptions,
                  accessor,
                  setActiveIndex,
                  field,
                  event.currentTarget,
                  inputValue,
                  name
                );
              } else {
                field.onChange({ name, value: inputValue });
                setActiveIndex(0);
              }
            }
          }
          break;
        }
      }
    },
  });

  return (
    <ComboboxBase
      Input={
        <TextField
          clearable={clearable}
          onClear={() => {
            setInput('');
          }}
          {...triggerProps}
          {...field}
          fieldComponentProps={{ ref, autoComplete: 'off' }}
          label={label}
          name={name}
        />
      }
      menuProps={getMenuProps()}
      /**
       * Manually calculate height of the menu based on the number of items.
       * This is necessary because Virtuoso needs a fixed height to work properly.
       *
       * Also remove padding around Virtuoso, and add padding to the first and last items.
       */
      menuStyles={css({
        height: Math.min(MAX_MENU_HEIGHT, itemHeight * filteredOptions.length + MENU_PADDING),
        padding: 0,
      })}
    >
      {filteredOptions.length ? (
        <Virtuoso
          ref={virtuosoRef}
          style={{ height: '100%', width: itemWidth, overflowX: 'hidden' }}
          data={filteredOptions}
          fixedItemHeight={itemHeight}
          itemContent={(index, item) => (
            <div
              style={{
                paddingTop: index === 0 ? theme.spacing(1) : 0,
                paddingBottom: index === filteredOptions.length - 1 ? theme.spacing(1) : 0,
              }}
            >
              <PopoverMenuItem
                css={{
                  width: itemWidth,
                  height: itemHeight,
                }}
                {...getItemProps({
                  index,
                  onClick: () => {
                    field.onChange({ name, value: getPrintableValue(item, accessor) });
                    onOptionSelect?.(item);
                    if (refs.domReference.current instanceof HTMLInputElement) {
                      refs.domReference.current.focus();
                    }
                  },
                })}
                active={index === activeIndex}
              >
                {Item ? <Item item={item} /> : <>{item}</>}
              </PopoverMenuItem>
            </div>
          )}
        />
      ) : null}
    </ComboboxBase>
  );
};

const debouncedTriggerSuggestion = debounce((options, accessor, setActiveIndex, field, node, input, fieldName) => {
  const suggestion = options[0];

  if (suggestion) {
    const printableValue = getPrintableValue(suggestion, accessor);
    setActiveIndex(0);
    field.onChange({ name: fieldName, value: printableValue });
    if (node instanceof HTMLInputElement) {
      node.setSelectionRange(input.length, printableValue.length);
    }
  }
}, 500);
