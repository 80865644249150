import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ItalicAction } from '../../molecules';
import { FORMAT_TEXT_COMMAND } from 'lexical';
import { useCallback } from 'react';
import { useToolbarState } from '../../providers';

export const Italic = () => {
  const [editor] = useLexicalComposerContext();
  const { isItalic } = useToolbarState(['isItalic']);

  const onItalicSelect = useCallback(() => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
  }, [editor]);

  return <ItalicAction onClick={onItalicSelect} isActive={isItalic} aria-label='Format Italic' />;
};

Italic.displayName = 'Italic';
