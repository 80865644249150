import { PersonsV3 } from '@frontend/api-person';
import { useListThreadsInfiniteQuery } from '../inbox-queries';
import { ContactType_Enum } from '@weave/schema-gen-ts/dist/shared/persons/v3/enums.pb';
import { sanitizePhoneNumber } from '@frontend/phone-numbers';
import { formatPhoneWithCountryCode } from '../utils';
import { useAppScopeStore } from '@frontend/scope';

const PHONE_CONTACT_INFO_TYPES: ContactType_Enum[] = [
  ContactType_Enum.PHONE_HOME,
  ContactType_Enum.PHONE_MOBILE,
  ContactType_Enum.PHONE_WORK,
];

type PropsWithPerson = {
  personId: string;
  groupIds?: string[];
  personPhones?: string[];
  departmentIds?: string[];
};

type PropsWithoutPerson = {
  personId?: string;
  groupIds?: string[];
  personPhones: string[];
  departmentIds?: string[];
};

type UseLastUpdateThreadArgs = PropsWithPerson | PropsWithoutPerson;

export const useLastUpdatedThread = ({ personId, groupIds, personPhones, departmentIds }: UseLastUpdateThreadArgs) => {
  const { selectedLocationIds } = useAppScopeStore();
  const resolvedGroupIds = groupIds?.length ? groupIds : selectedLocationIds;
  const personQuery = PersonsV3.PersonQueries.useGetPersonLegacyQuery(
    { personId: personId ?? '', locationIds: resolvedGroupIds },
    { enabled: !!personId && (!personPhones || !personPhones.length) && !!resolvedGroupIds.length }
  );
  const resolvedPersonPhones =
    personPhones ??
    personQuery.data?.contactInfo
      ?.filter(({ type }) => PHONE_CONTACT_INFO_TYPES.includes(type))
      .map(({ destination }) => (destination ? formatPhoneWithCountryCode(sanitizePhoneNumber(destination)) : ''))
      .filter(Boolean) ??
    [];
  const listThreadsQuery = useListThreadsInfiniteQuery(
    {
      personPhones: resolvedPersonPhones,
      departmentIds,
      groupIds: resolvedGroupIds,
      locationId: resolvedGroupIds[0] ?? '',
      threadLimit: 200,
    },
    { enabled: !!resolvedPersonPhones.length && resolvedGroupIds.length > 0 }
  );

  const existingThreads = listThreadsQuery.data?.pages.flatMap((page) => page.threads) ?? [];
  const lastUpdatedThread = existingThreads[0];

  return {
    lastUpdatedThread,
    isLoading: personQuery.isLoading || listThreadsQuery.isLoading,
  };
};
