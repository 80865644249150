import type { CommonFieldAtomProps, InputProps } from './types';
import type { FieldKey, ResolvedFieldProps } from '../hooks/types';
import type { MaskConfig } from './mask-types';
import { hasValue } from '../shared/utils';

export const getDescriptorId = (id: string) => `${id}-descriptor`;
export const getLabelId = (id: string) => `${id}-label`;

export type FieldAtomProps<T extends FieldKey> = Omit<ResolvedFieldProps<T>, 'hidden'> & {
  descriptorText?: string;
  name: string;
};

export type FieldAtomPropsResult<T extends FieldKey> = {
  fieldProps: InputProps<T>;
  descriptorProps: Omit<CommonFieldAtomProps, 'active'> & { children?: string };
  labelProps: CommonFieldAtomProps;
};

type ErrorProps = {
  'aria-invalid'?: boolean;
  error?: string;
  touched?: boolean;
};

export const shouldShowError = (field: ErrorProps) => !!field.error && field.touched && field['aria-invalid'];

export function getFieldAtomProps<T extends FieldKey>({
  active,
  descriptorText,
  disabled,
  error,
  id,
  touched,
  ...rest
}: FieldAtomProps<T>): FieldAtomPropsResult<T> {
  const hasError = shouldShowError({ error, touched, ...rest });
  return {
    fieldProps: { id, disabled, ...rest } as InputProps<T>,
    descriptorProps: {
      disabled,
      hasError,
      id,
      children: hasError ? error : descriptorText,
    },
    labelProps: {
      active: active || hasValue(rest.value),
      disabled,
      hasError,
      id,
    },
  };
}

const optionalKey = <T>(key: T) => (key ? { key } : {});

export function getMaskConfigProps<T extends MaskConfig>({
  currentCaretPosition,
  guide,
  keepCharPositions,
  pipe,
  placeholderChar,
  previousConformedValue,
  rawValue,
  ...rest
}: T) {
  return {
    maskConfigProps: {
      ...optionalKey(currentCaretPosition),
      ...optionalKey(guide),
      ...optionalKey(keepCharPositions),
      ...optionalKey(pipe),
      ...optionalKey(placeholderChar),
      ...optionalKey(previousConformedValue),
      ...optionalKey(rawValue),
    } as MaskConfig,
    nonConfigProps: rest,
  };
}
