import { css } from '@emotion/react';
import { NakedUl } from '../../../naked';
import React, { HTMLAttributes } from 'react';
import { useTemplateSettings, useTemplateTags } from '../message-template.context';
import { validateTags } from '../parse-template';
import { MessageTemplateTag } from './message-template-tag';
import { TagType } from '../types';

type TagListProps = HTMLAttributes<HTMLUListElement> & {
  onTagSelect?: (event: React.MouseEvent<HTMLElement, MouseEvent>, tag: TagType) => void;
};

export const TagList = ({ onTagSelect, ...props }: TagListProps) => {
  const { addTagToTemplate, tags, isNewTag, editorValue, correspondingTags, sharedTags, setSharedTags } =
    useTemplateTags();
  const { readOnly } = useTemplateSettings();

  if (process.env.NODE_ENV === 'development') {
    validateTags(tags, isNewTag);
  }

  return (
    <>
      {tags?.length > 0 && (
        <NakedUl
          css={css`
            display: flex;
            flex-wrap: wrap;
            margin: 0;
          `}
          {...props}
        >
          {tags.map((tag, idx) => (
            <li key={`${tag.label}-${tag.key}-${idx}`} css={css({ margin: 4 })}>
              <MessageTemplateTag
                tabIndex={0}
                tag={tag}
                onTagSelect={onTagSelect}
                addTagToTemplate={addTagToTemplate}
                readOnly={readOnly || tag?.readOnly}
                editorValue={editorValue}
                correspondingTags={correspondingTags}
                sharedTags={sharedTags}
                setSharedTags={setSharedTags}
              />
            </li>
          ))}
        </NakedUl>
      )}
    </>
  );
};
