export const handleButtonScroll = (direction: 'left' | 'right', element: HTMLElement | null) => {
  if (!element) return;

  const { scrollWidth, clientWidth } = element;
  const scrollAmount = scrollWidth - clientWidth;
  const scrollLeft = element.scrollLeft;

  if (direction === 'left') {
    element.scrollTo({
      left: scrollLeft - scrollAmount,
      behavior: 'smooth',
    });
  }

  if (direction === 'right') {
    element.scrollTo({
      left: scrollLeft + scrollAmount,
      behavior: 'smooth',
    });
  }
};
