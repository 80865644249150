import { css } from '@emotion/react';
import { transparentize } from '../../helpers';
import { paginationButtonTheme, paginationButtonThemeOriginal, PaginationThemeValues } from './pagination.theme';

type PaginationButtonProps = { isSelected: boolean };

const BUTTON_DIMENSION = 30;

const buttonStyles = (
  { borderRadiusMedium, borderRadiusSmall, spacing, background, primary, selectedFontWeight }: PaginationThemeValues,
  { isSelected }: PaginationButtonProps
) => [
  css`
    border-radius: ${borderRadiusSmall};
    height: ${BUTTON_DIMENSION}px;
    margin: ${spacing(0.75)};
    position: relative;
    transition: 100ms all ease-in-out;
    width: ${BUTTON_DIMENSION}px;
    &:hover {
      background: ${background};
    }
    :focus {
      outline: none;
      :before {
        border-radius: ${borderRadiusMedium};
        border: 3px solid ${transparentize(0.4, primary)};
        content: '';
        height: ${BUTTON_DIMENSION + parseInt(borderRadiusMedium)}px;
        left: -4px;
        position: absolute;
        top: -4px;
        width: ${BUTTON_DIMENSION + parseInt(borderRadiusMedium)}px;
      }
    }
  `,
  isSelected &&
    css`
      outline: 1px solid ${primary};
      background: ${background};
      font-weight: ${selectedFontWeight};
    `,
];

export const PaginationStyles = {
  PaginationButton: (props: PaginationButtonProps) => buttonStyles(paginationButtonTheme, props),
};

export const PaginationStylesOriginal: typeof PaginationStyles = {
  PaginationButton: (props: PaginationButtonProps) => buttonStyles(paginationButtonThemeOriginal, props),
};
