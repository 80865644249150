import { AnimatePresence, motion } from 'framer-motion';
import { useStepperContext } from '../provider/stepper.provider';
import * as styles from '../stepper.styles';
import { theme } from '@frontend/theme';
import { css } from '@emotion/react';
import { CheckIconSmall } from '../../../icon';
import { Heading } from '../../heading';
import { Text } from '../../text';

type Props = {
  numSteps: number;
  stepTitleText?: {
    [key: number]: string;
  };
};

const stepLine = {
  active: {
    height: '50%',
    transition: {
      delay: 0.5,
    },
  },
  completed: {
    height: '100%',
    transition: {
      delay: 0,
    },
  },
  inactive: {
    height: '0%',
  },
  error: {
    height: '0%',
  },
  initial: {
    height: 0,
    transition: {
      delay: 0,
      duration: 0,
    },
  },
  currActive: {
    height: '100%',
    transition: {
      delay: 1,
    },
  },
  errorActive: {
    height: '100%',
    transition: {
      delay: 1,
    },
  },
  lastActive: {
    height: '50%',
    transition: {
      delay: 1,
    },
  },
};

const stepNum = {
  active: {
    backgroundColor: theme.colors.primary50,
    border: `2px solid ${theme.colors.primary50}`,
    color: theme.colors.white,
  },
  inactive: {
    backgroundColor: theme.colors.white,
    color: theme.colors.neutral40,
    border: `2px solid ${theme.colors.neutral40}`,
    transition: {
      delay: 0,
      duration: 0,
    },
  },
  error: {
    backgroundColor: theme.colors.status.critical,
    color: theme.colors.white,
    border: `2px solid ${theme.colors.status.critical}`,
    transition: {
      delay: 0,
      duration: 0,
    },
  },
  initial: {
    backgroundColor: theme.colors.neutral40,
    color: theme.colors.primary50,
    border: `2px solid ${theme.colors.neutral40}`,
  },
  completed: {
    color: theme.colors.primary50,
    backgroundColor: theme.colors.white,
    border: `2px solid ${theme.colors.primary50}`,
    transition: {
      delay: 0,
      duration: 0.5,
    },
  },
  lastActive: {
    color: theme.colors.primary50,
    backgroundColor: theme.colors.white,
    border: `2px solid ${theme.colors.primary50}`,
    transition: {
      delay: 0,
      duration: 0.5,
    },
  },
  currActive: {
    backgroundColor: theme.colors.primary50,
    border: `2px solid ${theme.colors.primary50}`,
    color: theme.colors.white,
  },
  errorActive: {
    backgroundColor: theme.colors.status.critical,
    border: `2px solid ${theme.colors.status.critical}`,
    color: theme.colors.white,
  },
};

export const StepperVerticalSingleView = ({ numSteps, stepTitleText }: Props) => {
  const { stepStatus, handleStepperCard, isStepperCompleted, lastActive } = useStepperContext();
  const stepArray: number[] = [];
  for (let i = 1; i <= numSteps; i++) {
    stepArray.push(i);
  }

  return (
    <div className='stepper-vertical'>
      {stepArray.map((step) => {
        return (
          <div key={step} className='stepper-vertical-step'>
            <div className='step-line-vertical' css={styles.stepLineVertical('inactive')}></div>
            <motion.div
              animate={stepStatus[step]}
              variants={stepLine}
              transition={{ ease: 'easeOut', duration: 0.5 }}
              className='step-line-vertical'
              css={styles.stepLineVertical(stepStatus[step])}
            ></motion.div>
            <div
              css={css`
                display: flex;
                align-items: center;
                height: 100%;
              `}
            >
              <motion.div
                animate={stepStatus[step]}
                variants={stepNum}
                transition={{ ease: 'easeOut', duration: 0.5 }}
                className='step-num-vertical'
                css={styles.stepNum(stepStatus[step])}
              >
                {stepStatus[step] === 'completed' || (lastActive && +lastActive == step) ? (
                  <AnimatePresence>
                    <CheckIconSmall />
                  </AnimatePresence>
                ) : (
                  <Heading
                    level={2}
                    css={css`
                      font-size: ${theme.fontSize(16)};
                      color: white;
                    `}
                  >
                    {step}
                  </Heading>
                )}
              </motion.div>
              <Text
                css={styles.stepLineTextStyles(isStepperCompleted ? 'finished' : stepStatus[step])}
                onClick={(e) => {
                  e.stopPropagation();
                  handleStepperCard(step);
                }}
              >
                {stepTitleText?.[step]}
              </Text>
            </div>
          </div>
        );
      })}
    </div>
  );
};
