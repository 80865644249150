import type { TextareaProps, InputProps } from './types';

type Props = TextareaProps & InputProps<'text'>;

export const Textarea = ({ spellCheck = true, value = '', ...rest }: Props) => (
  // @ts-ignore - TextareaProps is using an HTMLInputElement to satisfy the props for now.
  //              long term the FieldConfig should have a type="textarea" added, but this
  //              would be breaking to how useForm works today with TextareaField, as it uses type="text"
  <textarea value={value} spellCheck={spellCheck} {...rest} />
);
