import { css } from '@emotion/react';
import { useThemeValues } from '../../../hooks';
import { Styles } from '../../../use-styles/use-styles';
import type { BoolInputKey, BoolInputProps } from './types';
import { forwardRef } from 'react';

export const BoolInput = forwardRef(
  <T extends BoolInputKey>({ trackingId, ...props }: BoolInputProps<T> & { css?: Styles }, ref) => {
    const theme = useThemeValues();

    return (
      <input
        css={css`
          width: 100%;
          height: 100%;
          display: block;
          margin: 0;
          padding: 0;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          z-index: ${theme.zIndex.low};
        `}
        data-trackingid={trackingId}
        {...props}
        ref={ref}
      />
    );
  }
);
