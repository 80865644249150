import { css } from '@emotion/react';
import type { HelperTextProps, HelperTextStyleValues } from './helper-text-theme';
import { getHelperTextTheme, getHelperTextThemeOriginal } from './helper-text-theme';

const style = ({ color, fontSize, paddingTop }: HelperTextStyleValues) => {
  return css`
    color: ${color};
    display: inline-block;
    font-size: ${fontSize};
    line-height: 1.33;
    padding-top: ${paddingTop};
    white-space: normal;
  `;
};

export const HelperTextThemeStyles = {
  HelperText: (helperTextProps: HelperTextProps) => style(getHelperTextTheme(helperTextProps)),
};

export const HelperTextThemeOriginalStyles = {
  HelperText: (helperTextProps: HelperTextProps) => style(getHelperTextThemeOriginal(helperTextProps)),
};
