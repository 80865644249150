import { ButtonHTMLAttributes } from 'react';
import { CaretLeftIconSmall, CaretRightIconSmall } from '../../../icon';
import { NakedButton } from '../../../naked';
import { useStyles } from '../../../use-styles';

type ScrollButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  direction: 'left' | 'right';
  show: boolean;
  disabled: boolean;
};

export const ScrollButton = ({ direction, disabled, show, ...rest }: ScrollButtonProps) => {
  const scrollButtonStyles = useStyles('Tabs', 'scrollArrowStyle', { disabled, direction });

  if (!show) return null;

  const IconComponent = direction === 'left' ? CaretLeftIconSmall : CaretRightIconSmall;

  return (
    <span css={scrollButtonStyles}>
      <NakedButton disabled={disabled} {...rest}>
        <IconComponent />
      </NakedButton>
    </span>
  );
};
