import { Node, Text } from 'slate';
import { isTagNode } from '../message-template.models';
import { TagType } from '../types';

const serializeNode = (
  node: Node,
  tagProp: keyof Omit<TagType, 'invalid' | 'options' | 'readOnly' | 'optional'> = 'key'
): string => {
  if (Text.isText(node)) {
    return Node.string(node);
  }

  if (isTagNode(node)) {
    const tag = node.tag;

    if (!tag || tag.invalid) {
      return '';
    }

    if (tagProp === 'key') {
      return Array.isArray(tag.key) ? tag.key[0] : tag.key;
    }

    return tag[tagProp] ?? '';
  }

  // do nothing - unexpected node type
  return '';
};

const serializeLine = (
  nodes: Node | Node[],
  tagProp: keyof Omit<TagType, 'invalid' | 'options' | 'readOnly' | 'optional'> = 'key'
): string => {
  if (nodes === undefined || nodes === null) {
    return '';
  }
  if (!(nodes instanceof Array)) {
    return serializeNode(nodes);
  }
  return nodes
    .map((node) =>
      node.children ? serializeNode(node, tagProp) + serializeLine(node.children as Node) : serializeNode(node, tagProp)
    )
    .join('');
};

/**
 * Serializes the editor nodes into plain text template with the tag keys.
 */
export const serializePlainText = (nodes: Node[]): string => {
  return nodes
    .map((node) => serializeLine(node.children as Node))
    .join('\n')
    .trim();
};

/**
 * Serializes the editor nodes into plain text for the template
 * using the values of the tags.
 */
export const serializePlainTextValues = (nodes: Node[]): string => {
  return nodes
    .map((node) => serializeLine(node.children as Node, 'value'))
    .join('\n')
    .trim();
};

export const testItems = { serializeNode, serializePlainText };
