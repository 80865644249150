export enum KeyNames {
  Backspace = 'Backspace',
  Delete = 'Delete',
  Down = 'ArrowDown',
  End = 'End',
  Enter = 'Enter',
  Escape = 'Escape',
  Home = 'Home',
  Left = 'ArrowLeft',
  PageDown = 'PageDown',
  PageUp = 'PageUp',
  Right = 'ArrowRight',
  Slash = '/',
  Space = ' ',
  Tab = 'Tab',
  Up = 'ArrowUp',
}
