import React, { forwardRef, useEffect } from 'react';
import { useTooltip } from '../tooltip';
import { StyledIconBtn } from './styled-icon-btn.component';
import composeRefs from '@seznam/compose-react-refs';
import { BtnWithHoverLabelProps } from './types';

export const BtnWithHoverLabel = forwardRef<HTMLElement, BtnWithHoverLabelProps>(
  (
    { children, disabled, hoverLabelPlacement = 'top', showLabelAlways, showLabelWhenDisabled, label, ...rest },
    externalRef
  ) => {
    const { Tooltip, tooltipProps, triggerProps, isOpen, setOpen } = useTooltip({
      placement: hoverLabelPlacement,
    });
    // if hover label is visible when button is disabled
    // it can hang around until the button is reenabled
    useEffect(() => {
      if (disabled && !showLabelWhenDisabled && isOpen) {
        setOpen(false);
      }
    }, [disabled, isOpen]);

    return (
      <StyledIconBtn
        {...rest}
        disabled={disabled}
        {...(disabled && !showLabelWhenDisabled ? {} : triggerProps)}
        ref={composeRefs(externalRef, triggerProps.ref) as React.Ref<HTMLButtonElement | HTMLAnchorElement>}
        showLabelAlways={showLabelAlways}
        showLabelWhenDisabled={showLabelWhenDisabled}
      >
        {children}
        <Tooltip {...tooltipProps}>{label}</Tooltip>
        {showLabelAlways && label}
      </StyledIconBtn>
    );
  }
);
