import { useMemo } from 'react';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { InvoiceStatus, getInvoiceHistory } from '@frontend/api-invoices';
import { DashboardAnalyticsProgressCircle } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { calculatePercentage } from '@frontend/math';
import { useMerchant, useMultiQueryUtils } from '@frontend/payments-hooks';
import { useAppScopeStore } from '@frontend/scope';

const todayDate = dayjs();
const startOfWeek = dayjs().startOf('week');
const staticInvoiceHistoryParams = {
  order: '-paidAt' as const,
  limit: 1,
  skip: 0,
};

const startDate = startOfWeek.toISOString();
const endDate = todayDate.toISOString();

export const PaymentsReceived = () => {
  const { paymentsUrl } = useMerchant();
  const { selectedLocationIds } = useAppScopeStore();
  const { getMultiQueryKey } = useMultiQueryUtils();
  const url = `${paymentsUrl}/v1/search/invoices`;
  const queryKeyLabel = 'payments-received-analytics';
  const { t } = useTranslation('payments');

  const { data } = useQuery({
    queryKey: getMultiQueryKey(queryKeyLabel),
    queryFn: () =>
      Promise.all([
        getInvoiceHistory(url, {
          ...staticInvoiceHistoryParams,
          start: startDate,
          end: endDate,
          locationIds: selectedLocationIds,
        }),
        getInvoiceHistory(url, {
          ...staticInvoiceHistoryParams,
          start: startDate,
          end: endDate,
          locationIds: selectedLocationIds,
          status: [InvoiceStatus.Paid, InvoiceStatus.PartiallyPaid],
        }),
      ]),
    refetchOnWindowFocus: 'always',
    staleTime: 0,
    cacheTime: 0,
    enabled: !!paymentsUrl,
  });

  const callsAnsweredPercentage = useMemo(() => {
    const numerator = data?.[1]?.data?.summary?.invoicesCount;
    const denominator = data?.[0]?.data?.summary?.invoicesCount;

    return calculatePercentage(numerator, denominator);
  }, [data]);

  return <DashboardAnalyticsProgressCircle label={t('Payments Received')} percentage={callsAnsweredPercentage} />;
};
