import { forwardRef } from 'react';
import { StyledIconBtn } from './styled-icon-btn.component';
import { BtnWithHoverLabel } from './btn-with-hover-label.component';
import { IconButtonProps } from './types';

export const IconButton = forwardRef<HTMLButtonElement | HTMLAnchorElement, IconButtonProps>(
  ({ showLabelOnHover, size = 'normal', showLabelAlways = false, type, ...props }, ref) => {
    return showLabelOnHover ? (
      <BtnWithHoverLabel size={size} showLabelAlways={showLabelAlways} type={type} {...props} ref={ref} />
    ) : (
      <StyledIconBtn
        size={size}
        showLabelAlways={showLabelAlways}
        type={type}
        {...props}
        aria-label={props['aria-label'] || `${props.label}`}
        ref={ref}
      />
    );
  }
);
