import { useState } from 'react';
import { useStyles } from '../../use-styles';
import { NakedButton } from '../../naked';
import { UsePaginationProps } from './use-pagination';

const ELLIPSIS = '...';
const FORWARD_CHEVRONS = '>>';
const BACK_CHEVRONS = '<<';

type Ellipsis = typeof ELLIPSIS;

type SkipToStartText = Ellipsis | typeof BACK_CHEVRONS;

type SkipToEndText = Ellipsis | typeof FORWARD_CHEVRONS;

export const PaginationButton = ({
  page,
  setPage,
  rangeValue,
  skipBackward,
  skipForward,
  shouldShowSkipToStart,
  shouldShowSkipToEnd,
  trackingId,
}: Pick<UsePaginationProps, 'setPage' | 'skipBackward' | 'skipForward'> & {
  page: number;
  shouldShowSkipToStart: boolean;
  shouldShowSkipToEnd: boolean;
  rangeValue: number | 'dots';
  trackingId?: string;
}) => {
  const styles = useStyles('PaginationButton', { isSelected: page === rangeValue });
  const [skipToStartText, setSkipToStartText] = useState<SkipToStartText>(ELLIPSIS);
  const [skipToEndText, setSkipToEndText] = useState<SkipToEndText>(ELLIPSIS);

  const showBackChevrons = () => setSkipToStartText(BACK_CHEVRONS);
  const showForwardChevrons = () => setSkipToEndText(FORWARD_CHEVRONS);

  if (shouldShowSkipToStart) {
    return (
      <NakedButton
        css={styles}
        onClick={() => {
          skipBackward();
          setSkipToStartText(ELLIPSIS);
        }}
        trackingId={trackingId}
        onMouseEnter={showBackChevrons}
        onMouseLeave={() => setSkipToStartText(ELLIPSIS)}
        onFocus={showBackChevrons}
        onBlur={() => setSkipToStartText(ELLIPSIS)}
      >
        {skipToStartText}
      </NakedButton>
    );
  }
  if (shouldShowSkipToEnd) {
    return (
      <NakedButton
        css={styles}
        onClick={() => {
          skipForward();
          setSkipToEndText(ELLIPSIS);
        }}
        trackingId={trackingId}
        onMouseEnter={showForwardChevrons}
        onMouseLeave={() => setSkipToEndText(ELLIPSIS)}
        onFocus={showForwardChevrons}
        onBlur={() => setSkipToEndText(ELLIPSIS)}
      >
        {skipToEndText}
      </NakedButton>
    );
  } else {
    return (
      <NakedButton
        trackingId={trackingId}
        onClick={rangeValue !== 'dots' ? () => setPage(rangeValue) : undefined}
        css={styles}
      >
        {rangeValue}
      </NakedButton>
    );
  }
};
