import { theme } from '@frontend/theme';
import { css } from '@emotion/react';
import { PrimaryButton, SecondaryButton } from '../../buttons';
import { useStepperContext } from '../provider/stepper.provider';
import { useStepperCardContext } from '../provider/stepper-card.provider';

export type StepperNextButtonProps = {
  isValid?: boolean;
  isSecondaryButton?: boolean;
  isOptionalStep?: boolean;
  onClick?: () => Promise<void> | void;
  onSubmit?: () => Promise<void> | void;
  trackingId?: string;
  children: string;
};

export const StepperNextButton = ({
  isOptionalStep = false,
  isSecondaryButton,
  isValid = false,
  onClick,
  onSubmit,
  trackingId,
  children = 'Next',
  ...rest
}: Partial<StepperNextButtonProps>) => {
  const buttonIsDisabled = !isOptionalStep && !isValid;
  const { nextFunction } = useStepperContext();
  const { step } = useStepperCardContext();
  const Button = isSecondaryButton ? SecondaryButton : PrimaryButton;

  const next = async () => {
    if (onClick) await onClick();
    nextFunction?.(step);
  };

  return (
    <Button
      disabled={buttonIsDisabled}
      className='step-button'
      trackingId={trackingId}
      css={css`
        margin-left: ${theme.spacing(2)};
      `}
      onClick={() => (onSubmit ? onSubmit() : next())}
      {...rest}
    >
      {children}
    </Button>
  );
};
