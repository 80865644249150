import { useStyles } from '../../use-styles';
import { Chip, ChipProps } from '../chip';
import { useSwitchChipGroupContext } from './switch-chip-group-context';

export type SwitchChipProps = Omit<ChipProps, 'onClick'> & {
  value: string;
  disabled?: boolean;
};

export const SwitchChip = ({ value, disabled, ...props }: SwitchChipProps) => {
  const { value: contextValue, toggleValue, disabled: contextDisabled } = useSwitchChipGroupContext();
  const resolvedDisabled = contextDisabled || disabled;
  const selected = contextValue.includes(value);
  const styles = useStyles('SwitchChip', { selected, disabled: resolvedDisabled });

  return (
    <Chip
      css={styles}
      tabIndex={resolvedDisabled ? -1 : 0}
      {...props}
      onClick={
        resolvedDisabled
          ? undefined
          : () => {
              toggleValue(value);
            }
      }
      onKeyDown={
        resolvedDisabled
          ? undefined
          : (e) => {
              if (e.key === 'Enter' || e.key === 'Space') {
                toggleValue(value);
              }
            }
      }
      size='large'
      variant='outline'
    />
  );
};

SwitchChip.displayName = 'SwitchChip';
