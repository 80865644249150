import { createProvider } from '@frontend/store';

export type PanelInfo = {
  id: string;
  isValid: boolean;
  isActive: boolean;
  isHidden: boolean | undefined;
  hasNav: boolean | undefined;
  hasBack: boolean | undefined;
};

export interface MultiPanelStore {
  panelInfo: PanelInfo[];
  setPanelInfo: (panelInfo: PanelInfo[]) => void;
}

export const { Provider: MultiPanelStoreProvider, useStore: useMultiPanelStore } = createProvider<MultiPanelStore>()(
  (set) => ({
    panelInfo: [],
    setPanelInfo: (panelInfo: PanelInfo[]) => set({ panelInfo }),
  })
);
