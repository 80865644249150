import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../../component-theme/theme-types';

export type MessageTemplateTagThemeValues = {
  spacing: WeaveThemeValues['Spacing'];
  errorColor: WeaveThemeValues['Color'];
  readOnlyTextColor: WeaveThemeValues['Color'];
  readOnlyBorderColor: WeaveThemeValues['Color'];
  borderColor: WeaveThemeValues['Color'];
  borderRadius: WeaveThemeValues['BorderRadius'];
  boxShadow: WeaveThemeValues['BoxShadow'];
  svgColor: WeaveThemeValues['Color'];
};

export const messageTemplateTagTheme: MessageTemplateTagThemeValues = {
  spacing: theme.spacing,
  errorColor: theme.colors.status.critical,
  readOnlyTextColor: theme.colors.neutral40,
  readOnlyBorderColor: theme.colors.status.disabled,
  borderColor: theme.colors.neutral20,
  borderRadius: theme.borderRadius.small,
  boxShadow: theme.shadows.light,
  svgColor: theme.colors.neutral60,
};

export const messageTemplateTagThemeOriginal: MessageTemplateTagThemeValues = {
  spacing: themeOriginal.spacing,
  errorColor: themeOriginal.colors.error,
  readOnlyTextColor: themeOriginal.colors.gray[400],
  readOnlyBorderColor: themeOriginal.colors.disabled,
  borderColor: themeOriginal.colors.gray[400],
  borderRadius: themeOriginal.borderRadius.small,
  boxShadow: themeOriginal.shadows.light,
  svgColor: themeOriginal.colors.gray[600],
};
