import { theme } from '@frontend/theme';

export const defaultColor = theme.colors.neutral30;

// This is used to assign colors to the charts items sequentially when keys are not known
export const sequentialChartColors = [
  theme.colors.success20,
  theme.colors.primary30,
  theme.colors.secondary.eggplant40,
  theme.colors.warning50,
  theme.colors.secondary.seaweed30,
  theme.colors.neutral30,
  theme.colors.critical30,
  theme.colors.secondary.eggplant20,
  theme.colors.success50,
  theme.colors.primary40,
  theme.colors.secondary.seaweed40,
  theme.colors.critical60,
  theme.colors.primary50,
  theme.colors.success40,
  theme.colors.secondary.seaweed70,
  theme.colors.secondary.eggplant80,
  theme.colors.warning60,
  theme.colors.success60,
  theme.colors.primary60,
  theme.colors.secondary.seaweed80,
  theme.colors.critical70,
  theme.colors.primary70,
  theme.colors.success70,
  theme.colors.secondary.eggplant90,
  theme.colors.warning10,
  theme.colors.success80,
  theme.colors.primary80,
  theme.colors.secondary.seaweed90,
  theme.colors.critical80,
  theme.colors.primary90,
];

/**
 * Used to adjust the given hex color by the given percent
 */
export const adjustHexColor = (color: string, percent: number, darken?: boolean): string => {
  if (!color) {
    return '';
  }

  // Ensure the percent is within the range [0, 100]
  percent = Math.min(100, Math.max(0, percent));

  // Parse the color into RGB components
  const hex = color.replace(/^#/, '');
  const rgb = parseInt(hex, 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = rgb & 0xff;

  // Calculate the adjusted RGB values
  let newR, newG, newB;
  if (darken) {
    newR = Math.round((r * (100 - percent)) / 100);
    newG = Math.round((g * (100 - percent)) / 100);
    newB = Math.round((b * (100 - percent)) / 100);
  } else {
    newR = Math.round(r + ((255 - r) * percent) / 100);
    newG = Math.round(g + ((255 - g) * percent) / 100);
    newB = Math.round(b + ((255 - b) * percent) / 100);
  }

  // Convert the adjusted RGB values back to hex
  const adjustedHex = ((newR << 16) | (newG << 8) | newB).toString(16).padStart(6, '0');

  return `#${adjustedHex}`;
};
