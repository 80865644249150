import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../../../component-theme/theme-types';

export type SingleMonthCalendarControlsThemeValues = {
  color: WeaveThemeValues['Color'];
};

export type SingleMonthControlsHeaderThemeValues = {
  spacing: WeaveThemeValues['Spacing'];
};

export type MonthNameThemeValues = {
  color: WeaveThemeValues['Color'];
  fontSize: WeaveThemeValues['FontSize'];
  fontWeight: WeaveThemeValues['FontWeight'];
};

export const singleMonthCalendarControlsTheme: SingleMonthCalendarControlsThemeValues = {
  color: theme.colors.neutral90,
};

export const singleMonthCalendarControlsThemeOriginal: SingleMonthCalendarControlsThemeValues = {
  color: themeOriginal.colors.gray[600],
};

export const singleMonthControlsHeaderTheme: SingleMonthControlsHeaderThemeValues = {
  spacing: theme.spacing,
};

export const singleMonthControlsHeaderThemeOriginal: SingleMonthControlsHeaderThemeValues = {
  spacing: themeOriginal.spacing,
};

export const monthNameTheme: MonthNameThemeValues = {
  color: theme.colors.neutral90,
  fontSize: theme.fontSize,
  fontWeight: theme.font.weight.bold,
};

export const monthNameThemeOriginal: MonthNameThemeValues = {
  color: themeOriginal.colors.gray[600],
  fontSize: themeOriginal.fontSize,
  fontWeight: themeOriginal.font.weight.bold,
};
