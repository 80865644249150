import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../../../component-theme/theme-types';
import { getThemeFontColor, getThemeOriginalFontColor } from '../utils';

export type HelperTextProps = {
  disabled?: boolean;
  hasError?: boolean;
};

export type HelperTextStyleValues = {
  color: WeaveThemeValues['Color'];
  fontSize: string;
  paddingTop: string;
};

export const getHelperTextTheme = ({ disabled, hasError }: HelperTextProps): HelperTextStyleValues => ({
  color: getThemeFontColor({ disabled, hasError, theme }),
  fontSize: theme.fontSize(12),
  paddingTop: theme.spacing(1),
});

export const getHelperTextThemeOriginal = ({ disabled, hasError }: HelperTextProps): HelperTextStyleValues => ({
  color: getThemeOriginalFontColor({ disabled, hasError, theme: themeOriginal }),
  fontSize: themeOriginal.fontSize(12),
  paddingTop: themeOriginal.spacing(1),
});
