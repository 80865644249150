import { useTooltip } from '../../';
import type { ColoredDotProps } from '../dot.component';
import { Dot } from '../dot.component';

export const PrimaryDot = ({ hoverText, ...props }: ColoredDotProps) => {
  const { Tooltip, tooltipProps, triggerProps } = useTooltip({ placement: 'top' });
  const { ref, ...rest } = triggerProps;

  return (
    <Dot color='primary' {...props} {...rest} innerRef={ref}>
      <Tooltip {...tooltipProps}>{hoverText}</Tooltip>
    </Dot>
  );
};
