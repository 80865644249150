import { useEffect } from 'react';
import { IPCRendererCallback, shell } from './adapter';

let lastRan: Date | undefined = undefined;
const throttle = 2000;
export const useShell = () => {
  useEffect(() => {
    if (!shell.isShell) {
      return;
    }
    if (lastRan && lastRan.valueOf() > Date.now() - throttle) {
      return;
    }

    lastRan = new Date();
    const id = 'info:version' + Math.random();
    shell.emit?.('info:version', undefined);

    const handler: IPCRendererCallback<'info:version'> = (_e, version) => {
      if (version) {
        //TODO: upgrade this to state if we need to track it, or if it becomes critical for it to be stable. It probably doesn't
        shell.version = version;
      }
    };
    shell.on?.('info:version', handler, id);
    return () => {
      shell.off?.('info:version', handler, id);
    };
  }, []);

  return shell;
};
