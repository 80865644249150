import { create, Mutate, StoreApi, UseBoundStore } from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';
import { devTools } from './devtools';
import { withImmer } from './withImmer';
import { StateCreator } from './zustand';

/**
 * Helper function that creates a Zustand store that integrates subscribeWithSelector middleware.
 * The subscribe functionality allows components to bind to a state-portion without forcing re-render on changes.
 * This can make a drastic performance impact when you are allowed to mutate the view directly.
 *
 * Furthermore, it integrates the store with Redux Devtools and implements immer middleware that allows working with
 * immutable state in a more convenient way.
 * @param config - Store configuration method
 * @param options - redux devtools options
 * @returns A Zustand store integrated with subscribeWithSelector middleware, Redux Devtools and immer.
 */

type AdditionalMethods = {
  reset: () => void;
};

type StoreReturnType<T> = UseBoundStore<
  Mutate<StoreApi<T>, [['zustand/subscribeWithSelector', never], ['zimmer', never], ['zustand/devtools', never]]>
> &
  AdditionalMethods;

const resetSet = new Set<AdditionalMethods['reset']>();

export const createStoreWithSubscribe = <T>(
  config: StateCreator<T, [['zustand/subscribeWithSelector', never], ['zimmer', never], ['zustand/devtools', never]]>,
  options: Parameters<typeof devtools>[1]
) => {
  const store = create<T>()(subscribeWithSelector(withImmer(devTools(config, options)))) as StoreReturnType<T>;
  const initialState = store.getState();

  const reset = () => {
    store.setState(initialState, true, 'RESET_STORE');
  };
  resetSet.add(reset);
  store.reset = reset;
  return store;
};

export const resetStoreWithSubscribe = () => resetSet.forEach((reset) => reset());
