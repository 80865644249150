import { PopoverDialog, usePopoverDialog } from '../../popover';
import { ActionPressedChip } from '../atoms';
import { css } from '@emotion/react';
import { LinkIconSmall } from '../../../icon';
import { TextField, useFormField } from '../../forms';
import { KeyNames } from '../../../constants';
import { theme } from '@frontend/theme';

type HyperlinkActionProps = {
  value?: string;
  onChange: (link: string) => void;
} & Pick<React.ComponentProps<typeof ActionPressedChip>, 'showBoxShadow' | 'compact' | 'css' | 'isActive'>;

export const HyperlinkAction = ({
  value = '',
  showBoxShadow = true,
  compact = true,
  onChange,
  isActive,
  ...rest
}: HyperlinkActionProps) => {
  const { getDialogProps, getTriggerProps, isOpen, close } = usePopoverDialog<HTMLButtonElement | HTMLAnchorElement>({
    placement: 'bottom-start',
    middlewareOptions: {
      offset: {
        crossAxis: 0,
        mainAxis: 12,
      },
    },
  });

  const linkField = useFormField({ type: 'text', value }, [value, isOpen]);

  return (
    <>
      <ActionPressedChip
        showBoxShadow={showBoxShadow}
        isActive={isOpen || isActive}
        compact={compact}
        {...getTriggerProps()}
        {...rest}
      >
        <LinkIconSmall />
      </ActionPressedChip>
      <PopoverDialog
        {...getDialogProps()}
        returnFocus={false}
        css={css`
          max-width: 400px;
          width: 400px;
          border-radius: ${theme.borderRadius.medium};
        `}
      >
        <TextField
          actionText='Delete'
          onActionClick={() => onChange('')}
          onKeyDown={(e) => {
            if (e.key === KeyNames.Enter) {
              e.preventDefault();
              onChange(/^(http|https):\/\//.test(linkField.value) ? linkField.value : `https://${linkField.value}`);
              close();
            }
          }}
          name='custom-size-action'
          label=''
          {...linkField}
        />
      </PopoverDialog>
    </>
  );
};
