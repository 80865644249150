import { i18next } from '@frontend/i18n';
import { IconName } from '@frontend/icons';
import { WidgetLibraryFilters } from './types';

type WidgetDimensions = { w: number; h: number };

export const MAX_GRID_COLUMNS = 6; //units
export const GRID_HEIGHT = 4; //4px
export const GRID_UNIT_WIDTH = 158; //158px

export const WidgetColumnSpaces = {
  slim: 1,
  narrow: 2,
  wide: 4,
};

export const GridHeightUnits = {
  small: 35,
  medium: 70,
  tall: 105,
};

type WidgetSizeOptions = 'tiny' | 'small' | 'medium' | 'large';
type WidgetSizeWidthUnits = 'slim' | 'narrow' | 'wide';

export type WidgetSizes =
  | 'tiny-slim'
  | `${Exclude<WidgetSizeOptions, 'tiny'>}-${Exclude<WidgetSizeWidthUnits, 'slim'>}`;

export const widgetSizes = {
  'tiny-slim': { w: WidgetColumnSpaces.slim, h: GridHeightUnits.small },
  'small-narrow': { w: WidgetColumnSpaces.narrow, h: GridHeightUnits.small },
  'small-wide': { w: WidgetColumnSpaces.wide, h: GridHeightUnits.small },
  'medium-narrow': { w: WidgetColumnSpaces.narrow, h: GridHeightUnits.medium },
  'medium-wide': { w: WidgetColumnSpaces.wide, h: GridHeightUnits.medium },
  'large-narrow': { w: WidgetColumnSpaces.narrow, h: GridHeightUnits.tall },
  'large-wide': { w: WidgetColumnSpaces.wide, h: GridHeightUnits.tall },
} satisfies Record<WidgetSizes, WidgetDimensions>;

export const librarySpace = {
  'tiny-slim': { ratio: '1.274/1' },
  'small-narrow': { ratio: '2.66/1' },
  'small-wide': { ratio: '5.516/1' },
  'medium-narrow': { ratio: '1.213/1' },
  'medium-wide': { ratio: '2.514/1' },
  'large-narrow': { ratio: '1/1.272' },
  'large-wide': { ratio: '1.628/1' },
};

export const sizesMap = {
  'tiny-slim': {
    label: i18next.t('Short Narrow', { ns: 'dashboard' }),
    iconHeight: 6,
    iconWidth: 7.65,
  },
  'small-narrow': {
    label: i18next.t('Short Regular', { ns: 'dashboard' }),
    iconHeight: 6,
    iconWidth: 16.06,
  },
  'small-wide': {
    label: i18next.t('Short Wide', { ns: 'dashboard' }),
    iconHeight: 6,
    iconWidth: 33.4,
  },
  'medium-narrow': {
    label: i18next.t('Medium Regular', { ns: 'dashboard' }),
    iconHeight: 13,
    iconWidth: 16.35,
  },
  'medium-wide': {
    label: i18next.t('Medium Wide', { ns: 'dashboard' }),
    iconHeight: 13,
    iconWidth: 33.48,
  },
  'large-narrow': {
    label: i18next.t('Tall Regular', { ns: 'dashboard' }),
    iconHeight: 20,
    iconWidth: 16.44,
  },
  'large-wide': {
    label: i18next.t('Tall Wide', { ns: 'dashboard' }),
    iconHeight: 20,
    iconWidth: 33.66,
  },
} satisfies Record<WidgetSizes, { label: string; iconHeight: number; iconWidth: number }>;

export const dashboardBreakpoints = { large: 1031, medium: 833, small: 500, extraSmall: 430 };

const zeroMargins: [number, number] = [0, 0];
export const defaultMargins: Record<string, [number, number]> = {
  large: zeroMargins,
  medium: zeroMargins,
  small: zeroMargins,
  extraSmall: zeroMargins,
};
export const gridColumns = { large: 6, medium: 4, small: 3, extraSmall: 2 };

export const widgetLibraryFeatures: Record<WidgetLibraryFilters, { label: string; icon?: IconName }> = {
  all: {
    label: i18next.t('All', { ns: 'dashboard' }),
  },
  general: {
    label: i18next.t('General', { ns: 'dashboard' }),
  },
  phone: {
    label: i18next.t('Phone', { ns: 'dashboard' }),
  },
  messaging: {
    label: i18next.t('Messaging', { ns: 'dashboard' }),
  },
  payments: {
    label: i18next.t('Payments', { ns: 'dashboard' }),
  },
  schedule: {
    label: i18next.t('Schedule', { ns: 'dashboard' }),
  },
  fax: {
    label: i18next.t('Fax', { ns: 'dashboard' }),
  },
  email: {
    label: i18next.t('Email', { ns: 'dashboard' }),
  },
  forms: {
    label: i18next.t('Forms', { ns: 'dashboard' }),
  },
};

export const featureList = Object.keys(widgetLibraryFeatures) as (keyof typeof widgetLibraryFeatures)[];
