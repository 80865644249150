import React from 'react';
import { useStyles } from '../../../../use-styles';
import { CaretLeftIcon, CaretRightIcon } from '../../../../icon';
import {
  CalendarContainer,
  CalendarMonth,
  CalendarControl,
  CalendarWeekHeader,
  CalendarTitle,
  CalendarMonths,
  CalendarYears,
} from '../../atoms';
import { IconButton } from '../../../icon-button';
import { SELECT_BY_MAP, useCalendarState } from '../../provider';

export const DialogCalendarDisplay = () => {
  const { months, selectBy } = useCalendarState();
  const { month, year, weeks } = months[0];
  const calWeekHeaderStyle = useStyles('CalendarCommonStyles', 'calWeekHeaderStyles', {
    fontWeight: 'bold',
  });
  const calContainerStyle = useStyles('CalendarCommonStyles', 'calContainerStyles');
  const controlsHeader = useStyles('CalendarControls', 'controlsHeader');
  const monthName = useStyles('CalendarControls', 'monthName');
  const calendarControls = useStyles('CalendarControls', 'calendarControls');

  return (
    <CalendarContainer css={calContainerStyle}>
      <header css={controlsHeader}>
        <CalendarControl
          renderPrevButton={(props) => (
            <IconButton label={`Previous ${SELECT_BY_MAP[selectBy]}`} showLabelOnHover type='button' {...props}>
              <CaretLeftIcon color='light' />
            </IconButton>
          )}
          renderNextButton={(props) => (
            <IconButton label={`Next ${SELECT_BY_MAP[selectBy]}`} showLabelOnHover type='button' {...props}>
              <CaretRightIcon color='light' />
            </IconButton>
          )}
          css={calendarControls}
        >
          <CalendarTitle month={month} year={year} css={monthName} />
        </CalendarControl>
      </header>
      {selectBy === 'day' && (
        <React.Fragment>
          <CalendarWeekHeader css={calWeekHeaderStyle} />
          <CalendarMonth weeks={weeks} />
        </React.Fragment>
      )}
      {selectBy === 'month' && <CalendarMonths month={month} year={year} />}
      {selectBy === 'year' && <CalendarYears year={year} />}
    </CalendarContainer>
  );
};
