import dayjs, { Dayjs } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);

export const DEFAULT_DISPLAY_DATE_FORMAT = 'MM/dd/yyyy';

/**
 * Takes a date string and returns a date object
 * @param dateString Date string formatted as MM/DD/YYY
 */
export const createDateObject = (dateString: string) => {
  const parts = dateString.split('/');
  return new Date(+parts[2], +parts[0] - 1, +parts[1]);
};

export const createISODateInUTC = (dateString: string) => {
  return dayjs(dateString).format('YYYY-MM-DDT00:00:00.000') + 'Z';
};

/**
 * Takes an ISO date string and returns a display date string
 * formatted MM/DD/YYY
 * @param date ISO Date string
 */
export const isoDateToDisplay = (date: string) => {
  return dayjs(new Date(date)).format('MM/DD/YYYY');
};

export const isoDateToDisplayInUTC = (date: string) => {
  return dayjs(date).utc().format('MM/DD/YYYY');
};

/**
 * Formats a timestamp (sec) or date string
 * @param date Timestamp in sec or Date string
 */
export const formatDate = (
  date?: string | number | Date | Dayjs,
  formattedDate?: string,
  useOnlyUTC = false
): string => {
  if (!date) return '';

  let dateObj = typeof date === 'number' ? dayjs.unix(date) : dayjs(date);
  if (isNaN(dateObj.toDate().getTime())) return '';
  // adding this check to ignore DB default date (0001-01-01T00:00:00Z)
  if (dateObj.year() < 1900) return '';

  if (useOnlyUTC) dateObj = dateObj.utc();

  return dateObj.format(formattedDate);
};

export const getTodaysDate = (formattedDate: string) => dayjs().format(formattedDate);

export enum Unit {
  MILLISECOND = 'millisecond',
  SECOND = 'second',
  MINUTE = 'minute',
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

type DurationFrom = {
  date?: string;
  duration: number;
  unit: Unit;
  format?: string;
};

export const getPastDate = ({ date, duration, format, unit }: DurationFrom) =>
  (date ? dayjs(date) : dayjs()).subtract(duration, unit).format(format ?? 'MM/DD/YYYY');

export const getFutureDate = ({ date, duration, format, unit }: DurationFrom) =>
  (date ? dayjs(date) : dayjs()).add(duration, unit).format(format ?? 'MM/DD/YYYY');

export const isDateValid = (date: string | Date) => dayjs(date).isValid();

export const formatDateInMonthAndDate = (date: Date): string => {
  if (!date) return '';
  const monthNameWithDate = dayjs(date).format('MMMM Do');
  return monthNameWithDate;
};
