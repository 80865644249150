import { css } from '@emotion/react';
import { infoTheme, infoThemeOriginal } from './';
import type { ColorThemeValues } from './info-theme';

const style = ({ focusColor }: ColorThemeValues) => css`
  vertical-align: middle;

  :focus {
    outline: none;
  }

  svg {
    transition: fill 200ms ease-in-out;
  }

  :hover svg,
  :focus svg {
    fill: ${focusColor};
  }
`;

export const InfoStyles = {
  Info: style(infoTheme),
};
export const InfoStylesOriginal: typeof InfoStyles = {
  Info: style(infoThemeOriginal),
};
