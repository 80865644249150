import { create, StoreApi, Mutate, StoreMutatorIdentifier, UseBoundStore } from 'zustand';
import { devtools, persist, PersistOptions, subscribeWithSelector } from 'zustand/middleware';
import { withImmer } from './withImmer';
import { devTools } from './devtools';
import { StateCreator } from './zustand';
/**
 * Helper function that creates a Zustand store that allows persisting store's data using any kind of storage.
 * it’s useful to persist a Zustand store, so we don’t lose its state if a user refreshes the website or
 * comes back later.
 *
 * It also integrates the store with subscribeWithSelector middleware.
 * The subscribe functionality allows components to bind to a state-portion without forcing re-render on changes.
 * This can make a drastic performance impact when you are allowed to mutate the view directly.
 *
 * Furthermore, it integrates the store with Redux Devtools and implements immer middleware that allows working with
 * immutable state in a more convenient way.
 * @param config - Store configuration method
 * @param persistOptions - config object for persist middleware.
 * @param options - redux devtools options
 * @returns A Zustand store integrated with subscribeWithSelector middleware, persist middleware,
 * Redux Devtools and immer.
 */

type AdditionalMethods = {
  /**Resets the store to the initial state*/
  reset: () => void;
};

type StoreReturnType<T, Mos extends [StoreMutatorIdentifier, unknown][] = []> = UseBoundStore<
  Mutate<
    StoreApi<T>,
    [
      ['zustand/subscribeWithSelector', never],
      ['zustand/persist', T],
      ['zimmer', never],
      ['zustand/devtools', never],
      ...Mos
    ]
  >
> &
  AdditionalMethods;

const resetSet = new Set<AdditionalMethods['reset']>();

export const createStoreWithPersistAndSubscribe = <T, Mos extends [StoreMutatorIdentifier, unknown][] = []>(
  config: StateCreator<
    T,
    [
      ['zustand/subscribeWithSelector', never],
      ['zustand/persist', unknown],
      ['zimmer', never],
      ['zustand/devtools', never]
    ],
    Mos
  >,
  persistOptions: PersistOptions<T>,
  options: Parameters<typeof devtools>[1]
): StoreReturnType<T, Mos> => {
  const store = create<T>()(
    subscribeWithSelector(persist(withImmer(devTools(config, options)), persistOptions))
  ) as StoreReturnType<T, Mos>;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const initialState = store.getState();

  const reset = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    store.setState(initialState, true, 'RESET_STORE');
  };
  resetSet.add(reset);
  store.reset = reset;
  return store;
};

export const resetStoreWithPersistAndSubscribe = () => resetSet.forEach((reset) => reset());
