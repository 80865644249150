import { css } from '@emotion/react';
import { useQuery } from 'react-query';
import { DataSourcesHooks } from '@frontend/api-data-sources';
import { PersonAPI, PersonTypes } from '@frontend/api-person';
import { useTranslation } from '@frontend/i18n';
import { useMultiQueryUtils } from '@frontend/payments-hooks';
import { Photos } from '@frontend/photos';
import { theme } from '@frontend/theme';
import {
  ComboboxBase,
  PopoverMenuItem,
  SpinningLoader,
  Text,
  TextField,
  UserIcon,
  useDebouncedValue,
  useFormField,
  usePopoverMenu,
} from '@frontend/design-system';

const styles = {
  popoverMenuItem: css`
    height: auto;
    padding: ${theme.spacing(1, 2)};
  `,
  personInfo: css`
    display: flex;
    flex-flow: row;
    gap: ${theme.spacing(1)};
    align-items: center;
  `,
  personName: css`
    width: 100%;
  `,
  noResult: css`
    padding: ${theme.spacing(1, 2)};
  `,
  textField: (isOpen: boolean) => css`
    border-radius: ${isOpen ? '8px 8px 0 0' : theme.borderRadius.small};
  `,
};

const API_RESPONSE_LIMIT = 50;
export const PatientDropdown = ({
  setSelectedPatient,
  locationId: locationIdProp,
  searchFieldProps: passedInSearchFieldProps,
}: {
  setSelectedPatient: (patient: PersonTypes.Person | null) => void;
  locationId?: string;
  searchFieldProps?: ReturnType<typeof useFormField<'text'>>;
}) => {
  const { t } = useTranslation('payments');
  const { demoSourceIds } = DataSourcesHooks.useDemoLocationSourceIdsShallowStore('demoSourceIds');
  const { locationId: locationIdFromUtils } = useMultiQueryUtils();
  const searchFieldProps = useFormField({ type: 'text', required: true });
  const { onChange: searchFieldOnChange, ...searchField } = passedInSearchFieldProps ?? searchFieldProps;

  const { getMenuProps, getItemProps, getTriggerProps, open, close, activeIndex, setActiveIndex, refs, isOpen } =
    usePopoverMenu<HTMLInputElement>({
      placement: 'bottom',
      interactionOptions: { listNavigation: { virtual: true }, typeahead: { enabled: false } },
    });

  const locationId = locationIdProp ?? locationIdFromUtils;

  const searchValue = useDebouncedValue(searchField.value, API_RESPONSE_LIMIT);
  const personsQuery = useQuery({
    queryKey: [locationId, 'payment-plan-person', searchValue, ...demoSourceIds],
    queryFn: () =>
      PersonAPI.getPersonsV2({
        order: ['last_name', 'first_name'],
        status: 'Active',
        query: searchValue,
        locationId,
        dataSourceIds: demoSourceIds,
      }),
    enabled: !!searchValue,
  });

  const fullName = (patient: PersonTypes.Person) => `${patient?.FirstName ?? ''} ${patient?.LastName ?? ''}`;
  const handleSelectPerson = (person: PersonTypes.Person) => {
    searchFieldOnChange({ name: 'person-search', value: fullName(person) });
    setSelectedPatient(person);
    close();
  };
  const getDropdownWidth = () => {
    const dropdown = document.querySelector('#customer-dropdown');
    const width = dropdown?.clientWidth;
    return Number(width) + 16 + 16 + 16 + 12;
  };
  let content;

  if (personsQuery.isLoading) {
    content = <SpinningLoader css={{ margin: `${theme.spacing(1)} auto` }} />;
  } else if (personsQuery.data?.rows?.length) {
    content = personsQuery.data.rows.map((person, index) => (
      <PopoverMenuItem
        {...getItemProps({
          index,
          onClick: () => handleSelectPerson(person),
        })}
        key={person.PersonID}
        css={styles.popoverMenuItem}
      >
        <div css={styles.personInfo}>
          <Photos.ContactProfilePhoto personId={person.PersonID} name={fullName(person)} size='small' disableClick />
          <Text css={styles.personName}>{fullName(person)}</Text>
        </div>
      </PopoverMenuItem>
    ));
  } else if (searchField.value === '' || personsQuery.data?.rows.length === 0) {
    content = (
      <Text size='small' css={styles.noResult}>
        {t('No results to display...')}
      </Text>
    );
  }

  const { ref, ...triggerProps } = getTriggerProps({
    onKeyDown: (event) => {
      if (event.key === 'Enter' && activeIndex != null && personsQuery.data?.rows[activeIndex]) {
        const item = personsQuery.data?.rows[activeIndex];

        searchFieldOnChange({ name: 'person-search', value: `${item.FirstName} ${item.LastName}` });
        setSelectedPatient(item);
        setActiveIndex(null);
        close();
      }
    },
    onChange: (e) => {
      searchFieldOnChange(e);
      // @ts-ignore our change events are still messed up :(
      if (e.target.value) {
        open();
      } else {
        close();
        setSelectedPatient(null);
      }
    },
  });

  return (
    <ComboboxBase
      Input={
        <TextField
          {...triggerProps}
          {...searchField}
          name='person-search'
          label={t('Customer *')}
          id='customer-dropdown'
          fieldComponentProps={{ ref: refs.setReference, autoComplete: 'off' }}
          startAdornment={<UserIcon size={16} color='light' />}
          css={styles.textField(isOpen)}
        />
      }
      menuProps={getMenuProps()}
      menuStyles={css({
        maxWidth: 600,
        minWidth: 228,
        width: getDropdownWidth(),
        marginLeft: '-12px',
        marginTop: theme.spacing(0.4),
        borderRadius: '0 0 4px 4px',
      })}
    >
      {content}
    </ComboboxBase>
  );
};
