import { theme } from '@frontend/theme';
import type { ShellColorTheme } from './types';

const solidColorToGradient = (color: string) => {
  return `linear-gradient(90deg, ${color} 0%, ${color} 100%)`;
};

export const defaultNonShellTheme = {
  shellColor: theme.colors.neutral10,
  sideNavColor: theme.colors.neutral10,
  hover: theme.colors.white,
  iconColor: theme.font.colors.default,
  activeIconColor: theme.font.colors.primary,
  helpColor: theme.colors.neutral10,
};

export const predefinedThemes = {
  primaryDKGradient: {
    shellColor: 'linear-gradient(270.04deg, #146EF5 0%, #0045AD 99.97%)',
    sideNavColor: 'linear-gradient(180.04deg, #146EF5 0%, #0045AD 99.97%)',
    hover: theme.colors.primary40,
    iconColor: theme.font.colors.white,
    activeIconColor: theme.font.colors.white,
    helpColor: theme.colors.primary90,
  },
  seaweedLTGradient: {
    shellColor: 'linear-gradient(135.55deg, #C2F4F1 25.85%, #89E1DB 107.81%)',
    sideNavColor: 'linear-gradient(135.55deg, #C2F4F1 25.85%, #89E1DB 107.81%)',
    hover: theme.colors.white,
    iconColor: theme.font.colors.default,
    activeIconColor: theme.font.colors.default,
    helpColor: theme.colors.secondary.seaweed30,
  },
  seaweedColor: {
    shellColor: solidColorToGradient(theme.colors.secondary.seaweed70),
    sideNavColor: solidColorToGradient(theme.colors.secondary.seaweed70),
    hover: theme.colors.secondary.seaweed50,
    iconColor: theme.colors.white,
    activeIconColor: theme.font.colors.white,
    helpColor: theme.colors.secondary.seaweed80,
  },
  seaweedDKGradient: {
    shellColor: 'linear-gradient(90deg, #018479 0%, #07A198 100%)',
    sideNavColor: 'linear-gradient(0deg, #018479 0%, #07A198 100%)',

    hover: theme.colors.secondary.seaweed20,
    iconColor: theme.font.colors.white,
    activeIconColor: theme.font.colors.default,
    helpColor: theme.colors.secondary.seaweed60,
  },
  eggplantDKGradient: {
    shellColor: 'linear-gradient(90.07deg, #3F0250 0%, #7D2099 100%)',
    sideNavColor: 'linear-gradient(180deg, #3F0250 0%, #7D2099 100%)',
    hover: theme.colors.secondary.eggplant50,
    iconColor: theme.font.colors.white,
    activeIconColor: theme.font.colors.white,
    helpColor: theme.colors.secondary.eggplant90,
  },
  eggplantLTGradient: {
    shellColor: 'linear-gradient(135.55deg, #F8E3FD 25.85%, #E5AFF3 107.81%)',
    sideNavColor: 'linear-gradient(0deg, #F8E3FD 25.85%, #E5AFF3 107.81%)',
    hover: theme.colors.white,
    iconColor: theme.font.colors.default,
    activeIconColor: theme.colors.primary50,
    helpColor: theme.colors.secondary.eggplant30,
  },
  primaryLTGradient: {
    shellColor: 'linear-gradient(135.55deg, #DCEBFE 25.85%, #9EC5FF 107.81%)',
    sideNavColor: 'linear-gradient(0deg, #DCEBFE 25.85%, #9EC5FF 107.81%)',
    hover: theme.colors.white,
    iconColor: theme.font.colors.default,
    activeIconColor: theme.colors.primary50,
    helpColor: theme.colors.primary30,
  },
  neutralLTGradient: {
    shellColor: 'linear-gradient(90.04deg, #F4F5F7 17.47%, #E7EBEE 90.13%)',
    sideNavColor: 'linear-gradient(0.04deg, #F4F5F7 17.47%, #E7EBEE 90.13%)',
    hover: theme.colors.white,
    iconColor: theme.font.colors.default,
    activeIconColor: theme.font.colors.default,
    helpColor: theme.colors.neutral20,
  },
  popcorn: {
    shellColor:
      'linear-gradient(307deg, lch(61.1623 40.2766 247.807) 0%, lch(60.7006 57.9823 272.506) 25%, lch(49.6318 68.5074 285.029) 50%, lch(57.5978 69.2666 311.957) 75%, lch(60.7064 69.2666 341.957) 100%)',
    sideNavColor:
      'linear-gradient(397deg, lch(61.1623 40.2766 247.807) 0%, lch(60.7006 57.9823 272.506) 25%, lch(49.6318 68.5074 285.029) 50%, lch(57.5978 69.2666 311.957) 75%, lch(60.7064 69.2666 341.957) 100%)',
    hover: 'lch(88.470 23.332 229.394)',
    iconColor: theme.font.colors.white,
    activeIconColor: theme.font.colors.default,
    helpColor: 'lch(37.887 67.215 290.304)',
  },
} satisfies Record<string, ShellColorTheme>;
