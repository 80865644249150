import { css } from '@emotion/react';
import { useTabbable, useThemeValues, useRefInterceptor } from '../../hooks';
import { CSSProperties, forwardRef, ReactNode, useEffect } from 'react';
import { animated } from 'react-spring';
import { DialogFlyoutProps } from './types';

type Props = DialogFlyoutProps<HTMLDivElement> & {
  disableAutofocus?: boolean;
  children: ReactNode;
  style?: CSSProperties;
  className?: string;
};

/**
 *
 * @deprecated use PopoverDialog instead
 */
export const FlyoutDialog = forwardRef<HTMLElement, Props>(({ disableAutofocus, children, ...rest }, ref) => {
  const [localRef, elementRef] = useRefInterceptor(ref);

  useTabbable({ ref: localRef });
  useEffect(() => {
    if (!disableAutofocus) {
      setTimeout(() => {
        localRef.current?.focus();
      }, 0);
    }
  }, []);
  const theme = useThemeValues();

  return (
    <animated.div
      css={css`
        background: ${theme.colors.white};
        box-shadow: ${theme.shadows.heavy};
        position: absolute;

        :focus {
          outline: none;
        }
      `}
      {...rest}
      ref={elementRef}
    >
      {children}
    </animated.div>
  );
});
