/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const CaretUpIconSmall: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={16} {...props} viewBox='0 0 16 16'>
    <path
      fillRule='evenodd'
      d='M5.07106781,13.0710678 L12.0710678,13.0710678 C12.6233526,13.0710678 13.0710678,13.5187831 13.0710678,14.0710678 C13.0710678,14.6233526 12.6233526,15.0710678 12.0710678,15.0710678 L4.07106781,15.0710678 C3.79492544,15.0710678 3.54492544,14.959139 3.36396103,14.7781746 C3.18299662,14.5972102 3.07106781,14.3472102 3.07106781,14.0710678 L3.07106781,6.07106781 C3.07106781,5.51878306 3.51878306,5.07106781 4.07106781,5.07106781 C4.62335256,5.07106781 5.07106781,5.51878306 5.07106781,6.07106781 L5.07106781,13.0710678 Z'
      transform='translate(8.071068, 10.071068) scale(1, -1) rotate(-45.000000) translate(-8.071068, -10.071068) '
    />
  </Icon>
);
