import { create } from 'zustand';
import { breakpoints } from './breakpoints';
import { useMatchMedia } from './use-match-media';
import { useWindowDimensions } from './use-window-dimensions';

/**
 * Used to find out which media range the window matches.
 * Be careful with this return value, as each match fn is a hook,
 * so don't ever conditionally invoke any of these, or you'll get a mismatched hooks error.
 */
export const useMediaMatches = create(() => ({
  matches: {
    xsmallMax: () => useMatchMedia({ maxWidth: breakpoints.xsmall.max }),
    smallMin: () => useMatchMedia({ minWidth: breakpoints.small.min }),
    smallMax: () => useMatchMedia({ minWidth: breakpoints.small.max }),
    smallMinMax: () =>
      useMatchMedia({
        minWidth: breakpoints.small.min,
        maxWidth: breakpoints.small.max,
      }),
    mediumMin: () => useMatchMedia({ minWidth: breakpoints.medium.min }),
    mediumMax: () => useMatchMedia({ minWidth: breakpoints.medium.max }),
    mediumMinMax: () =>
      useMatchMedia({
        minWidth: breakpoints.medium.min,
        maxWidth: breakpoints.medium.max,
      }),
    largeMin: () => useMatchMedia({ minWidth: breakpoints.large.min }),
    largeMax: () => useMatchMedia({ maxWidth: breakpoints.large.max }),
    largeMinMax: () =>
      useMatchMedia({
        minWidth: breakpoints.large.min,
        maxWidth: breakpoints.large.max,
      }),
  },
}));

export const useBreakpoint = () => {
  const { width } = useWindowDimensions();

  const isXsmall = width <= breakpoints.xsmall.max;
  const isSmall = width > breakpoints.small.min && width <= breakpoints.small.max;
  const isMedium = width > breakpoints.medium.min && width <= breakpoints.medium.max;
  const isLarge = width > breakpoints.large.min && width <= breakpoints.large.max;

  if (isXsmall) {
    return 'xsmall';
  }
  if (isSmall) {
    return 'small';
  }
  if (isMedium) {
    return 'medium';
  }
  if (isLarge) {
    return 'large';
  }
  return 'large';
};
