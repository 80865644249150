import { create } from 'zustand';

type Options = {
  isDebugModeOn: boolean;
  isDevToolsButtonVisible: boolean;
  debugUseLocalizedQuery: boolean;
  showRLDevtools: boolean;
  showRQDevtools: boolean;
  showDebugDataTracking: boolean;
  showVoicemailDetails: boolean;
  showTenantType: boolean;
};
export interface DevToolsStore {
  options: Options;
  setOptions: (val: Partial<Options>) => void;
}

export const useDevToolsStore = create<DevToolsStore>((set) => ({
  options: {
    debugUseLocalizedQuery: false,
    isDevToolsButtonVisible: false,
    showRLDevtools: false,
    showRQDevtools: false,
    isDebugModeOn: false,
    showDebugDataTracking: false,
    showVoicemailDetails: false,
    showTenantType: false,
  },
  setOptions: (opts: Partial<Options>) => set((state) => ({ options: { ...state.options, ...opts } })),
}));
