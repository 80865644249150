import { PersonSecondaryInfo, PersonSecondaryInfoProps } from '../person-secondary-info';
import { useThreadHeaderContext } from '../thread-header';

type InfoProps = Omit<PersonSecondaryInfoProps, 'personId' | 'groupId' | 'person' | 'groupIds'>;

export const Info = (props: InfoProps) => {
  const { groupId, primaryContact, primaryContactId } = useThreadHeaderContext();
  const hasChildren = Array.isArray(props.children) ? props.children.length > 0 : !!props.children;
  const personId = primaryContactId || primaryContact?.personId;

  if (!hasChildren && (!personId || !groupId)) return null;

  return <PersonSecondaryInfo {...props} personId={personId ?? ''} groupIds={[groupId]} person={primaryContact} />;
};
