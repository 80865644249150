/**
 * Replacement of polished lib
 * Taken from https://bit.cloud/styled-components/polished/internal/_errors/~code
 */
export class PolishedError extends Error {
  constructor(code: string | number) {
    super(
      `An error occurred. See https://github.com/styled-components/polished/blob/master/src/internalHelpers/errors.md#${code} for more information.`
    );
  }
}
