import { theme as newTheme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import { convertToCssVars } from './convert-to-css-vars';
import { mergeThemes } from './merge-themes';

const gray = {
  100: newTheme.colors.neutral5,
  200: newTheme.colors.neutral10,
  300: newTheme.colors.neutral20,
  400: newTheme.colors.neutral30,
  500: newTheme.colors.neutral50,
  600: newTheme.colors.neutral90,
} as const;

const themeOriginalMappedColors = {
  ...themeOriginal,
  colors: {
    ...themeOriginal.colors,
    gray,
    gray100: gray[100],
    gray200: gray[200],
    gray300: gray[300],
    gray400: gray[400],
    gray500: gray[500],
    gray600: gray[600],
    weaveBlue: newTheme.colors.primary50,
    hoverBlue: newTheme.colors.primary40,
    pressedBlue: newTheme.colors.primary60,
    studio: newTheme.colors.secondary.eggplant70,
    success: newTheme.colors.status.success,
    warning: newTheme.colors.status.warning,
    error: newTheme.colors.status.critical,
    errorPressed: newTheme.colors.status.criticalPressed,
    errorHovered: newTheme.colors.status.criticalHover,
  },
  // Inter font is meant just for the desktop and WAM
  fontFamily: 'Inter, sans-serif',
} as const;

const mergedTheme = mergeThemes(themeOriginalMappedColors, newTheme);

export const themeValues = {
  ...mergedTheme,
  // Add here so mergeThemes doesn't override the font family
  font: {
    ...mergedTheme.font,
    family: 'Inter, sans-serif',
  },
};

/**
 * Use this theme
 */
export const theme = {
  ...convertToCssVars(themeValues),
  name: 'adapter',
};

/**
 * Original theme values provides access directly to the object values.
 * Prefer using the values of `theme` as they are css variables and
 * will update with the theme values change.
 *
 * @deprecated Only used to set the CSS theme values
 */
export const originalThemeValues = themeOriginal;
export const newThemeValues = themeValues;
export type WeaveTheme = typeof theme;
