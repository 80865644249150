import { css } from '@emotion/react';
import { getTerminalImage } from '@frontend/payments-collection-flow';
import { TerminalReader } from '@frontend/payments-terminal-controller';
import { theme } from '@frontend/theme';
import { Text, Chip, TableColumnConfig } from '@frontend/design-system';

const styles = {
  terminalName: css`
    display: flex;
    gap: ${theme.spacing(1)};
    align-items: center;
  `,
  terminalIcon: css`
    width: ${theme.spacing(2)};
  `,
};

type TerminalNameProps = {
  name: string;
  deviceType: TerminalReader['deviceType'];
};

const TerminalName = ({ name, deviceType }: TerminalNameProps) => (
  <div css={styles.terminalName}>
    <img src={getTerminalImage(deviceType)} alt='Terminal' css={styles.terminalIcon} />
    <Text>{name}</Text>
  </div>
);

export const generateColumns = () =>
  [
    {
      id: 'name',
      Header: 'Name',
      accessor: ({ label, deviceType }) => ({ name: label, deviceType }),
      cellRenderer: (props: TerminalNameProps) => <TerminalName {...props} />,
    },
    { id: 'model', Header: 'Model', accessor: (reader: TerminalReader) => reader.deviceType },
    {
      id: 'status',
      Header: 'Status',
      accessor: (reader: TerminalReader) => reader,
      cellRenderer: (reader: TerminalReader) => (
        <Chip variant={reader.status === 'offline' ? 'default' : 'primary'}>{reader.status}</Chip>
      ),
    },
  ] satisfies TableColumnConfig<TerminalReader>[];
