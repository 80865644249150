import React, { forwardRef } from 'react';
import { css } from '@emotion/react';
import { NakedButton } from '../../../naked';
import { styles } from '../../../styles';
import { theme } from '@frontend/theme';

export type ActionPressedChipProps = React.HTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  isActive?: boolean;
  disabled?: boolean;
  hasPopover?: boolean;
  compact?: boolean;
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  showBoxShadow?: boolean;
};

export const ActionPressedChip = forwardRef<HTMLButtonElement, ActionPressedChipProps>(
  (
    { children, startIcon, endIcon, isActive, disabled, compact, width, minWidth, maxWidth, showBoxShadow, ...rest },
    ref
  ) => {
    const chipStyle = [
      css`
        display: flex;
        width: ${compact ? 'auto' : width || '102px'};
        min-width: ${minWidth || 'none'};
        max-width: ${maxWidth || 'none'};
        height: 24px;
        font-weight: ${isActive ? theme.font.weight.bold : theme.font.weight.regular};
        font-size: ${theme.font.size.small};
        text-align: left;
        justify-content: space-between;
        align-items: center;
        border-radius: ${theme.borderRadius.small};
        background-color: ${isActive ? theme.colors.neutral10 : theme.colors.white};
        padding: ${theme.spacing(0, 1)};
        gap: ${theme.spacing(0.5)};
        opacity: ${disabled ? 0.5 : 1};

        &:hover {
          background-color: ${theme.colors.neutral10};
        }
      `,
      showBoxShadow &&
        css`
          box-shadow: ${theme.shadows.floating};
        `,
    ];

    return (
      <NakedButton ref={ref} css={chipStyle} disabled={disabled} {...rest}>
        {startIcon && (
          <span
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            {startIcon}
          </span>
        )}
        <span
          css={[
            styles.truncate,
            css`
              display: flex;
              align-items: center;
            `,
          ]}
        >
          {children}
        </span>
        {endIcon && (
          <span
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            {endIcon}
          </span>
        )}
      </NakedButton>
    );
  }
);
