/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const SelectAllIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 Z M17.9350288,8.44974747 C17.4468735,7.9615921 16.6554173,7.9615921 16.1672619,8.44974747 L16.1672619,8.44974747 L10.3343381,14.2826713 L7.32842712,11.2781746 C6.84027176,10.7900192 6.04881554,10.7900192 5.56066017,11.2781746 C5.07250481,11.76633 5.07250481,12.5577862 5.56066017,13.0459415 L5.56066017,13.0459415 L9.44974747,16.9350288 C9.93790283,17.4231842 10.7293591,17.4231842 11.2175144,16.9350288 L11.2175144,16.9350288 L17.9350288,10.2175144 C18.4231842,9.72935906 18.4231842,8.93790283 17.9350288,8.44974747 Z'
    />
  </Icon>
);
