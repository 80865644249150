import { useStyles } from '../../use-styles';
import type { TextAlignment } from '../text/text-style-helpers';
import type { ElementType, ReactNode } from 'react';

export interface HeadingProps extends React.HTMLAttributes<HTMLElement> {
  as?: ElementType;
  children?: ReactNode;
  className?: string;
  color?: 'default' | 'white';
  level?: 1 | 2 | 3;
  textAlign?: TextAlignment;
}

const HTML_TAG_MAP = {
  1: 'h1',
  2: 'h2',
  3: 'h3',
};

export function Heading({ level = 1, color = 'default', textAlign = 'left', className, as, ...props }: HeadingProps) {
  const Component = !!as ? as : HTML_TAG_MAP[level];
  const style = useStyles('Heading', { textAlign, color, level });

  return <Component css={style} className={className} {...props} />;
}
