import type { Node } from 'slate';
import { contextFactory } from '../../hooks';
import { TagType } from './types';
import { AddTagToTemplateFun } from './message-template.models';

type TagContextState = {
  tags: TagType[];
  addTagToTemplate: AddTagToTemplateFun;
  handleAddTagToTemplate: (fn: AddTagToTemplateFun) => void;
  draggableTags: boolean;
  isNewTag: boolean;
  editorValue?: Node[];
  correspondingTags?: Record<string, TagType[]>;
  sharedTags?: TagType[];
  setSharedTags?: (tags: TagType[]) => void;
};

type TemplateSettingsContextState = {
  readOnly: boolean;
  shortenUrlsTo?: string;
};

export const [TagContext, useTemplateTags] = contextFactory<TagContextState>(
  'useTemplateTags must be used within a MessageTemplate'
);
export const [EditorValueContext, useTemplateEditorValue] = contextFactory<Node[]>(
  'useTemplateEditorValue must be used within a MessageTemplate',
  []
);
export const [TemplateSettingsContext, useTemplateSettings] = contextFactory<TemplateSettingsContextState>(
  'useTemplateSettings must be used within a MessageTemplate'
);
