import { useCallback, useEffect, useState } from 'react';
import { contextFactory } from '../../hooks';
import { WeekViewDatePickerContextType, WeekViewDatePickerProviderProps, WeekViewDatePickerValue } from './types';
import { formatDate } from './utils';

export const [WeekViewDatePickerContext, useWeekViewDatePicker] = contextFactory<WeekViewDatePickerContextType>();

const defaultValue = {
  date: '',
  endTime: '',
  startTime: '',
};

export const WeekViewDatePickerProvider = ({
  children,
  onChange,
  setTodayAsDefault = true,
  value = defaultValue,
}: WeekViewDatePickerProviderProps) => {
  // initialValue keeps a handy copy of starting value so we can reset to it when required
  const [initialValue, setInitialValue] = useState<WeekViewDatePickerValue>(value);
  const [activeValue, setActiveValue] = useState<WeekViewDatePickerValue>(value);
  const [hasTimeInput, setHasTimeInput] = useState<boolean>(false);

  const handleChange = useCallback(
    (value: WeekViewDatePickerValue, reset?: boolean) => {
      setActiveValue(reset ? value : (prev) => ({ ...prev, ...value }));
    },
    [onChange]
  );

  useEffect(() => {
    if (setTodayAsDefault && !value.date) {
      const date = formatDate(new Date());
      setActiveValue((prev) => ({ ...prev, date }));
      setInitialValue((prev) => ({ ...prev, date }));
    }
  }, [setTodayAsDefault]);

  useEffect(() => {
    onChange?.(activeValue);
  }, [activeValue]);

  return (
    <WeekViewDatePickerContext.Provider
      value={{
        activeValue,
        hasTimeInput,
        initialValue,
        onChange: handleChange,
        setHasTimeInput,
      }}
    >
      {children}
    </WeekViewDatePickerContext.Provider>
  );
};
