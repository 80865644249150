import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../../../component-theme/theme-types';

export type ColorThemeValues = {
  focusColor: WeaveThemeValues['Color'];
};

export const datePickerTheme: ColorThemeValues = {
  focusColor: theme.colors.primary50,
};

export const datePickerThemeOriginal: ColorThemeValues = {
  focusColor: themeOriginal.colors.weaveBlue,
};
