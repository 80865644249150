import { css } from '@emotion/react';
import type {
  MonthNameThemeValues,
  SingleMonthCalendarControlsThemeValues,
  SingleMonthControlsHeaderThemeValues,
} from './theme';
import {
  monthNameTheme,
  monthNameThemeOriginal,
  singleMonthCalendarControlsTheme,
  singleMonthCalendarControlsThemeOriginal,
  singleMonthControlsHeaderTheme,
  singleMonthControlsHeaderThemeOriginal,
} from './theme';

export const calendarControls = ({ color }: SingleMonthCalendarControlsThemeValues) => css`
  display: flex;

  button:focus,
  button:hover {
    outline: none;

    svg {
      color: ${color};
      transition: color ease-in 300ms;
    }
  }
`;

export const controlsHeader = ({ spacing }: SingleMonthControlsHeaderThemeValues) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${spacing(0, 0.5, 2, 2)};
`;

export const monthName = ({ color, fontSize, fontWeight }: MonthNameThemeValues) => css`
  color: ${color};
  font-size: ${fontSize(20)};
  font-weight: ${fontWeight};
  margin: 0;
`;

export const SingleMonthCalendarControlStyles = {
  SingleMonthCalendarControls: {
    calendarControls: calendarControls(singleMonthCalendarControlsTheme),
    controlsHeader: controlsHeader(singleMonthControlsHeaderTheme),
    monthName: monthName(monthNameTheme),
  },
};

export const SingleMonthCalendarControlStylesOriginal: typeof SingleMonthCalendarControlStyles = {
  SingleMonthCalendarControls: {
    calendarControls: calendarControls(singleMonthCalendarControlsThemeOriginal),
    controlsHeader: controlsHeader(singleMonthControlsHeaderThemeOriginal),
    monthName: monthName(monthNameThemeOriginal),
  },
};
