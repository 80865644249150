/**
 * Replacement of polished lib
 * Taken from https://bit.cloud/styled-components/polished/color/tint/~code
 */
import { curry } from './curry';
import { mix } from './mix';

/**
 * Tints a color by mixing it with white. `tint` can produce
 * hue shifts, where as `lighten` manipulates the luminance channel and therefore
 * doesn't produce hue shifts.
 */

const tint = (percentage: number | string, color: string): string => {
  if (color === 'transparent') return color;
  return mix(parseFloat(percentage as string), 'rgb(255, 255, 255)', color);
};

// prettier-ignore
const curriedTint = curry/* ::<number | string, string, string> */(tint)
export { curriedTint as tint };
