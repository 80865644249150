import { conformToMask } from '../../../helpers/text-mask';
import { digitsOnly } from '../../../helpers';
import { isFunction } from 'lodash-es';
import { conformTo12HrFormat, conformToTimeString, isTimeStringFormat } from '../fields/time-field/utils';
import type { FieldKey, FieldTypeValue, FormFieldState, ValueTransformable } from './types';

import type { TimeInterval } from '../fields/time-field/types';
import { Mask, MaskConfig, PipeFn } from '../atoms/mask-types';
import { currencyInputHelpers, floatCharsOnly } from '../atoms/number-input/utils';
import { getNumberMask } from '../atoms/number-input/number-input.component';
import { getPostalCodeMask } from '../atoms/postal-code-input.component';
import { autoCorrectedDatePipe, dateMask } from '../atoms/date-input.component';

type ValueSetter<State, NextValue> = (state: State, next: NextValue, isInitializing?: boolean) => State;

type TransformableFieldState<T extends FieldKey> = FormFieldState<T> & ValueTransformable<T>;

const maybeTransform = <T extends FieldKey>(state: TransformableFieldState<T>, value: FieldTypeValue<T>) => {
  if (isFunction(state.transformValue)) {
    const transformed = state.transformValue?.(value);
    // no type changes allowed (major breakage!)
    if (typeof transformed === typeof value) {
      return transformed;
    }
  }
  return value;
};

const basicSetter = <T extends FieldKey>(state: TransformableFieldState<T>, nextValue: FieldTypeValue<T>) => ({
  ...state,
  value: maybeTransform(state, nextValue),
});

const multiselectArray: ValueSetter<any & { value: string[] }, string | string[]> = (state, nextValue) => {
  if (Array.isArray(nextValue)) {
    // deselect all returns an empty array
    if (!nextValue.length) return { ...state, value: [] };

    return {
      ...state,
      value: nextValue.reduce((arr, item) => {
        if (arr.includes(item)) return arr.filter((v) => v !== item);
        return [...arr, item];
      }, state.value),
    };
  } else {
    // string toggle handling
    return {
      ...state,
      value: state.value.includes(nextValue)
        ? state.value.filter((item) => item !== nextValue)
        : [...state.value, nextValue],
    };
  }
};

// masked field utils + setters
export type ConformToPipeProps = {
  maskConfig?: MaskConfig;
  pipe: PipeFn;
  value: string;
};

const conformToPipedMask = ({ maskConfig = {}, pipe, value }: ConformToPipeProps) => {
  const pipeResult = pipe(value, maskConfig);
  if (pipeResult === false) return '';
  if (typeof pipeResult === 'string') return pipeResult;
  return value;
};

const convertMaskToPlaceholder = (mask: Mask, placeholderChar = '_') => {
  //@ts-ignore
  const usableMask = isFunction(mask) ? mask() : mask;
  return usableMask
    .map((char) => {
      return char instanceof RegExp ? placeholderChar : char;
    })
    .join('');
};

type GetConformedValueProps = MaskConfig & {
  mask: Mask;
  value: string;
};

const getConformedValue = ({ mask, value, pipe, ...rest }: GetConformedValueProps) => {
  const placeholder = convertMaskToPlaceholder(mask, rest?.placeholderChar);
  let conformed = conformToMask(value, mask, rest).conformedValue ?? '';

  if (pipe && isFunction(pipe)) {
    conformed = conformToPipedMask({ pipe, maskConfig: rest, value: conformed });
  }
  return conformed === placeholder ? '' : conformed;
};

const numeric: ValueSetter<FormFieldState<'number'>, string> = (state, nextValue) => ({
  ...state,
  value: floatCharsOnly(
    getConformedValue({
      mask: getNumberMask({
        allowDecimal: state.allowDecimal,
        allowNegative: state.allowNegative,
        includeThousandsSeparator: state.includeThousandsSeparator,
        prefix: state.prefix,
        suffix: state.suffix,
      }),
      value: nextValue,
    })
  ),
});

const money: ValueSetter<FormFieldState<'money'>, string> = (state, nextValue, isInitializing) => {
  return {
    ...state,
    value: isInitializing
      ? currencyInputHelpers.transformDefaultValue(nextValue)
      : currencyInputHelpers.transformCurrencyValue(nextValue),
  };
};

const time: ValueSetter<FormFieldState<'time'>, string | TimeInterval> = (state, nextValue, isInitializing) => {
  if (typeof nextValue === 'string') {
    return {
      ...state,
      displayValue:
        isInitializing || isTimeStringFormat(nextValue) ? conformTo12HrFormat(nextValue) : nextValue.toLowerCase(),
      value: conformToTimeString(nextValue),
    };
  } else {
    return { ...state, ...nextValue };
  }
};

type TimeRangeTypingValue = {
  value: string;
  position: number;
};

type TimeRangeIntervalValue = {
  value: TimeInterval;
  position: number;
};

export type TimeRangeNextValue = string[] | TimeRangeTypingValue | TimeRangeIntervalValue;

const timeRange: ValueSetter<FormFieldState<'timeRange'>, TimeRangeNextValue> = (state, nextValue) => {
  if (Array.isArray(nextValue)) {
    // nextValue is only an array during initialization
    if (state.min && state.max) {
      // if min and max are passed, convert them to usable values
      state.max = conformToTimeString(state.max);
      state.min = conformToTimeString(state.min);
    }
    return {
      ...state,
      displayValue: (nextValue as string[]).map(conformTo12HrFormat),
      value: nextValue.map(conformToTimeString),
    };
  } else if (typeof nextValue.value === 'string') {
    // user is typing a value
    state.displayValue[nextValue.position] = nextValue.value;
    state.value[nextValue.position] = conformToTimeString(nextValue.value);
    return { ...state, value: state.value, displayValue: state.displayValue };
  } else {
    // incoming selection from interval menu (TimeInterval)
    const { value: interval, position } = nextValue;
    const displayValue = [...state.displayValue];
    const value = [...state.value];
    value[position] = interval.value;
    displayValue[position] = interval.displayValue;
    return { ...state, displayValue, value: value };
  }
};

const phone: ValueSetter<FormFieldState<'phone'>, string> = (state, nextValue) => ({
  ...state,
  value: digitsOnly(nextValue),
});

const date: ValueSetter<FormFieldState<'date'>, string> = (state, nextValue) => ({
  ...state,
  value: getConformedValue({
    mask: dateMask,
    value: nextValue,
    pipe: autoCorrectedDatePipe,
  }),
});

const postalCode: ValueSetter<FormFieldState<'postalCode'>, string> = (state, nextValue) => ({
  ...state,
  value: getConformedValue({
    mask: getPostalCodeMask(state.locale),
    value: nextValue,
  }),
});

const valueSetters = {
  checklist: multiselectArray,
  date,
  list: multiselectArray,
  money,
  multiselect: multiselectArray,
  number: numeric,
  postalCode,
  phone,
  selectlist: multiselectArray,
  time,
  timeRange,
};

export function setValue<T extends FieldKey>(state: FormFieldState<T>, nextValue: any, isInitializing?: boolean) {
  const setter = valueSetters[state.type as string] ?? basicSetter;
  return setter(state, nextValue, isInitializing);
}
