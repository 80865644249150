import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { IconButton, Text, styles as dsStyles } from '@frontend/design-system';
import { ScrollControl } from '../atoms';
import { useChartContext } from '../chart.provider';
import { defaultFormatter } from '../helpers';
import { useIsVisible } from '../hooks';
import { FormatValue } from '../types';
import { BarChart } from './bar-chart';
import { Appearance, BarChartData, BarClick, CategoryBarChartData, GroupClick } from './types';

const Y_AXIS_WIDTH = 180;

type Formatters = {
  [categoryId: string]: FormatValue;
};

type Values = {
  [categoryId: string]: number;
};

type Markers = {
  [categoryId: string]: BarChartData['markers'];
};

type CategoryBarChartProps = {
  appearance?: Pick<
    Appearance,
    'barRadius' | 'barsGap' | 'barSize' | 'customTooltipTitle' | 'customYAxisTick' | 'groupsGap' | 'margin'
  > & {
    customCategoryNameRenderer?: (category: string) => string | React.ReactNode;
    formatters?: Formatters;
    maxValues?: Values;
    xAxisTickFormatters?: Formatters;
  };
  data: CategoryBarChartData;
  markers?: Markers;
  onClick?: (data: BarClick) => void;
  onGroupClick?: ((data: GroupClick) => void) | null;
  trackingIdBase?: string;
};

const transformData = (data: CategoryBarChartData) => {
  const transformedData: Record<string, Record<string, number>> = {};

  Object.entries(data).forEach(([location, categories]) => {
    Object.entries(categories).forEach(([category, value]) => {
      if (!transformedData[category]) {
        transformedData[category] = {};
      }
      transformedData[category][location] = value ?? 0;
    });
  });

  return transformedData;
};

export const CategoryBarChart = memo(
  ({ appearance, data, markers, onClick, onGroupClick, trackingIdBase }: CategoryBarChartProps) => {
    const { t } = useTranslation('analytics');
    const { labels = {} } = useChartContext();
    const scrollRef = useRef<HTMLDivElement>(null);
    const [showLeftScrollButton, setShowLeftScrollButton] = useState<boolean>(false);
    const [showRightScrollButton, setShowRightScrollButton] = useState<boolean>(false);
    const margin = { top: 0, right: 0, bottom: 0, left: 0, ...appearance?.margin };
    const categoryTitleRef = useRef<HTMLDivElement | HTMLParagraphElement>(null);
    const hasGroupClick = typeof onGroupClick === 'function';
    const isTitleVisible = useIsVisible(categoryTitleRef);

    const maxValue = useMemo(() => {
      if (appearance?.maxValues) {
        return;
      }

      const values = Object.values(data).reduce((acc, item) => {
        const itemValues = Object.values(item).map((value) => (typeof value === 'undefined' ? 0 : value));
        return [...acc, ...itemValues];
      }, [] as number[]);

      // Ensure maxValue is at least 4 to avoid decimal points in the x-axis
      return Math.ceil(Math.max(...values) / 4) * 4;
    }, [appearance, data]);

    const formatCategorizedBarGroups = useMemo((): Record<string, BarChartData> => {
      const formattedData: Record<string, BarChartData> = {};

      Object.entries(transformData(data)).forEach(([groupName, locationsValues]) => {
        formattedData[groupName] = {
          groups: Object.entries(locationsValues).map(([location, value]) => ({
            name: location,
            values: {
              [groupName]: value || 0,
            },
          })),
          markers: markers?.[groupName],
        };
      });

      return formattedData;
    }, [data, markers]);

    const groupsLength = Object.values(formatCategorizedBarGroups)?.[0]?.groups?.length;

    const handleControlledScroll = useCallback((direction: 'left' | 'right' | 'reset') => {
      if (!scrollRef.current) {
        return;
      }

      const scrollWidth = scrollRef.current.scrollWidth;
      const clientWidth = scrollRef.current.clientWidth;

      if (direction === 'reset') {
        scrollRef.current.scrollTo({
          left: 0,
          behavior: 'smooth',
        });

        const scrollable = scrollWidth > clientWidth;
        setShowLeftScrollButton(false);
        setShowRightScrollButton(scrollable);
        return;
      }

      const keepInViewAdjustment = 50;
      let scrollLeft = 0;

      if (direction === 'left') {
        scrollLeft = Math.max(scrollRef.current.scrollLeft - clientWidth + keepInViewAdjustment, 0);
        setShowLeftScrollButton(scrollLeft > 0);
        setShowRightScrollButton(true);
      } else {
        scrollLeft = scrollRef.current.scrollLeft + clientWidth - keepInViewAdjustment;
        setShowLeftScrollButton(true);
        setShowRightScrollButton(scrollLeft < scrollWidth - clientWidth);
      }

      scrollRef.current.scrollTo({
        left: scrollLeft,
        behavior: 'smooth',
      });
    }, []);

    const groupNamesMarginTop = useMemo(() => {
      return (categoryTitleRef.current?.getBoundingClientRect().height || 0) + 4;
    }, [isTitleVisible]);

    useEffect(() => {
      // Debounce the scroll event to avoid performance issues
      let timeout: ReturnType<typeof setTimeout>;

      const handleScroll = () => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          if (!scrollRef.current) {
            return;
          }

          const scrollWidth = scrollRef.current.scrollWidth;
          const clientWidth = scrollRef.current.clientWidth;
          const scrollLeft = scrollRef.current.scrollLeft;

          setShowLeftScrollButton(scrollLeft > 0);
          setShowRightScrollButton(scrollLeft < scrollWidth - clientWidth);
        }, 100);
      };

      if (scrollRef.current) {
        scrollRef.current.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (scrollRef.current) {
          scrollRef.current.removeEventListener('scroll', handleScroll);
        }
      };
    }, []);

    useEffect(() => {
      if (!scrollRef.current) {
        return;
      }
      handleControlledScroll('reset');
    }, [formatCategorizedBarGroups]);

    return (
      <div className={hasGroupClick ? 'group-click' : ''} css={styles.mainWrapper}>
        {/* This is used to only to render the y axis and help enabling horizontal scroll for all charts */}
        <div
          style={{
            marginTop: groupNamesMarginTop,
            transition: 'margin-top 0.2s',
            width: `${margin.left || Y_AXIS_WIDTH}px`,
          }}
        >
          {Object.values(formatCategorizedBarGroups)[0] && (
            <BarChart
              appearance={{
                groupsGap: 24,
                hideMarkers: true,
                layout: 'vertical',
                showYAxis: true,
                margin: {
                  top: groupsLength === 1 ? 8 : margin.top || 0,
                  right: 0,
                  bottom: margin.bottom || 0,
                  left: margin.left || Y_AXIS_WIDTH - 60,
                },
                showXAxis: true,
                ...appearance,
              }}
              data={Object.values(formatCategorizedBarGroups)[0]}
            />
          )}
        </div>

        <div css={styles.scrollableViewWrapper} style={{ width: `calc(100% - ${margin.left || Y_AXIS_WIDTH}px)` }}>
          <div css={styles.scrollableView} ref={scrollRef}>
            {Object.entries(formatCategorizedBarGroups).map(([categoryId, chartData], index) => {
              return (
                <div
                  css={styles.chartWrapper}
                  key={JSON.stringify(chartData)}
                  style={{ flexBasis: `${100 / Math.min(Object.keys(formatCategorizedBarGroups).length, 4)}%` }}
                >
                  {typeof appearance?.customCategoryNameRenderer === 'undefined' ? (
                    <Text
                      css={dsStyles.truncate}
                      ref={categoryTitleRef}
                      size='medium'
                      style={{ marginBottom: theme.spacing(1) }}
                      textAlign='center'
                    >
                      {labels?.[categoryId] ?? categoryId}
                    </Text>
                  ) : (
                    <div
                      ref={categoryTitleRef}
                      style={{ display: 'flex', justifyContent: 'center', marginBottom: theme.spacing(1) }}
                    >
                      {appearance.customCategoryNameRenderer(categoryId)}
                    </div>
                  )}
                  <div className='chart'>
                    <BarChart
                      appearance={{
                        customXAxisTickFormat: appearance?.xAxisTickFormatters?.[categoryId] || defaultFormatter,
                        groupsGap: 24,
                        layout: 'vertical',
                        margin: {
                          top: groupsLength === 1 ? 8 : margin.top || 0,
                          right:
                            index === Object.keys(formatCategorizedBarGroups).length - 1
                              ? 16 + (margin.right || 0)
                              : 16,
                          bottom: margin.bottom || 0,
                          left: index > 0 ? 16 : 1,
                        },
                        maxValue: appearance?.maxValues?.[categoryId] || maxValue,
                        showXAxis: true,
                        ...appearance,
                      }}
                      customLegendsIds={Object.keys(formatCategorizedBarGroups)}
                      data={chartData}
                      formatValue={appearance?.formatters?.[categoryId] || defaultFormatter}
                      key={JSON.stringify(chartData)}
                      onClick={onClick}
                    />
                  </div>
                </div>
              );
            })}
          </div>

          <ScrollControl
            css={styles.scrollButton}
            direction='left'
            isVisible={showLeftScrollButton}
            onClick={() => handleControlledScroll('left')}
          />

          <ScrollControl
            css={styles.scrollButton}
            direction='right'
            isVisible={showRightScrollButton}
            onClick={() => handleControlledScroll('right')}
          />
        </div>

        {hasGroupClick && (
          <div css={styles.actionsWrapper}>
            {Object.entries(data).map(([groupName, groupData], index) => {
              return (
                <IconButton
                  key={groupName}
                  label={t('More Details')}
                  onClick={() => {
                    onGroupClick?.({ groupName, groupData });
                  }}
                  showLabelOnHover
                  size='small'
                  trackingId={`${trackingIdBase}-group-button-${index + 1}`}
                >
                  <Icon color='light' name='caret-right-small' />
                </IconButton>
              );
            })}
          </div>
        )}
      </div>
    );
  }
);

CategoryBarChart.displayName = 'CategoryBarChart';

const styles = {
  mainWrapper: css`
    align-items: center;
    display: flex;
    flex-wrap: nowrap;

    &.group-click {
      padding-right: ${theme.spacing(5)};
      position: relative;
    }
  `,

  scrollableViewWrapper: css`
    position: relative;
  `,

  scrollableView: css`
    display: flex;
    flex-wrap: nowrap;
    flex: 1;
    gap: ${theme.spacing(3)};
    overflow: auto;
  `,

  chartWrapper: css`
    min-width: 220px;

    > .chart {
      padding-bottom: ${theme.spacing(1)};
      padding-right: ${theme.spacing(1)};
    }

    &:nth-of-type(odd) {
      > .chart {
        background-color: ${theme.colors.neutral5};
      }
    }
  `,

  scrollButton: css`
    bottom: 0;
    margin: auto;
    position: absolute;
    top: 0;

    &.left-scroll {
      left: ${theme.spacing(-0.5)};
    }

    &.right-scroll {
      right: ${theme.spacing(-1)};
    }
  `,

  actionsWrapper: css`
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: ${theme.spacing(5, 0, 6.25)};
    position: absolute;
    right: 0;
    top: 0;

    > button {
      height: ${theme.spacing(3.25)};
    }
  `,
};
