import { useState } from 'react';
import { css } from '@emotion/react';
import { InvoiceModel } from '@frontend/api-invoices';
import { formatCentsToCurrency } from '@frontend/currency';
import { formatDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { IconButton, ListRow, Text } from '@frontend/design-system';
import { usePersonActiveInvoiceList } from './hooks';

const styles = {
  row: css`
    display: flex;
    flex-direction: row;
    gap: ${theme.spacing(1)};
    align-items: center;
    width 100%;
  `,
  spaceBetween: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `,
  subtitle: css`
    font-size: 20px;
    margin-bottom: ${theme.spacing(2)};
  `,
  padding: css`
    padding: ${theme.spacing(1, 2)};
  `,
  createInvoicePadding: css`
    padding: ${theme.spacing(2)};
  `,
};

interface PersonUnpaidInvoiceProps {
  personId: string;
  createInvoiceOnClick: () => void;
  onInvoiceClick: (invoice: InvoiceModel) => void;
  onPreviewClick: (invoice: InvoiceModel) => void;
}

const isoDateToAbbrDisplayString = (date: string) => formatDate(date, 'MMMM DD, YYYY', true);

export const PersonUnpaidInvoiceList = ({
  personId,
  createInvoiceOnClick,
  onInvoiceClick,
  onPreviewClick,
}: PersonUnpaidInvoiceProps) => {
  const { t } = useTranslation('payments');
  const { invoices } = usePersonActiveInvoiceList(personId);
  const [selectedInvoice, setSelectedInvoice] = useState<string>();

  const handleRowClick = (invoice: InvoiceModel) => {
    onInvoiceClick(invoice);
    setSelectedInvoice(invoice.id);
  };

  return (
    <>
      <ListRow css={[styles.row, styles.createInvoicePadding]} onClick={createInvoiceOnClick}>
        <Icon name='plus-small' color='primary' />
        <Text weight='bold' size='medium' color='primary'>
          {t('Create New Invoice')}
        </Text>
      </ListRow>
      {invoices?.map((invoice) => (
        <ListRow
          isSelected={selectedInvoice === invoice.id}
          onClick={() => handleRowClick(invoice)}
          css={[styles.spaceBetween, styles.padding]}
          key={invoice.id}
        >
          <ListRow.Content>
            <div css={styles.row}>
              <Icon name='invoice' color='light' />
              <Text
                weight='bold'
                css={css`
                  width: 100px;
                `}
              >
                {formatCentsToCurrency(invoice.billedAmount)}
              </Text>
              <Text weight='light' color='light'>
                {isoDateToAbbrDisplayString(invoice.billedAt)}
              </Text>
            </div>
          </ListRow.Content>
          <ListRow.Trail
            css={css`
              justify-self: end;
            `}
          >
            <IconButton
              label='Preview'
              onClick={(e) => {
                e.stopPropagation();
                onPreviewClick(invoice);
              }}
              css={css`
                margin: 0;
              `}
            >
              <Icon name='preview' size={16} />
            </IconButton>
          </ListRow.Trail>
        </ListRow>
      ))}
    </>
  );
};
