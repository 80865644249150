import { css } from '@emotion/react';
import type { HoverLabelStyleValues } from './hover-label-theme';
import { hoverLabelTheme, originalHoverLabelTheme } from './hover-label-theme';
import { ThemeProps } from './types';

const styles = ({ background, color, fontWeight, maxWidth, shadow, spacing }: HoverLabelStyleValues) => {
  return css`
    background: ${background};
    box-shadow: ${shadow};
    font-weight: ${fontWeight};
    color: ${color};
    max-width: ${maxWidth};
    padding: ${spacing(1)};
  `;
};

export const HoverLabelStyles = {
  HoverLabel: (props: ThemeProps) => styles(hoverLabelTheme(props.theme)),
};

export const HoverLabelStylesOriginal: typeof HoverLabelStyles = {
  HoverLabel: (props: ThemeProps) => styles(originalHoverLabelTheme(props.theme)),
};
