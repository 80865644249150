import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import { useTranslation } from '@frontend/i18n';

dayjs.extend(calendar);

export const useTimestampFormatter = () => {
  const { t } = useTranslation('timestamp-formatter');

  return (timestamp: Parameters<typeof dayjs>[0]) => {
    const isSameYear = dayjs(timestamp).isSame(new Date(), 'year');
    const dateFormat = isSameYear ? 'MMM D, h:mm A' : 'MMM D, YYYY, h:mm A';
    return dayjs(timestamp).calendar(null, {
      nextDay: `[${t('Tomorrow')}] h:mm A`,
      sameDay: `[${t('Today')}] h:mm A`,
      lastDay: `[${t('Yesterday')}] h:mm A`,
      lastWeek: dateFormat,
      sameElse: dateFormat,
    });
  };
};
