import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';

export type ColorThemeValues = {
  focusColor: string;
};

export const infoTheme: ColorThemeValues = {
  focusColor: theme.colors.primary50,
};

export const infoThemeOriginal: ColorThemeValues = {
  focusColor: themeOriginal.colors.weaveBlue,
};
