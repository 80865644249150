import { StoreApi, UseBoundStore } from 'zustand';
import { shallow } from 'zustand/shallow';
import { pick } from './utils/pick';

/**
 * Helper function that takes a Zustand store and generates a shallow copy of Zustand store that abstracts state
 * selection to this structure const { state1, state2, setState1, ...} = useStore('state1', 'state2', 'setState2')
 *
 * You should keep in mind that this function only creates a shallow store. Subscribing to the store
 * should be done through the original store
 * @param store - The store that you want to create a hook for.
 * @returns A shallow store.
 */

export const createShallowStore =
  <T>(store: UseBoundStore<StoreApi<T>>) =>
  (...properties: (keyof T)[]) => {
    return store((state) => pick(state, ...properties), shallow);
  };
