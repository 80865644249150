import { forwardRef, useEffect, useState } from 'react';
import { KeyNames } from '../../../constants';
import { CaretDownIconSmall, XIconSmall } from '../../../icon';
import { omit } from 'lodash-es';
import { NakedButton } from '../../../naked';
import { useStyles } from '../../../use-styles';
import { useTooltip } from '../../tooltip';
import { Text } from '../../text';
import { MessageTagProps, MouseEventType, SelectEventType } from './types';
import { useThemeValues } from '../../../hooks';
import { PopoverDialog, usePopoverDialog } from '../../popover';
import { TagType } from '../types';

export const MessageTemplateTag = forwardRef<HTMLSpanElement, MessageTagProps>(
  (
    {
      tag,
      placed = false,
      focused,
      error,
      selected,
      onRemove,
      onTagSelect,
      addTagToTemplate,
      draggable,
      readOnly = false,
      editorValue,
      correspondingTags,
      sharedTags,
      setSharedTags,
      ...props
    },
    ref
  ) => {
    const theme = useThemeValues();
    const { Tooltip, tooltipProps, triggerProps } = useTooltip({ placement: 'top' });
    const showLabel = placed && error;
    const [selectedTag, setSelectedTag] = useState<TagType>(tag);

    useEffect(() => {
      const newSelectedTag = sharedTags?.find((t) => t.key === selectedTag.key);
      if (newSelectedTag) {
        setSelectedTag(newSelectedTag);
      }
    }, [sharedTags]);

    const id = selectedTag ? `${placed ? 'placed' : ''}` + selectedTag.key : '';
    const [offsetX, setOffsetX] = useState(0);
    const offsetY = 24;
    const tagStyle = useStyles('MessageTemplate', 'messageTemplateTag', {
      error,
      placed,
      focused,
      selected,
      readOnly,
    });
    const xIconStyle = useStyles('MessageTemplate', 'xIconStyle');
    useEffect(() => {
      // Popover dropdown needs to be dynamically offset so it's symmetrically positioned beneath the tag
      if (!placed && document.getElementById(id)) {
        const tagBounds = document.getElementById(id)?.getBoundingClientRect();

        if (tagBounds) {
          const tagWidth = tagBounds.width;
          // calculate the text width without the margin/padding
          const dropdownTextWidth = (tagWidth - 40) * 1.65;
          const dropdownWidth = dropdownTextWidth + 32;
          const difference = dropdownWidth - tagWidth / 2;
          setOffsetX(-difference);
        }
      }
    }, [tag]);

    const popover = usePopoverDialog({
      placement: 'bottom-end',
      initialOffset: {
        x: offsetX,
        y: offsetY,
      },
    });

    const removeElement = (e: MouseEventType) => {
      if (typeof onRemove === 'function') {
        onRemove(e);
      }
    };

    const selectElement = (e: SelectEventType) => {
      if (typeof addTagToTemplate === 'function') {
        addTagToTemplate(e, selectedTag);
      }

      if (typeof onTagSelect === 'function') {
        onTagSelect(e as MouseEventType, selectedTag);
      }
    };

    return (
      <>
        <Text
          id={id}
          as={NakedButton}
          ref={ref}
          size='small'
          tabIndex={placed ? -1 : 0}
          css={tagStyle}
          onClick={(e) => {
            // stop events from propagating to the Slate editor
            // Slate has an onClick handler that handles the cursor
            // position. Can get goofed up if the node is removed.
            e.stopPropagation();
            if (readOnly) return;
            if (placed) {
              removeElement(e);
            } else {
              selectElement(e);
            }
          }}
          onKeyDown={(e) => {
            if (!placed && e.key === KeyNames.Enter) {
              if (typeof addTagToTemplate === 'function') {
                addTagToTemplate(e, selectedTag);
              }
            }
          }}
          onDragStart={(e) => {
            if (typeof selectedTag.key === 'string') {
              const data = JSON.stringify(selectedTag);
              e.dataTransfer.setData('text/plain', data);
            } else if (Array.isArray(selectedTag.key)) {
              const data = JSON.stringify({
                label: selectedTag.label,
                value: selectedTag.value,
                key: selectedTag.key[0],
              });
              // Assume the first in array is preferred
              e.dataTransfer.setData('text/plain', data);
            }
          }}
          onDragEnd={(e) => {
            if (draggable && placed) {
              removeElement(e);
            }
          }}
          draggable={!readOnly && (draggable || !placed)}
          {...(showLabel ? omit(triggerProps, ['ref']) : {})}
          {...props}
        >
          {/* 
            only want to add the asterisk (*): 
            - when selectedTag.optional is equal to false and not if that value is falsy
            - when the template tag is not placed
          */}
          {selectedTag.label} {selectedTag?.optional === false && !placed && '*'}
          {!placed && tag?.options && (
            <NakedButton
              {...popover.getTriggerProps()}
              css={{
                marginLeft: theme.spacing(1),
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <CaretDownIconSmall
                css={{
                  transition: 'transform 200ms ease-out',
                  transform: popover?.isOpen ? 'rotate(180deg)' : 'inherit',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  popover.open();
                }}
              />
            </NakedButton>
          )}
          {tag?.options ? (
            <PopoverDialog css={{ padding: theme.spacing(2, 0) }} {...popover.getDialogProps()}>
              {tag.options.map((t, idx) => (
                <Text
                  as='li'
                  key={idx}
                  tabIndex={0}
                  css={{
                    listStyle: 'none',
                    padding: theme.spacing(1, 2),
                    cursor: 'pointer',
                  }}
                  onClick={(e) => {
                    setSelectedTag(t);
                    addTagToTemplate?.(e, t);
                    onTagSelect?.(e, t);
                    popover.close();
                  }}
                  onKeyDown={(e) => {
                    if (e.key === KeyNames.Enter) {
                      setSelectedTag(t);
                      addTagToTemplate?.(e, t);
                      // @ts-ignore event type will not match
                      onTagSelect?.(e, t);
                      popover.close();
                    }
                  }}
                >
                  {t.label}
                </Text>
              ))}
            </PopoverDialog>
          ) : null}
          {placed && !readOnly && <XIconSmall width={12} height={12} css={xIconStyle} />}
        </Text>
        {showLabel && (
          <Tooltip css={{ padding: theme.spacing(1) }} {...tooltipProps}>
            Invalid tag
          </Tooltip>
        )}
      </>
    );
  }
);
