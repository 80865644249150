import { css, Global } from '@emotion/react';
import Inter from './font-assets/Inter-Regular.ttf';
import InterSemibold from './font-assets/Inter-SemiBold.ttf';
import InterBold from './font-assets/Inter-Bold.ttf';
import { weaveTheme } from './weaveTheme';

const styles = css`
  @font-face {
    font-family: 'Inter';
    src: url(${Inter}) format('truetype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Inter';
    src: url(${InterSemibold}) format('truetype');
    font-weight: 600;
  }

  @font-face {
    font-family: 'Inter';
    src: url(${InterBold}) format('truetype');
    font-weight: 800;
  }

  html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
  }

  html,
  body {
    margin: 0;
    padding: 0;
  }

  * {
    box-sizing: inherit;
    font-family: ${weaveTheme.font.family};
  }

  *::before,
  *::after {
    box-sizing: inherit;
  }
`;

export const UniversalBaseStyle = () => <Global styles={styles} />;
