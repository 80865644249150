import { css } from '@emotion/react';
import { AvatarThemeValues, avatarTheme, avatarThemeOriginal } from './avatar-group-theme';
import { SizeProps } from '../avatar/avatar.styles';
import { TextSize } from '../text';

export const groupStyles = css`
  display: flex;
  align-items: center;
`;

type AvatarStylesProps = {
  size: SizeProps;
  borderColor?: string;
};

export const SIZE_MAP: Record<
  SizeProps,
  { overlapSpacing: number; borderWidth: number; textSize: TextSize; textSpacing: number }
> = {
  xxs: { overlapSpacing: -0.5, borderWidth: 2, textSize: 'small', textSpacing: 0.5 },
  xs: { overlapSpacing: -1, borderWidth: 2, textSize: 'small', textSpacing: 0.75 },
  small: { overlapSpacing: -1.25, borderWidth: 3, textSize: 'medium', textSpacing: 1 },
  medium: { overlapSpacing: -1.5, borderWidth: 3, textSize: 'medium', textSpacing: 1.25 },
  large: { overlapSpacing: -2, borderWidth: 3, textSize: 'large', textSpacing: 1.5 },
  xl: { overlapSpacing: -2.5, borderWidth: 4, textSize: 'large', textSpacing: 1.5 },
  xxl: { overlapSpacing: -2.5, borderWidth: 4, textSize: 'large', textSpacing: 1.5 },
};

export const avatarStyles = (
  { size, borderColor }: AvatarStylesProps,
  { spacing, borderRadius, defaultBorderColor }: AvatarThemeValues
) => {
  const { overlapSpacing, borderWidth } = SIZE_MAP[size];

  return css`
    outline: ${borderWidth}px solid ${borderColor || defaultBorderColor};
    border-radius: ${borderRadius};
    margin-left: ${spacing(overlapSpacing)};
  `;
};

export const firstAvatarStyles = (
  { size, borderColor }: AvatarStylesProps,
  { borderRadius, defaultBorderColor }: AvatarThemeValues
) => {
  const { borderWidth } = SIZE_MAP[size];

  return css`
    outline: ${borderWidth}px solid ${borderColor || defaultBorderColor};
    border-radius: ${borderRadius};
  `;
};

export const textStyles = ({ size }: AvatarStylesProps, { spacing }: AvatarThemeValues) => {
  const { textSpacing } = SIZE_MAP[size];

  return css`
    margin: ${spacing(0, 0, 0, textSpacing)};
    user-select: none;
  `;
};

export const tooltipContentStyles = ({ spacing }: AvatarThemeValues) => css`
  display: flex;
  flex-direction: column;
  gap: ${spacing(1)};
  span {
    display: flex;
    align-items: center;
    gap: ${spacing(1)};
    p {
      margin: 0;
    }
  }
`;

export const AvatarGroupStyles = {
  AvatarGroup: {
    group: groupStyles,
    avatar: (props: AvatarStylesProps) => avatarStyles(props, avatarTheme),
    firstAvatar: (props: AvatarStylesProps) => firstAvatarStyles(props, avatarTheme),
    text: (props: AvatarStylesProps) => textStyles(props, avatarTheme),
    tooltipContent: tooltipContentStyles(avatarTheme),
  },
};

export const AvatarGroupStylesOriginal: typeof AvatarGroupStyles = {
  AvatarGroup: {
    group: groupStyles,
    avatar: (props: AvatarStylesProps) => avatarStyles(props, avatarThemeOriginal),
    firstAvatar: (props: AvatarStylesProps) => firstAvatarStyles(props, avatarThemeOriginal),
    text: (props: AvatarStylesProps) => textStyles(props, avatarThemeOriginal),
    tooltipContent: tooltipContentStyles(avatarThemeOriginal),
  },
};
