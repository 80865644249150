/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const WarningBadgeIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 Z M12,16.75 C11.3096441,16.75 10.75,17.3096441 10.75,18 C10.75,18.6903559 11.3096441,19.25 12,19.25 C12.6903559,19.25 13.25,18.6903559 13.25,18 C13.25,17.3096441 12.6903559,16.75 12,16.75 Z M12,4.75 C11.3096441,4.75 10.75,5.30964406 10.75,6 L10.75,6 L10.75,13.5 C10.75,14.1903559 11.3096441,14.75 12,14.75 C12.6903559,14.75 13.25,14.1903559 13.25,13.5 L13.25,13.5 L13.25,6 C13.25,5.30964406 12.6903559,4.75 12,4.75 Z'
    />
  </Icon>
);
