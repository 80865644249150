import { Editor, Text as SlateText, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { TAG_ELEMENT_TYPE } from '../message-template.models';
import { hasTag, parseLine } from '../parse-template/parse-template';
import { TagType } from '../types';

export const withTags = (tags: TagType[], editor: ReactEditor, isNewTag: boolean) => {
  const { isVoid, isInline, normalizeNode } = editor;

  editor.isVoid = (element) => {
    return isTagElement(element) ? true : isVoid(element);
  };

  editor.isInline = (element) => {
    return isTagElement(element) ? true : isInline(element);
  };

  // parses typed or pasted text for tags
  editor.normalizeNode = (entry) => {
    const [node] = entry;
    if (SlateText.isText(node)) {
      transformTextToTag(entry, editor, tags, isNewTag);
    } else {
      return normalizeNode(entry);
    }
  };

  return editor;
};

export const isTagElement = (element) => {
  return element.type === TAG_ELEMENT_TYPE && typeof element.tag === 'object';
};

function transformTextToTag(match, editor: Editor, tags: TagType[], isNewTag: boolean) {
  const [node, path] = match;
  if (hasTag(node.text, isNewTag)) {
    Transforms.removeNodes(editor, { at: path });

    const newNodes = parseLine(tags, node.text, isNewTag);
    Transforms.insertNodes(editor, newNodes, { at: path });

    const newLocation = [path[0], path[1] + newNodes.length - 1];
    const lastNode = newNodes[newNodes.length - 1];

    if (!SlateText.isText(lastNode)) {
      throw Error('Last node was not a text node');
    }

    const offset = (newNodes[newNodes.length - 1] as SlateText).text.length; // last node will always be a text node
    Transforms.select(editor, {
      path: newLocation,
      offset: offset,
    });
  }
}
