import { useEffect, useState } from 'react';
import { extractColors } from 'extract-colors';
import { contextFactory } from '../../hooks';

export interface ColorPickerProvider {
  color?: string;
  syncColor?: string;
  imageSrc?: string;
  palette?: string[];
  children?: React.ReactNode;
  hidePalette?: boolean;
  hideCustom?: boolean;
  initialColor?: string;
  onChange?: (color: string) => void;
  onClick?: () => void;
  shouldApply?: boolean; // Only applies once the user clicks the apply button
}

interface ColorPickerContext {
  color?: string;
  syncColor?: string;
  palette?: string[];
  imageSrc?: string;
  extractedColors: string[];
  hidePalette?: boolean;
  hideCustom?: boolean;
  activeColor: string;
  isLoading: boolean;
  setActiveColor: (activeColor: string) => void;
  onChange?: (color: string) => void;
  onClick?: () => void;
  shouldApply?: boolean; // Only applies once the user clicks the apply button
}

export const [ColorPickerContext, useColorPicker] = contextFactory<ColorPickerContext>();

const DEFAULT_COLOR = '#FFFF';

export function ColorPickerProvider({
  children,
  initialColor,
  imageSrc,
  palette,
  hideCustom,
  hidePalette,
  color,
  syncColor,
  onChange,
  onClick,
  shouldApply,
}: ColorPickerProvider) {
  const [activeColor, setActiveColor] = useState<string>(initialColor ?? DEFAULT_COLOR);
  const [extractedColors, setExtractedColors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(!!imageSrc);

  useEffect(() => {
    async function fetchColors() {
      if (!imageSrc) return;

      try {
        const extractedColors = await extractColors(imageSrc, { crossOrigin: 'anonymous' });
        const hexColors = extractedColors.map((color) => color.hex);
        setExtractedColors(hexColors);
        setIsLoading(false);
      } catch (error) {
        console.error('Error extracting colors:', error);
        setIsLoading(false);
      }
    }

    fetchColors();
  }, [imageSrc]);

  useEffect(() => {
    if (syncColor) {
      setActiveColor(syncColor);
    }
  }, [syncColor]);

  return (
    <ColorPickerContext.Provider
      value={{
        extractedColors,
        activeColor: color ?? activeColor,
        setActiveColor,
        isLoading,
        palette,
        hidePalette,
        hideCustom,
        onChange,
        onClick,
        shouldApply,
      }}
    >
      {children}
    </ColorPickerContext.Provider>
  );
}
