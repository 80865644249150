import {
  add,
  endOfDay,
  endOfWeek,
  endOfMonth,
  endOfYear,
  endOfToday,
  format,
  startOfDay,
  startOfWeek,
  startOfMonth,
  startOfYear,
  startOfToday,
  sub,
} from 'date-fns';
import dayjs, { ManipulateType } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { getFilterDisplayDates } from '@frontend/api-invoices';
import { PaymentPlanDateObject, PaymentPlanFrequency } from '@frontend/api-payment-plans';
import { RefundDateObject, RefundFilterType } from '@frontend/api-refunds';
import { formatDate } from '@frontend/date';
import { i18next } from '@frontend/i18n';
import { QuickFillConfigOption } from '../types';
// import { ExportToCsv } from 'export-to-csv';
dayjs.extend(utc);

export const DEFAULT_DISPLAY_DATE_FORMAT = 'MM/dd/yyyy';

/**
 * Takes a date string and returns a date object
 * @param dateString Date string formatted as MM/DD/YYY
 */
export const createDateObject = (dateString: string) => {
  const parts = dateString.split('/');
  return new Date(+parts[2], +parts[0] - 1, +parts[1]);
};

export const createISODateInUTC = (dateString: string, startTime = '00:00:00.000') =>
  dayjs(dateString).format('YYYY-MM-DD') + 'T' + startTime + 'Z';

/**
 * Adds 5 minutes to current DateTime to ensure
 * we are not in the past for scheduling
 * @param dateString ISO date string
 */
export const createFutureISODateTimeInUTC = (dateString: string) => {
  const startTime = dayjs().utc().add(5, 'minute').format('HH:mm:ss.000');
  return createISODateInUTC(dateString, startTime);
};

export const getTodaysFutureISODateTimeInUTC = () => {
  const today = dayjs().toString();
  return createFutureISODateTimeInUTC(today);
};

/**
 * Takes an ISO date string and returns a display date string
 * formatted MM/DD/YYY
 * @param date ISO Date string
 */
export const isoDateToDisplay = (date: string) => {
  return format(new Date(date), 'MM/dd/yyy');
};

export const isValidUnixDate = (date: string) => {
  const time = new Date(date).getTime();
  return !isNaN(time) && time > 0;
};

export const isoDateToDisplayInUTC = (date: string | number) => {
  return dayjs(date).utc().format('MM/DD/YYYY');
};

export const isoDateToDisplayString = (date: string) => {
  return date ? dayjs(date).utc().format('MMMM D, YYYY') : 'N/A';
};

export const isoDateToDisplayLocalString = (date: string) => {
  return isValidUnixDate(date) && date ? dayjs(date).local().format('MMMM D, YYYY') : 'N/A';
};

export const isoDateToDisplayAbbrString = (date: string) => {
  return dayjs(date).local().format('MMM D, YYYY');
};

type PrettifyOptions = {
  split?: '_' | '-' | 'CapitalLetter';
  outputCase?: 'Sentence case' | 'Title Case';
};

/**
 * Takes an input string and formats the output to a 'pretty' format.
 * Useful for formatting keys to something you can display to a user.
 * @param input String to format
 * @param options.split The character that separates the words
 * @param options.outputCase The format to output the string
 */
export const prettify = (input: string, options?: PrettifyOptions) => {
  const { split = 'CapitalLetter', outputCase = 'Title Case' } = options ?? {
    split: 'CapitalLetter',
    outputCase: 'Title Case',
  };

  const parts = input.split(split !== 'CapitalLetter' ? split : /(?=[A-Z])/);

  return parts.reduce((acc, part, idx) => {
    return (
      acc +
      (outputCase === 'Sentence case' && idx !== 0 ? part.charAt(0).toLowerCase() : part.charAt(0).toUpperCase()) +
      part.slice(1).toLowerCase() +
      (idx !== parts.length - 1 ? ' ' : '')
    );
  }, '');
};

export const termOptions = [
  {
    value: PaymentPlanFrequency.Weekly,
    displayValue: i18next.t('Weekly', { ns: 'payments' }),
  },
  {
    value: PaymentPlanFrequency.BiWeekly,
    displayValue: i18next.t('Bi-Weekly', { ns: 'payments' }),
  },
  {
    value: PaymentPlanFrequency.Monthly,
    displayValue: i18next.t('Monthly', { ns: 'payments' }),
  },
];

export const paymentPlanFrequencyPeriod = {
  [PaymentPlanFrequency.Weekly]: i18next.t('the week', { ns: 'payments' }),
  [PaymentPlanFrequency.Monthly]: i18next.t('the month', { ns: 'payments' }),
  [PaymentPlanFrequency.BiWeekly]: i18next.t('every 2 weeks', { ns: 'payments' }),
};

export const paymentPlanFrequencyLabel = {
  [PaymentPlanFrequency.Weekly]: i18next.t('week', { ns: 'payments' }),
  [PaymentPlanFrequency.Monthly]: i18next.t('month', { ns: 'payments' }),
  [PaymentPlanFrequency.BiWeekly]: i18next.t('bi-week', { ns: 'payments' }),
};

const pluralize = (word: string, count = 0) => (!isNaN(+count) && word ? `${word}${count > 1 ? 's' : ''}` : '');

export const planFrequencyLabel = (frequency?: PaymentPlanFrequency, paymentTerm?: string | number) =>
  pluralize(frequency ? paymentPlanFrequencyLabel[frequency] ?? '' : '', +(paymentTerm ?? 0));

export const getFutureDate = (date: string, term: number, frequency: ManipulateType) =>
  dayjs(date).add(term, frequency);

export const getFutureLocalDisplayDate = (date: string, term: number, frequency: ManipulateType) =>
  getFutureDate(date, term, frequency).local().format('MMMM D, YYYY');

export const formatDisplayDate = (date: Date | string | number) => {
  return date && !isNaN(new Date(date).getTime()) ? format(new Date(date), DEFAULT_DISPLAY_DATE_FORMAT) : '';
};

export const getDefaultFilterRange = () => ({
  start: formatDisplayDate(startOfToday()),
  end: formatDisplayDate(endOfToday()),
});

export const getDefaultRange = () => [
  formatDate(dayjs().startOf('day'), 'MM/DD/YYYY'),
  formatDate(dayjs().endOf('day'), 'MM/DD/YYYY'),
];

const startOf = {
  year: startOfYear,
  month: startOfMonth,
  week: startOfWeek,
  day: startOfDay,
};

const endOf = {
  year: endOfYear,
  month: endOfMonth,
  week: endOfWeek,
  day: endOfDay,
};

export const calculateRange = (option: QuickFillConfigOption, fromDate: Date = new Date()): string[] => {
  const { period, startShift, endShift } = option;

  if (period === 'custom' || period == 'all') {
    return [];
  }

  const periodKey = `${period}s`;

  const newStartDate = sub(startOf[period](fromDate), {
    [periodKey]: startShift,
  });
  const newEndDate = add(endOf[period](newStartDate), {
    [periodKey]: endShift,
  });

  return [format(newStartDate, DEFAULT_DISPLAY_DATE_FORMAT), format(newEndDate, DEFAULT_DISPLAY_DATE_FORMAT)];
};

export function getRangeText(range: [Date, Date] | [], delim = ' - ') {
  const [start, end] = range;
  if (!start || !end) {
    return 'All Time';
  }
  const formattedStart = format(new Date(start), DEFAULT_DISPLAY_DATE_FORMAT);
  const formattedEnd = format(new Date(end), DEFAULT_DISPLAY_DATE_FORMAT);
  if (formattedStart === formattedEnd) {
    return formattedStart;
  }
  const formattedRange = [formattedStart, formattedEnd];
  return formattedRange.join(delim);
}

export const getDateRefunded = (filter: RefundFilterType): RefundDateObject => {
  const [start, end] = getFilterDisplayDates({
    start: filter.dateRefunded?.gte,
    end: filter.dateRefunded?.lte,
  });
  const [gte, lte] = [start?.toISOString(), end?.toISOString()];
  return { gte, lte };
};

export const getPaymentPlanFilterRange = (range: Date[] | []): PaymentPlanDateObject => {
  const [start, end] = range;
  const [gte, lte] = getFilterDisplayDates({ start, end });
  return { greaterThanEqual: gte?.toISOString(), lessThanEqual: lte?.toISOString() };
};
