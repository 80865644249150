import { DepartmentSelector as StandaloneDepartmentSelector } from '../department-selector';
import { useThreadHeaderContext } from '../thread-header';

export const DepartmentSelector = () => {
  const { groupId, personPhone, outboundPhone, onOutboundPhoneChange } = useThreadHeaderContext();

  if (!onOutboundPhoneChange) return null;

  return (
    <StandaloneDepartmentSelector
      groupId={groupId}
      departmentId={outboundPhone?.departmentId ?? ''}
      personPhone={personPhone}
      onSelectDepartment={onOutboundPhoneChange}
    />
  );
};
