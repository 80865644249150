import { createContext, PropsWithChildren, useContext } from 'react';
import { SkeletonLoaderProps } from './types';

export type SkeletonLoaderContextState = Pick<SkeletonLoaderProps, 'animation' | 'shape' | 'duration' | 'isLoading'>;

const SkeletonLoaderContext = createContext<SkeletonLoaderContextState | undefined>(undefined);

export type SkeletonLoaderProviderProps = PropsWithChildren<SkeletonLoaderContextState>;

export const SkeletonLoaderProvider = ({ children, ...rest }: SkeletonLoaderProviderProps) => {
  return <SkeletonLoaderContext.Provider value={{ ...rest }}>{children}</SkeletonLoaderContext.Provider>;
};

export const useSkeletonLoader = () => useContext(SkeletonLoaderContext);
