import { ReactNode, useCallback, useEffect, useRef, useState, forwardRef } from 'react';
import { createPortal } from 'react-dom';
import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import { computePosition } from '@floating-ui/react-dom-interactions';
import { $getSelection, $isRangeSelection } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { theme } from '@frontend/theme';
import { Bold, Italic, Underline, FontColor, EmojiPicker, Link, FontSize } from '../toolbar/components';
import { usePointerInteractions } from '../hooks';

const DOM_CONTAINER = document.body;
const TOP_OFFSET = 8;

export function FloatingToolbarPlugin({ customFloatingToolbar }: { customFloatingToolbar?: ReactNode }) {
  const toolbarRef = useRef<HTMLDivElement>(null);
  const [toolbarPosition, setToolbarPosition] = useState<ToolbarPosition>(undefined);
  const [editor] = useLexicalComposerContext();

  const { isPointerDown, isPointerReleased } = usePointerInteractions();

  const calculateToolbarPosition = useCallback(async () => {
    const selection = getSelection();
    const range = selection?.rangeCount ? selection.getRangeAt(0) : null;

    if (!range || !toolbarRef.current || isPointerDown) {
      setToolbarPosition(undefined);
      return;
    }

    try {
      const position = await computePosition(range, toolbarRef.current, { placement: 'top' });
      setToolbarPosition({ x: position.x, y: position.y - TOP_OFFSET });
    } catch {
      setToolbarPosition(undefined);
    }
  }, [isPointerDown]);

  const handleSelectionChange = useCallback(() => {
    if (editor.isComposing() || editor.getRootElement() !== document.activeElement) {
      setToolbarPosition(undefined);
      return;
    }

    const selection = $getSelection();
    if ($isRangeSelection(selection) && !selection.anchor.is(selection.focus)) {
      calculateToolbarPosition();
    } else {
      setToolbarPosition(undefined);
    }
  }, [editor, calculateToolbarPosition]);

  useEffect(() => {
    const unregisterUpdateListener = editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => handleSelectionChange());
    });
    return unregisterUpdateListener;
  }, [editor, handleSelectionChange]);

  const toolbarVisible = toolbarPosition !== undefined;

  useEffect(() => {
    if (!toolbarVisible && isPointerReleased) {
      editor.getEditorState().read(() => handleSelectionChange());
    }
  }, [isPointerReleased, handleSelectionChange, editor]);

  return createPortal(
    <FloatingToolbar
      ref={toolbarRef}
      editor={editor}
      position={toolbarPosition}
      customFloatingToolbar={customFloatingToolbar}
    />,
    DOM_CONTAINER
  );
}

export type ToolbarPosition = { x: number; y: number } | undefined;

type FloatingToolbarProps = {
  editor: ReturnType<typeof useLexicalComposerContext>[0];
  position: ToolbarPosition;
  customFloatingToolbar?: ReactNode;
};

export const FloatingToolbar = forwardRef<HTMLDivElement, FloatingToolbarProps>(function FloatingToolbar(
  { position, customFloatingToolbar },
  ref
) {
  const visible = position !== undefined;

  return (
    <motion.div
      initial={{ opacity: 0, y: -4 }}
      animate={{ opacity: visible ? 1 : 0, y: visible ? 0 : -4 }}
      transition={{ duration: 0.2 }}
      ref={ref}
      css={css`
        display: flex;
        gap: ${theme.spacing(0.5)};
        padding: ${theme.spacing(0.5)};
        justify-content: stretch;
      `}
      aria-hidden={!visible}
      style={{
        position: 'absolute',
        top: position?.y,
        left: position?.x,
        visibility: visible ? 'visible' : 'hidden',
      }}
    >
      {customFloatingToolbar ? (
        customFloatingToolbar
      ) : (
        <>
          <Bold />
          <Italic />
          <Underline />
          <FontColor />
          <Link />
          <EmojiPicker />
          <FontSize />
        </>
      )}
    </motion.div>
  );
});
