import { FC } from 'react';
import { Outlet, ReactLocation, Router } from '@tanstack/react-location';
import { useRoutes } from '../providers/routes.provider';
import { useThirdPartyScripts } from '../third-party-scripts/use-third-party-scripts';

interface IMain {
  location: ReactLocation;
}

export const Main: FC<React.PropsWithChildren<IMain>> = ({ location }) => {
  const { routes } = useRoutes();
  useThirdPartyScripts();

  return (
    <Router routes={routes} location={location} useErrorBoundary={false}>
      <Outlet />
    </Router>
  );
};
