import { css } from '@emotion/react';
import type { TextStyleProps, TextThemeValues } from './text-theme';
import { getTextTheme, getTextThemeOriginal } from './text-theme';

const textComponentStyle = ({ fontFamily, fontSize, fontColor, textAlignStyle, fontWeight }: TextThemeValues) => css`
  ${fontFamily}
  ${fontSize}
  ${fontColor}
  ${textAlignStyle}
  ${fontWeight}
`;

export const TextStyles = {
  Text: (props: TextStyleProps) => textComponentStyle(getTextTheme(props)),
};
export const TextStylesOriginal: typeof TextStyles = {
  Text: (props: TextStyleProps) => textComponentStyle(getTextThemeOriginal(props)),
};
