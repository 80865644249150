/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const PhoneIconSmall: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={16} {...props} viewBox='0 0 16 16'>
    <path
      fillRule='evenodd'
      d='M0.568094729,3.75929318 L0.055044466,5.03278972 C-0.395405979,6.1509006 -0.317600738,7.412525 0.266827094,8.46680885 C1.23225193,10.2084349 2.83018605,11.6751405 5.03630968,12.8738875 C7.09888251,13.994633 9.00864331,14.6739753 10.771194,14.9028945 C12.061444,15.0704715 13.3525816,14.6000422 14.2326043,13.6417187 L15.2823771,12.4985508 C15.3881196,12.3834007 15.479996,12.2562493 15.5561245,12.1197009 C16.0939988,11.1549392 15.7479381,9.93681214 14.7831765,9.39893789 L13.2282635,8.53204228 L13.0732312,8.45418287 C12.3914854,8.14798283 11.5890792,8.24653524 10.9995774,8.72148625 L10.2971882,9.28634449 L10.2121922,9.26384658 C9.60089383,9.08527705 8.76044396,8.72633099 7.70993125,8.18731036 L7.43938627,8.04480053 C6.56272847,7.57043791 5.93756912,7.10342607 5.56121296,6.66324603 L5.48518815,6.56834449 L5.68304847,5.75400713 C5.89093514,4.89772083 5.51391837,4.00626698 4.75472127,3.55898312 L3.43842407,2.78348161 C3.35255251,2.73289006 3.26301936,2.68878815 3.17057294,2.6515445 C2.14602208,2.23878634 0.980852893,2.73474232 0.568094729,3.75929318 Z M1.91015791,5.78015448 L2.42320818,4.50665795 L3.73950538,5.28215945 L3.48754342,6.31999209 C3.39057916,6.72023448 3.46208232,7.14227915 3.68532423,7.48782601 C4.26857097,8.39060938 5.30773697,9.20264797 6.79690078,9.96674155 C8.18139905,10.677131 9.27307905,11.1210185 10.1036481,11.2989452 C10.545577,11.3937104 11.0068602,11.2839828 11.3589564,11.0003052 L12.254356,10.2788978 L13.809269,11.1457934 L12.7594934,12.2889643 C12.3194811,12.7681271 11.6739141,13.0033411 11.0287894,12.9195527 C9.53216183,12.7251714 7.8510957,12.1271807 5.99119292,11.1165606 C4.09945019,10.0886395 2.7825079,8.87984964 2.01605092,7.49716149 C1.72383545,6.97001676 1.6849331,6.33920891 1.91015791,5.78015448 Z'
      transform='translate(7.787449, 8.721191) rotate(14.000000) translate(-7.787449, -8.721191) '
    />
  </Icon>
);
