import { forwardRef, useMemo } from 'react';
import { css } from '@emotion/react';
import { Icon, type IconName } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text, TextLink, styles } from '@frontend/design-system';
import { WidgetizedDashboardTrackingIds } from '../../tracking-ids';
import { WidgetSizeSelector } from '../widget-size-selector';
import { useDashboardWidget } from './provider';

export interface DashboardWidgetItemHeaderProps {
  icon?: IconName;
  title?: string;
  action?: DashboardWidgetActionProps;
  children?: React.ReactNode;
}

export const DashboardWidgetItemHeader = ({
  title,
  icon,
  children,
  action,
}: React.PropsWithChildren<DashboardWidgetItemHeaderProps>) => {
  const {
    isEditMode,
    sizes,
    icon: defaultIcon,
    title: defaultTitle,
    currentSize,
    onSizeChange,
    currentBreakpoint,
    id,
  } = useDashboardWidget();

  const actualSizes = useMemo(() => [...new Set(sizes)], [sizes]);

  const allowedSizes = useMemo(() => {
    if (currentBreakpoint !== 'large' && currentBreakpoint !== 'medium') {
      return actualSizes.filter((value) => !value.includes('wide'));
    }
    return actualSizes;
  }, [currentBreakpoint]);

  return (
    <header css={headerStyle}>
      <Text
        as='h3'
        css={[
          css`
            display: flex;
            gap: ${theme.spacing(0.5)};
            align-items: center;
            min-width: 0;
          `,
        ]}
      >
        <Icon
          name={icon ?? defaultIcon}
          css={css`
            color: ${theme.colors.neutral70};
          `}
          size={16}
        />
        <span css={titleStyle}>{defaultTitle ?? title}</span>
      </Text>
      {isEditMode ? (
        allowedSizes.length > 1 ? (
          <WidgetSizeSelector
            trackingId={WidgetizedDashboardTrackingIds.sizeSelector(`widget-${id}`)}
            options={allowedSizes}
            value={currentSize}
            onChange={onSizeChange}
          />
        ) : null
      ) : (
        <>
          {children}
          {action && <DashboardWidgetAction {...action} />}
        </>
      )}
    </header>
  );
};

type DashboardWidgetActionProps = Omit<React.ComponentProps<typeof TextLink>, 'children' | 'as'> & {
  label: React.ReactNode;
};

export const DashboardWidgetAction = forwardRef<HTMLButtonElement | HTMLAnchorElement, DashboardWidgetActionProps>(
  ({ onClick, label, ...rest }: DashboardWidgetActionProps, ref) => {
    return (
      <TextLink
        ref={ref}
        size='medium'
        weight='bold'
        css={css`
          white-space: nowrap;
          text-decoration: none;
        `}
        onClick={onClick}
        {...rest}
      >
        {label}
      </TextLink>
    );
  }
);

DashboardWidgetAction.displayName = 'DashboardWidgetAction';

export const headerStyle = css({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  minWidth: 0,
  justifyContent: 'space-between',
});

export const titleStyle = css([
  {
    fontSize: theme.fontSize(14),
    fontWeight: theme.font.weight.bold,
    margin: 0,
    marginTop: theme.spacing(0.75),
    marginBottom: theme.spacing(0.5),
  },
  styles.truncate,
]);
