import { css } from '@emotion/react';
import {
  containerTheme,
  containerThemeOriginal,
  getAlertTextTheme,
  getAlertTextThemeOriginal,
  alertActionContainerTheme,
  alertActionContainerThemeOriginal,
  GetAlertTextThemeValues,
  DivStyleThemeValues,
  AlertActionContainerThemeValues,
  CloseButtonThemeValues,
  closeButtonTheme,
  closeButtonThemeOriginal,
} from './alert-theme';

import type { TextStyleProp } from './types';

const textStyle = ({ spacing, borderLeftColor, borderRadius }: GetAlertTextThemeValues) => css`
  display: flex;
  align-items: center;
  margin: 0;

  ::before {
    content: '';
    width: 4px;
    height: 100%;

    background: ${borderLeftColor};
    border-top-left-radius: ${borderRadius};
    border-bottom-left-radius: ${borderRadius};
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }

  svg {
    min-width: 24px;
    margin-right: ${spacing(1)};
  }
`;

const containerStyle = ({ backgroundColor, borderRadius, boxShadow, zIndex }: DivStyleThemeValues) => css`
  background: ${backgroundColor};
  border-radius: ${borderRadius};
  box-shadow: ${boxShadow};
  width: 100%;
  overflow: hidden;
  display: flex;
  position: relative;
  pointer-events: all;
  z-index: ${zIndex};
`;

const alertActionContainerStyle = ({ spacing, borderLeftColor }: AlertActionContainerThemeValues) => css`
  border-left: 1px solid ${borderLeftColor};
  padding: ${spacing(0, 2)};
  margin-left: auto;
  display: flex;
  align-items: center;
  margin: ${spacing(2, 0)};
`;

const sizeValue = '24px';

const closeButtonStyle = ({ backgroundColor, boxShadow }: CloseButtonThemeValues) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  min-width: ${sizeValue};
  width: ${sizeValue};
  min-height: ${sizeValue};
  height: ${sizeValue};
  border-radius: 50px;
  padding: 0;
  font-size: initial;
  pointer-events: all;
  cursor: pointer;
  background: ${backgroundColor};
  box-shadow: ${boxShadow};
`;

export const AlertStyles = {
  Alert: {
    textStyle: (textStyleProps: TextStyleProp) => textStyle(getAlertTextTheme(textStyleProps)),
    containerStyle: containerStyle(containerTheme),
    alertActionContainerStyle: alertActionContainerStyle(alertActionContainerTheme),
    closeButtonStyle: closeButtonStyle(closeButtonTheme),
  },
};

export const AlertStylesOriginal: typeof AlertStyles = {
  Alert: {
    textStyle: (textStyleProps: TextStyleProp) => textStyle(getAlertTextThemeOriginal(textStyleProps)),
    containerStyle: containerStyle(containerThemeOriginal),
    alertActionContainerStyle: alertActionContainerStyle(alertActionContainerThemeOriginal),
    closeButtonStyle: closeButtonStyle(closeButtonThemeOriginal),
  },
};
