import { css } from '@emotion/react';
import { useThemeValues } from '../../../../hooks';
import { Text } from '../../..';
import { ReactNode } from 'react';
import { onlyText } from 'react-children-utilities';

export const ListRowSubtitle = ({ children, ...rest }: { children: ReactNode }) => {
  const { fontSize, spacing } = useThemeValues();

  return (
    <Text
      css={css`
        font-size: ${fontSize(14)};
        margin: ${spacing(0.5, 0)};
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      `}
      title={onlyText(children as React.ReactNode)}
      className='content-column-subtitle'
      {...rest}
    >
      {children}
    </Text>
  );
};
