import { css } from '@emotion/react';
import {
  TooltipStylesTheme,
  tooltipStyles as tooltipStylesObject,
  tooltipStylesOriginal,
  textStyles as textStylesObject,
  textStylesOriginal,
  TextStylesTheme,
} from './copy-to-clipboard.theme';

type TooltipStylesProps = { copied: boolean };

const tooltipStyles = (props: TooltipStylesProps, theme: TooltipStylesTheme) => css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};
  .copy-status-icon {
    fill: ${props.copied ? theme.copiedColor : theme.notCopiedColor};
  }
`;

const textStyles = (theme: TextStylesTheme) => css`
  transition: all 0.2s ease-in-out;
  :hover {
    cursor: pointer;
    background: ${theme.highlightColor};
    color: ${theme.color};
  }
`;

export const CopyToClipboardStyles = {
  CopyableText: {
    text: () => textStyles(textStylesObject),
    tooltip: (props: TooltipStylesProps) => tooltipStyles(props, tooltipStylesObject),
  },
};
export const CopyToClipboardStylesOriginal = {
  CopyableText: {
    text: () => textStyles(textStylesOriginal),
    tooltip: (props: TooltipStylesProps) => tooltipStyles(props, tooltipStylesOriginal),
  },
};
