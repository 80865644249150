import { useEffect, useState } from 'react';
import { css, keyframes } from '@emotion/react';
import { Placement } from '@floating-ui/react';
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';
import { theme } from '@frontend/theme';
import { EmojiIcon } from '../../icon';
import { IconButton } from '../icon-button';
import { PopoverDialog, usePopoverDialog } from '../popover';

export const EmojiButton = ({
  onSelect,
  disabled,
  pickerWidth = 350,
  label = 'Add Emoji',
  popoverPlacement = 'bottom-start',
  ...rest
}: {
  onSelect: (emoji: EmojiClickData) => void;
  disabled?: boolean;
  pickerWidth?: number;
  trackingId?: string;
  label?: string;
  showLabelAlways?: boolean;
  popoverPlacement?: Placement;
}) => {
  const { getDialogProps, getTriggerProps, isOpen, close } = usePopoverDialog<HTMLButtonElement | HTMLAnchorElement>({
    placement: popoverPlacement,
  });
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  useEffect(() => {
    let id: ReturnType<typeof setTimeout>;

    if (!isOpen) {
      setShowEmojiPicker(false);
    }

    if (isOpen) {
      id = setTimeout(() => setShowEmojiPicker(true), 400);
    }
    return () => {
      if (id) {
        clearTimeout(id);
      }
    };
  }, [isOpen]);

  const handleSelect = (emoji: EmojiClickData) => {
    onSelect(emoji);
    setShowEmojiPicker(false);
    close();
  };

  return (
    <>
      <IconButton label={label} {...getTriggerProps()} disabled={disabled} {...rest}>
        <EmojiIcon />
      </IconButton>
      <PopoverDialog
        {...getDialogProps()}
        css={{ width: pickerWidth, maxHeight: 450, padding: 0, borderRadius: 8 }}
        returnFocus={false}
      >
        <div
          css={css`
            width: ${pickerWidth}px;
            height: 450px;
            display: flex;
            justify-content: center;
            align-items: center;
            * {
              font-family: ${theme.font.family};
            }
          `}
        >
          {showEmojiPicker ? (
            <EmojiPicker onEmojiClick={handleSelect} lazyLoadEmojis height={450} width={pickerWidth} />
          ) : (
            <TypingIndicator />
          )}
        </div>
      </PopoverDialog>
    </>
  );
};

const blink = keyframes`
  0%, 100% {
    opacity: 0.3;
  }

  50% {
    opacity: 1;
    transform: scale(1.3);
  }
`;

const dotStyle = css`
  width: 4px;
  height: 4px;
  background-color: ${theme.colors.neutral50};
  border-radius: ${theme.borderRadius.small};
  display: inline-block;
  margin: ${theme.spacing(0, 0.25)};
`;

export const TypingIndicator = () => (
  <span
    css={css`
      margin-left: ${theme.spacing(1)};
      margin-top: ${theme.spacing(1)};

      ::before,
      ::after {
        content: '';
        ${dotStyle};
      }

      ::before {
        animation: ${blink} 1.5s ease infinite 0.5s;
      }

      ::after {
        animation: ${blink} 1.5s ease infinite 1.5s;
      }
    `}
    aria-hidden='true'
  >
    <span
      css={[
        dotStyle,
        css`
          animation: ${blink} 1.5s ease infinite 1s;
        `,
      ]}
    />
  </span>
);
