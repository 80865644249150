import i18next from 'i18next';
import { CreditCardBrand, PaymentType } from '@frontend/api-invoices';
import { PaymentMethod, PaymentMethodStatus, PaymentPlanPaymentMethod } from '@frontend/api-payment-plans';
import { cardBrandMap } from '@frontend/payments-card-brand';
import { prettify, titleCase } from '@frontend/string';

type PaymentMethodInfo = PaymentMethod | PaymentPlanPaymentMethod | undefined;

export const mapPaymentMethodToPaymentPlanPaymentMethod = (
  paymentMethod?: PaymentMethod
): PaymentPlanPaymentMethod | undefined => {
  if (!paymentMethod) return undefined;

  let cardBrand = paymentMethod.card?.brand.toUpperCase();
  cardBrand = cardBrand in cardBrandMap ? cardBrand : CreditCardBrand.CardBrandUnknown;
  return {
    cardInfo: {
      ...paymentMethod.card,
      brand: cardBrand as CreditCardBrand,
      lastFour: paymentMethod.card?.last4,
    },
    bankAccountInfo: {
      ...paymentMethod.bankAccount,
      type: paymentMethod.bankAccount.accountType,
      lastFour: paymentMethod.bankAccount.last4,
    },
    paymentMethodId: paymentMethod?.id,
  };
};

export const mapPaymentPlanPaymentMethodToPaymentMethod = (
  paymentMethod?: PaymentPlanPaymentMethod
): PaymentMethod | undefined => {
  if (!paymentMethod) return undefined;

  let cardBrand = paymentMethod.cardInfo?.brand.toUpperCase();
  cardBrand = cardBrand in cardBrandMap ? cardBrand : CreditCardBrand.CardBrandUnknown;
  return {
    card: {
      ...paymentMethod.cardInfo,
      brand: cardBrand.toLowerCase(),
      last4: paymentMethod.cardInfo?.lastFour,
    },
    bankAccount: {
      bankName: paymentMethod.bankAccountInfo?.bankName ?? '',
      accountType: paymentMethod.bankAccountInfo?.type ?? '',
      last4: paymentMethod.bankAccountInfo?.lastFour ?? '',
    },
    type: paymentMethod.bankAccountInfo?.lastFour ? 'us_bank_account' : 'card',
    id: paymentMethod?.paymentMethodId,
    is_default: false,
  };
};

export const getPaymentMethodNameParts = (pm: PaymentMethodInfo) => {
  let paymentMethodName: string | undefined;
  let paymentMethodBrand: CreditCardBrand | PaymentType | undefined;
  let last4: string | undefined;

  if (pm) {
    const cardBrand = 'id' in pm ? pm.card?.brand : pm.cardInfo?.brand;
    const cardLast4 = 'id' in pm ? pm.card?.last4 : pm.cardInfo?.lastFour;

    const accountName = 'id' in pm ? pm.bankAccount?.bankName : pm.bankAccountInfo?.bankName;
    const accountLast4 = 'id' in pm ? pm.bankAccount?.last4 : pm.bankAccountInfo?.lastFour;

    const cardBrandName = cardBrand.toUpperCase();
    const creditCardBrand =
      cardBrandName in cardBrandMap ? (cardBrandName as CreditCardBrand) : CreditCardBrand.CardBrandUnknown;

    const paymentMethodType = accountLast4 ? 'us_bank_account' : 'card';

    paymentMethodName =
      paymentMethodType === 'us_bank_account'
        ? accountName && titleCase(accountName)
        : cardBrand && prettify(cardBrand);
    paymentMethodBrand =
      paymentMethodType === 'us_bank_account' ? PaymentType.ACH : cardBrand ? creditCardBrand : undefined;
    last4 = paymentMethodType === 'us_bank_account' ? accountLast4 : cardLast4;
  }

  return { paymentMethodName, paymentMethodBrand, last4 };
};

export const getPaymentMethodName = (pm: PaymentMethodInfo) => {
  const { paymentMethodName, last4 } = getPaymentMethodNameParts(pm);
  return `${paymentMethodName ?? ''} ${last4 ?? ''}`.trim();
};

export const getCardStatusLabel = (status: PaymentMethodStatus) => {
  switch (status) {
    case PaymentMethodStatus.PAYMENT_METHOD_STATUS_EXPIRED:
      return i18next.t('Card Expired', { ns: 'payments' });
    case PaymentMethodStatus.PAYMENT_METHOD_STATUS_VERIFICATION_PENDING:
      return i18next.t('Pending', { ns: 'payments' });
    default:
      return '';
  }
};
