import { WeaveTheme } from '@frontend/theme';
import { WeaveTheme as WeaveThemeOriginal } from '@frontend/theme-original';
import { FieldKey } from '../hooks/types';
import { CommonFieldAtomProps } from '../atoms/types';
import { FieldAtomProps, FieldAtomPropsResult, getFieldAtomProps } from '../atoms/utils';

type GetThemeOriginalFontColorProps = Pick<CommonFieldAtomProps, 'disabled' | 'hasError'> & {
  theme: WeaveThemeOriginal;
};

type GetThemeFontColorProps = Pick<CommonFieldAtomProps, 'disabled' | 'hasError'> & {
  theme: WeaveTheme;
};

export const getThemeOriginalFontColor = ({ theme, disabled, hasError }: GetThemeOriginalFontColorProps) => {
  if (disabled) return theme.colors.gray[400];
  if (hasError) return theme.colors.error;
  return theme.colors.gray[500];
};

export const getThemeFontColor = ({ theme, disabled, hasError }: GetThemeFontColorProps) => {
  if (disabled) return theme.colors.neutral20;
  if (hasError) return theme.colors.status.critical;
  return theme.colors.neutral50;
};

type FormFieldAtomProps<T extends FieldKey> = FieldAtomPropsResult<T> & {
  wrapperProps: Omit<CommonFieldAtomProps, 'id'>;
};

type Props<T extends FieldKey> = FieldAtomProps<T> & {
  label?: string;
};

export function getFormFieldAtomProps<T extends FieldKey>(props: Props<T>): FormFieldAtomProps<T> {
  const { active, disabled, label } = props;
  const atomProps = getFieldAtomProps(props);

  // only allow placeholder when the label isn't sitting in the field
  if (label && props.placeholder && !props.active) {
    delete atomProps.fieldProps.placeholder;
  }
  return {
    ...atomProps,
    wrapperProps: { active, disabled, hasError: atomProps.labelProps.hasError },
  };
}
