import { useSlidePanelStore } from '@frontend/slide-panel';
import { createStoreWithSubscribe, createShallowStore } from '@frontend/store';

type ScrollTo = 'insurance-details' | 'appointment-details' | 'balance' | 'auto-messages';
export interface ContactPanelStore {
  personId: string;
  householdId: string | undefined;
  personLocationId?: string;
  lastFocused: string;
  setPersonId: (personId: string, show?: boolean, personLocationId?: string, scrollTo?: ScrollTo) => void;
  setHouseholdId: (householdId: string | undefined) => void;
  setLastFocused: (personId: string) => void;
  resetPersonId: () => void;
  scrollTo?: ScrollTo;
  setScrollTo: (scrollTo?: ScrollTo) => void;
}

export const useContactPanelStore = createStoreWithSubscribe<ContactPanelStore>(
  (set) => ({
    personId: '',
    householdId: '',
    lastFocused: '',

    setPersonId: (personId, show = true, personLocationId) => {
      set(
        (state) => {
          state.personId = personId;
          state.personLocationId = personLocationId;
        },
        false,
        'SET_CONTACT_PANEL_PERSON_ID'
      );

      set(
        (state) => {
          state.lastFocused = personId;
        },
        false,
        'SET_CONTACT_PANEL_LAST_FOCUSED_ID'
      );

      useSlidePanelStore.setState((state) => ({
        ...state,
        show,
        panelType: 'contact',
      }));
    },
    resetPersonId: () =>
      set(
        (state) => {
          state.personId = '';
          state.personLocationId = undefined;
          state.scrollTo = undefined;
        },
        false,
        'RESET_CONTACT_PANEL_PERSON_ID'
      ),
    setLastFocused: (personId) =>
      set(
        (state) => {
          state.personId = personId;
        },
        false,
        'SET_LAST_FOCUSED'
      ),
    setScrollTo: (scrollTo) =>
      set(
        (state) => {
          state.scrollTo = scrollTo;
        },
        false,
        'SET_SCROLL_TO'
      ),
    setHouseholdId: (householdId) =>
      set(
        (state) => {
          state.householdId = householdId;
        },
        false,
        'SET_CONTACT_PANEL_HOUSEHOLD_ID'
      ),
  }),
  { name: 'ContactPanelStore', trace: true }
);

export const useContactPanelShallowStore = createShallowStore(useContactPanelStore);
