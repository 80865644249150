import { ElementType, FC, forwardRef, ComponentPropsWithRef, PropsWithChildren, ComponentPropsWithoutRef } from 'react';
import { IconProps } from '../../../icon';
import { useStyles } from '../../../use-styles';
import { Text, useTooltip } from '../../';
import composeRefs from '@seznam/compose-react-refs';

type ElementTypePartial = Extract<ElementType, 'a' | 'button' | 'span'>;

export type PopoverMenuItem = {
  active?: boolean;
  disabled?: boolean;
  Icon?: FC<React.PropsWithChildren<Pick<IconProps, 'size' | 'color'>>>;
  label?: string;
  hoverLabel?: string;
  menuId?: string;
  trackingId?: string;
  destructive?: boolean;
};

type MakeHrefRequired<C> = C extends Extract<ElementTypePartial, 'a'> ? { href: string } : Record<string, unknown>;

type PolymorphicRef<C extends ElementTypePartial> = ComponentPropsWithRef<C>['ref'];

type AsProp<C extends ElementTypePartial> = {
  as?: C;
};

type PropsToOmit<C extends ElementTypePartial, P> = keyof (AsProp<C> & P);

type PolymorphicComponentProp<C extends ElementTypePartial, Props = Record<string, unknown>> = PropsWithChildren<
  Props & AsProp<C>
> &
  Omit<ComponentPropsWithoutRef<C>, PropsToOmit<C, Props>>;

type PolymorphicComponentPropWithRef<
  C extends ElementTypePartial,
  Props = Record<string, unknown>
> = PolymorphicComponentProp<C, Props> & { ref?: PolymorphicRef<C> };

export type PopoverMenuItemProps<C extends ElementTypePartial> = PolymorphicComponentPropWithRef<C, PopoverMenuItem> &
  MakeHrefRequired<C>;

export const PopoverMenuItem = forwardRef(
  <C extends ElementTypePartial = 'button'>(
    {
      active,
      as,
      disabled,
      Icon,
      hoverLabel,
      children,
      menuId,
      destructive,
      trackingId,
      ...rest
    }: PopoverMenuItemProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    const Component = as || ('button' as ElementType);
    const styles = useStyles('PopoverMenu', 'menuItem', { active, disabled, destructive });
    const { Tooltip, tooltipProps, triggerProps } = useTooltip({
      placement: 'bottom',
    });

    return (
      <Component
        {...rest}
        {...triggerProps}
        ref={composeRefs(ref, triggerProps.ref)}
        data-trackingid={trackingId}
        disabled={disabled}
        css={styles}
        role='menuitem'
      >
        {hoverLabel && <Tooltip {...tooltipProps}>{hoverLabel}</Tooltip>}
        {Icon && <Icon size={16} color={destructive ? 'error' : 'default'} />}
        {typeof children === 'string' ? (
          <Text as='span' className={`menu-item-text__${menuId}`} color={destructive ? 'error' : 'default'}>
            {children}
          </Text>
        ) : (
          children
        )}
      </Component>
    );
  }
);
