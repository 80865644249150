import { useEffect } from 'react';
import { useMatch, useNavigate } from '@tanstack/react-location';
import { useQuery } from 'react-query';
import { getPaymentPlanConfirmationDetails } from '@frontend/api-payment-plans';
import { HttpError } from '@frontend/fetch';
import { getInterScreenQueryOptions } from '@frontend/payments-hooks';

export const usePaymentPlanInfo = () => {
  const { params, pathname } = useMatch();
  const { planId } = params;
  const navigate = useNavigate();

  const {
    data: paymentPlan,
    error: paymentPlanError,
    isLoading: paymentPlanLoading,
    isFetched: paymentPlanFetched,
  } = useQuery({
    queryKey: [planId],
    queryFn: () => getPaymentPlanConfirmationDetails(planId),
    enabled: !!planId,
    ...getInterScreenQueryOptions(Infinity, true),
  });

  useEffect(() => {
    if (paymentPlanError) {
      if (paymentPlanError instanceof HttpError && paymentPlanError.status === 410)
        navigate({ to: pathname.replace(/\/confirm\/?/, '/410') });
      else navigate({ to: '/error' });
    }
  }, [paymentPlanError]);

  const { person, schedule, paymentSummary, paymentMethod, location } = paymentPlan ?? {};

  return {
    paymentPlan,
    paymentPlanError,
    paymentPlanLoading,
    paymentPlanFetched,
    person,
    schedule,
    paymentSummary,
    paymentMethod,
    location,
  };
};
