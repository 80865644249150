import React from 'react';
import { useIndexRowItemContext } from './index-row-item';
import { css } from '@emotion/react';

type IndexRowHeadProps = {
  children: React.ReactNode;
};

export const IndexRowHead = ({ children }: IndexRowHeadProps) => {
  const { disabled } = useIndexRowItemContext();
  return (
    <div
      css={
        disabled &&
        css`
          opacity: 0.5;
        `
      }
    >
      {children}
    </div>
  );
};

IndexRowHead.displayName = 'IndexRowHead';
