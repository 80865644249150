import { gql } from 'graphql-request';
import { QueryOperatorInput } from '@frontend/api-refunds';
import { PayoutEdge, PayoutStatus } from '../types';

export interface PayoutListFilter {
  amount?: QueryOperatorInput<number>;
  arrival?: QueryOperatorInput<string>;
  status?: PayoutStatus;
  locaionId?: string[];
}

export interface PayoutListQueryVariables {
  after?: string;
  before?: string;
  first?: number;
  last?: number;
  filter?: PayoutListFilter;
}

export interface PayoutListPaginationOptions {
  page?: number;
  pageSize?: number;
}

export interface PayoutListQueryResponse {
  payouts: {
    __typename: string;
    edges: PayoutEdge[];
    pageInfo: {
      hasPreviousPage: boolean;
      hasNextPage: boolean;
      startCursor: string;
      endCursor: string;
    };
  };
}

export const payoutListQuery = gql`
  query payouts($first: Int, $last: Int, $before: String, $after: String, $filter: PayoutsFilterInput) {
    payouts(first: $first, last: $last, before: $before, after: $after, filter: $filter) {
      edges {
        cursor
        node {
          payoutId
          locationId
          status
          amount
          createdAt
          estimatedArrivalOn
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const allPayoutsQuery = gql`
  query payouts($before: String, $after: String, $filter: PayoutsFilterInput) {
    payouts(before: $before, after: $after, filter: $filter) {
      edges {
        node {
          payoutId
          locationId
          status
          amount
          createdAt
          estimatedArrivalOn
        }
      }
    }
  }
`;
