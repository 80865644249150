import { gql } from 'graphql-request';
import { useQuery } from 'react-query';
import { InvoiceModel, getInvoiceReceipt, getRefundReceipt } from '@frontend/api-invoices';
import { useMerchant, useMultiQueryUtils } from '@frontend/payments-hooks';
import { usePaymentsGraphqlClient } from '../../utils';

export const useFetchPaymentReceipt = (invoice?: InvoiceModel) => {
  const { paymentsUrl } = useMerchant();

  return useQuery(
    ['invoiceReceipt', invoice?.payment?.paymentId],
    () => {
      if (!invoice || !paymentsUrl) return;
      return getInvoiceReceipt(paymentsUrl, invoice);
    },
    {
      staleTime: Infinity,
      cacheTime: 30 * 60 * 1000, // 30 min,
      enabled: invoice?.payment && !!paymentsUrl,
      onError: (error) => error,
      refetchOnWindowFocus: false,
    }
  );
};

export const useFetchRefundReceipt = (paymentId: string, refundId: string) => {
  const { paymentsUrl } = useMerchant();

  return useQuery(
    ['refundReceipt', refundId],
    () => {
      if (!paymentsUrl || !paymentId || !refundId) return;

      return getRefundReceipt(paymentsUrl, paymentId, refundId);
    },
    {
      staleTime: Infinity,
      cacheTime: 30 * 60 * 1000, // 30 min,
      enabled: !!paymentId && !!paymentsUrl && !!refundId,
      onError: (error) => error,
      refetchOnWindowFocus: false,
    }
  );
};

const SEND_REFUND = gql`
  mutation SendRefundReceiptEmail($input: SendRefundReceiptEmailInput!) {
    sendRefundReceiptEmail(input: $input)
  }
`;

export const useSendRefundReceipt = (paymentId?: string, externalId?: string) => {
  const { locationId } = useMultiQueryUtils();
  const { client } = usePaymentsGraphqlClient();

  const sendRefundReceipt = async (email: string) => {
    const variables = {
      input: {
        paymentId,
        externalId,
        locationId,
        emailAddress: email,
      },
    };
    return await client.request(SEND_REFUND, variables);
  };

  return { sendRefundReceipt };
};
