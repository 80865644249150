import { useStyles } from '../../../../use-styles';
import { Text } from '../../../text';
import { CheckIcon } from '../../../../icon';
import { TimeFieldProps, TimeInterval } from './types';
import { MutableRefObject } from 'react';
import { useInteractions } from '@floating-ui/react-dom-interactions';

type DropdownListItemProps = Pick<TimeFieldProps, 'disabled' | 'displayValue' | 'id' | 'name' | 'onChange'> & {
  active: boolean;
  getItemProps: ReturnType<typeof useInteractions>['getItemProps'];
  index: number;
  intervals: TimeInterval[];
  listItemsRef: MutableRefObject<(HTMLLIElement | null)[]>;
  selected: boolean;
  setFloatingElementOpen: (isOpen: boolean) => void;
  setSelectedIndex: (newIndex: number) => void;
  value: TimeInterval;
};

/**
 *
 * After some of the new floating-ui components have baked and garnered trust,
 * this component should be genericized and merged with
 * `libs/ui/design-system/src/components/forms/fields/dropdown/dropdown-field-option.tsx`
 */
export const DropdownListItem = ({
  active,
  disabled,
  displayValue,
  getItemProps,
  index,
  intervals,
  listItemsRef,
  name,
  onChange,
  selected,
  setFloatingElementOpen,
  setSelectedIndex,
  value,
  ...rest
}: DropdownListItemProps) => {
  const dropdownOptionStyles = useStyles('DropdownField', 'dropdownOption', { active, disabled });

  return (
    <li
      aria-disabled={disabled}
      aria-selected={active}
      role='option'
      {...getItemProps({
        onClick() {
          if (disabled) return;
          setSelectedIndex(index);
          onChange({ name, value });
          setFloatingElementOpen(false);
        },
      })}
      ref={(node) => (listItemsRef.current[index] = node)}
      css={dropdownOptionStyles}
      {...rest}
    >
      <Text as='span' className='select-option-text'>
        {displayValue ?? <i>No value</i>}
      </Text>
      {selected && <CheckIcon color='primary' />}
    </li>
  );
};
