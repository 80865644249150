import { css } from '@emotion/react';
import { templateCharCountTheme, templateCharCountThemeOriginal } from './char-count/template-char-count-theme';
import { templateCharCountStyle } from './char-count/template-char-count.style';
import { templateEditorTheme, templateEditorThemeOriginal } from './editor/template-editor-theme';
import { TemplateEditorStyleProps, templateEditorStyle } from './editor/template-editor.style';
import {
  linkTheme,
  linkThemeOriginal,
  LinkThemeValues,
  xIconTheme,
  xIconThemeOriginal,
  XIconThemeValues,
} from './message-template-theme';
import { templatePreviewTheme, templatePreviewThemeOriginal } from './preview/template-preview-theme';
import { templatePreviewStyle } from './preview/template-preview.styles';
import { messageTemplateTagTheme, messageTemplateTagThemeOriginal } from './tags/message-template-tag-theme';
import { messageTemplateTagStyle } from './tags/message-template-tag.style';
import { MessageTemplateTagStyleProps } from './tags/types';

const xIconStyle = ({ iconColor, spacing }: XIconThemeValues) => css`
  color: ${iconColor};
  margin-left: ${spacing(0.5)};
`;

const linkStyle = ({ linkTextColor }: LinkThemeValues) => css`
  color: ${linkTextColor};
`;

export const MessageTemplateStyles = {
  MessageTemplate: {
    templateEditor: (props: TemplateEditorStyleProps) => templateEditorStyle(templateEditorTheme, props),
    templateCharCount: templateCharCountStyle(templateCharCountTheme),
    templatePreview: templatePreviewStyle(templatePreviewTheme),
    messageTemplateTag: (props: MessageTemplateTagStyleProps) =>
      messageTemplateTagStyle(messageTemplateTagTheme, props),
    xIconStyle: xIconStyle(xIconTheme),
    linkStyle: linkStyle(linkTheme),
  },
};

export const MessageTemplateStylesOriginal: typeof MessageTemplateStyles = {
  MessageTemplate: {
    templateEditor: (props: TemplateEditorStyleProps) => templateEditorStyle(templateEditorThemeOriginal, props),
    templateCharCount: templateCharCountStyle(templateCharCountThemeOriginal),
    templatePreview: templatePreviewStyle(templatePreviewThemeOriginal),
    messageTemplateTag: (props: MessageTemplateTagStyleProps) =>
      messageTemplateTagStyle(messageTemplateTagThemeOriginal, props),
    xIconStyle: xIconStyle(xIconThemeOriginal),
    linkStyle: linkStyle(linkThemeOriginal),
  },
};
