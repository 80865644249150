import { useRef } from 'react';
import { genUID } from '../helpers';

/**
 * A hook for getting a stable uid in functional components or hooks.
 * @param {string} [prefix] Optional prefix to add to the generated id.
 * @returns React ref with stable uid value
 */
export function useUid(prefix?: string) {
  const idRef = useRef<string>(prefix ? `${prefix}-${genUID()}` : genUID());
  return idRef;
}
