import { KeyNames } from '../../../constants';
import { css } from '@emotion/react';
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { getLabelId } from '../atoms/utils';
import type { FieldChangeEvent } from '../hooks/types';
import { useDebouncedBlur } from '../hooks/use-debounced-blur/use-debounced-blur';
import type { BasicFormFieldProps, InlineLabelPlacement, RadioFieldKey } from '../layouts/types';

type RadioContextValue = {
  labelPlacement: InlineLabelPlacement;
  getOptionProps: (optValue: string) => {
    active: boolean;
    'aria-checked': boolean;
    checked: boolean;
    className: string;
    disabled?: boolean;
    id: string;
    name: string;
    onBlur: () => void;
    onChange: (e?: FieldChangeEvent) => void;
    onFocus: () => void;
    role: string;
    tabIndex: number;
    value: string;
  };
};

const RadioContext = createContext<RadioContextValue>({} as RadioContextValue);

type RadioProviderProps<T extends RadioFieldKey> = Pick<
  BasicFormFieldProps<T>,
  'active' | 'disabled' | 'id' | 'name' | 'onBlur' | 'onChange' | 'onFocus' | 'value'
> & {
  children: React.ReactNode;
  className?: string;
  labelPlacement?: InlineLabelPlacement;
};

const RADIO_CLASS = 'rg-radio';

export const RadioProvider = <T extends RadioFieldKey>({
  active,
  children,
  className,
  disabled,
  id,
  labelPlacement = 'right',
  name,
  onBlur,
  onChange,
  onFocus,
  value,
}: RadioProviderProps<T>) => {
  const [focusIndex, setFocusIndex] = useState(-1);
  const containerRef = useRef<HTMLDivElement>(null);

  const selectOptions = (): HTMLElement[] => {
    if (containerRef.current) {
      return Array.from(containerRef.current.querySelectorAll(`.${RADIO_CLASS}`));
    }
    return [];
  };

  const getNavData = (val: string) => {
    const options = selectOptions();
    const index = options.findIndex((el) => el.getAttribute('data-value') === val);
    return { options, index };
  };

  const debounceProps = useMemo(
    () => ({
      onBlur: () => {
        const payload: unknown = { target: { name } };
        onBlur(payload as React.FocusEvent<HTMLInputElement>);
        setFocusIndex(-1);
      },
      onFocus: () => {
        const payload: unknown = { target: { name } };
        onFocus(payload as React.FocusEvent<HTMLInputElement>);
      },
    }),
    [name]
  );
  const { blur, focus } = useDebouncedBlur(debounceProps);

  const contextValue = useMemo(
    () => ({
      labelPlacement,
      getOptionProps: (optValue: string) => {
        const { index } = getNavData(optValue);
        let tabIndex = optValue === value ? 0 : -1;
        if (!value && index === 0) tabIndex = 0;

        return {
          active: !!active && tabIndex === 0,
          'aria-checked': optValue === value,
          checked: optValue === value,
          className: RADIO_CLASS,
          'data-index': index,
          'data-value': optValue,
          disabled,
          id: `${id}-opt-${optValue}`,
          name,
          onBlur: blur,
          onChange,
          onFocus: focus,
          role: 'radio',
          tabIndex,
          value: optValue,
        };
      },
    }),
    [active, blur, disabled, focus, labelPlacement, name, value, containerRef.current]
  ) as RadioContextValue;

  const keyboardSelect = (options: HTMLElement[], index) => {
    onChange({
      name,
      value: options[index]?.getAttribute('data-value') ?? '',
    });
    setFocusIndex(index);
  };

  const handleKeyDown = (e) => {
    const optValue = e.target.getAttribute('data-value');
    const { options, index } = getNavData(optValue);
    const childCount = options.length;

    switch (e.key) {
      case KeyNames.Down:
      case KeyNames.Right: {
        e.preventDefault();
        let nextIdx = index + 1;
        if (nextIdx === childCount) nextIdx = 0;
        keyboardSelect(options, nextIdx);
        break;
      }
      case KeyNames.Up:
      case KeyNames.Left: {
        e.preventDefault();
        let nextIdx = index - 1;
        if (nextIdx < 0) nextIdx = childCount - 1;
        keyboardSelect(options, nextIdx);
        break;
      }
      case KeyNames.Space:
        e.preventDefault();
        onChange({ name, value: optValue });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (focusIndex !== -1) {
      const options = selectOptions();
      const target = options[focusIndex];
      if (target) target.focus();
    }
  }, [focusIndex]);

  return (
    <RadioContext.Provider value={contextValue}>
      <div
        aria-labelledby={getLabelId(id)}
        className={className}
        css={css`
          :focus {
            outline: none;
          }
        `}
        ref={containerRef}
        role='radiogroup'
        onKeyDown={handleKeyDown}
        tabIndex={-1}
      >
        {children}
      </div>
    </RadioContext.Provider>
  );
};

export function useRadioContext(): RadioContextValue {
  const context = useContext(RadioContext);
  if (!context) {
    throw new Error('useRadioContext must be used inside a RadioProvider');
  }
  return context;
}
