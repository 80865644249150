import { css } from '@emotion/react';
import { Direction } from './chat-item-types';
import {
  BubbleThemeValues,
  ContainerThemeValues,
  ImageBubbleThemeValues,
  ImagePreviewModalIconThemeValues,
  ImagePreviewModalThemeValues,
  bubbleTheme,
  containerTheme,
  imageBubbleTheme,
  imagePreviewModalIconTheme,
  imagePreviewModalTheme,
  originalBubbleTheme,
  originalContainerTheme,
  originalImageBubbleTheme,
  originalImagePreviewModalIconTheme,
  originalImagePreviewModalTheme,
  DownloadIconThemeValues,
  downloadIconTheme,
} from './chat-item-theme';

const TIMESTAMP_HEIGHT_FACTOR = 3;

type ContainerStyleProps = {
  direction: Direction;
  showSenderName: boolean;
  showTimestamp: boolean;
  isFuture: boolean;
  isPaused: boolean;
  maxWidth: string;
  tagsRowMaxWidth: number;
  tagsRowHeight: number;
  hasTags: boolean;
};

export const containerStyles = (
  { spacing, timestampStyles }: ContainerThemeValues,
  {
    direction,
    showSenderName,
    showTimestamp,
    isFuture,
    maxWidth,
    tagsRowMaxWidth,
    tagsRowHeight,
    hasTags,
  }: ContainerStyleProps
) => [
  css`
    width: 100%;
    display: flex;
    align-items: end;
    gap: ${spacing(1)};
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative;
    .avatar-container {
      padding-bottom: calc(${spacing(TIMESTAMP_HEIGHT_FACTOR)} + ${tagsRowHeight}px + ${hasTags ? spacing(1) : '0px'});
      width: 32;
      transition: padding-bottom 150ms ease-in-out;
    }
    .content-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: ${direction === 'inbound' ? 'start' : 'end'};
    }
    .bubbles-container {
      max-width: ${maxWidth};
      display: flex;
      flex-direction: column;
      justify-content: start;
      gap: ${spacing(1)};
      align-items: ${direction === 'inbound' ? 'start' : 'end'};
    }
    .metadata-container {
      height: ${spacing(TIMESTAMP_HEIGHT_FACTOR)};
      display: flex;
      flex-direction: ${direction === 'inbound' ? 'row-reverse' : 'row'};
      gap: ${spacing(1)};
      align-items: center;
      p {
        margin: 0;
      }
    }
    .status-container {
      display: flex;
      flex-direction: ${direction === 'inbound' ? 'row-reverse' : 'row'};
      gap: ${spacing(0.5)};
      align-items: center;
    }
    .sender-name {
      transition: opacity 300ms ease-in-out;
      opacity: ${showSenderName ? 1 : 0};
    }
    .timestamp {
      transition: opacity 300ms ease-in-out;
      opacity: ${showTimestamp ? 1 : 0};
      color: ${isFuture ? timestampStyles.scheduledColor : timestampStyles.color};
    }
    .tags-container {
      width: ${tagsRowMaxWidth}px;
      display: flex;
      gap: ${spacing(1)};
      flex-wrap: wrap;
      justify-content: ${direction === 'inbound' ? 'start' : 'end'};
    }
    .tag-transition-container {
      padding-top: ${hasTags ? spacing(1) : '0px'};
      min-height: calc(${tagsRowHeight}px - ${spacing(1)});
      height: calc(${tagsRowHeight}px + ${hasTags ? spacing(1) : '0px'});
      transition: min-height 150ms ease-in-out, height 150ms ease-in-out, padding-top 150ms ease-in-out;
    }
    .more-actions-button {
      transition: opacity 300ms ease-in-out;
      opacity: ${showSenderName ? 1 : 0};
    }
    .more-actions-icon {
      width: ${spacing(2)};
      height: ${spacing(2)};
    }
  `,
  direction === 'inbound'
    ? css`
        flex-direction: row;
        justify-content: start;
      `
    : css`
        flex-direction: row-reverse;
        justify-content: end;
      `,
];

type BubbleStyleProps = {
  direction: Direction;
  isInteractive: boolean;
  isFuture: boolean;
  isPaused: boolean;
  error: boolean;
  maxWidth?: string;
  customTextColor: boolean;
};

export const bubbleStyles = (
  {
    spacing,
    borderRadius,
    inboundBackgroundColor,
    outboundBackgroundColor,
    errorBackgroundColor,
    focusBorderColor,
    interactiveStyles,
    scheduledStyles,
  }: BubbleThemeValues,
  { direction, isInteractive, isFuture, isPaused, customTextColor, error }: BubbleStyleProps
) => [
  css`
    list-style-type: none;
    max-width: 100%;
    padding: ${spacing(2)};
    display: flex;
    align-items: start;
    justify-content: start;
    gap: ${spacing(2)};
    border-radius: ${borderRadius};
    background-color: ${direction === 'inbound' ? inboundBackgroundColor : outboundBackgroundColor};
    white-space: pre-line;
    :focus {
      outline: 3px solid ${focusBorderColor};
    }
    .bubble-content {
      display: flex;
      flex-direction: column;
      gap: ${spacing(1)};
    }
    .bubble-text {
      user-select: ${isInteractive ? 'none' : 'auto'};
      margin: 0;
      word-break: break-word;
    }
  `,
  error &&
    css`
      background-color: ${errorBackgroundColor};
    `,
  isInteractive &&
    !isFuture &&
    css`
      cursor: pointer;
      background-color: ${interactiveStyles.backgroundColor};
      outline: 1px solid ${interactiveStyles.borderColor};
      color: ${interactiveStyles.color};
      transition: background-color 100ms ease-in-out, color 100ms ease-in-out;
      :hover {
        background-color: ${interactiveStyles.hover.backgroundColor};
      }
      :active {
        background-color: ${interactiveStyles.pressed.backgroundColor};
        color: ${interactiveStyles.pressed.color};
      }
    `,
  isFuture &&
    (isPaused
      ? css`
          background-color: ${scheduledStyles.pausedBackgroundColor};
          outline: 1px dashed ${scheduledStyles.borderColor};
          color: ${scheduledStyles.pausedColor};
        `
      : css`
          background-color: ${scheduledStyles.backgroundColor};
          color: ${scheduledStyles.color};
        `),
  !customTextColor &&
    css`
      .bubble-text {
        color: inherit;
      }
    `,
];

type ImageBubbleStyleProps = {
  isLoading: boolean;
  suppressPreview: boolean;
  isFuture: boolean;
  maxWidth?: string;
  maxHeight?: string;
};

export const imageBubbleStyles = (
  { spacing, borderRadius, backgroundColor, focusedBorderColor, scheduledStyles }: ImageBubbleThemeValues,
  { isLoading, suppressPreview, isFuture, maxWidth = '320px', maxHeight = '480px' }: ImageBubbleStyleProps
) => [
  css`
    list-style-type: none;
    overflow: hidden;
    width: 'auto';
    height: ${isLoading ? `calc(${maxHeight} * 0.66)` : 'auto'};
    max-width: ${isLoading ? 'auto' : maxWidth};
    max-height: ${maxHeight};
    position: relative;
    border-radius: ${borderRadius};
    z-index: 1;
    transition: width 300ms ease-in-out, height 300ms ease-in-out;
    background-color: ${backgroundColor};
    figure {
      margin: 0;
    }
    img {
      display: block;
      max-width: 100%;
    }
    :focus {
      outline: 3px solid ${focusedBorderColor};
    }
  `,
  !suppressPreview &&
    css`
      cursor: ${isLoading ? 'progress' : 'pointer'};
    `,
  isLoading &&
    css`
      figure {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: ${spacing(2)};
        height: 100%;
        padding: ${spacing(2)};
      }
      p {
        margin: 0;
      }
    `,
  isFuture &&
    css`
      background-color: ${scheduledStyles.backgroundColor};
      color: ${scheduledStyles.color};
    `,
];

export const imagePreviewModalStyles = ({ borderRadius }: ImagePreviewModalThemeValues) => css`
  padding: 0;
  border-radius: ${borderRadius};
  max-width: unset;
  img {
    max-height: 90vh;
    max-width: 70vw;
    padding: 0;
  }
`;

export const imagePreviewModalIconStyles = ({ spacing, backgroundColor }: ImagePreviewModalIconThemeValues) => css`
  position: absolute;
  top: -${spacing(4)};
  right: 0;
  background-color: ${backgroundColor};
`;

export const downloadIconStyle = ({ spacing, backgroundColor }: DownloadIconThemeValues) => css`
  position: absolute;
  top: ${spacing(-4)};
  right: ${spacing(4)};
  background-color: ${backgroundColor};
`;

export const ChatItemStyles = {
  ChatItem: {
    bubble: (bubbleProps: BubbleStyleProps) => bubbleStyles(bubbleTheme, bubbleProps),
    container: (containerProps: ContainerStyleProps) => containerStyles(containerTheme, containerProps),
    imageBubbleStyles: (imageBubbleProps: ImageBubbleStyleProps) =>
      imageBubbleStyles(imageBubbleTheme, imageBubbleProps),
    imagePreviewModalStyles: imagePreviewModalStyles(imagePreviewModalTheme),
    imagePreviewModalIconStyles: imagePreviewModalIconStyles(imagePreviewModalIconTheme),
    downloadIconStyle: downloadIconStyle(downloadIconTheme),
  },
};

export const ChatItemStylesOriginal = {
  ChatItem: {
    bubble: (bubbleProps: BubbleStyleProps) => bubbleStyles(originalBubbleTheme, bubbleProps),
    container: (containerProps: ContainerStyleProps) => containerStyles(originalContainerTheme, containerProps),
    imageBubbleStyles: (imageBubbleProps: ImageBubbleStyleProps) =>
      imageBubbleStyles(originalImageBubbleTheme, imageBubbleProps),
    imagePreviewModalStyles: imagePreviewModalStyles(originalImagePreviewModalTheme),
    imagePreviewModalIconStyles: imagePreviewModalIconStyles(originalImagePreviewModalIconTheme),
    downloadIconStyle: downloadIconStyle(downloadIconTheme),
  },
};
