import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import React, { useCallback } from 'react';
import { DynamicFieldAction, DynamicFieldAttributes } from '../../molecules/dynamic-field-action';
import { useBeautifulMentions } from 'lexical-beautiful-mentions';
import { DynamicFieldPlugin } from '../../plugins/dynamic-field';
import { $getSelection, $createTextNode } from 'lexical';
import { $isDynamicFieldNode } from '../../nodes';
import { DYNAMIC_FIELD_TRIGGER } from '../../constants';

type Props = {
  buttonLabel?: React.ReactNode;
  fields: DynamicFieldAttributes[];
  noTransform?: boolean;
};
export const DynamicField = ({ buttonLabel, noTransform, fields }: Props) => {
  const { insertMention } = useBeautifulMentions();
  const [editor] = useLexicalComposerContext();

  const insertEmptySpace = useCallback(() => {
    editor.update(() => {
      const selection = $getSelection();

      if (selection !== null) {
        const space = $createTextNode(' ');
        selection.insertNodes([space]);
      }
    });
  }, [editor]);

  return (
    <>
      <DynamicFieldAction
        label={buttonLabel}
        fields={fields}
        onSelect={(selectedDynamicField) => {
          editor.update(() => {
            const selection = $getSelection();

            if (selection !== null) {
              selection.getNodes().forEach((node) => {
                if ($isDynamicFieldNode(node)) {
                  node.remove();
                }
              });
            }

            insertMention({
              trigger: DYNAMIC_FIELD_TRIGGER,
              value: selectedDynamicField.symbol,
              data: {
                label: selectedDynamicField.label,
                val: selectedDynamicField.val ?? '',
                noTransform: !!noTransform,
              },
            });
          });

          insertEmptySpace();
        }}
      />
      <DynamicFieldPlugin fields={fields} noTransform={noTransform} />
    </>
  );
};
