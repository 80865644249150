import { memo } from 'react';
import { theme } from '@frontend/theme';
import { Name } from './name';
import { Rank } from './rank';

export type MinAndMax = {
  highestValue: number;
  highestValueNames: string[];
  lowestValue: number;
  lowestValueNames: string[];
};

type NameHeaderProps = {
  className?: string;
  columnId: string;
  highestMetric?: string;
  lowestMetric?: string;
  minAndMax?: MinAndMax | null;
  name: string;
  style?: React.CSSProperties;
};

export const NameHeader = memo(
  ({ className = '', columnId, highestMetric, lowestMetric, minAndMax, name, style = {} }: NameHeaderProps) => {
    const hasHighestRank = !!highestMetric && minAndMax?.highestValueNames.includes(name);
    const hasLowestRank = !!lowestMetric && minAndMax?.lowestValueNames.includes(name);
    const nameHasRank = !!hasHighestRank || !!hasLowestRank;

    return (
      <div
        className={`name ${className}`.trim()}
        style={{
          height: nameHasRank ? theme.spacing(5) : 'auto',
          ...style,
        }}
      >
        <Name size='medium' value={name} />
        {(highestMetric === columnId || lowestMetric === columnId) && (
          <>
            {highestMetric && hasHighestRank && <Rank metric={highestMetric} rank='highest' />}
            {lowestMetric && hasLowestRank && <Rank metric={lowestMetric} rank='lowest' />}
          </>
        )}
      </div>
    );
  }
);

NameHeader.displayName = 'NameHeader';
