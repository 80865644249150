import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../component-theme/theme-types';

export type ListRowThemeValues = {
  borderColor: WeaveThemeValues['Color'];
  primaryBlue: WeaveThemeValues['Color'];
  spacing: WeaveThemeValues['Spacing'];
  hoverBackground: WeaveThemeValues['Color'];
  selectedBackground: WeaveThemeValues['Color'];
};

export const listRowTheme: ListRowThemeValues = {
  borderColor: theme.colors.neutral20,
  primaryBlue: theme.colors.primary50,
  spacing: theme.spacing,
  hoverBackground: theme.colors.neutral5,
  selectedBackground: theme.colors.neutral5,
};

export const listRowThemeOriginal: ListRowThemeValues = {
  borderColor: themeOriginal.colors.gray300,
  primaryBlue: themeOriginal.colors.weaveBlue,
  spacing: themeOriginal.spacing,
  hoverBackground: themeOriginal.colors.gray200,
  selectedBackground: themeOriginal.colors.gray100,
};
