import { contextFactory } from '../../hooks';
import type { ThumbAction, ThumbState } from './reducer';
import type { RangeValue, ValueDisplayMode, SingleValue } from './types';

interface SliderContextType<T extends boolean = false> {
  min: number;
  max: number;
  step: number;
  value: T extends true ? RangeValue : SingleValue;
  isRange?: T;
  valueDisplay: ValueDisplayMode;
  formatValue?: (value: number) => string;
  updateValue: (newValue: number, index?: 0 | 1) => void;
  getPercentage: (value: number) => number;
  thumbState: ThumbState;
  thumbDispatch: React.Dispatch<ThumbAction>;
}

const [SliderContext, useSliderContext] = contextFactory<SliderContextType<any>>();

interface SliderProps<T extends boolean = false> {
  min: number;
  max: number;
  step: number;
  value?: T extends true ? RangeValue : SingleValue;
  defaultValue?: T extends true ? RangeValue : SingleValue;
  onChange?: (value: T extends true ? RangeValue : SingleValue) => void;
  isRange?: T;
  valueDisplay?: ValueDisplayMode;
  ariaLabel?: string;
  formatValue?: (value: number) => string;
}

export { SliderContext, useSliderContext, SliderContextType, SliderProps };
