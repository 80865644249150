import { Icon, IconProps } from '../Icon.component';

export const MultiLocationSmall: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={16} {...props} viewBox='0 0 16 16' noFill>
    <path
      d='M13.4999 8.15997C13.4999 10.0147 10.1416 13.7572 10.1416 13.7572C10.1416 13.7572 6.78326 10.0147 6.78326 8.15997C6.78326 6.30521 8.28684 4.80164 10.1416 4.80164C11.9963 4.80164 13.4999 6.30521 13.4999 8.15997Z'
      stroke='currentColor'
      strokeWidth='2'
    />
    <path
      d='M9.21672 5.60113C9.21672 3.74637 7.71314 2.2428 5.85839 2.2428C4.00364 2.2428 2.50006 3.74637 2.50006 5.60113C2.50006 7.45588 5.85839 11.1983 5.85839 11.1983C5.85839 11.1983 6.69662 10.2767 7.0322 9.87018'
      stroke='currentColor'
      strokeWidth='2'
    />
    <circle cx='10.141' cy='8.15985' r='1.11944' fill='currentColor' />
    <circle cx='5.85827' cy='5.60125' r='1.11944' fill='currentColor' />
  </Icon>
);
