import { StateCreator } from 'zustand';
import { createStoreWithSubscribe, createShallowStore } from '@frontend/store';
import { Device } from '@weave/schema-gen-ts/dist/schemas/phone/user/v1/user_service.pb';

export type PhoneConfigStore = {
  phoneConfig: Device | undefined;
  setPhoneConfig: (phoneConfig: Device | undefined) => void;
};

const storeDefinition: StateCreator<
  PhoneConfigStore,
  [['zustand/subscribeWithSelector', never], ['zimmer', never], ['zustand/devtools', never]]
> = (set) => ({
  phoneConfig: undefined,
  setPhoneConfig: (phoneConfig: Device | undefined) => {
    set({ phoneConfig });
  },
});

export const usePhoneConfigStore = createStoreWithSubscribe<PhoneConfigStore>(storeDefinition, {
  name: 'PhoneConfigStore',
  trace: true,
  serialize: {
    options: {
      map: true,
    },
  },
});

export const usePhoneConfigShallowStore = createShallowStore(usePhoneConfigStore);
