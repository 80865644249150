import { PaymentMethod } from '@frontend/api-payment-plans';
import { contextFactory } from '@frontend/design-system';

interface CardACHSelectionContextType {
  addPaymentMethodEnabled: boolean;
  achOnFileEnabled: boolean;
  achList: PaymentMethod[];
  addACHEnabled: boolean;
  cardList: PaymentMethod[];
  disableACHPaymentMethods: boolean;
  disableACHPaymentMessage?: string;
  isLoading: boolean;
  locationId: string;
  patientId: string;
  selectedPM?: PaymentMethod;
  isResendVerificationACHMode?: boolean;
  setSelectedPM: (pm?: PaymentMethod) => void;
  onDeleteAction: (pm: PaymentMethod) => void;
  openAddCard: () => void;
  closeAddCard: () => void;
  openAddACH: () => void;
  closeAddACH: () => void;
  openResendACHVerification: () => void;
}

export const [CardACHSelectionContext, useCardACHSelectionContext] = contextFactory<CardACHSelectionContextType>();
