import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { InvoicesQuickFillBar } from '../../Invoices';
import { QuickFilters } from './quick-filters';

const styles = {
  dateFilters: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${theme.spacing(3)};
    flex-wrap: wrap;
    gap: ${theme.spacing(3)};
  `,
};

export const DateFilters = () => {
  return (
    <section css={styles.dateFilters}>
      <QuickFilters />
      <InvoicesQuickFillBar showAllTimeButton={true} enableDropDownFilter={true} />
    </section>
  );
};
