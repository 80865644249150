import { SerializedStyles } from '@emotion/react';
import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import { FontColorType } from '../../helpers';
import { getFontColorStyle } from '../../helpers';
import type { FontWeight, TextAlignment, TextSize } from './text-style-helpers';
import { getFontFamilyStyle, getFontSizeStyle, getFontWeightStyle, getTextAlignStyle } from './text-style-helpers';

export type TextStyleProps = {
  size: TextSize;
  color: FontColorType;
  textAlign?: TextAlignment;
  weight: FontWeight;
};

export type TextThemeValues = {
  fontFamily: SerializedStyles;
  fontSize: SerializedStyles;
  fontColor: SerializedStyles;
  textAlignStyle: SerializedStyles | null;
  fontWeight: SerializedStyles;
};

export const getTextTheme = ({ size, color, textAlign, weight }: TextStyleProps) => ({
  fontFamily: getFontFamilyStyle(theme),
  fontSize: getFontSizeStyle(size, theme),
  fontColor: getFontColorStyle(color, theme),
  textAlignStyle: textAlign ? getTextAlignStyle(textAlign) : null,
  fontWeight: getFontWeightStyle(weight, theme),
});

export const getTextThemeOriginal = ({ size, color, textAlign, weight }: TextStyleProps) => ({
  fontFamily: getFontFamilyStyle(themeOriginal),
  fontSize: getFontSizeStyle(size, themeOriginal),
  fontColor: getFontColorStyle(color, themeOriginal),
  textAlignStyle: textAlign ? getTextAlignStyle(textAlign) : null,
  fontWeight: getFontWeightStyle(weight, themeOriginal),
});
