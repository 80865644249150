import { DeepReadonly } from '@frontend/types';
import { DashboardConfig } from './utils';

/**
 * **DashboardConfig** is a 2D array of strings that represents the layout of the dashboard.
 * Each string is a unique identifier for a dashboard module.
 *
 * The first dimension of the array represents the rows of the dashboard.
 * The second dimension of the array represents the modules in each row.
 * This way we can have a flexible layout that can be easily changed.
 *
 * Each dashboard module should have a unique identifier that is used to render the module.
 * This configuration uses a grid like layout, where each row can have a different number of modules.
 * For example, if the first row has 3 modules, they each get 1/3 of the row. So the space is divided equally.
 * If want to have a module take up more space, you can just add it multiple times in the same row.
 * @example
 * ```ts
 * const config = [
 * ['module1','module1','module1','module2','module3'] // module1 takes up 3/5 of the row, module2 takes up 1/5, module3 takes up 1/5
 * ['module4','module5','module6'] // each module takes up 1/3 of the row
 * ['module7','module8'] // each module takes up 1/2 of the row
 * ]
 * ```
 *
 *
 */
export const config = [
  /**------------------------------------ */
  /*Row 0*/ ['all-apps'],
  /*Row 1*/ ['analytics'],
  /*Row 2*/ ['quick-actions'],
  /*Row 3*/ ['schedule'],
  /*Row 4*/ ['payments'],
  /*Row 5*/ ['onboarding', 'training'],
  /**------------------------------------ */
] as const satisfies DeepReadonly<DashboardConfig>;

export type ReadonlyConfig = typeof config;
export type DashboardConfigIds = (typeof config)[number][number];
