import type { DashboardConfigIds, ReadonlyConfig } from './dashboard-config';

export type DashboardConfig = string[][];
export type DashboardConfigMap = { [K in DashboardConfigIds]: number };

export function generateConfigMap(config: ReadonlyConfig): DashboardConfigMap {
  return config.reduce<DashboardConfigMap>((acc, row) => {
    const countMap: Partial<DashboardConfigMap> = {};
    row.forEach((element) => {
      countMap[element] = (countMap[element] || 0) + 1;
    });
    row.forEach((element) => {
      const totalOccurrences = countMap[element] as number;
      const space = row.length / totalOccurrences;
      acc[element as DashboardConfigIds] = space;
    });
    return acc;
  }, {} as DashboardConfigMap);
}
