import { css } from '@emotion/react';
import type { FloatingLabelStyleValues } from './floating-label-theme';
import { getFloatingLabelTheme, getFloatingLabelThemeOriginal } from './floating-label-theme';
import { NonFloatingLabelProps } from './types';

type FloatingLabelStyleProps = Pick<NonFloatingLabelProps, 'disabled' | 'hasError'>;

const styles = ({ fontColor, fontSize, spacing }: FloatingLabelStyleValues) => css`
  display: inline-block;
  color: ${fontColor};
  font-size: ${fontSize(16)};
  margin-bottom: ${spacing(0.5)};
`;

export const NonFloatingLabelStyles = {
  NonFloatingLabel: (nonFloatingLabelProps: FloatingLabelStyleProps) =>
    styles(getFloatingLabelTheme(nonFloatingLabelProps)),
};

export const NonFloatingLabelStylesOriginal: typeof NonFloatingLabelStyles = {
  NonFloatingLabel: (nonFloatingLabelProps: FloatingLabelStyleProps) =>
    styles(getFloatingLabelThemeOriginal(nonFloatingLabelProps)),
};
