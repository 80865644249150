import { css } from '@emotion/react';
import {
  BannerNotificationThemeValues,
  bannerNotificationTheme,
  bannerNotificationThemeOriginal,
} from './banner.theme';
import { BannerProps } from './banner-notification.component';
import { theme } from '@frontend/theme';

type BannerNotificationStylesProps = {
  status: BannerProps['status'];
  fullWidth: boolean;
};

const style = (themeValue: BannerNotificationThemeValues, { fullWidth, status }: BannerNotificationStylesProps) => css`
  max-width: ${fullWidth ? '100%' : '700px'};
  background-color: ${themeValue.colors[status]};
  color: ${themeValue.colors.textColor};
  display: flex;
  gap: ${theme.spacing(2)};
  padding: ${theme.spacing(2)};
  border: 1px solid ${themeValue.borderColor[status]};
  border-radius: ${theme.borderRadius.medium};
  align-items: center;
`;

export const BannerNotificationStyles = {
  BannerNotification: (props) => style(bannerNotificationTheme, props),
};

export const BannerNotificationStylesOriginal = {
  BannerNotification: (props) => style(bannerNotificationThemeOriginal, props),
};
