import { useStyles } from '../../../../use-styles';
import { memo } from 'react';
import { NakedButton } from '../../../../naked';
import { Text } from '../../../text';
import { useRadioContext } from '../../providers';
import { cx } from '@emotion/css';

type Props = {
  title: string;
  description?: string;
  value: string;
  trackingId?: string;
  className?: string;
};

export const RadioCardOption = memo(({ title, description, trackingId, value, className }: Props) => {
  const { getOptionProps } = useRadioContext();
  const { active, checked, onChange, ...optProps } = getOptionProps(value);
  const radioStyles = useStyles('Radio', { active, checked, disabled: optProps.disabled });
  const radioOptionStyles = useStyles('RadioCard');
  const radioOptionContentStyles = useStyles('RadioCardContent');
  const radioOptionIconStyles = useStyles('RadioCardIcon');
  return (
    <NakedButton
      css={radioOptionStyles}
      {...optProps}
      className={cx(optProps.className, className)}
      onClick={onChange}
      type='button'
      trackingId={trackingId}
    >
      <div css={[radioStyles, radioOptionIconStyles]} />
      <div css={[radioOptionContentStyles]}>
        <Text as='span' size='medium' weight='bold'>
          {title}
        </Text>
        {description && (
          <Text as='span' size='small' color='light'>
            {description}
          </Text>
        )}
      </div>
    </NakedButton>
  );
});
