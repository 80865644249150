import { Fragment, memo, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { NakedUl, Text, TextLink } from '@frontend/design-system';
import { useChartContext } from '../chart.provider';
import { useIsMobile } from '../hooks';
import { Card } from './card';
import { ColumnHeaders } from './column-headers';
import { NameHeader } from './name-header';
import { ScrollButtons } from './scroll-buttons';
import { StickyColumn } from './sticky-column';
import { styles } from './styles';
import { LeaderboardProps, SortType, Sorted } from './types';

// TODO :: calculate it based on the content in one of the next PR
const MAX_HEIGHT = 345 + 42; // 345 is the height of 3 rows and 42 is the height of column headers

const sortByKey = (data: LeaderboardProps['data'], key: string, sortType: SortType) => {
  return data.slice().sort((a, b) => {
    let aValue, bValue;

    switch (key) {
      case 'name':
        aValue = a.name;
        bValue = b.name;
        break;

      default:
        aValue = a.values[key]?.primaryValue ?? 0;
        bValue = b.values[key]?.primaryValue ?? 0;
        break;
    }

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return sortType === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
    } else if (typeof aValue === 'number' && typeof bValue === 'number') {
      return sortType === 'asc' ? aValue - bValue : bValue - aValue;
    }

    return 0;
  });
};

export const Leaderboard = memo(
  ({ appearance = {}, columnHeaderLabels, data = [], defaultSort, onClick, trackingIdBase }: LeaderboardProps) => {
    const { comparisonType = 'rows', highestMetric, lowestMetric, minCardWidth = 180 } = appearance;
    const { t } = useTranslation('analytics');
    const isMobile = useIsMobile();
    const { isLoading } = useChartContext();
    const ulRef = useRef<HTMLUListElement>(null);
    const [showMore, setShowMore] = useState<boolean | null>(null);
    const [isShowingAllRows, setIsShowingAllRows] = useState<boolean>(isMobile);
    const [columnsOrder, setColumnsOrder] = useState<string[]>([]);
    const [sorted, setSorted] = useState<Sorted | null>(defaultSort ?? null);

    const isRowComparison = comparisonType === 'rows';

    const minAndMax = useMemo(() => {
      if (!highestMetric && !lowestMetric) return null;

      return data.reduce(
        (acc, { name, values }) => {
          const highestValue = highestMetric ? values[highestMetric]?.primaryValue ?? 0 : 0;
          const lowestValue = lowestMetric ? values[lowestMetric]?.primaryValue ?? 0 : 0;

          if (highestValue > acc.highestValue) {
            acc.highestValue = highestValue;
            acc.highestValueNames = [name];
          } else if (highestValue === acc.highestValue) {
            acc.highestValueNames.push(name);
          }

          if (lowestValue < acc.lowestValue) {
            acc.lowestValue = lowestValue;
            acc.lowestValueNames = [name];
          } else if (lowestValue === acc.lowestValue) {
            acc.lowestValueNames.push(name);
          }

          return acc;
        },
        {
          highestValue: 0,
          highestValueNames: [] as string[],
          lowestValue: Infinity,
          lowestValueNames: [] as string[],
        }
      );
    }, [highestMetric, lowestMetric, data]);

    const handleShowMore = useCallback(() => {
      if (isShowingAllRows) {
        // If already showing all rows, hide as per max height
        ulRef.current?.style.setProperty('max-height', `${MAX_HEIGHT}px`);
      } else {
        // If not showing all rows, show all
        ulRef.current?.style.setProperty('max-height', `${ulRef.current.scrollHeight}px`);
      }

      // Toggle the state
      setIsShowingAllRows(!isShowingAllRows);
    }, [isShowingAllRows]);

    const handleSortClick = useCallback(
      (id: string) => {
        // Cancel the sort if the column is sorted in ascending order
        if (sorted?.key === id && sorted.type === 'asc') {
          setSorted(null);
          return;
        }

        // If already sorted by the same column, toggle the sort order
        if (sorted?.key === id) {
          setSorted({ key: id, type: sorted.type === 'asc' ? 'desc' : 'asc' });
          return;
        }

        // Sort by the given column
        setSorted({ key: id, type: 'desc' });
      },
      [sorted]
    );

    const sortedData = useMemo(() => {
      if (!sorted) return data;
      return sortByKey(data, sorted.key, sorted.type);
    }, [data, sorted]);

    useLayoutEffect(() => {
      // Set initial columns order based on the data
      setColumnsOrder(Object.keys(sortedData[0]?.values ?? {}));
      ulRef.current?.scrollTo({
        left: 0,
      });

      setShowMore(!isMobile && data.length > 3);
      if (isShowingAllRows || isMobile) {
        if (isMobile) {
          ulRef.current?.style.setProperty('max-height', 'none');
          setIsShowingAllRows(true);
          return;
        }
        ulRef.current?.style.setProperty('max-height', `${ulRef.current.scrollHeight}px`);
      }
    }, [data.length, minCardWidth, ulRef.current?.childElementCount, isMobile]);

    return (
      <div css={styles.mainWrapper}>
        <NakedUl
          className={isRowComparison ? 'rows-comparison-ul' : 'columns-comparison-ul'}
          css={styles.ul}
          ref={ulRef}
          style={{ maxHeight: MAX_HEIGHT }}
        >
          {!!data.length && ((isRowComparison && !isMobile) || !isRowComparison) && (
            <ColumnHeaders
              columnsOrder={columnsOrder}
              labels={columnHeaderLabels}
              onSortClick={data.length > 1 ? handleSortClick : undefined}
              showNameColumn={!isMobile}
              sorted={sorted}
              trackingIdBase={trackingIdBase}
            />
          )}
          {sortedData.map(({ id, name, values }, index) => {
            return (
              <li
                className={isRowComparison ? 'rows-comparison-li' : 'columns-comparison-li'}
                css={styles.leaderboardRow}
                key={name}
              >
                {!isMobile && (
                  <StickyColumn
                    highestMetric={highestMetric}
                    lowestMetric={lowestMetric}
                    minAndMax={minAndMax}
                    name={name}
                    serialNo={index + 1}
                  />
                )}
                <div className='data-cards-wrapper' css={styles.dataCardsWrapper}>
                  {columnsOrder.map((key, index) => {
                    const { onClick: cardClick, ...rest } = values[key] || {};

                    return (
                      <Fragment key={key}>
                        {isMobile && isRowComparison && index === 0 && (
                          <NameHeader
                            className='card-space'
                            columnId={key}
                            highestMetric={highestMetric}
                            lowestMetric={lowestMetric}
                            minAndMax={minAndMax}
                            name={name}
                          />
                        )}
                        <div className='card-space' key={key} style={{ minWidth: minCardWidth }}>
                          {isMobile &&
                            (isRowComparison ? (
                              <Text color='subdued' size='medium'>
                                {columnHeaderLabels?.[key] ?? key}
                              </Text>
                            ) : (
                              <NameHeader
                                columnId={key}
                                highestMetric={highestMetric}
                                lowestMetric={lowestMetric}
                                minAndMax={minAndMax}
                                name={name}
                                style={{ marginBottom: theme.spacing(1) }}
                              />
                            ))}
                          <Card
                            {...rest}
                            columnId={key}
                            rowId={id ?? name}
                            onClick={cardClick ?? onClick}
                            trackingIdBase={trackingIdBase}
                          />
                        </div>
                      </Fragment>
                    );
                  })}
                </div>
              </li>
            );
          })}
        </NakedUl>
        {showMore && (
          <TextLink
            className='toggle-more'
            onClick={handleShowMore}
            trackingId={`${trackingIdBase}-toggle-show-more`}
            weight='bold'
          >
            {isShowingAllRows ? t('Show Less') : t('Show More')}
            <Icon name={isShowingAllRows ? 'caret-up-small' : 'caret-down-small'} />
          </TextLink>
        )}

        {isMobile &&
          !isLoading &&
          ((!isRowComparison && columnsOrder.length > 2) || (isRowComparison && data.length > 2)) && (
            <ScrollButtons
              columnsOrder={columnsOrder}
              dataLength={data.length}
              isRowComparison={isRowComparison}
              scrollView={ulRef.current}
            />
          )}
      </div>
    );
  }
);

Leaderboard.displayName = 'Leaderboard';
