import { ComponentPropsWithoutRef } from 'react';
import { NakedButton } from '../../../naked';
import { mergeClasses } from '../../../helpers';
import { useCalendarState } from '../provider';
import type { BaseAtomProps } from './calendar-atom.types';
import { formatDate } from '../../../helpers';

type Props = BaseAtomProps & {
  month: number;
  year: number;
};

type CalendarMonthsProps = Props & Omit<ComponentPropsWithoutRef<'div'>, keyof Props>;

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const CalendarMonths = ({ className, month, year, ...rest }: CalendarMonthsProps) => {
  const { getMonthButtonProps } = useCalendarState();
  return (
    <div className={mergeClasses('calendar__months__years', className)} {...rest}>
      {months.map((month, i) => (
        <NakedButton
          {...getMonthButtonProps(formatDate(new Date(year, i), 'YYYY-MM'), i)}
          key={i}
          className='cal__months'
          type='button'
        >
          {month}
        </NakedButton>
      ))}
    </div>
  );
};
