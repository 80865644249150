import { mergeClasses } from '../../../helpers';
import { useCalendarState } from '../provider';
import type { BaseAtomProps } from './calendar-atom.types';

type CalendarDisplayProps = BaseAtomProps & {
  className?: string;
  children: React.ReactNode;
};

export const CalendarContainer = ({ className, children, ...rest }: CalendarDisplayProps) => {
  const { calendarContainerProps } = useCalendarState();
  return (
    <div className={mergeClasses('cal__container', className)} {...calendarContainerProps} {...rest}>
      {children}
    </div>
  );
};
