import type { InputProps } from '../types';
import { currencyInputHelpers } from './utils';
import { KeyboardEvent } from 'react';
import { safeSetSelection } from '../../../../helpers/text-mask';

export const CurrencyInput = (props: InputProps<'money'>) => {
  const { onChange, onKeyDown, value = '' } = props;

  const callOnChangeProp = (currencyValue: string) =>
    onChange({
      name: props.name,
      value: currencyValue,
    });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //@ts-ignore typescript is giving error on the data property of the nativeEvent
    const { currencyValue, caretPosition } = transformCurrencyValue(e.target, e.nativeEvent.data ?? '');
    callOnChangeProp(currencyValue);
    setTimeout(() => safeSetSelection(e.target, caretPosition));
  };

  const handleKeyDown = <T extends Element>(event: KeyboardEvent<T>) => {
    onKeyDown?.(event);
  };

  const transformCurrencyValue = (inputElem: HTMLInputElement, pressedKey: string) => {
    const typedValue = inputElem.value;
    const currencyValue = currencyInputHelpers.transformCurrencyValue(typedValue);
    const caretPosition = currencyInputHelpers.getNewCaretPosition(
      typedValue,
      currencyValue,
      pressedKey,
      inputElem.selectionStart ?? 0
    );
    return { currencyValue, caretPosition };
  };

  const inputProps = {
    ...props,
    onChange: handleChange,
    onKeyDown: handleKeyDown,
    autoComplete: 'off',
    pattern: '[0-9]*',
    type: 'text',
    value: value.trim() ? currencyInputHelpers.addDollarAndCommas(value) : '',
  };

  return <input {...inputProps} />;
};
