import { css } from '@emotion/react';
import type { ModalBackdropThemeValue } from './modal-backdrop-theme';

export const modalBackdropStyles = ({ backdropFilter }: ModalBackdropThemeValue) => css`
  ${backdropFilter && `backdrop-filter: blur(8px);`}
  background-color: rgba(0, 33, 82, .3);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  -webkit-tap-highlight-color: transparent;
`;
