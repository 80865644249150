import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../../component-theme/theme-types';
import { CommonFieldAtomProps } from './../atoms/types';
import { getThemeFontColor, getThemeOriginalFontColor } from './utils';

export type FieldWrapperStyleValues = {
  borderColor: WeaveThemeValues['Color'];
  borderRadius: WeaveThemeValues['BorderRadius'];
  color: WeaveThemeValues['Color'];
  contextualBorderColor: WeaveThemeValues['Color'];
  disabledColor: WeaveThemeValues['Color'];
  fontFamily: WeaveThemeValues['FontFamily'];
  fontSize: WeaveThemeValues['FontSize'];
  spacing: WeaveThemeValues['Spacing'];
  placeholderColor: WeaveThemeValues['Color'];
};

export interface FieldWrapperProps extends Omit<CommonFieldAtomProps, 'id'> {
  className?: string;
  mode?: 'light' | 'dark';
  active?: boolean;
  disabled?: boolean;
  hasPadding?: boolean;
  hasError?: boolean;
}

type GetBorderColorProps = {
  active?: boolean;
  disabled?: boolean;
  hasError?: boolean;
};

const getBorderColorThemeOriginal = ({ active, hasError, disabled }: GetBorderColorProps) => {
  if (disabled) return themeOriginal.colors.gray[300];
  if (hasError) return themeOriginal.colors.error;
  if (active) return themeOriginal.colors.weaveBlue;
  return themeOriginal.colors.gray[400];
};

const getBorderColorTheme = ({ active, hasError, disabled }: GetBorderColorProps) => {
  if (disabled) return theme.colors.neutral20;
  if (hasError) return theme.colors.status.critical;
  if (active) return theme.colors.primary50;
  return theme.colors.neutral30;
};

export const getFieldWrapperTheme = ({ active, disabled, hasError }: GetBorderColorProps) => ({
  borderColor: theme.colors.neutral30,
  contextualBorderColor: getBorderColorTheme({ active, disabled, hasError }),
  borderRadius: theme.borderRadius.small,
  color: theme.colors.neutral90,
  disabledColor: theme.colors.neutral20,
  fontSize: theme.fontSize,
  fontFamily: theme.font.family,
  spacing: theme.spacing,
  placeholderColor: getThemeFontColor({ theme, disabled, hasError }),
});

export const getFieldWrapperThemeOriginal = ({ active, disabled, hasError }: GetBorderColorProps) => ({
  borderColor: themeOriginal.colors.gray[400],
  contextualBorderColor: getBorderColorThemeOriginal({
    active,
    disabled,
    hasError,
  }),
  borderRadius: themeOriginal.borderRadius.small,
  color: themeOriginal.colors.gray[600],
  disabledColor: themeOriginal.colors.gray[400],
  fontFamily: themeOriginal.font.family,
  fontSize: themeOriginal.fontSize,
  spacing: themeOriginal.spacing,
  placeholderColor: getThemeOriginalFontColor({ theme: themeOriginal, disabled, hasError }),
});
