import { contextFactory } from '../../hooks';
import { INDEX_ROW_ERRORS } from './context-errors';

export interface IndexRowProps {
  disabled?: boolean;
  caretIcon?: React.ReactNode;
  children: React.ReactNode;
}

interface IndexRowContext {
  disabled?: boolean;
  caretIcon?: React.ReactNode;
}

export const [IndexRowContext, useIndexRow] = contextFactory<IndexRowContext>(INDEX_ROW_ERRORS.indexRow);

export function IndexRowProvider({ children, disabled, caretIcon }: IndexRowProps) {
  return (
    <IndexRowContext.Provider
      value={{
        disabled,
        caretIcon,
      }}
    >
      {children}
    </IndexRowContext.Provider>
  );
}
