import dayjs from 'dayjs';

export const formatTimestamp = (date: Date, isFuture: boolean) => {
  const prefixes = {
    sameDay: isFuture ? '[today at]' : '[Today at]',
    nextDay: isFuture ? '[tomorrow at]' : '[Tomorrow at]',
    lastDay: isFuture ? '[yesterday at]' : '[Yesterday at]',
  };
  const formattedTimestamp = dayjs(date).calendar(undefined, {
    sameDay: `${prefixes.sameDay} h:mm A`,
    nextDay: `${prefixes.nextDay} h:mm A`,
    nextWeek: 'dddd [at] h:mm A',
    lastDay: `${prefixes.lastDay} h:mm A`,
    lastWeek: 'dddd [at] h:mm A',
    sameElse: 'dddd, MMMM D [at] h:mm A',
  });
  if (isFuture) {
    return 'Scheduled for ' + formattedTimestamp;
  }
  return formattedTimestamp;
};
