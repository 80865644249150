import { css } from '@emotion/react';
import type { WeaveTheme } from '@frontend/theme';
import type { WeaveTheme as WeaveThemeOriginal } from '@frontend/theme-original';

export type TextSize = 'large' | 'medium' | 'small' | 'inherit';

export const getFontSizeStyle = (size: TextSize, theme: WeaveTheme | WeaveThemeOriginal) => {
  return css`
    font-size: ${theme.font.size[size] ?? size};
    line-height: ${theme.font.lineHeight};
  `;
};

export type TextAlignment = 'left' | 'center' | 'right' | 'inherit';

export const getTextAlignStyle = (align: TextAlignment) => css`
  text-align: ${align};
`;

export const getFontFamilyStyle = (theme: WeaveTheme | WeaveThemeOriginal) =>
  css`
    font-family: ${theme.font.family};
  `;

export type FontWeight = 'regular' | 'bold' | 'inherit' | 'light' | 'medium' | 'semibold';

export const getFontWeightStyle = (weight: FontWeight, theme: WeaveTheme | WeaveThemeOriginal) => css`
  font-weight: ${theme.font.weight[weight]};
`;
