/**
 * NOTE: For now we're just going to use plaing hex colors, as not
 * all colors have theme values.
 */

export const userColors = [
  { name: 'Black', fill: '#202328', text: '#FFFFFF' },
  { name: 'Grey', fill: '#A1A8B0', text: '#FFFFFF' },
  { name: 'Dark Red', fill: '#BD0F0F', text: '#FFFFFF' },
  { name: 'Red', fill: '#FF584C', text: '#FFFFFF' },
  { name: 'Dark Seaweed', fill: '#018479', text: '#FFFFFF' },
  { name: 'Seaweed', fill: '#22CBC1', text: '#FFFFFF' },
  { name: 'Brown', fill: '#6E4302', text: '#FFFFFF' },
  { name: 'Yellow', fill: '#F2BE04', text: '#FFFFFF' },
  { name: 'Dark Eggplant', fill: '#7D2099', text: '#FFFFFF' },
  { name: 'Eggplant', fill: '#C467DE', text: '#FFFFFF' },
  { name: 'Dark Green', fill: '#0C6734', text: '#FFFFFF' },
  { name: 'Green', fill: '#13C35F', text: '#FFFFFF' },
  { name: 'Dark Blue', fill: '#0045AD', text: '#FFFFFF' },
  { name: 'Blue', fill: '#146EF5', text: '#FFFFFF' },
  { name: 'Dark Tangerine', fill: '#A34900', text: '#FFFFFF' },
  { name: 'Tangerine', fill: '#F06F05', text: '#FFFFFF' },
  { name: 'Dark Rose', fill: '#BF0D69', text: '#FFFFFF' },
  { name: 'Rose', fill: '#F358A8', text: '#FFFFFF' },
  { name: 'Dark Indigo', fill: '#3E32BD', text: '#FFFFFF' },
  { name: 'Indigo', fill: '#8788F8', text: '#FFFFFF' },
];

export const contactColors = [
  { name: 'Grey', fill: '#E7EBEE', text: '#202328' },
  { name: 'Red', fill: '#FFE3E0', text: '#4D0505' },
  { name: 'Seaweed', fill: '#C2F4F1', text: '#002533' },
  { name: 'Yellow', fill: '#FFE895', text: '#381C00' },
  { name: 'Eggplant', fill: '#F8E3FD', text: '#3F0250' },
  { name: 'Green', fill: '#C5F6DA', text: '#022C16' },
  { name: 'Blue', fill: '#DCEBFE', text: '#002152' },
  { name: 'Indigo', fill: '#E0E7FF', text: '#1C1859' },
  { name: 'Rose', fill: '#FBCFE8', text: '#560B2A' },
  { name: 'Rose', fill: '#FED7AA', text: '#492303' },
];
