import { Vertical } from '@weave/schema-gen-ts/dist/shared/vertical/vertical.pb';
import { createShallowStore, createStoreWithPersistAndSubscribe } from '@frontend/store';

export interface LastUsedVerticalStore {
  lastUsedVertical: Vertical | null;
  setLastUsedVertical: (lastUsedVertical: Vertical) => void;
}

const useLastUsedVerticalStore = createStoreWithPersistAndSubscribe<LastUsedVerticalStore>(
  (set) => ({
    lastUsedVertical: null,
    setLastUsedVertical: (lastUsedVertical) =>
      set(
        (state) => {
          state.lastUsedVertical = lastUsedVertical;
        },
        false,
        'SET_LAST_USED_VERTICAL'
      ),
  }),
  { name: 'LastUsedVertical' },
  { name: 'LastUsedVerticalStore', trace: true }
);

export const useLastUsedVerticalShallowStore = createShallowStore(useLastUsedVerticalStore);
