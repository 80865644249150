import { createStoreWithSubscribe, createShallowStore } from '@frontend/store';

export type FeatureFlag = {
  Name: string;
  Value: boolean;
  DisplayName: string;
};

export interface FeatureFlagStore {
  setFlags: (flags: Record<string, FeatureFlag>) => void;
  setFlagValues: (flags: Record<string, boolean>) => void;
  setFlag: ({ name, value }: { name: string; value: boolean }) => void;
  getFlag: (name: string) => boolean;
  flags: Record<string, FeatureFlag>;
  flagValues: Record<string, boolean>;
}

export const useFeatureFlagStore = createStoreWithSubscribe<FeatureFlagStore>(
  (set, get) => ({
    flags: {},
    flagValues: {},
    setFlag: ({ name, value }) =>
      set(
        (state) => {
          const targetFlag = state.flags[name];
          if (targetFlag) {
            targetFlag.Value = value;
            state.flagValues[name] = value;
          }
        },
        false,
        'SET_FEATURE_FLAG'
      ),
    setFlags: (flags) =>
      set(
        (state) => {
          state.flags = flags;
        },
        false,
        'SET_FEATURE_FLAGS'
      ),
    setFlagValues: (flags) =>
      set(
        (state) => {
          state.flagValues = flags;
        },
        false,
        'SET_FEATURE_FLAG_VALUES'
      ),
    /**
     *
     * @deprecated
     * Use the `flags` property directly instead.
     */
    getFlag: (name) => {
      return Boolean(get().flags[name]?.Value);
    },
  }),
  { name: 'FeatureFlagStore', trace: true }
);

export const useFeatureFlagShallowStore = createShallowStore(useFeatureFlagStore);

export const useHasFeatureFlag = (flag: string) => {
  const flags = useFeatureFlagStore((state) => state.flags);
  return !!flags[flag]?.Value === true;
};
