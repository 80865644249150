import { SerializedStyles } from '@emotion/utils';
import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../../../component-theme/theme-types';
import { getFontColorStyle } from '../../../../helpers/font-styles';

export type ListRowContentColumnThemeValues = {
  spacing: WeaveThemeValues['Spacing'];
  lightTextColor: SerializedStyles;
  defaultTextColor: SerializedStyles;
};

export const listRowContentColumnTheme: ListRowContentColumnThemeValues = {
  spacing: theme.spacing,
  lightTextColor: getFontColorStyle('light', theme),
  defaultTextColor: getFontColorStyle('default', theme),
};

export const listRowContentColumnThemeOriginal: ListRowContentColumnThemeValues = {
  spacing: themeOriginal.spacing,
  lightTextColor: getFontColorStyle('light', themeOriginal),
  defaultTextColor: getFontColorStyle('default', themeOriginal),
};
