import { PaymentNotificationType } from '@frontend/api-payments';
import { i18next } from '@frontend/i18n';

export const PaymentSettingTypeList: { type: PaymentNotificationType; label: string }[] = [
  { type: 'terminal_enabled', label: i18next.t('Terminal Pay', { ns: 'payments>>notification-settings' }) },
  { type: 'manual_pay_enabled', label: i18next.t('Manual Pay', { ns: 'payments>>notification-settings' }) },
  { type: 'text_to_pay_enabled', label: i18next.t('Text To Pay', { ns: 'payments>>notification-settings' }) },
  { type: 'online_bill_pay_enabled', label: i18next.t('Online Bill Pay', { ns: 'payments>>notification-settings' }) },
  {
    type: 'mobile_tap_to_pay_enabled',
    label: i18next.t('Mobile Tap To Pay', { ns: 'payments>>notification-settings' }),
  },
  {
    type: 'online_scheduling_enabled',
    label: i18next.t('Online Scheduling', { ns: 'payments>>notification-settings' }),
  },
  { type: 'payment_plan_enabled', label: i18next.t('Payment Plan', { ns: 'payments>>notification-settings' }) },
];

export const DefaultNotificationSettings: Record<PaymentNotificationType, boolean> = {
  terminal_enabled: false,
  manual_pay_enabled: false,
  text_to_pay_enabled: false,
  online_bill_pay_enabled: false,
  mobile_tap_to_pay_enabled: false,
  online_scheduling_enabled: false,
  payment_plan_enabled: false,
};
