import { HTMLAttributes, forwardRef } from 'react';
import { css } from '@emotion/react';

export type UlProps = HTMLAttributes<HTMLUListElement> & {
  children: React.ReactNode;
};

export const NakedUl = forwardRef<HTMLUListElement, UlProps>(({ children, ...rest }, ref) => (
  <ul
    css={css`
      list-style-type: none;
      padding-left: 0;
    `}
    {...rest}
    ref={ref}
  >
    {children}
  </ul>
));
