import { KeyNames } from '../constants';

function getPreviousIndex(current: number, elements: HTMLButtonElement[], shouldLoop: boolean) {
  for (let i = current - 1; i >= 0; i--) {
    if (!elements[i].disabled) {
      return i;
    }
  }

  if (shouldLoop) {
    for (let i = elements.length - 1; i >= 0; i--) {
      if (!elements[i].disabled) {
        return i;
      }
    }
  }

  return current;
}

function getNextIndex(current: number, elements: HTMLButtonElement[], shouldLoop: boolean) {
  for (let i = current + 1; i < elements.length; i++) {
    if (!elements[i].disabled) {
      return i;
    }
  }

  if (shouldLoop) {
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].disabled) {
        return i;
      }
    }
  }

  return current;
}

export const scrollIntoView = (element: HTMLElement | null, shouldScrollIntoView: boolean) => {
  if (!shouldScrollIntoView || !element) return;
  element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'end' });
};

const handleArrowKeys = (
  e: React.KeyboardEvent<HTMLButtonElement>,
  element: HTMLButtonElement,
  shouldActivateOnFocus: boolean
) => {
  e.stopPropagation();
  e.preventDefault();
  element.focus();
  shouldActivateOnFocus && element.click();
};

/** Validates that target element is on the same level as sibling, used to filter out children that have the same sibling selector */
function onSameLevel(target: HTMLButtonElement, sibling: HTMLButtonElement, parentSelector: string) {
  return findElementAncestor(target, parentSelector) === findElementAncestor(sibling, parentSelector);
}

interface KeydownHandlerCreator {
  /** Selector used to find parent node. You could use any deterministic selector */
  parentSelector: string;

  /** Determines if the next/previous element should be scrolled into view */
  shouldScrollIntoView?: boolean;

  /** Selector used to find element siblings. It should be the same for all siblings */
  siblingSelector: string;

  /** Determines whether next/previous indices should loop */
  shouldLoop?: boolean;

  /** Determines which arrow keys will be used */
  orientation: 'vertical' | 'horizontal';

  /** Text direction */
  dir?: 'rtl' | 'ltr';

  /** Determines whether element should be clicked when focused with keyboard event */
  shouldActivateOnFocus?: boolean;

  /** External keydown event */
  onKeyDown?(event: React.KeyboardEvent<HTMLButtonElement>): void;
}

export function createKeydownHandler({
  parentSelector,
  siblingSelector,
  onKeyDown,
  shouldLoop = true,
  shouldScrollIntoView = false,
  shouldActivateOnFocus = false,
  dir = 'ltr',
  orientation,
}: KeydownHandlerCreator) {
  return (event: React.KeyboardEvent<HTMLButtonElement>) => {
    onKeyDown?.(event);

    const elements = Array.from(
      findElementAncestor(event.currentTarget, parentSelector)?.querySelectorAll<HTMLButtonElement>(siblingSelector) ||
        []
    ).filter((node) => onSameLevel(event.currentTarget, node, parentSelector));

    const current = elements.findIndex((el) => event.currentTarget === el);
    const _nextIndex = getNextIndex(current, elements, shouldLoop);
    const _previousIndex = getPreviousIndex(current, elements, shouldLoop);
    const nextIndex = dir === 'rtl' ? _previousIndex : _nextIndex;
    const previousIndex = dir === 'rtl' ? _nextIndex : _previousIndex;

    switch (event.key) {
      case KeyNames.Right: {
        if (orientation === 'horizontal') {
          handleArrowKeys(event, elements[nextIndex], shouldActivateOnFocus);
          scrollIntoView(elements[nextIndex], shouldScrollIntoView);
        }
        break;
      }

      case KeyNames.Left: {
        if (orientation === 'horizontal') {
          handleArrowKeys(event, elements[previousIndex], shouldActivateOnFocus);
          scrollIntoView(elements[previousIndex], shouldScrollIntoView);
        }
        break;
      }

      case KeyNames.Up: {
        if (orientation === 'vertical') {
          handleArrowKeys(event, elements[_previousIndex], shouldActivateOnFocus);
          scrollIntoView(elements[_previousIndex], shouldScrollIntoView);
        }
        break;
      }

      case KeyNames.Down: {
        if (orientation === 'vertical') {
          handleArrowKeys(event, elements[_nextIndex], shouldActivateOnFocus);
          scrollIntoView(elements[_nextIndex], shouldScrollIntoView);
        }
        break;
      }

      case KeyNames.Home: {
        event.stopPropagation();
        event.preventDefault();
        const firstEnabled = elements.find((el) => !el.disabled) as HTMLElement;
        firstEnabled.focus();
        scrollIntoView(firstEnabled, shouldScrollIntoView);
        break;
      }

      case KeyNames.End: {
        event.stopPropagation();
        event.preventDefault();
        const lastEnabled = [...elements].reverse().find((el) => !el.disabled) as HTMLElement;
        lastEnabled.focus();
        scrollIntoView(lastEnabled, shouldScrollIntoView);
        break;
      }
    }
  };
}

export function findElementAncestor(element: HTMLElement, selector: string) {
  return element.closest(selector);
}
