type PrettifyOptions = {
  split?: '_' | '-' | 'CapitalLetter';
  outputCase?: 'Sentence case' | 'Title Case';
};

/**
 * Takes an input string and formats the output to a 'pretty' format.
 * Useful for formatting keys to something you can display to a user.
 * @param input String to format
 * @param options.split The character that separates the words
 * @param options.outputCase The format to output the string
 */
export const prettify = (input: string, options?: PrettifyOptions) => {
  const { split = 'CapitalLetter', outputCase = 'Title Case' } = options ?? {
    split: 'CapitalLetter',
    outputCase: 'Title Case',
  };

  const parts = input.split(split !== 'CapitalLetter' ? split : /(?=[A-Z])/);

  return parts.reduce((acc, part, idx) => {
    return (
      acc +
      (outputCase === 'Sentence case' && idx !== 0 ? part.charAt(0).toLowerCase() : part.charAt(0).toUpperCase()) +
      part.slice(1).toLowerCase() +
      (idx !== parts.length - 1 ? ' ' : '')
    );
  }, '');
};
