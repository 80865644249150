import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { PieChart as RechartPieChart, Pie, Cell, ResponsiveContainer, Label } from 'recharts';
import { PolarViewBox, ViewBox } from 'recharts/types/util/types';
import { theme } from '@frontend/theme';
import { EmptyData, Tooltip, TooltipData } from '../atoms';
import { useChartContext } from '../chart.provider';
import { adjustHexColor, defaultColor, defaultFormatter } from '../helpers';
import { FormatValue, GenerateTooltipData } from '../types';
import { Appearance, PieChartData, PieChartDatum, ResponsiveCenterLabels } from './types';

type BaseProps = {
  appearance?: Appearance;
  commonTooltipLabel?: string;
  customLegendsIds?: string[];
  data?: PieChartData;
  formatValue?: FormatValue;
  onClick?: (args: PieChartDatum) => void;
};

type PieChartProps = Omit<React.HTMLAttributes<HTMLDivElement>, keyof BaseProps> & BaseProps;

const generateTooltipData = ({
  colors,
  commonTooltipLabel,
  customTooltipData,
  data,
  formatValue,
  labels,
}: GenerateTooltipData<PieChartData>) => {
  const tooltipData: Record<string, TooltipData[]> = {};

  data.groups.forEach(({ name, value }) => {
    if (name.startsWith('skipTip')) return;

    const formatter = formatValue
      ? typeof formatValue === 'object'
        ? formatValue[name] ?? defaultFormatter
        : formatValue
      : defaultFormatter;
    const tooltipBlock: TooltipData[] = [];

    if (typeof customTooltipData !== 'function') {
      tooltipBlock.push({
        color: colors[name] || theme.colors.neutral50,
        formattedValue: formatter(value),
        id: name,
        label: commonTooltipLabel || labels?.[name] || name,
      });
    }

    tooltipData[name] = customTooltipData?.(name) ?? tooltipBlock;
  });

  return tooltipData;
};

const segmentStrokeWidth = 2;

export const PieChart = memo(
  ({
    appearance = {},
    commonTooltipLabel: commonPieTooltipLabel,
    customLegendsIds,
    data = { groups: [] },
    formatValue = defaultFormatter,
    onClick,
    ...rest
  }: PieChartProps) => {
    const {
      collectiveTooltip,
      customTooltipData,
      customTooltipTitle,
      height = 240,
      hideTooltip,
      innerRadius = 84,
      margin,
      outerRadius = 110,
      width = '100%',
      minWidth,
      borderRadius = 8,
      paddingAngle,
    } = appearance;

    const { activeLegends, colors, commonTooltipLabel, emptyStateConfig, labels, setPrimaryLegendsList } =
      useChartContext();
    const [hoveredGroup, setHoveredGroup] = useState<string | null>(null);
    const [tooltipData, setTooltipData] = useState<Record<string, TooltipData[]>>({});
    const [tooltipPosition, setTooltipPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

    const isClickable = typeof onClick === 'function';

    const handleMouseLeave = useCallback(() => {
      setHoveredGroup(null);
    }, []);

    const chartData = useMemo(() => {
      return data.groups.filter(({ name }) => activeLegends.includes(name));
    }, [activeLegends, data.groups]);

    const selectedTotalValue = useMemo(() => {
      return chartData?.reduce((acc, val) => (acc += val?.value ?? 0), 0);
    }, [chartData]);

    const totalValue = useMemo(() => data.groups.reduce((acc, { value }) => acc + value, 0), [data.groups]);

    // This is an experimental formula to calculate corner radius based on segment size
    // Review and adjust if needed
    const getCornerRadius = useCallback(
      (value: number) => {
        const maxRadius = borderRadius;

        if (!value) {
          return maxRadius;
        }

        const segmentSize = Math.floor((value / totalValue) * 360);
        const factor = Math.floor(segmentSize / value);
        const radius = value * factor;

        if (!radius) {
          if (segmentSize < maxRadius) {
            return segmentSize - 1;
          }

          return maxRadius;
        }

        return Math.min(maxRadius, value * factor);
      },
      [activeLegends, totalValue, borderRadius]
    );

    useEffect(() => {
      const tooltipData = generateTooltipData({
        colors,
        commonTooltipLabel: commonPieTooltipLabel ?? commonTooltipLabel,
        customTooltipData,
        data,
        formatValue,
        labels,
      });

      setTooltipData(tooltipData);
      setPrimaryLegendsList(customLegendsIds ?? Object.keys(tooltipData));
    }, [data.groups]);

    const { responsiveCenterLabels, centerMetric, centerMetricLabel } = data;

    return (
      <div css={styles.mainWrapper} style={{ height, minHeight: height, minWidth: minWidth, width }} {...rest}>
        {chartData.length ? (
          <ResponsiveContainer height='100%' width='100%'>
            <RechartPieChart margin={margin} onMouseLeave={handleMouseLeave}>
              <Pie
                data={chartData}
                dataKey='value'
                innerRadius={innerRadius}
                onClick={(props) => {
                  onClick?.({
                    name: props.name,
                    value: props.value,
                  });
                }}
                onMouseLeave={handleMouseLeave}
                onMouseMove={(props, _i, event) => {
                  setHoveredGroup(props.name);
                  setTooltipPosition({
                    x: event.clientX,
                    y: event.clientY,
                  });
                }}
                outerRadius={outerRadius}
                paddingAngle={paddingAngle ?? (isClickable ? 3 : 2)}
                startAngle={90}
                endAngle={-270}
              >
                {chartData.map(({ name, value }) => {
                  const color = colors[name] || defaultColor;
                  const additionalProps = {
                    cornerRadius: getCornerRadius(value),
                  };

                  return (
                    <Cell
                      {...additionalProps}
                      cursor={isClickable ? 'pointer' : 'default'}
                      fill={
                        hoveredGroup
                          ? isClickable
                            ? hoveredGroup === name
                              ? color
                              : adjustHexColor(color, 65)
                            : color
                          : color
                      }
                      key={name}
                      role={isClickable ? 'button' : 'presentation'}
                      strokeWidth={isClickable ? segmentStrokeWidth : 0}
                      stroke={
                        isClickable
                          ? hoveredGroup
                            ? hoveredGroup === name
                              ? adjustHexColor(color, 20, true)
                              : adjustHexColor(color, 65)
                            : adjustHexColor(color, 20, true)
                          : 'none'
                      }
                    />
                  );
                })}
                {centerMetric && (
                  <Label
                    fill={theme.font.colors.default}
                    fontSize={28}
                    fontWeight='bold'
                    position='center'
                    value={centerMetric}
                    style={{ transform: `translateY(${theme.spacing(centerMetricLabel ? -1 : 0)})` }}
                  />
                )}
                {centerMetricLabel && (
                  <Label
                    fill={theme.colors.neutral50}
                    position='center'
                    value={centerMetricLabel}
                    style={{ transform: `translateY(${theme.spacing(2)})` }}
                  />
                )}
                {responsiveCenterLabels ? (
                  <Label
                    content={(props) => (
                      <ResponsiveLabel totalValue={selectedTotalValue} {...props} {...responsiveCenterLabels} />
                    )}
                    position='center'
                  />
                ) : null}
              </Pie>
            </RechartPieChart>
          </ResponsiveContainer>
        ) : (
          <EmptyData emptyStateConfig={emptyStateConfig} />
        )}
        {hoveredGroup && !hideTooltip && (
          <Tooltip
            data={collectiveTooltip ? Object.values(tooltipData).flat() : tooltipData[hoveredGroup]}
            isClickable={isClickable}
            itemType={'segment'}
            name={
              customTooltipTitle?.({
                groupName: hoveredGroup,
                hoveredSegment: hoveredGroup,
              }) || hoveredGroup
            }
            xPos={tooltipPosition.x}
            yPos={tooltipPosition.y}
          />
        )}
      </div>
    );
  }
);

function isPolarViewBox(viewBox?: ViewBox): viewBox is PolarViewBox {
  if (!viewBox) return false;

  return 'cx' in viewBox || 'cy' in viewBox || 'innerRadius' in viewBox || 'outerRadius' in viewBox;
}

interface ResponsiveLabelProps extends ResponsiveCenterLabels {
  viewBox?: ViewBox;
  totalValue: number;
}

const ResponsiveLabel: React.FC<ResponsiveLabelProps> = ({
  viewBox,
  primaryText,
  secondaryText,
  maxFontSize = 16,
  outerRadiusRatio = 0.16,
  textSpacing = 4,
  textVerticalOffset = 4,
  totalValue,
}) => {
  if (!isPolarViewBox(viewBox)) return null;

  const cx = viewBox.cx ?? 0;
  const cy = viewBox.cy ?? 0;
  const outerRadius = viewBox.outerRadius ?? 0;
  const fontSize = Math.min(maxFontSize, outerRadius * outerRadiusRatio);
  const primaryFontSize = fontSize * (primaryText.textSizeRatio ?? 1.3);
  const secondaryFontSize = secondaryText ? fontSize * (secondaryText.textSizeRatio ?? 1) : 0;

  const primaryTextPosition = secondaryText
    ? cy - primaryFontSize / 2 - textSpacing / 2 + textVerticalOffset
    : cy + textVerticalOffset;

  const secondaryTextPosition = cy + primaryFontSize / 2 + textSpacing / 2 + textVerticalOffset;

  return (
    <g>
      <text
        x={cx}
        y={primaryTextPosition}
        textAnchor='middle'
        dominantBaseline='central'
        fontSize={`${primaryFontSize}px`}
        fill={primaryText.color ?? theme.colors.neutral70}
        fontWeight={primaryText.weight ?? 'bold'}
      >
        {typeof primaryText.label === 'function' ? primaryText.label(totalValue) : primaryText.label}
      </text>
      {secondaryText && (
        <text
          x={cx}
          y={secondaryTextPosition}
          textAnchor='middle'
          dominantBaseline='central'
          fontSize={`${secondaryFontSize}px`}
          fill={secondaryText.color ?? theme.font.colors.subdued}
          fontWeight={secondaryText.weight ?? 'normal'}
        >
          {typeof secondaryText.label === 'function' ? secondaryText.label(totalValue) : secondaryText.label}
        </text>
      )}
    </g>
  );
};

export default ResponsiveLabel;

PieChart.displayName = 'PieChart';

const styles = {
  mainWrapper: css`
    display: flex;
    align-items: center;
    path.recharts-sector:focus {
      outline: none;
    }
  `,
};
