/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const CheckIconSmall: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={16} {...props} viewBox='0 0 16 16'>
    <path
      fillRule='evenodd'
      d='M3.70710678,7.54289322 C3.31658249,7.15236893 2.68341751,7.15236893 2.29289322,7.54289322 C1.90236893,7.93341751 1.90236893,8.56658249 2.29289322,8.95710678 L5.24089322,11.9051068 C5.63141751,12.2956311 6.26458249,12.2956311 6.65510678,11.9051068 L13.8531068,4.70710678 C14.2436311,4.31658249 14.2436311,3.68341751 13.8531068,3.29289322 C13.4625825,2.90236893 12.8294175,2.90236893 12.4388932,3.29289322 L5.948,9.78378644 L3.70710678,7.54289322 Z'
    />
  </Icon>
);
