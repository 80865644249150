import { css, SerializedStyles } from '@emotion/react';
import React, { forwardRef } from 'react';
import Children from 'react-children-utilities';
import type { InputProps } from './types';

type Props = InputProps<'dropdown'> & {
  as?: keyof JSX.IntrinsicElements;
  className?: string;
  displayValue?: React.ReactNode;
  containerCss?: SerializedStyles;
};

// This input is set up this way to be able
// to display custom content (children of selected option)
// It contains the minimal amount of styling needed for its use case
export const DropdownInput = forwardRef<HTMLInputElement, Props>(
  ({ className, as = 'p', displayValue, placeholder, containerCss, ...rest }, ref) => {
    const Component = as;
    return (
      <Component
        css={css`
          width: 100%;
          margin: 0;
        `}
        className={className}
      >
        <input
          {...rest}
          /**
           * Display the text shown in each option as selected value (not the option value)
           */
          value={Children.onlyText(displayValue as React.ReactNode)}
          css={css`
            width: 100%;
            height: 100%;
            cursor: default;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
          `}
          type='text'
          readOnly
          ref={ref}
        />
        {displayValue || rest.value || placeholder}
      </Component>
    );
  }
);
