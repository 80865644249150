/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const LinkIconSmall: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={16} {...props} viewBox='0 0 16 16'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.1888 10.0523C8.44114 11.6484 10.8582 11.6484 12.1105 10.0523L15.0568 6.29713C15.9846 5.11462 15.9846 3.45131 15.0568 2.2688C13.7504 0.603759 11.2291 0.603759 9.92265 2.2688L9.62848 2.64372C9.28757 3.07823 9.36343 3.70683 9.79794 4.04775C10.2324 4.38866 10.861 4.31279 11.202 3.87829L11.4961 3.50337C12.0018 2.8589 12.9777 2.8589 13.4833 3.50337C13.8425 3.96107 13.8425 4.60486 13.4834 5.06256L10.537 8.81771C10.0854 9.39327 9.21387 9.39327 8.76228 8.81771C8.42136 8.3832 7.79276 8.30733 7.35825 8.64825C6.92375 8.98917 6.84788 9.61777 7.1888 10.0523ZM10.3556 5.94794C9.10329 4.35181 6.68627 4.35181 5.43393 5.94794L2.4876 9.70309C1.55979 10.8856 1.5598 12.5489 2.4876 13.7314C3.79401 15.3965 6.31538 15.3965 7.62179 13.7314L7.91595 13.3565C8.25687 12.922 8.181 12.2934 7.7465 11.9525C7.31199 11.6116 6.68339 11.6874 6.34247 12.1219L6.04831 12.4968C5.54265 13.1413 4.56674 13.1413 4.06108 12.4968C3.70197 12.0391 3.70197 11.3954 4.06108 10.9377L7.00741 7.18251C7.459 6.60695 8.33057 6.60695 8.78216 7.18251C9.12307 7.61702 9.75168 7.69288 10.1862 7.35197C10.6207 7.01105 10.6966 6.38245 10.3556 5.94794Z'
    />
  </Icon>
);
