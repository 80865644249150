import { css } from '@emotion/react';
import { getBaseFontSize } from '@frontend/theme-original';
import { useThemeValues } from '../../../../hooks';
import { Children, useEffect } from 'react';
import { useStyles } from '../../../../use-styles';
import { Text } from '../../../../components';
import { AnimatedMenu } from '../../../flyouts';
import { StyledListboxList, useListboxContext, useListboxSearch } from '../../../listbox';
import { useDropdownContext } from '../../providers';
import { useDropdownSearch } from '../dropdown/use-dropdown-search';

const BASE = getBaseFontSize();

export const EmptyState = ({ value }: { value?: string }) => {
  const theme = useThemeValues();
  return (
    <Text
      as='li'
      css={css`
        margin: ${theme.spacing(2)};
      `}
    >
      {!!value ? `No results found for "${value}"` : 'No results found'}
    </Text>
  );
};

export const MultiselectMenu = ({ active, children, direction, name = '', placeholder = 'Search', ...rest }) => {
  const theme = useThemeValues();
  const { debouncedBlur, debouncedFocus, menuRef } = useDropdownContext();
  const { listboxProps, setActiveItem } = useListboxContext();
  const { searchFieldProps, SearchField, matchingChildren } = useListboxSearch(children);
  const searchFieldName = `ms-search-${name}`;
  const showSearch = Children.toArray(children)?.length >= 10;
  const dropdownMenuStyle = useStyles('DropdownInput', 'dropdownMenuStyle', {
    active,
    direction,
  });
  const childrenArray = matchingChildren as [];
  const keydownSearch = useDropdownSearch({
    children,
    // @ts-ignore types of KeyboardEvent<HTMLUListElement> and KeyboardEvent<Element> no longer match
    onKeyDown: listboxProps.onKeyDown,
    onMatch: setActiveItem,
  });

  useEffect(() => {
    if (active) {
      // make sure to reset field value between menu toggles
      searchFieldProps.onChange({ name: searchFieldName, value: '' });
    }
  }, [active]);

  return (
    // @ts-ignore some issue with HTMLAttributes on FlyoutProps
    <AnimatedMenu
      as='div'
      css={[
        dropdownMenuStyle,
        css`
          display: flex;
          flex-direction: column;
        `,
        showSearch &&
          css`
            max-height: ${288 / BASE}rem;
          `,
      ]}
      {...rest}
      ref={menuRef}
    >
      {showSearch && (
        <SearchField
          {...searchFieldProps}
          onBlur={() => {
            debouncedBlur();
            searchFieldProps.onBlur();
          }}
          onFocus={() => {
            debouncedFocus();
            searchFieldProps.onFocus();
          }}
          label=''
          placeholder={placeholder}
          name={searchFieldName}
          css={css`
            margin: ${theme.spacing(1, 2, 0)};
          `}
          tabIndex={active ? undefined : -1}
        />
      )}

      <StyledListboxList
        css={css`
          margin: ${theme.spacing(showSearch ? 1 : 0, 0, 0.5)};
        `}
        {...listboxProps}
        onKeyDown={showSearch ? listboxProps.onKeyDown : keydownSearch}
        tabIndex={active ? 0 : -1}
      >
        {childrenArray?.length ? childrenArray : <EmptyState value={searchFieldProps.value} />}
      </StyledListboxList>
    </AnimatedMenu>
  );
};
