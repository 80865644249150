import { animated as SpringAnimated } from 'react-spring';
import { useThemeOptions } from '../../theme-option-provider';

type ExtendedAnimatedTypes = {
  element: keyof typeof SpringAnimated;
  component: (typeof SpringAnimated)[keyof typeof SpringAnimated];
};

const ExtendedAnimated = ({ element, component, ...props }: ExtendedAnimatedTypes) => {
  const options = useThemeOptions();

  let Component;
  if (options?.skipAnimation) {
    Component = element;
  } else {
    Component = component;
  }

  return <Component {...props} />;
};

export const animated = Object.entries(SpringAnimated).reduce((acc, [element, component]) => {
  acc[element] = (props) => <ExtendedAnimated element={element} component={component} {...props} />;
  return acc;
}, {}) as typeof SpringAnimated;
