import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../../component-theme/theme-types';

export type FullscreenModalHeaderTheme = {
  borderColor: WeaveThemeValues['Color'];
  spacing: WeaveThemeValues['Spacing'];
};

export const fullscreenModalHeaderTheme: FullscreenModalHeaderTheme = {
  borderColor: theme.colors.neutral50,
  spacing: theme.spacing,
};

export const fullscreenModalHeaderThemeOriginal: FullscreenModalHeaderTheme = {
  borderColor: themeOriginal.colors.gray[300],
  spacing: themeOriginal.spacing,
};
