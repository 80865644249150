import { ReactNode, createContext, useMemo } from 'react';

interface SelectedLocationProviderProps {
  children: ReactNode;
  locationId: string | string[];
  bypassContext?: boolean;
}

export const selectedLocationContext = createContext<string[] | undefined>(undefined);

export const SelectedLocationProvider = ({
  children,
  locationId,
  bypassContext = false,
}: SelectedLocationProviderProps) => {
  const locationIds = Array.isArray(locationId) ? locationId : [locationId];
  const locationIdValue = useMemo(() => (locationId ? locationIds : undefined), [...locationIds]);

  return (
    <>
      {bypassContext ? (
        children
      ) : (
        <selectedLocationContext.Provider value={locationIdValue}>{children}</selectedLocationContext.Provider>
      )}
    </>
  );
};
