/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const HelpIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M12,23.25 C5.78679656,23.25 0.75,18.2132034 0.75,12 C0.75,5.78679656 5.78679656,0.75 12,0.75 C18.2132034,0.75 23.25,5.78679656 23.25,12 C23.25,18.2132034 18.2132034,23.25 12,23.25 Z M12,20.75 C16.8324916,20.75 20.75,16.8324916 20.75,12 C20.75,7.16750844 16.8324916,3.25 12,3.25 C7.16750844,3.25 3.25,7.16750844 3.25,12 C3.25,16.8324916 7.16750844,20.75 12,20.75 Z M12.7281978,13.2620891 L12.7253191,13.2595009 C13.1609422,13.6594875 13.1587319,14.3035801 12.7191252,14.7010922 C12.2778745,15.100091 11.5630422,15.0995688 11.1225027,14.6999258 C10.9030961,14.5008873 10.6897231,14.1911833 10.5720586,13.7642178 C10.3995416,13.1382107 10.4973513,12.4283728 10.9113394,11.6772599 C11.2546664,11.0543499 11.6483405,10.7418627 12.5409086,10.2020571 C13.3537909,9.71044361 13.5020341,9.54607749 13.5020341,9.06142815 C13.5020341,8.70568145 13.0057143,8.24587703 12.3339121,8.09267449 C11.6611066,7.93924315 11.1897966,8.13872293 10.9617039,8.76653222 C10.7668108,9.30296187 10.128682,9.59472981 9.53640337,9.41821415 C8.94412476,9.2416985 8.62198017,8.66374214 8.81687328,8.12731248 C9.43707594,6.42024813 11.1215257,5.7073125 12.8852365,6.10952164 C14.4767975,6.47247248 15.76,7.66126691 15.76,9.06142815 C15.76,10.4202962 15.1766622,11.0670778 13.7948017,11.9027968 C13.2242078,12.2478795 13.0398426,12.3942233 12.9315764,12.5906545 C12.7528485,12.9149267 12.7236476,13.1268475 12.7628149,13.2689727 C12.7716499,13.3010319 12.770624,13.3005948 12.7281978,13.2620891 Z M12,18.5 C11.3096441,18.5 10.75,17.9403559 10.75,17.25 C10.75,16.5596441 11.3096441,16 12,16 C12.6903559,16 13.25,16.5596441 13.25,17.25 C13.25,17.9403559 12.6903559,18.5 12,18.5 Z'
    />
  </Icon>
);
