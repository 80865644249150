import { forwardRef } from 'react';
import { ActionPressedChip, ActionPressedChipProps } from './action-pressed-chip';
import { css } from '@emotion/react';
import { CaretDownIconSmall } from '../../../icon';

type ActionDropdownChip = Omit<ActionPressedChipProps, 'endIcon'> & {
  isOpen?: boolean;
};

const chevronAnimatedStyle = (isActive: boolean) => css`
  transition: transform 300ms ease-out;
  display: flex;
  flex-direction: center;
  justify-content: center;
  transform: rotate(${isActive ? 180 : 0}deg);
`;

export const ActionDropdownChip = forwardRef<HTMLButtonElement, ActionDropdownChip>(
  ({ isOpen, children, ...rest }, ref) => {
    return (
      <ActionPressedChip
        ref={ref}
        {...rest}
        endIcon={
          <span className='chevron-arrow' css={chevronAnimatedStyle(!!isOpen)}>
            <CaretDownIconSmall size={8} />
          </span>
        }
      >
        {children}
      </ActionPressedChip>
    );
  }
);
