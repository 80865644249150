import { css } from '@emotion/react';
import { useCallback, useState } from 'react';

export function useImageReady(transitionSpeed = 500) {
  const [ready, setReady] = useState(false);
  const onLoad = useCallback(() => setReady(true), []);

  return {
    css: [
      css`
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0;
        transition: opacity ${transitionSpeed}ms ease-out;
      `,
      ready &&
        css`
          opacity: 1;
        `,
    ],
    onLoad,
  };
}
