import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../component-theme/theme-types';

export type DotThemeValues = {
  colors: {
    warning: WeaveThemeValues['Color'];
    success: WeaveThemeValues['Color'];
    critical: WeaveThemeValues['Color'];
    primary: WeaveThemeValues['Color'];
    white: WeaveThemeValues['Color'];
  };
};

export const dotTheme: DotThemeValues = {
  colors: {
    warning: theme.colors.status.warning,
    success: theme.colors.status.success,
    critical: theme.colors.status.critical,
    primary: theme.colors.primary50,
    white: theme.colors.white,
  },
};

export const dotThemeOriginal: DotThemeValues = {
  colors: {
    warning: themeOriginal.colors.warning,
    success: themeOriginal.colors.success,
    critical: themeOriginal.colors.error,
    primary: themeOriginal.colors.weaveBlue,
    white: themeOriginal.colors.white,
  },
};
