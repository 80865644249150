import { PersonPhoneSelector as StandalonePersonPhoneSelector } from '../person-phone-selector';
import { useThreadHeaderContext } from '../thread-header';

export const PersonPhoneSelector = () => {
  const { groupId, personPhone, primaryContactId, primaryContact, onPersonPhoneChange } = useThreadHeaderContext();

  if (!onPersonPhoneChange) return null;

  return (
    <StandalonePersonPhoneSelector
      groupId={groupId}
      personPhone={personPhone}
      personId={primaryContact?.personId || primaryContactId || ''}
      person={primaryContact}
      onSelectPersonPhone={onPersonPhoneChange}
    />
  );
};
