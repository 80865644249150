import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../../../component-theme/theme-types';

export type MultiselectSelectionTextThemeValues = {
  borderColor: WeaveThemeValues['Color'];
  spacing: WeaveThemeValues['Spacing'];
  zIndex: WeaveThemeValues['ZIndex'];
};

export type MultiselectSelectionButtonValues = {
  color: WeaveThemeValues['Color'];
  spacing: WeaveThemeValues['Spacing'];
  focusColor: WeaveThemeValues['Color'];
  blueColor: WeaveThemeValues['Color'];
};

export type MultiselectSelectionRemainderThemeValues = {
  spacing: WeaveThemeValues['Spacing'];
};

export const multiselectSelectionTextTheme: MultiselectSelectionTextThemeValues = {
  borderColor: theme.colors.neutral40,
  spacing: theme.spacing,
  zIndex: theme.zIndex.low,
};

export const multiselectSelectionTextThemeOriginal: MultiselectSelectionTextThemeValues = {
  borderColor: themeOriginal.colors.gray[400],
  spacing: themeOriginal.spacing,
  zIndex: themeOriginal.zIndex.low,
};

export const multiselectSelectionButtonTheme: MultiselectSelectionButtonValues = {
  color: theme.colors.neutral50,
  spacing: theme.spacing,
  focusColor: theme.colors.neutral60,
  blueColor: theme.colors.primary50,
};

export const multiselectSelectionButtonThemeOriginal: MultiselectSelectionButtonValues = {
  color: themeOriginal.colors.gray[500],
  spacing: themeOriginal.spacing,
  focusColor: themeOriginal.colors.gray[600],
  blueColor: themeOriginal.colors.weaveBlue,
};

export const multiselectSelectionRemainderTheme: MultiselectSelectionRemainderThemeValues = {
  spacing: theme.spacing,
};
export const multiselectSelectionRemainderThemeOriginal: MultiselectSelectionRemainderThemeValues = {
  spacing: themeOriginal.spacing,
};
