/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const AlignLeftIconSmall: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={16} {...props} viewBox='0 0 16 16'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.76501 0.991211C2.21273 0.991211 1.76501 1.43893 1.76501 1.99121C1.76501 2.5435 2.21273 2.99121 2.76501 2.99121H14.7794C15.3317 2.99121 15.7794 2.5435 15.7794 1.99121C15.7794 1.43893 15.3317 0.991211 14.7794 0.991211H2.76501ZM2.76501 8.96191C2.21273 8.96191 1.76501 9.40963 1.76501 9.96191C1.76501 10.5142 2.21273 10.9619 2.76501 10.9619H11.3467C11.899 10.9619 12.3467 10.5142 12.3467 9.96191C12.3467 9.40963 11.899 8.96191 11.3467 8.96191H2.76501ZM1.76501 5.97656C1.76501 5.42428 2.21273 4.97656 2.76501 4.97656H8.77222C9.3245 4.97656 9.77222 5.42428 9.77222 5.97656C9.77222 6.52885 9.3245 6.97656 8.77222 6.97656H2.76501C2.21273 6.97656 1.76501 6.52885 1.76501 5.97656ZM8.0869 15.0086C8.63915 15.0087 9.08469 14.561 9.08203 14.0087C9.07938 13.4564 8.62954 13.0086 8.07728 13.0085L2.76061 13.0078C2.20836 13.0077 1.76282 13.4553 1.76548 14.0077C1.76813 14.56 2.21797 15.0078 2.77023 15.0078L8.0869 15.0086Z'
    />
  </Icon>
);
