export const zIndex = {
  background: -1,
  low: 1,
  middle: 5,
  high: 10,
  higher: 100,
  highest: 1000,
  // RESERVED: not for normal usage
  modals: 10000,
  popover: 100000,
  hints: 110000,
  alerts: 1000000,
  // TODO: test these
  hide: -1,
  auto: 'auto',
  base: 0,
  docked: 10,
  dropdown: 1000,
  sticky: 1100,
  banner: 1200,
  overlay: 1300,
  modal: 1400,
  skipLink: 1600,
  toast: 1700,
  tooltip: 1800,
};
