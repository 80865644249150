import { Node } from 'slate';
import { TagNode, TagType } from './types';

export const TAG_ELEMENT_TYPE = 'tag';

export type AddTagToTemplateFun = (
  event: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>,
  tag: TagType
) => void;

export const getTag = (tags: TagType[], key: string): TagType | undefined => {
  const checkKey = (tag: TagType) => {
    if (Array.isArray(tag.key)) {
      return tag.key.some((k) => k === key);
    } else {
      return tag.key === key;
    }
  };

  return tags.find((tag) => {
    if (tag.options) {
      return tag.options.some(checkKey);
    } else {
      return checkKey(tag);
    }
  });
};

export const isTagNode = (node: Node): node is TagNode => node.type === TAG_ELEMENT_TYPE;
