import { css } from '@emotion/react';
import { Text } from '../../text';
import { useThemeValues } from '../../../hooks';

export const HelperText = ({ helperText }: { helperText: React.ReactNode }) => {
  const { spacing } = useThemeValues();

  return (
    <section
      css={css`
        padding: ${spacing(1)};
      `}
    >
      {typeof helperText === 'string' ? (
        <Text
          size='small'
          color='light'
          css={css`
            margin: 0;
          `}
        >
          {helperText}
        </Text>
      ) : (
        helperText
      )}
    </section>
  );
};
