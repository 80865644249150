import { css } from '@emotion/react';
import { ReactNode } from 'react';
import { Text } from '../../..';

export const ListRowTertiaryTitle = ({
  children,
  as = Text,
  ...rest
}: {
  as?: any;
  children: ReactNode;
  className?: string;
}) => {
  const Component = as;
  return (
    <Component size='small' css={css({ margin: 0 })} className='content-column-tertiary-title' {...rest}>
      {children}
    </Component>
  );
};
