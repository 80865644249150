import { css } from '@emotion/react';
import { useStyles } from '../../../../use-styles';
import { BoolInput } from '../../atoms';
import type { RadioOptionProps } from './types';

export const Radio = ({ active, checked, disabled, trackingId, ...rest }: RadioOptionProps) => {
  const radioStyles = useStyles('Radio', { active, checked, disabled });
  return (
    <div css={radioStyles}>
      <BoolInput
        {...rest}
        css={[
          disabled &&
            css`
              cursor: not-allowed;
            `,
        ]}
        aria-checked={checked}
        checked={checked}
        disabled={disabled}
        type='radio'
        data-trackingid={trackingId}
      />
    </div>
  );
};
