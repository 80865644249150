import { merge } from 'lodash-es';
import { IconColorType } from '@frontend/icons';
import { ButtonProps, ButtonStyleValues, ButtonVariants } from '../button.type';
import { primaryStyles } from './primary.styles';
import { secondaryStyles } from './secondary.styles';
import { tertiaryStyles } from './tertiary.styles';

export const getStyleValues = ({ destructive, ...styles }: ButtonStyleValues, isDestructive: boolean) => {
  /**
   * Recursively merge destructive styles over base styles.
   */
  if (isDestructive) {
    return merge(styles, destructive);
  }
  return styles;
};

export const getIconColor: (variant: ButtonVariants, disabled?: boolean, destructive?: boolean) => IconColorType = (
  variant,
  disabled,
  destructive
) => {
  if (disabled) {
    switch (variant) {
      case 'primary':
        return 'white';
      case 'secondary':
        return 'disabled';
      case 'tertiary':
        return 'disabled';
    }
  } else if (destructive) {
    switch (variant) {
      case 'primary':
        return 'white';
      case 'secondary':
        return 'error';
      case 'tertiary':
        return 'error';
    }
  } else {
    switch (variant) {
      case 'primary':
        return 'white';
      case 'secondary':
        return 'default';
      case 'tertiary':
        return 'primary';
      default:
        return 'primary';
    }
  }
};

export const getLoaderColor = (variant: ButtonVariants, destructive: boolean) => {
  if (destructive) {
    switch (variant) {
      case 'primary':
        return 'white';
      case 'secondary':
        return 'error';
      case 'tertiary':
        return 'error';
    }
  } else {
    switch (variant) {
      case 'primary':
        return 'primary';
      case 'secondary':
        return 'subdued';
      case 'tertiary':
        return 'primary';
    }
  }
};

export const getVariantStyles = ({
  variant,
  size,
  loading = false,
  iconOnly,
  destructive,
}: Pick<ButtonProps, 'destructive' | 'loading' | 'size' | 'variant'> & {
  iconOnly: boolean;
}) => {
  switch (variant) {
    case 'primary':
      return primaryStyles({ size, isLoading: loading, iconOnly, destructive });
    case 'secondary':
      return secondaryStyles({ size, isLoading: loading, iconOnly, destructive });
    case 'tertiary':
      return tertiaryStyles({ size, isLoading: loading, iconOnly, destructive });
    default:
      return primaryStyles({ size, isLoading: loading, iconOnly, destructive });
  }
};
