import { ReactNode } from 'react';
import { contextFactory } from '@frontend/design-system';
import { isPaymentsAppVariant } from '../utils';

export type PaymentsPageVariant = 'portal' | 'payments-app';

interface AppVariantContextProps {
  variant: PaymentsPageVariant;
}

export const [AppVariantContext, useAppVariantContext] = contextFactory<AppVariantContextProps>();

interface AppVariantProviderProps {
  children: ReactNode;
  variant: PaymentsPageVariant;
}

export const AppVariantProvider = ({ children, variant }: AppVariantProviderProps) => (
  <AppVariantContext.Provider value={{ variant }}>{children}</AppVariantContext.Provider>
);

export const getLinkBase = (variant: PaymentsPageVariant) =>
  isPaymentsAppVariant(variant) ? '/payments' : '/portal/payments';
