import { memo } from 'react';
import { Text } from '@frontend/design-system';
import { Name } from './name';
import { MinAndMax } from './name-header';
import { Rank } from './rank';

type Props = {
  highestMetric?: string;
  lowestMetric?: string;
  minAndMax?: MinAndMax | null;
  name: string;
  serialNo: number;
};

export const StickyColumn = memo(({ highestMetric, lowestMetric, minAndMax, name, serialNo }: Props) => {
  return (
    <div className='sticky-content'>
      <div className='serial'>
        <Text color='subdued' weight='bold'>
          {serialNo}
        </Text>
      </div>
      <div className='name'>
        <Name value={name} />
        {highestMetric && minAndMax?.highestValueNames.includes(name) && <Rank metric={highestMetric} rank='highest' />}
        {lowestMetric && minAndMax?.lowestValueNames.includes(name) && <Rank metric={lowestMetric} rank='lowest' />}
      </div>
    </div>
  );
});

StickyColumn.displayName = 'StickyColumn';
