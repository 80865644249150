import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import InstallBatteryImg from './assets/install-battery.svg?react';
import PlugInTerminalImg from './assets/plug-in-terminal.svg?react';
import TurnTerminalOnImg from './assets/turn-on-terminal.svg?react';
import { RegisterTerminalModalSteps } from './hooks';
import { RegisterTerminalModalStep } from './register-terminal-modal-step';

const styles = {
  list: css`
    list-style-type: number;
    padding-left: ${theme.spacing(2)};
    margin-top: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(1)};
  `,
};

export const InstallBattery = () => {
  const { t } = useTranslation('payments');

  return (
    <RegisterTerminalModalStep
      title={t('Install battery')}
      nextStep={RegisterTerminalModalSteps.PlugInTerminal}
      nextSectionStep={RegisterTerminalModalSteps.OpenWifiSettings}
      hideBackButton
    >
      <InstallBatteryImg />
      <ol css={styles.list}>
        <li>
          {t(
            'Install the battery by lifting up the back cover from the indentation at the bottom left. Slide the battery into the exposed slot and align the gold connectors at the top of the battery with the terminal.'
          )}
        </li>
      </ol>
    </RegisterTerminalModalStep>
  );
};

export const PlugInTerminal = () => {
  const { t } = useTranslation('payments');

  return (
    <RegisterTerminalModalStep
      title={t('Plug in terminal to charge')}
      nextStep={RegisterTerminalModalSteps.TurnTerminalOn}
      nextSectionStep={RegisterTerminalModalSteps.OpenWifiSettings}
    >
      <PlugInTerminalImg />
      <ol css={styles.list} start={2}>
        <li>
          {t(
            'Connect the reader to power by plugging the provided power cable into the port with the lightning bolt symbol.'
          )}
        </li>
      </ol>
    </RegisterTerminalModalStep>
  );
};

export const TurnTerminalOn = () => {
  const { t } = useTranslation('payments');

  return (
    <RegisterTerminalModalStep
      title={t('Turn the terminal on')}
      nextStep={RegisterTerminalModalSteps.OpenWifiSettings}
      hasContinue
    >
      <TurnTerminalOnImg />
      <ol css={styles.list} start={3}>
        <li>
          {t(
            'Once charged, find and hold down the Power button on the right side of the terminal until the terminal screen turns on.'
          )}
        </li>
      </ol>
    </RegisterTerminalModalStep>
  );
};
