import { css } from '@emotion/react';
import { useThemeValues } from '../../../hooks';
import React, { Children, forwardRef } from 'react';

type FormRowColumns = number[];

interface FormRowProps {
  cols?: FormRowColumns;
  hidden?: boolean;
  children: React.ReactNode;
  className?: string;
}

type ChildStyleProps = { count: number; cols?: FormRowColumns };

const V_SPACE = 3;
const H_SPACE = 2;

const customColsStyle = (cols: FormRowColumns) => css`
  ${cols
    .map(
      (percent, idx) => `> :nth-of-type(${idx + 1}) {
    flex-basis: ${percent}%;
  }`
    )
    .join('')};
`;

const childStyles = (spacing: (...args: number[]) => string, { count, cols }: ChildStyleProps) => {
  const splitHSpace = H_SPACE / 2;
  return css`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-left: ${spacing(-splitHSpace)};
    margin-right: ${spacing(-splitHSpace)};

    > * {
      margin-left: ${spacing(splitHSpace)};
      margin-right: ${spacing(splitHSpace)};
      ${!cols && `flex-basis: ${100 / count}%;`}
    }

    ${cols?.length && customColsStyle(cols)}
  `;
};

export const FormRow = forwardRef<HTMLDivElement, FormRowProps>(({ children, cols, hidden, ...rest }, ref) => {
  const { spacing } = useThemeValues();
  if (hidden) return null;
  const count = Children.toArray(children).length;
  return (
    <div
      ref={ref}
      css={[
        css`
          margin-bottom: ${spacing(V_SPACE)};
        `,
        count > 1 && childStyles(spacing, { count, cols }),
        count === 1 &&
          cols?.length &&
          css`
            > * {
              width: calc(${cols[0]}% - ${H_SPACE / 2}px);
            }
          `,
      ]}
      {...rest}
    >
      {children}
    </div>
  );
});
