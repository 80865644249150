import { useRef, useCallback, useReducer } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { useEventListener } from '../../hooks';
import { useSlider } from './hooks';
import { Track, Thumb, Label } from './molecules';
import { SliderContextType, SliderProps, SliderContext } from './provider';
import { thumbStateReducer } from './reducer';
import type { RangeValue, SingleValue } from './types';

export function Slider<T extends boolean = false>({
  min,
  max,
  step,
  value: controlledValue,
  defaultValue,
  onChange,
  isRange,
  valueDisplay = 'hover',
  ariaLabel = 'Slider',
  formatValue,
}: SliderProps<T>) {
  const sliderRef = useRef<HTMLDivElement>(null);
  const { value, handleChange } = useSlider({
    min,
    max,
    step,
    value: controlledValue,
    defaultValue,
    onChange,
    isRange,
  });

  const [thumbState, thumbDispatch] = useReducer(thumbStateReducer, {
    isDragging: false,
    hoveredThumb: null,
    focusedThumb: null,
  });

  const getPercentage = useCallback((value: number) => ((value - min) / (max - min)) * 100, [min, max]);

  const updateValue = useCallback(
    (newValue: number, index?: 0 | 1) => {
      if (isRange && Array.isArray(value)) {
        const updatedValue = [...value] as RangeValue;

        const updateRangeValue = (val: number, idx: 0 | 1) => {
          updatedValue[idx] = idx === 0 ? Math.min(val, updatedValue[1] - step) : Math.max(val, updatedValue[0] + step);
        };

        if (index !== undefined) {
          updateRangeValue(newValue, index);
        } else {
          const [distToLower, distToUpper] = [
            Math.abs(newValue - updatedValue[0]),
            Math.abs(newValue - updatedValue[1]),
          ];
          updateRangeValue(newValue, distToLower <= distToUpper ? 0 : 1);
        }

        handleChange(updatedValue as T extends true ? RangeValue : SingleValue);
      } else if (!isRange) {
        handleChange(newValue as T extends true ? RangeValue : SingleValue);
      }
    },
    [isRange, value, handleChange, step]
  );

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      if (thumbState.isDragging === false) return;

      const slider = sliderRef.current;
      if (!slider) return;

      const rect = slider.getBoundingClientRect();
      const position = Math.min(Math.max(0, event.clientX - rect.left), rect.width);
      const percentage = position / rect.width;
      const newValue = percentage * (max - min) + min;
      updateValue(Math.round(newValue / step) * step, thumbState.isDragging as 0 | 1);
    },
    [thumbState.isDragging, min, max, step, updateValue]
  );

  const handleMouseUp = useCallback(() => {
    thumbDispatch({ type: 'SET_DRAGGING', payload: false });
  }, []);

  useEventListener('mousemove', handleMouseMove, thumbState.isDragging !== false);
  useEventListener('mouseup', handleMouseUp, thumbState.isDragging !== false);

  const contextValue: SliderContextType<T> = {
    min,
    max,
    step,
    value,
    isRange,
    valueDisplay,
    formatValue,
    updateValue,
    getPercentage,
    thumbState,
    thumbDispatch,
  };

  return (
    <SliderContext.Provider value={contextValue}>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: ${theme.spacing(2)};
        `}
      >
        <Label isMin />

        <div css={containerStyles} id='slider-container' ref={sliderRef}>
          <Track />
          {isRange && Array.isArray(value) ? (
            <>
              <Thumb index={0} ariaLabel={`${ariaLabel} lower bound`} />
              <Thumb index={1} ariaLabel={`${ariaLabel} upper bound`} />
            </>
          ) : (
            <Thumb index={0} ariaLabel={ariaLabel} />
          )}
        </div>
        <Label />
      </div>
    </SliderContext.Provider>
  );
}

const containerStyles = css`
  position: relative;
  width: 100%;
  height: 40px;
  touch-action: none;
`;
