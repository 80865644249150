import { DepartmentsSchemaServiceV1 } from '@frontend/schema';
import { LocationsSearchResponse } from '@weave/schema-gen-ts/dist/schemas/location/v2/data-location/messages.pb';
import { http } from '@frontend/fetch';
import { Location } from '@frontend/location-helpers';
import { GetTimezoneTypes, SearchLocation, UnprivilegedLocation } from './types';

const baseUrl = 'portal/v1';

/**
 * This is used to query location data for non-Weave users.
 * The data returned from this endpoint is slightly different from the privileged version.
 */
export const getNonPrivilegedLocations = (params: string) =>
  http.getData<UnprivilegedLocation[]>(`${baseUrl}/locations/?${params}`, {
    skipValidation: true,
  });

// export const getLocationOrgIds = () => http.get<{ orgId: string[] }>(`location/v2/data-location/organizations`);

/**
 * This is used to query location data for Weave users.
 * The location data returned is slightly different than the one used in the unprivileged version.
 */
export const getPrivilegedLocations = async (query: string) =>
  // We use the desktop version here because that does not require a location id header
  http.get<LocationsSearchResponse>(`/location/v2/data-location/locations/search?searchText=${query}`, {
    skipValidation: true,
  });

export const getLocation = (value: string | string[]) =>
  http.getData<Location>(`${baseUrl}/location/${value}`, {
    skipValidation: true,
  });

export const getFamilyLocations = (locationId: string) =>
  http.getData<Location[]>(`${baseUrl}/locations/${locationId}/family`);

export const getChildrenLocations = (locationId: string) =>
  http.getData<Location[]>(`${baseUrl}/location/${locationId}/children`, { skipValidation: true });

export const searchLocation = (search: string) =>
  http.getData<SearchLocation[]>(`portal/locations/${search}`, {
    skipValidation: true,
  });

// This service might be under Departments but this endpoint provides office timezone.
export const getOfficeTimezone = (
  req: GetTimezoneTypes['input'],
  opts?: { locationId?: string }
): Promise<GetTimezoneTypes['output']> => {
  return DepartmentsSchemaServiceV1.ReadTimezone(req, {
    headers: opts?.locationId ? { 'Location-Id': opts?.locationId } : undefined,
  });
};
