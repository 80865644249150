import { css } from '@emotion/react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { NakedButton } from '../../naked';
import { IconButton } from '../icon-button';
import { useSlideCarousel } from './provider';

type SlideNavigationProps = {
  variant?: 'default' | 'fullIndicators';
};

export const SlideNavigation = ({ variant = 'default' }: SlideNavigationProps) => {
  const { activeId, setActiveId, slideIds } = useSlideCarousel();
  const activeIndex = slideIds.indexOf(activeId);

  const handlePrev = () => {
    if (activeIndex > 0) {
      setActiveId(slideIds[activeIndex - 1]);
    }
  };

  const handleNext = () => {
    if (activeIndex < slideIds.length - 1) {
      setActiveId(slideIds[activeIndex + 1]);
    }
  };

  const isFullIndicatorsVariant = variant === 'fullIndicators';

  return (
    <footer css={buttonContainerStyle}>
      {!isFullIndicatorsVariant && (
        <div css={css({ display: 'flex', gap: theme.spacing(1) })}>
          <IconButton css={buttonStyle} size='xsmall' label='Next' disabled={activeIndex === 0} onClick={handlePrev}>
            <Icon name='caret-left' />
          </IconButton>
          <IconButton
            css={buttonStyle}
            size='xsmall'
            label='Forward'
            disabled={activeIndex === slideIds.length - 1}
            onClick={handleNext}
          >
            <Icon name='caret-right' />
          </IconButton>
        </div>
      )}
      <div css={[css({ display: 'flex', gap: theme.spacing(1) }), isFullIndicatorsVariant && css({ width: '100%' })]}>
        {slideIds.map((id, index) => (
          <ActiveSectionIndicator
            variant={variant}
            key={id}
            isActive={activeIndex === index}
            onClick={() => setActiveId(id)}
          />
        ))}
      </div>
    </footer>
  );
};

const buttonStyle = css({
  padding: theme.spacing(0.5),
});

interface ActiveSectionIndicatorProps {
  isActive: boolean;
  onClick: () => void;
  variant?: SlideNavigationProps['variant'];
}

const ActiveSectionIndicator = ({ isActive, onClick, variant }: ActiveSectionIndicatorProps) => {
  const isFullIndicatorsVariant = variant === 'fullIndicators';

  return (
    <NakedButton
      onClick={onClick}
      css={[
        css`
          display: block;
          height: 8px;
          width: 8px;
          border-radius: ${theme.borderRadius.full};
          transition: background-color 0.3s ease;
          background-color: ${isActive ? theme.colors.neutral90 : theme.colors.neutral20};
          cursor: pointer;
        `,
        isFullIndicatorsVariant &&
          css`
            width: 100%;
            background-color: ${isActive ? theme.colors.primary50 : theme.colors.neutral10};
          `,
      ]}
    />
  );
};

const buttonContainerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  alignItems: 'baseline',
});
