import { contextFactory } from '../../hooks';
import { TextProps } from '../text';

export interface BreadcrumbsProps {
  children: React.ReactNode;
  size?: TextProps['size'];
}

interface BreadcrumbsContext {
  size?: TextProps['size'];
}

export const [BreadcrumbsContext, useBreadcrumbs] = contextFactory<BreadcrumbsContext>();

export function BreadcrumbsProvider({ children, size }: BreadcrumbsProps) {
  return (
    <BreadcrumbsContext.Provider
      value={{
        size,
      }}
    >
      {children}
    </BreadcrumbsContext.Provider>
  );
}
