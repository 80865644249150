import React from 'react';
import { NakedButton } from '../../../../naked';
import { useStyles } from '../../../../use-styles';
import { useRadioContext } from '../../providers';

type Props = {
  children: React.ReactNode;
  value: string;
  trackingId?: string;
};

type ClickEvent = (e: React.MouseEvent<HTMLButtonElement>) => void;

export const OptionSwitchOption = React.memo(({ children, trackingId, value }: Props) => {
  const styles = useStyles('OptionSwitchOption');
  const { getOptionProps } = useRadioContext();
  const { active, checked, onChange, ...optProps } = getOptionProps(value);
  const handleClick: unknown = onChange;
  return (
    <NakedButton css={styles} {...optProps} onClick={handleClick as ClickEvent} type='button' trackingId={trackingId}>
      {children}
    </NakedButton>
  );
});
