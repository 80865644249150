import { css } from '@emotion/react';
import type { CalContainerThemeValues, CalWeekHeaderThemeValues } from './common-theme';
import {
  calContainerTheme,
  calContainerThemeOriginal,
  calWeekHeaderTheme,
  calWeekHeaderThemeOriginal,
} from './common-theme';
import { tint } from '../../../helpers';

type CalWeekHeaderStyleProps = { fontWeight?: string | false };

type CalContainerStyleProps = { btnDiameter: number };

export const calWeekHeaderStyle = (
  { color, spacing, fontSize, fontWeight }: CalWeekHeaderThemeValues,
  props: CalWeekHeaderStyleProps
) => css`
  color: ${color};
  font-size: ${fontSize(16)};
  margin-bottom: ${spacing(1)};
  ${props?.fontWeight && `font-weight: ${fontWeight[props?.fontWeight]};`}
`;

export const calContainerStyle = (
  {
    spacing,
    primaryColor,
    disabledColor,
    disabledBorderColor,
    hoverBackgroundColor,
    backgroundColor,
    boldFontWeight,
    zIndex,
    ringWidth,
    highlightColor,
    focusColor,
    focusBackgroundColor,
    borderRadius,
  }: CalContainerThemeValues,
  { btnDiameter }: CalContainerStyleProps
) => {
  const circleDiameter = spacing(btnDiameter);
  const inRangeBackground = highlightColor ?? tint(0.85, primaryColor);
  const spaceBetweenButtons = spacing(1);
  return css`
    width: ${7 * (btnDiameter * 8 + 8)}px;

    .calendar__months__years {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .cal__month-name {
      margin: 0;
    }

    .cal__day {
      height: ${circleDiameter};
      width: ${circleDiameter};
      border-radius: 50%;
      margin: calc(${spaceBetweenButtons} / 2);
    }

    button.cal__day {
      position: relative;

      ::after {
        content: '';
        width: ${ringWidth};
        height: ${ringWidth};
        display: block;
        border: 2px solid ${primaryColor};
        border-radius: 50%;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: opacity 150ms ease-out;
      }

      &[aria-disabled='true'] {
        color: ${disabledColor};
        cursor: default;

        ::after {
          border-color: ${disabledBorderColor};
        }
      }

      :focus {
        outline: none;
      }

      :focus:not(:active)::after {
        opacity: 1;
      }

      :hover:not([aria-disabled='true']) {
        /*Make sure our range-start and range-end don't override */
        background-color: ${hoverBackgroundColor} !important;
      }

      &[in-range='true']:hover {
        /*Make sure our range-start and range-end don't override */
        background-color: ${inRangeBackground} !important;
      }

      /*Selected day styles */
      &[aria-selected='true'] {
        color: ${backgroundColor};
        background-color: ${primaryColor};

        :hover,
        :focus {
          /*Make sure our range-start and range-end don't override */
          background-color: ${focusBackgroundColor} !important;
          ${focusColor && `color: ${focusColor} !important;`}
        }
      }

      &[data-is-today='true']:not([aria-selected='true']) {
        color: ${primaryColor};
        font-weight: ${boldFontWeight};

        &[aria-disabled='true'] {
          color: ${tint(0.5, primaryColor)};
        }
      }

      &[in-range='true'] {
        :not(:first-of-type):not(:last-of-type):not([range-start='true']):not([range-end='true'])::before {
          content: '';
          position: absolute;
          height: ${circleDiameter};
          width: calc(${circleDiameter} + ${spaceBetweenButtons});
          top: 0;
          left: 0;
          z-index: ${zIndex};
          background-color: ${inRangeBackground};
        }

        :first-of-type:not(:last-of-type):not([range-start='true']):not([range-end='true']) {
          background-color: ${inRangeBackground};
          border-radius: 50%;

          ::before {
            width: calc(${circleDiameter} / 2 + ${spaceBetweenButtons});
            left: 50%;
            content: '';
            position: absolute;
            height: ${circleDiameter};
            top: 0;
            z-index: ${zIndex};
            background-color: ${inRangeBackground};
          }
        }

        &[range-start='true']:not(:last-of-type)::before {
          width: calc(${circleDiameter} / 2 + ${spaceBetweenButtons});
          left: 50%;
          content: '';
          position: absolute;
          height: ${circleDiameter};
          top: 0;
          z-index: ${zIndex};
          background-color: ${inRangeBackground};
        }

        :last-of-type:not([range-start='true']):not([range-end='true']) {
          background-color: ${inRangeBackground};
          border-radius: 50%;

          :not(:first-of-type)::before {
            width: calc(${circleDiameter} / 2 + ${spaceBetweenButtons});
            right: 50%;
            content: '';
            position: absolute;
            height: ${circleDiameter};
            top: 0;
            z-index: ${zIndex};
            background-color: ${inRangeBackground};
          }
        }

        &[range-end='true']:not(:first-of-type)::before {
          width: calc(${circleDiameter} / 2 + ${spaceBetweenButtons});
          right: 50%;
          content: '';
          position: absolute;
          height: ${circleDiameter};
          top: 0;
          z-index: ${zIndex};
          background-color: ${inRangeBackground};
        }
      }
    }

    button.cal__months {
      text-align: center;
      flex: 0 0 30%;
      padding: ${spacing(1.5)} 0;
      border-radius: ${borderRadius};
      outline: none;
      position: relative;

      ::after {
        content: '';
        width: calc(100% + ${spacing(1.5)});
        height: calc(100% + ${spacing(1.5)});
        display: block;
        border: 2px solid ${primaryColor};
        border-radius: ${borderRadius};
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: opacity 150ms ease-out;
      }

      &:not(:nth-last-of-type(-n + 3)) {
        margin-bottom: ${spacing(1.5)};
      }

      &[data-is-today='true']:not([aria-selected='true']) {
        color: ${primaryColor};
        font-weight: ${boldFontWeight};

        &[aria-disabled='true'] {
          color: ${tint(0.5, primaryColor)};
        }
      }

      &[aria-disabled='true'] {
        color: ${disabledColor};
        cursor: default;

        ::after {
          border-color: ${disabledBorderColor};
        }
      }

      &[aria-selected='true'] {
        color: ${backgroundColor};
        background-color: ${primaryColor};

        :hover,
        :focus {
          background-color: ${focusBackgroundColor};
          ${focusColor && `color: ${focusColor};`}
        }
      }

      :hover:not([aria-disabled='true']) {
        background-color: ${hoverBackgroundColor};
        transition: background-color 0.2s ease;
      }

      &:focus {
        transition: fill 250ms ease-in-out;
        ::after {
          opacity: 1;
        }
      }
    }
    .calendar__single {
      & button {
        padding: ${spacing(2.5)} 0;
      }
    }
  `;
};

export const CalendarCommonStyles = {
  CalendarCommonStyles: {
    calWeekHeaderStyles: (props: CalWeekHeaderStyleProps) => calWeekHeaderStyle(calWeekHeaderTheme, props),
    calContainerStyles: (props: CalContainerStyleProps) =>
      calContainerStyle(calContainerTheme, {
        btnDiameter: props?.btnDiameter || 4,
      }),
  },
};

export const CalendarCommonStylesOriginal: typeof CalendarCommonStyles = {
  CalendarCommonStyles: {
    calWeekHeaderStyles: (props: CalWeekHeaderStyleProps) => calWeekHeaderStyle(calWeekHeaderThemeOriginal, props),
    calContainerStyles: (props: CalContainerStyleProps) =>
      calContainerStyle(calContainerThemeOriginal, {
        btnDiameter: props?.btnDiameter || 4,
      }),
  },
};
