/**
 * Replacement of polished lib
 * Taken from https://bit.cloud/styled-components/polished/types/color/~code
 */

/**
 * @property {number}  hue
 * @property {number}  saturation
 * @property {number}  lightness
 */
export type HslColor = {
  hue: number;
  saturation: number;
  lightness: number;
};

/**
 * @property {number}  hue
 * @property {number}  saturation
 * @property {number}  lightness
 * @property {number}  alpha
 */
export type HslaColor = {
  hue: number;
  saturation: number;
  lightness: number;
  alpha: number;
};

/**
 * @property {number}  red
 * @property {number}  green
 * @property {number}  blue
 */
export type RgbColor = {
  red: number;
  green: number;
  blue: number;
};

/**
 * @property {number}  red
 * @property {number}  green
 * @property {number}  blue
 * @property {number}  alpha
 */
export type RgbaColor = {
  red: number;
  green: number;
  blue: number;
  alpha: number;
};

export const isRgbaColor = (color: RgbColor | RgbaColor): color is RgbaColor => 'alpha' in color;
export const isRgbColor = (color: RgbColor | RgbaColor): color is RgbColor => !('alpha' in color);
