import { KeyNames } from '../../../constants/key-names';
import { useUid } from '../../../hooks/use-uid';
import { isFunction } from 'lodash-es';
import { createContext, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import type { FlyoutRefType } from '../../flyouts/types';
import { useFlyoutState } from '../../flyouts/use-flyout-state';
import { ListboxProvider } from '../../listbox/listbox.provider';
import { getLabelId } from '../atoms/utils';
import { useDebouncedBlur } from '../hooks/use-debounced-blur/use-debounced-blur';
import type { BasicFormFieldProps } from '../layouts/types';

import type { FocusEvent, KeyboardEvent, MutableRefObject, ReactElement } from 'react';

type FieldProps = Pick<BasicFormFieldProps<'dropdown'>, 'id' | 'name' | 'onBlur' | 'onChange' | 'onFocus'> & {
  value: string | string[];
};

export type ListboxTriggerProps<T extends HTMLElement> = {
  'aria-haspopup': string;
  'aria-expanded': boolean;
  'aria-labelledby': string;
  id: string;
  onBlur?: () => void;
  onClick: () => void;
  onFocus?: () => void;
  onKeyDown: (e: KeyboardEvent) => void;
  ref: FlyoutRefType<T>;
};

export type DropdownContextType = {
  active: boolean;
  debouncedBlur: () => void;
  debouncedFocus: () => void;
  id: string;
  labelId: string;
  menuRef: MutableRefObject<HTMLElement | null>;
  setActive: (active: boolean) => void;
  triggerProps: ListboxTriggerProps<HTMLInputElement>;
};

const DropdownContext = createContext<DropdownContextType>({} as DropdownContextType);

export type DropdownChildren = ReactElement | Array<ReactElement | null>;

type DropdownProviderProps = FieldProps & {
  children?: DropdownChildren;
};

export const DropdownProvider = ({ children, id, name, onBlur, onChange, onFocus, value }: DropdownProviderProps) => {
  const containerRef = useRef<HTMLElement>(null);
  const onToggle = useCallback(({ active }: any) => {
    // the input will no longer be focused after opening the flyout
    // so make sure to update field's active state when clicking outside
    // @ts-ignore
    if (!active && isFunction(onBlur)) onBlur({ target: { name } });
  }, []);
  const { active, flyoutRef, triggerRef, setActive } = useFlyoutState<any, HTMLInputElement>({
    onToggle,
    ignoreRefs: [containerRef],
  });

  // focus handling between trigger and menu
  // so we don't lose the menu or field active state until blurring field w/o
  // moving to menu, or menu w/o moving to field
  const { blur, focus } = useDebouncedBlur({
    onBlur: useCallback(
      (e?: FocusEvent<HTMLInputElement>) => {
        setActive(false);
        if (e) {
          onBlur({
            ...e,
            target: {
              ...e.target,
              name,
            },
          });
        } else {
          onBlur();
        }
      },
      [name]
    ),
    onFocus: useCallback(
      (e?: FocusEvent<HTMLInputElement>) => {
        if (e) {
          onFocus({
            ...e,
            target: {
              ...e.target,
              name,
            },
          });
        } else {
          onFocus();
        }
      },
      [name]
    ),
  });

  // return focus to trigger on esc and select (enter/click)
  const focusTrigger = () => {
    setTimeout(() => {
      triggerRef?.current?.focus();
    }, 0);
  };

  const onEscape = useCallback(() => {
    setActive(false);
    focusTrigger();
  }, []);

  const isMultiselect = Array.isArray(value);
  const onSelect = useCallback(
    (value: string) => {
      onChange({ name, value });
      if (!isMultiselect) {
        setActive(false);
        focusTrigger();
      }
    },
    [name, isMultiselect]
  );

  useEffect(() => {
    if (active) {
      setTimeout(() => {
        flyoutRef.current?.focus();
      }, 0);
    }
  }, [active]);

  const listboxId = useUid();
  const labelId = getLabelId(id);
  const triggerId = `${listboxId.current}-trigger`;

  const contextValue = useMemo(
    () => ({
      active,
      // expose debounced focus handling,
      // for cases with focusable elements inside the menu
      debouncedBlur: blur,
      debouncedFocus: focus,
      id: listboxId.current,
      labelId,
      // ref for dropdowns where menu is not the listbox (eg multiselect)
      menuRef: containerRef,
      setActive,
      triggerProps: {
        id: triggerId,
        'aria-expanded': active,
        'aria-haspopup': 'listbox',
        'aria-labelledby': `${labelId} ${triggerId}`,
        onBlur: blur,
        onFocus: focus,
        onClick: () => {
          setActive((active) => !active);
        },
        onKeyDown: (e: KeyboardEvent) => {
          switch (e.key) {
            case KeyNames.Enter:
            case KeyNames.Space:
            case KeyNames.Up:
            case KeyNames.Down:
              if (!active) setActive(true);
              break;
            default:
              break;
          }
        },
        ref: triggerRef,
      },
    }),
    [active, labelId, triggerId, blur, focus]
  );

  return (
    <DropdownContext.Provider value={contextValue}>
      <ListboxProvider
        active={active}
        id={listboxId.current}
        labelId={labelId}
        onBlur={blur}
        onFocus={focus}
        onEscape={onEscape}
        // @ts-ignore
        onSelect={onSelect}
        listboxRef={flyoutRef}
        value={value}
      >
        {children}
      </ListboxProvider>
    </DropdownContext.Provider>
  );
};

export function useDropdownContext(): DropdownContextType {
  const context = useContext(DropdownContext);
  if (typeof context === 'undefined') {
    throw new Error('useDropdownContext must be used inside a DropdownProvider');
  }
  return context;
}
