import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { RedoAction, UndoAction } from '../../molecules';
import { REDO_COMMAND, UNDO_COMMAND } from 'lexical';
import { useCallback } from 'react';
import { useToolbarState } from '../../providers';

export const History = () => {
  const [editor] = useLexicalComposerContext();
  const { canUndo, canRedo } = useToolbarState(['canUndo', 'canRedo']);

  const onUndoSelect = useCallback(() => {
    editor.dispatchCommand(UNDO_COMMAND, undefined);
  }, [editor]);

  const onRedoSelect = useCallback(() => {
    editor.dispatchCommand(REDO_COMMAND, undefined);
  }, [editor]);

  return (
    <>
      <UndoAction disabled={!canUndo} onClick={onUndoSelect} className='toolbar-item spaced' aria-label='Undo' />
      <RedoAction disabled={!canRedo} onClick={onRedoSelect} className='toolbar-item' aria-label='Redo' />
    </>
  );
};

History.displayName = 'History';
