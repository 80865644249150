/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const ClockIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M12.5,12.5774575 L16.209017,14.431966 C16.8264901,14.7407026 17.0767705,15.4915439 16.768034,16.109017 C16.4592974,16.7264901 15.7084561,16.9767705 15.090983,16.668034 L10.690983,14.468034 C10.267503,14.256294 10,13.8234651 10,13.35 L10,6.75 C10,6.05964406 10.5596441,5.5 11.25,5.5 C11.9403559,5.5 12.5,6.05964406 12.5,6.75 L12.5,12.5774575 Z M12,22.25 C6.33908131,22.25 1.75,17.6609187 1.75,12 C1.75,6.33908131 6.33908131,1.75 12,1.75 C17.6609187,1.75 22.25,6.33908131 22.25,12 C22.25,17.6609187 17.6609187,22.25 12,22.25 Z M12,19.75 C16.2802068,19.75 19.75,16.2802068 19.75,12 C19.75,7.71979319 16.2802068,4.25 12,4.25 C7.71979319,4.25 4.25,7.71979319 4.25,12 C4.25,16.2802068 7.71979319,19.75 12,19.75 Z'
    />
  </Icon>
);
