export function processArrayBuffer(buffer: Buffer | ArrayBuffer): string {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  // Convert the int array to a binary string
  // We loop through the bytes, instead of using the Array in an apply(), since
  // String.fromCharCode().apply() hits a maximum size when the bytelength
  // is more than 65536 (which is the limit for apply()).
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }

  return binary;
}

export function arrayBufferToBase64(buffer: Buffer | ArrayBuffer) {
  // Convert to base64
  return window.btoa(processArrayBuffer(buffer));
}
