import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../component-theme';

export type AvatarThemeValues = {
  spacing: WeaveThemeValues['Spacing'];
  borderRadius: WeaveThemeValues['BorderRadius'];
  defaultBorderColor: WeaveThemeValues['Color'];
};

export const avatarTheme: AvatarThemeValues = {
  spacing: theme.spacing,
  borderRadius: theme.borderRadius.full,
  defaultBorderColor: theme.colors.white,
};

export const avatarThemeOriginal: AvatarThemeValues = {
  spacing: themeOriginal.spacing,
  borderRadius: '999px',
  defaultBorderColor: themeOriginal.colors.white,
};
