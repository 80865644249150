import type { BoolFieldProps, BoolFieldKey, BoolInputProps } from './types';
import { shouldShowError } from '../atoms/utils';
import { BaseBoolLayout } from './base-bool-layout.component';

export const BoolFieldLayout = <T extends BoolFieldKey>({
  active,
  className,
  error,
  field,
  hidden,
  labelPlacement = 'right',
  label,
  labelDisabled,
  onBlur,
  onFocus,
  onChange,
  touched,
  ...rest
}: BoolFieldProps<T>) => {
  if (hidden) return null;
  const Field = field;

  const fieldProps: unknown = {
    ...rest,
    active,
    error: shouldShowError({ error, touched, ...rest }),
    onBlur,
    onFocus,
    onChange: () => {
      onChange({ name: rest.name, value: !rest.value });
    },
  };

  return (
    <BaseBoolLayout
      id={rest.id}
      label={label}
      labelPlacement={labelPlacement}
      labelDisabled={labelDisabled}
      className={className}
      disabled={rest.disabled}
    >
      {/* @ts-ignore */}
      <Field {...(fieldProps as BoolInputProps<T>)} />
    </BaseBoolLayout>
  );
};
