import { gql } from 'graphql-request';
import { PayoutModel } from '../types';

export interface PayoutQueryVariables {
  payoutId?: string;
}

export interface PayoutQueryResponse {
  payout: PayoutModel;
}

export const payoutQuery = gql`
  query payout($payoutId: String!, $filter: PayoutFilterInput) {
    payout(payoutId: $payoutId, filter: $filter) {
      payoutId
      locationId
      amount
      status
      createdAt
      estimatedArrivalOn
      destination {
        destinationId
        accountName
        brand
        country
        currency
        destinationType
        fingerprint
        last4
        routingNumber
      }
      transactions {
        items {
          transactionId
          amount
          eta
          createdAt
          currency
          fee
          net
          status
          transactionType
          firstName
          lastName
          pmid
        }
      }
      summary {
        total
        items {
          itemType
          count
          gross
          fees
          net
        }
      }
    }
  }
`;
