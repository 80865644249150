import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../component-theme/theme-types';

export type XIconThemeValues = {
  iconColor: WeaveThemeValues['Color'];
  spacing: WeaveThemeValues['Spacing'];
};

export type LinkThemeValues = {
  linkTextColor: WeaveThemeValues['Color'];
};

export const xIconTheme: XIconThemeValues = {
  iconColor: theme.colors.neutral20,
  spacing: theme.spacing,
};

export const xIconThemeOriginal: XIconThemeValues = {
  iconColor: themeOriginal.colors.gray[400],
  spacing: themeOriginal.spacing,
};

export const linkTheme: LinkThemeValues = {
  linkTextColor: theme.colors.primary50,
};

export const linkThemeOriginal: LinkThemeValues = {
  linkTextColor: themeOriginal.colors.weaveBlue,
};
