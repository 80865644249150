import { Icon, IconProps } from '../Icon.component';

export const CubeIconSmall: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={16} {...props} viewBox='0 0 16 16'>
    <path d='M8.00024 13.6238L8.00024 7.58636' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.73065 1.18859C8.27654 0.935366 7.72367 0.935366 7.26956 1.18859L2.09958 4.07154C1.70589 4.29107 1.51829 4.73717 1.60887 5.15601C1.59424 5.24072 1.58657 5.32849 1.58657 5.41889V10.6887C1.58657 11.2326 1.88099 11.7339 2.35602 11.9988L7.50117 14.8679C7.52276 14.8803 7.54477 14.8919 7.56715 14.9026C7.70623 14.9696 7.85409 15.0015 8.00012 15.0013C8.14615 15.0015 8.29401 14.9696 8.43309 14.9026C8.45548 14.8919 8.47749 14.8803 8.49908 14.8679L13.6442 11.9988C14.1193 11.7339 14.4137 11.2326 14.4137 10.6887V5.41889C14.4137 5.32846 14.406 5.24066 14.3914 5.15592C14.4819 4.73711 14.2943 4.29106 13.9006 4.07154L8.73065 1.18859ZM11.022 4.75628L8.0001 3.07116L4.97819 4.75627L8.00012 6.44139L11.022 4.75628ZM8.49912 8.45307C8.47752 8.46548 8.45549 8.47706 8.43309 8.4878C8.29401 8.55475 8.14615 8.58665 8.00012 8.58651C7.85409 8.58665 7.70623 8.55475 7.56715 8.4878C7.54475 8.47706 7.52272 8.46548 7.50112 8.45307L3.58657 6.27019V10.3951L8.00012 12.8562L12.4137 10.3951V6.27019L8.49912 8.45307Z'
    />
  </Icon>
);
