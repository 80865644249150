export const InboxPrefixes = {
  List: 'inbox-list',
  Popout: 'inbox-popout',
  ScheduledMessages: 'inbox-scheduled-messages',
  DraftMessages: 'inbox-draft-messages',
  DraftList: 'inbox-draft-list',
  Signature: 'inbox-signature',
  Tag: 'inbox-tags',
  Thread: 'inbox-thread',
};
