import { Optional } from 'ts-toolbelt/out/Object/Optional';
import { PersonsV3 } from '@frontend/api-person';
import { useTranslation } from '@frontend/i18n';
import { AssociatedContactsListItem } from '../associated-contacts-list-item';
import { ThreadDrawer, ThreadDrawerProps } from '../thread-drawer';
import { useThreadHeaderContext } from '../thread-header';

type AssociatedContactsDrawerProps = Optional<
  Omit<ThreadDrawerProps, 'open' | 'children' | 'closeLabel'>,
  'onClose'
> & {
  onSelect?: (personId: string) => void;
};

export const AssociatedContactsDrawer = ({ onSelect, onClose, ...rest }: AssociatedContactsDrawerProps) => {
  const { t } = useTranslation('thread-header');
  const {
    groupId,
    personPhone,
    associatedContacts,
    associatedContactsDrawerIsOpen,
    setAssociatedContactsDrawerIsOpen,
  } = useThreadHeaderContext();
  const { mutate } = PersonsV3.PersonMutations.useUpsertPrimaryContactLegacyMutation({
    onSuccess: (_, req) => {
      onSelect?.(req.personId);
    },
  });
  const persons = associatedContacts || [];

  return (
    <ThreadDrawer
      open={associatedContactsDrawerIsOpen}
      onClose={() => {
        setAssociatedContactsDrawerIsOpen(false);
        onClose?.();
      }}
      closeLabel={t('Hide Associated Contacts')}
      {...rest}
    >
      <ul css={{ width: '100%', listStyle: 'none', margin: 0, paddingInlineStart: 0 }}>
        {persons.map((person) => (
          <AssociatedContactsListItem
            key={person.personId}
            personId={person.personId}
            person={person}
            onSelect={() => {
              mutate({ personId: person.personId, locationId: groupId, phoneNumber: personPhone });
              setAssociatedContactsDrawerIsOpen(false);
              onClose?.();
            }}
          />
        ))}
      </ul>
    </ThreadDrawer>
  );
};
