import { theme } from '@frontend/theme';
import { theme as originalTheme } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../component-theme/theme-types';

export interface TextButtonStyleValues {
  activeBackgroundColor: WeaveThemeValues['Color'];
  disabledTextColor: WeaveThemeValues['Color'];
  focusBackgroundColor: WeaveThemeValues['Color'];
  fontFamily: WeaveThemeValues['FontFamily'];
  fontSize: WeaveThemeValues['FontSize'];
  primaryColor: WeaveThemeValues['Color'];
  spacing: WeaveThemeValues['Spacing'];
  textColor: WeaveThemeValues['Color'];
  roundedBorderRadius: WeaveThemeValues['BorderRadius'];
  roundedHoverColor: WeaveThemeValues['Color'];
}

export const textButtonTheme: TextButtonStyleValues = {
  activeBackgroundColor: theme.colors.neutral10,
  disabledTextColor: theme.colors.neutral20,
  focusBackgroundColor: theme.colors.neutral5,
  fontFamily: theme.font.family,
  fontSize: theme.fontSize,
  primaryColor: theme.colors.primary50,
  spacing: theme.spacing,
  textColor: theme.colors.neutral90,
  roundedBorderRadius: theme.borderRadius.small,
  roundedHoverColor: theme.colors.neutral20,
};

export const textButtonThemeOriginal: TextButtonStyleValues = {
  activeBackgroundColor: originalTheme.colors.gray300,
  disabledTextColor: originalTheme.colors.gray400,
  focusBackgroundColor: originalTheme.colors.gray200,
  fontFamily: originalTheme.font.family,
  fontSize: originalTheme.fontSize,
  primaryColor: originalTheme.colors.weaveBlue,
  spacing: originalTheme.spacing,
  textColor: originalTheme.colors.gray600,
  roundedBorderRadius: theme.borderRadius.small,
  roundedHoverColor: originalTheme.colors.gray200,
};
