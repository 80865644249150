import { theme } from '@frontend/theme';
import { theme as originalTheme } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../component-theme/theme-types';
import type { WeekViewDatePickerTheme } from './types';

export type WeekViewDatePickerStyleValues = {
  background: WeaveThemeValues['Color'];
  borderRadius: WeaveThemeValues['BorderRadius'];
  color: WeaveThemeValues['Color'];
  disabledTextColor: WeaveThemeValues['Color'];
  hoverAndFocusBackgroundColor: WeaveThemeValues['Color'];
  hoverAndFocusTextColor: WeaveThemeValues['Color'];
  selectedBackgroundColor: WeaveThemeValues['Color'];
  selectedTextColor: WeaveThemeValues['Color'];
  spacing: WeaveThemeValues['Spacing'];
  subduedColor: WeaveThemeValues['Color'];
};

export const weekViewDatePickerTheme = (lightOrDarkVersion: WeekViewDatePickerTheme): WeekViewDatePickerStyleValues => {
  const darkColor = theme.colors.neutral90;

  return {
    background: lightOrDarkVersion === 'dark' ? darkColor : theme.colors.white,
    borderRadius: theme.borderRadius.medium,
    color: lightOrDarkVersion === 'dark' ? theme.colors.white : darkColor,
    disabledTextColor: theme.colors.text.disabled,
    hoverAndFocusBackgroundColor: theme.colors.neutral5,
    hoverAndFocusTextColor: theme.colors.neutral80,
    selectedBackgroundColor: theme.colors.text.interactive,
    selectedTextColor: theme.colors.white,
    spacing: theme.spacing,
    subduedColor: theme.colors.text.subdued,
  };
};

export const originalWeekViewDatePickerTheme = (
  lightOrDarkVersion: WeekViewDatePickerTheme
): WeekViewDatePickerStyleValues => {
  const darkColor = originalTheme.colors.gray600;

  return {
    background: lightOrDarkVersion === 'dark' ? darkColor : originalTheme.colors.white,
    borderRadius: originalTheme.borderRadius.medium,
    color: lightOrDarkVersion === 'dark' ? originalTheme.colors.white : darkColor,
    disabledTextColor: originalTheme.colors.disabled,
    hoverAndFocusBackgroundColor: originalTheme.colors.gray200,
    hoverAndFocusTextColor: originalTheme.colors.gray600,
    selectedBackgroundColor: originalTheme.colors.weaveBlue,
    selectedTextColor: originalTheme.colors.white,
    spacing: originalTheme.spacing,
    subduedColor: originalTheme.colors.gray500,
  };
};
