import { forwardRef, useEffect, useRef, KeyboardEvent } from 'react';
import { KeyNames } from '../../constants';
import { AnimatedMenu } from './animated-menu.component';
import { FlyoutMenuItem } from './flyout-menu-item.component';
import { useRefInterceptor } from '../../hooks';
import { FlyoutMenuProps } from './types';

/**
 *
 * @deprecated use PopoverMenu instead
 */
export const FlyoutMenu = forwardRef<HTMLUListElement, FlyoutMenuProps>(({ actions, onClose, ...rest }, ref) => {
  const indexRef = useRef(0);
  const [menuRef, elementRef] = useRefInterceptor(ref);

  const moveFocus = (index: number) => {
    indexRef.current = index;
    if (menuRef.current) {
      const nextItem = menuRef.current.querySelectorAll('li')[index];
      if (nextItem) {
        nextItem.focus();
      }
    }
  };

  const handleKeyDown = (action: () => void) => (e: KeyboardEvent, disabled?: boolean) => {
    switch (e.key) {
      case KeyNames.Escape:
        return onClose();
      case KeyNames.End:
        e.preventDefault();
        return moveFocus(actions.length - 1);
      case KeyNames.Enter:
        e.preventDefault();
        if (!disabled) {
          return action();
        }
      case KeyNames.Home:
        e.preventDefault();
        return moveFocus(0);
      case KeyNames.Down:
        e.preventDefault();
        if (indexRef.current + 1 === actions.length) {
          return moveFocus(0);
        }
        return moveFocus(indexRef.current + 1);
      case KeyNames.Up:
        e.preventDefault();
        if (indexRef.current === 0) {
          return moveFocus(actions.length - 1);
        }
        return moveFocus(indexRef.current - 1);
      default:
        break;
    }
  };

  useEffect(() => {
    moveFocus(0);
  }, []);

  return (
    <AnimatedMenu as={'ul'} {...rest} ref={elementRef}>
      {actions.map(({ onClick, ...rest }, i) => {
        const handleClick = () => {
          onClick();
          onClose();
        };
        return (
          <FlyoutMenuItem
            key={`menu-item-${i}`}
            {...rest}
            onClick={handleClick}
            onKeyDown={handleKeyDown(handleClick)}
          />
        );
      })}
    </AnimatedMenu>
  );
});
