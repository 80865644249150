import { createShallowStore, createStoreWithPersistAndSubscribe } from '@frontend/store';
import { TemplateType_Slug } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { STATIC_TEMPLATE_ID } from '../constants';

export type TemplateUsageStore = {
  lastTemplateUseTimestamps: Record<string, { timestamp: string; templateType: TemplateType_Slug }>;
  updateTemplateLastUseTimestamp: ({
    templateId,
    templateType,
    timestamp,
  }: {
    templateId: string;
    templateType: TemplateType_Slug;
    timestamp?: string | Date;
  }) => void;
};

export const useTemplateUsageStore = createStoreWithPersistAndSubscribe<TemplateUsageStore>(
  (set) => ({
    lastTemplateUseTimestamps: {},
    updateTemplateLastUseTimestamp: ({ templateId, templateType, timestamp }) =>
      set(
        (state) => {
          if (!templateId.includes(STATIC_TEMPLATE_ID))
            state.lastTemplateUseTimestamps[templateId] = {
              templateType,
              timestamp:
                typeof timestamp === 'string' ? timestamp : timestamp?.toISOString() || new Date().toISOString(),
            };
        },
        false,
        'UPDATE_TEMPLATE_RENDER_TIMESTAMP'
      ),
  }),
  { name: 'TemplateUsageStorage' },
  { name: 'TemplateUsage', trace: true }
);

export const useTemplateUsageShallowStore = createShallowStore(useTemplateUsageStore);
