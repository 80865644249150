import { useQuery, ContextlessQueryObserverOptions } from '@frontend/react-query-helpers';
import { HttpError } from '@frontend/fetch';
import { SchemaIO, SchemaPersonV3Service } from '@frontend/schema';
import { UseInfiniteQueryOptions, UseQueryOptions, useInfiniteQuery, useQueries, useQueryClient } from 'react-query';
import { DataSourcesHooks } from '@frontend/api-data-sources';
import { formatPhoneNumberE164 } from '@frontend/phone-numbers';

const defaultOptions: ContextlessQueryObserverOptions = {
  refetchOnWindowFocus: false,
  refetchOnMount: true,
};

export const queryKeys = {
  baseKey: ['persons-v3'],
  getPersonLegacy: (req: GetPersonLegacyIO['input']) => [...queryKeys.baseKey, 'get-person-legacy', { ...req }],
  personSearchLegacy: (req: PersonSearchLegacyIO['input']) => [
    ...queryKeys.baseKey,
    'person-search-legacy',
    { ...req },
  ],
  listPersonsByPhoneLegacy: (req: ListPersonsByPhoneLegacyIO['input']) => [
    ...queryKeys.baseKey,
    'list-persons-by-phone-legacy',
    { ...req },
  ],
  listPreferredPersonsByPhone: (req: ListPreferredPersonsByPhoneIO['input']) => [
    ...queryKeys.baseKey,
    'list-preferred-persons-by-phone',
    { ...req },
  ],
  getPrimaryContactLegacy: (req: GetPrimaryContactLegacyIO['input']) => [
    ...queryKeys.baseKey,
    'get-primary-contact-legacy',
    { ...req },
  ],
};

type GetPersonLegacyIO = SchemaIO<(typeof SchemaPersonV3Service)['GetPersonLegacy']>;
export const useGetPersonLegacyQuery = <T = GetPersonLegacyIO['output']>(
  req: GetPersonLegacyIO['input'],
  options?: UseQueryOptions<GetPersonLegacyIO['output'], unknown, T>
) =>
  useQuery({
    queryKey: queryKeys.getPersonLegacy(req),
    queryFn: () => SchemaPersonV3Service.GetPersonLegacy(req),
    ...defaultOptions,
    ...options,
  });

type PersonPerLocationTransformedResponse = {
  person?: GetPersonLegacyIO['output'];
  locationId: string;
};
/**
 * Attempts to fetch a person from each location in the provided list of locationIds.
 * This can be useful to identify if a person exists in a location when the origin of the person is unknown.
 */
export const useGetPersonPerLocationQueries = (
  { locationIds, ...req }: GetPersonLegacyIO['input'],
  options?: UseQueryOptions<GetPersonLegacyIO['output'], unknown, PersonPerLocationTransformedResponse>
) => {
  const queryClient = useQueryClient();
  return useQueries(
    locationIds.map((locationId) => ({
      queryKey: queryKeys.getPersonLegacy({ ...req, locationIds: [locationId] }),
      queryFn: () => SchemaPersonV3Service.GetPersonLegacy({ ...req, locationIds: [locationId] }),
      ...defaultOptions,
      select: (data?: GetPersonLegacyIO['output']): PersonPerLocationTransformedResponse => {
        return {
          locationId,
          person: data && Object.values(data).filter(Boolean).length ? data : undefined,
        };
      },
      retry: 0,
      ...options,
      onError: (err: unknown) => {
        if (options?.onError) options.onError(err);
        queryClient.setQueryData(queryKeys.getPersonLegacy({ ...req, locationIds: [locationId] }), undefined);
      },
    }))
  );
};

type PersonSearchLegacyIO = SchemaIO<(typeof SchemaPersonV3Service)['SearchPersonsLegacy']>;
export const usePersonSearchLegacyInfiniteQuery = (
  req: PersonSearchLegacyIO['input'],
  options?: UseInfiniteQueryOptions<PersonSearchLegacyIO['output'], HttpError>
) => {
  const { demoSourceIds } = DataSourcesHooks.useDemoLocationSourceIdsShallowStore('demoSourceIds');
  const modifiedRequest: PersonSearchLegacyIO['input'] = { sourceIds: demoSourceIds || [], ...req };

  return useInfiniteQuery({
    queryKey: queryKeys.personSearchLegacy(modifiedRequest),
    queryFn: ({ pageParam }) =>
      SchemaPersonV3Service.SearchPersonsLegacy({
        ...modifiedRequest,
        page: { ...req.page, token: pageParam || req.page?.token },
      }),
    getNextPageParam: (lastPage) => {
      const persons = lastPage.persons ?? [];
      const lastPageIsNotFull = req.page?.size ? persons.length < req.page.size : !persons.length;
      if (lastPageIsNotFull || !lastPage.nextPageToken) return undefined;

      return lastPage.nextPageToken;
    },
    ...defaultOptions,
    ...options,
  });
};

type ListPersonsByPhoneLegacyIO = SchemaIO<(typeof SchemaPersonV3Service)['ListPersonsByPhoneLegacy']>;
export const useListPersonsByPhoneLegacyInfiniteQuery = (
  req: ListPersonsByPhoneLegacyIO['input'],
  options?: UseInfiniteQueryOptions<ListPersonsByPhoneLegacyIO['output'], HttpError>
) =>
  useInfiniteQuery({
    queryKey: queryKeys.listPersonsByPhoneLegacy(req),
    queryFn: ({ pageParam }) =>
      SchemaPersonV3Service.ListPersonsByPhoneLegacy({
        ...req,
        page: { ...req.page, token: pageParam || req.page?.token },
      }),
    getNextPageParam: (lastPage) => {
      const persons = lastPage.persons ?? [];
      const lastPageIsNotFull = req.page?.size ? persons.length < req.page.size : !persons.length;
      if (lastPageIsNotFull || !lastPage.nextPageToken) return undefined;

      return lastPage.nextPageToken;
    },
    ...defaultOptions,
    ...options,
  });

type ListPreferredPersonsByPhoneIO = SchemaIO<(typeof SchemaPersonV3Service)['ListPreferredPersonsByPhoneLegacy']>;
export const useListPreferredPersonsByPhoneQuery = <E = unknown, D = ListPreferredPersonsByPhoneIO['output']>(
  req: ListPreferredPersonsByPhoneIO['input'],
  options?: UseQueryOptions<ListPreferredPersonsByPhoneIO['output'], E, D>
) => {
  const standardizedReq: typeof req = {
    ...req,
    phoneNumber: formatPhoneNumberE164(req.phoneNumber),
  };
  return useQuery({
    queryKey: queryKeys.listPreferredPersonsByPhone(standardizedReq),
    queryFn: () => SchemaPersonV3Service.ListPreferredPersonsByPhoneLegacy(standardizedReq),
    ...defaultOptions,
    ...options,
  });
};

type GetPrimaryContactLegacyIO = SchemaIO<(typeof SchemaPersonV3Service)['GetPrimaryContactLegacy']>;
export const useGetPrimaryContactLegacyQuery = <E = unknown, D = GetPrimaryContactLegacyIO['output']>(
  req: GetPrimaryContactLegacyIO['input'],
  options?: UseQueryOptions<GetPrimaryContactLegacyIO['output'], E, D>
) => {
  const standardizedReq: typeof req = {
    ...req,
    phoneNumber: formatPhoneNumberE164(req.phoneNumber),
  };
  return useQuery({
    queryKey: queryKeys.getPrimaryContactLegacy(standardizedReq),
    queryFn: () => SchemaPersonV3Service.GetPrimaryContactLegacy(standardizedReq),
    ...defaultOptions,
    ...options,
  });
};
