import { useCallback, useEffect } from 'react';
import { css } from '@emotion/react';
import {
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  $getSelection,
  $isRangeSelection,
  $isElementNode,
  type RangeSelection,
  type ElementFormatType,
} from 'lexical';
import { $isHeadingNode } from '@lexical/rich-text';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isLinkNode } from '@lexical/link';
import { $isAtNodeEnd, $getSelectionStyleValueForProperty } from '@lexical/selection';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import { $isListNode, ListNode } from '@lexical/list';
import { theme } from '@frontend/theme';
import { useToolbarState } from '../providers';
import {
  Link,
  BlockType,
  Bold,
  ElementFormat,
  FontColor,
  FontPicker,
  FontSize,
  History,
  Italic,
  Underline,
  DynamicField,
  EmojiPicker,
} from '../toolbar/components';

const LowPriority = 1;
const DEFAULT_FONT_SIZE = '16px';
const DEFAULT_FONT_COLOR = theme.font.colors.default;
const DEFAULT_FONT_FAMILY = 'DM Sans';

function getSelectedNode(selection: RangeSelection) {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }
}

type ToolbarPluginProps = {
  children: React.ReactNode | React.ReactNode[];
};

const ToolbarPlugin = ({ children }: ToolbarPluginProps) => {
  const [editor] = useLexicalComposerContext();

  const {
    setCanUndo,
    setCanRedo,
    setFontFamily,
    setFontColor,
    setIsBold,
    setIsItalic,
    setIsUnderline,
    setElementFormat,
    setBlockType,
    setFontSize,
    setIsLink,
  } = useToolbarState([
    'setCanUndo',
    'setCanRedo',
    'setFontColor',
    'setFontSize',
    'setFontFamily',
    'setIsBold',
    'setIsItalic',
    'setIsUnderline',
    'setElementFormat',
    'setBlockType',
    'setIsLink',
  ]);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element = anchorNode.getKey() === 'root' ? anchorNode : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type as 'ul');
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType();
          setBlockType(type as 'ul');
        }
      }
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));

      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }

      setFontSize($getSelectionStyleValueForProperty(selection, 'font-size', DEFAULT_FONT_SIZE));
      setFontColor($getSelectionStyleValueForProperty(selection, 'color', DEFAULT_FONT_COLOR));

      setFontFamily($getSelectionStyleValueForProperty(selection, 'font-family', DEFAULT_FONT_FAMILY));

      setElementFormat(
        ($isElementNode(parent)
          ? parent.getFormatType()
          : $isElementNode(node)
          ? node.getFormatType() || 'left'
          : null) as Extract<ElementFormatType, 'left' | 'center' | 'right'>
      );
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload) => {
          updateToolbar();
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        LowPriority
      )
    );
  }, [editor, updateToolbar]);

  return (
    <div
      css={css`
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 1px;
        gap: 4px;
        padding: 4px;
        width: 100%;
        margin-bottom: 20px;
        justify-content: stretch;
      `}
      className='toolbar-container'
    >
      {children}
    </div>
  );
};

ToolbarPlugin.displayName = 'Toolbar';

const ToolbarNamespace = Object.assign(ToolbarPlugin, {
  Link,
  BlockType,
  Bold,
  Italic,
  Underline,
  ElementFormat,
  FontColor,
  FontPicker,
  FontSize,
  History,
  DynamicField,
  EmojiPicker,
});

export { ToolbarNamespace as Toolbar };
