import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../../../component-theme/theme-types';

export type RadioCardThemeValues = {
  selectedColor: WeaveThemeValues['Color'];
  borderColor: WeaveThemeValues['Color'];
  hoverBGColor: WeaveThemeValues['Color'];
  fontSize: WeaveThemeValues['FontSize'];
  spacing: WeaveThemeValues['Spacing'];
  backgroundColor: WeaveThemeValues['Color'];
  borderRadius: WeaveThemeValues['BorderRadius'];
};

export const radioCardTheme: RadioCardThemeValues = {
  selectedColor: theme.colors.primary50,
  borderColor: theme.colors.neutral40,
  hoverBGColor: theme.colors.neutral5,
  fontSize: theme.fontSize,
  spacing: theme.spacing,
  backgroundColor: theme.colors.white,
  borderRadius: theme.borderRadius.medium,
};

export const radioCardThemeOriginal: RadioCardThemeValues = {
  selectedColor: themeOriginal.colors.weaveBlue,
  borderColor: themeOriginal.colors.gray[400],
  hoverBGColor: themeOriginal.colors.gray[100],
  fontSize: theme.fontSize,
  spacing: theme.spacing,
  backgroundColor: themeOriginal.colors.white,
  borderRadius: themeOriginal.borderRadius.medium,
};
