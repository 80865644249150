import { theme } from '@frontend/theme';
import { theme as originalTheme } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../component-theme/theme-types';

export interface ActionButtonStyleValues {
  background: WeaveThemeValues['Color'];
  color: WeaveThemeValues['Color'];
  borderColor: WeaveThemeValues['Color'];
  secondaryColor: WeaveThemeValues['Color'];
  mediumDiameter: number;
  focusBackground: WeaveThemeValues['Color'];
  focusColor: WeaveThemeValues['Color'];
  hoverBackground: WeaveThemeValues['Color'];
  activeBackground: WeaveThemeValues['Color'];
  disabledColor: WeaveThemeValues['Color'];
  disabledBackground: WeaveThemeValues['Color'];
  floatingColor: WeaveThemeValues['Color'];
  floatingHoverBackground: WeaveThemeValues['Color'];
  floatingActiveBackground: WeaveThemeValues['Color'];
  boxShadow: WeaveThemeValues['Color'];
}

export const actionButtonTheme: ActionButtonStyleValues = {
  boxShadow: theme.shadows.heavy,
  background: theme.colors.white,
  color: theme.colors.black,
  secondaryColor: theme.colors.primary50,
  borderColor: theme.colors.neutral20,
  mediumDiameter: 40,
  focusBackground: theme.colors.neutral20,
  focusColor: theme.colors.black,
  hoverBackground: theme.colors.neutral20,
  activeBackground: theme.colors.primary50,
  disabledColor: theme.colors.neutral20,
  disabledBackground: theme.colors.neutral5,
  floatingColor: theme.colors.white,
  floatingHoverBackground: theme.colors.primary70,
  floatingActiveBackground: theme.colors.primary80,
};

export const actionButtonThemeOriginal: ActionButtonStyleValues = {
  boxShadow: originalTheme.shadows.heavy,
  background: originalTheme.colors.weaveBlue,
  color: originalTheme.colors.white,
  secondaryColor: originalTheme.colors.weaveBlue,
  borderColor: originalTheme.colors.weaveBlue,
  mediumDiameter: 48,
  focusBackground: originalTheme.colors.weaveBlue,
  focusColor: originalTheme.colors.white,
  hoverBackground: originalTheme.colors.hoverBlue,
  activeBackground: originalTheme.colors.pressedBlue,
  disabledColor: originalTheme.colors.white,
  disabledBackground: originalTheme.colors.gray[300],
  floatingColor: originalTheme.colors.white,
  floatingHoverBackground: originalTheme.colors.hoverBlue,
  floatingActiveBackground: originalTheme.colors.pressedBlue,
};
