import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { SizePickerAction } from '../../molecules';
import { $isRangeSelection, $getSelection } from 'lexical';
import { useCallback } from 'react';
import { $patchStyleText } from '@lexical/selection';
import { useToolbarState } from '../../providers';
import { $patchDynamicFieldLabelStyle } from '../../utils/node-helpers';

export const FontSize = () => {
  const [editor] = useLexicalComposerContext();
  const { fontSize } = useToolbarState(['fontSize']);

  const onFontSizeSelect = useCallback(
    (option: number) => {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $patchStyleText(selection, {
            ['font-size']: `${option}px`,
          });
        }
        $patchDynamicFieldLabelStyle(selection, { fontSize: option });
      });
    },
    [editor]
  );

  return <SizePickerAction size={!!fontSize ? parseInt(fontSize) : '__'} onChange={onFontSizeSelect} />;
};

FontSize.displayName = 'FontSize';
