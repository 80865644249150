import React from 'react';
import { PopoverMenu, PopoverMenuItem, usePopoverMenu } from '../../popover';
import { ActionDropdownChip } from '../atoms';
import { css } from '@emotion/react';
import { NumberField, useFormField } from '../../forms';
import { KeyNames } from '../../../constants';
import { Text } from '../../text';
import { theme } from '@frontend/theme';

type SizePickerActionProps = {
  size?: number | string;
  onChange: (size: number) => void;
} & Pick<React.ComponentProps<typeof ActionDropdownChip>, 'showBoxShadow' | 'css'>;

export const SizePickerAction = ({ size = 0, showBoxShadow = true, onChange, ...rest }: SizePickerActionProps) => {
  const { getTriggerProps, getMenuProps, getItemProps, isOpen, close } = usePopoverMenu<
    HTMLButtonElement | HTMLAnchorElement
  >({
    interactionOptions: {
      typeahead: { enabled: false },
      listNavigation: { selectedIndex: typeof size === 'number' ? selectedIndex(size) : null },
    },
    placement: 'bottom-start',
    middlewareOptions: {
      offset: {
        crossAxis: 0,
        mainAxis: 12,
      },
    },
  });

  const customSizeField = useFormField({ type: 'number', value: `${size}` }, [size, isOpen]);

  return (
    <>
      <ActionDropdownChip
        showBoxShadow={showBoxShadow}
        isOpen={isOpen}
        isActive={isOpen}
        {...getTriggerProps()}
        {...rest}
        css={css`
          width: 65px;
        `}
      >
        <span
          css={css`
            color: ${theme.colors.neutral90};
          `}
        >
          {size} px
        </span>
      </ActionDropdownChip>
      <PopoverMenu
        {...getMenuProps()}
        returnFocus={false}
        css={css`
          max-width: 160px;
        `}
      >
        <div
          tabIndex={0}
          css={css`
            outline: none;
            width: 100%;
            display: flex;
            flex-direction: column;
          `}
        >
          {defaultSizes.map(({ label, value }, index) => (
            <PopoverMenuItem
              key={label}
              tabIndex={-1}
              {...getItemProps({
                index,
                onClick: () => onChange(value),
              })}
            >
              <SizeItem label={label} value={value} />
            </PopoverMenuItem>
          ))}
        </div>
        <div
          css={css`
            padding: ${theme.spacing(1.5, 2, 1, 2)};
            border-top: 1px solid ${theme.colors.neutral20};
          `}
        >
          <NumberField
            suffix='px'
            onKeyDown={(e) => {
              e.stopPropagation();
              if (e.key === KeyNames.Enter) {
                e.preventDefault();
                onChange(parseInt(customSizeField.value ?? 0));
                close();
              }

              if (e.key === KeyNames.Escape) {
                close();
              }
            }}
            name='custom-size-action'
            label=''
            {...customSizeField}
          />
        </div>
      </PopoverMenu>
    </>
  );
};

type SizeItemProps = {
  label: string;
  value: number;
};

const SizeItem = ({ label, value }: SizeItemProps) => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        span {
          flex: 0 1 auto;
        }
      `}
    >
      <Text>{label}</Text>
      <Text color='light' size='small'>
        {value}
      </Text>
    </div>
  );
};

const selectedIndex = (size: number) => {
  const index = defaultSizes.findIndex((sizeObj) => sizeObj.value === size);
  return index < 0 ? null : index;
};

const defaultSizes = [
  {
    label: 'Extra Small',
    value: 8,
  },
  {
    label: 'Small',
    value: 12,
  },
  {
    label: 'Medium',
    value: 16,
  },
  {
    label: 'Large',
    value: 24,
  },
  {
    label: 'X-Large',
    value: 40,
  },
];
