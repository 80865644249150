import { forwardRef } from 'react';
import type { ElementType, HTMLAttributes } from 'react';
import type { FontColorType } from '../../helpers';
import { useStyles } from '../../use-styles';
import type { FontWeight, TextAlignment, TextSize } from './text-style-helpers';

export interface TextProps extends HTMLAttributes<HTMLElement> {
  className?: string;
  color?: FontColorType;
  as?: ElementType;
  size?: TextSize;
  textAlign?: TextAlignment;
  weight?: FontWeight;
}

export const Text = forwardRef<HTMLElement, TextProps>(
  ({ size = 'large', weight = 'inherit', color = 'default', textAlign, as = 'p', className, ...props }, ref) => {
    const Component = as;
    const textStyle = useStyles('Text', { size, weight, color, textAlign });
    return <Component ref={ref} className={className} css={textStyle} {...props} />;
  }
);
