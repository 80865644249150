import { FontColorType } from '../helpers';
import { ReactNode, SVGProps } from 'react';
import { useStyles } from '../use-styles';

export type IconProps = SVGProps<SVGSVGElement> & {
  children?: ReactNode;
  size?: number;
  color?: FontColorType;
};

export const Icon = ({
  children,
  color,
  focusable,
  fill,
  viewBox,
  size = 24,
  noFill,
  ...props
}: IconProps & { noFill?: boolean }) => {
  const styles = useStyles('Icon', { size, color, noFill });
  return (
    <svg
      css={styles}
      focusable={focusable ?? false}
      width={size}
      height={size}
      aria-hidden={props['aria-hidden'] ?? true}
      {...props}
      viewBox={viewBox}
    >
      {children}
    </svg>
  );
};
