import { ComponentProps } from 'react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { InboxPrefixes } from '@frontend/tracking-prefixes';
import { IconButton, PopoverMenu, PopoverMenuItem, usePopoverMenu } from '@frontend/design-system';
import { ContactListItem } from './contact-list-item';

type AssociatedContactsListItemProps = Omit<ComponentProps<typeof ContactListItem>, 'children'> & {
  onSelect: () => void;
};

/**
 * `AssociatedContactsListItem` is a component that displays a single contact in the associated contacts list.
 * If the `person` prop is provided, it will be used to render the contact. Otherwise, the component will fetch
 * the contact using the `personId` prop.
 *
 * @param personId - The ID of the person to display.
 * @param person - The person to display. If provided, the component will not fetch the person using the `personId` prop.
 * @param groupIds - The IDs of the locations to which the person belongs. If provided, the component will fetch the
 * person using the `personId` prop. Defaults to the currently selected location IDs.
 */
export const AssociatedContactsListItem = ({ onSelect, ...rest }: AssociatedContactsListItemProps) => {
  const { t } = useTranslation('thread-header');

  const { getMenuProps, getItemProps, getTriggerProps } = usePopoverMenu({
    placement: 'bottom-end',
  });

  return (
    <>
      <ContactListItem {...rest} data-trackingid={`${InboxPrefixes.Thread}-associated-contact-view-contact`}>
        <IconButton {...getTriggerProps()} label={t('Open Associated Contact Actions')} size='small'>
          <Icon name='more-small' />
        </IconButton>
      </ContactListItem>
      <PopoverMenu {...getMenuProps()}>
        <PopoverMenuItem
          {...getItemProps({
            index: 0,
            onClick: (e) => {
              e.stopPropagation();
              onSelect();
            },
            trackingId: `${InboxPrefixes.Thread}-associated-contact-set-primary`,
          })}
          css={{
            cursor: 'pointer',
          }}
        >
          <Icon name='star' />
          {t('Make Primary Contact For This Number')}
        </PopoverMenuItem>
      </PopoverMenu>
    </>
  );
};
