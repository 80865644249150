import type { FilterAndRemoveVitePrefix } from './index.d';
// We need to import the custom types and not rely on the default

const prefixes = ['VITE_WEAVE_'] as const;
type AppConfig = FilterAndRemoveVitePrefix<ImportMetaEnv, (typeof prefixes)[number]>;

let appConfig: AppConfig;
declare global {
  // We need to use `var` here for this declaration to work
  //eslint-disable-next-line
  var weave: ImportMetaEnv;

  //eslint-disable-next-line
  var env: ImportMetaEnv | undefined;
}

const curateVariables = (input: ImportMetaEnv): AppConfig => {
  return Object.fromEntries(
    Object.entries(input)
      .filter(([key]) => prefixes.some((prefix) => key.startsWith(prefix)))
      .map(([key, value]) => [prefixes.reduce((acc, prefix) => acc.replace(prefix, ''), key), value])
  ) as AppConfig;
};

if (import.meta.env.MODE === 'development' || import.meta.env.MODE === 'test') {
  /**
   * If we're in local dev, get the values from the .env file (accessible through `import.meta.env`),
   * but curate them before exposing through this API
   */
  appConfig = curateVariables(import.meta.env);
} else {
  /**
   * If we're in prod, the env vars will be exposed through `globalThis`.
   * This is achieved using the `config.js` file that is generated just prior to running the app
   * on our servers.
   * The values are the same ones passed into the WAML for the specific environment.
   */
  const envVars = globalThis?.weave ?? typeof env !== 'undefined' ? env : undefined;
  if (typeof env !== 'undefined' && !globalThis.weave) {
    globalThis.weave = env;
  }
  if (!envVars) {
    console.warn('Warning: No environment variables found');
    appConfig = {} as AppConfig;
  } else {
    appConfig = curateVariables(envVars ?? {});
  }

  /**
   * Make an exception for versioning.
   * The version is currently read from the .env file
   */
  appConfig.VERSION = import.meta.env.VITE_WEAVE_VERSION;
}

export default appConfig;

const getWebsocketApi = (backendEnv: string | null) => {
  if (backendEnv === 'dev') {
    return 'wss://api.weavedev.net/websocket/connect/user';
  } else {
    return 'wss://api.weaveconnect.com/websocket/connect/user';
  }
};

const getWebsocketApiLegacy = (backendEnv: string | null) => {
  if (backendEnv === 'dev') {
    return 'wss://api.weavedev.net/v2/client-manager/ws';
  } else {
    return 'wss://api.weaveconnect.com/v2/client-manager/ws';
  }
};

export const getInitialParams = () => {
  const backendEnv = localStorage.getItem('backendEnv');
  const oidcIssuer = appConfig.OIDC_ISSUER;
  const oidcClientID = appConfig.OIDC_CLIENT_ID;
  const appSource = appConfig.APP_SOURCE;
  let backendApi = appConfig.BACKEND_API;
  let oktaId = appConfig.OKTA_CLIENT_ID;
  let websocketApiLegacy = appConfig.WEBSOCKET_URL;
  let websocketApi = appConfig.WEBSOCKET_TWO_DOT_ZERO_URL;

  if (backendEnv) {
    backendApi = backendEnv === 'dev' ? 'https://api.weavedev.net' : 'https://api.weaveconnect.com';
    websocketApiLegacy = getWebsocketApiLegacy(backendEnv);
    websocketApi = getWebsocketApi(backendEnv);
    oktaId = backendEnv === 'dev' ? '0oa18nw1n4XSp3g1B4h7' : '0oa10acs96StGDuQY4h7';

    //this object is readonly for a good reason, but I'm going to modify it here because we want to actually be able to trust them

    //eslint-disable-next-line
    //@ts-ignore
    appConfig.BACKEND_API = backendApi;
    //eslint-disable-next-line
    //@ts-ignore
    appConfig.OKTA_CLIENT_ID = oktaId;
    //eslint-disable-next-line
    //@ts-ignore
    appConfig.WEBSOCKET_URL = websocketApiLegacy;
    //eslint-disable-next-line
    //@ts-ignore
    appConfig.WEBSOCKET_TWO_DOT_ZERO_URL = websocketApi;
  }

  return { backendApi, oktaId, oidcIssuer, oidcClientID, appSource, websocketApiLegacy, websocketApi };
};
