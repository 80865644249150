import { FC, useState } from 'react';
import { Interpolation, Theme, css } from '@emotion/react';
import { PaymentPlanQueries } from '@frontend/api-payment-plans';
import { isWeaveUser } from '@frontend/auth-helpers';
import { http } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  ContentLoader,
  EmailField,
  Heading,
  PrimaryButton,
  SecondaryButton,
  Text,
  useAlert,
  useFormField,
} from '@frontend/design-system';
import { STRIPE_RESPONSE_DELAY } from '../constants';
import { useCardACHSelectionContext } from '../context';

interface AddACHFormProps {
  styles?: Interpolation<Theme>;
}

export const AddACHForm: FC<AddACHFormProps> = ({ styles }) => {
  const { t } = useTranslation('payments');
  const alerts = useAlert();

  const { isResendVerificationACHMode, locationId, patientId, closeAddACH } = useCardACHSelectionContext();
  const [isMutating, setIsMutating] = useState(false); // Custom state as timeout is there on success

  const fieldProps = useFormField({ type: 'email', required: true });

  const { invalidatePaymentMethodsByPerson } = PaymentPlanQueries.usePaymentPlansInvalidation();

  const { mutate } = PaymentPlanQueries.useCreatePMRegistrationLink({
    onSuccess: () => {
      setTimeout(() => {
        alerts.success(t('ACH verification email sent successfully'));
        invalidatePaymentMethodsByPerson(patientId);
        closeAddACH();
        setIsMutating(false);
      }, STRIPE_RESPONSE_DELAY);
    },
    onError: () => {
      alerts.error(t('Failed to send ACH verification'));
      setIsMutating(false);
    },
  });

  const sendACHVerification = () => {
    if (fieldProps.error || !patientId) {
      return;
    } else if (http.baseUrl.includes('api.weaveconnect.com') && isWeaveUser()) {
      alerts.error(t('Weave users do not have access to save ACH.'));
      return;
    }
    setIsMutating(true);
    mutate({ email: fieldProps.value, personId: patientId, locationId });
  };

  return (
    <div css={[containerStyles, styles]}>
      <ContentLoader show={isMutating} />
      <Heading level={2}>
        {isResendVerificationACHMode ? t('Resend ACH on File Verification') : t('Save ACH on File')}
      </Heading>
      <EmailField {...fieldProps} label={t('Customer Email*')} name={'email'} placeholder={t('Enter Email')} />
      <Text size='medium'>
        {t(
          'Tell customer to add bank account information in the link provided to them via email. whenever ACH is successfully added, it will be automatically marked as the default payment method and will be used for future payments above $250, or over $50 per payment on payment plans.'
        )}
      </Text>
      <div css={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: theme.spacing(1) }}>
        <SecondaryButton onClick={closeAddACH} css={{ width: 'max-content' }}>
          {t('Cancel')}
        </SecondaryButton>
        <PrimaryButton
          disabled={fieldProps['aria-invalid'] || !fieldProps.value}
          css={{ width: 'max-content' }}
          onClick={sendACHVerification}
        >
          {t('Send ACH Verification')}
        </PrimaryButton>
      </div>
    </div>
  );
};

const containerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(0, 4),
});
