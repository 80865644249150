import { ReactNode } from 'react';
import { contextFactory } from '../../../hooks';

export interface StepperCardProviderProps {
  step: number;
  stepValue?: ReactNode;
  children: ReactNode | ReactNode[];
}

interface StepperCardContext {
  step: number;
  stepValue?: ReactNode;
  children: ReactNode | ReactNode[];
}

export const [StepperCardContext, useStepperCardContext] = contextFactory<StepperCardContext>();

export function StepperCardProvider({ step, stepValue, children }: StepperCardProviderProps) {
  return (
    <StepperCardContext.Provider
      value={{
        step,
        stepValue,
        children,
      }}
    >
      {children}
    </StepperCardContext.Provider>
  );
}
