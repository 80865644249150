import { theme } from '@frontend/theme';
import { Text, useTooltip } from '@frontend/design-system';
import { CustomAxisTickProps } from '../types';

// Show ellipsis if label is longer than the given length
const defaultClipLength = 10;

export const YAxisLabelValueTick = ({
  clipLength = defaultClipLength,
  doNotClip,
  label,
  translateTo,
  value,
  x,
  y,
}: CustomAxisTickProps) => {
  const { Tooltip: Tooltip, triggerProps, tooltipProps } = useTooltip({ placement: 'top' });

  const labelMod = !doNotClip && label.length > clipLength ? `${label.slice(0, clipLength)}...` : label;

  const getSpace = (value: number | string) => {
    if (translateTo) {
      return translateTo;
    }

    const valueLength = value.toString().length;
    const base = 24;

    if (valueLength === 1) {
      return base;
    }

    // Add 10 for all other digits/chars
    const additionalSpace = (valueLength - 1) * 10;
    return base + additionalSpace;
  };

  return (
    <g {...(label !== labelMod ? triggerProps : {})}>
      <text
        fill={theme.colors.neutral50}
        style={{
          fontSize: theme.fontSize(14),
          textAnchor: 'end',
        }}
        transform={
          x && y
            ? `translate(${typeof value === 'undefined' ? x : x + 10 - getSpace(value)}, ${y + 2})`
            : `translate(-${typeof value === 'undefined' ? '10' : getSpace(value)}, 4)`
        }
      >
        <tspan>{labelMod}</tspan>
      </text>
      {typeof value !== 'undefined' && (
        <text
          style={{
            fontSize: theme.fontSize(14),
            textAnchor: 'end',
          }}
          transform={x && y ? `translate(${x}, ${y + 2})` : 'translate(-8, 4)'}
        >
          <tspan fontWeight='bold' fill={theme.colors.neutral80}>
            {value}
          </tspan>
        </text>
      )}

      {label !== labelMod && (
        <Tooltip {...tooltipProps}>
          <Text size='medium' color='white'>
            {label}
          </Text>
        </Tooltip>
      )}
    </g>
  );
};
