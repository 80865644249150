import { CalendarProvider } from '../../provider';
import type { UseCalendarReturn } from '../../provider';
import { DialogCalendarDisplay } from './dialog-calendar-display';
import { css } from '@emotion/react';

export const DialogCalendarLayout = (props: Omit<UseCalendarReturn, 'children'>) => (
  <CalendarProvider {...props} css={css({ outline: 'none' })}>
    <DialogCalendarDisplay />
  </CalendarProvider>
);
