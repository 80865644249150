import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import type { DivStyleThemeValues, TextStyleProp } from './types';
import { AlertType } from '../alerts/types';

const colorMapThemeOriginal = {
  error: 'error',
  info: 'weaveBlue',
  success: 'success',
  warning: 'warning',
};

const getBorderLeftColor = (type: AlertType) => {
  if (type === 'error') return theme.colors.status.critical;
  if (type === 'info') return theme.colors.primary50;
  if (type === 'success') return theme.colors.status.success;
  else return theme.colors.status.warning;
};

export const getAlertTextTheme = ({ type }: TextStyleProp) => ({
  spacing: theme.spacing,
  borderLeftColor: getBorderLeftColor(type),
  borderRadius: theme.borderRadius.medium,
});

export const getAlertTextThemeOriginal = ({ type }: TextStyleProp) => ({
  spacing: themeOriginal.spacing,
  borderLeftColor: themeOriginal.colors[colorMapThemeOriginal[type]],
  borderRadius: themeOriginal.borderRadius.medium,
});

export const containerTheme: DivStyleThemeValues = {
  backgroundColor: theme.colors.white,
  borderRadius: theme.borderRadius.small,
  boxShadow: theme.shadows.heavy,
  zIndex: theme.zIndex.low,
};

export const containerThemeOriginal: DivStyleThemeValues = {
  backgroundColor: themeOriginal.colors.white,
  borderRadius: themeOriginal.borderRadius.small,
  boxShadow: themeOriginal.shadows.heavy,
  zIndex: themeOriginal.zIndex.low,
};
