import { css } from '@emotion/react';
import { InlineLabel } from './inline-label.component';
import type { InlineLabelPlacement } from './types';

type Props = {
  children: React.ReactNode;
  className?: string;
  id: string;
  labelPlacement?: InlineLabelPlacement;
  label: React.ReactNode;
  labelDisabled?: boolean;
  disabled?: boolean;
};

export const BaseBoolLayout = ({
  children,
  className,
  id,
  labelPlacement = 'right',
  label,
  labelDisabled,
  disabled = false,
}: Props) => (
  <div
    className={className}
    css={[
      css`
        display: flex;
        align-items: flex-start;
      `,
      labelPlacement === 'left' &&
        css`
          align-items: center;
          flex-direction: row-reverse;

          label {
            width: 100%;
          }
        `,
    ]}
  >
    {children}
    {!!label && (
      <InlineLabel id={id} disabled={labelDisabled ?? disabled} placement={labelPlacement}>
        {label}
      </InlineLabel>
    )}
  </div>
);
