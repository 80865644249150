import { css } from '@emotion/react';
import type { MessageTemplateTagThemeValues } from './message-template-tag-theme';
import { MessageTemplateTagStyleProps } from './types';

export const messageTemplateTagStyle = (
  {
    spacing,
    errorColor,
    readOnlyBorderColor,
    readOnlyTextColor,
    borderColor,
    borderRadius,
    boxShadow,
    svgColor,
  }: MessageTemplateTagThemeValues,
  { error, placed, focused, selected, readOnly }: MessageTemplateTagStyleProps
) => css`
  display: inline-flex;
  flex: 0 1;
  align-items: center;
  height: 24px;
  padding: ${spacing(0, 1)};
  border: 1px solid ${error ? errorColor : readOnly ? readOnlyBorderColor : borderColor};
  border-radius: ${borderRadius};
  user-select: none;
  white-space: nowrap;
  margin: 0;
  line-height: 0;
  outline: none;
  cursor: ${readOnly ? 'default' : 'pointer'};

  ${readOnly &&
  `
    color: ${readOnlyTextColor};
    svg {
      color: ${readOnlyTextColor};
      cursor: default;
    }
  `}
  ${!readOnly &&
  `
    :hover,
    :focus {
      cursor: pointer;
      box-shadow: ${boxShadow};
      svg {
        color: ${svgColor};
      }
    }
  `}
  ${placed && focused && selected && !readOnly && `box-shadow: ${boxShadow};`}
  ${!placed &&
  !readOnly &&
  `
    :focus {
      box-shadow: ${boxShadow};
    }
  `}
`;
