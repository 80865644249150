import { css } from '@emotion/react';
import type { ListFieldThemeValues } from './list-field-item-theme';
import { listFieldItemTheme, listFieldItemThemeOriginal } from './list-field-item-theme';

const style = ({ borderColor, spacing }: ListFieldThemeValues) =>
  css`
    border-bottom: 1px solid ${borderColor};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${spacing(2, 0)};

    :first-of-type {
      border-top: 1px solid ${borderColor};
    }
  `;

export const ListFieldItemStyles = {
  ListFieldItem: style(listFieldItemTheme),
};
export const ListFieldItemStylesOriginal: typeof ListFieldItemStyles = {
  ListFieldItem: style(listFieldItemThemeOriginal),
};
