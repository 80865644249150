import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { ButtonStyleProps, ButtonStyleValues } from '../button.type';
import { baseButtonStyle, pressedStyle, hoverStyle } from './common.styles';
import { getStyleValues } from './helpers';

const tertiaryButtonTheme: ButtonStyleValues = {
  backgroundColor: theme.colors.white,
  color: theme.colors.primary50,
  focusRingColor: theme.colors.primary50,
  hoverColor: theme.colors.primary5,
  pressed: {
    backgroundColor: theme.colors.primary5,
    color: theme.colors.primary70,
  },
  loading: {
    backgroundColor: 'transparent',
    borderColor: theme.colors.neutral30,
  },
  disabled: {
    backgroundColor: theme.colors.white,
    color: theme.colors.status.disabled,
    secondaryColor: theme.colors.neutral20,
    borderColor: theme.colors.status.disabled,
  },
  destructive: {
    pressed: {
      backgroundColor: theme.colors.critical10,
      color: theme.colors.critical70,
    },
    backgroundColor: theme.colors.white,
    color: theme.colors.status.critical,
    focusRingColor: theme.colors.status.critical,
    hoverColor: theme.colors.critical5,
  },
};

export const tertiaryStyles = ({ size, isLoading, iconOnly, destructive }: ButtonStyleProps) => {
  const styles = getStyleValues(tertiaryButtonTheme, destructive || false);

  return [
    baseButtonStyle({
      focusRingColor: styles.focusRingColor,
      size: size ?? 'small',
      iconOnly,
    }),
    css`
      background: ${styles.backgroundColor};
      border: none;
      color: ${styles.color};
    `,
    isLoading
      ? css`
          background: ${styles.loading.backgroundColor};
          &[aria-disabled='true'] {
            box-shadow: none;
            color: ${styles.disabled.color};
          }
        `
      : css`
          :hover {
            ${hoverStyle({
              hoverColor: styles.hoverColor,
              borderColor: styles.borderColor,
            })};
          }
          &[aria-disabled='true'] {
            background: ${styles.disabled.backgroundColor};
            border-color: ${styles.disabled.borderColor};
            color: ${styles.disabled.color};
          }
          ${pressedStyle({
            pressed: styles.pressed,
          })}
        `,
  ];
};
