import { PersonAPI } from '@frontend/api-person';
import { createStoreWithSubscribe, createShallowStore } from '@frontend/store';

export interface PhotoStore {
  photoRecord: Record<string, string>;
  setPhotoRecord: (id: string, imgUrl: string) => void;
  handleGetPicture: (id: string) => void;
}

export const usePhotoStore = createStoreWithSubscribe<PhotoStore>(
  (set, get) => ({
    photoRecord: {},
    setPhotoRecord: (personId: string, imgUrl: string) => {
      set((state) => {
        state.photoRecord[personId] = imgUrl;
      });
    },
    handleGetPicture: async (id: string) => {
      const photo = get().photoRecord[id];
      if (photo !== undefined) return;

      try {
        const photoData = await PersonAPI.getPersonImage(id);
        set(
          (state) => {
            state.photoRecord[id] = photoData;
          },
          false,
          'SET_SHOW_CONTACT_PANEL'
        );
      } catch {
        set(
          (state) => {
            state.photoRecord[id] = '';
          },
          false,
          'SET_SHOW_CONTACT_PANEL'
        );
      }
    },
  }),
  { name: 'PhotoStore', trace: true }
);

export const usePhotoShallowStore = createShallowStore(usePhotoStore);
