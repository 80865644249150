import { css } from '@emotion/react';

export type ContentColumnStyleProps = {
  textChildren: 1 | 2 | 3;
};

export const contentColumnStyles = (
  { spacing, lightTextColor, defaultTextColor },
  { textChildren }: ContentColumnStyleProps
) => [
  css`
    display: grid;
    grid-area: content;
    .content-column-title-container,
    .content-column-subtitle,
    .content-column-tertiary-title {
      margin: ${spacing(0.25, 0)};
    }
  `,
  textChildren === 2 &&
    css`
      .content-column-subtitle {
        ${lightTextColor}
      }
    `,
  textChildren === 3 &&
    css`
      .content-column-subtitle {
        ${defaultTextColor}
      }
      .content-column-tertiary-title {
        ${lightTextColor}
        margin: 0;
      }
    `,
];
