import { css } from '@emotion/react';
import { useThemeValues } from '../../hooks/use-theme-values';

import type { CenteredModalProps } from './molecules/centered-modal';
import { CenteredModal } from './molecules/centered-modal';
import { ModalHeader } from './atoms/modal-header';
import { ModalFlexContent } from './atoms/modal-flex-content';
import { ModalActions } from './atoms/modal-actions';

export type ModalProps = Omit<CenteredModalProps, 'flex'>;

export const Modal = ({ children, minWidth = 0, maxWidth = 400, ...rest }: ModalProps) => {
  const { spacing } = useThemeValues();
  return (
    <CenteredModal
      css={css`
        padding-top: ${spacing(3)};
        padding-bottom: ${spacing(3)};

        .wv-modal-header,
        .wv-modal-flex-content {
          padding-right: ${spacing(3)};
          padding-left: ${spacing(3)};
        }
      `}
      flex
      minWidth={minWidth}
      maxWidth={maxWidth}
      {...rest}
    >
      {children}
    </CenteredModal>
  );
};

Modal.Header = ModalHeader;
Modal.Body = ModalFlexContent;
/**
 * Component for adding secondary + primary actions to a modal
 * @param {string} [props.className] Optional css/classname
 * @param {string | function} props.onPrimaryClick Either 'submit' (for buttons controlling a form in a modal) or a click handler function.
 * @param {function} [props.onSecondaryClick] Optionals click handler for the secondary action. Defaults to the modals onClose function.
 * @param {string} [props.primaryLabel] Optional text for the primary button (default = 'Save')
 * @param {string} [props.secondaryLabel] Optional text for the secondary button (default = 'Cancel')
 */
Modal.Actions = ModalActions;
