import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const quickFilterStyles = {
  buttonContainer: css`
    display: flex;
    gap: ${theme.spacing(1)};
    flex-wrap: wrap;
  `,
  quickFilterButton: { width: 'max-content' },
  notificationBadgeContainer: css`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  notificationBadge: (isFilterActive = false) => css`
    border-radius: ${theme.borderRadius.full};
    background-color: ${isFilterActive ? theme.colors.white : theme.colors.primary[50]};
    color: ${isFilterActive ? theme.colors.black : theme.colors.white};
    display: inline-flex;
    min-width: ${theme.spacing(2.5)};
    height: ${theme.spacing(2.5)};
    margin-left: ${theme.spacing(0.5)};
    justify-content: center;
    align-items: center;
    padding: ${theme.spacing(0, 0.4)};
    font-size: ${theme.font.size.small};
    margin-bottom: -1px;
  `,
  needsActionFilter: (isFilterActive = false) => css`
    color: ${isFilterActive ? theme.colors.white : 'inherit'};
  `,
};
