import format from 'date-fns/format';
import parse from 'date-fns/parse';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';
import isValid from 'date-fns/isValid';
import { isEmail, isPostalCode } from 'validator';
import { digitsOnly } from '../../../helpers';
import { PostalCodeLocale } from './types';
import type { ValidatorFieldState } from './types';
import { stringToTimeUnit, is24HrFormat, to12HrString } from '../fields/time-field/utils';

export const phone = ({ value = '' }: ValidatorFieldState<'phone'>) => {
  if (!/^\d{10,11}$/.test(digitsOnly(value))) return 'Invalid phone number';
  return '';
};

export const postalCode = ({ value, locale = PostalCodeLocale.US }: ValidatorFieldState<'postalCode'>) =>
  isPostalCode(value, locale) ? '' : `Invalid ${locale === PostalCodeLocale.US ? 'zip' : 'postal'} code`;

export const email = ({ value = '' }: ValidatorFieldState<'email'>) => (isEmail(value) ? '' : 'Invalid email address');

const isBlackoutDay = (blackouts: string[], day: string) =>
  blackouts.find((d) => isSameDay(new Date(d), new Date(day)));

const dateFormats = [
  { format: 'M/d/yy', pattern: /^\d{1,2}\/\d{1,2}\/\d{2}$/ },
  { format: 'M/d/yyyy', pattern: /^\d{1,2}\/\d{1,2}\/\d{4}$/ },
  { format: 'yyyy-M-d', pattern: /^\d{4}-\d{1,2}-\d{1,2}$/ },
];

const createDate = (date: string) => {
  const matchedFormat = dateFormats.find(({ pattern }) => pattern.test(date))?.format;
  if (!matchedFormat) return new Date(date);

  return parse(date, matchedFormat, new Date());
};

export const date = ({ blackoutDates, minDate, maxDate, value = '' }: ValidatorFieldState<'date' | 'datePicker'>) => {
  const targetDate = createDate(value);
  if (!isValid(targetDate)) return 'Invalid date';

  const min = minDate ? createDate(minDate) : false;
  if (min && isBefore(targetDate, min)) {
    return `Must be after ${format(min, 'MM/dd/yyyy')}`;
  }
  const max = maxDate ? createDate(maxDate) : false;
  if (max && isAfter(targetDate, max)) {
    return `Must be before ${format(max, 'MM/dd/yyyy')}`;
  }

  if (blackoutDates?.length && isBlackoutDay(blackoutDates, value)) {
    return 'Day unavailable';
  }
  return '';
};

export const datePicker = date;

const formatNumber = (amount: number, { prefix = '', suffix = '' }) =>
  `${amount < 0 ? `-${prefix}` : prefix}${!!prefix ? Math.abs(amount).toFixed(2) : Math.abs(amount)}${suffix}`;

export const number = ({ max, min = 0, value = '', prefix, suffix }: ValidatorFieldState<'number'>) => {
  const numValue = +value;
  if (isNaN(numValue)) return 'Invalid amount';
  if (numValue < min) return `Must be at least ${formatNumber(min, { prefix, suffix })}`;
  if (max && numValue > max) {
    return `Can't be more than ${formatNumber(max, { prefix, suffix })}`;
  }
  return '';
};

export const money = (props: ValidatorFieldState<'money'>) => {
  const data: unknown = { ...props, prefix: '$' };
  return number(data as ValidatorFieldState<'number'>);
};

const pluralize = (count: number) => (count === 1 ? '' : 's');

// TODO: add more useful validation?
export const password = ({ minChars = 8, value }: ValidatorFieldState<'password'>) =>
  value.trim().length >= minChars ? '' : `Must be at least ${minChars} character${pluralize(minChars)}`;

export const getListMaxError = (max: number) => `Only ${max} item${pluralize(max)} allowed.`;

export const list = ({ minRequired = 0, maxAllowed, value }: ValidatorFieldState<'list'>) => {
  if (minRequired > 0 && value.length < minRequired) {
    return `Requires at least ${minRequired} item${pluralize(minRequired)}.`;
  } else if (maxAllowed && value.length > maxAllowed) {
    return getListMaxError(maxAllowed);
  } else {
    return '';
  }
};

export const checklist = list;
export const selectlist = list;
export const multiselect = list;

const INVALID_TIME_ERROR = 'Invalid time';

export const time = ({ minTime, maxTime, value }: ValidatorFieldState<'time'>) => {
  // basic invalid value checks
  if (!is24HrFormat(value)) return INVALID_TIME_ERROR;
  const valueTime = stringToTimeUnit(value);
  if (!valueTime) return INVALID_TIME_ERROR;
  // boundary checks
  const { hours, minutes } = valueTime;
  const start = stringToTimeUnit(minTime) ?? { hours: 0, minutes: 0 };
  const end = stringToTimeUnit(maxTime) ?? { hours: 23, minutes: 59 };

  if (hours < start.hours || (hours === start.hours && minutes < start.minutes)) {
    return `Can't be before ${to12HrString(start)}`;
  }
  if (hours > end.hours || (hours === end.hours && minutes > end.minutes)) {
    return `Can't be after ${to12HrString(end)}`;
  }
  return '';
};
