/* Based on https://github.com/ianmcnally/react-currency-masked-input */

const getDigitsFromValue = (value = '') => value.replace(/(-(?!\d))|[^0-9|-]/g, '') || '';
const removeLeadingZeros = (value: string) => value.replace(/^0+([0-9]+)/, '$1');

const padDigits = (digits: string) => {
  const desiredLength = 3;
  const actualLength = digits.length;

  if (actualLength >= desiredLength) {
    return digits;
  }

  const amountToAdd = desiredLength - actualLength;
  const padding = '0'.repeat(amountToAdd);

  return padding + digits;
};

const addDecimalToNumber = (value: string) => {
  const centsStartingPosition = value.length - 2;
  const dollars = removeLeadingZeros(value.substring(0, centsStartingPosition));
  const cents = value.substring(centsStartingPosition);
  return `${dollars}.${cents}`;
};

export const toCurrency = (value?: string) => {
  const digits = getDigitsFromValue(value);
  const digitsWithPadding = padDigits(digits);
  return addDecimalToNumber(digitsWithPadding);
};

export const displayAmount = (amount: number) => {
  return amount.toLocaleString(undefined, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'currency',
    currency: 'USD',
  });
};

// API expects amount to be in cents (no decimals)
export const convertToCents = (amount: number) => {
  return Math.round(amount * 100);
};
