import Fuse from 'fuse.js';

class RoutePathLookup {
  private root: Set<string> | undefined = undefined;
  private fuse: Fuse<{ labelPath: string; path: string }>;

  constructor() {
    this.fuse = new Fuse<{ labelPath: string; path: string }>([], {
      shouldSort: true,
      threshold: 0.3,
      location: 0,
      keys: ['labelPath'],
      distance: 100,
      includeMatches: true,
      includeScore: true,

      sortFn: (a, b) => {
        if (a.score === b.score) {
          //@ts-ignore
          //Something's wrong with Fuse.js types
          return a.item[0].v.length - b.item[0].v.length;
        }
        return a.score - b.score;
      },
    });
  }

  build(routes: string[]): void {
    this.root = new Set(routes);
  }

  buildFuse(routes: { labelPath: string; path: string }[]): void {
    this.fuse.setCollection(routes);
  }

  searchPath(searchPath: string): string[] {
    const foundRoutes: string[] = [];

    const searchPathSegments = searchPath.split('/').filter(Boolean);

    searchPathSegments.reduce((acc: string, segment: string) => {
      const currentCheckingPath = `${acc}/${segment}`;

      if (this.root?.has(currentCheckingPath + '/')) {
        foundRoutes.push(currentCheckingPath);
      }

      return currentCheckingPath;
    }, '');

    return foundRoutes;
  }

  searchKeyword(word: string): Fuse.FuseResult<{ labelPath: string; path: string }>[] {
    const fuseResults = new Set(this.fuse.search(word));

    return [...fuseResults];
  }
}

export const settingsRouteLookup = new RoutePathLookup();
