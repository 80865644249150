import React, { forwardRef } from 'react';
import { css } from '@emotion/react';
import { NakedButton } from '../../../naked';
import { PolymorphicComponentPropWithRef, PolymorphicRef } from '../../../type-utils';
import { useAccordionContext } from '../provider/accordion.provider';
import { useAccordionItemContext } from './accordion-item';
import { createKeydownHandler } from '../../../helpers';
import { IconButton } from '../../icon-button';
import { useStyles } from '../../../use-styles';
import { DefaultAccordionHeader } from './default-accordion-header';
import { useThemeValues } from '../../../hooks';
import { AccordionChevronPosition } from '../accordion.types';
import { WeaveThemeValues } from '../../../component-theme/theme-types';
import { Text } from '../../text';
import { IconProps, LocationIcon, MultiLocationSmall } from '../../../icon';

type Props = {
  title?: string;
  subtitle?: string;
  disabled?: boolean;
  chevronIcon?: React.FC<React.PropsWithChildren<IconProps>> | boolean;
  children?: React.ReactNode;
};

type AccordionHeaderProps<C extends React.ElementType> = PolymorphicComponentPropWithRef<C, Props>;

const locationPadding = {
  small: [1, 2],
  large: [1.5, 2],
};

export const AccordionHeaderOptional = forwardRef(
  <C extends React.ElementType = 'section'>(
    { disabled, onKeyDown, onClick, chevronIcon, children, title, subtitle, ...others }: AccordionHeaderProps<C>,
    ref: PolymorphicRef<C>
  ) => {
    const {
      isItemActive,
      headingLevel,
      onChange,
      getHeaderId,
      getBodyId,
      shouldLoop,
      isInteractive,
      variant,
      chevronPosition,
      chevronIcon: globalChevron,
      chevronSize,
      padding: headerPadding,
      hasMultiLocations: hasMultiLocationsGlobal,
      size,
      showBorderBottomAlways,
    } = useAccordionContext();
    const { value, hasMultiLocations, trackingId } = useAccordionItemContext();
    const isActive = isItemActive(value);
    const { spacing } = useThemeValues();
    const accordionHeaderStyles = useStyles('Accordion', 'accordionHeader', {
      chevronPosition,
      isActive,
      variant,
      isInteractive,
      isDisabled: disabled,
      hasMultiLocations: hasMultiLocations ?? hasMultiLocationsGlobal,
      showBorderBottomAlways,
    });
    const chevronContainerStyle = useStyles('Accordion', 'chevronContainer', { disabled });

    const conditionalProps = {
      disabled: disabled,
      onClick: (event) => {
        onClick?.(event);
        onChange?.(value);
      },
      'data-active': isActive || undefined,
      'aria-expanded': isActive,
      'aria-controls': getBodyId(value),
      id: getHeaderId(value),
      'data-trackingid': trackingId,
    };

    const handleConditionalChevronRender = () => {
      const ChevronIcon = chevronIcon || globalChevron;

      if (!ChevronIcon || typeof ChevronIcon === 'boolean') return null;

      if (isInteractive) {
        return (
          <IconButton label='chevron' css={chevronContainerMarginStyle(spacing, chevronPosition)} {...conditionalProps}>
            <div css={chevronAnimatedStyle(isActive)}>
              <ChevronIcon size={chevronSize ?? variant === 'location' ? 16 : 24} />
            </div>
          </IconButton>
        );
      }

      return (
        <section css={[chevronContainerStyle, chevronContainerMarginStyle(spacing, chevronPosition)]}>
          <div css={chevronAnimatedStyle(isActive)}>
            <ChevronIcon size={chevronSize ?? variant === 'location' ? 16 : 24} />
          </div>
        </section>
      );
    };

    const MainHeading = headingLevel ? `h${headingLevel}` : ('h3' as React.ElementType);
    const Component = isInteractive ? 'article' : NakedButton;

    return (
      <MainHeading
        css={css`
          width: 100%;
          height: 100%;
          all: unset;
          display: flex;
        `}
      >
        <Component
          {...others}
          {...(!isInteractive && conditionalProps)}
          ref={ref}
          data-accordion-control
          onKeyDown={createKeydownHandler({
            siblingSelector: '[data-accordion-control]',
            parentSelector: '[data-accordion]',
            shouldActivateOnFocus: false,
            shouldLoop,
            orientation: 'vertical',
            onKeyDown,
          })}
          css={accordionHeaderStyles}
        >
          {handleConditionalChevronRender()}
          <div
            className='header-container'
            css={css`
              flex: 1;
              display: flex;
              width: 100%;
              padding: ${typeof headerPadding !== 'undefined'
                ? spacing(...(typeof headerPadding === 'number' ? [headerPadding] : headerPadding))
                : spacing(...(variant === 'location' && size ? locationPadding[size] : [2]))};
            `}
          >
            <DefaultAccordionHeader title={title} subtitle={subtitle} />
            {children}
          </div>
        </Component>
      </MainHeading>
    );
  }
);

const chevronAnimatedStyle = (isActive: boolean) => css`
  transition: transform 300ms ease-out;
  display: flex;
  flex-direction: center;
  justify-content: center;
  transform: rotate(${isActive ? 180 : 0}deg);
`;

const chevronContainerMarginStyle = (
  spacing: WeaveThemeValues['Spacing'],
  chevronPosition?: AccordionChevronPosition
) => {
  if (chevronPosition === 'left') {
    return css`
      margin-left: ${spacing(1)};
    `;
  }
  return css`
    margin-right: ${spacing(1)};
  `;
};

type AccordionLocationHeaderProps = {
  title: string;
} & React.HTMLAttributes<HTMLSpanElement>;

const AccordionLocationHeader = ({ title, ...rest }: AccordionLocationHeaderProps) => {
  const { spacing } = useThemeValues();
  const { hasMultiLocations: hasMultiLocationsGlobal } = useAccordionContext();
  const { hasMultiLocations } = useAccordionItemContext();

  const IconComponent = hasMultiLocations ?? hasMultiLocationsGlobal ? MultiLocationSmall : LocationIcon;

  return (
    <span
      css={css`
        display: flex;
        align-items: center;
        gap: ${spacing(1)};
      `}
      {...rest}
    >
      <IconComponent size={16} />
      <Text
        css={css`
          margin: 0;
          color: inherit;
        `}
        size='large'
      >
        {title}
      </Text>
    </span>
  );
};

const AccordionHeaderNamespace = Object.assign(AccordionHeaderOptional, {
  Location: AccordionLocationHeader,
});

export { AccordionHeaderNamespace as AccordionHeader };
