import { Children, ReactElement } from 'react';
import type { ListboxChildren } from '../../../listbox/listbox.provider';

type Props = {
  children: ListboxChildren;
  value: string | string[];
};

export const getSelectedChild = ({ children, value }: Props) => {
  const targetValue = Array.isArray(value) ? value[0] : value;
  if (targetValue) {
    return Children.toArray(children).find((child) => {
      if (!child) return false;
      return child?.['props']?.value === targetValue;
    }) as ReactElement;
  }
  return;
};
/**
 * Grabs the children of the selected option for displaying.
 * If multiselect, uses only the first value (per design.)
 */
export const getDisplayValue = (props: Props) => {
  const activeOption = getSelectedChild(props);
  return activeOption?.props.displayValue ?? activeOption?.props.children;
};
