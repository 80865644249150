import { MutableRefObject, ReactNode, ReactPortal, useCallback, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

export type PortalDivAttributes = Omit<React.HTMLAttributes<HTMLDivElement>, 'style'> & {
  style?: string;
};

function createRoot(attributes: PortalDivAttributes, className?: string): HTMLDivElement | null {
  if (typeof document !== 'undefined') {
    const root = document.createElement('div');

    if (className) root.className = className;

    for (const key in attributes) {
      root.setAttribute(key, attributes[key]);
    }
    return root;
  }
  return null;
}

export type UsePortalProps = {
  attributes?: PortalDivAttributes;
  className?: string;
  mountTarget?: string;
  prepend?: boolean;
};

/**
 * Function to simplify creating portals with ease. Will create a div for mounting the portal, will also clean it up on unmount.
 * @param {object} [props.attributes] - An object of html attributes to add to the div created by the portal.
 * @param {object} [props.className] - ClassName to apply to the portal div.
 * @param {string} [props.mountTarget] - CSS selector for the element where the portal should add the div.
 * @param {string} [props.prepend] - Should the portal element be added first in the container instead of last?
 */
export function usePortal({ attributes = {}, className, mountTarget, prepend }: UsePortalProps): {
  Portal: ({ children }: { children: ReactNode }) => ReactPortal | null;
  portalRef: MutableRefObject<HTMLDivElement | null>;
} {
  const rootEl = useRef<HTMLDivElement>(createRoot(attributes, className));

  useEffect(() => {
    const target = mountTarget ? document.querySelector(mountTarget) ?? document.body : document.body;

    if (rootEl.current) {
      // insert the portal container in the right place
      if (prepend && target.firstChild) {
        target.insertBefore(rootEl.current, target.firstChild);
      } else {
        target.appendChild(rootEl.current);
      }
    }

    return () => {
      if (rootEl.current) rootEl.current.remove();
    };
  }, []);

  const Portal = useCallback(({ children }: { children: ReactNode }) => {
    if (rootEl.current) {
      return createPortal(children, rootEl.current);
    }
    return null;
  }, []);

  return { Portal, portalRef: rootEl };
}
