import { css } from '@emotion/react';
import { styles } from '../../../../styles';
import { PolymorphicComponentPropWithRef, PolymorphicRef } from '../../../../type-utils';
import { forwardRef } from 'react';
import { useThemeValues } from '../../../../hooks';
import { useVerticalSlideModalContext } from '../vertical-slide-modal';

type VerticalSlideModalContentProps<C extends React.ElementType> = PolymorphicComponentPropWithRef<C>;

export const VerticalSlideModalContent = forwardRef(
  <C extends React.ElementType = 'div'>(
    { children, as, ...rest }: VerticalSlideModalContentProps<C>,
    ref: PolymorphicRef<C>
  ) => {
    const { descriptionId } = useVerticalSlideModalContext();
    const theme = useThemeValues();
    const Component = as || 'div';

    return (
      <Component
        ref={ref}
        css={[
          css`
            flex: 1;
            overflow-y: auto;
            position: relative;
          `,
          styles.smallScrollbar(theme),
        ]}
        {...rest}
        id={descriptionId}
      >
        {children}
      </Component>
    );
  }
);
