import { useRef, useCallback } from 'react';

export default function useCallbackRef<T>(rawCallback: (node: T) => () => void): React.RefCallback<T> {
  const cleanupRef = useRef<(() => void) | null>(null);
  const callback = useCallback(
    (node: T) => {
      if (cleanupRef.current) {
        cleanupRef.current();
        cleanupRef.current = null;
      }

      if (node) {
        cleanupRef.current = rawCallback(node);
      }
    },
    [rawCallback]
  );

  return callback;
}
