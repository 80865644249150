import React, { useCallback, useEffect, useState } from 'react';
import { contextFactory, useUniqueID } from '../../hooks';

export type ActiveTab = { activeTab: string; uniqueId: string };
export type TabSetter = (id: string) => void;

export const [ActiveTabContext, useActiveTab] = contextFactory<ActiveTab>();
export const [TabSetterContext, useTabSetter] = contextFactory<TabSetter>();

export type TabsProviderProps = {
  children: React.ReactNode;
  initialTab: string;
  controlledTab?: string;
  onChange?: TabSetter;
};

export const TabsProvider = ({ children, initialTab, controlledTab, onChange }: TabsProviderProps) => {
  const [activeTabId, setActiveTabId] = useState(controlledTab ?? initialTab);
  const uniqueId = useUniqueID();

  useEffect(() => {
    setActiveTabId(controlledTab ?? initialTab);
  }, [controlledTab, initialTab]);

  const activeTab = controlledTab ?? activeTabId;

  const handleTabChange = useCallback(
    (id: string) => {
      setActiveTabId(id);
      if (typeof onChange === 'function') {
        onChange(id);
      }
    },
    [onChange]
  );

  return (
    <TabSetterContext.Provider value={handleTabChange}>
      <ActiveTabContext.Provider value={{ activeTab, uniqueId }}>{children}</ActiveTabContext.Provider>
    </TabSetterContext.Provider>
  );
};
