import { useState, useEffect, PropsWithChildren } from 'react';
import { css } from '@emotion/react';
import { useTranslation, Trans } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text, SpinningLoader, Heading } from '@frontend/design-system';
import { StatusIndicator } from '../..';
import SunbitLogo from '../assets/SunBit-logo.png';

const page = css`
  padding-top: 0;
  margin-top: -${theme.spacing(2)};
`;

const pageHeader = css`
  margin-bottom: ${theme.spacing(4)};
  display: flex;
  flex-flow: row;
`;

type MerchantStatusObjType = {
  text: string | null;
  indicator: 'success' | 'critical' | 'warning';
};

type WrapperProps = {
  merchantStatus: string | null;
  isLoading: boolean;
};

export const BuyNowPayLaterPageWrapper = ({ merchantStatus, isLoading, children }: PropsWithChildren<WrapperProps>) => {
  const { t } = useTranslation('payments');
  const [merchantStatusObj, setMerchantStatusObj] = useState<MerchantStatusObjType>({
    text: null,
    indicator: 'warning',
  });

  const getStatus = () => {
    switch (merchantStatus) {
      case 'ACTIVATED':
        setMerchantStatusObj({ text: t('Account Active'), indicator: 'success' });
        break;
      case 'DECLINED':
        setMerchantStatusObj({ text: t('Declined'), indicator: 'critical' });
        break;
      case 'SUBMITTED':
        setMerchantStatusObj({ text: t('Pending Review'), indicator: 'warning' });
        break;
      case 'CREATED':
      default:
        setMerchantStatusObj({
          text: t('Finish account activation to offer buy now, pay-over-time to your customers'),
          indicator: 'warning',
        });
        break;
    }
  };

  const getPageSubtitle = () => {
    if (merchantStatus === 'NOT_CREATED') {
      return (
        <Trans t={t}>
          <Text color='light'>Create a Sunbit account below to offer buy now, pay-over-time to your customers</Text>
        </Trans>
      );
    }
    return (
      <Text color='light'>
        <StatusIndicator
          status={merchantStatusObj.indicator}
          css={css`
            margin-right: ${theme.spacing(1)};
          `}
          data-trackingid='buy-now-pay-later-status'
        />
        {merchantStatusObj.text}
      </Text>
    );
  };

  useEffect(() => {
    getStatus();
  }, [merchantStatus]);

  if (isLoading) {
    return (
      <SpinningLoader
        size='xl'
        css={css`
          position: absolute;
          top: 50%;
          left: 50%;
        `}
      />
    );
  }

  return (
    <div css={page}>
      <section css={pageHeader}>
        <div css={{ marginRight: theme.spacing(2) }}>
          <img src={SunbitLogo} alt={t('Sunbit Logo')} />
        </div>
        <div>
          <div css={{ marginBottom: theme.spacing(0.5) }}>
            <Heading>{t('Sunbit Buy Now, Pay-over-time')}</Heading>
          </div>
          <div css={{ maxWidth: '750px' }}>{getPageSubtitle()}</div>
        </div>
      </section>
      {children}
    </div>
  );
};
