import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../component-theme/theme-types';

export type FlyoutMenuItemThemeValues = {
  spacing: WeaveThemeValues['Spacing'];
  backgroundColor: WeaveThemeValues['Color'];
  borderColor: WeaveThemeValues['Color'];
  disabledColor: WeaveThemeValues['Color'];
};
export type IconStyleThemeValues = {
  spacing: FlyoutMenuItemThemeValues['spacing'];
};

export const flyoutMenuItemTheme: FlyoutMenuItemThemeValues = {
  spacing: theme.spacing,
  backgroundColor: theme.colors.neutral10,
  borderColor: theme.colors.neutral20,
  disabledColor: theme.colors.status.disabled,
};

export const flyoutMenuItemThemeOriginal: FlyoutMenuItemThemeValues = {
  spacing: themeOriginal.spacing,
  backgroundColor: themeOriginal.colors.gray[200],
  borderColor: themeOriginal.colors.gray[300],
  disabledColor: themeOriginal.colors.disabled,
};

export const iconStyleTheme: IconStyleThemeValues = {
  spacing: theme.spacing,
};

export const iconStyleThemeOriginal: IconStyleThemeValues = {
  spacing: themeOriginal.spacing,
};
