export const shadows = {
  none: 'none',
  light: '0 4px 10px 0 rgba(49, 53, 61, 0.13)',
  heavy: '0 4px 10px 0 rgba(49, 53, 61, 0.24)',
  footer: '0 -2px 4px 0 rgba(49, 53, 61, 0.2)',
  header: '0 4px 4px 0 rgba(49, 53, 61, 0.19)',
  hover: '0 8px 20px 0 rgba(49, 53, 61, 0.25)',
  innerLeft: 'inset 10px 0 8px -8px rgba(32, 35, 40, 0.12)',
  innerRight: 'inset -10px 0 8px -8px rgba(32, 35, 40, 0.12)',
} as const;
