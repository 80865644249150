import { css } from '@emotion/react';
import { PaymentPlanLocationInfo } from '@frontend/api-payment-plans';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Heading, Modal, Text } from '@frontend/design-system';

const styles = {
  modalTitle: css`
    margin-bottom: ${theme.spacing(3)};
  `,
  modalBody: css`
    padding: ${theme.spacing(1, 3)};
  `,
  modalText: css`
    margin: ${theme.spacing(2, 0)};
    &:first-of-type {
      margin-top: 0;
    }
  `,
  modalTextBtn: css`
    font-size: ${theme.font.size.large};
  `,
};

interface PlanTermsOfServiceProps {
  show: boolean;
  onClose: () => void;
  location: PaymentPlanLocationInfo | undefined;
}

export const PlanTermsOfService = ({ show = false, onClose, location }: PlanTermsOfServiceProps) => {
  const { t } = useTranslation('payments');

  return (
    <Modal show={show} onClose={onClose} maxWidth={800}>
      <Modal.Header onClose={onClose} css={styles.modalTitle} textAlign='center'>
        {t('Terms of service')}
      </Modal.Header>
      <Modal.Body css={styles.modalBody}>
        <Trans t={t}>
          <Heading level={2}>Payment Authorization</Heading>
          <Text>
            <b>
              <em>Storing Payment Method Details:&nbsp;</em>
            </b>
            You authorize us to store your payment method details. We will use this information to authorize future
            charges as described in this Agreement. Unless you opt-out, we may also use this information to prefill your
            payment method details for future purchases. In the event <b>{location?.name}</b> obtains updated payment
            method information from your bank, you agree that we may update and use this information in our system. You
            further agree that if your primary payment method fails, we may use any alternative method provided by you
            to process the outstanding payment.
          </Text>
          <Text>
            <b>
              <em>Fees Imposed By Your Financial Institution or Card Issuer:&nbsp;</em>
            </b>
            You understand that your financial institution or card issuer may impose fees in connection with rejected
            charges or debits, and you agree that we do not have any liability to you for such fees. If you know that a
            payment will be rejected by your financial institution (e.g., because there is not enough money in the
            account or availability on the card), you should contact us so that alternate arrangements can be made.
          </Text>
          <Text>
            <b>
              <em>Revocation and Termination:&nbsp;</em>
            </b>
            To cancel your Payment Authorization, please call Business AT LEAST THREE (3) BUSINESS DAYS PRIOR TO THE
            NEXT SCHEDULED PAYMENT. Otherwise, the Payment may have already been automatically initiated.
          </Text>
          <Text>
            <b>
              <em>Miscellaneous:&nbsp;</em>
            </b>
            You promise that the Payment Method is legitimate, open, and active and that you are an authorized user on
            such account(s). You understand that this Authorization is subject to applicable law and network rules. Each
            payment will be processed in U.S. dollars, and, if it is converted into another currency, its amount may
            vary based on fluctuations in the applicable conversion rate.
          </Text>
          <Heading level={2}>Electronic Consent Agreement</Heading>
          <Text>
            By using the Services offered by <b>{location?.name}</b>, you consent and agree that:
          </Text>
          <ul>
            <li>
              The Business can provide all communications, payment information, terms, disclosures required by law, and
              other information (collectively, “Communications”) to you electronically during the course of the
              relationship, including via any site, app, or email address(es) that you provided to us.
            </li>
            <li>
              You are providing your consent to receive electronic communications pursuant to the Electronic Signatures
              in Global and National Commerce Act and our intent is that the statute applies to the fullest extent
              possible.
            </li>
            <li>You will access and retain any Communication received from us.</li>
            <li>
              This consent applies to any Communication in connection with any Business relationship with you, including
              all future Communications from us, unless you have withdrawn your consent by the procedure outlined below
              in “Withdrawing Consent”.
            </li>
          </ul>
          <Text>
            You understand and acknowledge that, in order to access and retain Communications, you will need the
            following:
          </Text>
          <ul>
            <li>A computer or a mobile device with an internet connection.</li>
            <li>A current web browser that includes at least 128-bit encryption with cookies enabled.</li>
            <li>A valid email address or mobile phone number.</li>
            <li>Sufficient storage space to save past Communications or a means to print them.</li>
          </ul>
          <Text>
            <b>
              <em>Withdrawing consent</em>
            </b>
            <br />
            If you’d like to withdraw your consent to receive Communications electronically, you may contact us in
            writing. If your consent is withdrawn, you may be prohibited from obtaining or using Business Services.
          </Text>
          <Text>
            <b>
              <em>Requesting paper copies</em>
            </b>
            <br />
            You may also request paper copies of any specific Communication within 180 days of the date of the
            Communication without withdrawing your electronic communications consent given in this Agreement.
          </Text>
          <Text>
            <b>
              <em>Updating your contact information</em>
            </b>
            <br />
            It is your responsibility to ensure that Business has your current email address or mobile phone number so
            that we can communicate with you electronically. If you need to update your primary email address, please
            contact us to make the change. If we send you an electronic Communication that you don’t receive due to an
            outdated email address, we will maintain the position that the Communication was provided to you.
          </Text>
        </Trans>
      </Modal.Body>
    </Modal>
  );
};
