import { css } from '@emotion/react';
import { StarFilledIcon, StarIcon } from '@frontend/design-system';
import { StarRatingFieldProps } from './useStarRating';

const noop = () => null;

export const StarRating = ({
  value: rating,
  ratingRange,
  disabled,
  enableEdit,
  onClick,
  trackingID = '',
  name,
}: StarRatingFieldProps) => (
  <div css={starRatingContainer} data-trackingid={trackingID}>
    {[...Array(ratingRange)].map((star, index) => {
      index += 1;
      return (
        <button
          disabled={disabled}
          key={`rating-${index}-${star}`}
          onClick={enableEdit ? () => onClick(index) : noop}
          css={buttonStyles(enableEdit)}
          name={`${name}-${index}`}
        >
          {index <= rating ? <StarFilledIcon /> : <StarIcon />}
        </button>
      );
    })}
  </div>
);

const starRatingContainer = css`
  display: flex;
`;

const buttonStyles = (enableEdit: boolean) => css`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: ${enableEdit ? 'pointer' : 'default'};
`;
