import { useEffect } from 'react';
import type { UseCalendarReturn } from '../../../calendar';
import { DialogCalendarLayout } from '../../../calendar';

export const CalendarDialogCalendar = (props: Omit<UseCalendarReturn, 'children'>) => {
  useEffect(() => {
    setTimeout(() => props.focusDate(), 0);
  }, []);
  return <DialogCalendarLayout {...props} />;
};
