import { InstructionsTypes } from '@frontend/api-phone-tree';
import { ScheduleTypes, ScheduleUtils } from '@frontend/api-schedule';
import { DepartmentScheduleTypes, DepartmentWithSchedules, ListDefaultSMSTypes } from './types';
import { useListDefaultSMSQueries } from './queries';

export const getDepartmentScheduleStatus = (
  department: DepartmentWithSchedules,
  timezone: string
): DepartmentScheduleTypes => {
  const schedules = department.schedules;
  if (department.voicemailOverrideEnabled) {
    return DepartmentScheduleTypes.VMO;
  }
  if (department.forwardingEnabled) {
    return DepartmentScheduleTypes.FWD;
  }
  const isOpen = !!schedules.find(
    (schedule) =>
      schedule.type === ScheduleTypes.ScheduleType.Open && ScheduleUtils.scheduleIsCurrent(schedule, timezone)
  );
  const currentBreakSchedules = schedules.filter(
    (schedule) =>
      schedule.type === ScheduleTypes.ScheduleType.Break && ScheduleUtils.scheduleIsCurrent(schedule, timezone)
  );
  if (isOpen) {
    return !currentBreakSchedules.length ? DepartmentScheduleTypes.Open : DepartmentScheduleTypes.Break;
  }

  return DepartmentScheduleTypes.Closed;
};

export const InstructionTypeMap = {
  [InstructionsTypes.Instruction.CallGroup as InstructionsTypes.Instruction]: 'Call Group',
  [InstructionsTypes.Instruction.CallQueue]: 'Call Queue',
  [InstructionsTypes.Instruction.PhoneTree]: 'Phone Tree',
  [InstructionsTypes.Instruction.ForwardDevice]: 'Device',
  [InstructionsTypes.Instruction.ForwardNumber]: 'Forwarding Number',
  [InstructionsTypes.Instruction.Play]: 'Play Media',
  [InstructionsTypes.Instruction.VoicemailBox]: 'Listen to Voicemails',
  [InstructionsTypes.Instruction.VoicemailPrompt]: 'Send to Voicemail',
  [InstructionsTypes.Instruction.IVRMenu]: 'Phone Tree',
};

const getScheduleTypes = (type: DepartmentScheduleTypes) => {
  switch (type) {
    case DepartmentScheduleTypes.Open:
      return ScheduleTypes.ScheduleType.Open;
    case DepartmentScheduleTypes.Break:
      return ScheduleTypes.ScheduleType.Break;
    default:
      return ScheduleTypes.ScheduleType.Closed;
  }
};

export const getDepartmentRoutingSummary = (department: DepartmentWithSchedules, timezone: string): string => {
  // This is an overriding setting, unlike the voicemail instructions that can be set in a schedule's routing options
  // VMO should have precedence over Call Forwarding
  if (department.voicemailOverrideEnabled) {
    return 'Voicemail Override (VMO)';
  }

  // This is an overriding setting, unlike the call forwarding instructions that can be set in a schedule's routing options
  if (department.forwardingEnabled) {
    return 'Call Forwarding Override (FWD)';
  }

  const { schedules } = department;

  const currentDeptStatus = getDepartmentScheduleStatus(department, timezone);
  const currentSchedule = schedules.find(
    (schedule) =>
      schedule.type === getScheduleTypes(currentDeptStatus) && ScheduleUtils.scheduleIsCurrent(schedule, timezone)
  );

  if (!currentSchedule) {
    return 'No Schedule';
  }

  if (!currentSchedule.callRoute?.instructions?.[0]) {
    return 'No Routing Settings Configured';
  }

  const names = currentSchedule.callRoute.instructions.map((inst) => {
    const type = inst.type;
    return InstructionTypeMap[type];
  });

  return names.join(' > ');
};

export const reduceDefaultSMSQueriesToLocationIdMap = <E = unknown, D = ListDefaultSMSTypes['output'], T = D>(
  queries: ReturnType<typeof useListDefaultSMSQueries<E, D>>,
  mapFn?: (data: D) => T
): {
  data: Record<string, T>;
  isLoading: boolean;
  isFetched: boolean;
  isError: boolean;
} => ({
  data: queries.reduce<Record<string, T>>((acc, query) => {
    if (query.data) {
      const { locationId, ...rest } = query.data;
      acc[locationId] = mapFn ? mapFn(rest as D) : (rest as T);
    }
    return acc;
  }, {}),
  isLoading: queries.some((query) => query.isLoading),
  isFetched: queries.every((query) => query.isFetched),
  isError: queries.some((query) => query.isError),
});
