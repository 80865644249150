import { ElementType, forwardRef, KeyboardEvent, useState, ReactEventHandler, useCallback, Fragment } from 'react';
import { Modal, useModalControl } from '../../modal';
import { XIcon, ImageIcon, WarningBadgeIcon, DownloadIcon } from '../../../icon';
import { Text } from '../../text';
import { useChatItemContext } from '..';
import { PolymorphicComponentPropWithRef, PolymorphicRef } from '../../../type-utils';
import { useStyles } from '../../../use-styles';
import { KeyNames } from '../../../constants';
import { ContextMenu, ContextMenuActions } from '../../context-menu/context-menu';
import { IconButton } from '../../icon-button';

export type ImageBubbleProps<C extends ElementType = 'li'> = PolymorphicComponentPropWithRef<C> & {
  loadingText?: string;
  src?: string;
  suppressPreview?: boolean;
  onError?: ReactEventHandler<HTMLImageElement>;
  maxWidth?: string;
  maxHeight?: string;
  contextActions?: ContextMenuActions;
  contextMenuReturnFocus?: boolean;
  onClickDownload?: () => void;
  downloadImageTrackingId?: string;
};

export const ImageBubble = forwardRef(
  <C extends ElementType = 'li'>(
    {
      as,
      loadingText,
      src,
      suppressPreview,
      onError,
      maxWidth,
      maxHeight,
      contextActions,
      contextMenuReturnFocus,
      onClickDownload,
      downloadImageTrackingId,
      ...rest
    }: ImageBubbleProps<C>,
    ref: PolymorphicRef<C>
  ) => {
    const Component = as || 'li';
    const [error, setError] = useState(false);
    const previewModalProps = useModalControl();
    const { hoverProps, isFuture } = useChatItemContext();
    const styles = useStyles('ChatItem', 'imageBubbleStyles', {
      isLoading: !src || error,
      suppressPreview: !!suppressPreview,
      isFuture,
      maxWidth,
      maxHeight,
    });
    const modalStyles = useStyles('ChatItem', 'imagePreviewModalStyles');
    const modalIconStyles = useStyles('ChatItem', 'imagePreviewModalIconStyles');
    const downloadIconStyle = useStyles('ChatItem', 'downloadIconStyle');

    const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
      if (event.key === KeyNames.Enter || event.key === KeyNames.Space) {
        previewModalProps.triggerProps.onClick(event);
      }
    };

    const Wrapper = useCallback(
      contextActions
        ? ({ children }: { children: JSX.Element }) => (
            <ContextMenu actions={contextActions} returnFocus={contextMenuReturnFocus}>
              {children}
            </ContextMenu>
          )
        : Fragment,
      [src]
    );

    return (
      <>
        <Wrapper>
          <Component ref={ref} css={styles} tabIndex={0} {...hoverProps} onKeyDown={handleKeyDown} {...rest}>
            <figure {...(suppressPreview && src ? {} : previewModalProps.triggerProps)}>
              {src && !error ? (
                <img
                  src={src}
                  onError={(e) => {
                    setError(true);
                    onError?.(e);
                  }}
                />
              ) : (
                <>
                  {error ? <WarningBadgeIcon color='error' /> : <ImageIcon color='default' />}
                  {error ? (
                    <Text>There was an error loading this image.</Text>
                  ) : (
                    loadingText && <Text>{loadingText}</Text>
                  )}
                </>
              )}
            </figure>
          </Component>
        </Wrapper>
        {src && (
          <Modal {...previewModalProps.modalProps} css={modalStyles}>
            <IconButton
              label='close-image-preview-button'
              size='xsmall'
              css={modalIconStyles}
              onClick={previewModalProps.closeModal}
            >
              <XIcon size={14} />
            </IconButton>
            {onClickDownload && (
              <IconButton
                label='download-image-preview-button'
                size='xsmall'
                css={downloadIconStyle}
                onClick={onClickDownload}
                {...(downloadImageTrackingId && { trackingId: downloadImageTrackingId })}
              >
                <DownloadIcon size={14} />
              </IconButton>
            )}
            <img src={src} />
          </Modal>
        )}
      </>
    );
  }
);
