import type {
  CSSObject,
  Keyframes,
  // InterpolationPrimitive,
  SerializedStyles,
  ComponentSelector,
} from '@emotion/react';
import { RootComponentStyles } from '../component-theme';
import { useContext, useMemo, useRef } from 'react';
import { StylesContext } from './styles-provider';

export type InterpolationPrimitive =
  | null
  | undefined
  | boolean
  | number
  | string
  | ComponentSelector
  | Keyframes
  | SerializedStyles
  | CSSObject;

export type Styles = InterpolationPrimitive | InterpolationPrimitive[];

type StyleValueFunction = (props: any) => Styles;

type StyleValues = Styles | StyleValueFunction;

export type StyleObjectSlice =
  | StyleValues
  | {
      [componentPart: string]: StyleValues;
    };

// export type StyleSelector<TComponentKey extends keyof RootComponentStyles> = (
//   styles: RootComponentStyles,
//   props?: Record<string, any>
// ) => RootComponentStyles[TComponentKey];

export type GetPropsFromStyleFunction<TValue> = TValue extends StyleValueFunction ? Parameters<TValue>[0] : never;

// type DerivedStyleProps<> =

// This is an internal helper to call the function if the value is a function
// there is little benefit to strict typing here
function evaluateStyle(value: StyleValues, props: Record<string, any>) {
  return typeof value === 'function' ? value(props) : value;
}

// overloaded loaded TS function
// start with the (componentKey, selector, props) version to make the TS experience better for dev
export function useStyles<
  TComponentKey extends keyof RootComponentStyles,
  TSelector extends keyof RootComponentStyles[TComponentKey],
  TValue extends RootComponentStyles[TComponentKey][TSelector]
>(componentKey: TComponentKey, selector: TSelector, props?: GetPropsFromStyleFunction<TValue>): Styles;
export function useStyles<
  TComponentKey extends keyof RootComponentStyles,
  TValue extends RootComponentStyles[TComponentKey]
>(componentKey: TComponentKey, props?: GetPropsFromStyleFunction<TValue>): Styles;
/**
 * TODO: This type broke the inference for the signature above
 * Need to decide if we should continue to support this or remove
 * this selector function pattern from the utility
 */
// export function useStyles<
//   TComponentKey extends keyof RootComponentStyles,
//   TSelector extends keyof RootComponentStyles[TComponentKey],
//   TValue extends RootComponentStyles[TComponentKey][TSelector]
// >(
//   componentKey: TComponentKey,
//   selector: StyleSelector<TComponentKey>,
//   props?: GetPropsFromStyleFunction<TValue>
// ): Styles | Record<keyof RootComponentStyles[TComponentKey], Styles>;
export function useStyles<
  TComponentKey extends keyof RootComponentStyles,
  TSelector extends keyof RootComponentStyles[TComponentKey],
  TValue extends RootComponentStyles[TComponentKey][TSelector]
>(
  componentKey: keyof RootComponentStyles,
  selectorOrProps?:
    | TSelector
    // | StyleSelector<TComponentKey>
    | GetPropsFromStyleFunction<TValue>,
  props?: GetPropsFromStyleFunction<TValue>
): Styles | Record<string, Styles> {
  const styleObject = useContext(StylesContext) as RootComponentStyles;

  if (!styleObject) {
    throw new Error('You must use a style object hook inside of a StyleProvider');
  }

  const componentStyles = useRef<StyleObjectSlice | StyleValues>();

  // TODO: should this be in the MEMO?
  if (typeof selectorOrProps === 'object' || typeof selectorOrProps === 'undefined') {
    componentStyles.current = styleObject[componentKey];
  } else if (typeof selectorOrProps === 'string' || 15 > 1) {
    //@ts-ignore - TS is looking for strings that exist in the component keys and not just a general type string
    componentStyles.current = styleObject[componentKey]?.[selectorOrProps];
  }
  // TODO: remove this pattern
  else if (typeof selectorOrProps === 'function') {
    componentStyles.current = selectorOrProps(styleObject[componentKey], props);
  } else {
    throw new Error(
      `Got an unsupported style in useStyles for ${componentKey}, selectorOrProp ${String(selectorOrProps)}`
    );
  }

  const styles = useMemo(() => {
    const _props = typeof selectorOrProps === 'object' ? selectorOrProps : props;

    return typeof componentStyles.current !== 'object'
      ? evaluateStyle(componentStyles.current!, _props ?? {})
      : Object.entries(componentStyles.current ?? {}).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: evaluateStyle(value, _props ?? {}),
          }),
          {}
        );
  }, [props, selectorOrProps]); //TODO: should this be each prop?

  return styles;
}
