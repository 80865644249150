import { createStoreWithSubscribe, createShallowStore } from '@frontend/store';

export interface DemoLocationSourceIdsStore {
  demoSourceIds: string[];
  setDemoSourceIds: (sourceIds: string[]) => void;
}

export const useDemoLocationSourceIds = createStoreWithSubscribe<DemoLocationSourceIdsStore>(
  (set) => ({
    demoSourceIds: [],
    setDemoSourceIds: (_sourceIds: string[]) => {
      set(
        (state) => {
          state.demoSourceIds = _sourceIds;
        },
        false,
        'SET_DEMO_LOCATION_SOURCE_IDS'
      );
    },
  }),
  { name: 'DemoLocationSourceIdsStore', trace: true }
);

export const useDemoLocationSourceIdsShallowStore = createShallowStore(useDemoLocationSourceIds);
