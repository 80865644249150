export const digitsOnly = (value = '') => value.replace(/\D/g, '');

// phone formatting
type PhoneFormat = 'standard' | 'hyphenated' | 'numbers';
type Replacers = { local: string; foreign: string };
type PhoneFormatOptions = {
  format?: PhoneFormat;
  hideCountry?: boolean;
};

const phonePatterns = {
  local: /^(\d{3})(\d{3})(\d{4})$/,
  foreign: /^(\d{1})(\d{3})(\d{3})(\d{4})$/,
};

const formatPhone =
  (replacers?: Replacers) =>
  (value: string, hideCountry?: boolean): string => {
    let digits = digitsOnly(value);
    let pattern = digits.length === 11 ? 'foreign' : 'local';
    if (pattern === 'foreign' && hideCountry) {
      digits = digits.substring(1);
      pattern = 'local';
    }
    if (!replacers) return digits;
    return digits.replace(phonePatterns[pattern], replacers[pattern]);
  };

const phoneFormatters = {
  standard: formatPhone({ local: '($1) $2-$3', foreign: '+1 $2-$3-$4' }),
  hyphenated: formatPhone({ local: '$1-$2-$3', foreign: '+1 $2-$3-$4' }),
  numbers: formatPhone(),
};

export const phone = (value: string, { format = 'standard', hideCountry }: PhoneFormatOptions = {}) =>
  phoneFormatters[format](value, hideCountry);

// number + currency formatting
type NumberOptions = {
  locale?: 'en-US' | 'en-CA' | 'fr-CA';
  currency?: 'USD' | 'CAD';
  decimals?: number;
};

const getDefaultDecimals = (value: string): number => {
  const decimals = value.split('.').pop()?.length ?? 0;
  if (decimals === 1) return 2;
  return decimals;
};

/**
 * Convert numbers or numeric strings to fancy formats.
 * @param {string | number} value Value to be formatted
 * @param {Object} [options] Options for customizing output
 * @returns {string | number} NaN for bad input, otherwise a formatted numeric value.
 */
export const numeric = (
  value: string | number,
  { locale = 'en-US', currency, decimals }: NumberOptions = {}
): string | number => {
  const amount = +value;
  if (isNaN(amount)) return amount;
  const decimal = decimals ?? getDefaultDecimals(value.toString());
  const options: Intl.NumberFormatOptions = {
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
  };
  if (currency) {
    options.style = 'currency';
    options.currency = currency;
  }
  return amount.toLocaleString(locale, options);
};

export const currency = (value: string | number, { locale = 'en-US', currency = 'USD', ...rest }: NumberOptions = {}) =>
  numeric(value, { ...rest, locale, currency });
