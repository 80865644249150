import { createContext, ReactNode, useContext } from 'react';

type ThemeOptions = {
  skipAnimation?: boolean;
};

const ThemeOptionContext = createContext<ThemeOptions | undefined>(undefined);

type ThemeOptionProps = {
  skipAnimation?: boolean;
  children: ReactNode;
};

export const ThemeOptionProvider = ({ skipAnimation = false, children }: ThemeOptionProps) => {
  return <ThemeOptionContext.Provider value={{ skipAnimation }}>{children}</ThemeOptionContext.Provider>;
};

export const useThemeOptions = () => {
  return useContext(ThemeOptionContext);
};
