import { FC } from 'react';
import { Interpolation, Theme, css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { NakedButton, PrimaryButton, SecondaryButton, Text } from '@frontend/design-system';
import { useCardACHSelectionContext } from '../context';

interface CardACHSelectionActionProps {
  onBack?: () => void;
  onCancel: () => void;
  onPrimaryClick: () => void;
  primaryLabel: string;
  styles?: Interpolation<Theme>;
}

export const CardACHSelectionAction: FC<CardACHSelectionActionProps> = ({
  onBack,
  onCancel,
  onPrimaryClick,
  primaryLabel,
  styles,
}) => {
  const { t } = useTranslation('payments');

  const { selectedPM } = useCardACHSelectionContext();

  return (
    <div css={[actionBarStyles, styles]}>
      {!!onBack && (
        <NakedButton onClick={onBack}>
          <Text color='primary' weight='bold'>
            {t('Back')}
          </Text>
        </NakedButton>
      )}
      <div css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1), flex: 1, justifyContent: 'flex-end' }}>
        <SecondaryButton onClick={onCancel} css={{ width: 'max-content' }}>
          {t('Cancel')}
        </SecondaryButton>
        <PrimaryButton onClick={onPrimaryClick} disabled={!selectedPM} css={{ width: 'max-content' }}>
          {primaryLabel}
        </PrimaryButton>
      </div>
    </div>
  );
};

const actionBarStyles = css({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: theme.spacing(2, 3, 0, 3),
  alignItems: 'center',
});
