import { forwardRef, type ComponentProps } from 'react';
import { FieldLayoutWithAction } from '../../layouts';
import type { FieldActionProps, BasicFormFieldProps, ExtraFieldProps } from '../../layouts';
import { DateInput } from '../../atoms';

type Props = BasicFormFieldProps<'date'> & ExtraFieldProps & FieldActionProps;

export const DateField = forwardRef<HTMLDivElement, Props>(({ helperText, ...rest }, ref) => (
  <FieldLayoutWithAction
    {...rest}
    helperText={helperText ?? 'MM/DD/YYYY'}
    //TODO: this might be able to be improved. But this type cast just makes sure the field prop is any of the correct field types
    field={DateInput as ComponentProps<typeof FieldLayoutWithAction>['field']}
    ref={ref}
  />
));
