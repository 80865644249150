import { css } from '@emotion/react';
import { Heading } from '../../../';
import { IconButton } from '../../../';
import { XIcon } from '../../../../icon';
import { useStyles } from '../../../../use-styles';

type ClickHandler = (event?: React.PointerEvent | React.FormEvent) => void | Promise<void>;

type FilterSidebarHeaderProps = {
  closeFilterSidebar: ClickHandler;
  filterSidebarHeaderLabel?: string;
};

export const FilterSidebarHeader = ({ closeFilterSidebar, filterSidebarHeaderLabel }: FilterSidebarHeaderProps) => {
  const sidebarHeaderStyles = useStyles('TableCommonStyles', 'tableFilterStyles', {
    type: 'filterSidebarHeader',
  });

  return (
    <header css={sidebarHeaderStyles}>
      <Heading
        css={css`
          margin: 0;
        `}
        level={2}
      >
        {filterSidebarHeaderLabel ?? 'Filter Results'}
      </Heading>
      <IconButton onClick={closeFilterSidebar} label='Close Filters'>
        <XIcon />
      </IconButton>
    </header>
  );
};
