import EmptyStateAppointmentRequests from './empty-state-appointment-requests.svg?react';
import EmptyStateBirthdays from './empty-state-birthdays.svg?react';
import EmptyStateCollections from './empty-state-collections.svg?react';
import EmptyStateContactDirectories from './empty-state-contact-directories.svg?react';
import EmptyStateEmailMarketingSchedule from './empty-state-email-marketing-schedule.svg?react';
import EmptyStateEmailOptionsCompose from './empty-state-email-options-compose.svg?react';
import EmptyStateFaxContacts from './empty-state-fax-contacts.svg?react';
import EmptyStateFax from './empty-state-fax.svg?react';
import EmptyStateFollowUps from './empty-state-follow-ups.svg?react';
import EmptyStateGeneric from './empty-state-generic.svg?react';
import EmptyStateMarketing from './empty-state-marketing.svg?react';
import EmptyStateMessages from './empty-state-messages.svg?react';
import EmptyStateNoCallFollowUps from './empty-state-no-call-follow-ups.svg?react';
import EmptyStateNoCallsMade from './empty-state-no-calls-made.svg?react';
import EmptyStateNoTask from './empty-state-no-task.svg?react';
import EmptyStateOnlineScheduling from './empty-state-online-scheduling.svg?react';
import EmptyStateOverduePatients from './empty-state-overdue-patients.svg?react';
import EmptyStatePayments from './empty-state-payments.svg?react';
import EmptyStatePhoneExtensions from './empty-state-phone-extensions.svg?react';
import EmptyStatePMSConnection from './empty-state-pms-connection.svg?react';
import EmptyStateQuickFill from './empty-state-quick-fill.svg?react';
import EmptyStateRecentCalls from './empty-state-recent-calls.svg?react';
import EmptyStateReviews from './empty-state-reviews.svg?react';
import EmptyStateScheduleRequests from './empty-state-schedule-requests.svg?react';
import EmptyStateSchedule from './empty-state-schedule.svg?react';
import EmptyStateSummary from './empty-state-summary.svg?react';
import EmptyStateSyncPhone from './empty-state-sync-your-phone.svg?react';
import EmptyStateTextConnect from './empty-state-text-connect.svg?react';
import EmptyStateUpload from './empty-state-upload.svg?react';
import EmptyStateUsers from './empty-state-users.svg?react';
import EmptyStateVoicemail from './empty-state-voicemail.svg?react';

export const emptyStateGraphics = {
  appointment_requests: EmptyStateAppointmentRequests,
  birthdays: EmptyStateBirthdays,
  collections: EmptyStateCollections,
  contact_directories: EmptyStateContactDirectories,
  email_marketing_schedule: EmptyStateEmailMarketingSchedule,
  email_options_compose: EmptyStateEmailOptionsCompose,
  fax_contacts: EmptyStateFaxContacts,
  fax: EmptyStateFax,
  follow_ups: EmptyStateFollowUps,
  generic: EmptyStateGeneric,
  marketing: EmptyStateMarketing,
  messages: EmptyStateMessages,
  no_calls_made: EmptyStateNoCallsMade,
  no_task: EmptyStateNoTask,
  online_scheduling: EmptyStateOnlineScheduling,
  overdue_patients: EmptyStateOverduePatients,
  payments: EmptyStatePayments,
  phone_extensions: EmptyStatePhoneExtensions,
  pms_connection: EmptyStatePMSConnection,
  quick_fill: EmptyStateQuickFill,
  recent_calls: EmptyStateRecentCalls,
  reviews: EmptyStateReviews,
  schedule_requests: EmptyStateScheduleRequests,
  schedule: EmptyStateSchedule,
  summary: EmptyStateSummary,
  sync_your_phone: EmptyStateSyncPhone,
  text_connect: EmptyStateTextConnect,
  upload: EmptyStateUpload,
  users: EmptyStateUsers,
  voicemail: EmptyStateVoicemail,
  noCallFollowUps: EmptyStateNoCallFollowUps,
} as const;
