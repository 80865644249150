import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../component-theme/theme-types';

export type ContentContainerThemeValues = {
  backgroundColor: WeaveThemeValues['Color'];
  color: WeaveThemeValues['Color'];
};

export type SizeThemeValues = {
  fontSize: typeof theme.font.size | typeof themeOriginal.font.size;
  weight: typeof theme.font.weight | typeof themeOriginal.font.weight;
};

export type AvatarContainerThemeValues = {
  focusRingColor: WeaveThemeValues['Color'];
};

export const contentContainerTheme: ContentContainerThemeValues = {
  backgroundColor: theme.colors.neutral10,
  color: theme.colors.neutral90,
};

export const contentContainerThemeOriginal: ContentContainerThemeValues = {
  backgroundColor: themeOriginal.colors.gray400,
  color: themeOriginal.colors.white,
};

export const sizeTheme: SizeThemeValues = {
  fontSize: theme.font.size,
  weight: theme.font.weight,
};

export const sizeThemeOriginal: SizeThemeValues = {
  fontSize: themeOriginal.font.size,
  weight: themeOriginal.font.weight,
};

export const avatarContainerTheme: AvatarContainerThemeValues = {
  focusRingColor: theme.colors.primary50,
};

export const avatarContainerThemeOriginal: AvatarContainerThemeValues = {
  focusRingColor: themeOriginal.colors.weaveBlue,
};
