import React from 'react';
import { ActionPressedChip } from '../atoms';
import { Text } from '../../text';

type ItalicActionProps = Omit<React.ComponentProps<typeof ActionPressedChip>, 'children'>;

export const ItalicAction = ({ compact = true, showBoxShadow = true, ...rest }: ItalicActionProps) => {
  return (
    <ActionPressedChip {...rest} compact={compact} showBoxShadow={showBoxShadow}>
      <Text as='em' weight='bold'>
        i
      </Text>
    </ActionPressedChip>
  );
};
