import { legacyColors } from '../colors';
import { fontSize } from './font-size';

export const font = {
  family: "'Inter', sans-serif",
  colors: {
    primary: legacyColors.weaveBlue,
    default: legacyColors.gray[600],
    light: legacyColors.gray[500],
    disabled: legacyColors.gray[400],
    white: legacyColors.white,
    error: legacyColors.error,
    success: legacyColors.success,
    warn: legacyColors.warning,
  },
  lineHeight: 1.5,
  size: {
    large: fontSize(16),
    medium: fontSize(14),
    small: fontSize(12),
    xs: fontSize(10),
    h1: fontSize(36),
    h2: fontSize(24),
    h3: fontSize(20),
    inherit: 'inherit',
  },
  weight: {
    regular: 'normal',
    semibold: 600,
    bold: 700,
    inherit: 'inherit',
  },
} as const;
