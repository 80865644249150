import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const marginBottom = css`
  margin-bottom: ${theme.spacing(1)};
`;

export const marginLeft = css`
  margin-left: ${theme.spacing(1)};
`;

export const pageRow = css`
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: ${theme.spacing(3)};
`;

export const pageUrlRow = css`
  ${pageRow};
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: ${theme.spacing(1, 1)};
  & > * {
    margin-bottom: 0px;
  }
`;

export const pageQRRow = css`
  ${pageRow};
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${theme.spacing(1, 1)};
`;

export const pageRowTitle = css`
  ${marginBottom};
  font-weight: ${theme.font.weight.bold};
  display: flex;
  align-items: center;
  & > *:not(:last-child) {
    margin-right: ${theme.spacing(1)};
  }
`;

export const imageBorder = css`
  min-width: ${theme.spacing(32)};
  border: 1px solid ${theme.colors.neutral20};
  border-radius: ${theme.borderRadius.small};
  overflow: hidden;
`;

export const row = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${theme.spacing(1, 2)};
`;

export const column = css`
  display: flex;
  flex-flow: column;
  gap: ${theme.spacing(1)};
`;

export const textDecorationNone = css`
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

export const pageHr = css`
  border-top: 1px solid ${theme.colors.neutral20};
  margin-top: ${theme.spacing(1)};
  margin-bottom: ${theme.spacing(1)};
`;
