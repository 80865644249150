import { forwardRef, type ForwardedRef } from 'react';
import { FieldWrapper } from './field-wrapper.component';
import { FloatingLabel } from './floating-label.component';
import { HelperText } from './helper-text';
import { getFormFieldAtomProps } from './utils';
import type { InputProps } from '../atoms/types';
import { ClearFieldBtn } from './clear-field-btn.component';
import { css, SerializedStyles } from '@emotion/react';
import type { FieldKey, FormFieldProps } from './types';
import { useThemeValues } from '../../../hooks/use-theme-values';
import { NonFloatingLabel } from './non-floating-label.component';
import { useRefInterceptor } from '../../../hooks';

const FieldLayoutInner = (
  {
    outerProps,
    children,
    className,
    clearable,
    onClear,
    containerCss,
    endAdornment,
    field,
    fieldComponentProps,
    helperText,
    hidden,
    label,
    hasNonFloatingLabel,
    startAdornment,
    hideErrorText,
    hasPadding = true,
    /** Required when adding something to the start of a field */
    startAdornmentWidth = 16,
    onSearchSubmit,
    ...props
  }: FormFieldProps<FieldKey> & { hasPadding?: boolean },
  ref?: ForwardedRef<HTMLDivElement>
) => {
  const [localRef, elementRef] = useRefInterceptor(ref);
  const theme = useThemeValues();
  if (hidden) return null;
  // Ignore specialized props from custom value setters
  // TODO: This can be removed when we consolidate field configs
  const { ...rest } = props;

  const { fieldProps, descriptorProps, labelProps, wrapperProps } = getFormFieldAtomProps({
    ...(rest as any),
    descriptorText: helperText,
    label,
  });
  const FieldComponent = field;

  const clearField = () => {
    fieldProps.onChange({ name: rest.name, value: '' });
    onClear?.();
    if (localRef) {
      const input = localRef.current?.querySelector('input');
      input?.focus();
    }
  };
  const { active, ...restLabelProps } = labelProps;

  return (
    <div css={containerCss as SerializedStyles} ref={elementRef} {...outerProps}>
      {!!label && hasNonFloatingLabel && <NonFloatingLabel {...restLabelProps}>{label}</NonFloatingLabel>}
      <FieldWrapper className={className} hasPadding={hasPadding} {...wrapperProps}>
        {!!label && !hasNonFloatingLabel && (
          <FloatingLabel
            {...restLabelProps}
            active={active}
            leftOffset={startAdornment ? startAdornmentWidth : undefined}
          >
            {label}
          </FloatingLabel>
        )}
        {!onSearchSubmit && startAdornment}
        <FieldComponent {...(fieldProps as InputProps<any>)} {...(fieldComponentProps ? fieldComponentProps : {})} />
        {!onSearchSubmit && endAdornment}
        {!onSearchSubmit && clearable && !endAdornment && !!rest.value && (
          <ClearFieldBtn onClick={clearField} onBlur={() => rest?.onBlur()} onFocus={() => rest?.onFocus()} />
        )}
        {onSearchSubmit && clearable && !!rest.value && (
          <ClearFieldBtn
            css={css`
              margin-right: ${theme.spacing(3)};
            `}
            onClick={clearField}
            onBlur={() => rest?.onBlur()}
            onFocus={() => rest?.onFocus()}
          />
        )}
        {onSearchSubmit && endAdornment}
        {children}
      </FieldWrapper>
      {!hideErrorText && !!descriptorProps.children && <HelperText {...descriptorProps} />}
    </div>
  );
};

export const FieldLayout = forwardRef(FieldLayoutInner);
