import { css } from '@emotion/react';
import type { StoryHeaderProps, WarningTypeProps } from './story-header-theme';
import { getStoryHeaderTheme, getStoryHeaderThemeOriginal } from './story-header-theme';

const styles = ({ backgroundColor, borderRadius, spacing }: StoryHeaderProps) => css`
  max-width: 760px;
  background: ${backgroundColor};
  color: white;
  display: flex;
  align-items: center;
  border-radius: ${borderRadius};
  padding: ${spacing(2)};

  svg {
    margin-right: ${spacing(1)};
  }
`;

export const StoryHeaderStyles = {
  StoryHeader: (stylesProps: WarningTypeProps) => styles(getStoryHeaderTheme(stylesProps)),
};
export const StoryHeaderStylesOriginal = {
  StoryHeader: (stylesProps: WarningTypeProps) => styles(getStoryHeaderThemeOriginal(stylesProps)),
};
