import { ReactNode, useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { sendInvoiceReceipt } from '@frontend/api-invoices';
import { useTranslation } from '@frontend/i18n';
import { useMerchant } from '@frontend/payments-hooks';
import { useSelectedInvoice } from '@frontend/payments-invoice-controller';
import { useMultiStepModal } from '@frontend/payments-multistep-modal';
import { PaymentsTerminalController, StoredReader, TerminalReader } from '@frontend/payments-terminal-controller';
import { PickRequired } from '@frontend/types';
import { theme } from '@frontend/theme';
import {
  DropdownField,
  PrimaryButton,
  SecondaryButton,
  TextField,
  TextLink,
  useFormField,
} from '@frontend/design-system';
import { useInvoicePerson } from '../hooks';
import { readerConnectErrors, sortReaders } from '../reader-payment';
import { ReceiptSection } from '../receipt';
import { useConnectSelectedReader, useCollectPayment, useTerminalMethods } from './hooks';
import { ReaderStatus } from './reader-status';
import { useTerminalPaymentsContext } from './';

const styles = {
  section: css`
    display: grid;
    grid-template-rows: 1fr auto;
    justify-items: center;
  `,
  paymentForm: css`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: ${theme.spacing(50)};
    margin-bottom: ${theme.spacing(5)};
    margin-top: ${theme.spacing(1)};
  `,
  readerListDropdown: css`
    margin-bottom: ${theme.spacing(2)};
  `,
  buttonBar: css`
    display: flex;
    justify-content: end;
    margin-top: ${theme.spacing(2)};
    gap: ${theme.spacing(2)};
  `,
  receiptButtonBar: css`
    display: flex;
    justify-content: space-between;
    margin-top: ${theme.spacing(2)};
  `,
  cancelButton: css`
    width: auto;
  `,
};

/**
 * @deprecated Use `CollectionStepReaderPayment` instead, works with server side connection.
 */
export const CollectReaderPayment = () => {
  const { resetSteps, closeModal } = useMultiStepModal();
  const { selectedReader } = useTerminalPaymentsContext();
  const { connectionError } = useTerminalMethods();
  const { invoice } = useSelectedInvoice();
  const { merchantsData } = useMerchant({ allLocations: true });

  const { connectingSelectedReader, connectSelectedReader } = useConnectSelectedReader();
  const { paymentSuccessful, collectPaymentError, setCollectPaymentError, cancelCollectPayment } = useCollectPayment();
  const readerError = connectionError ?? collectPaymentError;

  const handleCancel = async () => {
    await cancelCollectPayment();
    resetSteps();
  };

  useEffect(() => {
    connectSelectedReader();
  }, []);

  const handleTryAgain = () => {
    if (collectPaymentError) setCollectPaymentError(null);
    else if (connectionError) connectSelectedReader();
  };

  const handleEndActivePayment = () => {
    connectSelectedReader(false);
  };

  const isTerminalInUse = !!connectionError?.message.includes(readerConnectErrors.alreadyInUse);

  type RequiredProps = {
    locationId: string | undefined;
    paymentsUrl: (typeof merchantsData)['']['paymentsUrl'];
    selectedReader: typeof selectedReader;
  };

  type AssertRequired<T extends RequiredProps> = { [K in keyof T]: NonNullable<T[K]> };

  const showSelectedReader = (props: RequiredProps): props is AssertRequired<RequiredProps> => {
    const { locationId, paymentsUrl, selectedReader } = props as RequiredProps;
    return !!locationId && !!paymentsUrl && !!selectedReader;
  };

  const showSelectedReaderProps: RequiredProps = {
    locationId: invoice?.locationId,
    paymentsUrl: merchantsData[invoice?.locationId ?? ''].paymentsUrl,
    selectedReader,
  };

  return (
    <CollectReaderPaymentDisplay
      selectedReader={
        showSelectedReader(showSelectedReaderProps)
          ? PaymentsTerminalController.createStoredReader(
              showSelectedReaderProps.locationId,
              showSelectedReaderProps.paymentsUrl,
              PaymentsTerminalController.formatReaderToTerminalReader(showSelectedReaderProps.selectedReader)
            )
          : undefined
      }
      readerError={readerError}
      paymentSuccessful={paymentSuccessful}
      isConnecting={connectingSelectedReader}
      isTerminalInUse={isTerminalInUse}
      handleEndActivePayment={handleEndActivePayment}
      onChangePaymentMethodClick={resetSteps}
      onTryAgainClick={handleTryAgain}
      onCancelClick={handleCancel}
      onCloseClick={closeModal}
    />
  );
};

type InferProps<T extends (args: any) => any> = Parameters<T>[number];
type ReaderStatusProps = InferProps<typeof ReaderStatus>;

type CollectReaderPaymentDisplayProps = {
  selectedReader: StoredReader | undefined;
  onChangePaymentMethodClick: () => void;
  onTryAgainClick: () => void;
  onCancelClick: () => void;
  onCloseClick: () => void;
  ReaderStatusOverrideComponent?: ReactNode;
  availableReaders?: TerminalReader[];
  onSelectedReaderChange?: (reader: TerminalReader) => void;
  disableTerminalSelection?: boolean;
} & PickRequired<ReaderStatusProps, 'readerError' | 'paymentSuccessful'>;

export const CollectReaderPaymentDisplay = ({
  selectedReader,
  readerError,
  paymentSuccessful,
  isConnecting,
  isTerminalInUse,
  handleEndActivePayment,
  onCancelClick,
  onChangePaymentMethodClick,
  onCloseClick,
  onTryAgainClick,
  ReaderStatusOverrideComponent,
  availableReaders,
  onSelectedReaderChange,
  disableTerminalSelection = false,
}: CollectReaderPaymentDisplayProps) => {
  const { t } = useTranslation('payments');

  const readerNameFieldProps = useFormField({
    type: 'text',
    value: selectedReader?.label,
  });

  const availableReaderProps = useFormField(
    {
      type: 'dropdown',
      value: selectedReader?.readerId,
    },
    [selectedReader?.readerId]
  );

  useEffect(() => {
    if (availableReaderProps.value) {
      const reader = availableReaders?.find((reader) => reader.id === availableReaderProps.value);

      if (reader && reader?.id !== selectedReader?.readerId) {
        onSelectedReaderChange?.(reader);
      }
    }
  }, [availableReaderProps.value]);

  const sortedAvailableReaders = useMemo(() => sortReaders(availableReaders ?? []), [availableReaders]);

  /// shared
  const { invoice } = useSelectedInvoice();
  const { paymentsUrl } = useMerchant();
  const person = useInvoicePerson(invoice);

  const emailReceiptFieldProps = useFormField({
    type: 'email',
    value: person?.Email,
  });

  const handleSendReceipt = async () => {
    onCloseClick();
    await sendInvoiceReceipt(paymentsUrl as string, invoice?.id as string, [emailReceiptFieldProps.value]);
  };

  return (
    <section
      css={css`
        display: grid;
        max-height: ${theme.spacing(60)};
      `}
    >
      <section css={styles.section}>
        <div css={styles.paymentForm}>
          {sortedAvailableReaders?.length ? (
            <DropdownField
              name={'available-terminals'}
              label={t('Terminal')}
              disabled={disableTerminalSelection}
              {...availableReaderProps}
              css={styles.readerListDropdown}
            >
              {sortedAvailableReaders.map(({ id, label, status }) => (
                <DropdownField.Option key={id} value={id} disabled={status === 'offline'}>
                  {label}
                </DropdownField.Option>
              ))}
            </DropdownField>
          ) : (
            selectedReader && (
              <TextField
                name='reader-name'
                label='Terminal'
                {...readerNameFieldProps}
                css={styles.readerListDropdown}
                readOnly
              />
            )
          )}
          {ReaderStatusOverrideComponent ? (
            ReaderStatusOverrideComponent
          ) : (
            <ReaderStatus
              readerError={readerError}
              paymentSuccessful={paymentSuccessful}
              isConnecting={isConnecting ?? false}
              isTerminalInUse={isTerminalInUse ?? false}
              handleEndActivePayment={handleEndActivePayment ?? (() => undefined)}
            />
          )}
        </div>
        {!isTerminalInUse && paymentSuccessful && <ReceiptSection emailFieldProps={emailReceiptFieldProps} />}
      </section>
      {!isTerminalInUse &&
        (!paymentSuccessful ? (
          <div css={styles.buttonBar}>
            {readerError ? (
              <>
                <SecondaryButton css={styles.cancelButton} size='large' onClick={onChangePaymentMethodClick}>
                  {t('Change Payment Method')}
                </SecondaryButton>
                <PrimaryButton css={styles.cancelButton} size='large' onClick={onTryAgainClick}>
                  {t('Try Again')}
                </PrimaryButton>
              </>
            ) : (
              <SecondaryButton css={styles.cancelButton} size='large' onClick={onCancelClick}>
                {t('Cancel')}
              </SecondaryButton>
            )}
          </div>
        ) : (
          <div css={styles.receiptButtonBar}>
            <TextLink onClick={onCloseClick}>{t('Close without receipt')}</TextLink>
            <PrimaryButton css={styles.cancelButton} size='large' onClick={handleSendReceipt}>
              {t('Send Receipt')}
            </PrimaryButton>
          </div>
        ))}
    </section>
  );
};
