import { ReactNode } from 'react';
import type { TabSetter } from './tabs.provider';
import { TabsProvider } from './tabs.provider';
import { TabList } from './tab-list';
import { TabListItem } from './tab-list-item';
import { TabPanel } from './tab-panel';

type Props = {
  children: ReactNode;
  initialTab?: string;
  controlledTab?: string;
  onChange?: TabSetter;
  className?: string;
};

export const Tabs = ({ children, initialTab = '', controlledTab, onChange }: Props) => (
  <TabsProvider initialTab={initialTab} onChange={onChange} controlledTab={controlledTab}>
    {children}
  </TabsProvider>
);

Tabs.Bar = TabList;
Tabs.Tab = TabListItem;
Tabs.Panel = TabPanel;
