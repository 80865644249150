import { createStoreWithSubscribe, createShallowStore } from '@frontend/store';
import { predefinedThemes, defaultNonShellTheme } from './constant';
import type { ShellColorTheme } from './types';
import { getRandomColorTheme } from './utils';
import { type Adapter } from '@frontend/shell-utils';

export type ShellColor = keyof typeof predefinedThemes;
type ShellThemes = Record<ShellColor, ShellColorTheme>;

declare global {
  interface Window {
    shell?: Adapter;
  }
}

interface ShellColorStore {
  colorObject: ShellColorTheme;
  setShellColor: (color: ShellColor | ShellColorTheme) => void;
  allColors: ShellThemes;
  randomizeTheme: (baseColor?: string, maxNumOfColors?: number) => void;
}

const hasShellThemeEnabled = window?.shell?.isShell && window?.shell?.featureAvailability?.has('shell-theme');

export const useShellThemeStore = createStoreWithSubscribe<ShellColorStore>(
  (set) => ({
    colorObject: hasShellThemeEnabled ? predefinedThemes.seaweedColor : defaultNonShellTheme,
    setShellColor: (color: ShellColor | ShellColorTheme) => {
      if (typeof color === 'string') {
        set((state) => {
          state.colorObject = predefinedThemes[color];
        });
      } else {
        set((state) => {
          state.colorObject = color;
        });
      }
    },
    allColors: predefinedThemes,
    randomizeTheme: (baseColor?: string, maxNumOfColors?: number) => {
      const randomTheme = getRandomColorTheme(baseColor, maxNumOfColors);
      set((state) => {
        state.colorObject = randomTheme;
      });
    },
  }),
  { name: 'Test' }
);

export const useShellTheme = createShallowStore(useShellThemeStore);
