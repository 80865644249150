import { useState } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

interface Props {
  actionText?: string;
  className?: string;
  text: string | React.ReactNode;
  hiddenText?: string;
}

const mainStyles = css`
  display: inline-block;
  width: 100%;
`;

const expandStyles = css`
  color: ${theme.font.colors.primary};
  cursor: pointer;
  margin-left: 3px;
`;

export const ExpandText = ({ className, text, hiddenText }: Props) => {
  const [showHiddenText, setShowHiddenText] = useState(false);

  return (
    <span css={mainStyles} className={className}>
      {text}
      {!showHiddenText && hiddenText && (
        <span
          css={expandStyles}
          onClick={() => setShowHiddenText(true)}
          data-trackingid='nav-portal-sharedpageheader-btn-more'
        >
          {' '}
          more...
        </span>
      )}
      {showHiddenText && (
        <>
          {hiddenText}
          <span
            css={expandStyles}
            onClick={() => setShowHiddenText(false)}
            data-trackingid='nav-portal-sharedpageheader-btn-less'
          >
            {' '}
            ...less
          </span>
        </>
      )}
    </span>
  );
};
