import React, { ReactNode, useRef } from 'react';
import { StepperCard } from './atoms/stepper-card.component';
import { StepperContent } from './atoms/stepper-content.component';
import {
  StepperButtonAlternate,
  StepperButtonBar,
  StepperButtonBarAlternate,
} from './atoms/stepper-button-bar.component';
import { StepperNextButton } from './atoms/stepper-next-button.component';
import { StepperPreviousButton } from './atoms/stepper-previous-button.component';
import { StepperTitle } from './atoms/stepper-title.component';
import * as styles from './stepper.styles';
import { StepStatus, StepperProvider } from './provider/stepper.provider';
import { StepperHorizontal } from './atoms/stepper-horizontal.component';
import { StepperClearButton } from './atoms/stepper-clear-button.component';
import { StepperSkipButton } from './atoms/stepper-skip-button.component';
import { StepperVerticalSingleView } from './atoms/stepper-vertical-single-view';
import { StepperVertical } from './atoms/stepper-vertical.component';
import { StepperSuccess } from './atoms/stepper-success';
import { filter } from 'react-children-utilities';

type StepperProps = {
  isHorizontal?: boolean;
  isVerticalSingleView?: boolean;
  children: ReactNode | ReactNode[];
  stepTitleText?: Record<number, string>;
  maxWidth?: number;
  isCompleted?: boolean;
  shouldScrollToCard?: boolean;
  stepStatus?: StepStatus;
  initialActiveStep?: number;
  hideStepTitle?: boolean;
};

export const StepperComponent = ({
  isHorizontal,
  isVerticalSingleView,
  stepTitleText,
  isCompleted,
  maxWidth,
  children,
  shouldScrollToCard = true,
  stepStatus,
  initialActiveStep = 0,
  hideStepTitle,
  ...rest
}: StepperProps) => {
  const reactChildren = filter(
    children,
    (child) =>
      React.isValidElement(child) &&
      typeof child.type !== 'string' &&
      (('displayName' in child.type && child.type.displayName === 'StepperCard') || child.props.css)
  ) as Array<React.ReactElement>;
  const SuccessPage = filter(
    children,
    (child) =>
      React.isValidElement(child) &&
      typeof child.type !== 'string' &&
      'displayName' in child.type &&
      child.type.displayName === 'StepperSuccess'
  ) as Array<React.ReactElement>;

  const ref = useRef<HTMLDivElement>(null);

  // prevent having more than one success page
  if (SuccessPage.length > 1) {
    throw new Error('You cannot have more than one Stepper Success Component');
  }

  const scrollToCard = (step: number) => {
    if (!isHorizontal && shouldScrollToCard) {
      const steps = ref.current;
      const stepNode = steps?.querySelectorAll('.step-card')[step];
      setTimeout(() => {
        stepNode?.scrollIntoView({
          behavior: 'smooth',
        });
      }, 500);
    }
  };

  const renderCards = () => {
    return reactChildren.map((child, index) => {
      const step = index + 1;
      return (
        <div className='step-container' key={index}>
          {!isHorizontal && !isVerticalSingleView && !hideStepTitle && <StepperVertical step={step} />}
          <StepperCard
            {...child.props}
            key={step}
            step={step}
            isVerticalSingleView={isVerticalSingleView}
            isHorizontal={isHorizontal}
          ></StepperCard>
        </div>
      );
    });
  };

  return (
    <StepperProvider scrollToCard={scrollToCard} stepStatus={stepStatus} initialActiveStep={initialActiveStep}>
      <div
        ref={ref}
        className='stepper'
        css={styles.stepper(isHorizontal, isVerticalSingleView, reactChildren.length, maxWidth)}
        {...rest}
      >
        {isHorizontal && !hideStepTitle && (
          <StepperHorizontal numSteps={reactChildren.length} stepTitleText={stepTitleText} />
        )}
        {isVerticalSingleView && !hideStepTitle && (
          <StepperVerticalSingleView numSteps={reactChildren.length} stepTitleText={stepTitleText} />
        )}
        {!isCompleted && renderCards()}
        {isCompleted && SuccessPage}
      </div>
    </StepperProvider>
  );
};

const StepperNamespace = Object.assign(StepperComponent, {
  Card: StepperCard,
  Title: StepperTitle,
  Content: StepperContent,
  ButtonBar: StepperButtonBar,
  ButtonAlternate: StepperButtonAlternate,
  ButtonBarAlternate: StepperButtonBarAlternate,
  ClearButton: StepperClearButton,
  SkipButton: StepperSkipButton,
  NextButton: StepperNextButton,
  PreviousButton: StepperPreviousButton,
  Success: StepperSuccess,
});

export { StepperNamespace as Stepper };
