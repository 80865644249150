import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { chartColors } from './constant';
import { TotalInvoicesCount } from './narrow-layout';
import { PaymentsPieChart } from './pie-chart';
import { PaymentsChartData } from './types';

interface WideLayoutProps {
  chartData: PaymentsChartData;
  totalInvoices: number;
}

export const WideLayout = ({ chartData, totalInvoices }: WideLayoutProps) => (
  <>
    <TotalInvoicesCount
      value={totalInvoices}
      css={css`
        width: 40%;
      `}
    />
    <div css={halfWidthStyle}>
      <PaymentsPieChart colors={chartColors} data={chartData.net} />
    </div>
  </>
);

const halfWidthStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  flexDirection: 'column',
  width: '60%',
  height: '100%',
  minHeight: 0,
});
