import { FC, useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  EditSimpleIcon,
  IconButton,
  TextField,
  useFormField,
  useOnClickOutside,
  ValidatorFieldState,
  XIcon,
} from '@frontend/design-system';

interface InlineTextEditorProps {
  actionText?: string;
  editEnabled?: boolean;
  inputFieldLabel: string;
  isSaving?: boolean;
  isUpdating?: boolean;
  onActionClick: (value: string) => void;
  validator?: (state: ValidatorFieldState<'text'>) => string;
  value?: string;
  maxLength?: number;
  handleResetValidation?: () => void;
  setDebounceValue?: (value: string | undefined) => void;
  toggleRerender?: boolean;
  isFullWidth?: boolean;
}

export const InlineTextEditor: FC<React.PropsWithChildren<InlineTextEditorProps>> = ({
  actionText,
  editEnabled,
  inputFieldLabel,
  isSaving,
  isUpdating,
  onActionClick,
  validator,
  value,
  maxLength,
  setDebounceValue,
  handleResetValidation,
  toggleRerender,
  isFullWidth,
}) => {
  const { t } = useTranslation('base');
  const [readOnly, setReadOnly] = useState<boolean>(true);
  const [userCancelled, setUserCancelled] = useState<boolean>(true);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputFieldRef = useRef<HTMLInputElement | null>(null);

  const inputField = useFormField(
    {
      required: true,
      type: 'text',
      validator,
      validateOnChange: true,
      value,
    },
    [userCancelled, value, toggleRerender]
  );

  useEffect(() => {
    if (!!inputField.id) {
      inputFieldRef.current = document.getElementById(inputField.id) as HTMLInputElement;
    }
  }, [inputField.id]);

  const handleEdit = () => {
    if (!editEnabled) return;

    setReadOnly(false);
    setUserCancelled(false);
    if (!inputFieldRef.current) return;
    inputFieldRef?.current.focus();
    inputFieldRef?.current.select();
  };

  const handleReset = () => {
    setReadOnly(true);
    if (!inputFieldRef.current) return;
    inputFieldRef?.current.blur();
    inputFieldRef?.current.setSelectionRange(0, 0);
  };

  const handleUserCancel = () => {
    handleResetValidation?.();
    setUserCancelled(true);
    setDebounceValue?.(undefined);
    handleReset();
  };

  const handleOutsideClick = (e: MouseEvent | undefined) => {
    const target = e?.target;
    if (!readOnly && !!target && !wrapperRef.current?.contains(target as Node)) {
      handleUserCancel();
    }
  };

  useOnClickOutside({
    ref: wrapperRef,
    handler: handleOutsideClick,
  });

  // Reset values and effects after a successful save event (when the isSaving variable has turned false)
  useEffect(() => {
    if (!isSaving) handleReset();
  }, [isSaving]);

  // used for debouncing function
  useEffect(() => {
    if (!readOnly && editEnabled) {
      setDebounceValue?.(inputField.value);
    }
  }, [inputField.value]);

  return (
    <div
      className={`${editEnabled ? 'editable' : ''} ${readOnly ? '' : 'editing'}`}
      css={[inlineEditorWrapperStyle, isFullWidth && { width: '100%' }]}
      ref={wrapperRef}
    >
      <TextField
        {...inputField}
        actionText={actionText}
        className={`input-area${isSaving ? ' updating-data' : ''}`}
        disabled={isSaving}
        label={inputFieldLabel}
        name={inputFieldLabel}
        onActionClick={readOnly ? undefined : () => onActionClick(inputField.value)}
        readOnly={isUpdating || readOnly}
        maxLength={maxLength}
        onKeyDownCapture={(e) => e.stopPropagation()}
        css={{ borderColor: 'transparent' }}
        containerCss={
          isFullWidth
            ? css`
                width: 100%;
              `
            : undefined
        }
      />
      {editEnabled &&
        (readOnly ? (
          <IconButton className='edit-icon' label={t('Edit')} onClick={handleEdit}>
            <EditSimpleIcon size={16} />
          </IconButton>
        ) : (
          <IconButton className='close-icon' label={t('Cancel')} onClick={handleUserCancel}>
            <XIcon size={16} />
          </IconButton>
        ))}
    </div>
  );
};

const inlineEditorWrapperStyle = css`
  display: flex;
  position: relative;

  .input-area {
    &.updating-data {
      button {
        pointer-events: none;
        color: ${theme.colors.neutral40};
      }
    }

    &:active,
    &:focus-within {
      border-color: ${theme.colors.primary50};
    }

    label {
      display: none;
    }
  }

  &.editing {
    .input-area {
      input[type='text'] {
        pointer-events: auto;
      }

      button {
        padding: ${theme.spacing(0.5)};
        &:hover {
          background-color: ${theme.colors.neutral5};
          color: ${theme.colors.primary50};
          border-radius: ${theme.spacing(0.5)};
        }
      }
    }

    .close-icon {
      display: flex;
      margin-top: ${theme.spacing(1.25)};
    }
  }

  &.editable:hover,
  &.editable:focus-within {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .edit-icon {
      display: flex;
      margin-top: ${theme.spacing(1.25)};
    }

    .input-area {
      border-color: ${theme.colors.neutral40};

      &:active,
      &:focus-within {
        border-color: ${theme.colors.primary50};
      }

      > input[type='text'] {
        width: 93%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .edit-icon {
    position: absolute;
    right: ${theme.spacing(1)};
  }

  .close-icon {
    margin-left: ${theme.spacing(1)};
  }

  .edit-icon,
  .close-icon {
    color: ${theme.colors.neutral50};
    display: none;
    height: ${theme.spacing(4)};
    min-width: unset;
    padding: ${theme.spacing(0.5)};
    width: ${theme.spacing(2.5)};
    height: ${theme.spacing(2.5)};

    :focus-visible {
      outline: 2px solid ${theme.colors.primary50};
      outline-offset: 2px;
    }

    :focus:not(:active)::before,
    :focus-within:not(:active)::before {
      opacity: 1;
    }
  }

  .input-area input[type='text'] {
    pointer-events: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
