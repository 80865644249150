import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const formContainerStyle = (isEdit?: boolean) => [
  css`
    border-top: solid 1px ${theme.colors.neutral20};
    position: relative;
    :focus {
      outline: none;
    }
  `,
  !isEdit &&
    css`
      padding-top: ${theme.spacing(1)};
    `,
  isEdit &&
    css`
      overflow: hidden;
    `,
];

export const topRowContainer = (isEdit?: boolean) => [
  css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  isEdit &&
    css`
      padding: ${theme.spacing(2)};
    `,
];

export const textAreaContainer = (isEdit?: boolean) => [
  css`
    flex: 1;
    margin: 0;
  `,
  !isEdit &&
    css`
      padding: ${theme.spacing(1, 2, 0, 2)};
    `,
];

export const skeletonLoaderContainer = (hasSubtext: boolean, childrenMaxWidth: number[] = [430]) => css`
  height: ${hasSubtext ? '132px' : '105px'};
  padding: ${theme.spacing(1)};
  & > div:first-child {
    max-width: ${childrenMaxWidth[0]}px;
  }
`;

export const skeletonLoaderStyles = (maxWidth = 700) => css`
  height: 16px;
  max-width: ${maxWidth}px;
  background: linear-gradient(90deg, #d9d9d9 0%, rgba(217, 217, 217, 0) 100%);
`;

export const actionButtonsContainer = (isEdit?: boolean) => [
  css`
    display: flex;
    margin-left: auto;
    justify-content: space-between;
  `,
  !isEdit &&
    css`
      padding-bottom: ${theme.spacing(1)};
    `,
];

export const cancelButtonStyles = css`
  margin-right: ${theme.spacing(1)};
`;

export const sendBtnStyling = css`
  padding-top: ${theme.spacing(1)};
`;

export const sendBtnIconStyling = css`
  transform: rotate(45deg);
`;

export const bottomRowContainer = (isEdit?: boolean) => [
  css`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  `,
  !isEdit &&
    css`
      height: 48px;
      padding: ${theme.spacing(0, 2, 1, 1)};
    `,
  isEdit &&
    css`
      background: ${theme.colors.warning5};
      padding: ${theme.spacing(1)};
    `,
];

export const invalidTextAreaStyle = css`
  outline: 1px solid ${theme.colors.critical50} !important;
  outline-offset: ${theme.spacing(1)};
`;

export const invalidMessageStyle = css`
  color: ${theme.colors.critical[50]};
  padding: ${theme.spacing(0, 0.5)};
`;

export const tagListStyle = css`
  border-top: 1px solid ${theme.colors.neutral20};
  margin-top: ${theme.spacing(1)};
  padding-top: ${theme.spacing(1)};
`;

export const messageTemplateStyle = css`
  margin-bottom: ${theme.spacing(2)};
  padding: 0;
  word-wrap: break-word;
  &:focus,
  &:focus-within {
    box-shadow: none;
  }
`;
