import React from 'react';
import { shouldShowError, getLabelId } from '../atoms/utils';
import { GroupLabel } from './group-label.component';
import { HelperText } from './helper-text';
import type {
  BasicFormFieldProps,
  InlineLabelPlacement,
  BoolFieldKey,
  BoolInputProps,
  FieldGroupOrientation,
} from './types';
import { BaseBoolLayout } from './base-bool-layout.component';
import { css } from '@emotion/react';
import { BoolListProvider, useBoolListContext } from '../providers/bool-list-provider';

type Props = BasicFormFieldProps<'checklist'> & {
  children: React.ReactNode;
  labelPlacement?: InlineLabelPlacement;
  labelDisabled?: boolean;
  orientation: FieldGroupOrientation;
};

export const BoolListLayout = ({
  className,
  children,
  disabled,
  error,
  hidden,
  id,
  label,
  labelPlacement,
  labelDisabled,
  name,
  onBlur,
  onFocus,
  onChange,
  value,
  orientation,
  ...rest
}: Props) => {
  if (hidden) {
    return null;
  }

  const childComponents =
    orientation === 'vertical' ? children : <div css={css({ display: 'flex', gap: 16 })}>{children}</div>;

  return (
    <BoolListProvider
      id={id}
      disabled={disabled}
      labelDisabled={labelDisabled}
      labelPlacement={labelPlacement}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      value={value}
    >
      <div role='group' aria-labelledby={getLabelId(id)} className={className}>
        <GroupLabel id={id}>{label}</GroupLabel>
        {childComponents}
        {shouldShowError({ error, ...rest }) && (
          <HelperText id={id} hasError disabled={disabled}>
            {error}
          </HelperText>
        )}
      </div>
    </BoolListProvider>
  );
};

export type BoolListOptionProps<T extends BoolFieldKey> = {
  children: React.ReactNode;
  className?: string;
  field: React.FC<React.PropsWithChildren<BoolInputProps<T>>>;
  disabled?: boolean;
  labelDisabled?: boolean;
  name: string;
  trackingId?: string;
};

export const BoolListLayoutOption = React.memo(
  <T extends BoolFieldKey>({
    className,
    children,
    disabled,
    labelDisabled,
    field,
    name,
    trackingId,
  }: BoolListOptionProps<T>) => {
    const getOptionProps = useBoolListContext();
    const {
      labelPlacement,
      disabled: optDisabled,
      labelDisabled: optLabelDisabled,
      ...optionProps
    } = getOptionProps(name);
    const Field = field;
    const optionDisabled = optDisabled ?? disabled;
    const optionLabelDisabled = optLabelDisabled ?? labelDisabled;
    return (
      <BaseBoolLayout
        className={className}
        id={optionProps.id}
        label={children}
        labelPlacement={labelPlacement}
        labelDisabled={optionLabelDisabled ?? optionDisabled}
        disabled={optionDisabled}
      >
        {/* @ts-ignore */}
        <Field {...(optionProps as BoolInputProps<T>)} disabled={optionDisabled} data-trackingid={trackingId} />
      </BaseBoolLayout>
    );
  }
);
