import { SerializedStyles } from '@emotion/react';
import { theme } from '@frontend/theme';
import { theme as themeOriginal } from '@frontend/theme-original';
import { FontColorType, getFontColorStyle } from '../../helpers';
import { TextAlignment, getFontFamilyStyle, getTextAlignStyle } from '../text/text-style-helpers';

export type HeadingStylesProps = {
  color: FontColorType;
  level: 1 | 2 | 3;
  textAlign: TextAlignment;
};
export type HeadingThemeValues = {
  fontColorStyle: SerializedStyles;
  fontFamily: SerializedStyles;
  fontSize: string;
  fontWeight: typeof theme.font.weight.bold | typeof themeOriginal.font.weight.bold;
  lineHeight: typeof theme.font.lineHeight | typeof themeOriginal.font.lineHeight;
  alignment: SerializedStyles;
};

export const getHeadingTheme = ({ color, level, textAlign }: HeadingStylesProps) => ({
  fontColorStyle: getFontColorStyle(color, theme),
  fontFamily: getFontFamilyStyle(theme),
  fontSize: theme.font.size[`h${level}`] ?? theme.font.size.h1,
  fontWeight: theme.font.weight.bold,
  lineHeight: theme.font.lineHeight,
  alignment: getTextAlignStyle(textAlign),
});

export const getHeadingThemeOriginal: typeof getHeadingTheme = ({ color, level, textAlign }: HeadingStylesProps) => ({
  fontColorStyle: getFontColorStyle(color, themeOriginal),
  fontFamily: getFontFamilyStyle(themeOriginal),
  fontSize: themeOriginal.font.size[`h${level}`] ?? themeOriginal.font.size.h1,
  fontWeight: themeOriginal.font.weight.bold,
  lineHeight: themeOriginal.font.lineHeight,
  alignment: getTextAlignStyle(textAlign),
});
