import { useState, useCallback, type ComponentProps, forwardRef } from 'react';
import { FieldLayout } from '../../layouts';
import type { BasicFormFieldProps, ExtraFieldProps } from '../../layouts';
import { Input, PasswordInput } from '../../atoms';
import { TextLink } from '../../../text-link';

type Props = BasicFormFieldProps<'password'> & Pick<ExtraFieldProps, 'containerCss'>;

export const PasswordField = forwardRef<HTMLDivElement, Props>(({ minChars, ...rest }, ref) => {
  const [show, setShow] = useState(false);
  const toggleShow = useCallback(() => {
    setShow((current) => !current);
  }, []);
  return (
    <FieldLayout
      {...rest}
      //TODO: this might be able to be improved. But this type cast just makes sure the field prop is any of the correct field types
      field={(show ? Input : PasswordInput) as ComponentProps<typeof FieldLayout>['field']}
      endAdornment={
        <TextLink size='large' onClick={toggleShow}>
          {show ? 'Hide' : 'Show'}
        </TextLink>
      }
      ref={ref}
    />
  );
});
