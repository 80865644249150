import { darken, lighten, getLuminance } from 'color2k';

export function generateColorProgression(baseColor: string, count = 6): string[] {
  const result: string[] = [];
  const step = 0.08;

  let lastLightenColor = baseColor;
  let lastDarkenColor = baseColor;

  result.push(baseColor);
  count--;

  for (let i = 1; i <= count; i++) {
    const luminanceLight = getLuminance(lastLightenColor);
    const luminanceDark = getLuminance(lastDarkenColor);
    const baseLuminance = getLuminance(baseColor);

    if (luminanceLight > 0.85 && baseLuminance !== 1) {
      lastDarkenColor = darken(lastDarkenColor, step);
      result.push(lastDarkenColor);
    } else if (luminanceDark < 0.15 && baseLuminance !== 0) {
      lastLightenColor = lighten(lastLightenColor, step);
      result.push(lastLightenColor);
    } else {
      if (i % 2 === 0) {
        lastDarkenColor = darken(lastDarkenColor, step);
        result.push(lastDarkenColor);
      } else {
        lastLightenColor = lighten(lastLightenColor, step);
        result.push(lastLightenColor);
      }
    }
  }

  result.sort((a, b) => getLuminance(a) - getLuminance(b));

  return result;
}
