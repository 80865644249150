import React from 'react';
import { ActionPressedChip } from '../atoms';
import { UpdateIcon } from '../../../icon';
import { css } from '@emotion/react';

type RedoActionProps = Omit<React.ComponentProps<typeof ActionPressedChip>, 'children'>;

export const RedoAction = ({ compact = true, showBoxShadow = true, ...rest }: RedoActionProps) => {
  return (
    <ActionPressedChip {...rest} compact={compact} showBoxShadow={showBoxShadow}>
      <UpdateIcon
        css={css`
          transform: scaleX(-1);
        `}
        size={16}
      />
    </ActionPressedChip>
  );
};
