import { Params } from '@tanstack/react-location';

export const constructRoutePath = (crumb: string, params: Params<string>) => {
  if (!crumb.includes(':')) {
    return crumb;
  }

  let newPath: string = crumb;

  for (const [key, value] of Object.entries<string>(params)) {
    newPath = newPath.replace(`:${key}`, value);
  }

  return newPath;
};

export const setHashWithParams = (fullPath: string, params = {}) => {
  const [basePath, existingQueryString] = fullPath.split('?');
  const formattedBasePath = `#settings/${basePath.replace('settings/', '')}`;

  if (Object.keys(params).length === 0 && !existingQueryString) {
    window.location.hash = formattedBasePath;
    return;
  }

  const queryString = new URLSearchParams(params).toString();

  window.location.hash = queryString ? `${formattedBasePath}?${queryString}` : formattedBasePath;
};

export const getHashParams = () => {
  const hash = window.location.hash.substring(1);
  const paramsIndex = hash.indexOf('?');
  if (paramsIndex === -1) return undefined;

  const queryString = hash.substring(paramsIndex + 1);
  return Object.fromEntries(new URLSearchParams(queryString).entries());
};
