import { $generateNodesFromDOM } from '@lexical/html';
import { $getRoot, $insertNodes, type LexicalEditor } from 'lexical';

export const parseHTML = (editor: LexicalEditor, initialHTML: string) => {
  editor.update(() => {
    const parser = new DOMParser();
    const dom = parser.parseFromString(initialHTML, 'text/html');

    const nodes = $generateNodesFromDOM(editor, dom);

    $getRoot().select();

    $insertNodes(nodes);
  });
};
