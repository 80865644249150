/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const MoreIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M3,15 C1.61928813,15 0.5,13.8807119 0.5,12.5 C0.5,11.1192881 1.61928813,10 3,10 C4.38071187,10 5.5,11.1192881 5.5,12.5 C5.5,13.8807119 4.38071187,15 3,15 Z M12,15 C10.6192881,15 9.5,13.8807119 9.5,12.5 C9.5,11.1192881 10.6192881,10 12,10 C13.3807119,10 14.5,11.1192881 14.5,12.5 C14.5,13.8807119 13.3807119,15 12,15 Z M21,15 C19.6192881,15 18.5,13.8807119 18.5,12.5 C18.5,11.1192881 19.6192881,10 21,10 C22.3807119,10 23.5,11.1192881 23.5,12.5 C23.5,13.8807119 22.3807119,15 21,15 Z'
    />
  </Icon>
);
