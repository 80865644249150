import { css } from '@emotion/react';
import type {
  CalendarControlThemeValues,
  ControlsHeaderThemeValues,
  MonthNameThemeValues,
  TitleHeaderThemeValues,
} from './theme';
import {
  calendarControlTheme,
  calendarControlThemeOriginal,
  controlsHeaderTheme,
  controlsHeaderThemeOriginal,
  monthNameTheme,
  monthNameThemeOriginal,
  titleHeaderTheme,
  titleHeaderThemeOriginal,
} from './theme';

export const calendarControls = ({ spacing, svgColor }: CalendarControlThemeValues) => css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${spacing(0, 1)};
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);

  button:focus,
  button:hover {
    outline: none;

    svg {
      color: ${svgColor};
      transition: color ease-in 300ms;
    }
  }
`;

export const controlsHeader = ({ borderColor, spacing }: ControlsHeaderThemeValues) => css`
  height: 56px;
  border-bottom: 1px solid ${borderColor};
  display: flex;
  margin: ${spacing(0, -2, 2)};
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const titleHeader = ({
  backgroundColor,
  svgColor,
  focusRingColor,
  borderRadius,
  spacing,
}: TitleHeaderThemeValues) => css`
  display: flex;
  align-items: center;
  padding: ${spacing(0, 2, 0, 2.5)};
  border-radius: ${borderRadius};
  outline: none;
  position: relative;
  height: 40px;

  ::after {
    content: '';
    width: calc(100% + ${spacing(1.5)});
    height: calc(100% + ${spacing(1.5)});
    display: block;
    border: 2px solid ${focusRingColor};
    border-radius: ${borderRadius};
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 150ms ease-out;
  }

  span {
    height: 18px;
    display: flex;
    margin-left: 10px;
    flex-direction: column;
  }

  &:hover {
    background-color: ${backgroundColor};
    transition: background-color 0.2s ease;

    svg {
      color: ${svgColor};
      transition: color ease-in 300ms;
    }
  }

  &:focus-visible {
    background: ${backgroundColor};
    transition: fill 250ms ease-in-out;
    svg {
      fill: ${svgColor};
    }
    ::after {
      opacity: 1;
    }
  }
  &:not(:focus-visible) {
    ::after {
      opacity: 0;
    }
  }
`;

export const monthName = ({ color, fontSize, fontWeight }: MonthNameThemeValues) => css`
  color: ${color};
  font-size: ${fontSize(16)};
  font-weight: ${fontWeight};
  margin: 0;
`;

export const CalendarControlsStyles = {
  CalendarControls: {
    calendarControls: calendarControls(calendarControlTheme),
    controlsHeader: controlsHeader(controlsHeaderTheme),
    monthName: monthName(monthNameTheme),
    titleHeader: titleHeader(titleHeaderTheme),
  },
};

export const CalendarControlsStylesOriginal: typeof CalendarControlsStyles = {
  CalendarControls: {
    calendarControls: calendarControls(calendarControlThemeOriginal),
    controlsHeader: controlsHeader(controlsHeaderThemeOriginal),
    monthName: monthName(monthNameThemeOriginal),
    titleHeader: titleHeader(titleHeaderThemeOriginal),
  },
};
