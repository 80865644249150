import { css } from '@emotion/react';
import { PolymorphicComponentPropWithoutRef } from '../../../type-utils';

type ComponentProps = {
  children: React.ReactNode;
  className?: string;
};

type TrayBodyProps<T extends React.ElementType> = PolymorphicComponentPropWithoutRef<T, ComponentProps>;

export const TrayBody = <T extends React.ElementType = 'section'>({ as, children, ...rest }: TrayBodyProps<T>) => {
  const Component = as || 'section';
  return (
    <Component
      className='tray-body'
      css={css`
        flex: 1;
        overflow-y: auto;
      `}
      {...rest}
    >
      {children}
    </Component>
  );
};
