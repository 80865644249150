import { css } from '@emotion/react';
import { useThemeValues } from '../../../hooks/use-theme-values';
import { Text } from '../../text';
import { Label } from '../atoms/label.component';
import type { InlineLabelPlacement } from './types';

type Props = {
  children: React.ReactNode;
  id: string;
  disabled?: boolean;
  placement?: InlineLabelPlacement;
};

export const InlineLabel = ({ children, id, disabled = false, placement = 'right' }: Props) => {
  const theme = useThemeValues();

  return (
    <Text
      id={id}
      css={[
        css`
          line-height: 1.35;
          opacity: ${disabled ? 0.25 : 1};
        `,
        placement === 'left'
          ? css`
              margin-right: ${theme.spacing(2)};
            `
          : css`
              margin-left: ${theme.spacing(1)};
            `,
      ]}
      as={Label}
    >
      {children}
    </Text>
  );
};
