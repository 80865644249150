import { TimePickerInterval, TimeProps } from '../shared/types';
import type { ChangeEvent, FocusEvent as ReactFocusEvent, FormEvent, KeyboardEvent } from 'react';

export type ObjectFromFormConfig<T extends FormConfig, U> = {
  [K in keyof T]: U;
};

// event normalization types
export type CustomChangeEvent<T = any> = { name: string; value: T };
export type FieldChangeEvent<T = any> = FormEvent | ChangeEvent | CustomChangeEvent<T>;

// validation types
export type ValidationResult = Pick<BaseFieldState, 'aria-invalid' | 'error'>;
export type ValidatorFn<T extends FieldKey> = (
  props: ValidatorFieldState<T>,
  // for validators with useForm, it will provide all the field state in case needed
  // eg confirm password validation (needs to check password value against confirm)
  allFields?: any
) => string;

type BuiltinValidators =
  | 'number'
  | 'checklist'
  | 'date'
  | 'datePicker'
  | 'email'
  | 'list'
  | 'money'
  | 'multiselect'
  | 'password'
  | 'phone'
  | 'postalCode'
  | 'selectlist'
  | 'time'
  | 'getListMaxError';
export type Validator<T extends FieldKey> = BuiltinValidators | ValidatorFn<T>;
export type ValidatorFieldState<T extends FieldKey> = Omit<FormFieldState<T>, 'validator' | 'type'>;

export type BaseFieldState = {
  active: boolean;
  'aria-invalid': boolean;
  error: string;
  touched: boolean;
};

type SharedFieldProps = {
  hidden?: boolean;
  placeholder?: string;
  required?: boolean;
};

type FieldValueTypes = boolean | string | string[];
export type BaseFieldConfig<T extends FieldKey, V extends FieldValueTypes = string> = SharedFieldProps & {
  touched?: boolean;
  type: T;
  validator?: ValidatorFn<T>;
  validateOnChange?: boolean;
  value?: V;
};

export type FieldHandlers<TElement extends HTMLElement> = {
  onBlur: (e?: ReactFocusEvent<TElement>) => void;
  onChange: (e?: FieldChangeEvent) => void;
  onFocus: (e?: ReactFocusEvent<TElement>) => void;
};

export type BaseFieldProps<
  TValue extends FieldValueTypes = string,
  TFieldKey extends FieldKey | string = '',
  TElement extends HTMLElement = HTMLInputElement
> = ResolvedFieldState<TFieldKey> &
  SharedFieldProps &
  FieldHandlers<TElement> & {
    disabled?: boolean;
    id: string;
    value: TValue;
  };

// Config types for useFormField and useField
export enum PostalCodeLocale {
  US = 'US',
  CA = 'CA',
}

export type PhoneProps = { format?: 'standard' | 'hyphenated' };

export type PostalCodeProps = { locale?: PostalCodeLocale };

export type DateProps = {
  blackoutDates?: string[];
  maxDate?: string;
  minDate?: string;
  today?: string;
};

export type NumericProps = {
  allowDecimal?: boolean;
  allowNegative?: boolean;
  includeThousandsSeparator?: boolean;
  max?: number;
  min?: number;
  prefix?: string;
  suffix?: string;
  onKeyDown?: (e: KeyboardEvent) => void;
};

export type CurrencyProps = { max?: number; min?: number; onKeyDown?: (e: KeyboardEvent) => void };

export type MoneyProps = CurrencyProps;

export type ListProps = {
  maxAllowed?: number;
  minRequired?: number;
  disabled?: boolean;
};

type MultiSelectProps = { autoClose?: boolean; direction?: 'up' | 'down' };

export type TimeRangeProps = TimeProps & {
  minRangeDuration?: number;
  min?: string;
  max?: string;
  interval: TimePickerInterval;
};

export type PasswordProps = {
  // default is 8
  minChars?: number;
};

export type ValueTransformer<T extends FieldKey> = (value: FieldTypeValue<T>) => FieldTypeValue<T>;

export type ValueTransformable<T extends FieldKey> = {
  transformValue?: ValueTransformer<T>;
};
export type DirectionProps = { direction?: 'up' | 'down' };

// FIELD CONFIG - as look up
export type FieldConfigs = {
  checkbox: BaseFieldConfig<'checkbox', boolean>;
  checklist: BaseFieldConfig<'checklist', string[]> & ListProps;
  date: BaseFieldConfig<'date'> & DateProps;
  datePicker: BaseFieldConfig<'datePicker'> & DateProps;
  dateRange: BaseFieldConfig<'dateRange', string[]> & DateProps;
  dropdown: BaseFieldConfig<'dropdown'>;
  email: BaseFieldConfig<'email'>;
  list: BaseFieldConfig<'list', string[]> & ListProps;
  money: BaseFieldConfig<'money'> & MoneyProps;
  multiselect: BaseFieldConfig<'multiselect', string[]> & ListProps & MultiSelectProps;
  number: BaseFieldConfig<'number'> & NumericProps;
  optionswitch: BaseFieldConfig<'optionswitch'>;
  password: BaseFieldConfig<'password'> & PasswordProps;
  phone: BaseFieldConfig<'phone'> & PhoneProps;
  postalCode: BaseFieldConfig<'postalCode'> & PostalCodeProps;
  radio: BaseFieldConfig<'radio'>;
  selectlist: BaseFieldConfig<'selectlist', string[]> & ListProps;
  switch: BaseFieldConfig<'switch', boolean>;
  text: BaseFieldConfig<'text'> & ValueTransformable<'text'>;
  time: BaseFieldConfig<'time'> & TimeProps;
  timeRange: BaseFieldConfig<'timeRange', string[]> & TimeRangeProps;
};

export type FieldKey = keyof FieldConfigs;

export type ResolvedFieldConfig<T extends FieldKey> = FieldConfigs[T];

export type FieldConfig<T extends FieldKey> = {
  type: T;
} & ResolvedFieldConfig<T>;

// FIELD CONFIG - as union
// This is needed to be able to infer the instance type for the field used in the FormConfig
export type FieldConfigUnion =
  | FieldConfigs['checkbox']
  | FieldConfigs['checklist']
  | FieldConfigs['date']
  | FieldConfigs['datePicker']
  | FieldConfigs['dateRange']
  | FieldConfigs['dropdown']
  | FieldConfigs['email']
  | FieldConfigs['list']
  | FieldConfigs['money']
  | FieldConfigs['multiselect']
  | FieldConfigs['number']
  | FieldConfigs['optionswitch']
  | FieldConfigs['password']
  | FieldConfigs['phone']
  | FieldConfigs['postalCode']
  | FieldConfigs['radio']
  | FieldConfigs['selectlist']
  | FieldConfigs['switch']
  | FieldConfigs['text']
  | FieldConfigs['time']
  | FieldConfigs['timeRange'];

// CUSTOM FIELD STATE (from value setters)
type CustomFieldState = {
  time: BaseFieldState & { displayValue: string };
  timeRange: BaseFieldState & { displayValue: string[] };
};

type ResolvedFieldState<K extends string> = K extends keyof CustomFieldState ? CustomFieldState[K] : BaseFieldState;

// FIELD PROPS
export type ListFieldProps = BaseFieldProps<string[]> & ListProps;

type FieldProps = {
  checkbox: BaseFieldProps<boolean>;
  checklist: ListFieldProps;
  date: BaseFieldProps & DateProps;
  datePicker: BaseFieldProps<string> & DateProps;
  dateRange: BaseFieldProps<string[]> & DateProps;
  money: BaseFieldProps & MoneyProps;
  list: ListFieldProps;
  multiselect: ListFieldProps & MultiSelectProps;
  number: BaseFieldProps & NumericProps;
  currency: BaseFieldProps & CurrencyProps;
  password: BaseFieldProps & PasswordProps;
  phone: BaseFieldProps & PhoneProps;
  postalCode: BaseFieldProps & PostalCodeProps;
  selectlist: ListFieldProps;
  switch: BaseFieldProps<boolean>;
  time: BaseFieldProps<string, 'time'> & TimeProps;
  timeRange: BaseFieldProps<string[], 'timeRange', HTMLTextAreaElement> & TimeRangeProps;
};

export type ResolvedFieldProps<K extends FieldKey> = K extends keyof FieldProps
  ? FieldProps[K]
  : BaseFieldProps<string>;

export type FieldTypeValue<T extends FieldKey> = FieldConfig<T>['value'];

// State types for useFormField
export enum FormFieldActionTypes {
  Blur = 'BLUR',
  Focus = 'FOCUS',
  Reset = 'RESET',
  Seed = 'SEED',
  Update = 'UPDATE',
  Validate = 'VALIDATE',
}

export type FormFieldActions =
  | {
      type: FormFieldActionTypes.Focus | FormFieldActionTypes.Validate;
    }
  | { type: FormFieldActionTypes.Blur; payload?: FieldValue }
  | { type: FormFieldActionTypes.Reset; payload: FieldConfigUnion }
  | { type: FormFieldActionTypes.Update; payload: CustomChangeEvent };

export type FormFieldState<K extends FieldKey> = Omit<FieldConfig<K>, 'value'> &
  Required<Pick<FieldConfig<K>, 'value'>> &
  ResolvedFieldState<K>;

export type FormConfig = {
  [key: string]: FieldConfigUnion;
};

export type FormFocusAction = {
  type: FormFieldActionTypes.Blur | FormFieldActionTypes.Focus;
  payload: { name: string };
};

export type FormSeedAction = {
  type: FormFieldActionTypes.Seed;
  payload: { [key: string]: FieldValue };
};

export type FormUpdateAction = {
  type: FormFieldActionTypes.Update;
  payload: CustomChangeEvent;
};

export type FormValidateAction = { type: FormFieldActionTypes.Validate };

export type FormActions<T extends FormConfig> =
  | FormFocusAction
  | { type: FormFieldActionTypes.Reset; payload: T }
  | FormSeedAction
  | FormUpdateAction
  | FormValidateAction;

export type FormState<T extends FormConfig> = ObjectFromFormConfig<T, FormFieldState<T[keyof T]['type']>>;

export type FieldStateReducer<T extends FormConfig> = (
  state: FormState<T>,
  action: FormActions<T>
) => FormState<T> | null;

export type FieldValue = FieldConfigUnion['value'];

export type FormValues<TConfig extends FormConfig> = {
  [TFieldName in keyof TConfig]?: ResolvedFieldConfig<TConfig[TFieldName]['type']>['value'];
};

export type FormErrors<T extends FormConfig> = ObjectFromFormConfig<T, string>;

export type FocusEvent = ReactFocusEvent<HTMLInputElement>;

export type UseFormProps<T extends FormConfig> = {
  allowInvalidSubmission?: boolean;
  computeChangedValues?: boolean;
  fields: T;
  fieldStateReducer?: FieldStateReducer<T>;
  onSubmit?: (
    values: FormValues<T>,
    // if computeChangedValues = true
    changedValues: FormValues<T> | null
  ) => void;
};
