import { filter } from 'react-children-utilities';
import React from 'react';

/**
 * Creates a function to filter specific children by their `displayName`.
 *
 * @remarks
 * - It is crucial that the `displayName` property is set on the child components for accurate filtering.
 * - Cannot filter by the component's name as it may get minified in a production build.
 *
 *
 * @param children - The children React nodes to be filtered.
 * @returns A function that filters children based on `displayName`.
 */
export function createGetSpecificChildren<T extends string, Props = any>(
  children: React.ReactNode,
  hasMultiple = false
) {
  return function getSpecificChildren(targetDisplayName: T): Array<React.ReactElement<Props>> {
    const reactChildren = filter(
      children,
      (child) =>
        React.isValidElement(child) &&
        typeof child.type !== 'string' &&
        'displayName' in child.type &&
        child.type.displayName === targetDisplayName
    ) as Array<React.ReactElement>;

    if (reactChildren.length > 1 && !hasMultiple) {
      throw new Error(`Only one child with displayName ${targetDisplayName} is expected. Multiple instances found.`);
    }

    return reactChildren;
  };
}

/**
 * Creates a function to split children into an array of children selected by their `displayName`, and all other children.
 *
 * @remarks
 * - It is crucial that the `displayName` property is set on the specific child components for accurate filtering.
 * - Cannot filter by the component's name as it may get minified in a production build.
 *
 *
 * @param children - The children React nodes to be split.
 * @returns A function that splits children based on `displayName`, returning a tuple. The first entry will be the
 * specific children, the second entry will be the other children.
 */
export function createSplitSpecificChildren<T extends string>(children: React.ReactNode, hasMultiple = false) {
  return function splitSpecificChildren(targetDisplayName: T): [Array<React.ReactElement>, Array<React.ReactNode>] {
    const specificChildren = filter(
      children,
      (child) =>
        React.isValidElement(child) &&
        typeof child.type !== 'string' &&
        'displayName' in child.type &&
        child.type.displayName === targetDisplayName
    ) as Array<React.ReactElement>;

    const otherChildren = filter(
      children,
      (child) =>
        React.isValidElement(child) &&
        typeof child.type !== 'string' &&
        ('displayName' in child.type ? child.type.displayName !== targetDisplayName : true)
    );

    if (specificChildren.length > 1 && !hasMultiple) {
      throw new Error(`Only one child with displayName ${targetDisplayName} is expected. Multiple instances found.`);
    }

    return [specificChildren, otherChildren];
  };
}
