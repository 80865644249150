import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Status } from '.';

export const stepperLineWidth = '2px';
const topPosition = '24px';
const marginSpacing = theme.spacing(3);
const buttonBarBreakpoint = '550px';
const titleWidth = '550px';
export const stepperDuration = '0.5s';
export const stepperDelay = '0.5s';
export const activeStepperDelay = '0.75s, 0.5s';
export const inactiveStepperDuration = '1s';

export const stepper = (horizontal = false, isVerticalSingleView = false, numSteps = 6, maxWidth?: number) => [
  css`
    max-width: ${maxWidth || 700}px;
    margin-left: ${theme.spacing(1)};
    margin-right: ${theme.spacing(1)};
    .step-container:last-child .step-line {
      display: none;
      height: 0%;
    }
    .step-container {
      position: relative;
      ${isVerticalSingleView && 'overflow: auto; width: 100%; &:empty{width: 0}'}
    }

    .step-card {
      ${isVerticalSingleView && 'border: none; border-radius: 0; margin: 0;'}
    }
  `,
  horizontal &&
    css`
      position: unset;
      margin: ${theme.spacing(1)};
      .step-content {
        margin-left: 0;
        margin-top: ${theme.spacing(10)};
        margin-right: ${theme.spacing(3)};
      }
      .step-card {
        max-width: unset;
        width: 100%;
        margin-top: ${theme.spacing(3)};
        margin-left: 0;
        margin-right: ${theme.spacing(2)};
      }
      .stepper-horizontal-step:last-child .step-line-horizontal {
        display: none;
      }
    `,
  isVerticalSingleView &&
    css`
      display: flex;

      .step-card {
        max-width: unset;
        width: 100%;

        .step-content-title,
        .step-content-svg {
          font-size: ${theme.fontSize(24)};
        }
      }

      .stepper-vertical {
        margin-left: ${theme.spacing(1)};
        min-width: 200px;
        height: ${parseInt(theme.spacing(10)) * numSteps}px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .step-num-vertical {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .stepper-vertical-step {
        height: ${100 / numSteps}%;
        position: relative;
        width: 100%;
      }

      .stepper-vertical-step:last-child .step-line-vertical {
        display: none;
      }
    `,
];

export const stepCard = (stepStatus: Status, allowClicking: boolean) => [
  css`
    overflow-y: hidden;
    padding: ${theme.spacing(3)};
    border: 1px solid ${theme.colors.neutral20};
    border-radius: ${theme.borderRadius.small};
    padding-bottom: ${theme.spacing(3)};
    min-height: 82px;
    margin-bottom: ${marginSpacing};
    &:not(:first-child) {
      margin-left: ${theme.spacing(5)};
    }
    .step-content-title,
    .step-content-svg {
      font-size: ${theme.fontSize(20)};
      color: ${theme.colors.neutral20};
      font-weight: ${theme.font.weight.regular};
    }
    .step-button {
      width: fit-content;
      @media only screen and (max-width: ${buttonBarBreakpoint}) {
        .skip-step {
          margin: 0;
        }
        .clear-step {
          margin: ${theme.spacing(0, 2, 0, 0)};
        }
      }
    }
    .step-button-bar {
      position: relative;
      margin-top: ${theme.spacing(3)};
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
    }
  `,
  stepStatus !== 'active' &&
    stepStatus !== 'currActive' &&
    stepStatus !== 'errorActive' &&
    css`
      .step-content {
        display: none;
      }
      .step-button-bar,
      .step-content {
        display: none;
      }
      .step-content-title {
        width: ${titleWidth};
        height: 32px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    `,
  (stepStatus === 'active' || stepStatus === 'currActive' || stepStatus === 'errorActive') &&
    css`
      height: unset;
      .step-content-title,
      .step-content-svg {
        font-weight: ${theme.font.weight.bold};
        color: ${theme.colors.neutral90};
      }
      .step-title-container {
        margin-bottom: ${theme.spacing(3)};
      }
      .step-content {
        display: initial;
      }
      .step-button-bar {
        display: flex;
      }
    `,
  stepStatus === 'errorActive' &&
    css`
      border: 1px solid ${theme.colors.status.critical};
      outline: 1px solid ${theme.colors.status.critical};
    `,
  stepStatus === 'completed' &&
    css`
      .step-content-title,
      .step-content-svg {
        color: ${theme.colors.neutral90};
      }
    `,
  stepStatus === 'completed' &&
    allowClicking &&
    css`
      background-color: ${theme.colors.neutral10};
      cursor: pointer;
      .step-content-title,
      .step-content-svg {
        font-weight: ${theme.font.weight.bold};
      }
    `,
  stepStatus === 'error' &&
    css`
      border: 1px solid ${theme.colors.status.critical};
      outline: 1px solid ${theme.colors.status.critical};
      .step-content-title,
      .step-content-svg {
        color: ${theme.colors.status.critical};
      }
    `,
];

export const stepContent = (stepStatus: Status) => [
  css`
    height: 82px;
    margin-left: ${theme.spacing(4)};
    .step-content-title,
    .step-content-svg {
      color: ${theme.colors.neutral20};
      font-weight: ${theme.font.weight.regular};
    }
  `,
  (stepStatus === 'active' || stepStatus === 'currActive' || stepStatus === 'errorActive') &&
    css`
      height: unset;
      .step-content-title,
      .step-content-svg {
        font-weight: ${theme.font.weight.bold};
        color: ${theme.colors.neutral90};
      }
    `,
  stepStatus === 'completed' &&
    css`
      background-color: ${theme.colors.neutral10};
      .step-content-title,
      .step-content-svg {
        color: ${theme.colors.neutral90};
      }
    `,
];

export const stepLine = (stepStatus: Status) => [
  css`
    width: ${stepperLineWidth};
    position: absolute;
    top: ${topPosition};
    height: calc(100% + ${marginSpacing});
  `,
  stepStatus === 'completed' &&
    css`
      background-color: ${theme.colors.primary50};
    `,
  (stepStatus === 'active' || stepStatus === 'currActive') &&
    css`
      background-color: ${theme.colors.primary50};
    `,
  stepStatus === 'errorActive' &&
    css`
      background-color: ${theme.colors.status.critical};
    `,
  stepStatus === 'inactive' &&
    css`
      background-color: ${theme.colors.neutral40};
    `,
  stepStatus === 'error' &&
    css`
      background-color: ${theme.colors.status.critical};
    `,
];

export const stepNum = (stepStatus: Status) => [
  css`
    position: absolute;
    top: ${topPosition};
    background-color: ${theme.colors.white};
    transform: translateX(-50%);
    border: 2px solid ${theme.colors.neutral40};
    border-radius: 50%;
    width: 24px;
    height: 24px;
    p,
    h2 {
      line-height: 20px;
      font-size: ${theme.fontSize(16)};
      text-align: center;
      color: ${theme.colors.neutral40};
      margin: 0;
    }
  `,
  (stepStatus === 'inactive' || stepStatus === 'error') &&
    css`
      transition-delay: 0s;
      transition-duration: ${inactiveStepperDuration};
      transition-property: background-color, border, width, height, padding, line-height, transform, margin-left;
      p,
      h2 {
        transition-delay: 0s;
        transition-duration: ${inactiveStepperDuration};
        transition-property: color, line-height, font-size;
      }
    `,
  stepStatus === 'completed' &&
    css`
      color: ${theme.colors.primary50};
      background-color: ${theme.colors.white};
      border: 2px solid ${theme.colors.primary50};
      width: 24px;
      height: 24px;
      padding: ${stepperLineWidth};
      p,
      h2 {
        color: ${theme.colors.neutral40};
      }
    `,
  (stepStatus === 'active' || stepStatus === 'currActive' || stepStatus === 'errorActive') &&
    css`
      top: ${topPosition};
      transform: translateX(-50%);
      background-color: ${theme.colors.primary50};
      border: 2px solid ${theme.colors.primary50};
      width: 32px;
      height: 32px;
      padding: 2px;
      transition-delay: ${stepperDelay};
      transition-duration: ${stepperDuration};
      transition-property: background-color, border, width, height, padding, line-height, transform, margin-left;
      p,
      h2 {
        line-height: ${theme.spacing(3)};
        font-size: ${theme.fontSize(18)};
        color: ${theme.colors.white};
        font-weight: ${theme.font.weight.bold};
        transition-delay: ${activeStepperDelay};
        transition-duration: ${stepperDuration};
        transition-property: color, line-height;
      }
    `,
  (stepStatus === 'error' || stepStatus === 'errorActive') &&
    css`
      border: 2px solid ${theme.colors.status.critical};
      background-color: ${theme.colors.status.critical};
      p,
      h2 {
        color: ${theme.colors.white};
      }
    `,
];

export const stepLineVertical = (stepStatus: Status) => [
  css`
    width: ${stepperLineWidth};
    position: absolute;
    top: 41px;
    height: calc(100% - 22px);
  `,
  (stepStatus === 'completed' || stepStatus === 'lastActive') &&
    css`
      background-color: ${theme.colors.primary50};
    `,
  (stepStatus === 'active' || stepStatus === 'currActive') &&
    css`
      background-color: ${theme.colors.primary50};
    `,
  stepStatus === 'errorActive' &&
    css`
      background-color: ${theme.colors.status.critical};
    `,
  stepStatus === 'inactive' &&
    css`
      background-color: ${theme.colors.neutral40};
      height: 100%;
    `,
  stepStatus === 'error' &&
    css`
      background-color: ${theme.colors.status.critical};
      height: 100%;
    `,
];

export const stepLineTextStyles = (stepStatus: Status) => [
  css`
    padding-left: ${theme.spacing(3.5)};
    color: ${theme.colors.neutral20};
    position: absolute;
    ${stepStatus === 'finished' && 'pointer-events: none;'}
  `,
  (stepStatus === 'completed' || stepStatus === 'lastActive') &&
    css`
      color: ${theme.colors.neutral50};
      &:hover {
        cursor: pointer;
        color: ${theme.colors.primary50};
      }
    `,
  (stepStatus === 'active' || stepStatus === 'currActive') &&
    css`
      font-weight: ${theme.font.weight.bold};
      color: ${theme.colors.neutral90};
    `,
  stepStatus === 'errorActive' &&
    css`
      font-weight: ${theme.font.weight.bold};
      color: ${theme.colors.status.critical};
    `,
  stepStatus === 'error' &&
    css`
      color: ${theme.colors.status.critical};
    `,
];
