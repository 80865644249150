import { useMemo } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { getInvoiceHistory } from '@frontend/api-invoices';
import {
  DashboardWidget,
  DashboardWidgetFC,
  useDashboardWidget,
  TimePeriodListBoxMenu,
} from '@frontend/grid-dashboard';
import { Trans, useTranslation } from '@frontend/i18n';
import { useMerchant, useMultiQueryUtils } from '@frontend/payments-hooks';
import { CreateAndCollectModal } from '@frontend/payments-invoice-create';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  ContentLoader,
  emptyStateGraphics,
  NakedButton,
  Text,
  TextLink,
  useModalControl,
} from '@frontend/design-system';
import { NarrowLayout } from './narrow-layout';
import type { PaymentsChartData } from './types';
import { WideLayout } from './wide-layout';

const queryKeyLabel = 'payments-summary-widget';
const EmptyPlaceholder = emptyStateGraphics.payments;

/**
 * @dashboard-widget
 *
 * id: payments-summary
 * title: Payments Summary
 * description: Provides net total, fees, refunds, and paid/outstanding invoices, offering a comprehensive transaction snapshot.
 * icon: payments-small
 */
export const PaymentsSummaryWidget: DashboardWidgetFC = () => {
  const { currentSize } = useDashboardWidget();
  const { paymentsUrl } = useMerchant();
  const { getMultiQueryKey } = useMultiQueryUtils();
  const { selectedLocationIds } = useAppScopeStore();
  const currentTime = dayjs();
  const { t } = useTranslation('payments');

  const isWide = currentSize.includes('wide');
  const { data, isLoading } = useQuery({
    queryKey: getMultiQueryKey(queryKeyLabel),
    queryFn: () =>
      getInvoiceHistory(`${paymentsUrl}/v1/search/invoices`, {
        start: currentTime.startOf('day').toISOString(),
        end: currentTime.toISOString(),
        locationIds: selectedLocationIds,
      }),
    refetchOnWindowFocus: 'always',
    staleTime: 0,
    cacheTime: 0,
    enabled: !!paymentsUrl,
  });

  const chartData = useMemo(
    () =>
      ({
        net: [
          { key: 'net-total', label: t('Net Total'), value: data?.data?.summary?.net ?? 0 },
          { key: 'fees-total', label: t('Fees Total'), value: data?.data?.summary?.fees ?? 0 },
          { key: 'refund-total', label: t('Refund'), value: data?.data?.summary?.refunds ?? 0 },
        ],
      } satisfies PaymentsChartData),
    [data, t]
  );

  const renderContent = () => {
    if (isLoading) return <ContentLoader show />;

    if (!data?.data?.invoices) {
      return <PaymentsEmptyState />;
    }

    return isWide ? (
      <WideLayout totalInvoices={data?.data?.summary?.invoicesCount ?? 0} chartData={chartData} />
    ) : (
      <NarrowLayout totalInvoices={data?.data?.summary?.invoicesCount ?? 0} chartDataList={chartData} />
    );
  };

  return (
    <DashboardWidget>
      <DashboardWidget.Header>
        <TimePeriodListBoxMenu value='today' readonly />
      </DashboardWidget.Header>
      <DashboardWidget.Content css={[contentContainerStyle, !isWide && { flexDirection: 'column' }]}>
        {renderContent()}
      </DashboardWidget.Content>
    </DashboardWidget>
  );
};

PaymentsSummaryWidget.config = {
  size: {
    large: 'medium-wide',
    medium: 'medium-wide',
    small: 'medium-narrow',
    extraSmall: 'medium-narrow',
  },
  feature: 'payments',
};

const contentContainerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  height: '100%',
  padding: 0,
  minHeight: 0,
});

const PaymentsEmptyState = () => {
  const { modalProps, triggerProps } = useModalControl();
  const { t } = useTranslation('payments');

  return (
    <>
      <div
        css={css`
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding-bottom: ${theme.spacing(1)};
        `}
      >
        <EmptyPlaceholder
          css={css`
            max-height: 200px;
            margin-bottom: ${theme.spacing(3)};
          `}
        />
        <Trans t={t}>
          <Text size='large' weight='regular' color='subdued' textAlign='center'>
            No payments yet today. How about you{' '}
            <TextLink {...triggerProps} as={NakedButton}>
              send an invoice?
            </TextLink>
          </Text>
        </Trans>
      </div>
      <CreateAndCollectModal {...modalProps} />
    </>
  );
};
