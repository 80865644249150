import { css } from '@emotion/react';
import { ActionButtonStyleValues, actionButtonTheme, actionButtonThemeOriginal } from './action-button-theme';
import { buttonFocusStyle } from '../buttons/common.styles';

type PositionProps = {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
};

type ActionButton = {
  floating: boolean;
  position: PositionProps;
  size: 'medium' | 'large';
};

const getPosition = (coordinates: PositionProps) =>
  // @ts-ignore
  Object.entries(coordinates).reduce((arr, [key, value]) => {
    if (!value) return arr;
    return [
      ...arr,
      css`
        ${key}: ${value}px;
      `,
    ];
  }, []);

const styles = (
  {
    background,
    color,
    secondaryColor,
    boxShadow,
    borderColor,
    mediumDiameter,
    focusBackground,
    focusColor,
    hoverBackground,
    activeBackground,
    disabledColor,
    disabledBackground,
    floatingColor,
    floatingHoverBackground,
    floatingActiveBackground,
  }: ActionButtonStyleValues,
  { floating, position, size }: ActionButton
) => {
  const diameter = size === 'large' ? '56px' : `${mediumDiameter}px`;
  return [
    css`
      min-width: ${diameter};
      width: ${diameter};
      height: ${diameter};
      background: ${background};
      border: 1px solid ${borderColor};
      border-radius: 50%;
      color: ${color};
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background 250ms ease-out;

      ${buttonFocusStyle({
        focusRingColor: secondaryColor,
        borderRadius: '999px',
      })};

      :focus,
      :hover {
        color: ${focusColor};
      }

      :focus {
        background: ${focusBackground};
      }

      :hover:not([disabled]) {
        background: ${hoverBackground};
      }

      :active {
        color: white;
        background: ${activeBackground};
        box-shadow: none;
      }

      :disabled {
        color: ${disabledColor};
        background: ${disabledBackground};
        cursor: default;
      }
    `,
    ...(floating
      ? [
          css`
            box-shadow: ${boxShadow};
            position: absolute;
            background: ${secondaryColor};
            color: white;
          `,
          ...getPosition(position),
          css`
            :focus,
            :hover {
              color: ${floatingColor};
            }

            :focus {
              background: ${secondaryColor};
            }

            :hover {
              background: ${floatingHoverBackground};
            }

            :active {
              background: ${floatingActiveBackground};
            }

            :disabled {
              color: ${disabledColor};
              background: ${disabledBackground};
              cursor: default;
            }
          `,
        ]
      : []),
  ];
};

export const ActionButtonStyles = {
  ActionButton: (actionButtonProps: ActionButton) => styles(actionButtonTheme, actionButtonProps),
};

export const ActionButtonStylesOriginal = {
  ActionButton: (actionButtonProps: ActionButton) => styles(actionButtonThemeOriginal, actionButtonProps),
};
