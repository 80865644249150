/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const PreviewIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M17.8333333,1.75 C19.1580545,1.75 20.25,2.80294747 20.25,4.125 L20.25,4.125 L20.25,9.75 C20.25,10.4403559 19.6903559,11 19,11 C18.3096441,11 17.75,10.4403559 17.75,9.75 L17.75,9.75 L17.75,4.25 L6.25,4.25 L6.25,19.75 L12.0068992,19.75 C12.6972552,19.75 13.2568992,20.3096441 13.2568992,21 C13.2568992,21.6903559 12.6972552,22.25 12.0068992,22.25 L12.0068992,22.25 L6.16666667,22.25 C4.84194553,22.25 3.75,21.1970525 3.75,19.875 L3.75,19.875 L3.75,4.125 C3.75,2.80294747 4.84194553,1.75 6.16666667,1.75 L6.16666667,1.75 Z M12.75,8.5 C15.6494949,8.5 18,10.8505051 18,13.75 C18,14.8113004 17.6850851,15.7990485 17.1436012,16.6248986 L19.8336309,19.315864 C20.3217863,19.8040194 20.3217863,20.5954756 19.8336309,21.0836309 C19.3454756,21.5717863 18.5540194,21.5717863 18.065864,21.0836309 L15.3145435,18.3320824 C14.5561548,18.7574558 13.681402,19 12.75,19 C9.85050506,19 7.5,16.6494949 7.5,13.75 C7.5,10.8505051 9.85050506,8.5 12.75,8.5 Z M12.75,11 C11.2312169,11 10,12.2312169 10,13.75 C10,15.2687831 11.2312169,16.5 12.75,16.5 C14.2687831,16.5 15.5,15.2687831 15.5,13.75 C15.5,12.2312169 14.2687831,11 12.75,11 Z'
    />
  </Icon>
);
