import { FC } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

export const snippetStyle = css`
  display: flex;
  align-items: center;
  height: auto;
  padding: ${theme.spacing(0.5, 1)};
  margin: 0;
  position: relative;
  color: ${theme.colors.neutral60};
  background-color: ${theme.colors.neutral5};
  border: 1px solid ${theme.colors.neutral20};
  border-radius: ${theme.borderRadius.small};
  outline-color: transparent;
`;

interface SnippetProps {
  size?: 'large' | 'medium' | 'small';
}
export const Snippet: FC<React.PropsWithChildren<SnippetProps>> = ({ children, size = 'medium' }) => {
  return (
    <Text as='code' css={snippetStyle} size={size}>
      {children}
    </Text>
  );
};
