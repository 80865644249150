import { css } from '@emotion/react';
import { WeaveThemes } from '../../../component-theme';
import { mergeClasses } from '../../../helpers';
import { styles } from '../../../styles';

type Props = React.HTMLAttributes<HTMLElement> & {
  as?: React.ElementType | React.FC<React.PropsWithChildren<unknown>>;
  children: React.ReactNode;
};

export const ModalFlexContent = ({ as = 'div', children, className, ...rest }: Props) => {
  const Element = as;
  return (
    <Element
      css={(theme: WeaveThemes) => [
        css`
          flex: 1;
          overflow-y: auto;
          position: relative;
        `,
        styles.smallScrollbar(theme),
      ]}
      className={mergeClasses('wv-modal-flex-content', className)}
      {...rest}
    >
      {children}
    </Element>
  );
};
