import { theme } from '@frontend/theme';
import { theme as originalTheme } from '@frontend/theme-original';
import { WeaveThemeValues } from '../../../component-theme/theme-types';
import { getThemeFontColor, getThemeOriginalFontColor } from './utils';
import { FloatingLabelProps } from './types';

type FloatingLabelThemeProps = Pick<FloatingLabelProps, 'disabled' | 'hasError'>;

export type FloatingLabelStyleValues = {
  fontColor: WeaveThemeValues['Color'];
  fontSize: WeaveThemeValues['FontSize'];
  spacing: WeaveThemeValues['Spacing'];
};

export const getFloatingLabelTheme = ({ disabled, hasError }: FloatingLabelThemeProps): FloatingLabelStyleValues => ({
  fontColor: getThemeFontColor({ theme, disabled, hasError }),
  fontSize: theme.fontSize,
  spacing: theme.spacing,
});

export const getFloatingLabelThemeOriginal = ({
  disabled,
  hasError,
}: FloatingLabelThemeProps): FloatingLabelStyleValues => ({
  fontColor: getThemeOriginalFontColor({
    theme: originalTheme,
    disabled,
    hasError,
  }),
  fontSize: originalTheme.fontSize,
  spacing: originalTheme.spacing,
});
