import { gql } from 'graphql-request';
import { BalanceSummaryModel } from '../types';

export interface BalanceQueryResponse {
  balance: BalanceSummaryModel;
}

export const balanceQuery = gql`
  query balance($filter: BalanceFilterInput) {
    balance(input: $filter) {
      currency
      total
      available
      inTransit
    }
  }
`;
