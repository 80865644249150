/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const CaretRightIconSmall: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={16} {...props} viewBox='0 0 16 16'>
    <path
      fillRule='evenodd'
      d='M3.07106781,11.0710678 L10.0710678,11.0710678 C10.6233526,11.0710678 11.0710678,11.5187831 11.0710678,12.0710678 C11.0710678,12.6233526 10.6233526,13.0710678 10.0710678,13.0710678 L2.07106781,13.0710678 C1.79492544,13.0710678 1.54492544,12.959139 1.36396103,12.7781746 C1.18299662,12.5972102 1.07106781,12.3472102 1.07106781,12.0710678 L1.07106781,4.07106781 C1.07106781,3.51878306 1.51878306,3.07106781 2.07106781,3.07106781 C2.62335256,3.07106781 3.07106781,3.51878306 3.07106781,4.07106781 L3.07106781,11.0710678 Z'
      transform='translate(6.071068, 8.071068) rotate(-135.000000) translate(-6.071068, -8.071068) '
    />
  </Icon>
);
