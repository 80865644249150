import { ComponentPropsWithoutRef, PropsWithChildren } from 'react';
import { NakedButton } from '../../../naked';
import { mergeClasses } from '../../../helpers';
import { useCalendarState } from '../provider';
import type { NextButtonProps, PrevButtonProps } from '../calendar-types';
import type { BaseAtomProps } from './calendar-atom.types';

type Props = BaseAtomProps & {
  renderPrevButton?: (props: PrevButtonProps) => JSX.Element;
  renderNextButton?: (props: NextButtonProps) => JSX.Element;
};

type CalendarControlProps = PropsWithChildren<Props> & Omit<ComponentPropsWithoutRef<'div'>, keyof Props>;

export const CalendarControl = ({
  className,
  children,
  renderPrevButton,
  renderNextButton,
  ...rest
}: CalendarControlProps) => {
  const { prevBtnProps, nextBtnProps } = useCalendarState();

  return (
    <div className={mergeClasses('cal__controls', className)} {...rest}>
      {renderPrevButton ? (
        renderPrevButton(prevBtnProps)
      ) : (
        <NakedButton className='cal__prev-control' {...prevBtnProps} type='button'>
          prev
        </NakedButton>
      )}
      {children}
      {renderNextButton ? (
        renderNextButton(nextBtnProps)
      ) : (
        <NakedButton className='cal__next-control' {...nextBtnProps} type='button'>
          next
        </NakedButton>
      )}
    </div>
  );
};
