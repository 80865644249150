import { SerializedStyles } from '@emotion/utils';
import { theme, WeaveTheme } from '@frontend/theme';
import { theme as originalTheme, WeaveTheme as WeaveThemeOriginal } from '@frontend/theme-original';
import { FontColorType, getFontColorStyle } from '../helpers';

export type IconThemeValues = {
  fontColorStyle: SerializedStyles | null;
  iconSize: string;
};

export type IconProps = {
  color?: FontColorType;
  size: number;
};

const getThemedFontColorStyle = (color: FontColorType, theme: WeaveTheme | WeaveThemeOriginal) =>
  getFontColorStyle(color, theme);

export const getIconTheme = ({ size, color }: IconProps) => ({
  iconSize: theme.fontSize(size),
  fontColorStyle: color ? getThemedFontColorStyle(color, theme) : null,
});

export const getOriginalIconTheme = ({ size, color }: IconProps) => ({
  iconSize: originalTheme.fontSize(size),
  fontColorStyle: color ? getThemedFontColorStyle(color, originalTheme) : null,
});
