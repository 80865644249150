import React, { useLayoutEffect, useMemo } from 'react';
import createCache from '@emotion/cache';
import { ThemeProvider as EmotionThemeProvider, Theme, CacheProvider as EmotionCacheProvider } from '@emotion/react';
import { FloatingTree } from '@floating-ui/react-dom-interactions';
import { theme, UniversalBaseStyle, type WeaveTheme } from '@frontend/theme';
import { rootStyles } from '../component-theme';
import { usePrefersReducedMotion } from '../hooks';
import { ThemeOptionProvider } from '../theme-option-provider';
import { StylesProvider } from '../use-styles';

// This fixes the warnings about server-side rendering when using :first-child, :last-child, :nth-child
// This app is not server side rendered, so we manually turn it off globally.
// REF: https://github.com/emotion-js/emotion/issues/1105#issuecomment-557726922
const emotionCache = createCache({ key: 'frontend' });
emotionCache.compat = true;

type ThemeProviderProps = {
  children?: React.ReactNode;
  includeEmotionTheme?: boolean;
  skipAnimation?: boolean;
  heightOffset?: number;
};

// Define these in the global scope because theme and root styles
// are consistent instances.

declare module '@emotion/react' {
  export interface Theme extends WeaveTheme {
    heightOffset: 0;
  }
}

export const ThemeProvider = ({
  children,
  includeEmotionTheme = false,
  skipAnimation = false,
  heightOffset,
}: ThemeProviderProps) => {
  useLayoutEffect(() => {
    document.body.setAttribute('data-theme', 'light');
  }, []);

  const prefersReducedMotion = usePrefersReducedMotion();

  const adjustedTheme = useMemo(() => {
    if (heightOffset) {
      return {
        ...theme,
        heightOffset,
      };
    }

    return theme;
  }, [heightOffset]);

  return (
    <ThemeOptionProvider skipAnimation={prefersReducedMotion || skipAnimation}>
      <FloatingTree>
        {includeEmotionTheme ? (
          <EmotionCacheProvider value={emotionCache}>
            <StylesProvider styles={rootStyles}>
              <EmotionThemeProvider theme={adjustedTheme as Theme}>
                <UniversalBaseStyle />
                {children}
              </EmotionThemeProvider>
            </StylesProvider>
          </EmotionCacheProvider>
        ) : (
          <StylesProvider styles={rootStyles}>
            <UniversalBaseStyle />
            {children}
          </StylesProvider>
        )}
      </FloatingTree>
    </ThemeOptionProvider>
  );
};
